@charset "UTF-8";

.m-page-heading-area {
  @include ClearFix;
  padding: 20px;

  .page-heading {
    float: left;
    margin-right: 40px;
    margin-top: -5px;
    font-size: 20px;
    color: $font_color_theme;
    font-weight: bold;
  } // .page-heading

  .sort{
    float: left;

    a, strong {
      display: inline-block;
      width: 150px;
      text-align: center;
      height: 26px;
      line-height: 20px;
      padding: 3px 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      margin-right: 10px;
    }

    strong {
      background: $theme_color_light;
      color: #fff;
    }

    a {
      @include HoverOpacityTrans();
      background: #fff;
      color: #666;
    }

    img {
      width: 20px;
      vertical-align: top;
    }

  } // .sort

} // .m-page-heading-area
