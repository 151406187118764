@charset "UTF-8";

.page-information-new .page-inner .m-form .form-wrap .form-group .private-check::before {
  top: -8px;
}

.page-information-new .page-inner .m-form .form-wrap .form-group .private-check::after {
  top: -12px;
}

/* ▼4.0_RQ51 */
.question-view p,
.question-view label,
p.input-text,
.table-setting-ip tr td pre,
.sel-txt span,
.mutil-space-wrap,
table:not(.table-setting-ip) tr td pre,
.container-header .name span{
  white-space: pre-wrap !important;
}
/* ▲4.0_RQ51 */

.page-courses-detail .contents-name,
.container-header .course-name,
.page-lti-setting-uri .top-area2 p,
.cell-inner .txt span:not(#publicIdOuter),
.cell-inner .txt-assign span,
.page-user-group-new #user-group-parent-name,
.select-tree-modal .label-name,
.c-name span span,
.c-name #courseNameMain,
#noticeNewEdit .c-name-label,
.mutil-space{
  white-space: pre !important;
}

/*
 ▼ポータル作成アプリケーション▼
*/

button.btn.btn-add-course-group, input.btn.btn-add-course-group, a.btn.btn-add-course-group {
  position: relative;
  padding: 0 40px 0 10px;
  height: 38px;
  line-height: 38px;
  font-size: 14px;
  color: #37bef0;
  border: 1px solid #37bef0;
  font-weight: normal;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  text-align: center
}

button.btn.btn-add-course-group:after, input.btn.btn-add-course-group:after, a.btn.btn-add-course-group:after {
  content: " ";
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  background: url(#{$IMG_PATH}icon/icn_imageupload_b.png) left top no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  right: 10px;
  top: 50%;
  margin-top: -13px
}

button.btn.btn-add-course-group.disabled, input.btn.btn-add-course-group.disabled, a.btn.btn-add-course-group.disabled {
  cursor: default;
  opacity: .4
}

button.btn.btn-add-course-group.disabled:hover, input.btn.btn-add-course-group.disabled:hover, a.btn.btn-add-course-group.disabled:hover {
  opacity: .4
}

.page-portal .ps-scrollbar-y-rail {
  right: 0 !important
}

.page-portal .page-contents {
  height: 100%
}

.page-portal .error-text {
  color: red
}

.page-portal .error-text.has-margin {
  margin: 5px 0
}

.portal-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100
}

.portal-modal-inner {
  position: absolute;
  width: 420px;
  background: #fff;
  left: 50%;
  margin-left: -210px;
  top: 20%
}

.portal-modal-header {
  padding: 25px 15px 5px;
  font-size: 16px;
  font-weight: 400;
  text-align: center
}

.portal-modal-content {
  text-align: center;
  padding: 15px 20px 0 20px
}

.portal-modal-footer {
  padding: 30px
}

.portal-modal-footer .ui-btns {
  overflow: visible;
  margin: 0
}

.portal-modal.portal-modal-error .portal-modal-header {
  color: red
}

.portal-modal .btn-modal-cancel {
  margin-top: 15px;
  height: 32px;
  line-height: 32px;
  font-weight: normal;
  font-size: 16px;
  width: 40%;
  background: none;
  border: #6E6E6E solid 1px;
  color: #6E6E6E;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  padding: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box
}

.portal-modal .btn-modal-ok {
  width: 40%;
  height: 32px;
  line-height: 32px;
  font-weight: normal;
  font-size: 16px;
  background: #fff;
  border: 1px solid #ee7800;
  color: #ee7800;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  padding: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box
}

.portal-modal .btn-modal-close {
  width: 40%;
  margin-top: 15px;
  height: 32px;
  line-height: 32px;
  font-weight: normal;
  font-size: 16px;
  background: none;
  border: #6E6E6E solid 1px;
  color: #6E6E6E;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  padding: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box
}

#portal-maker-app {
  position: relative;
  min-height: 100%
}

#portal-maker-app .portal-layout {
  padding-left: 181px
}

#portal-maker-app .portal-layout .side {
  left: 1px;
  top: 0;
  position: absolute;
  height: 100%;
  width: 180px;
  background: #77b6ee;
  z-index: 11
}

#portal-maker-app .portal-layout .main {
  padding: 10px
}

#portal-maker-app .portal-nav li {
  border-bottom: 1px solid #fff
}

#portal-maker-app .portal-nav li:nth-child(2n) {
  background: #58a0ef
}

#portal-maker-app .portal-nav li a, #portal-maker-app .portal-nav li label {
  display: block;
  text-align: center;
  padding: 10px 0;
  color: #fff
}

#portal-maker-app .portal-nav li a, #portal-maker-app .portal-nav li a:link, #portal-maker-app .portal-nav li a:active, #portal-maker-app .portal-nav li a:visited, #portal-maker-app .portal-nav li label, #portal-maker-app .portal-nav li label:link, #portal-maker-app .portal-nav li label:active, #portal-maker-app .portal-nav li label:visited {
  text-decoration: none
}

#portal-maker-app .portal-nav li a:hover, #portal-maker-app .portal-nav li label:hover {
  opacity: .7
}

#portal-maker-app .portal-nav li .disabled {
  background: #ddd;
  color: #ccc
}

#portal-maker-app .portal-nav li .error {
  background: #ff6565
}

#portal-maker-app .portal-nav li .cancel {
  position: relative;
  cursor: default
}

#portal-maker-app .portal-nav li .cancel:after {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3)
}

#portal-maker-app .portal-nav li .cancel:hover {
  opacity: 1
}

#portal-maker-app .no-edit-permission {
  background: #fff;
  z-index: 1;
  padding: 10px 20px;
  text-align: center;
  border: 2px solid #333;
  margin: 10px
}

#portal-maker-app .no-edit-permission p {
  font-size: 16px
}

#portal-maker-app .no-edit-permission span {
  font-size: 13px
}

#portal-maker-app .no-use-display {
  z-index: 10;
  position: absolute;
  text-align: center;
  left: 0;
  top: 0;
  padding-top: 200px;
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);
  display: none
}

#portal-maker-app .no-use-display p {
  padding-left: 180px
}

#portal-maker-app .portal-loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(255, 255, 255, 0.8)
}

#portal-maker-app .portal-loading .loader, #portal-maker-app .portal-loading .loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em
}

#portal-maker-app .portal-loading .loader {
  font-size: 10px;
  display: block;
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.2);
  border-right: 1.1em solid rgba(0, 0, 0, 0.2);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}
/*
#portal-maker-app .portal-content-block {
  position: relative;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px
}
*/
#portal-maker-app .portal-content-block:last-child {
  margin-bottom: 0
}

#portal-maker-app .trigger-close {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #666;
  display: inline-block;
  background-image: url(#{$IMG_PATH}icon/icn_close_k.png);
  background-position: right center;
  background-repeat: no-repeat;
  -webkit-background-size: 12px 12px;
  background-size: 12px 12px;
  padding-right: 18px
}

#portal-maker-app .m-form {
  left: 0;
  width: auto
}

#portal-maker-app .sel-txt span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 25px
}

#portal-maker-app .upload-area {
  position: relative;
  border: 2px dashed #d9d9d9;
  background: #f6f6f6;
  color: #ccc;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s
}

#portal-maker-app .upload-area.dragenter {
  -webkit-box-shadow: 0 0 4px 3px rgba(132, 132, 132, 0.3);
  -moz-box-shadow: 0 0 4px 3px rgba(132, 132, 132, 0.3);
  box-shadow: 0 0 4px 3px rgba(132, 132, 132, 0.3)
}

#portal-maker-app .upload-area p:before {
  position: absolute;
  content: " ";
  display: block;
  width: 110px;
  height: 113px;
  top: 50%;
  margin-top: -55px;
  display: inline-block;
  background: url(#{$IMG_PATH}icon/drop_icn_jpg.png) left top no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto
}

#portal-maker-app .upload-area:before {
  content: " ";
  display: block;
  position: absolute;
  width: 79px;
  height: 79px;
  left: -5px;
  top: -5px;
  background-image: url(#{$IMG_PATH}icon/drop_frame_left_top.png)
}

#portal-maker-app .upload-area:after {
  content: " ";
  display: block;
  position: absolute;
  width: 79px;
  height: 79px;
  right: -5px;
  top: -5px;
  background-image: url(#{$IMG_PATH}icon/drop_frame_right_top.png)
}

#portal-maker-app .upload-area .upload-area-inner:before {
  content: " ";
  display: block;
  position: absolute;
  width: 79px;
  height: 79px;
  left: -5px;
  bottom: -5px;
  background-image: url(#{$IMG_PATH}icon/drop_frame_left_bottom.png)
}

#portal-maker-app .upload-area .upload-area-inner:after {
  content: " ";
  display: block;
  position: absolute;
  width: 79px;
  height: 79px;
  right: -5px;
  bottom: -5px;
  background-image: url(#{$IMG_PATH}icon/drop_frame_right_bottom.png)
}

#portal-maker-app .upload-area.-size-s:before {
  content: " ";
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  -webkit-background-size: 40px auto;
  background-size: 40px auto;
  left: -3px;
  top: -3px;
  background-image: url(#{$IMG_PATH}icon/drop_frame_left_top.png)
}

#portal-maker-app .upload-area.-size-s:after {
  content: " ";
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  -webkit-background-size: 40px auto;
  background-size: 40px auto;
  right: -3px;
  top: -3px;
  background-image: url(#{$IMG_PATH}icon/drop_frame_right_top.png)
}

#portal-maker-app .upload-area.-size-s .upload-area-inner:before {
  content: " ";
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  -webkit-background-size: 40px auto;
  background-size: 40px auto;
  left: -3px;
  bottom: -3px;
  background-image: url(#{$IMG_PATH}icon/drop_frame_left_bottom.png)
}

#portal-maker-app .upload-area.-size-s .upload-area-inner:after {
  content: " ";
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  -webkit-background-size: 40px auto;
  background-size: 40px auto;
  right: -3px;
  bottom: -3px;
  background-image: url(#{$IMG_PATH}icon/drop_frame_right_bottom.png)
}

#portal-maker-app .upload-area.-size-m:before {
  content: " ";
  display: block;
  position: absolute;
  width: 55px;
  height: 55px;
  -webkit-background-size: 55px auto;
  background-size: 55px auto;
  left: -3px;
  top: -3px;
  background-image: url(#{$IMG_PATH}icon/drop_frame_left_top.png)
}

#portal-maker-app .upload-area.-size-m:after {
  content: " ";
  display: block;
  position: absolute;
  width: 55px;
  height: 55px;
  -webkit-background-size: 55px auto;
  background-size: 55px auto;
  right: -3px;
  top: -3px;
  background-image: url(#{$IMG_PATH}icon/drop_frame_right_top.png)
}

#portal-maker-app .upload-area.-size-m .upload-area-inner:before {
  content: " ";
  display: block;
  position: absolute;
  width: 55px;
  height: 55px;
  -webkit-background-size: 55px auto;
  background-size: 55px auto;
  left: -3px;
  bottom: -3px;
  background-image: url(#{$IMG_PATH}icon/drop_frame_left_bottom.png)
}

#portal-maker-app .upload-area.-size-m .upload-area-inner:after {
  content: " ";
  display: block;
  position: absolute;
  width: 55px;
  height: 55px;
  -webkit-background-size: 55px auto;
  background-size: 55px auto;
  right: -3px;
  bottom: -3px;
  background-image: url(#{$IMG_PATH}icon/drop_frame_right_bottom.png)
}

#portal-maker-app .upload-area .preview-img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1
}

#portal-maker-app .portal-sitebnr .upload-area {
  width: 320px;
  height: 200px
}

#portal-maker-app .portal-sitebnr .upload-area p {
  padding-top: 78px;
  padding-left: 140px
}

#portal-maker-app .portal-sitebnr .upload-area p:before {
  left: 15px
}

#portal-maker-app .portal-sitebnr .layout-table>div {
  vertical-align: middle
}

#portal-maker-app .portal-sitebnr .layout-table .upload-outer {
  width: 320px
}

#portal-maker-app .portal-sitebnr .layout-table .meta {
  padding-left: 40px
}

#portal-maker-app .portal-main-visual .upload-area {
  width: 550px;
  height: 270px
}

#portal-maker-app .portal-main-visual .upload-area p {
  padding-top: 116px;
  padding-left: 250px
}

#portal-maker-app .portal-main-visual .upload-area p:before {
  left: 125px
}

#portal-maker-app .portal-main-visual .layout-table>div {
  vertical-align: middle
}

#portal-maker-app .portal-main-visual .layout-table .upload-outer {
  width: 550px
}

#portal-maker-app .portal-main-visual .layout-table .meta {
  padding-left: 20px
}

#portal-maker-app .portal-main-visual .layout-table .meta .util-mb5 {
  margin-bottom: 5px
}

#portal-maker-app .portal-main-visual .layout-table .meta ul li {
  margin-bottom: 10px
}

#portal-maker-app .portal-menu-setting .upload-area {
  width: 100%;
  height: 120px;
  text-align: center;
  margin-bottom: 10px;
  margin-left: -2px
}

#portal-maker-app .portal-menu-setting .upload-area p {
  padding-top: 35px;
  display: inline-block;
  text-align: left
}

#portal-maker-app .portal-menu-setting .upload-area p:before {
  display: none
}

#portal-maker-app .portal-menu-setting .upload-area .preview-img {
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  background-size: cover
}

#portal-maker-app .portal-menu-setting .m-form p {
  margin-bottom: 5px
}

#portal-maker-app .portal-menu-setting .m-form .radio-group {
  margin: 0;
  display: block
}

#portal-maker-app .portal-menu-setting .m-form .radio-group .pseudo-radio {
  margin-right: 5px
}

#portal-maker-app .portal-menu-setting .menu-list {
  margin-top: 10px;
  overflow: hidden;
  margin-left: -10px
}

#portal-maker-app .portal-menu-setting .menu-list:first-of-type {
  margin-top: 30px
}

#portal-maker-app .portal-menu-setting .menu-list li {
  width: 20%;
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 10px
}

#portal-maker-app .portal-menu-setting .ui-droppable-hover .menu-item:after {
  opacity: 1;
  z-index: 1
}

#portal-maker-app .portal-menu-setting .menu-item {
  position: relative;
  background: #fff
}

#portal-maker-app .portal-menu-setting .menu-item .error-text {
  font-size: 10px
}

#portal-maker-app .portal-menu-setting .menu-item:after {
  content: "入れ替える";
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding-top: 80px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(118, 184, 230, 0.5);
  opacity: 0;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s
}

#portal-maker-app .portal-menu-setting .menu-item .m-form {
  font-size: 12px
}

#portal-maker-app .portal-menu-setting .menu-item .m-form .input-text {
  padding-top: 3px;
  padding-bottom: 3px
}

#portal-maker-app .portal-menu-setting .menu-item-header {
  position: relative;
  background: #eee;
  padding: 0 5px 5px
}

#portal-maker-app .portal-menu-setting .menu-item-header .draggable-elem {
  cursor: move;
  background: #e3e3e3;
  height: 12px;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 5px;
  text-align: center;
  position: relative
}

#portal-maker-app .portal-menu-setting .menu-item-header .draggable-elem .bar {
  position: absolute;
  display: block;
  width: 24px;
  height: 1px;
  background: #ccc;
  left: 50%;
  margin-left: -12px
}

#portal-maker-app .portal-menu-setting .menu-item-header .draggable-elem .bar-top {
  top: 4px
}

#portal-maker-app .portal-menu-setting .menu-item-header .draggable-elem .bar-btm {
  top: 7px
}

#portal-maker-app .portal-menu-setting .menu-item-header p {
  line-height: 18px
}

#portal-maker-app .portal-menu-setting .menu-item-header .trigger-clear {
  position: absolute;
  right: 5px;
  top: 15px;
  display: block;
  width: 18px;
  height: 18px;
  background: url(#{$IMG_PATH}icon/icn_formclear_k.png) left top no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto
}

#portal-maker-app .portal-menu-setting .menu-item-header .trigger-clear, #portal-maker-app .portal-menu-setting .menu-item-header .trigger-clear:link, #portal-maker-app .portal-menu-setting .menu-item-header .trigger-clear:active, #portal-maker-app .portal-menu-setting .menu-item-header .trigger-clear:visited {
  text-decoration: none
}

#portal-maker-app .portal-menu-setting .menu-item-header .trigger-clear:hover {
  opacity: .7
}

#portal-maker-app .portal-menu-setting .menu-item-content {
  padding: 10px;
  border: 1px solid #eee;
  border-width: 0 1px 1px 1px
}

#portal-maker-app .portal-information {
  padding: 0;
  border: none
}

#portal-maker-app .portal-information .col-6 {
  position: relative
}

#portal-maker-app .portal-information .trigger-close {
  color: #fff;
  background-image: url(#{$IMG_PATH}icon/icn_close_w.png)
}

#portal-maker-app .portal-information .img-delete-btn {
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: block;
  background: rgba(0, 0, 0, 0.6) url(#{$IMG_PATH}icon/icn_close_w.png) center center no-repeat;
  -webkit-background-size: 12px;
  background-size: 12px;
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 2;
  -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3)
}

#portal-maker-app .portal-information .img-delete-btn:hover {
  opacity: .7
}

#portal-maker-app .portal-information .information-img {
  padding-right: 10px
}

#portal-maker-app .portal-information .information-img .upload-area {
  width: 100%;
  height: 166px;
  text-align: center
}

#portal-maker-app .portal-information .information-img .upload-area p {
  margin-top: 20px;
  position: relative;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 120px;
  display: inline-block;
  text-align: left
}

#portal-maker-app .portal-information .information-img .upload-area p:before {
  left: 0
}

#portal-maker-app .portal-information .information-img .upload-area .preview-img {
  width: 100%;
  height: 166px;
  -webkit-background-size: cover;
  background-size: cover
}

#portal-maker-app .portal-information .information-area {
  background: #81c0e9;
  padding: 10px 0 10px 10px;
  margin-bottom: 5px
}
/*
#portal-maker-app .portal-information .information-area .heading a {
  display: block;
  color: #fff;
  font-size: 18px;
  cursor: default;
  margin-bottom: 5px
}

#portal-maker-app .portal-information .information-area .heading span {
  display: inline-block;
  padding-left: 22px;
  background: url(#{$IMG_PATH}icon/icn_infomation_w.png) left center no-repeat;
  -webkit-background-size: 18px;
  background-size: 18px;
  background-position: 0% 30%
}
*/
#portal-maker-app .portal-information .information-area .number-info {
  font-size: 30px;
  font-weight: bold;
  color: #4aa7d8
}

#portal-maker-app .portal-information .information-area .new-info {
  float: right;
  margin-right: 9px;
  color: #949394
}

#portal-maker-app .portal-information .information-area .new-info .infomation {
  background: #fff;
  text-align: center;
  margin-bottom: 4px;
  font-size: 16px;
  position: relative;
  padding: 9px 0px 9px 0px
}

#portal-maker-app .portal-information .information-area .new-info .new {
  padding-right: 10px;
  display: inline-block;
  width: 50px;
  height: 22px;
  background: url(#{$IMG_PATH}icon/icn_new_k.png) center center no-repeat;
  -webkit-background-size: 50px;
  background-size: 50px;
  opacity: .3
}

#portal-maker-app .portal-information .information-area .star, #portal-maker-app .portal-information .information-area .bell {
  padding-right: 10px;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  opacity: .2;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  -ms-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s
}

#portal-maker-app .portal-information .information-area .star, #portal-maker-app .portal-information .information-area .star:link, #portal-maker-app .portal-information .information-area .star:active, #portal-maker-app .portal-information .information-area .star:visited, #portal-maker-app .portal-information .information-area .bell, #portal-maker-app .portal-information .information-area .bell:link, #portal-maker-app .portal-information .information-area .bell:active, #portal-maker-app .portal-information .information-area .bell:visited {
  text-decoration: none
}

#portal-maker-app .portal-information .information-area .star:hover, #portal-maker-app .portal-information .information-area .bell:hover {
  opacity: .1
}

#portal-maker-app .portal-information .information-area .star.on, #portal-maker-app .portal-information .information-area .bell.on {
  background-size: contain;
  opacity: 1;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  -ms-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s
}

#portal-maker-app .portal-information .information-area .star.on, #portal-maker-app .portal-information .information-area .star.on:link, #portal-maker-app .portal-information .information-area .star.on:active, #portal-maker-app .portal-information .information-area .star.on:visited, #portal-maker-app .portal-information .information-area .bell.on, #portal-maker-app .portal-information .information-area .bell.on:link, #portal-maker-app .portal-information .information-area .bell.on:active, #portal-maker-app .portal-information .information-area .bell.on:visited {
  text-decoration: none
}

#portal-maker-app .portal-information .information-area .star.on:hover, #portal-maker-app .portal-information .information-area .bell.on:hover {
  opacity: .7
}

#portal-maker-app .portal-information .information-area .bell {
  background: url(#{$IMG_PATH}icon/icn_bell_k.png) center center no-repeat;
  -webkit-background-size: contain;
  background-size: contain
}

#portal-maker-app .portal-information .information-area .bell.on {
  background: url(#{$IMG_PATH}icon/icn_bell_o.png) center center no-repeat;
  -webkit-background-size: contain;
  background-size: contain
}

#portal-maker-app .portal-information .information-area .star {
  background: url(#{$IMG_PATH}icon/icn_star_k.png) center center no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  -ms-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s
}

#portal-maker-app .portal-information .information-area .star, #portal-maker-app .portal-information .information-area .star:link, #portal-maker-app .portal-information .information-area .star:active, #portal-maker-app .portal-information .information-area .star:visited {
  text-decoration: none
}

#portal-maker-app .portal-information .information-area .star:hover {
  opacity: .1
}

#portal-maker-app .portal-information .information-area .star.on {
  background: url(#{$IMG_PATH}icon/icn_star_o.png) center center no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  -ms-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s
}

#portal-maker-app .portal-information .information-area .star.on, #portal-maker-app .portal-information .information-area .star.on:link, #portal-maker-app .portal-information .information-area .star.on:active, #portal-maker-app .portal-information .information-area .star.on:visited {
  text-decoration: none
}

#portal-maker-app .portal-information .information-area .star.on:hover {
  opacity: .7
}

#portal-maker-app .portal-information .information-area .remind {
  width: 49%;
  float: right;
  background: #fff;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px
}

#portal-maker-app .portal-information .information-area .important {
  width: 49%;
  background: #fff;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px
}

#portal-maker-app .portal-information .information-area .remind .number-info, #portal-maker-app .portal-information .information-area .important .number-info {
  font-size: 26px
}

#portal-maker-app .portal-information .information-area .detail-infomation {
  height: 115px
}

#portal-maker-app .portal-information .information-area .detail-infomation .detail-infomation-inner {
  padding-right: 13px
}

#portal-maker-app .portal-information .information-area .detail-infomation .detail-infomation-inner a {
  padding: 3px 10px 2px 0px;
  position: relative;
  text-align: right;
  line-height: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  -ms-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s;
  background: #fff;
  color: #81c0e9;
  display: block
}

#portal-maker-app .portal-information .information-area .detail-infomation .detail-infomation-inner a, #portal-maker-app .portal-information .information-area .detail-infomation .detail-infomation-inner a:link, #portal-maker-app .portal-information .information-area .detail-infomation .detail-infomation-inner a:active, #portal-maker-app .portal-information .information-area .detail-infomation .detail-infomation-inner a:visited {
  text-decoration: none
}

#portal-maker-app .portal-information .information-area .detail-infomation .detail-infomation-inner a:hover {
  opacity: .5
}

#portal-maker-app .portal-information .information-area .detail-infomation .detail-infomation-inner a .all-info-icon {
  padding-right: 20px;
  width: 25px;
  height: 25px;
  background: url(#{$IMG_PATH}icon/icn_infomation_all_b.png) center center no-repeat;
  -webkit-background-size: 15px;
  background-size: 15px;
  background-position-y: 2px
}

#portal-maker-app .portal-information .information-area .detail-infomation .detail-infomation-inner ul li {
  background: #fff;
  margin-bottom: 3px;
  font-size: 12px;
  position: relative;
  padding: 3px 5px 4px 30px
}

#portal-maker-app .portal-information .information-area .detail-infomation .detail-infomation-inner ul li.notice:before {
  content: " ";
  display: block;
  width: 10px;
  height: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background: #ee7800;
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -5px
}

#portal-maker-app .portal-information .information-area .detail-infomation .detail-infomation-inner ul li p {
  display: table;
  width: 100%;
  table-layout: fixed;
  width: 80%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

#portal-maker-app .portal-information .information-area .detail-infomation .detail-infomation-inner ul li p span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  vertical-align: middle;
  display: table-cell
}

#portal-maker-app .portal-information .information-area .detail-infomation .detail-infomation-inner ul li p .date {
  width: 80px;
  padding-right: 8px;
  font-size: 11px
}

#portal-maker-app .portal-information .information-area .detail-infomation .detail-infomation-inner ul li .star, #portal-maker-app .portal-information .information-area .detail-infomation .detail-infomation-inner ul li .bell {
  width: 18px;
  height: 18px;
  top: 15px;
  position: absolute
}

#portal-maker-app .portal-information .information-area .detail-infomation .detail-infomation-inner ul li .star {
  right: 30px
}

#portal-maker-app .portal-information .information-area .detail-infomation .detail-infomation-inner ul li .bell {
  right: 5px
}

#portal-maker-app .portal-information .information-area .scroll-area.ps-active-y .scroll-area-inner {
  padding-right: 25px
}

#portal-maker-app .portal-information .information-area .scroll-area {
  height: 115px
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner {
  padding-right: 10px
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li {
  background: #fff;
  margin-bottom: 3px;
  font-size: 12px;
  position: relative;
  padding: 5px 5px 5px 30px
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li.notice:before {
  content: " ";
  display: block;
  width: 10px;
  height: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background: #ee7800;
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -5px
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li p {
  display: table;
  width: 100%;
  table-layout: fixed;
  padding-right: 80px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li p span {
  vertical-align: middle;
  display: table-cell
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li p .date {
  width: 80px;
  padding-right: 8px
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .bell {
  position: absolute;
  top: 4px;
  display: block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  opacity: .2;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  -ms-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star:link, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star:active, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star:visited, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .bell, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .bell:link, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .bell:active, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .bell:visited {
  text-decoration: none
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star:hover, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .bell:hover {
  opacity: .1
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star.on, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .bell.on {
  background-size: contain;
  opacity: 1;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  -ms-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star.on, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star.on:link, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star.on:active, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star.on:visited, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .bell.on, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .bell.on:link, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .bell.on:active, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .bell.on:visited {
  text-decoration: none
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star.on:hover, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .bell.on:hover {
  opacity: .7
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star {
  right: 50px;
  background: url(#{$IMG_PATH}icon/icn_star_k.png) center center no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  -ms-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star:link, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star:active, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star:visited {
  text-decoration: none
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star:hover {
  opacity: .1
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star.on {
  background: url(#{$IMG_PATH}icon/icn_star_o.png) center center no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  -ms-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star.on, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star.on:link, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star.on:active, #portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star.on:visited {
  text-decoration: none
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .star.on:hover {
  opacity: .7
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .bell {
  right: 15px;
  background: url(#{$IMG_PATH}icon/icn_bell_k.png) center center no-repeat;
  -webkit-background-size: contain;
  background-size: contain
}

#portal-maker-app .portal-information .information-area .scroll-area .scroll-area-inner ul li .bell.on {
  background: url(#{$IMG_PATH}icon/icn_bell_o.png) center center no-repeat;
  -webkit-background-size: contain;
  background-size: contain
}

#portal-maker-app .portal-course-group .course-group-edit {
  margin-top: 30px
}

#portal-maker-app .portal-course-group .ui-droppable-hover .course-group-item:after {
  opacity: 1;
  z-index: 1
}

#portal-maker-app .portal-course-group .course-group-item {
  background: #fff;
  position: relative;
  padding: 5px 10px 5px 20px;
  border: 1px solid #ccc;
  margin-bottom: 5px
}

#portal-maker-app .portal-course-group .course-group-item.has-error .des {
  color: red
}

#portal-maker-app .portal-course-group .course-group-item:after {
  content: "入れ替える";
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(118, 184, 230, 0.5);
  opacity: 0;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s
}

#portal-maker-app .portal-course-group .course-group-item:last-child {
  margin-bottom: 0
}

#portal-maker-app .portal-course-group .course-group-item .draggable-elem {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 11px;
  background: #e3e3e3;
  cursor: move
}

#portal-maker-app .portal-course-group .course-group-item .draggable-elem .bar {
  position: absolute;
  display: block;
  width: 1px;
  height: 24px;
  background: #ccc;
  top: 50%;
  margin-top: -12px
}

#portal-maker-app .portal-course-group .course-group-item .draggable-elem .bar-left {
  left: 3px
}

#portal-maker-app .portal-course-group .course-group-item .draggable-elem .bar-right {
  left: 6px
}

#portal-maker-app .portal-course-group .trigger-delete {
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  background: url(#{$IMG_PATH}icon/icn_trash_k.png) left top no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  right: 5px;
  top: 50%;
  margin-top: -13px
}

#portal-maker-app .portal-course-group .trigger-delete, #portal-maker-app .portal-course-group .trigger-delete:link, #portal-maker-app .portal-course-group .trigger-delete:active, #portal-maker-app .portal-course-group .trigger-delete:visited {
  text-decoration: none
}

#portal-maker-app .portal-course-group .trigger-delete:hover {
  opacity: .7
}

.ui-draggable-dragging {
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 10
}
.feedback-field-upload-region .drop-disabled{
  background: #a9a9a9 !important;
}

/*
 ▲ポータル作成アプリケーション▲
*/
#cke_responseText .cke_wysiwyg_div {
  text-align: left;
  min-height: 210px;
  max-height: auto;
  height: auto;
}

/*
  ▼RQ67
*/

.form-group tr .c-name .inpt{
  display: flex;
}

.form-group tr .c-name{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.form-group tr .c-name .c-name .multi-space-ellipsis{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 34px;
}

input.btn.btn-common {
  margin: 0 auto;
  padding: 15px 30px;
  margin: 10px 10px;
  background: #fff;
}

input.btn.btn-common.btn-main {
  border: 1px solid #ee7800;
  color: #ee7800;
}
input.btn.btn-common.btn-other {
  color: #b9b9b9;
  border: 1px solid #b9b9b9;
}

.m-modal .model-content{
  padding: 20px;
}

.m-modal .main-content {
  text-align: left;
  padding: 10px 20px;
  overflow-y: scroll;
  max-height: 500px;
}


.table-background {
  background-color: #bae3f9;
  margin-top: 20px;
  padding: 15px 10px 30px 10px;
}

.table-background table{
  width: -webkit-fill-available;
  table-layout: fixed;
  background-color: white;
}

.table-background table th.header-table{
  border: 1px solid #999;
  text-align: left !important;
}

.table-background table td{
  border: 1px solid #999;
  text-align: left;
}

.modal-inner .btn-close-modal{
  position: relative;
  top: -10px;
  right: -10px;
  background: url(#{$IMG_PATH}icon/icn_close_k.png) left center no-repeat;
  background-size: 15px;
  width: 20px;
  height: 20px;
  float: right;
  cursor: pointer;
}

.back .back-image{
  height: 30px;
  position: absolute;
}

.title-header{
  margin: 30px 0px;
  width: 35%;
  min-width: 200px;
  border-bottom: 2px solid #78659e;
}

.loading-page{
  position: fixed;
  top: 40%;
  left: 50%;
  z-index: 1000;
}

#loadingSpan{
  background: url(#{$IMG_PATH}ccp/common/dialog/loading_dialog.gif);
  width: 96px;
  height: 96px;
}

.page-all-history .page-inner .page-contents .form-row .attention{
  position: absolute;
}

.error.error-custom{
  position: absolute;
  margin-top: 0px;
  margin-left: 100px;
}

#exportStatus{
  color: #a0a0a0;
  border: 1px solid #a0a0a0;
}

/*
  ▲RQ67
*/
/* ▼ 6.0_RQ25_2 */
.page-courses-search .page-inner .course-result.result-tbl .table-result.tbl-user-chapter .col-forced-complete, .page-courses-search .page-inner .course-result.result-tbl .table-header.tbl-user-chapter .col-forced-complete {
  width: 8%;
}

.page-courses-search a.btn.btn-forced-complete {
  background: url(#{$IMG_PATH}icon/icn_edit_k.png) 20px center no-repeat;
  -webkit-background-size: 80%;
  background-size: 80%;
  background-position-x: 50%;
  font-weight: normal;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  font-size: 16px;
  padding: 0;
}

.course-attend-multiple-chapter-result .header-12th, .course-attend-multiple-chapter-result .col-12th {
  min-width: 7% !important;
  width: 7%;
}

.course-attend-multiple-chapter-result a.btn.btn-forced-complete {
  background: url(#{$IMG_PATH}icon/icn_edit_k.png) 20px center no-repeat;
  -webkit-background-size: 80%;
  background-size: 80%;
  background-position-x: 50%;
  font-weight: normal;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  font-size: 16px;
  padding: 0;
}
/* ▲ 6.0_RQ25_2 */