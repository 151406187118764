@charset "UTF-8";

.page-content-approval {

  .page-inner {

    .hidden {
      display : none;
    }

    .page-contents {
      color: #7d7777;
      width: 100%;
      padding: 20px 30px;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      max-width: 1200px;

      .approval-header {
        min-height: 34px;
        padding: 20px 0 0 0;
        position: relative;
        overflow: hidden;

        .inner {
          min-height: 34px;
          padding: 20px 0 0 0;
          position: relative;
          overflow: hidden;

           &.hide {
            display: none;
          }
          
          img {
            width: 20px;
            height: 20px;
          }
        }
      }

      .m-search-result {
        .result-table {
          thead {
            tr {
              th {
                background:#6CC0FF;
                color: white;
                letter-spacing: 1.5pt;
                text-align: center;

                font-weight: initial;
                font-size: initial;
                padding: initial;
              }
            }
          }
          tbody {
            tr {
              td {
                text-align: center;
                position: relative;
                padding: 5px;
                font-size: 12px;
              }
            }
          }
        }
      }

      .text-color-blue {
        color: #3cb2e1;
      }

      #jsConfirmApproval {
        .msg-comp-other-list {
          max-height: 150px;
        }
      }
    }//.page-contents

    .m-form {
      left: 0;
      width: 840px;

      .form-wrap {

        &:first-child {
          margin-top: 0;
          // .form-sub-heading {
          // }
        }

        .form-group {
          padding-right: 40px;
        }
      }

      table {
        table-layout: initial; // <=> auto
        thead {
          th:first-child {
            background:#6CC0FF;
            color: white;
            text-align: left;
            letter-spacing: 1.5pt;
          }
        }

        tbody {
          tr {
            td {
              &:first-child, &.label {
                background: #dff2fc;
                width: 25%;
                text-align: left;
                word-wrap: break-word;
              }
              .info {
                line-height: 35px;
                font-size: 16px;
                font-weight: normal;
              }

                span {
                    min-width: 108px;

                    vertical-align: middle;
                    display: inline-block;
                    line-height: 35px;
                    margin-right: 15px;
                  &.slide {
                    padding-left: 20px;
                    background: url(#{$IMG_PATH:}icon/ico_sheets_10x10_g.png) no-repeat scroll 0 12px/13px 13px;
                  }
                  &.time {
                    padding-left: 20px;
                    background: url(#{$IMG_PATH:}icon/ico_time_10x10_g.png) no-repeat scroll 0 12px/13px 13px;
                  }
                  &.capacity {
                    padding-left: 20px;
                    background: url(#{$IMG_PATH:}icon/ico_size_12x10_g.png) no-repeat scroll 0 12px/13px 13px;
                  }
                  &.type {
                    padding-left: 20px;
                    background: url(#{$IMG_PATH:}icon/ico_contents_10x13_g.png) no-repeat scroll 0 12px/13px 13px;
                  }
                } 

                .ui-btns{
                  margin: 20px 0;
                  text-align: left;
                  overflow: hidden;
                  .btn-preview {
                    text-align: center;
                    vertical-align: center;
                    position: relative;
                    background: #fff;
                    border: 1px solid #DC780A;
                    width: 200px;
                    height: 35px;
                    line-height: 35px;
                    font-size: 16px;
                    color: #DC780A;
                    font-weight: normal;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                    border-radius: 4px;
                    -webkit-box-sizing: content-box;
                    -moz-box-sizing: content-box;
                    box-sizing: content-box;
                    padding: 0;
                  } //.btn-preview
                }//.ui-btns
            }

            // td:nth-child(3) {
            //   background: #dff2fc;
            //   width: 25%;
            //   text-align: left;
            //   word-wrap: break-word;
            // }
          }
        }

      }

      &.conf-form {
        .form-wrap {

          .form-group {
            padding-right: 0;
          }
        }
      }

      .comment-area {
        textarea {
          resize: none;
        }
      }

      .ui-btns {
        .btn-gray {
          position: relative;
          border: 1px solid #a0a0a0;
          width: 240px;
          height: 44px;
          line-height: 44px;
          font-size: 16px;
          color: #a0a0a0;
          background-color: #fff;
          font-weight: normal;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
        }
      }

    }//.m-form

    .m-message {
      padding-top: 0;
      width: 840px;
      margin: auto;
    }

    .m-list-course {
      padding-top: 0;
      width: 840px;
      margin: auto;
      margin-top: 20px;
      .lesson-cell-error{
        float: left;
        width: 35%;
      }
      .error{
        color: red;
      }

      .noneSelect{
            cursor: no-drop !important;
            // color: #d2d2d2 !important;
            background: #eceff3 !important;
      }

      .layout {
          overflow: hidden;
          margin-left: -20px;
          margin-bottom: 20px;
          & > div {
            float: left;
            padding-left: 20px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
          }
          .course-search{
            display: flex;
            align-items: center;
            color: #3cb2e1;
            div {
              float: left;
            }
            .div-contain-btn {
              display: flex;
              align-items: center;
              padding-left: 10px;
            }
            .icon-course-choose {
              display: inline-block;
              padding-left: 25px;
              background: url(#{$IMG_PATH}icon/icn_coursesingle_b.png) no-repeat;
              background-position: 0% 35%;
              -webkit-background-size: 20px;
              background-size: 20px;
              cursor: default;
            }
            .btn-search-course{
              background: url(#{$IMG_PATH}icon/icn_search_w.png) 50% center no-repeat;
              background-color: #82BEF0;
              background-size: 22px;
              position: relative;
              width: 55px;
              height: 33px;
              line-height: 33px;
              color: #333;
              font-weight: normal;
              -webkit-border-radius: 2px;
              -moz-border-radius: 2px;
              border-radius: 2px;
              text-align: center;
            }
          } 
      }

      &.ps-active-y {
          .courses-detail-layout {
            margin-bottom: 20px;
          }
      }

      .layout-list-course {
          padding-top: 5px;
          padding-bottom: 20px;
          padding-right: 20px;
          background: #d2ecfa;
          margin-left:0px;
          height:350px;
          @media screen and (min-width : 1200px) {
             @media screen and (max-height : 850px) {
              height:250px;
             }
          }
          .courses-detail-layout {
            @include LayoutTable();
            height: 100%;
            min-height: 1px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            & > div {
              @include LayoutCell(top);
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              height: 100%;
              min-height: 1px;
              padding-top: 30px;
              position: relative;
              .title {
                position: absolute;
                top: 0;
                color: #3cb2e1;
                font-size: 16px;
                margin-bottom: 5px;

                .check-star {
                  font-size: 12px;
                  color: #fe0000;
                  vertical-align: super;
                }
              }
            }

            .cell-inner {
              height: 100%;
              min-height: 1px;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              position: relative;

              @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) { // chrome only
                -webkit-box-sizing: content-box;
                -moz-box-sizing: content-box;
                box-sizing: content-box;
              }

              .ps-container.ps-active-y>.ps-scrollbar-y-rail {
                background: none;
                right: 16px!important;
              }

              .ps-container>.ps-scrollbar-y-rail {
                opacity: 0;
              }

              .ps-container:hover >.ps-scrollbar-y-rail,
              .ps-container.ps-in-scrolling >.ps-scrollbar-y-rail {
                opacity: .3;
              }

              .sc-top, .sc-btm {
                position: absolute;
                width: 100%;
                height: 10px;
                left: 0;
                display: block;
                padding-right: 30px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                z-index: 1;

                a {
                  @include HoverOpacity();
                  display: block;
                  height: 100%;
                  width: 100%;
                  background-color: #d1d1d1;
                }
              }

              .sc-top {
                top: 0;
              }
              .sc-btm {
                bottom: 0;
              }

              .courses-detail-list-outer {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;

                .courses-detail-list {
                  height: 100%;
                  overflow-y: auto;
                  padding-right: 15px;

                  .courses-detail-list-inner {
                    background: #ccc;
                    min-height: 100%;
                  }

                  li {
                    position: relative;
                    border-bottom: 1px solid #ccc;
                    background: #fff;

                    .inner {
                    }

                    .inner > .inner-box {
                      @include HoverOpacity();
                      cursor: pointer;

                      position: relative;
                      display: block;
                      //padding: 3px 0 3px 3px;
                      font-size: 13px;
                      color: $font_color_default;

                      &:hover {
                        text-decoration: none;
                      }

                      & > div {
                        padding: 8px 60px 8px 42px;
                      }

                      .txt {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        line-height: 25px;
                        height: 25px;
                        font-size: 12px;
                      }
                    }

                    .inner > .inner-box:before {
                      @include PseudoElements();
                      position: absolute;
                      display: block;
                      width: 26px;
                      height: 26px;
                      left: 8px;
                      top: 50%;
                      margin-top: -13px;
                      opacity: .6;
                    }

                    &.icn-contents-no-set .inner > .inner-box:before {
                      background: url(#{$IMG_PATH}icon/icn_contents_no_set_k.png) left center no-repeat;
                      -webkit-background-size: contain;
                      background-size: contain;
                    }
                    &.icn-ccp .inner > .inner-box:before {
                        background: url(#{$IMG_PATH}icon/icn_conccp_k.png) left top no-repeat;
                        -webkit-background-size: contain;
                        background-size: contain;
                    }
                    &.icn-anket .inner > .inner-box:before {
                        background: url(#{$IMG_PATH}icon/icn_conenq_k.png) left top no-repeat;
                        -webkit-background-size: contain;
                        background-size: contain;
                    }
                    &.icn-test .inner > .inner-box:before {
                        background: url(#{$IMG_PATH}icon/icn_test_k.png) left top no-repeat;
                        -webkit-background-size: contain;
                        background-size: contain;
                    }
                    &.icn-doc .inner > .inner-box:before {
                        background: url(#{$IMG_PATH}icon/icn_file_document_k.png) left top no-repeat;
                        -webkit-background-size: contain;
                        background-size: contain;
                    }
                    &.icn-video .inner > .inner-box:before {
                        background: url(#{$IMG_PATH}icon/icn_video_k.png) left top no-repeat;
                        -webkit-background-size: contain;
                        background-size: contain;
                    }
                    &.icn-html .inner > .inner-box:before {
                        background: url(#{$IMG_PATH}icon/icn_file_html_k.png) left top no-repeat;
                        -webkit-background-size: contain;
                        background-size: contain;
                    }
                    &.icn-books .inner > .inner-box:before {
                      background: url(#{$IMG_PATH}icon/icn_lesson_k.png) left center no-repeat;
                      -webkit-background-size: contain;
                      background-size: contain;
                    }
                    &.icn-test .inner > .inner-box:before {
                      background: url(#{$IMG_PATH}icon/icn_test_k.png) left center no-repeat;
                      -webkit-background-size: contain;
                      background-size: contain;
                    }
                    &.icn-debate .inner > .inner-box:before {
                      background: url(#{$IMG_PATH}icon/icn_debate_k.png) left center no-repeat;
                      -webkit-background-size: contain;
                      background-size: contain;
                    }

                    &.checked .inner:before {
                      @include PseudoElements();
                      position: absolute;
                      display: block;
                      width: 24px;
                      height: 24px;
                      left: 8px;
                      top: 50%;
                      margin-top: -12px;
                      background: url(#{$IMG_PATH}icon/icn_check02_b.png) left center no-repeat;
                      -webkit-background-size: contain;
                      background-size: contain;
                      z-index: 1;
                    }

                    &.checked .inner > .inner-box:before {
                      opacity: .3!important;
                    }

                    &.selected {
                      .inner .inner-box {
                        color: #fff;
                        & > div {
                          background: #5fadda;
                        }

                        &:before {
                          opacity: 1;
                        }

                        &:hover {
                          opacity: 1;
                        }

                      }

                      &.icn-contents-no-set .inner > .inner-box:before {
                        background: url(#{$IMG_PATH}icon/icn_contents_no_set_w.png) left top no-repeat;
                        -webkit-background-size: contain;
                        background-size: contain;
                      }
                      &.icn-ccp .inner > .inner-box:before {
                        background: url(#{$IMG_PATH}icon/icn_conccp_w.png) left top no-repeat;
                        -webkit-background-size: contain;
                        background-size: contain;
                      }
                      &.icn-anket .inner > .inner-box:before {
                        background: url(#{$IMG_PATH}icon/icn_conenq_w.png) left top no-repeat;
                        -webkit-background-size: contain;
                        background-size: contain;
                      }
                      &.icn-test .inner > .inner-box:before {
                        background: url(#{$IMG_PATH}icon/icn_test_w.png) left top no-repeat;
                        -webkit-background-size: contain;
                        background-size: contain;
                      }
                      &.icn-doc .inner > .inner-box:before {
                        background: url(#{$IMG_PATH}icon/icn_file_document_w.png) left top no-repeat;
                        -webkit-background-size: contain;
                        background-size: contain;
                      }
                      &.icn-video .inner > .inner-box:before {
                        background: url(#{$IMG_PATH}icon/icn_video_w.png) left top no-repeat;
                        -webkit-background-size: contain;
                        background-size: contain;
                      }
                      &.icn-html .inner > .inner-box:before {
                        background: url(#{$IMG_PATH}icon/icn_file_html_w.png) left top no-repeat;
                        -webkit-background-size: contain;
                        background-size: contain;
                      }
                      &.icn-books .inner > .inner-box:before {
                        background: url(#{$IMG_PATH}icon/icn_lesson_w.png) left top no-repeat;
                        -webkit-background-size: contain;
                        background-size: contain;
                      }
                      &.icn-test .inner > .inner-box:before {
                        background: url(#{$IMG_PATH}icon/icn_test_w.png) left top no-repeat;
                        -webkit-background-size: contain;
                        background-size: contain;
                      }
                      &.icn-debate .inner > .inner-box:before {
                        background: url(#{$IMG_PATH}icon/icn_debate_w.png) left top no-repeat;
                        -webkit-background-size: contain;
                        background-size: contain;
                      }
                    }
                  } // li

                } // .courses-detail-list
              } // .courses-detail-list-outer
            } // .cell-inner

            .lesson-cell, .chapter-cell {
              //width: 30%;
            }

            .chapter-cell {
              .courses-detail-list-inner {
                ul {
                  display: none;
                }
              }
            }

            .chapter-detail-cell {
              width: 34%;
            }

            .chapter-detail-cell .cell-inner {
              background: #fff;
              padding: 30px;

              & > div {
                display: none;
              }

              .chapter-info-layout {
                .content-thumnail-info {
                  margin-bottom: 20px;
                }
                .content-had-chapter {
                      display:flex;
                      justify-content:center;
                }
                .ph {
                  text-align: center;
                  margin-bottom: 20px;
                }

                .upload {
                  margin: auto;
                  position: relative;
                  height: 194px;
                  width: 230px;
                  @media screen and (max-width : 1082px) {
                    width: 90%;
                  }

                  .ph {
                    display: block;
                    width: 100%;
                    height: 100%;
                    border: 2px solid #999;
                    .thumb {
                      margin: auto;
                      display: block;
                      width: 100%;
                      height: 100%;
                      background-repeat: no-repeat;
                      -webkit-background-size: cover;
                      background-size: cover;
                      background-position: center center;
                      color: #fff;
                      text-align: center;
                      font-size: 22px;
                      line-height: 217.5px;
                    }

                    .thumb-test-anket {
                      margin: auto;
                      display: block;
                      width: 100%;
                      height: 100%;
                      background-repeat: no-repeat;
                      -webkit-background-size: contain;
                      background-size: inherit;
                      background-position: center center;
                      color: #fff;
                      text-align: center;
                      font-size: 22px;
                      line-height: 217.5px;
                    }

                  }
                }

                .info {

                  .contents-name {
                    font-weight: bold;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }

                  table {
                    width: 100%;

                    th, td {
                      font-size: 13px;
                      border: 1px solid #ccc;
                      padding: 3px 8px;
                    }

                    th {
                      background: #eee;
                    }

                  }

                  .content-info{
                    display: inline-block;
                    width: 32%;
                    float: left;
                  }

                  .content-info:not(:first-child){
                    padding-left:5px;
                  }
                }
              } // .chapter-info-layout

              @media screen and (max-height : 850px) {

                padding: 20px;

                .ph img {
                  //width: 165px;
                }

                .chapter-detail-screen{
                  height: 80%;
                  .ps-scrollbar-y{
                    margin-left: 17px;
                  }
                }
              }

            }

            @media screen and (min-width : 1200px) {
              @media screen and (max-height : 850px) {

                .chapter-detail-cell {
                  width:34%;
                  .cell-inner>.ui-btns{
                     padding: 10px 0 !important;
                      .btn-back, .btn-submit{
                        height: 35px;
                        line-height: 35px;
                      
                    }
                  }
                }
                .chapter-detail-cell .cell-inner {
                  padding: 10px;
                  height: 100%;

                  .chapter-info-layout {
                    .content-thumnail-info {
                      margin-bottom: 20px;
                    }
                    .content-had-chapter {
                      display:flex;
                      justify-content:center;
                    }
                    display: table;
                    width: 100%;
                    table-layout: fixed;

                    .ph {
                      text-align: center;
                      margin-bottom: 0px;
                    }

                    .info {
                      padding-left: 10px;
                      .content-info:not(:first-child){
                        padding-left:0px;
                      }
                    }

                    .upload {
                      height: 133px;
                      width: 200px;

                      .ph {
                        display: block;
                        width: 100%;
                        height: 100%;

                        .thumb {
                          font-size: 14px;
                          line-height: 136px;
                        }

                      }
                    }
                  } // .chapter-info-layout

                }
              }
            }
          } // .courses-detail-layout

      }// .layout-list-course

    }

    .m-search-result {
      padding-top: 0;
      width: 840px;
      margin: auto;
    }

  }
}