@charset "UTF-8";
  .page-select-mail-sender {
          height: 100%;
    .page-inner {
        background: $background_theme_color_contents;
        height: 100%;
        .page-contents {
            height: 100%;
            width: 100%;
            margin: auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
           .form-wrap-inner{
              padding: 0 10%;
              .wrap-row{
                width: 100%;
                margin-bottom: 10px;
                div{
                  display: inline-block;
                  vertical-align: top;
                  &.course-name-cell{
                    width: 60% ;
                  }
                  &.percent-cell{
                    width: 35%;
                  }
                  &.user-name-cell{
                    width: 30%;
                  }
                  &.user-group-cell{
                    width: calc(30% - 5px);
                  }
                  &.status-cell{
                    width: calc(40% - 5px);
                  }
				  &.lesson-name-cell{
                    width: 30%;
			 	  }
				  &.chapter-name-cell{
                    width: calc(30% - 5px);
			 	  }
				  &.last-day-cell{
                    width: calc(40% - 5px);
			 	  }

                    &.course-name-cell, &.user-name-cell,&.user-group-cell{
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: pre;
                    }
                    &.lesson-name-cell, &.chapter-name-cell,&.last-day-cell{
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: pre;
                    }
                }             
              }
            }
             .m-form-search {
                 padding: 0;
                 .error {
                     margin-top: 1px;
                 }
                .form-group {
                    .inner {
                        width: 85%;
                        .item-group{
                            width: 25%;
                            margin-bottom: 10px;
                            .content{
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                display: inline-block;
                                width: 57%;
                                vertical-align: bottom;
                            }
                            @media screen and (max-width: 1240px) {
                                &.item-date-time{
                                    width : 30%;
                                }
                                &.item-condition{
                                    width : 20%;
                                }
                            }
                            .all-content{
                                display: none;
                                position: absolute;
                                z-index: 2;
                                padding: 10px;
                                width: 300px;
                                -webkit-box-sizing: border-box;
                                -moz-box-sizing: border-box;
                                box-sizing: border-box;
                                background: #333;
                                color: #fff;
                                -webkit-border-radius: 5px;
                                -moz-border-radius: 5px;
                                border-radius: 5px;
                                font-size: 12px;
                                margin-top: 5px;
                                white-space: normal;
                                word-break: break-all;
                                 &:before {
                                    background: #333;
                                    z-index: 1;
                                    @include PseudoElements();
                                    width: 10px;
                                    height: 10px;
                                    position: absolute;
                                    top: -5px;
                                    -webkit-transform: rotate(-45deg);
                                    -moz-transform: rotate(-45deg);
                                    -ms-transform: rotate(-45deg);
                                    -o-transform: rotate(-45deg);
                                    transform: rotate(-45deg);

                                }
                            }
                             .content:hover > .all-content {
                                display: block;
                            }
                        }
                        .item-group:last-child{
                            width: 100%;
                        }
                    }
                }
            }
        }

      .course-header {
        display: flex;
        padding-bottom: 5px;

        .except {
          height: 71px;
          display: none;
        }

        .result-txt {
          margin-top: 5px;
          margin-left: 10px;
          min-height: 30px;
          display: flex;
          align-items: center;
        }

      }

        .mail-search-header {
            background: #fff;
            padding: 0 20px;
            .inner {
                min-height: 34px;
                padding: 20px 0 0 0;
                position: relative;
                overflow: hidden;
                .back {
                    @include HoverOpacity();
                    margin-top: 4px;
                    position: absolute;
                    left: 0;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
                .name{
                    padding-left: 30px;
                    line-height: 30px;
                    display: inline-block;
                    cursor: default;
                    word-break: break-all;
                    width: 90%;
                }
                .btn {
                    float: right;
                    text-align: center;
                }
            }
        }

        .mail-result {
            @include search_table_f;
          margin: 0 10px;
          padding: 0;
          position: relative;
          height: 76%;
            @media screen and (max-height: 940px) {
                height : calc(74% - 10px);
            }
            @media screen and (max-height: 900px) {
                height : calc(73% - 10px);
            }
            @media screen and (max-height: 880px) {
                height : calc(72% - 10px);
            }
            @media screen and (max-height: 830px) {
                height : calc(70% - 10px);
            }
            @media screen and (max-height: 810px) {
                height : calc(69% - 10px);
            }
            @media screen and (max-height: 790px) {
                height : calc(68% - 10px);
            }
            @media screen and (max-height: 760px) {
                height : calc(67% - 10px);
            }
            @media screen and (max-height: 730px) {
                height : calc(66% - 10px);
            }
            @media screen and (max-height: 700px) {
                height : calc(65% - 10px);
            }
            @media screen and (max-height: 670px) {
                height : calc(64% - 10px);
            }
            @media screen and (max-height: 640px) {
                height : calc(63% - 10px);
            }
            @media screen and (max-height: 610px) {
                height : calc(62% - 10px);
            }
            @media screen and (max-height: 600px) {
                height : calc(61% - 10px);
            }
            .table-header{
                table-layout: auto;
                tr{
                    width: 100%;
                    position: fixed;
                    z-index: 10;
                    th{
                        padding: 5px;
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                }
                .col-check-box{
                  min-width: 45px;
                }
                .col-login-id{
                  min-width: 146px;
                }
                .col-user-name{
                  min-width: 146px;
                }
                .col-complete-percent{
                  min-width: 94px;
                }
                .col-status{
                  min-width: 160px;
                }
                .col-course-group{
                  min-width: 146px;
                }
                .col-last-access{
                  min-width: 129px;
                }
            }
            .table-result-nodata{
                height: 100%;
                tr{
                    td{
                        border: 0px solid #fafafa  !important;
                        color: #AFAFAF;
                         box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        span{
                            position: relative;
                        }
                    }
                }
            }
            .table-result{
                table-layout: auto;
                    z-index: -1;
                .col-check-box{
                  min-width: 47px;
                  width: 5%;
                }
                .col-login-id{
                  width: 17%;
                }
                .col-user-name{
                  width: 17%;
                }
                .col-complete-percent{
                  width: 11%;
                }
                .col-status{
                  min-width: 160px;
                  width: 18%;
                }
                .col-course-group{
                  width: 17%;
                }
                .col-last-access{
                  width: 15%;
                }
                tr{
                    td:not(.row-ope){
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                }
            }
            .table-result,.table-header{
                .checkbox-btn {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  label {
                    padding-left: 20px;
                    &::after {
                      top: 2px;
                      left: 15px;
                      width: 14px !important;
                      height: 14px !important;
                    }
                    &::before {
                      top: 5px;
                      left: 17px;
                      width: 11px !important;
                      height: 5px !important;
                    }
                  }
                }
            }
            .header {
                cursor: pointer;
                div {
                    display: inline-flex;
                    .img {
                        background-image: url(#{$IMG_PATH}icon/icn_pullupdown_k.png) ;
                        background-repeat: no-repeat ;
                        background-position: center ;
                        background-size: 26px;
                        width: 27px;
                        padding-left: 7px;
                    }
                }
                
            }
            .header-sort-up {
                cursor: pointer;
                div {
                    display: inline-flex;
                    .img {
                    width: 26px;
                    background-position: center ;
                    background-repeat: no-repeat ;
                    background-image: url(#{$IMG_PATH}icon/icn_pullup_k.png) ;
                    background-size: 13px;
                    padding-left: 7px;
                    }
                }
            }

            .header-sort-down {
                cursor: pointer;
                div {
                    display: inline-flex;
                    .img {
                    width: 26px;
                    background-position: center ;
                    background-repeat: no-repeat ;
                    background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png) ;
                    background-size: 13px;
                    padding-left: 7px;
                    }
                    
                }
            }
              
        }
        
        .mail-list-inner{
            height: 100%;
            background-color: #fff;
        }
        .mail-list-outer{
            width: 100%;
            height: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;}
          }
        .scroll-area {
            &.ps-active-y {
                padding-right: 16.2px;
                margin-right: -16.2px;
            }
            .ps-scrollbar-y-rail{
                right: 16.2px;
            }
            height: 100%;
        } // .scroll-area
        .form-btns{
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .btn-modal-mail-create{
          padding: 0 15px;
          width: auto;
          background-color: #FFFFFF;
          text-align: center;
          -moz-border-radius: 0px;
          border-radius: 2px;
          border: 1px solid #DC780A;
          color: #DC780A;
          font-weight: normal;
          line-height: 30px;
          height: 30px;
          font-size: 16px;
          box-sizing: border-box;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          vertical-align: middle;
      }
  }
