@charset "UTF-8";

.page-education-pack-history-management {

  .global-header {
    position: static !important;
    width: 800px;
    margin-top: 80px;
    box-shadow: none;
    border-bottom: 2px solid gray;

    span.name {
      font-size: 18px;
      opacity: 1 !important;
      color: gray !important;
    }

    span img {
      opacity: 1 !important;
    }

  }

  .page-inner {
    padding-top: 10px;
    width: 800px;
    margin: auto;

    .page-contents {
      text-align: center;

      .m-form {
        width: 800px;
        left: 0;
      }

      .inner {
        text-align: left;

        .back img {
          width: 25px;
          height: 25px;
          vertical-align: -webkit-baseline-middle;
        }

      }

      .content-box {
        display: none;
      }

      .content-box.active {
        display: block !important;
      }

      .form-row.btn-switch a {
        margin-left: 13px;
      }

      .form-row {
        width: 525px;
        margin: auto;
        text-align: left;
        margin-top: 50px;
        clear: both;

        & > span {
          padding-top: 2px;
        }

        & > input {
          height: 32px;
        }

        a {
          display: inline-block;
        }

        .attention {
          font-size: 12px;
          color: red;
        }
        
        .div-data-error {
          display: flex;
          .div-data-from-error{
            margin-left: 100px;
            width: 205px;
            .error{
              margin-top: 0px
            }
          }
          .div-data-to-error{
            margin-left: 5px;
            width: 205px;
            .error{
              margin-top: 0px
            }
          }
        }
        
        .sel {
          margin:-1px 5px 0 14px;
        }

      }

      .form-btns.btn-status {
        margin-top: 79px !important;

        .btn-gray {
          border: 1px solid #a0a0a0;
          color: #a0a0a0;
        }
      }

      .course-length {
        display: inline-block;
        margin: -1px 5px 0 5px;
        vertical-align: middle;
        max-width: 25em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .c-name-btn {
        display: inline-block;
        margin: -5px 0 0 -11px;
        vertical-align: middle;
      }

      .btn {
        font-size: 18px;
      }

      .form-btns {
        margin-top: 60px;
        margin-bottom: 30px;
      }
    }
  }

  .confirm-link-modal {
    opacity: 0;
    display: none;
  }
  
  .modal-extraction-status{
    table{ 
      tr{
         td{ 
           &.col-nodata {
               padding: 0px!important;
           }
         }
       }
     }
   }
  
  .modal-search-education-course {
    height: 100%;
    .page-inner {
        background: $background_theme_color_contents;
        width: 95%;
        height: 100%;
        .page-contents {
            width: 100%;
            height: 100%;
            margin: auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            .result-txt {
                margin-top: 5px;
                margin-left: 10px;
                min-height: 30px;
                display: flex;
                align-items: center;
            }
            .no-data {
              min-height: 85%;
              margin: 0 10px;
              background: white;
              display: flex;
              justify-content: center;
              align-items: center;
              padding-bottom: 0px !important;
              
              &.hide {
                display: none;
              }
            }
        }
        .course-result {
            @include search_table_f;
            padding: 0px !important;
	        margin: 0px 10px 0px 10px !important;
            height: 61%;
            background: white;
            position: relative;
            @media screen and (max-height: 980px) {
                height : 59%;
            }
            @media screen and (max-height: 930px) {
                height : 57%;
            }
            @media screen and (max-height: 900px) {
                height : 54%;
            }
            @media screen and (max-height: 850px) {
                height : 52%;
            }
            @media screen and (max-height: 800px) {
                height : 50%;
            }
            @media screen and (max-height: 780px) {
                height : 49%;
            }
            @media screen and (max-height: 750px) {
                height : 44%;
            }
            @media screen and (max-height: 700px) {
                height : 39%;
            }
            @media screen and (max-height: 650px) {
                height : 37%;
            }
            @media screen and (max-height: 635px) {
                height : 34%;
            }
            @media screen and (max-height: 600px) {
                height : 32%;
            }
            .table-header{
                table-layout: auto;
                 .col-name{
                    min-width: 257px;
                }
                .col-id{
                    min-width: 135px;
                }
                .col-btn{
                    min-width: 83.2px;
                }
                tr{
                    width: 100%;
                    position: fixed;
                    th{
                        padding: 5px 0;
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                }
            }
            .table-result-nodata{
                height: 100%;
                tr{
                    td{
                        border: 0px solid #fafafa;
                        color: #AFAFAF;
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        span{
                            position: relative;
                        }
                    }
                }
            }
            .table-result{
                table-layout: auto;
                .col-name{
                    min-width: 250px;
                    width: 35%;
                }
                .col-id{
                    min-width: 135px;
                    width: 10%;
                }
                .col-btn{
                    min-width: 84px;
                    width: 10%;
                }
                tr{
                    td:not(.row-ope){
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                }
            }
            table{ 
                tr:not(:first-child):hover, tr.selected,.hover{
                    .btn-submit-delete { 
                        background: url(#{$IMG_PATH}icon/icn_trash_w.png) 20px center no-repeat;
                        -webkit-background-size: 80%;
                        background-size: 80%;
                        background-position-x: 50% ;
                    }
                    .btn-submit-setting {
                        background: url(#{$IMG_PATH}icon/icn_edit_w.png) 20px center no-repeat;
                        -webkit-background-size: 80%;
                        background-size: 80%;
                        background-position-x: 50% ;
                    }
                }
            }
        } 
        .scroll-area {
            &.ps-active-y {
                padding-right: 16.2px;
                margin-right: -16.2px;
            }
            .ps-scrollbar-y-rail{
                z-index: 6;
                right: 2px;
            }
            height: 100%;
        } 
        .no-data {
            .modal-inner {
                width: 440px;
            }
        }
    }
    input.btn,
  
    .c-search{
      padding-top:0;
      padding-bottom: 0;
      top: 12%;
        .top-area{
          position: relative;
          height:30px;
          background-color: white;
          text-align: center;
          .btn-close-modal {
              position: absolute;
              top:4px;
              right: 4px;
              background: url(#{$IMG_PATH}icon/icn_close_k.png) left center no-repeat;
              background-size: 15px;
              width: 20px;
              height: 20px;
              float: right;
              cursor:pointer;
          }
          .top-title{
              line-height: 30px;
              color: #82bef0;
              font-size: 15px;
          }
        }
        .c-search-header{
          margin: 10px 20px !important;
          display: block !important;
          .row-c{
            text-align: left;
            width: 100%;
            .title{
              text-align: left;
              display: inline-block;
              &.tt-group {
                padding-left: 15px;
              }
              .group{
                background: url(#{$IMG_PATH}icon/icn_coursegroup_b.png) no-repeat;
                background-position: 0% 35%;
                -webkit-background-size: 20px;
                background-size: 20px;
              }
            }
            .c-name{
              display: inline-block;
              width: 30%;
              max-width: 250px;
              .error{
                padding-top: 2px;
                position: absolute;
                font-size: 12px;
                color: red;
                font-weight: bold;
                width: 30%;
                max-width: 270px;
              }
            }
            .c-btn{
              display: inline-block;
              .btn-c-search{
                width: 60px;
                height: 29px;
              }
            }
          }
        }
        .table-header{
          tr{
            z-index: 1000;
          }
        }
        .table-result{
            .checkbox-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              label {
                padding-left: 20px;
                &::after {
                  top: 2px;
                  left: 15px;
                  width: 14px !important;
                  height: 14px !important;
                }
                &::before {
                  top: 5px;
                  left: 17px;
                  width: 11px !important;
                  height: 5px !important;
                }
              }
            }
            .col-education-course-id{
              width: 10% !important;
            }
            .col-education-course-name{
              width: 42% !important;
            }
            .col-education-pack-name{
              width: 48% !important;
            }
        }
        .header-m-course{
          .select-all-header:hover{
            // background: #82bef0 !important;
            z-index: 1;
            color: #fff;
            cursor: pointer;
          }
          .checkbox-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            label {
              padding-left: 20px;
              &::after {
                top: 2px;
                left: 15px;
                width: 14px !important;
                height: 14px !important;
              }
              &::before {
                top: 5px;
                left: 17px;
                width: 11px !important;
                height: 5px !important;
              }
            }
          }
        }
        .ps-scrollbar-y-rail{
          z-index: 1000;
        }
      .footer-area{
          background: #fff;
          width: 100%;
          height: 30px;
          padding: 10px 0;
          text-align: center;
          .btn-modal-submit-c{
              padding: 0 15px;
              width: 10%;
              background-color: #FFFFFF;
              text-align: center;
              -moz-border-radius: 0px;
              border-radius: 2px;
              border: 1px solid #DC780A;
              color: #DC780A;
              font-weight: normal;
              line-height: 30px;
              font-size: 16px;
          }
      }
    }
  }
}
