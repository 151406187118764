.popup-info-remind {
    opacity: 1;
    display: block;
    position: absolute;
    right: 0;
    top: 49px;
    margin-right: 232px;
    background-color:white;
    .button{
        padding-top: 10px;
        padding-bottom: 10px;
        .btn-modal-clone {
                width: 107px;
                height: 32px;
                line-height: 32px;
                border: 1px solid #666;
                color: #666;
                font-weight: normal;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                border-radius: 2px;
                font-size: 16px;
                text-align: center;
        }
        .ok{
            color: #DC780A;
            
            border: 1px solid #DC780A;
        }
    }
    .text{
        text-align: center;
        padding-top: 10px;
    }
    .input{
        padding-top: 10px;
        .input-size-period{
            height: 24px;
            width: 300px;
            text-align: center;
        }
    }
    .header{
        background-color: #82bef0;
        height: 40px;
        text-align: center;
        span{
            display: inline-block;
            padding-left: 27px;
            background: url(#{$IMG_PATH}icon/icn_bell_w.png) left center no-repeat;
            -webkit-background-size: 21px;
            background-size: 21px;
            background-position-y: 9px;
            font-size: 20px;
            color: white;
            padding-top: 6px;
        }
    }
    .m-modal .modal-inner{
        padding: 0;
        .tri{
            position: absolute;
            width: 14px;
            height: 14px;
            background: white;
            right: -5px;
            top: 54px;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
          
}