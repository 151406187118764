@charset "UTF-8";

.page-content-detail {
  .content-detail-header {
    background: #fff;
    padding: 0 20px;

    .inner {
      min-height: 34px;
      padding: 20px 0;
      position: relative;
      overflow: hidden;

      .back {
        @include HoverOpacity();
        position: absolute;
        left: 0;
        display: block;
        height: 34px;
        width: 34px;
        top: 50%;
        margin-top: -17px;
        text-align: center;

        img {
          width: 20px;
          margin-top: 5px;
        }
      }
      .btn {
        float: right;
        text-align: center;
      }
    }

  }

  .page-inner {
    background: #bae3f9;
  }

  .m-form-search {
    .inner {
      width: 90%;
      table {
        width: 100%;
        td {
          vertical-align: middle;
          &.cell-icn-content {
            width: 10%;
            .icn-content-ccp {
              @include icon_content_detail;
              background-image: url(#{$IMG_PATH}icon/icn_conccp_k.png);
            }

            .icn-content-anket {
              @include icon_content_detail;
              background-image: url(#{$IMG_PATH}icon/icn_conenq_k.png);
            }

            .icn-content-test {
              @include icon_content_detail;
              background-image: url(#{$IMG_PATH}icon/icn_test_k.png);
            }

            .icn-content-doc {
              @include icon_content_detail;
              background-image: url(#{$IMG_PATH}icon/icn_file_document_k.png);
            }

            .icn-content-html {
              @include icon_content_detail;
              background-image: url(#{$IMG_PATH}icon/icn_file_html_k.png);
            }

            .icn-content-video {
              @include icon_content_detail;
              background-image: url(#{$IMG_PATH}icon/icn_video_k.png);
            }

            .icn-content-url {
              @include icon_content_detail;
              background-image: url(#{$IMG_PATH}icon/icn_url_k.png);
            }

            .icn-content-slide {
              @include icon_content_detail;
              background-image: url(#{$IMG_PATH}icon/icn_slideonly_k.png);
            }

            .icn-content-lti-external-tool {
              @include icon_content_detail;
              background-image: url(#{$IMG_PATH}icon/icn_ltiexternaltool_k.png);
            }
          }
          &.cell-label {
            width: 10%;
            min-width: 120px;
            padding-right: 10px;
            text-align: right;
          }
          &.cell-input {
              width: 35%;
          }
        }
       
      }
    }
    .form-btns {
      margin-top: 20px;
      .btn {
        height: 40px;
        line-height: 40px;
        width: 150px;
      }
    }
  }

  .selected-area {
    background: #fff;
    padding: 0 20px 0;
    .selected-area-inner {
      padding: 20px;
      width: 70%;
      margin: 0 auto;
      span {
        color: #3cb2e1;
      }
      .course-result {
        padding: 0;
      }
    }

  }

  .table-content-current {
    @include search_table_f;
    padding: 0;
    table {
      td, th {
        padding: 5px;
        border: 1px solid #c8c8c8 !important;
      }
    }
  }


  .version-area {
    background: #fff;
    padding: 20px;
    .version-row + .version-row {
      margin-top: 20px;
    }
    .content-sub-heading-blue {
      color: #3cb2e1;
      padding-left: 5px;
    }
    .content-sub-heading-gray {
      color: #6ec9c8;
      padding-left: 5px;
    }
    .line-blue {
        width: 100%;
        height: 2px;
        background: #7eafd9;
        top: 3px;
        position: relative;
    }
    .line-gray {
        width: 100%;
        height: 2px;
        background: #6ec9c8;
        top: 3px;
        position: relative;
    }
    .version-inner {
      border: 1px solid #b2b2b2;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      padding: 20px;

      .layout-table {
        .ph {
          vertical-align: top;
          width: 240px;
          text-align: center;

          & > div {
            margin-bottom: 10px;
            height: 180px;
            border: 1px solid #c8c8c8;
          }

          img {
            width: 100%;
          }
          .thumb-content-ccp {
            background: url(#{$IMG_PATH}icon/icn_conccp_k.png) center center no-repeat;
            vertical-align: middle;
            display: inline-block;
            width: 100%;
            height: 100%;
            background-position-x: 50%;
            opacity: .8;
          }
          .thumb-content-anket {
            background: url(#{$IMG_PATH}icon/icn_conenq_k.png) center center no-repeat;
            vertical-align: middle;
            display: inline-block;
            width: 100%;
            height: 100%;
            background-position-x: 50%;
            opacity: .8;
          }
          .thumb-content-test {
            background: url(#{$IMG_PATH}icon/icn_test_k.png) center center no-repeat;
            vertical-align: middle;
            display: inline-block;
            width: 100%;
            height: 100%;
            background-position-x: 50%;
            opacity: .8;
          }
          .thumb-content-doccsv {
            background: url(#{$IMG_PATH}icon/icn_filetype_csv.png) center center no-repeat;
            vertical-align: middle;
            display: inline-block;
            width: 100%;
            height: 100%;
            background-position-x: 50%;
            opacity: .8;
          }
          .thumb-content-docdoc {
            background: url(#{$IMG_PATH}icon/icn_filetype_doc.png) center center no-repeat;
            vertical-align: middle;
            display: inline-block;
            width: 100%;
            height: 100%;
            background-position-x: 50%;
            opacity: .8;
          }
          .thumb-content-docpdf {
            background: url(#{$IMG_PATH}icon/icn_filetype_pdf.png) center center no-repeat;
            vertical-align: middle;
            display: inline-block;
            width: 100%;
            height: 100%;
            background-position-x: 50%;
            opacity: .8;
          }
          .thumb-content-docppt {
            background: url(#{$IMG_PATH}icon/icn_filetype_ppt.png) center center no-repeat;
            vertical-align: middle;
            display: inline-block;
            width: 100%;
            height: 100%;
            background-position-x: 50%;
            opacity: .8;
          }
          .thumb-content-docxls {
            background: url(#{$IMG_PATH}icon/icn_filetype_xls.png) center center no-repeat;
            vertical-align: middle;
            display: inline-block;
            width: 100%;
            height: 100%;
            background-position-x: 50%;
            opacity: .8;
          }
          .thumb-content-html {
            background: url(#{$IMG_PATH}icon/icn_file_html_k.png) center center no-repeat;
            vertical-align: middle;
            display: inline-block;
            width: 100%;
            height: 100%;
            background-position-x: 50%;
            opacity: .8;
          }
          .thumb-content-video {
            background: url(#{$IMG_PATH}icon/icn_video_k.png) center center no-repeat;
            vertical-align: middle;
            display: inline-block;
            width: 100%;
            height: 100%;
            background-position-x: 50%;
            opacity: .8;
          }
          .thumb-content-url {
            background: url(#{$IMG_PATH}icon/icn_url_k.png) center center no-repeat;
            vertical-align: middle;
            display: inline-block;
            width: 100%;
            height: 100%;
            background-position-x: 50%;
            opacity: .8;
          }
        }

        .info {
          padding-left: 20px;
          table {
          width: 100%;
          text-align: center;
          td {
            padding: 5px;
            vertical-align: middle;
            text-align: center;
            word-break: break-all;
            word-wrap: break-word;
          }
          border-collapse: collapse;
          td, th {
            border: 1px solid #c8c8c8;
            width: 20%;
          }
          td:first-child , th:first-child {
            min-width: 155px;
          }
          th {
            background-color: #82BEF0;
            color: white;
            padding: 5px;
            }
          &.table-gray {
              th {
                background-color: #8cd4d3;
                color: white;
                
                }
            }

            .row-no-border {
                td, th {
                    border-bottom: 0;
                }
            }
          }
          div {
            height: 40px;
            margin-top: 10px;
            position: relative;
            .btn-submit {
              text-align: center;
              bottom: 3px;
              height: 40px;
              line-height: 40px;
            }
          }
          
        }

      }

    }

  }

  .old-version-area {
    padding: 20px;
    background: #bae3f9;
    .content-sub-heading-blue {
      color: #3cb2e1;
      padding-left: 5px;
    }
    @include search_table_f;

    table {

      .row-version {
        width: 140px;
      }
      .row-time {
        width: 80px;
      }
      .row-date {
        width: 90px;
      }
      .row-ope {
        width: 126px;
      }
      th,td {
        padding: 5px;
      }
    }
    .ta-c {
      input,a{
        vertical-align: middle;
        &.btn-submit-delete {
            background: url(#{$IMG_PATH}icon/icn_trash_k.png) 20px center no-repeat;
           -webkit-background-size: 80%;
            background-size: 80%;
            background-position-x: 50% ;
            font-weight: normal;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            font-size: 16px;
            padding: 0;
        }
        &.btn-submit-setting {
            background: url(#{$IMG_PATH}icon/icn_setting_k.png) 20px center no-repeat;
           -webkit-background-size: 80%;
            background-size: 80%;
            background-position-x: 50% ;
            font-weight: normal;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            font-size: 16px;
            padding: 0;
        }
        &.btn-play-video {
            background: url(#{$IMG_PATH}icon/icn_preview_o.png) 20px center no-repeat;
           -webkit-background-size: 80%;
            background-size: 80%;
            background-position-x: 50% ;
            font-weight: normal;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            font-size: 16px;
            padding: 0;
        }
      }

    }
  }

}