@charset "UTF-8";
.page-user-search_new {
        height: 100%;
  .m-search-c-inner {
    iframe {
      width: 100vw;
      height: 100vh;
    }
  }
    .page-inner {
        background: $background_theme_color_contents;
            height: 100%;
        .page-contents {
            width: 100%;
            height: 100%;
            margin: auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
             .m-form-search {
                    padding: 0;
                    .error {
                        margin: 1px 0 1px 0;
                    }
                .form-group {

                    .inner {
                        width: 80%;
                        tr {
                            height: 50px;
                            input{
                              height: 32px;
                            }
                        }
                        tr:last-child{
                            height: 60px;
                        }
                        .td-1 {
                            width: 10%;
                             text-align: right;
                             padding-right: 5px;
                        }
                        .td-2 {
                            width: 30%;	
                            vertical-align:top;
                            .chosen {
                                height: 32px;
                                .sel {
                                    height: 100%;
                                    width: 100%;
                                    .sel-txt {
                                        border-bottom: 13px solid transparent;
                                    }
                                    p {
                                        font-weight: normal;
                                        margin-top:8px;
                                    }
                                }
                            }
                            .status-chk {
                                display: flex;
                                position: absolute;
                                label {
                                    padding-left: 15px;
                                    justify-content: center;
                                    align-items: center;
                                }
                                .checkbox-btn {
                                    display: flex;
                                    label {
                                        padding-left: 20px;
                                        &::after {
                                            left: 15px;
                                            width: 14px !important;
                                            height: 14px !important;
                                        }
                                        &::before {
                                            top: 3px;
                                            left: 17px;
                                            width: 11px !important;
                                            height: 5px !important;
                                        }
                                    }
                                }
                            }
                        }
                        .td-3 {
                            text-align: right;
                            width: 20%;
                            padding-right: 5px;
                        }
                        .td-4 {
                            width: 30%;
                        }
                        .td-5 {
                           text-align: center;
                        }
                        
                        .td-6 {

                            width: 20%;
                            div {
                              margin-left: calc(40% - 120px);
                              display: inline-block;
                              width: 100%;
                            }
                        }
                        
                        
                        
                        .form-row{
                            .layout>*{
                                width: 100% !important; 
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
                        
        }
      .course-header {
        padding-bottom: 5px;

        .except {
          height: 71px;
          display: none;
        }

        .download {
          position: absolute;
          right: 0px;
          margin-top: 5px;
          margin-right: 10px;
          min-height: 30px;
          padding-bottom: 5px;
        }

        .result-txt {
          margin-top: 10px;
          margin-left: 10px;
          min-height: 25px;
          display: inline-block;
          align-items: center;
        }


      }

      .course-search-user-header {
            background: #fff;
            padding: 0 20px;
            .inner {
                min-height: 34px;
                padding: 20px 0;
                position: relative;
                // border-bottom: 2px solid #d1d1d1;
                overflow: hidden;
                .back {
                    @include HoverOpacity();
                    position: absolute;
                    left: 0;
                    img {
                        width: 20px;
                        height: 20px;
                        vertical-align: -webkit-baseline-middle;
                    }
                }
                .btn {
                    float: right;
                    text-align: center;
                }
                .select-upload {
                    float: right;
                    margin-right: 5px;
                    .sel {
                        background-color: white;
                        -webkit-border-radius: 0px;
                        -moz-border-radius: 0px;
                        border-radius: 0px;
                        color: #468cc8;
                        border: 1px solid;
                        
                    }
                    .sel.sel-size-auto {
                        height: 36px;
                        width: 170px !important;
                        .sel-txt {
                            background-size: 18px;
                            background-image: url(#{$IMG_PATH}icon/icn_selectbtn_bottom_b.png);
                        }
                    }
                }
            }
            
        }
        .course-result {
            @include search_table_f;
          padding: 0;
          margin: 0 10px;
            position: relative;
          height: 61%;

            @media screen and (max-height: 980px) {
                height : 59.5%;
            }
            @media screen and (max-height: 950px) {
                height : 58.4%;
            }
            @media screen and (max-height: 925px) {
                height : 57%;
            }
            @media screen and (max-height: 900px) {
                height : 55%;
            }
            @media screen and (max-height: 850px) {
                height : 53%;
            }
            @media screen and (max-height: 825px) {
                height : 51%;
            }
            @media screen and (max-height: 800px) {
                height : 50%;
            }
            @media screen and (max-height: 775px) {
                height : 48%;
            }
            @media screen and (max-height: 750px) {
                height : 47%;
            }
            @media screen and (max-height: 725px) {
                height : 43%;
            }
            @media screen and (max-height: 700px) {
                height : 41.5%;
            }
            @media screen and (max-height: 685px) {
                height : 40.5%;
            }
            @media screen and (max-height: 675px) {
                height : 39.3%;
            }
            @media screen and (max-height: 650px) {
                height : 39%;
            }
            @media screen and (max-height: 630px) {
                height : 33%;
            }
            @media screen and (max-height: 600px) {
                height : 30%;
            }


        .table-header{
            table-layout: auto;
                tr{
                    width: 100%;
                    position: fixed;
                    th{
                        padding: 5px;
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                    th:nth-child(1),th:nth-child(2),th:nth-child(3){
                    min-width: 140px;
                    }
                    th:nth-child(4){
                        min-width: 175px;
                    }
                    th:nth-child(5){
                        min-width: 141px;
                        
                    }
                    th:nth-child(6){
                        min-width: 116px;
                    }
                }
            }
          .table-result{
                table-layout: auto;
                tr{
                    td:not(.row-ope){
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                }
            }
             .course-rows {
                height: 48px !important;
                span{
                       display: inline-block;
                       padding-top: 16px;
                }
              }
            table {
                tr:not(:first-child):hover, tr.selected,.hover{
                    .btn-submit-delete { 
                        background: url(#{$IMG_PATH}icon/icn_trash_w.png) 20px center no-repeat;
                        -webkit-background-size: 90%;
                        background-size: 90%;
                        background-position-x: 50% ;
                    }
                    .btn-submit-setting {
                        background: url(#{$IMG_PATH}icon/icn_edit_w.png) 20px center no-repeat;
                        -webkit-background-size: 90%;
                        background-size: 90%;
                        background-position-x: 50% ;
                    }
                }
                td:nth-child(1),td:nth-child(2),td:nth-child(3){
                    width: 12%;
                    min-width: 140px;
                }
                tr td:nth-child(4){
                    width: 15%;
                    min-width: 175px;
                }
                tr td:nth-child(5),.group-name{
                    text-align: left;
                    width: 38.9%;
                }
                tr td:nth-child(6){
                    width: 10%;
                    min-width: 116px;
                }
              }

            .table-result-nodata{
                height: 90%;
                
                background: white;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 0px !important;
                tr{
                    td{
                        
                        color: #AFAFAF;
                    }
                }
            }
              
        }

      .course-list-inner{
        height: 99.9%;
        background-color: #fff;
      }
      .course-list-outer{
        width: 100%;
        height: 90%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }
      .scroll-area {
        &.ps-active-y {
          padding-right: 16.2px;
          margin-right: -16.2px;
        }
        .ps-scrollbar-y-rail{
          right: 16.2px;
        }
        height: 100%;
      } // .scroll-area
    }
    input.btn,
    a.btn {
        &.btn-submit-download {
            float: right;
            font-weight: initial;
            text-align: center;
            width: auto;
            padding: 6px 19px;
            color: #000;
            border: 1px solid #c8c8c8;
            margin: -3px 0;
            line-height: 22px;
            background: #fff;
            color: #6E6E6E;
            img{
              width: 20px;
              height: 20px;
              padding-right: 7px;
            }
        }
        &.btn-submit-upload {
            background: url(#{$IMG_PATH}icon/icn_upload_w.png) 20px center no-repeat;
            -webkit-background-size: 20px;
            background-size: 20px;
            color: #468cc8;
            border: 1px solid;
            background-color: white;
            font-weight: normal;
            height: 34px;
            line-height: 34px;
            text-align: center;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 0px;
            font-size: 16px;
            padding: 0;
        }
        &.btn-submit-search-new {
            background: url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
            -webkit-background-size: 20px;
            background-size: 20px;
            background-position-x: 50% ;
            color: #468cc8;
            border: 1px solid #82BEF0;
            background-color: #82BEF0;
            font-weight: normal;
            width: 130px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 5px;
            font-size: 16px;
            padding: 0;
        }
         &.btn-submit-setting {
            background: url(#{$IMG_PATH}icon/icn_edit_k.png) 20px center no-repeat;
            -webkit-background-size: 80%;
            background-size: 80%;
            background-position-x: 50% ;
            font-weight: normal;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            font-size: 16px;
            padding: 0;
        }
        &.btn-transparent {
            position: absolute;
            width: 246px;
            height: 48px;
            left: -26px;
            background: transparent;
            z-index: 9999;
        }
        &.btn-submit-delete {
            background: url(#{$IMG_PATH}icon/icn_trash_k.png) 20px center no-repeat;
           -webkit-background-size: 80%;
            background-size:80%;
            background-position-x: 50% ;
            font-weight: normal;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            font-size: 16px;
            padding: 0;
        }
       
    }
    
    // loading 
    .loader {
    position: absolute;
    left: 50%;
    top: 60%;
    z-index: -999999;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    
    }

    @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }
    /* Add animation to "page content" */
    .animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
    }

    @-webkit-keyframes animatebottom {
    from { bottom:-100px; opacity:0 } 
    to { bottom:0px; opacity:1 }
    }

    @keyframes animatebottom { 
    from{ bottom:-100px; opacity:0 } 
    to{ bottom:0; opacity:1 }
    }
}


.btns-info{
        .modal-inner.modal-inner-content{
            // margin-top: -(screen.height - this)px !important; 
            top: 50%;
            left: 50%;
            position:fixed;
            transform: translate(-50%, -50%);
        }

}
 .m-import-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, .5);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: none;
  opacity: 0;

  .m-import-modal-inner {
    width: 100%;
    height: 100%;
    position: relative;

    iframe {
      width: 100%;
      height: 100%;
    }

    .btn-close {
      position: absolute;
      display: block;
      width: 50px;
      height: 50px;
      right: 10px;
      top: 10px;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      background: #fff;
      line-height: 50px;
      text-align: center;
    }

  }

}
#userGroupNameCondition[disabled]{
    background:rgb(235, 235, 228);
    border: 1px solid #c8c8c8;
}
#userGroupIdCondition[disabled]{
    background:rgb(235, 235, 228);
    border: 1px solid #c8c8c8;
}
