@charset "UTF-8";
.page-user-new {
    .page-inner {
       
        .page-contents {
            width: 100%;
            margin: auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
             .m-form-search {
                 border-bottom: 1px solid #6e6e6e;
                 p {
                        font-size: 10px;
                        font-weight: normal !important ;
                        margin-top: 1px;
                        color: red;
                  }   
                .form-group {
                    .inner {
                        width: 80%;
                        table{
                            width: 100%;
                            tr {
                                    height: 60px;
                                    input{
                                    height: 39px;
                                    }
                                }
                               .div-1{
                                   width: 100%;
                                   min-height: 50px; 
                               }
                               .div-2{
                                   width: 35%;
                                   min-height: 50px; 
                                   float: left;
                               }
                               .div-3{
                                   width: 35%;
                                   min-height: 50px; 
                                   float: left;
                               }
                            }
                           
                            }
                            .td-1{
                                text-align: right;
                                padding-right: 5px;
                                 width: 40%;
                                  span{
                                    
                                    color: red;
                                    padding-top: 1px;
                                   
                                }
                            }
                            .td-5{
                                text-align: center;
                            }
                            .input-text{
                                width: 50%;
                            }
                            .checkbox{
                                input[type='checkbox']{
                                    vertical-align: middle;
                                }
                            }
                             .tr-1{
                                height: 20px !important;
                                
                             }
                             .td-button{
                                 text-align: right !important;
                                 
                             }
                    }
                }
                .form-btns{
                    padding-top: 20px;
                    display: flex;
                    justify-content: center;
                }
            }
        }
        .course-search-header {
            background: #fff;
            padding: 0 20px;
            .inner {
                min-height: 34px;
                padding: 20px 0;
                position: relative;
                // border-bottom: 2px solid #d1d1d1;
                overflow: hidden;
                .back {
                    @include HoverOpacity();
                    position: absolute;
                    left: 0;
                    img {
                        width: 20px;
                        height: 20px;
                        vertical-align: -webkit-baseline-middle;
                    }
                    margin-left: 28%;
                }
                span {
                    float: right;
                    margin-right: 33%;
                    color: red;
                }
                
            }
        }
      
        
    }
    input,a{
        &.btn-new-user-groups {
            background: url(#{$IMG_PATH}icon/icn_download_w.png) 20px center no-repeat;
            -webkit-background-size: 20px;
            background-size: 20px;
            color: #dc780a;
            border: 1px solid;
            font-weight: normal;
            width: 250px;
            height: 39px;
            line-height: 34px;
            text-align: center;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 4px;
            border-color: #dc780a;
            background-color: #fff;
            font-size: 16px;
            padding: 0 ;
            
        }
        &.btn-new-parents {
           
            -webkit-background-size: 20px;
            background-size: 20px;
            background-position-x:50% ;
            color: #a0a0a0;
            border: 1px solid;
            background-color: #fff;
            font-weight: normal;
            width: 100px;
            height: 39px;
            line-height: 34px;
            text-align: center;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 4px;
            font-size: 16px;
            padding: 0;
            float: right;
            margin-right: 50%;
        }
         
       
    }

/* CSS POPUP */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 99999; /* Sit on top */
    padding-top: 280px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 40px 20px;
    border: 1px solid #888;
    width: 20%;
    min-width: 450px;
    min-height: 150px;
}

.modal-content .main {
    text-align: center;
    font-size: 20px;
}

.modal-content .main p {
    margin: 5px 5px;
}

.modal-content .main img {
    max-width: 20px;
    height: 20px;
}

.modal-content .main span {
    font-size: 25px;
}

.modal-content .footer {
    margin-top: 15px;
    text-align: center;
}

.modal-content .footer > a:first-child {
    margin-right: 10px;
}
