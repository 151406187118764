@charset "UTF-8";

.c-user-course-history-header {
  background: #fff;

  .c-user-course-history-header-inner {
    position: relative;
    padding-top: 20px;

    .user-course-history-header-layout {
      height: 100%;
      min-height: 1px;

      .hide {
        display: none;
      }
      .m-form-search {
            padding-top: 10px;
        .form-btns {
          display: flex;
          justify-content: center;

          > div {
            margin: 0px;
          }
        }

        .form-group {
          
          .layout {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            .mutil-space-wrap {
              white-space: pre-wrap !important;
            }
            .multi-space-ellipsis {
              white-space: pre !important;
              overflow: hidden;
              text-overflow: ellipsis !important;
            }

          }
          .inner-resize {
            padding: 0px;
            min-width: 890px;
            margin: 0 auto;
            max-width: 1300px;

            .layout{
              &.override-flex-wrap{
                flex-wrap: wrap;

                .col3{
                  &.per{
                    min-width: 360px;
                    width: 360px;
                  }
                  &.stetus{
                    min-width: 350px;
                    width: 350px;
                  }
                  &.access{
                    min-width: 580px;
                    width: 580px;
                  }
                }
              }
            }

            .col1 {
              width: 27%;

            } //.col1

            .col-middle-2 {
              width: 7%;
            }

            .col2 {
              width: 40%;
            }

            .col-middle-3 {
              width: 1%;
            }

            .col3 {
                &.per{
                    margin-left: 0%;
                    //max-width: 21%;
                    //min-width: 21%;
                    //width: 21%;
                    max-width: 30%;
                    min-width: 30%;
                    width: 30%;
                }
                &.stetus{
                    padding-left: 2px;
                    max-width: 28%;
                    min-width: 28%;
                    width: 28%;
                }
                &.access{
                    max-width: 48%;
                    min-width: 48%;
                    width: 48%;
                }

              // &.status-check-col {
              //   @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
              //       width: 100%;
              //   }
              // }

              .inpt {
                .title {
                    min-width: 80px;
                    margin-left: 5px;
                }
                .txt {
                  &.rate {
                    min-width: 45px;

                    //hiding the up & down button inside the text box
                    input[type="number"]::-webkit-outer-spin-button,
                    input[type="number"]::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    input[type="number"] {
                        -moz-appearance: textfield;
                    }
                  }
                }


                .date-input {
                  //min-width: 100px;
                  width: 200px;
                  //IE: hiding the 'x' insite the text box
                  input[type="text"]::-ms-clear {
                      display: none;
                  }
                    .input-text{
                        //max-width: 70%;
                        max-width: 77%;
                    }
                }

                .until {
                    // margin-left: 16px;
                    font-weight: normal;
                }

              }
              
              .status-chk {
                display: flex;
                justify-content: center;
                align-items: center;

                label {
                  min-width: 42px;
                  padding-left: 15px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                .checkbox-btn {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  label {
                    padding-left: 20px;
                    &::after {
                      top: 5px;
                      left: 15px;
                      width: 14px !important;
                      height: 14px !important;
                    }
                    &::before {
                      top: 8px;
                      left: 17px;
                      width: 11px !important;
                      height: 5px !important;
                    }
                  }
                }
                // .pseudo-checkbox {
                //   @include pseudo-checkbox-f
                // }

              } //..status-chk

              .note {
                font-size: 11px;
                margin-top: -10px;
              }
            } //.col3

          } //.inner-resize

          .inner {
            padding: 0px;
          }

          .layout {
            margin-bottom: 0px;

            div {
              margin-bottom: 0px;
              * {
                height: 32px;
              }

              .inpt {
                display: flex;
                align-items: center;
                width: 100%;

                &.f-end {
                  justify-content: center;
                }

                &.flex-end {
                  //justify-content: flex-end;
                }

                &.flex-start {
                  justify-content: flex-start;
                }

                .title {
                  display: flex;
                  min-width: 85px;
                  justify-content:flex-end;
                  align-items:center;
                  padding-right: 5px;

                  &.size-l {
                    min-width: 119px;
                  }

                  &.no-width {
                    min-width: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding-left: 2px;
                  }

                }
                .c-name {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: flex;
                    align-items: center;

                    span {
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      padding-top: 10px;
                    }
                  }

                .chosen {
                  min-width: 60px;
                  width: 100%;

                  .sel {
                    width: 100%;         
                    border: 1px solid #c8c8c8;
                    -moz-border-radius: 0px;
                    border-radius: 0px;

                    p {
                      font-size: inherit;
                      font-weight: normal;
                      margin-top: 0;
                      background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
                      background-size: 12px;
                      line-height: 32px;
                      white-space: nowrap;
                      // text-overflow: ellipsis;
                      overflow: hidden;
                    }

                  }

                  select {
                    width: 100%;
                  }

                  &.catology {
                    width: 35%;
                    // font-size: 10px;

                    // p {
                    //   line-height: normal;
                    // }

                    select {
                      // word-wrap: break-word;      /* IE*/
                      // white-space: -moz-pre-space; /* Firefox */
                      // white-space: pre-wrap;      /* other browsers */
                      display:inline-block;
                      
                    }
                  }
                }

                .txt {
                  width: 100%;

                  &.maker {
                    width: 50%;
                  }

                }

                .rate {
                    //max-width: 65px;
                    max-width: 30%;
                }

                .btn-c-search {
                  width: 40px;
                  margin-left: 5px;
                }
              } //.inpt
              .user-group-name-err-div {
                height: auto;
                min-height: 20px;
                .user-group-name-err {
                  height: 100%;
                }
              }
              .div-err {
                
                height: 20px !important;

                &.size-l {
                  margin-left: 120px !important;
                }
                .error {
                  height: 100% !important;

                  &.course-name-err {
                    text-align: left;
                  }
                  &.rate-err{
                    padding-left: 90px;
                  }
                  &.last-access-err{
                    padding-left: 5px;
                  }
                  &:first-child {
                    margin-bottom: 0px !important;
                  }

                }

              }
         
            } //> div

          } //.layout 

        } //.form-group

      } //.m-form-search

    } // .user-course-history-layout

  } // .index-header-inner

} // .index-header
