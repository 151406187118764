@charset "UTF-8";


.c-courses-input-header {
  min-width: 1030px;
  .c-site-header-inner .site-flow ul li {
    width: 300px;

    &:after {
      width: 271px;
      left: 177px;
    }

    a {
      width: 130px;
      margin: auto;
    }

  }
}