@charset "UTF-8";


.modal-inner {
  &.modal-inner-external-authentication-setting {
    padding-left: 0px;
    padding-right: 0px;
    width: 750px;
    min-height: 500px;
    
    .title {
      span {
        color: #82bef0;
        font-size: 18px;
      }
    }
    .btn-download {
      padding: 2px 8px;
      width: auto;
      -webkit-background-size: 19px;
      border: 1px solid #039DD3;
      font-weight: normal;
      line-height: 26px;
      text-align: center;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 0px;
      font-size: 12px;
      background-color: #fff;
      background: none;
      color: #039DD3;
    }
    
    .m-form {
      &.m-form-external-authentication-setting {
        width: 100%;
        left: 0;
        
        .form-row {
          text-align: right;
          padding-right: 40px;
        }
        
        td#login-id-td {
          div {
          line-height: 100% ;
          }
        }
        .input-text {
          &.input-size-m {
          width: 80%;
          }
        }
        tr {
          td {
            &:first-child{
              width:30%;
              text-align: right;
            }
            &:last-child{
              text-align: left;
            }
          }
          
        }
        td#upload-td {
          vertical-align: middle;
          div {
            display: flex;
            width: 80%;
            div {
              width: 40%
            }
          }
        }
        #metaDataFileInputWrap {
          width: 80%;
          label {
            &.label-file-upload {
              position: relative;
              display: inline-block;
              width: 28%;
              height: 25px;
              padding: 3px;
              font-size: 12px;
              text-align: center;
              color: #a0a0a0;
              vertical-align: middle;
              border: 1px solid #a0a0a0;
              cursor: pointer;
              box-sizing: border-box;
              
              #metaDataUploadFile {
                display: none;
              }
              table tr th {
                  padding: 8px;
              }
              &.disabled {
                cursor: default;
                pointer-events: none;
                opacity: .3;
              }
            }
          }
          #file-upload {
            width: 70%;
            height: 25px;
            color: #a0a0a0;
            box-sizing: border-box;
            border: 1px solid #a0a0a0;
            &:disabled {
              cursor: default;
              background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
              color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
              border-color: rgba(118, 118, 118, 0.3);
            }
          }
        }
      }
    }
    
    #confirmDialog {
      .modal-inner {
        top: 30%;
      }
    
    }
  }
}
