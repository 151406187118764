@charset "UTF-8";
body{
	background: transparent;
}

.page-material-selection{
	.answer{
		display: block;
		margin-left: 20px;
		margin-right: 15px;
		max-width: 100%;
		p{
			font-weight: normal;
		}
		p:first-of-type{
			margin-top: 0;
		}
		.valid-click-range {
		  &.option-text {
		    padding-left: 1em;
		    p{
		      padding: 0;
		    }
		  }
		  &.img {
		    img {
		      cursor: pointer;
		    }
		  }
		}
	}
	.img-answer{
		margin-left: 20px;
		margin-right: 15px;
		max-width: 100%;
		p{
			font-weight: normal;
		}
		p:first-of-type{
			margin-top: 0;
		}
		.valid-click-range {
		  &.option-text {
		    padding-left: 1em;
		    p{
		      padding: 0;
		    }
		  }
		  &.img {
		    img {
		      cursor: pointer;
		    }
		  }
		}
	}
	input[type=checkbox]:not(old),
	input[type=radio   ]:not(old){
		width     : 25px;
		margin    : 0;
		padding   : 0;
		font-size : 1px;
		opacity   : 0;
	}

	input[type=checkbox]:not(old) + label,
	input[type=radio   ]:not(old) + label{
		// display      : inline-block;
		// margin-left  : -10px;
		line-height  : 19px;
		// text-overflow: ellipsis;
		// white-space: nowrap;
		// overflow: hidden;
		max-width: 100%;
	}

	input[type=checkbox]:not(old) + label > span,
	input[type=radio   ]:not(old) + label > span{
		display          : inline-block;
		width            : 16px;
		height           : 16px;
		margin           : 1px 1px 1px 1px;
		border           : 2px solid rgb(102,102,102);
		border-radius    : 10px;
		background       : none;
		vertical-align   : bottom;
		margin-right: 5px;
	}
	input[type=checkbox]:not(old) + label > span {
		border-radius    : 3px;
	}

	input[type=checkbox]:not(old):checked + label > span:before{
		content     : '✓';
		display     : block;
		width       : 12px;
		height: 12px;
		border-radius    : 3px;
		margin           : 8px 2px 2px 2px;
		color       : white;
		line-height : 2px;
		text-align  : center;
		font-weight : bold;
	}
	input[type=checkbox]:not(old):checked + label > span {
		border-color: #4285F4;
		background: #4285F4;
	}

	input[type=radio]:not(old):checked +  label > span  {
		border-color: #4285F4;
	}

	input[type=radio]:not(old):checked +  label > span > span{
		display          : block;
		width            : 10px;
		height           : 10px;
		margin           : 2px 2px 2px 2px;
		border           : 1px solid #4285F4;
		border-radius    : 5px;
		background-color : #4285F4;
	}

	.page-inner{
		top:60px;
	}

	.back img{
		width:20px;
	}

	.m-form-search{
		padding-top: 60px;
		padding-bottom: 0px;
	}

	.inner{
		border-bottom: none !important;
	}

	.text-align-center{
		text-align: center;
	}

	.m-form-search{
		.table1 {
			padding-bottom: 20px;
		}

		.ps-scrollbar-y-rail{
			right: 0px;
		}

		.table-search{
			display: table;
			width: 97%;

			.padding-20{
				padding-left:20px;
			}

			.row{
				display: table-row;
				border: none;
				.cell-1{
					display:table-cell;
					width: 1%;
				}

				.cell-3{
					display:table-cell;
					width: 3%;
				}

				.cell-5{
					display:table-cell;
					width: 5%;
				}

				.cell-6{
					display:table-cell;
					width: 6%;
				}

				.cell-10{
					display:table-cell;
					width: 10%;
				}

				.cell-15{
					display:table-cell;
					width: 15%;
				}
				.cell-12{
					display:table-cell;
					width: 12%;
				}

				.cell-19{
					display:table-cell;
					width: 19%;
				}

				.cell-20{
					display:table-cell;
					width: 20%;
				}
				.cell-21{
					display:table-cell;
					width: 21%;
				}
				.cell-22{
					display:table-cell;
					width: 22%;
				}
				.cell-30{
					display:table-cell;
					width: 30%;
				}
			}

			.row div h2{
				text-align: right;
				padding-right: 10px;
			}

			.row div {
				vertical-align: middle;
				.sel {
					height: 40px;
					p {
						font-size: 14px;
						font-weight: normal;
						margin-top: 0px;
					}
				}
			}

			.row img{
				border: 2px solid #ccc;
				padding : 0px 3.5px 3.5px 3.5px;
			}

			.input-datepicker{
				width: 81%;
			}
			.input-author{
				width: 78%;
			}
		}
	}

	.info{

		.result-label{
			padding-bottom: 3px;
			input{
				float: right;
			}

			p.sel-txt{
				font-size: 12px; 
				font-weight: normal; 
				margin-top: 0px;
			}
		}
		.scroll-area{
			height: 314px !important;
			background: white;
		}

		.message-no-data{
			color: #AFAFAF;
		}

		table.custom-content th, table.custom-content td{
			padding: 4px;
		}

		table.custom-content td:nth-child(5){
			width: 100px;
		}

		table.custom-content td:nth-child(1){
			width: 35%;
		}

		table.custom-content tr.toggleClick:hover td{
			cursor:pointer;
			background: #82BEF0 !important;
		}

		table.custom-content tr.selected td{
			background: #82BEF0 !important;
			color: #fff !important;
			z-index: 1;
		}

		table.custom-content tr.toggleClick{
			td:nth-child(4){
				text-align: left;
    			padding-left: 20px;
			}
		}

		table.custom-content tr.toggle td{
			 vertical-align: baseline;
			 background-color: white;
			 text-align: left;
			 border: none;
		}

		table.custom-content tr.toggle td:nth-child(2){
			div{
				div:nth-child(1){
				 float:left;
			 	}

				div:nth-child(2){
					padding-left: 10px;
					div:nth-child(3){
						float:left;
					}
				}
				div.image{
				    position: relative;
					padding-right: 20px;
					img {
					  max-height: 100%;
                      width: auto;
                      height: auto;
                      position: absolute;
                      left: 0;
                      right: 0;
                      top: 0;
                      bottom: 0;
                      margin: auto;
                      max-width: 100%;
                    }
				}
				div.info-ccp-content{
					padding-top:15px;
				}
			}
		}

		table.custom-content .data tr:first-child th {
			background: #d1d1d1;	
		}

		table{
			&.custom-content{
				.data {
					tr:nth-child(4n+1) > td {
						background:#FCFCFC;
					}
				}
			} 
		}

		table.custom-content .data td {
			background: #fff;
			padding:5px;
		}

		table.custom-content tr.toggle{
			.question{
				border: 1px solid #A3A1A0;
				margin-top: 10px;
				margin-bottom: 10px;
				margin-left: 20px;
				padding-bottom: 20px;

				label{
					padding-left: 10px;
					display: flex;
				}
				.question-view{
					margin: 15px;

					.question-image{
						padding-left: 30px;
						padding-right: 15px;
						img {
							display: block;
						}
						img:not(:first-of-type){
							margin-top: 10px;
						}
					}
				}
				.img-answer{
					label{
						-webkit-flex-direction: column;
						-ms-flex-direction: column;
						flex-direction: column;

						img{
							margin-top: 10px;
							margin-left: initial;
						}
					}
				}

				.question-hidden{
					margin: 15px;
					display: none;
					.question-image{
						padding-left: 30px;
						padding-right: 15px;
						img {
							display: block;
						}
						img:not(:first-of-type){
							margin-top: 10px;
						}
					}
				}

				div.answer{
					// margin-left:-20px;
				}
			}
		} 

		table.custom-content tr.toggle .rectangle{
			width:8px;
			height:8px;
			background:#6E6E6E;
			margin-top: 6px;
			margin-right: 4px;
    		margin-left: 15px;
			display: inline-block;
		}
	}

	#result{
		padding:20px;
	}

	.result-inner{
		padding-bottom: 15px !important;
	}	
	
	.btn-submit{
		text-align: center;
	}

	.content-result {
      @include search_table_f;
	  .table-header{
		tr{
			position: fixed;
			th{
				padding: 5px;
				box-sizing:border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
			}
		}
	}
    }

	.content-result{
		background:#D1EAF9 !important;
		height: 371px;
		table tr.selected td {
			background: #fcfcfc !important;
		}

		div.image{
			width: 146px;
    		height: 110px;
		}
	}

	.s-z-result {
		.ps-scrollbar-y {
			z-index: 2;
		}

		.ps-scrollbar-y-rail {
			z-index: 2;
		}
	}
	
	.btn-submit-search-new{
		background: url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
		-webkit-background-size: 20px;
		background-size: 20px;
		background-position-x: 50%;
		color: #81BDEF;
		border: 1px solid;
		background-color: #81BDEF;
		font-weight: normal;
		width: 152px;
		height: 36px;
		line-height: 36px;
		text-align: center;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 5px;
		font-size: 16px;
		padding: 0
	}

	.btn-clear-new {
		background: url(#{$IMG_PATH}icon/icn_formclear_k.png) 20px no-repeat;
		background-size: 20px;
		background-color: #FFFFFF;
		font-weight: normal;
		width: 152px;
		height: 36px;
		line-height: 36px;
		text-align: center;
		-moz-border-radius: 0px;
		border-radius: 3px;
		padding-left: 32px;
		border: 1px solid #c8c8c8;
	}

	.btn-select {
		background-size: 20px;
		background-color: #FFFFFF;
		width: 80px;
		text-align: center;
		-moz-border-radius: 0px;
		border-radius: 2px;
		border: 1px solid #A0A0A0;
		color: #6E6E6E;
		font-weight: normal;
	}

	.btn-viewer {
		background-size: 20px;
		background-color: #FFFFFF;
		width: 152px;
		height: 36px;
		line-height: 36px;
		text-align: center;
		-moz-border-radius: 0px;
		border-radius: 2px;
		border: 1px solid #A3A1A0;
		color: #A3A1A0;
		margin-left: 16px;
	}

	.btn-see-more {
		margin:auto;
		display: block;
	}

	.btn-next-page{
		display: none;
		margin:auto;
	}

	.area-btn-close {
		background: url(#{$IMG_PATH}icon/icn_close_k.png) left center no-repeat;
		background-size: 15px;
		width: 20px;
		height: 20px;
		position: absolute;
		top: 5px;
		right: 5px;
		opacity: .7;
		cursor:pointer;
	}

	.toggle{ 
		display: none; 
	}

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		.info .scroll-area{
			height: 351px !important;
		}	
	}


	@media screen and (max-width : 1366px) {
        @media screen and (max-height : 768px) {
            &.page-material-selection {
             width:100%;
             height:300px;
             }
			.m-form-search{
				padding-top: 18px;
			}
			#result{
				padding-top:0px;
			}
			.m-form-search .table1{
				padding-bottom: 3px;
			}
			.content-result{
				padding-top: 2px;
				height: 330px;
			}
			.form-btns>div{
				margin: 3px 0;
			}
			.area-btn-close{
			    padding: 0px 4px;
    			font-size: 13px;
			}
			.info .scroll-area{
				height: 274px !important;
			}
			.table-search .input-datepicker{
				margin-left: 5px;
			}
			.page-inner{
				top: 20px;
			}
        }
        }

	@media screen and (max-width : 1366px) and (-ms-high-contrast: none), (-ms-high-contrast: active){
        @media screen and (max-height : 768px) and (-ms-high-contrast: none), (-ms-high-contrast: active){
			.info .scroll-area{
				height: 312px !important;
			}
        }
    }
}
