@charset "UTF-8";
.detail_information_pc {
    .modal-inner {
        position: static;
        margin-top: 65px !important;
        width: 58%;
        height: 700px;
        min-width: 750px;
        
        @media screen and (max-height: 768px) {
            height: 540px;
        }
        .btn-back {
            @include HoverOpacity();
            background: url(#{$IMG_PATH}icon/icn_back_k.png) left center no-repeat;
            background-size: 20px;
            width: 20px;
            height: 20px;
            position: relative;
            top: -18px;
            left: 7px;
            float: left;
            
            border: 0;
        }
        .btn-modal-exit {
            position: relative;
            top: -13px;
            float: right;
            right: -6px;
            margin: 0px;
        }
        .title {
            display: block;
            color: #82bef0;
            font-size: 24px;
            cursor: default;
            
            .notice{
                display: inline-block;
                padding-left: 29px;
                background: url(#{$IMG_PATH}icon/icn_notice_detail_b.png) left center no-repeat;
                -webkit-background-size: 18px;
                background-size: 23px;
                background-position-y: 6px;
            }
            .infor{
                display: inline-block;
                padding-left: 29px;
                background: url(#{$IMG_PATH}icon/icn_infomation_detail_b.png) left center no-repeat;
                -webkit-background-size: 18px;
                background-size: 23px;
                background-position-y: 6px;
            }
        }
        .details {
            
            .date-and-from {
                height: 23px;
                text-align: left;
                font-size: 14px;
                color: #6e6e6e;
                .date {} .from {
                    float: right;
                    white-space: nowrap;
                    
                    width: 200px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    text-align: right;

                }
            }
            .notice {
                height: 38px;
                text-align: left;
                background-color: #fff;
                position: relative;
                color: #82bef0;
                border: solid #82bef0 1.5px;
                font-size: 14px;
                margin-left: auto;
                padding-left: 15px;
                p{
                  padding-top: 8px;
                }

            }
        }
        .detail-information{
          text-align: left;
          .detail-infomation-inner{
            border-bottom: 0.5px solid #777777;
            height: 472px;
            padding: 0 15px 0 15px;
            @media screen and (max-height: 768px) {
            height: 318px;
            }
            .url-externally{
              text-align: right;
              padding-right: 16px;
              max-height: 71px;
              a{
                text-decoration: underline !important;
              }
            }
            .attachments{
              text-align: right;
              padding-right: 16px;
              max-height: 71px;
              
              .icon-attachments{
                display: inline-block;
                padding-left: 17px;
                background: url(#{$IMG_PATH}icon/icn_fileattachment_k.png) left center no-repeat;
                -webkit-background-size: 14px;
                background-size: 14px;
                background-position-y: 2px;
                height: 16px;
              }
              .icon-pdf{
                display: inline-block;
                padding-left: 17px;
                background: url(#{$IMG_PATH}icon/icn_pdf_r.png) left center no-repeat;
                -webkit-background-size: 14px;
                background-size: 14px;
                background-position-y: 2px;
                height: 16px;
              }
              .icon-docx{
                display: inline-block;
                padding-left: 17px;
                background: url(#{$IMG_PATH}icon/icn_word_b.png) left center no-repeat;
                -webkit-background-size: 14px;
                background-size: 14px;
                background-position-y: 2px;
                height: 16px;
              }
              .icon-xlsx{
                display: inline-block;
                padding-left: 17px;
                background: url(#{$IMG_PATH}icon/icn_excels_g.png) left center no-repeat;
                -webkit-background-size: 14px;
                background-size: 14px;
                background-position-y: 2px;
                height: 16px;
              }
              .icon-pptx{
                display: inline-block;
                padding-left: 17px;
                background: url(#{$IMG_PATH}icon/icn_powerpoint_y.png) left center no-repeat;
                -webkit-background-size: 14px;
                background-size: 14px;
                background-position-y: 2px;
                height: 16px;
              }
              .icon-comma{
                display: inline-block;
                padding-left: 17px;
                background: url(#{$IMG_PATH}icon/icn_comma_y.png) left center no-repeat;
                -webkit-background-size: 14px;
                background-size: 14px;
                background-position-y: 2px;
                height: 16px;
              }
              .ps-scrollbar-y-rail{
                  right: 8px;
              }
            }
          }
        }
        .footer-information{
          .footer-infomation-inner{
            margin-top: 10px;
            span{
                display: inline-block;
                padding-left: 22px;
                background: url(#{$IMG_PATH}icon/icn_bell_k.png) left center no-repeat;
                -webkit-background-size: 18px;
                background-size: 18px;
                background-position-y: 3px;
                font-size: 18px;
              }
              .input-size-period{
                height: 35px;
                width: 274px;
              }
              img{
                padding-top: 2px;
              }
              .btn-submit{
                  width: 180px;
                  height: 41px;
                  line-height: 40px;
                  margin-left: 15px;
              }
          }
          .btn-area {
            padding-top: 20px;
            .prev, .next {
                @include HoverOpacity();
                vertical-align: top;
                display: inline-block;
                width: 40px;
                height: 40px;
                opacity: 0.6;
              }

              .prev {
                background: url(#{$IMG_PATH}icon/icn_arrow_back_k.png) center center no-repeat;
                -webkit-background-size: 40px auto;
                background-size: 40px auto;
                margin-right: 30px;
              }

              .next {
                background: url(#{$IMG_PATH}icon/icn_arrow_next_k.png) center center no-repeat;
                -webkit-background-size: 40px auto;
                background-size: 40px auto;
                margin-left: 30px;
              }
              .btn-gray{
                color: #a0a0a0;
                background: #fff !important;
                border: 1px solid #a0a0a0;
                line-height: 40px;
                height: 38px;
                width: 180px;
              }
          }

        }
    }
    .btn-disabled-lp021 {
        background-color: #fff !important;
        cursor: default;
        pointer-events: none;
        opacity: .2 !important;
    }
}
  .hidden-text-3dotnet{
      max-width: 94%;
      max-height: 100%;
      white-space: nowrap; 
      text-overflow: ellipsis; 
      overflow: hidden;
      position: absolute;
      -webkit-line-clamp: 1;
      
  }
#modal-zoom-img{
    .modal-inner{
        .ps-scrollbar-y-rail{
            right: 0px;
            background-color: transparent;
        }
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: 0px !important;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        .details-img{ 
            margin-top: 10px;
            div{
                height: 100%;
            }
        }
        .modal-btns{
            margin-top: -20px;
            height: 20px;
            .btn-modal-exit{
                position: relative;
                float: right;
                right: -21px;
                top: -1px;
            
        }
        }
        
    }
  }