@charset "UTF-8";
.tooltip-pro-comment {
    white-space: normal;
    position: absolute;
    display: none;
    z-index: 99;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #333;
    color: #fff;
    font-size: 12px;
    min-height: 100px;
    min-width: 300px;
    word-break: break-all;
    max-width: 300px !important;

    .content-tooltip-pro-comment {
        display: block;
        padding: 10px;
        max-height: 100%;
        .ps-scrollbar-y-rail {
            right: 1px !important;
        }
    }
    .content-tooltip-pro-text {
        display: block;
        
        .content-tooltip-pro-image {
            display: block;
            padding-top: 5px;
            width: 50px;
            height: 50px;
        }
    }

    &.leftPosition{
        .tooltip-pro-arrow{

        }
    }

} //.tooltip-pro-comment

.tooltip-pro-arrow{
    position: absolute;
    width: 0; 
    height: 0; 
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent; 
    border-right: 20px solid #333;     
    z-index: 99;
    left: -9999px;
    bottom: -9999px;
}
.col-tooltip-pro{
    .tooltip-pro-name {
        line-height: 22px;
        height: 22px;
    }

    .tooltip-pro-content{
        display: none;
        width: 0;
        overflow: hidden;
        white-space: normal;
    }
    
    .content-tooltip-pro-image {
        display: none;
    }
}