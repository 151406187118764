@charset "UTF-8";

html {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  width: 100%;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

h1, h2, h3, h4, h5, h6, form {
  font-weight: normal;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  text-align: left;
  width: 100%;
}

img {
  vertical-align: top;
  outline: none;
  border-width: 0 0 0 0;
}

br {
  letter-spacing: normal;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: " ";
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a, a:link, a:active, a:visited {
  outline: none;
}

a:focus {
  outline: none;
}

a:hover, a:active {
  outline: 0;
}

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button, input {
  line-height: normal;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
}
