@charset "UTF-8";

.page-courses-detail {
  padding: 0 5px;

  &.only-chapter {
    .page-contents {
      max-width: 1400px;
    }

    .page-contents .courses-detail-layout .chapter-detail-cel {
      width: 50%!important;
    }

    .page-contents .courses-detail-layout .chapter-detail-cell .cell-inner .chapter-info-layout .ph {
      img {
        width: 310px;
      }
    }

  }

  .page-inner {
    background: #d2ecfa;

    &.ps-active-y {
      .courses-detail-layout {
        margin-bottom: 20px;
      }
    }

    .page-contents {
      width: 100%;
      height: 100%;
      padding: 10px;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      .courses-detail-layout {
        @include LayoutTable();
        height: 100%;
        min-height: 1px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        & > div {
          @include LayoutCell(top);
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          height: 100%;
          min-height: 1px;
          padding-top: 30px;
          position: relative;
          .title {
            position: absolute;
            top: 0;
            color: #3cb2e1;
            font-size: 16px;
            margin-bottom: 5px;
          }
        }

        .cell-inner {
          height: 100%;
          min-height: 1px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          position: relative;

          // @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) { // chrome only
          //   -webkit-box-sizing: content-box;
          //   -moz-box-sizing: content-box;
          //   box-sizing: content-box;
          // }

          .ps-container.ps-active-y>.ps-scrollbar-y-rail {
            background: none;
            right: 16px!important;
          }

          .ps-container>.ps-scrollbar-y-rail {
            opacity: 0;
          }

          .ps-container:hover >.ps-scrollbar-y-rail,
          .ps-container.ps-in-scrolling >.ps-scrollbar-y-rail {
            opacity: .3;
          }

          .sc-top, .sc-btm {
            position: absolute;
            width: 100%;
            height: 10px;
            left: 0;
            display: block;
            padding-right: 30px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            z-index: 1;

            a {
              @include HoverOpacity();
              display: block;
              height: 100%;
              width: 100%;
              background-color: #d1d1d1;
            }
          }

          .sc-top {
            top: 0;
          }
          .sc-btm {
            bottom: 0;
          }

          .courses-detail-list-outer {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            .courses-detail-list {
              height: 100%;
              overflow-y: auto;
              padding-right: 15px;

              .courses-detail-list-inner {
                background: #ccc;
                min-height: 100%;
              }

              li {
                position: relative;
                border-bottom: 1px solid #ccc;
                background: #fff;

                .inner {
                }

                .inner > .inner-box {
                  @include HoverOpacity();
                  cursor: pointer;

                  position: relative;
                  display: block;
                  //padding: 3px 0 3px 3px;
                  font-size: 13px;
                  color: $font_color_default;

                  &:hover {
                    text-decoration: none;
                  }

                  & > div {
                    padding: 8px 60px 8px 42px;
                  }

                  .txt {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: 25px;
                    height: 25px;
                    font-size: 12px;
                    input {
                      width: calc(100% - 38px);
                      -webkit-box-sizing: border-box;
                      -moz-box-sizing: border-box;
                      box-sizing: border-box;
                      border:none;
                    }
                    .face-auth-moni {
                      width: calc(100% - 67px);
                    }
                  }
                }

                .inner > .inner-box:before {
                  @include PseudoElements();
                  position: absolute;
                  display: block;
                  width: 26px;
                  height: 26px;
                  left: 8px;
                  top: 50%;
                  margin-top: -13px;
                  opacity: .6;
                }

                &.icn-docs .inner > .inner-box:before {
                  background: url(#{$IMG_PATH}icon/icn_contents_no_set_k.png) left center no-repeat;
                  -webkit-background-size: contain;
                  background-size: contain;
                }
                &.icn-content-ccp .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_conccp_k.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                }
                &.icn-content-anket .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_conenq_k.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                }
                &.icn-content-test .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_test_k.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                }
                &.icn-content-doc .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_file_document_k.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                }
                &.icn-content-video .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_video_k.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                }
                &.icn-content-html .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_file_html_k.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                }
                &.icn-content-debate .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_debate_k.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                }
                &.icn-content-url .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_url_k.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                }
                &.icn-content-report .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_report_k.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                }
                &.icn-content-slide .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_slideonly_k.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                }
                &.icn-content-lti-external-tool .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_ltiexternaltool_k.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                }
                &.icn-books .inner > .inner-box:before {
                  background: url(#{$IMG_PATH}icon/icn_lesson_k.png) left center no-repeat;
                  -webkit-background-size: contain;
                  background-size: contain;
                }
                &.icn-test .inner > .inner-box:before {
                  background: url(#{$IMG_PATH}icon/icn_test_k.png) left center no-repeat;
                  -webkit-background-size: contain;
                  background-size: contain;
                }
                &.icn-debate .inner > .inner-box:before {
                  background: url(#{$IMG_PATH}icon/icn_debate_k.png) left center no-repeat;
                  -webkit-background-size: contain;
                  background-size: contain;
                }

                &.checked .inner:before {
                  @include PseudoElements();
                  position: absolute;
                  display: block;
                  width: 24px;
                  height: 24px;
                  left: 8px;
                  top: 50%;
                  margin-top: -12px;
                  background: url(#{$IMG_PATH}icon/icn_check02_b.png) left center no-repeat;
                  -webkit-background-size: contain;
                  background-size: contain;
                  z-index: 1;
                }

                &.checked .inner > .inner-box:before {
                  opacity: .3!important;
                }

                &.has-description {
                  .inner > a > div {
                    padding-right: 40px;
                  }
                }

                .btn-description {
                  @include HoverOpacity();
                  position: absolute;
                  display: block;
                  right: 35px;
                  top: 50%;
                  margin-top: -10px;
                  width: 20px;
                  height: 20px;
                  z-index: 1;
                  -webkit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  background: url(#{$IMG_PATH}icon/icn_docs_b.png) left top no-repeat;
                  -webkit-background-size: 100% 100%;
                  background-size: 100% 100%;

                  &.empty {
                    background: url(#{$IMG_PATH}icon/icn_docsnull_b.png) left top no-repeat;
                    -webkit-background-size: 100% 100%;
                    background-size: 100% 100%;
                  }

                  &.none {
                    background: url(#{$IMG_PATH}icon/icn_docsnone_b.png) left center no-repeat;
                    -webkit-background-size: 100% 100%;
                    background-size: 100% 100%;
                  }

                }

                .btn-remove {
                  @include HoverOpacity();
                  position: absolute;
                  display: block;
                  right: 10px;
                  top: 50%;
                  margin-top: -10px;
                  width: 20px;
                  height: 20px;
                  z-index: 1;
                  -webkit-border-radius: 100%;
                  -moz-border-radius: 100%;
                  border-radius: 100%;
                  -webkit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  border: 2px solid #ccc;

                  span {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 100%;
                    -webkit-border-radius: 100%;
                    -moz-border-radius: 100%;
                    border-radius: 100%;

                    &:after, &:before {
                      @include PseudoElements();
                      position: absolute;
                      left: 50%;
                      top: 50%;
                      display: block;
                      width: 2px;
                      height: 12px;
                      margin-left: -1px;
                      margin-top: -6px;
                      background: #aaa;
                    }

                    &:before {
                      -webkit-transform: rotate(-45deg);
                      -moz-transform: rotate(-45deg);
                      -ms-transform: rotate(-45deg);
                      -o-transform: rotate(-45deg);
                      transform: rotate(-45deg);
                    }

                    &:after {
                      -webkit-transform: rotate(45deg);
                      -moz-transform: rotate(45deg);
                      -ms-transform: rotate(45deg);
                      -o-transform: rotate(45deg);
                      transform: rotate(45deg);
                    }

                  }
                }

                .description {
                  position: absolute;
                  right: 20px;
                  top: 50%;
                  margin-top: -8px;
                  a {
                    @include HoverOpacity();
                    opacity: .4;
                    display: block;
                    width: 16px;
                    height: 16px;
                    background: url(#{$IMG_PATH}icon/icn_infomation02_k.png) left center no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                  }

                }

                &.selected {
                  //.inner:after {
                  //  @include PseudoElements();
                  //  position: absolute;
                  //  right: -15px;
                  //  top: 50%;
                  //  margin-top: -18px;
                  //  display: block;
                  //  height: 36px;
                  //  width: 15px;
                  //  background: url(#{$IMG_PATH}common/balloon_list.png) left top no-repeat;
                  //  -webkit-background-size: contain;
                  //  background-size: contain;
                  //}

                  .inner .inner-box {
                    color: #fff;
                    & > div {
                      background: #5fadda;
                    }

                    &:before {
                      opacity: 1;
                    }

                    &:hover {
                      opacity: 1;
                    }

                  }


                  .btn-description {
                    background: url(#{$IMG_PATH}icon/icn_docs_w.png) left top no-repeat;
                    -webkit-background-size: 100% 100%;
                    background-size: 100% 100%;

                    &.empty {
                      background: url(#{$IMG_PATH}icon/icn_docsnull_w.png) left top no-repeat;
                      -webkit-background-size: 100% 100%;
                      background-size: 100% 100%;
                    }

                    &.none {
                      background: url(#{$IMG_PATH}icon/icn_docsnone_w.png) left top no-repeat;
                      -webkit-background-size: 100% 100%;
                      background-size: 100% 100%;
                    }

                  }

                  .btn-remove {
                    @include HoverOpacity();
                    border: 2px solid #fff;

                    span {
                      &:after, &:before {
                        background: #fff;
                      }

                    }
                  }

                  .description {
                    a {
                      opacity: 1;
                      background: url(#{$IMG_PATH}icon/icn_infomation02_w.png) left center no-repeat;
                      -webkit-background-size: contain;
                      background-size: contain;
                    }
                  }

                  &.icn-docs .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_contents_no_set_w.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                  }
                  &.icn-content-ccp .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_conccp_w.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                  }
                  &.icn-content-anket .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_conenq_w.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                  }
                  &.icn-content-test .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_test_w.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                  }
                  &.icn-content-doc .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_file_document_w.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                  }
                  &.icn-content-video .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_video_w.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                  }
                  &.icn-content-html .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_file_html_w.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                  }
                  &.icn-content-debate .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_debate_w.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                  }
                  &.icn-content-url .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_url_w.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                  }
                  &.icn-content-report .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_report_w.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                  }
                  &.icn-content-slide .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_slideonly_w.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                  }
                  &.icn-content-lti-external-tool .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_ltiexternaltool_w.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                  }
                  &.icn-books .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_lesson_w.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                  }
                  &.icn-test .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_test_w.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                  }
                  &.icn-debate .inner > .inner-box:before {
                    background: url(#{$IMG_PATH}icon/icn_debate_w.png) left top no-repeat;
                    -webkit-background-size: contain;
                    background-size: contain;
                  }

                  .btn-up {
                    background-image: url(#{$IMG_PATH}icon/icn_pullup_w.png);
                  }
                  .btn-down {
                    background-image: url(#{$IMG_PATH}icon/icn_pulldown_w.png);
                  }
                  .btn-block-chapter {
                    background-image: url(#{$IMG_PATH}icon/icn_premise_base_w.png);
                  }
                  .icn-face-auth {
                    background-image: url(#{$IMG_PATH}icon/icn_face_certification_w.png);
                  }
                  .icn-face-moni {
                    background-image: url(#{$IMG_PATH}icon/icn_monitoring_w.png);
                  }
                }

                // btn up down
                .btn-up, .btn-down, .btn-block-chapter,.icn-face-auth,.icn-face-moni {
                  @include HoverOpacity();
                  position: absolute;
                  display: block;
                  width: 15px;
                  height: 15px;
                  z-index: 1;
                  -webkit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  
                }

                .btn-up {
                  background-position: center;
                  background-repeat: no-repeat;
                  background-image: url(#{$IMG_PATH}icon/icn_pullup_k.png);
                  background-size: 15px;
                  right: 60px;
                  top: 59%;
                  margin-top: -10px;
                }
                .btn-down {
                  background-position: center;
                  background-repeat: no-repeat;
                  background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
                  background-size: 15px;
                  right: 75px;
                  top: 50%;
                  margin-top: -10px;
                }
                .btn-block-chapter {
                  background-position: center;
                  background-repeat: no-repeat;
                  background-image: url(#{$IMG_PATH}icon/icn_premise_base_k.png);
                  background-size: contain;
                  width: 26px;
                  height: 26px;
                  left: 8px;
                  top: 50%;
                  margin-top: -13px;
                }

                .icn-face-auth {
                  background-position: center;
                  background-repeat: no-repeat;
                  background-image: url(#{$IMG_PATH}icon/icn_face_certification_k.png);
                  background-size: contain;
                  width: 26px;
                  height: 26px;
                  right: 95px;
                  top: 50%;
                  margin-top: -13px;
                }

                .icn-face-moni {
                  background-position: center;
                  background-repeat: no-repeat;
                  background-image: url(#{$IMG_PATH}icon/icn_monitoring_k.png);
                  background-size: contain;
                  width: 26px;
                  height: 26px;
                  right: 95px;
                  top: 50%;
                  margin-top: -13px;
                }

                .premise-off {
                  display: none;
                }
              } // li

            } // .courses-detail-list
          } // .courses-detail-list-outer

          .btn-clone {
            text-align: center;
            border-bottom: 1px solid #ccc;
            background: #d8d8d8;
            line-height: 30px;
            @include HoverOpacity();

            a {
              display: block;
              padding: 10px;
              position: relative;
              color: #fff;
              text-decoration: none;
              font-size: 15px;
            }

            span {
              position: absolute;
              right: 10px;
              top: 10px;
              background: #fff url(#{$IMG_PATH}icon/icn_selectbtn_plus_b.png);
              -webkit-background-size: 100% 100%;
              background-size: 100% 100%;
              width: 30px;
              height: 30px;

              display: block;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              border-radius: 50%;

            }
          }

          .no-lesson {
            background: #d8d8d8;
            text-align: center;
            color: #fff;

            label {
              display: block;
              line-height: 30px;
              padding: 10px;
            }

            input {
              margin-right: 5px;
              vertical-align: middle;
            }
          }

        } // .cell-inner

        .lesson-cell, .chapter-cell {
          //width: 30%;
        }

        .chapter-cell {
          .courses-detail-list-inner {
            ul {
              display: none;
            }
          }
        }

        .chapter-detail-cell {
          width: 40%;
        }

        .chapter-detail-cell .cell-inner {
          background: #fff;
          padding: 30px;

          & > div {
            display: none;

            &.ui-btns {
              margin-bottom: 0px;
              background: #d2ecfa;
              position: absolute;
              bottom: 0;
              left: 0;
              display: block;
              width: 100%;
              // margin: 0;
              padding: 10px 0;

              a {
                width: 40%;
              }
            }
          }

          .chapter-info-layout {

            .ph {
              text-align: center;
              // margin-bottom: 20px;
            }

            .upload {
              margin: auto;
              position: relative;
              //background: #d8d8d8;
              height: 217.5px;
              width: 290px;
              border: 2px solid #999;

              .ph {
                display: block;
                width: 100%;
                height: 100%;

                .thumb {
                  margin: auto;
                  display: block;
                  width: 100%;
                  height: 100%;
                  background-repeat: no-repeat;
                  -webkit-background-size: cover;
                  background-size: cover;
                  background-position: center center;
                  color: #fff;
                  text-align: center;
                  font-size: 22px;
                  line-height: 217.5px;
                  &.slide {
                    -webkit-background-size: contain;
                    background-size: contain;
                  }
                }

                .thumb-test-anket {
                  margin: auto;
                  display: block;
                  width: 100%;
                  height: 100%;
                  background-repeat: no-repeat;
                  -webkit-background-size: contain;
                  background-size: inherit;
                  background-position: center center;
                  color: #fff;
                  text-align: center;
                  font-size: 22px;
                  line-height: 217.5px;
                }

              }

              .btn-upload {
                position: absolute;
                right: 0;
                bottom: 0;
                overflow: hidden;
                padding: 10px;

                input[type="file"] {
                  position: absolute;
                  left: -9999px;
                  top: -9999px;
                }

                label {
                  @include HoverOpacity();
                  background: #fff url(#{$IMG_PATH}icon/icn_selectbtn_plus_b.png);
                  -webkit-background-size: 100% 100%;
                  background-size: 100% 100%;
                  width: 38px;
                  height: 38px;
                  display: block;

                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%;
                  border-radius: 50%;

                }
              }

            }

            .info {
              margin-top: 10px;
              margin-bottom: 10px;
              text-align: left;

              .contents-name {
                font-weight: bold;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }

              table {
                width: 100%;

                th, td {
                  font-size: 13px;
                  border: 1px solid #ccc;
                  padding: 3px 8px;
                }

                th {
                  background: #eee;
                }

              }

              .content-info{
                display: inline-block;
                width: 23%;
                float: left;
                padding-right:5px;
              }
              .content-waiting {
                color: red;
                text-align: center;
                width: 100%;
                padding-top: 5px;
                padding-bottom: 5px;
                float: left;
              }

              // .content-info:not(:first-child){
              //   padding-right:5px;
              // }
            }

            .ui-btns {
              margin: 5px 0;
            }
            .ui-three-btn {
              display: flex;
              justify-content: center;
              .content-info {
                width:calc((100% - 10px) / 3);
                
              }
            }
            .ui-four-btn {
              .content-info {
                width:23%;
              }
            }
            
          } // .chapter-info-layout

        }

        // @media screen and (max-height : 850px) and (-ms-high-contrast: none), (-ms-high-contrast: active){
        //   .chapter-detail-screen{
        //     height: calc(100% - 170px) ;
        //     .ps-scrollbar-y{
        //         margin-left: 17px;
        //       }
        //   }
        // }

        @media screen and (max-width : 1280px) {
          @media screen and (max-height : 1024px) {
            .chapter-detail-cell .cell-inner {
              .chapter-detail-screen{
                height: calc(100% - 35px);
                min-height: 380px;
                .ps-scrollbar-y{
                  margin-left: 17px;
                }
              }
              .chapter-info-layout {
                .info {
                  .ui-two-btn {
                    .content-info {
                      width:45%;
                    }
                  }
                  .ui-three-btn {
                    display: flex;
                    justify-content: center;
                    .content-info {
                      width: calc(33% - 5px);
                      margin:0px;
                    }
                  }
                  .ui-four-btn {
                    .content-info {
                      width:45%;
                      margin:3px;
                    }
                  }
                }
              }
            }
          }
        }

        //▼ 5.0_98662
        @media screen and (max-height : 820px) {
          @media screen and (min-width : 1280px) {
            .chapter-detail-cell {
              .cell-inner>.ui-btns{
                  padding: 10px 0 !important;
                  .btn-back, .btn-submit{
                    height: 35px;
                    line-height: 35px;
                  
                }
              }
            }
            .chapter-detail-cell .cell-inner {
              padding: 10px;
              min-height: 250px;

              .chapter-info-layout {
                display: table;
                width: 100%;
                table-layout: fixed;

                & > div {
                  vertical-align: top;
                  display: table-cell;
                }

                .ph {
                  text-align: center;
                  margin-bottom: 0px;
                  
                }
                .ph-img-chapter {
                  width:210px;
                }

                .info {
                  padding-left: 10px;
                  .ui-two-btn,.ui-three-btn,.ui-four-btn  {
                    display: block;
                    justify-content: initial;
                    .content-info {
                      width:99%;
                      margin:0px;
                    }
                  }
                  .ui-btns {
                    div, a, input {
                      width: 100%;
                      display: block;
                    }

                    a, input {
                      margin-bottom: 5px;
                    }
                  }

                  .content-info:not(:first-child){
                    padding-left:0px;
                  }
                }

                .upload {
                  height: 133px;
                  width: 200px;

                  .ph {
                    display: block;
                    width: 100%;
                    height: 100%;

                    .thumb {
                      font-size: 14px;
                      line-height: 136px;
                    }

                  }

                  .btn-upload {
                    padding: 10px;

                    label {
                      width: 26px;
                      height: 26px;

                      &:after, &:before {
                        margin-left: -2px;
                        margin-top: -9px;
                        width: 4px;
                        height: 18px;
                      }

                    }
                  }


                }

              } // .chapter-info-layout

            }
          }
        }
        //▲ 5.0_98662

        @media screen and (max-width : 1366px) {
          @media screen and (max-height : 768px) {
            .chapter-detail-cell {
              .cell-inner>.ui-btns{
                 padding: 10px 0 !important;
                  .btn-back, .btn-submit{
                    height: 35px;
                    line-height: 35px;
                  
                }
              }
            }
            .chapter-detail-cell .cell-inner {
              padding: 10px;
              min-height: 250px;

              .chapter-info-layout {
                display: table;
                width: 100%;
                table-layout: fixed;

                & > div {
                  vertical-align: top;
                  display: table-cell;
                }

                .ph {
                  text-align: center;
                  margin-bottom: 0px;
                  
                }
                .ph-img-chapter {
                  width:210px;
                }

                .info {
                  padding-left: 10px;
                  .ui-two-btn,.ui-three-btn,.ui-four-btn  {
                    display: block;
                    justify-content: initial;
                    .content-info {
                      width:99%;
                      margin:0px;
                    }
                  }
                  .ui-btns {
                    div, a, input {
                      width: 100%;
                      display: block;
                    }

                    a, input {
                      margin-bottom: 5px;
                    }
                  }

                  .content-info:not(:first-child){
                    padding-left:0px;
                  }
                }

                .upload {
                  height: 133px;
                  width: 200px;

                  .ph {
                    display: block;
                    width: 100%;
                    height: 100%;

                    .thumb {
                      font-size: 14px;
                      line-height: 136px;
                    }

                  }

                  .btn-upload {
                    padding: 10px;

                    label {
                      width: 26px;
                      height: 26px;

                      &:after, &:before {
                        margin-left: -2px;
                        margin-top: -9px;
                        width: 4px;
                        height: 18px;
                      }

                    }
                  }


                }

              } // .chapter-info-layout

            }
          }
        }

         // @media screen and (min-width : 1200px)  and (-ms-high-contrast: none), (-ms-high-contrast: active){
         //  @media screen and (max-height : 850px)  and (-ms-high-contrast: none), (-ms-high-contrast: active){
         //    .chapter-detail-cell{
         //      .cell-inner{
         //        height: 261px;
         //      }
         //    } 
         //  }
         // }

        @media screen and (min-width : 1280px) {
          @media screen and (min-height : 820px) {
            .chapter-detail-screen{
              height: calc(100% - 35px);
              min-height: 380px;
            }
          }
        }

        @media screen and (max-width : 1280px) {
          @media screen and (min-height : 1024px) {
            .chapter-detail-screen{
              height: calc(100% - 35px);
              min-height: 380px;
            }
          }
        }

      } // .courses-detail-layout

    } // .page-contents
  } // .page-inner

  .description-balloon {
    position: absolute;
    width: 570px;
    z-index: 2;
    background: rgba(0, 0, 0, .9);
    color: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px;
    font-size: 12px;
    display: none;

    .description-balloon-inner {
      position: relative;

      & > div {
        display: none;
        max-height: 150px;
        padding-right: 20px;
        margin-right: -10px;
        overflow-y: auto;
      }

      .tri {
        position: absolute;
        bottom: -40px;
        left: 50%;
        margin-left: -80px;
        display: block;
        width: 20px;
        height: 20px;
        overflow: hidden;

        &:after {
          @include PseudoElements();
          width: 12px;
          height: 12px;
          margin: -6px auto 0;
          background: rgba(0, 0, 0, .9);
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      .close {
        @include HoverOpacity();
        display: block;
        width: 14px;
        height: 14px;
        position: absolute;
        right: -14px;
        top: -14px;

        div {
          position: relative;
          width: 100%;
          height: 100%;

          span {
            position: absolute;
            top: 50%;
            margin-top: -1px;
            display: block;
            width: 100%;
            height: 2px;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
            background: #fff;

            &.bar-01 {
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
            }
            &.bar-02 {
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(-45deg);
            }
          }
        }
      }
    }

  }

} // .page-courses-detail


.m-description-popup {
  display: none;
  position: fixed;
  left: 388px;
  top: 370px;
  width: 200px;
  height: 40px;
  border: 2px solid $theme_color_default;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background: #fff;
  text-align: center;
  z-index: 1;

  .tri {
    position: absolute;
    bottom: -7px;
    left: 10px;
    width: 10px;
    height: 10px;
    background: #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 2px solid $theme_color_default;
    border-right: 2px solid $theme_color_default;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .make-description, .none-description{
    display: inline-block;
    padding-left: 25px;
    margin: 0 8px;
    line-height: 40px;
  }

  .make-description {
    background: url(#{$IMG_PATH}icon/icn_docs_b.png) left center no-repeat;
    -webkit-background-size: 20px auto;
    background-size: 20px auto;
  }

  .none-description {
    background: url(#{$IMG_PATH}icon/icn_docsnone_b.png) left center no-repeat;
    -webkit-background-size: 20px auto;
    background-size: 20px auto;
  }

  .btn-close{
    position: absolute;
    right: 0;
    top: 0;
  }

  .disabled{
    cursor: default;
    opacity: .3;
  }
}
#ccm021Form {
  #jsConfirmCourse {
    .msg-comp {
      max-height: 250px;
      &.ps-active-y {
          padding-right: 18.2px;
          margin-right: -16.2px;
      }
    }
  }
}

#sessionOverride{
  #moveManageCourseBtn{
    width: auto;
    padding: 0 10px;
  }
}
