@charset "UTF-8";

// ---------------------------------------------------------------
//
//  Import Settings
//
// ---------------------------------------------------------------

@import "settings/define";
@import "settings/view";
@import "settings/color";
@import "settings/font";

// ---------------------------------------------------------------
//
//  Import Core
//
// ---------------------------------------------------------------

@import "../core/class";
@import "../core/function";
@import "../core/snippet";

//  Base Style
// ---------------------------------------------------------------

@import "base/mixin";
@import "base/reset";
@import "base/base";
@import "base/layout";

@import "base/ccp";

//  Js libs
// ---------------------------------------------------------------

@import "js_libs/perfect-scrollbar";
@import "js_libs/jquery.datetimepicker";

//  Common
// ---------------------------------------------------------------

@import "common/sts_notice";
@import "common/search_table";
@import "common/search_table_f";
@import "common/icon_content_detail";


//  Globals
// ---------------------------------------------------------------

@import "globals/global_header";
@import "globals/global_menu";
//{globals}

//  Components
// ---------------------------------------------------------------

@import "components/no_login_header";
@import "components/site_header";
@import "components/group_header";
@import "components/courses_input_header";
@import "components/c_input_header_detail";
@import "components/courses_detail_header";
@import "components/courses_summary_header";
@import "components/course_user_history_header";
@import "components/user_course_history_header";
@import "components/course_chapter_history_header";
@import "components/user_chapter_history_header";
@import "components/courses_chapters_attend_each_user_header";
@import "components/group_header";
@import "components/user_courses_header";
@import "components/courses_user_header";
@import "components/user_assign_header";
@import "components/role_user";
@import "components/information_new_header";
@import "components/information_remind";
@import "components/report_score_list_header";
@import "components/tooltip_pro";
//{components}


//  Modules
// ---------------------------------------------------------------

@import "modules/page_heading_area";
@import "modules/lst_course";
@import "modules/form";
@import "modules/modal";
@import "modules/modal_new";
@import "modules/search-result";
@import "modules/contents-list";
@import "modules/chapter_modal";
@import "modules/modal_export";
@import "modules/modal_search_course";
@import "modules/modal_search_user";
@import "modules/modal_search_mutil_group";
@import "modules/modal_search_mutil_user";
@import "modules/lst_information";
@import "modules/chapter_setting";
@import "modules/lst_report_download";
@import "modules/modal_face_reg_ref";
@import "modules/modal_face_reg_ref_eng";
@import "modules/modal_search_course_face";
@import "modules/modal_search_user_face";
@import "modules/modal_face_cer_ref";
@import "modules/modal_face_wat_ref";
@import "modules/modal_face_wat_status";
@import "modules/modal_extraction_status";
@import "modules/modal_external_authentication";
@import "modules/modal_search_exceptional_user";
@import "modules/modal_lti_external_tool_search";
@import "modules/modal_video_play_count";
@import "modules/modal_mail_body";
@import "modules/modal_test_score_histogram";
//{modules}

//  Parts
// ---------------------------------------------------------------

@import "parts/input";
@import "parts/select";
@import "parts/textarea";
@import "parts/btn";
@import "parts/pager";
@import "parts/icon";
//@import "parts/item_list";
@import "parts/heading";
//@import "parts/common_font";
@import "parts/progress_bar";
//{parts}

//  Pages
// ---------------------------------------------------------------

@import "pages/login";
@import "pages/forgot";
@import "pages/index";
@import "pages/site";
@import "pages/site_usage";
@import "pages/site_usage_check";
@import "pages/courses_search";
@import "pages/users_search_new";
@import "pages/user_edit";
@import "pages/user_role";
@import "pages/user_conf";
@import "pages/content_approval";
@import "pages/collective_approval";
@import "pages/content_search";
@import "pages/content_step02";
@import "pages/user_search";
@import "pages/error";
@import "pages/group_search";
@import "pages/group_step1";
@import "pages/group_step2";
@import "pages/group_step3";
@import "pages/group_user_search";
@import "pages/group_course_search";
@import "pages/grade_evaluate_import";
@import "pages/grade_evaluate_setting";
@import "pages/user_group_new";
@import "pages/user_new";
@import "pages/courses_input";
@import "pages/modal_import";
@import "pages/courses_lesson_chapter";
@import "pages/courses_lesson_detail";
@import "pages/courses_chapter_detail";
@import "pages/content_detail";
@import "pages/courses_summary";
@import "pages/debate_grading";
@import "pages/user_course_history_summary";
@import "pages/notice_management";
@import "pages/notice_detail";
@import "pages/courses_history_top";
@import "pages/user_courses";
@import "pages/courses_user";
@import "pages/user_group_vs_user";
@import "pages/user_vs_user_group";
@import "pages/_material_selection";
@import "pages/_list_approval";
@import "pages/courses_chapters_attend_each_user";
@import "pages/courses_management_top";
@import "pages/user_management_top";
@import "pages/modal_report_csv_content_confirmation";
@import "pages/modal_import";
@import "pages/modal_import1";
@import "pages/modal_import2";
@import "pages/modal_import3";
@import "pages/modal_admin_import";
@import "pages/modal_admin_import2";
@import "pages/course_group_vs_course";
@import "pages/user_assign";
@import "pages/role_vs_users";
@import "pages/roles_search";
@import "pages/information_new";
@import "pages/lti_setting_uri";
@import "pages/site_permission_IP";
@import "pages/user_permission_IP_1";
@import "pages/user_permission_IP_2";
@import "pages/portal";
@import "pages/page_multi_courses_search";
@import "pages/mail_confirm_target_user";
@import "pages/mail_reservation_list";
@import "pages/mail_input";
@import "pages/mail_select_mail_sender";
@import "pages/password_reset_user_creation";
@import "pages/content_document";
@import "pages/site_management_top";
@import "pages/course_user_history";
@import "pages/report_scoring_detail";
@import "pages/report_score_list";
@import "pages/import_debate";
@import "pages/face_management_top";
@import "pages/face_reg_ref";
@import "pages/face_cer_ref";
@import "pages/face_wat_ref";
@import "pages/all_history";
@import "pages/all_history_extraction";
@import "pages/all_history_extraction_error";
@import "pages/history_download";
@import "pages/modal_admin_import1";
@import "pages/lti_external_tool_v1p3_edit";
@import "pages/lti_external_tool_v1p3_search";
@import "pages/ccm094";
@import "pages/break_information";
@import "pages/test";
@import "pages/log_search_sign_in";
@import "pages/correct_answer";
@import "pages/_screen_operation_log_search";
@import "pages/education_pack_history_management";
@import "pages/ccm112";
@import "pages/education_pack_management_top";
//{pages}


//  Temp
// ---------------------------------------------------------------
@import "temp/temp";
//{Temp}