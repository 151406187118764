@charset "UTF-8";
.page-site-usage {
    height: 100%;
    .page-inner {
        background: $background_theme_color_contents;
        height: 100%;
        .page-contents {
            width: 100%;
            height: 100%;
            margin: auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
             .m-form-search {
                .back {
                    @include HoverOpacity();
                    position: absolute;
                    padding: 0px 0 0 20px;
                    float: right;
                    text-align: center;
                    img {
                        padding-top: 12px;
                        width: 20px;
                        height: 20px;
                        vertical-align: -webkit-baseline-middle;
                    }
                }
                 padding: 0;
                 .error {
                     margin: 1px 0 1px 0;
                 }
                .form-group {
                    .inner {
                        .item-name{
                            text-align: right;
                            padding-right: 7px;
                            width: 11%;
                        }
                        .tbl-search{
                            margin: 0 auto;
                            margin-top: 20px;
                            width: 93%;
                            @media screen and (max-width: 1280px) {
                                width: 98%;
                            }
                            .item-input{
                                width: 40%;
                            }
                            .td-button-clear{
                                text-align: right;
                            }
                            .td-button-search{
                                text-align: left;
                            }
                        }
                        .tbl-child{
                            width: 100%;
                            tr{
                                width: 79px;
                            }

                            td{
                                 width: 30%;
                            }
                            .item-name{
                                width: 10%;
                            }

                        }
                        .btn-clear, .btn-submit-search-new{
                            margin-bottom: 15px;
                        }
                        .btn-submit-search-new{
                            margin-left: 22px;
                        }

                        .sel{
                            .sel-txt{
                                margin-top: 0;
                                font-weight: normal !important ;
                            }
                            height: 39px;
                            @media screen and (max-width: 1920px) {
                                width: 298px;
                            }
                            @media screen and (max-width: 1280px) {
                                width: 203px;
                            }
                            @media screen and (max-width: 1366px) {
                                width: 203px;
                            }
                        }

                        .group-checkbox{
                            border: 1px solid #c8c8c8;
                            height: 37px;
                            padding-left: 12px;
                            padding-top: 2px;
                        }

                        .lbl-only-display{
                            white-space: nowrap;
                            width: 90%;
                        }
                        .padding-checkbox{
                            padding-left: 6px;
                        }
                        .cb-horizontal{
                            width: 49%;
                            float: left;
                        }
                        width: 100%;
                        tr {
                            height: 67px;
                            input{
                              height: 39px;
                            }
                        }
                        .check-input{
                            height: 83px;
                        }
                        .no-check{
                            height: 55px;
                        }
                        tr:last-child{
                            height: 50px;
                        }
                        .form-row{
                            .layout>*{
                                margin-bottom: 0;
                                float: none;
                            }
                        }
                    }
                }
            }
            input,a{
                vertical-align: middle;
            }
        }

        .site-header {
          display: flex;
          padding-bottom: 5px;
          
          .except {
            height: 71px;
            display: none;
          }
          .download {
            position: absolute;
            right: 0px;
            margin-top: 5px;
            margin-right: 10px;
            min-height: 30px;
            padding-bottom: 5px;
          }

          .result-txt {
            margin-top: 5px;
            margin-left: 10px;
            min-height: 30px;
            display: flex;
            align-items: center;
          }

        }
        
        .site-result {
            @include search_table_f;
            padding: 0px !important;
            margin-right: 10px;
            margin-left: 10px;
            height: 61%;
            position: relative;
            @media screen and (max-height: 980px) {
                height : 59%;
            }
            @media screen and (max-height: 930px) {
                height : 57%;
            }
            @media screen and (max-height: 900px) {
                height : 56%;
            }
            @media screen and (max-height: 850px) {
                height : 52%;
            }
            @media screen and (max-height: 800px) {
                height : 50%;
            }
            @media screen and (max-height: 780px) {
                height : 49%;
            }
            @media screen and (max-height: 750px) {
                height : 44%;
            }
            @media screen and (max-height: 700px) {
                height : 41%;
            }
            @media screen and (max-height: 650px) {
                height : 37%;
            }
            @media screen and (max-height: 635px) {
                height : 34%;
            }
            @media screen and (max-height: 600px) {
                height : 32%;
            }
            .table-header{
                table-layout: auto;
                .col-site-id{
                    min-width: 84px;
                    // width: 8%;
                }
                .col-site-name{
                    min-width: 84px;
                    // width: 8%;
                }
                .col-contact-name{
                    min-width: 84px;
                    // width: 8%;
                }
                .col-contract-plan{
                    min-width: 84px;
                    // width: 8%;
                }
                .col-capacity{
                    min-width: 270px;
                    // width: 27.9%;
                }
                .col-number-of-users{
                    min-width: 84px;
                    // width: 8%;
                }
                .col-number-of-administrators{
                    min-width: 153px;
                    // width: 16%;
                }
                .col-cu-responsible{
                    min-width: 84px;
                    // width: 8%;
                }
                .ucol-btn{
                    min-width: 84px;
                    // width: 8%;
                }
                tr{
                    width: 100%;
                    position: fixed;
                    th{
                        padding: 5px 0;
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                }
            }
            .table-result-nodata{
                height: 100%;
                tr{
                    td{
                        border: 0px solid #fafafa;
                        color: #AFAFAF;
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        span{
                            position: relative;
                        }
                    }
                }
            }
            .table-result{
                table-layout: auto;
                    .col-site-id{
                    min-width: 84px;
                    width: 8%;
                }
                .col-site-name{
                    min-width: 84px;
                    width: 8%;
                }
                .col-contact-name{
                    min-width: 84px;
                    width: 8%;
                }
                .col-contract-plan{
                    min-width: 84px;
                    width: 8%;
                }
                .col-capacity{
                    min-width: 270px;
                    width: 27.9%;
                }
                .col-number-of-users{
                    min-width: 84px;
                    width: 8%;
                }
                .col-number-of-administrators{
                    min-width: 153px;
                    width: 16%;
                }
                .col-cu-responsible{
                    min-width: 84px;
                    width: 8%;
                }
                .ucol-btn{
                    min-width: 84px;
                    width: 8%;
                }
                tr{
                    td:not(.row-ope){
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                    .red{
                        color: #e7001f;
                    }
                    td{
                        padding: 4px;
                    }
                }
            }
            table{ 
                tr:not(:first-child):hover, tr.selected,.hover{
                    .btn-submit-delete { 
                        background: url(#{$IMG_PATH}icon/icn_trash_w.png) 20px center no-repeat;
                        -webkit-background-size: 60%;
                        background-size: 60%;
                        background-position-x: 50% ;
                    }
                    .btn-submit-setting {
                        background: url(#{$IMG_PATH}icon/icn_edit_w.png) 15px center no-repeat;
                        -webkit-background-size: 60%;
                        background-size: 60%;
                        background-position-x: 50% ;
                    }
                }
                tr.selected, .hover{
                    .red{
                        color: #d10064;
                    }
                }
                tr:hover{
                    .red{
                        color: #d10064;
                    }
                }
            }
        }
        .site-list-inner{
            height: 100%;
            background-color: #fff;
        }
        .site-list-outer{
            width: 100%;
            height: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
          }
        .scroll-area {
            &.ps-active-y {
                padding-right: 16.2px;
                margin-right: -16.2px;
            }
            .ps-scrollbar-y-rail{
                right: 16.2px;
            }
            height: 100%;
        } // .scroll-area
    }
    input.btn,
    a.btn {
        &.btn-submit-download {
            float: right;
            font-weight: initial;
            text-align: center;
            width: auto;
            padding: 6px 19px;
            color: #000;
            border: 1px solid #c8c8c8;
            margin: -3px 0;
            line-height: 22px;
            background: #fff;
            color: #6E6E6E;
            img{
              width: 20px;
              height: 20px;
              padding-right: 7px;
            }
        }
        &.btn-new-site {
          width: 150px !important;
        }
        &.btn-submit-upload {
            background: url(#{$IMG_PATH}icon/icn_upload_w.png) 20px center no-repeat;
            -webkit-background-size: 20px;
            background-size: 20px;
            color: #468cc8;
            border: 1px solid;
            background-color: white;
            font-weight: normal;
            width: auto;
            height: 34px;
            line-height: 34px;
            text-align: center;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 0px;
            font-size: 16px;
            padding: 0;
        }
        &.btn-submit-search-new {
            background: url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
            -webkit-background-size: 20px;
            background-size: 20px;
            background-position-x: 50% ;
            color: #468cc8;
            border: 1px solid #82BEF0;
            background-color: #82BEF0;
            font-weight: normal;
            width: 130px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 5px;
            font-size: 16px;
            padding: 0;
        }
         &.btn-submit-setting {
            background: url(#{$IMG_PATH}icon/icn_edit_k.png) 15px center no-repeat;
            -webkit-background-size: 60%;
            background-size: 60%;
            background-position-x: 50% ;
            font-weight: normal;
            width: 35px;
            height: 23px;
            line-height: 35px;
            text-align: center;
            font-size: 16px;
            padding: 0;
        }
        &.btn-submit-delete {
            background: url(#{$IMG_PATH}icon/icn_trash_k.png) 20px center no-repeat;
           -webkit-background-size: 80%;
            background-size:80%;
            background-position-x: 50% ;
            font-weight: normal;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            font-size: 16px;
            padding: 0;
        }
       
    }
}

.btn-download-modal{
    background-image: url(#{$IMG_PATH}icon/icn_download_o.png);
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 20px 20px;
    padding-left: 40px;
}

