@charset "UTF-8";

.page-content-manager-search {
  height: 100%;

  .m-search-c-inner {
    iframe {
      width: 100vw;
      height: 100vh;
    }
  }

  .page-inner {
    background: $background_theme_color_contents;
    height: 100%;

    ////// ここからv6.0のscss
        .page-contents {
            height: 100%;
            width: 100%;
            margin: auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
             .m-form-search {
                 padding: 0;
                 .error {
                     margin-top: 1px;
                 }
                .form-group {
                    .inner {
                        width: 90%;
                        padding: 0;
                        table {
                          .td-btn-submit{
                            text-align: center;
                          }
                          .btn-clear{
                            margin-right: 10px;
                          }
                          tr {
                            height: 50px;
                            input{
                              height: 39px;
                            }
                            td {
                              p {
                                  margin-top: 0;
                                  font-weight: normal !important;
                                }
                                .sel {
                                  height: 39px;
                                  width: 100% !important;
                                }
                                &.td-label {
                                  text-align: right;
                                  padding-right: 10px;
                                  
                                }
                                &.td-label:first-child {
                                  width: 12%;
                                }
                                &.td-input {
                                  width: 21%;
                                }
                                vertical-align: middle;
                              }
                              &.cond-row1 {
                                td:nth-child(3) {
                                      width: 7%;
                                }
                                td:nth-child(5) {
                                      width: 13%;
                                      min-width: 120px;
                                }
                              }
                              &.cond-row2 {
                                td:first-child {
                                  min-width: 130px;
                                }
                              }
                              &.cond-row3 {
                                td:first-child {
                                  min-width: 100px;
                                }
                              }
                              &.cond-row4 {
                                td:nth-child(3) {
                                      width: 8%;
                                }
                                td>a>img{
                                   vertical-align: middle;
                                }
                              }
                              &.tr-btn-submit {
                                  height: 72px;
                              }
                            }
                            &.table-one-cal{
                                display: none;
                                @media screen and (max-width: 1280px) {
                                    display: table;
                                }
                            }
                            &.table-two-cal{
                                display: table;
                                @media screen and (max-width: 1280px) {
                                    display: none;
                                }
                            }
                        }
                         
                        .form-row{
                            .layout {
                                margin-bottom: 0 ;
                            }
                            .layout>*{
                                width: 100% !important; 
                                margin-bottom: 0 ;
                                @media screen and (max-height: 768px) {
                                    input {
                                        height: 32px;
                                    }
                                    tr {
                                       height: 37px;
                                    }
                                    .sel {
                                        height: 32px;
                                        width: 100% !important;
                                        .sel-txt {
                                            line-height: 33px;
                                        }
                                    }
                                    .tr-btn-submit {
                                        height: 50px;
                                    }
                                }
                            }
                        }

                        .input-size-period1,.input-size-period2 {
                           width: 35%;
                           @media screen and (max-width: 1280px) {
                               width: 100%;
                           }
                        }
                        .td-cal {
                            padding-left: 5px;
                            width: 86px !important;
                        }
                    }
                }
            }
        }
    //ここまで現行v6.0のscss


    //// ここからv6.1用css
    .page-contents.v6_1 {
      height: 100%;
      width: 100%;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      .m-form-search {
        padding: 0;

        .error {
          margin-top: 1px;
        }

        .form-group {
          margin: auto;

          .inner {
            width: 90%;
            padding: 0;

            table {
              .td-btn-submit {
                text-align: center;
              }

              .btn-clear {
                margin-right: 10px;
              }

              tr.cond-row1 {

                td {
                  &.td-input {
                    width: 20%;
                  }
                }

              }

              tr {
                height: 50px;

                input {
                  height: 32px;
                }

                td {
                  p {
                    margin-top: 0;
                    font-weight: normal !important;
                    line-height: 30px;
                  }

                  .sel {
                    height: 32px;
                    width: 100% !important;
                  }

                  &.td-label.top {
                    width: 88px;
                    text-align: left;
                  }

                  &.td-label.middle {
                    text-align: right;
                    min-width: 126px;
                    width: 13.5%;
                  }

                  &.td-label.bottom {
                    text-align: right;
                    min-width: 130px;
                    width: 13.5%;
                  }

                  &.td-label {
                    padding: 12px 12px 12px 25px;
                    text-align: right;
                  }

                  &.td-label:first-child {
                    width: 7.5%;
                    padding-left: 0px;
                  }

                  &.td-input {
                    width: 20%;
                  }

                  &.td-label.date-ttl-wrap {}

                  .search-sub-ttl.date {
                    min-width: 66px;
                  }

                  .search-sub-txt {
                    display: inline-block;
                  }

                  .c-name-btn {
                    display: inline-block;
                  }

                  vertical-align: middle;
                }

                &.cond-row1 {
                  td:first-child {
                    width: 7.5%;
                    min-width: 84px;
                    padding-left: 28px;
                  }

                  td:nth-child(3) {
                    width: 13.5%;
                  }

                  td:nth-child(5) {
                    width: 13.5%;
                    min-width: 120px;
                  }
                }

                &.cond-row2 {
                  td:first-child {
                    min-width: 112px;
                    text-align: left;
                  }

                  td:nth-child(2),
                  td:nth-child(4) {
                    width: 20%;
                  }

                  .radios {
                    margin-left: -10px;
                  }

                  .td-input {
                    width: 20%;

                    .input-text {
                      width: 150px;
                    }
                  }
                }

                &.cond-row3 {
                  .td-label:first-child {
                    width: 7.5%;
                    padding-left: 50px;
                    padding-right: 2px;
                  }

                  td:first-child {
                    min-width: 70px;
                    text-align: left;
                  }

                  td:nth-child(2) {
                    width: 20%;
                  }

                  td:nth-child(3) {
                    width: 13.5%;
                    min-width: 70px;
                  }

                  td:nth-child(4) {
                    width: 20%;
                    min-width: 234px;
                  }

                  td:nth-child(5) {
                    width: 13.5%;
                  }

                  td:nth-child(6) {
                    width: 20%;
                  }
                }

                &.cond-row4 {

                  max-width: 1210px;

                  .td-label:first-child,
                  td:nth-child(3) {
                    width: 11%;
                  }

                  td:nth-child(2),
                  td:nth-child(4) {
                    width: 39%;
                  }

                  td > a > img {
                    vertical-align: middle;
                  }
                }

                &.tr-btn-submit {
                  height: 72px;
                }
              }

              &.table-one-cal {
                display: none;

                @media screen and (max-width: 1280px) {
                  display: table;
                }
              }

              &.table-two-cal {
                display: table;

                @media screen and (max-width: 1280px) {
                  display: none;
                }
              }
            }

            .form-row {
              .layout {
                margin-bottom: 0;
              }

              .layout > * {
                width: 100% !important;
                margin-bottom: 0;

                @media screen and (max-height: 768px) {
                  input {
                    height: 32px;
                  }

                  tr {
                    height: 37px;
                  }

                  .sel {
                    height: 32px;
                    width: 100% !important;

                    .sel-txt {
                      line-height: 33px;
                    }
                  }

                  .tr-btn-submit {
                    height: 50px;
                  }
                }
              }
            }

            .input-size-period1,
            .input-size-period2 {
              width: 150px;

              @media screen and (max-width: 1280px) {
                width: 100%;
              }
            }

            .td-cal {
              padding-left: 5px;
              width: 86px !important;
            }
          }
        }
      }
    }

    //ここまでデザインテンプレ用4列　v6.1

    .course-header {
      display: flex;
      padding-bottom: 5px;

      .except {
        height: 71px;
        display: none;
      }

      .download {
        position: absolute;
        right: 0px;
        margin-top: 5px;
        margin-right: 10px;
        min-height: 30px;
        padding-bottom: 5px;
      }

      .result-txt {
        margin-top: 5px;
        margin-left: 10px;
        min-height: 30px;
        display: flex;
        align-items: center;
      }

    }

    .course-search-header {
      background: #fff;
      padding: 0 20px;
      height: 59px;

      .inner {
        min-height: 34px;
        padding: 20px 0;
        position: relative;
        // border-bottom: 2px solid #d1d1d1;
        overflow: hidden;

        .back {
          @include HoverOpacity();
          position: absolute;
          left: 0;

          img {
            width: 20px;
            height: 20px;
            vertical-align: -webkit-baseline-middle;
          }
        }

        .btn {
          float: right;
          text-align: center;
        }

        .select-upload {
          float: right;
          margin-right: 5px;

          .sel {
            background-color: white;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 0px;
            color: #468cc8;
            border: 1px solid;
            width: 200px;
          }

          .sel.sel-size-auto {
            height: 36px;
            width: 170px !important;

            .sel-txt {
              padding-left: 10px;
              padding-right: 17px;
              background-size: 18px;
              background-image: url(#{$IMG_PATH}icon/icn_selectbtn_bottom_b.png);

              span {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
          }
        }
      }
    }

    .course-result {
      @include search_table_f;
      margin: 0 10px;
      padding: 0;
      position: relative;

      .table-header {
        tr {
          width: 100%;
          position: fixed;

          th {
            padding: 5px;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
          }

        }

      }

      .table-result-nodata {
        height: 100%;

        tr {
          td {
            border: 0px solid #fafafa !important;
            color: #AFAFAF;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;

            span {
              position: relative;
            }
          }
        }
      }

      .table-result,
      .table-header {
        table-layout: auto;

        .col-content-name {
          width: 20%;
          min-width: 100px;
        }

        .col-content-version {
          width: 8%;
          min-width: 80px;
        }

        .col-user-create {
          width: 10%;
          min-width: 80px;
        }

        .col-chapter-flag {
          width: 15%;
          min-width: 130px;
        }

        .col-date-create {
          width: 8%;
          min-width: 80px;
        }

        .col-date-update {
          width: 8%;
          min-width: 80px;
        }

        .col-content-type {
          width: 13%;
          min-width: 130px;
        }

        .col-btn-preview {
          width: 8%;
          min-width: 80px;
        }

        .col-btn-setting {
          width: 10%;
          min-width: 90px;
        }
      }

      .table-result {

        tr {
          td:not(.row-ope) {
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
          }

          .content-ccp {
            background: url(#{$IMG_PATH}icon/icn_conccp_k.png) center center no-repeat;
            -webkit-background-size: 100%;
            background-size: 100%;
            vertical-align: middle;
            display: inline-block;
            width: 34px;
            height: 34px;
            background-position-x: 50%;
          }

          .content-anket {
            background: url(#{$IMG_PATH}icon/icn_conenq_k.png) center center no-repeat;
            -webkit-background-size: 100%;
            background-size: 100%;
            vertical-align: middle;
            display: inline-block;
            width: 34px;
            height: 34px;
            background-position-x: 50%;
          }

          .content-test {
            background: url(#{$IMG_PATH}icon/icn_test_k.png) center center no-repeat;
            -webkit-background-size: 100%;
            background-size: 100%;
            vertical-align: middle;
            display: inline-block;
            width: 34px;
            height: 34px;
            background-position-x: 50%;
          }

          .content-doccument {
            background: url(#{$IMG_PATH}icon/icn_file_document_k.png) center center no-repeat;
            -webkit-background-size: 100%;
            background-size: 100%;
            vertical-align: middle;
            display: inline-block;
            width: 34px;
            height: 34px;
            background-position-x: 50%;
          }

          .content-video {
            background: url(#{$IMG_PATH}icon/icn_video_k.png) center center no-repeat;
            -webkit-background-size: 100%;
            background-size: 100%;
            vertical-align: middle;
            display: inline-block;
            width: 34px;
            height: 34px;
            background-position-x: 50%;
          }

          .content-html {
            background: url(#{$IMG_PATH}icon/icn_file_html_k.png) center center no-repeat;
            -webkit-background-size: 100%;
            background-size: 100%;
            vertical-align: middle;
            display: inline-block;
            width: 34px;
            height: 34px;
            background-position-x: 50%;
          }

          .content-url {
            background: url(#{$IMG_PATH}icon/icn_url_k.png) center center no-repeat;
            -webkit-background-size: 100%;
            background-size: 100%;
            vertical-align: middle;
            display: inline-block;
            width: 34px;
            height: 34px;
            background-position-x: 50%;
          }

          .content-slide {
            background: url(#{$IMG_PATH}icon/icn_slideonly_k.png) center center no-repeat;
            -webkit-background-size: 100%;
            background-size: 100%;
            vertical-align: middle;
            display: inline-block;
            width: 34px;
            height: 34px;
            background-position-x: 50%;
          }
          
          .content-preview {
            background: url(#{$IMG_PATH}icon/icn_preview_o.png) 20px center no-repeat;
            -webkit-background-size: 80%;
            background-size: 80%;
            background-position-x: 50%;
            font-weight: normal;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            font-size: 16px;
            padding: 0;
            z-index: -1;
          }

          .icn-chapter-check {
            background: url(#{$IMG_PATH}icon/icn_check_k.png) center center no-repeat;
            -webkit-background-size: 70%;
            background-size: 70%;
            display: inline-block;
            width: 34px;
            height: 34px;
            background-position-x: 50%;
            vertical-align: middle;
          }
        }

      }

      table {

        tr:not(:first-child):hover,
        tr.selected,
        .hover {
          .btn-submit-delete {
            background: url(#{$IMG_PATH}icon/icn_edit_w.png) 20px center no-repeat;
            -webkit-background-size: 80%;
            background-size: 80%;
            background-position-x: 50%;
          }

          .btn-submit-setting {
            background: url(#{$IMG_PATH}icon/icn_trash_w.png) 20px center no-repeat;
            -webkit-background-size: 80%;
            background-size: 80%;
            background-position-x: 50%;
          }

          .content-ccp {
            background: url(#{$IMG_PATH}icon/icn_conccp_w.png) center center no-repeat;
            -webkit-background-size: 100%;
            background-size: 100%;
            background-position-x: 50%;
          }

          .content-anket {
            background: url(#{$IMG_PATH}icon/icn_conenq_w.png) center center no-repeat;
            -webkit-background-size: 100%;
            background-size: 100%;
            background-position-x: 50%;
          }

          .content-test {
            background: url(#{$IMG_PATH}icon/icn_test_w.png) center center no-repeat;
            -webkit-background-size: 100%;
            background-size: 100%;
            background-position-x: 50%;
          }

          .content-doccument {
            background: url(#{$IMG_PATH}icon/icn_file_document_w.png) center center no-repeat;
            -webkit-background-size: 100%;
            background-size: 100%;
            background-position-x: 50%;
          }

          .content-video {
            background: url(#{$IMG_PATH}icon/icn_video_w.png) center center no-repeat;
            -webkit-background-size: 100%;
            background-size: 100%;
            background-position-x: 50%;
          }

          .content-html {
            background: url(#{$IMG_PATH}icon/icn_file_html_w.png) center center no-repeat;
            -webkit-background-size: 100%;
            background-size: 100%;
            background-position-x: 50%;
          }

          .content-url {
            background: url(#{$IMG_PATH}icon/icn_url_w.png) center center no-repeat;
            -webkit-background-size: 100%;
            background-size: 100%;
            background-position-x: 50%;
          }

          .icn-chapter-check {
            background: url(#{$IMG_PATH}icon/icn_check_w.png) center center no-repeat;
            -webkit-background-size: 70%;
            background-size: 70%;
            background-position-x: 50%;
          }
        }
      }

    }

    input,
    a {
      vertical-align: middle;

      &.btn-submit-download {
        float: right;
        font-weight: initial;
        text-align: center;
        width: auto;
        padding: 6px 19px;
        color: #000;
        border: 1px solid #c8c8c8;
        margin: -3px 0;
        line-height: 22px;
        background: #fff;
        color: #6E6E6E;

        img {
          width: 20px;
          height: 20px;
          padding-right: 7px;
        }
      }


      &.btn-submit-search-new {
        background: url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
        -webkit-background-size: 20px;
        background-size: 20px;
        background-position-x: 50%;
        color: #468cc8;
        border: 1px solid #82BEF0;
        background-color: #82BEF0;
        font-weight: normal;
        width: 130px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 5px;
        font-size: 16px;
        padding: 0;
      }

      &.btn-submit-setting {
        background: url(#{$IMG_PATH}icon/icn_trash_k.png) 20px center no-repeat;
        -webkit-background-size: 80%;
        background-size: 80%;
        background-position-x: 50%;
        font-weight: normal;
        width: 34px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        font-size: 16px;
        padding: 0;
      }

      &.btn-submit-delete {
        background: url(#{$IMG_PATH}icon/icn_edit_k.png) 20px center no-repeat;
        -webkit-background-size: 80%;
        background-size: 80%;
        background-position-x: 50%;
        font-weight: normal;
        width: 34px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        font-size: 16px;
        padding: 0;
      }

    }

    .course-list-inner {
      height: 100%;
      background-color: #fff;
    }

    .course-list-outer {
      width: 100%;
      height: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }

  .scroll-area {
    &.ps-active-y {
      padding-right: 16.2px;
      margin-right: -16.2px;
    }

    .ps-scrollbar-y-rail {
      right: 16.2px;
    }

    height: 100%;
  }

  // .scroll-area
  .pseudo-radio {
    margin-right: 10px;
  }

}


.confirm-search-modal {
  .modal-inner {
    min-width: 600px;

    .btn {
      width: 150px !important;
    }
  }
}
