@charset "UTF-8";

.notice-detail {

  .page-inner {

    .page-contents {
      width: 100%;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      max-width: 1200px;
    }

    .m-form {
      left: 0;
      width: 840px;

      .form-wrap {

        &:first-child {
          margin-top: 0;
        }

        .form-group {
          padding-right: 40px;

          .deleteBtn {
            right: -14px;
            top: -10px;
            border: 0 none;
            width: 15px;
            height: 16px;
            background: transparent url(../img/ccp/common/dialog/btn_close.png) no-repeat scroll 0 0;
          }

          .uploadFile {
            display: none;
          }
        }

        table {
          th:first-child {
            width: 20%;
            padding-right: 50px;
          }

          .td-rc {
            text-align: right;
          }

          .td-va {
            vertical-align:top;

            .uploadButton {
              float: right;
            }
          }

          .td-bl {
            width: 16px;
            vertical-align: middle;
          }

          .td-iu {
            vertical-align: middle;
            text-align: right;
          }

          .td-pa20 {
            padding-top: 20px;
          }

          .chk-description {
            display: block;
            margin-top: -20px;
            margin-left: 50px;
          }
        }
      }

      &.conf-form {
        .form-wrap {
          .form-group {
            padding-right: 0;
          }
        }
      }
    }

    .notice-detail-header {
      background: #fff;
      padding: 0 20px;

      .inner {
        min-height: 34px;
        padding: 20px 0;
        position: relative;
        border-bottom: 2px solid #d1d1d1;
        overflow: hidden;

        .back {
          @include HoverOpacity();
          position: absolute;
          left: 0;
        }
      }
    }
  }
}