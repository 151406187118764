@charset "UTF-8";
.c-user-assign-header {
  .c-user-assign-header-inner {
    padding: 10px 10px 0px;
    a,
    span {
      vertical-align: middle;
    }
    .arrow-back {
      width: 24px;
      height: 24px;
      display: inline-block;
      background: url(#{$IMG_PATH}icon/icn_back_k.png) left center no-repeat;
      -webkit-background-size: 20px 20px;
      background-size: 20px 20px;
      @include HoverOpacityTrans();
      cursor: pointer;
    }
    .user {
      margin-left: 10px;
      width: 40px;
      height: 40px;
      display: inline-block;
      background: url(#{$IMG_PATH}icon/icn_user_k.png) left no-repeat;
      -webkit-background-size: contain;
      background-size: contain;
    }
    .group {
      margin-left: 10px;
      width: 40px;
      height: 40px;
      display: inline-block;
      background: url(#{$IMG_PATH}icon/icn_usergroup_k.png) left no-repeat;
      -webkit-background-size: contain;
      background-size: contain;
    }
    .name {
      padding-left: 10px;
      text-align: left;
      line-height: 30px;
      max-width: 91%;
      word-break: break-all;
      font-size: 26px;
      color: #6E6E6E;
      display: inline-block;
      cursor: default;
      vertical-align: -webkit-baseline-middle;
    }
    hr {
      display: block;
      height: 2px;
      border: 0;
      border-top: 2px solid #6E6E6E;
      margin: 10px 0;
      padding: 0;
    }
  }
}