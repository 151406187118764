@charset "UTF-8";
.information-new-header {
  .information-new-header-inner {
    padding: 15px 10px 0px;
    a,
    span {
      vertical-align: middle;
      
    }
    .arrow-back {
      width: 24px;
      height: 24px;
      display: inline-block;
      background: url(#{$IMG_PATH}icon/icn_back_k.png) left center no-repeat;
      -webkit-background-size: 20px 20px;
      background-size: 20px 20px;
      @include HoverOpacityTrans();
      cursor: pointer;
      margin-left: 10px;
      margin-top: 8px;
    }
    .required{
      float: right;
      margin-right: 20px;
      color: red;
    }
  }
}