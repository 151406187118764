@charset "UTF-8";
.page-list-content-approval {
    height: 100%;
    .page-inner {
        background: $background_theme_color_contents;
        height: 100%;
        .page-contents {
            height: 100%;
            width: 100%;
            margin: auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
             .m-form-search {
                 padding: 0;
                 .error {
                     margin-top: 1px;
                 }
                .form-group {
                    .inner {
                        width: 90%;
                        padding: 0;
                        table {
                          .td-btn-submit{
                            text-align: center;
                          }
                          .btn-clear{
                            margin-right: 10px;
                          }
                          tr {
                            height: 50px;
                            input{
                              height: 39px;
                            }
                            td {
                              p {
                                  margin-top: 0;
                                  font-weight: normal !important;
                                }
                                &.td-label:first-child {
                                    width: 100px;
                                    text-align: left;
                                    padding-right: 10px;
                                    padding-left: 10px;
                                }
                                vertical-align: middle;
                              }
                                &.tr-btn-submit {
                                    height: 72px;
                                }
                                .td-label-course-name,.td-label-processing-number,.td-label-date {
                                    text-align: left;
                                    padding-right: 10px;
                                    text-align: left;
                                    padding-right: 10px;
                                    padding-left: 10px;
                                    width: 59px;
                                }
                                .td-label-type {
                                    text-align: left;
                                    padding-right: 10px;
                                    padding-left: 10px;
                                    width: 115px;
                                }
                                .td-input {
                                    width: 18%;
                                }
                                .sel {
                                    width: 100%;
                                    height: 39px;
                                    .sel-txt {
                                        line-height: 38px;
                                        font-size: unset;
                                    }
                                }
                                &.error-input{
                                    height: 30px;
                                }
                            }
                        }
                         
                        .form-row{
                            .layout {
                                margin-bottom: 0 ;
                                max-width: 1200px;
                                margin: 0 auto;
                            }
                            .layout>*{
                                width: 100% !important; 
                                margin-bottom: 0 ;
                                @media screen and (max-height: 768px) {
                                    input {
                                        height: 32px;
                                    }
                                    tr {
                                       height: 45px;
                                       &.error-input{
                                            height: 30px;
                                        }
                                    }
                                    .sel {
                                        height: 32px;
                                        .sel-txt {
                                            line-height: 32px;
                                        }
                                    }
                                    .tr-btn-submit {
                                        height: 50px;
                                    }
                                }
                            }
                        }

                        .input-size-period1,.input-size-period2 {
                           width: 28.5%;
                        }
                        .td-cal {
                            padding-left: 5px;
                            width: 86px !important;
                        }
                    }
                }
            }
        }

      .course-header {
        display: flex;
        padding-bottom: 5px;

        .except {
          height: 71px;
          display: none;
        }
        .download {
          position: absolute;
          right: 0px;
          margin-top: 5px;
          margin-right: 10px;
          min-height: 30px;
          padding-bottom: 5px;
        }

        .result-txt {
          margin-top: 5px;
          margin-left: 10px;
          min-height: 30px;
          display: flex;
          align-items: center;
        }

      }

        .course-search-header {
            background: #fff;
            padding: 0 20px;
            height: 59px;
            .inner {
                min-height: 34px;
                padding: 20px 0;
                position: relative;
                // border-bottom: 2px solid #d1d1d1;
                overflow: hidden;
                .back {
                    @include HoverOpacity();
                    position: absolute;
                    left: 0;
                    img {
                        width: 20px;
                        height: 20px;
                        vertical-align: -webkit-baseline-middle;
                    }
                }
                .btn {
                    float: right;
                    text-align: center;
                }
                .select-upload {
                    float: right;
                    margin-right: 5px;
                    .sel {
                        background-color: white;
                        -webkit-border-radius: 0px;
                        -moz-border-radius: 0px;
                        border-radius: 0px;
                        color: #468cc8;
                        border: 1px solid;
                        width: 200px;
                    }
                    .sel.sel-size-auto {
                        height: 36px;
                        width: 170px !important;

                        .sel-txt {
                            padding-left: 10px;
                            padding-right: 17px;
                            background-size: 18px;
                            background-image: url(#{$IMG_PATH}icon/icn_selectbtn_bottom_b.png);
                            span{
                               text-overflow: ellipsis;
                               white-space: nowrap;
                               overflow: hidden;
                            }
                        }
                    }
                }
            }
        }

        .course-result {
          @include search_table_f;
          margin: 0 10px;
          padding: 0;
          position: relative;
            .table-header{
                tr{
                    width: 100%;
                    position: fixed;
                    th{
                        padding: 5px;
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }

                }
                 
            }
            .table-result-nodata{
                height: 100%;
                tr{
                    td{
                        border: 0px solid #fafafa  !important;
                        color: #AFAFAF;
                         box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        span{
                            position: relative;
                        }
                    }
                }
            }
            .table-result, .table-header {
                    // table-layout: auto;
                    thead tr {
                      z-index:3;
                    }
                    .col-content-code{
                      width: 63px;
                    }
                    .col-btn-preview {
                      width: 85px;
                    }
                    .col-content-date,.col-content-date-approved {
                      width: 100px;
                    }
                    .col-content-type {
                      width: 94px;
                    }
                    .col-content-stt{
                      //width: 69px;
                      width: 71px;
                      .stt-color{
                          font-weight: bold;
                          color: #82BEF0;
                      }
                    }
                    // .col-content-request , .col-content-cmt-approved{
                    //   width:13%;
                    // }
                    // td {
                    //   width: calc(calc(100% - 519px) / 6);
                    // }
            }
            .table-result{
                tr{

                    td:not(.row-ope){
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                    .content-ccp,.content-anket,.content-test,.content-doccument,.content-video,.content-html,.content-slide {
                        width: 45px;
                        height: 45px;
                    }
                    .content-ccp {
                        background: url(#{$IMG_PATH}icon/icn_matccp_k.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        vertical-align: middle;
                        display: inline-block;
                        background-position-x: 50%;
                    }
                    .content-anket {
                        background: url(#{$IMG_PATH}icon/icn_matenq_k.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        vertical-align: middle;
                        display: inline-block;
                        background-position-x: 50%;
                    }
                    .content-test {
                        background: url(#{$IMG_PATH}icon/icn_mattest_k.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        vertical-align: middle;
                        display: inline-block;
                        background-position-x: 50%;
                    }
                    .content-doccument {
                        background: url(#{$IMG_PATH}icon/icn_file_document_k.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        vertical-align: middle;
                        display: inline-block;
                        background-position-x: 50%;
                    }
                    .content-video {
                        background: url(#{$IMG_PATH}icon/icn_matmov_k.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        vertical-align: middle;
                        display: inline-block;
                        background-position-x: 50%;
                    }
                    .content-html {
                        background: url(#{$IMG_PATH}icon/icn_file_html_k.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        vertical-align: middle;
                        display: inline-block;
                        background-position-x: 50%;
                    }
                    .content-slide {
                        background: url(#{$IMG_PATH}icon/icn_slideonly_k.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        vertical-align: middle;
                        display: inline-block;
                        background-position-x: 50%;
                    }
                    .btn-play-thumb {
                      z-index:2;
                      display: block;
                      position: relative;
                      @include HoverOpacity();
                      
                      &:before,
                      &:after {
                        @include PseudoElements();
                        width: 100%;
                        height: 100%;
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                      }

                      &:before{
                        background: rgba(0, 0, 0, .4);
                      }

                      &:after {
                        background: url(#{$IMG_PATH}icon/icn_preview_w.png) center center no-repeat;
                        -webkit-background-size: 26px auto;
                        background-size: 26px auto;
                      }
                      
                    }
                    .icn-chapter-check {
                        background: url(#{$IMG_PATH}icon/icn_check_k.png) center center no-repeat;
                        -webkit-background-size: 70%;
                        background-size: 70%;
                        display: inline-block;
                        width: 34px;
                        height: 34px;
                        background-position-x: 50%;
                        vertical-align: middle;
                    }
                }
                
            }
            table{
                tr:hover, tr.selected{  
                    .stt-color{
                       color: #fff !important;
                    }
                } 
                tr:not(:first-child):hover, tr.selected,.hover {
                    .stt-color{
                       color: #fff;
                    }
                    .btn-submit-delete { 
                        background: url(#{$IMG_PATH}icon/icn_edit_w.png) 20px center no-repeat;
                        -webkit-background-size: 80%;
                        background-size: 80%;
                        background-position-x: 50% ;
                    }
                    .btn-submit-setting {
                        background: url(#{$IMG_PATH}icon/icn_trash_w.png) 20px center no-repeat;
                        -webkit-background-size: 80%;
                        background-size: 80%;
                        background-position-x: 50% ;
                    }
                    .content-ccp {
                        background: url(#{$IMG_PATH}icon/icn_matccp_w.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        background-position-x: 50%;
                      }
                    .content-anket {
                        background: url(#{$IMG_PATH}icon/icn_matenq_w.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        background-position-x: 50%;
                    }
                    .content-test {
                        background: url(#{$IMG_PATH}icon/icn_mattest_w.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        background-position-x: 50%;
                      }
                    .content-doccument {
                        background: url(#{$IMG_PATH}icon/icn_file_document_w.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        background-position-x: 50%;
                    }
                    .content-video {
                        background: url(#{$IMG_PATH}icon/icn_matmov_w.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        background-position-x: 50%;
                    }
                    .content-html {
                        background: url(#{$IMG_PATH}icon/icn_file_html_w.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        background-position-x: 50%;
                    }
                    .content-slide {
                        background: url(#{$IMG_PATH}icon/icn_slideonly_w.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        background-position-x: 50%;
                    }
                    .icn-chapter-check {
                        background: url(#{$IMG_PATH}icon/icn_check_w.png) center center no-repeat;
                        -webkit-background-size: 70%;
                        background-size: 70%;
                        background-position-x: 50%;
                    }
                }
            }
            .comment-area{
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .all-comment, .all-app-comment {
              position: absolute !important;
              display: none;
              right: 100px;
              z-index: 99;
              padding: 10px;
              width: 400px;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              background: #333;
              color: #fff;
              -webkit-border-radius: 5px;
              -moz-border-radius: 5px;
              border-radius: 5px;
              font-size: 12px;

                &:before {
                  background: #333;
                  z-index: 1;
                  @include PseudoElements();
                  width: 10px;
                  height: 10px;
                  position: absolute;
                  right: -5px;
                  bottom: 15px;
                  -webkit-transform: rotate(-45deg);
                  -moz-transform: rotate(-45deg);
                  -ms-transform: rotate(-45deg);
                  -o-transform: rotate(-45deg);
                  transform: rotate(-45deg);

                }
            }
              
        }
        input,a{
        vertical-align: middle;
        &.btn-bulk-approval {
            float: right;
            font-weight: normal;
            text-align: center;
            width: 180px;
            color: #000;
            border: 1px solid #82BEF0;
            line-height: 29px;
            height: 30px;
            background: #fff;
            color: #6E6E6E;
        }
  
       
        &.btn-submit-search-new {
           background: url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
            -webkit-background-size: 20px;
            background-size: 20px;
            background-position-x: 50% ;
            color: #468cc8;
            border: 1px solid #82BEF0;
            background-color: #82BEF0;
            font-weight: normal;
            width: 130px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 5px;
            font-size: 16px;
            padding: 0;
        }
         &.btn-submit-setting {
            background: url(#{$IMG_PATH}icon/icn_trash_k.png) 20px center no-repeat ;
            -webkit-background-size: 80%;
            background-size: 80%;
            background-position-x: 50% ;
            font-weight: normal;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            font-size: 16px;
            padding: 0;
        }
        &.btn-submit-delete {
            background: url(#{$IMG_PATH}icon/icn_edit_k.png) 20px center no-repeat;
           -webkit-background-size: 80%;
            background-size: 80%;
            background-position-x: 50% ;
            font-weight: normal;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            font-size: 16px;
            padding: 0;
        }
       
    }
        
        .course-list-inner{
            height: 100%;
            background-color: #fff;
        }
        .course-list-outer{
            width: 100%;
            height: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }
   }
    .scroll-area {
            &.ps-active-y {
                padding-right: 16.2px;
                margin-right: -16.2px;
                
    }
    .ps-scrollbar-y-rail{
                right: 16.2px;
                z-index:4;
    }
    height: 100%;
    } // .scroll-area
         .pseudo-radio {
            margin-right: 10px;
    }
  
}
    

.confirm-search-modal{
    .modal-inner{
        min-width: 600px;
        .btn{
            width: 150px !important;
        }
    }
}

