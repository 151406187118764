@charset "UTF-8";

/*
#overview
ベースレイアウト

*/

.wrap {
  width: 100%;
  height: 100%;
  max-width: 2048px;
  min-width: 1000px;
  min-height: 600px;
  overflow: hidden;

  position: relative;

  .global-header {
    position: absolute;
    z-index: 3;
    width: 100%;
    top: 0;
    left: 0;
    height: 53px;
  } // .header

  .global-menu {
    position: absolute;
    z-index: 2;
    width: 110px;
    padding-right: 10px;
    height: 100%;
    padding-top: 53px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  } // .menu

  .container {
    width: 100%;
    height: 100%;
    z-index: 1;
    padding-left: 110px;
    padding-top: 53px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  } // .container

  .contents {
    //overflow: hidden;
    .contents-inner {
      max-height: 100%;
    }
  }

  &.unnecessary_global {
    height: auto;
    .container {
      padding-top: 0;
      padding-left: 0;
    }

    .contents {
      width: 800px;
      margin: auto;
      padding-bottom: 10px;
    }

  }

} // .wrap