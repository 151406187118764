@charset "UTF-8";

.modal-inner {
  &.modal-inner-lti-external-tool-search {
    padding: 25px;
    width: 600px;
    height: 500px;
    .search-header {
      display: flex;
      align-items: center;
      .label {
        color: #82BEF0;
        margin-right: 20px;
      }
      button#search {
        -webkit-background-size: 22px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        background: url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
        background-color: #82BEF0;
        background-size: 22px;
        background-position-x: 50%;
        border: 1px solid #82BEF0;
        border-radius: 5px;
        color: #468cc8;
        font-size: 12px;
        font-weight: normal;
        height: 28px;
        line-height: 34px;
        padding: 0;
        text-align: center;
        width: 60px;
      }
      @mixin placeholder($color) {
        &::placeholder {
          color: $color;
        }
        // IE
        &:-ms-input-placeholder {
          color: $color;
        }

        // Edge
        &::-ms-input-placeholder {
          color: $color;
        }
      }
      input[name="toolName"] {
        @include placeholder(#82BEF0);
        width: 260px;
        margin-right: 15px;
      }
    }
    .validation-error-msg {
      height: 19px;
      text-align : left;
      font-size: 12px;
      color:red;
      font-weight: bold;
    }
    .search-result-box {
      background: #bae3f9;
      height: 400px;
      .search-result-header {
        display: flex;
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        text-align: center;
        z-index: 11000;
        .except {
          height: 71px;
          display: none;
        }
        .result-txt {
          margin-top: 5px;
          margin-left: 10px;
          min-height: 30px;
          display: flex;
          align-items: center;
        }
      }
      .search-result-body {
        height: 365px;
        .result-list-outer {
          width:100%;
          height:100%;
          padding: 0 10px 10px 10px;
          box-sizing: border-box;
          .result-list-inner {
            height: 100%;
            background-color: #fff;
            .table-header {
              width: 100%;
              table-layout: auto;
              tr {
                th {
                  height: 30px;
                  width: 50%;
                  border-width: 0 1px 1px 1px;
                  border-color: #c8c8c8;
                  border-style: solid;
                  &:first-child {
                    border-left: 0;
                  }
                  &:last-child {
                    border-right: 0;
                  }
                }
                &:first-child {
                  th {
                    color: #82BEF0;
                    vertical-align: middle;
                    text-align: center;
                  }
                }
              }
            }
            .scroll-area {
              overflow:hidden;
              height: calc(100% - 30px);
              .table-result {
                background-color: #ffffff;
                width: 100%;
                border-collapse: collapse;
                table-layout: fixed;    
                tr {
                  cursor: pointer;
                  td {
                    height: 30px;
                    width: 50%;
                    border-width: 0 1px 1px 1px;
                    border-color: #c8c8c8;
                    border-style: solid;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding: 0 15px;
                    overflow: hidden;
                    vertical-align: middle;
                    &:first-child {
                      border-left: 0;
                    }
                    &:last-child {
                      border-right: 0;
                    }
                  }
                }
              }
              .ps-scrollbar-y-rail {
                right: 0px;
              }
            }
            .table-result-nodata {
              height: 355px;
              width: 100%;
              #nodata, #loading {
                vertical-align: middle;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }  
}
.lti-external-tool-search-tooltip {
    &:read-only{
      background: #000;
    }
    position: absolute;
    padding: 10px;
    z-index: 101;
    font-size: 12px;
    word-break: break-all;
    line-height: 1.6em;
    color: #fff;
    border-radius: 5px;
    background: #000;
    min-height: 100px;
    min-width: 300px;
    max-width: 300px !important;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}