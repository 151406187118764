@charset "UTF-8";

.m-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, .5);
  
  &.update-ver-modal,&.update-chapter-modal,&update-already-modal {
    .modal-inner {
    width: 470px;
    }
  }

  .modal-inner {
    position: relative;
    top: 20%;
    width: 420px;
    padding: 25px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 auto !important;
    background: #fff;
    text-align: center;
    -webkit-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.5);
  }


  .msg-comp {
    padding: 10px 0;
    font-size: 16px;
  }

  .btn-modal-close {
    margin-top: 15px;
    width: 176px;
    height: 32px;
    line-height: 32px;
    background: #666;
    color: #fff;
    font-weight: normal;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    font-size: 16px;
  }

  .modal-btns {

    .btn {
      margin: 5px;
    }

    .btn-modal-close {
      width: 40%;
      background: none;
      color: #6E6E6E;
      border: #6E6E6E solid 1px;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
    }

    .btn-modal-ok {
      width: 40%;
      height: 32px;
      background: none;
      color: #DC780A;
      border: #DC780A solid 1px;
      font-size: 16px;
      font-weight: normal;
      line-height: 32px;
    }

    .btn-modal-cancle {
      width: 40%;
      height: 32px;
      background: none;
      color: gray;
      border: gray solid 1px;
      font-size: 16px;
      font-weight: normal;
      line-height: 32px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
    }

    .btn-modal-delete {
      width: 40%;
      height: 32px;
      background: none;
      color: #DC780A;
      border: #DC780A solid 1px;
      font-size: 16px;
      font-weight: normal;
      line-height: 32px;
    }

  }
}
