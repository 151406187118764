@charset "UTF-8";

.m-modal {
    &.confirm-link-modal,&.confirm-search-modal{
      br{
      content: "";
      display: block;
      }
      .modal-btns{
        a{
          margin: 5px; 
        }
        .btn-modal-ok{
          width: 40%;
          height: 32px;
          background: none;
          color: #DC780A;
          border: #DC780A solid 1px;
          font-size: 16px;
          font-weight: normal;
          line-height: 32px;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
        }
        .btn-modal-close{
            width: 40%;
            background: none;
            color: #6E6E6E;
            border: #6E6E6E solid 1px;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
        }
      }
      .modal-extracting{
        width: 480px;
      }
      .msg-extracting{
        text-align: left;
        padding-left: 30px;
        line-height: 25px;
        margin-bottom: 10px;
      }
  }
  .modal-inner{
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    &.modal-inner-btn{
      padding-left: 40px;
      width: auto;
      max-width: 650px;
      .btn-area{
        text-align: left;
        display: table-cell;
      }
      .btn-modal{
        @include HoverOpacity();
        color: #6E6E6E;
        text-decoration: none;
        text-align: left;
        padding: 0px 20px 0px 20px;
        height: 46px;
        width: 200px;
        display: inline-block;
        margin-left: 45px;
        z-index: 1;
        position: relative;
        margin: 15px 15px 15px 30px;
        .btn-text{
          position: absolute;
          vertical-align: middle;
          width: 195px;
          height: 45px;;
          border-bottom: 1px solid #c8c8c8;
          border-right: 1px solid #c8c8c8;
          border-top: 1px solid #c8c8c8;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          left: 24px;
          background: #fff;
          p{
            margin-top: 13px;
            padding-left: 5px;
            &.fs-12px {
              font-size: 12px;
            }
          }
        }
        .btn-img{
          position: absolute;
          left: -27px;
          top: -8px;
          border: 1px solid #c8c8c8;
          border-radius: 50%;
          width: 60px;
          height: 60px;
          img{
            margin-top: 11px;
            margin-left: 9px;
            width: 40px;
            height: 40px;
          }
        }
      }
    } 
    &.modal-inner-content{
      padding: 25px 40px;
      width: 800px;
      text-align: -webkit-center;
      .content-area{
        width: 100%;
        height: 100%;
        .content-header{ 
          font-size: 30px;
          color: #82BEF0;
        }
        .row-content{
          color: #6E6E6E;
          padding-top: 30px;
          padding-bottom: 30px;
          position: relative;
          height: 20%;
          width: 100%;
          border-bottom: 1px solid #c8c8c8;
          display: table;
          div{
            max-height: 100%;
            font-size: 15px;
          }
          .name{
            font-size: 30px;
            &.id{
              margin-left: 10px;
            }
          }

          .first-col{
            padding-right: 20px;
            display: table-cell;
            text-align: right;
            width: 30%;
          }
          .mid-col{
            padding-right: 20px;
            display: table-cell;
            width: 50%;
            text-align: left;
            &.head{
              width: 30%;
            }
          }
          .last-col{
            vertical-align: middle;
            display: table-cell;
            width: 20%;
            &.head{
              text-align: left;
              width: 40%;
            }
            .btn-config{
              @include HoverOpacity();
              border: 1px solid #b6b6b6;
              background: url(#{$IMG_PATH}icon/icn_edit_k.png) left center no-repeat;
              background-size: 25px;
              background-position: 50%;
              width: 30px;
              height: 30px;
              -webkit-border-radius: 5px;
              -moz-border-radius: 5px;
              border-radius: 5px;
            }
          }
        }
        .btn-confirm{
          @include HoverOpacity();
          margin-top: 20px;
          background: #fff;
          width: 200px;
          height: 34px;
          line-height: 34px;
          font-size: 16px;
          color: #DC780A;
          border: 1px solid #DC780A;
          font-weight: normal;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
        }
      }
    }

  }
  .btn-modal-exit {
    background: url(#{$IMG_PATH}icon/icn_close_k.png) left center no-repeat;
    background-size: 15px;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: .7;
  }
 .btn-modal-prev {
    background: url(#{$IMG_PATH}icon/icn_back_k.png) left center no-repeat;
    background-size: 15px;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    left: 5px;
    opacity: .7;
  }
  .btn-modal-back {
    @include HoverOpacity();
    background: url(#{$IMG_PATH}icon/icn_back_k.png) left center no-repeat;
    background-size: 20px;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    left: 7px;
  }

}