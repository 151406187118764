@charset "UTF-8";

.c-user-chapter-history-header {
  background: #fff;

  .c-user-chapter-history-header-inner {
    position: relative;
    padding-top: 20px;

    .user-chapter-history-header-layout {
      height: 100%;
      min-height: 1px;

      .hide {
        display: none;
      }
      .m-form-search {
            padding-top: 10px;
        .form-btns {
          display: flex;
          justify-content: center;

          > div {
            margin: 0px;
          }
        }

        .form-group {
          
          .layout {
            display: flex;
            justify-content: center;
            align-items: flex-start;
          }
          .inner-resize {
            padding: 0px;
            min-width: 890px;
            margin: 0 auto;
            max-width: 1500px;

            .col1 {
              width: 25%;
            }

            .col2 {
              width: 30%;
            }

            .col3 {
              @media screen and (min-width: 1430px) {
                width: 30%;
              }
              @media screen and (min-width: 1280px ) and (max-width: 1429px) {
                width: 35%;
              }
              @media screen and (min-width: 1100px ) and ( max-width: 1279px ) {
                width: 40%;
              }
              width: 45%;
              &.attend-stt {
                padding-left: 25px;
              }
              .status-rd {
                * {
                  height: 20px;
                }
                span {
                  height: 12px;
                }
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                label {
                  padding-left: 24px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  div {
                    margin-right: 5px;
                  }
                }
                .rd-btn {
                  min-width: 100px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  label {
                    padding-left: 10px;
                    &::after {
                      top: 5px;
                      left: 15px;
                      width: 14px !important;
                      height: 14px !important;
                    }
                    &::before {
                      top: 8px;
                      left: 17px;
                      width: 11px !important;
                      height: 5px !important;
                    }
                  }
                }
              }
              .status-chk {
                display: flex;
                justify-content: center;
                align-items: center;

                label {
                  padding-left: 15px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                .checkbox-btn {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  label {
                    padding-left: 20px;
                    &::after {
                      top: 5px;
                      left: 15px;
                      width: 14px !important;
                      height: 14px !important;
                    }
                    &::before {
                      top: 8px;
                      left: 17px;
                      width: 11px !important;
                      height: 5px !important;
                    }
                  }
                }
                // .pseudo-checkbox {
                //   @include pseudo-checkbox-f
                // }

              }

              .note {
                font-size: 12px;
                margin-top: -8px;
                margin-left: 21px;
              }
            }

          } //.inner-resize

          .inner {
            padding: 0px;
          }

          .layout {
            margin-bottom: 0px;

            div {
              margin-bottom: 0px;
              * {
                height: 32px;
              }

              .inpt {
                display: flex;
                align-items: center;
                width: 100%;

                &.f-end {
                  justify-content: center;
                }
                .title {
                  display: flex;
                  min-width: 85px;
                  justify-content:flex-end;
                  align-items:center;
                  padding-right: 5px;

                  &.size-l {
                    min-width: 119px;
                    &.st-left {
                      justify-content: flex-start;
                      padding-left: 20px;
                      min-width: 98px;
                    }
                  }

                  &.no-width {
                    min-width: 22px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-right: 0px;
                  }

                }
                .c-name {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: flex;
                    align-items: center;

                    span {
                      white-space: pre !important;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      padding-top: 10px;
                    }
                  }

                .chosen {
                  min-width: 60px;
                  width: 100%;

                  .sel {
                    width: 100%;         
                    border: 1px solid #c8c8c8;
                    -moz-border-radius: 0px;
                    border-radius: 0px;

                    p {
                      font-size: inherit;
                      font-weight: normal;
                      margin-top: 0;
                      background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
                      background-size: 12px;
                      line-height: 32px;
                      white-space: nowrap;
                      // text-overflow: ellipsis;
                      overflow: hidden;
                    }

                  }

                  select {
                    width: 100%;
                  }

                  &.catology {
                    width: 35%;
                    // font-size: 10px;

                    // p {
                    //   line-height: normal;
                    // }

                    select {
                      // word-wrap: break-word;      /* IE*/
                      // white-space: -moz-pre-space; /* Firefox */
                      // white-space: pre-wrap;      /* other browsers */
                      display:inline-block;
                      
                    }
                  }
                }

                .txt {
                  width: 100%;

                  &.s-right {
                    padding-right: 20px;
                  }

                }

                .btn-c-search {
                  width: 40px;
                  margin-left: 5px;
                }
              } //.inpt

              .div-err {
                
                height: auto !important;
                min-height: 20px;

                &.size-l {
                  margin-left: 120px !important;
                }
                .error {
                  height: 100% !important;

                  &.course-name-err {
                    text-align: center;
                  }
                  &.rate-err{
                    padding-left: 90px !important;
                  }
                  &:first-child {
                    white-space: normal;
                    padding-left: 124px;
                    margin-bottom: 0px !important;
                  }

                }

              }
         
            } //> div

          } //.layout 

        } //.form-group

      } //.m-form-search

    } // .user-chapter-history-layout

  } // .index-header-inner

} // .index-header
