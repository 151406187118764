@charset "UTF-8";

/*
#overview
inputスタイル

*/
input[type="checkbox"].pseudo, input[type="radio"].pseudo {
  opacity: 0;
}

label {
  //@include HoverOpacity();
  cursor: pointer;
}

input:focus,
textarea:focus {
  outline-offset: 0;
  outline: none;
}


/*
#styleguide
input[type="checkbox"]

```
<input type="checkbox" id="foo01" class="pseudo">
```
*/

.pseudo-checkbox {
  @include _box-sizing();
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  border: 2px solid #6E6E6E;
  vertical-align: middle;
  cursor: pointer;
  background: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;

  &.on,
  &:hover {
  }
  &.on{
    border-color: #4EA3D4;
  }

  & .checked {
    position: absolute;
    width: 20px;
    height: 20px;
    left: -2px;
    top: -2px;
    display: none;
    z-index: 1;
    background: url(#{$IMG_PATH}common/input_checked.png) center center no-repeat;
  }

  &.on .checked {
    display: block;
  }

  /*
  #styleguide
  input[type="checkbox"]

  ```
  <input type="checkbox" id="bar01" class="pseudo" disabled>
  ```
  */
  &.disabled {
    cursor: default;
    background-color: #ebebe4;
    &:hover {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }

  }

  &.focus {
    outline-offset: 0;
    outline: none;
    //box-shadow: 0 0 5px 1px $theme_color_light;
  }

  input {
    position: fixed;
    top: -26px;
    left: -26px;
    //display: none;
  }

  &.input-checkbox-switch {
    border: none;
    width: 56px;
    height: 24px;
    display: inline-block;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    background: #ccc;
    position: relative;
        -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -ms-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;

    .checked {
      display: block;
      width: 18px;
      height: 18px;
      background: #fff;
      -webkit-border-radius: 9px;
      -moz-border-radius: 9px;
      border-radius: 9px;
      position: absolute;
      left: auto;
      right: 3px;
      top: 3px;
      -webkit-transition: all .2s;
      -moz-transition: all .2s;
      -ms-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s;
    }

    &.on {
      background: #4EA3D4;
      .checked {
        -webkit-transform: translateX(-32px);
        -moz-transform: translateX(-32px);
        -ms-transform: translateX(-32px);
        -o-transform: translateX(-32px);
        transform: translateX(-32px);
      }
    }

  }

}

/*
#styleguide
input[type="radio"]

```
<input type="radio" id="foo03" name="foo" class="pseudo">
```
*/

.pseudo-radio {
  @include _box-sizing();
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  cursor: pointer;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #4EA3D4;

  &.on,
  &:hover {
    -webkit-box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.1);
  }

  .checked {
    position: absolute;
    width: 12px;
    height: 12px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    left: 3px;
    top: 3px;
    background: #4EA3D4;
    display: none;
    z-index: 1;
  }

  &.on .checked {
    display: block;
  }

  &.focus {
    outline-offset: 0;
    outline: none;
    //box-shadow: 0 0 5px 1px $theme_color_light;
  }

  /*
  #styleguide
  input[type="radio"]

  ```
  <input type="radio" id="foo04" name="foo" class="pseudo" disabled>
  ```
  */
  &.disabled {
    cursor: default;

    &:hover {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }

  }

  input {
    position: absolute;
    top: -20px;
    left: -20px;
  }

}


/** Checkbox Button */
.checkbox-btn {
   input[type="checkbox"] {
    opacity: 0;
    z-index: 1;
  }
  label {
      display: inline-block;
      line-height: 22px;
      vertical-align: middle;
      position: relative;
      padding-left: 10px;
      cursor: pointer;
      user-select: none;
      color: #424242;
  }
  label::after {
      position: absolute;
      content: "";
      width: 16px;
      height: 16px;
      left: 0;
      top: 0;
      margin-left: -18px;
      -webkit-border-radius: 3px;
      border-radius: 2px;
      //background-clip: padding-box;
      cursor: pointer
  }
  label::before {
      position: absolute;
      content: "";
      width: 12px;
      height: 5px;
      top: 4px;
      left: 2px;
      margin-left: -18px;
      border: 3px solid #ffffff;
      border-top: none;
      border-right: none;
      background: transparent;
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      opacity: 0;
      z-index: 1
  }
  label:active::before {
      opacity: 0.3
  }
  input[type="checkbox"]:checked~label::after{
      border: 2px solid #4285F4;
      background-color: #4285F4;
  }
  input[type="checkbox"]~label::after{
      border: 2px solid #787878;
      background-color: #ffffff;
  }
  input[type="checkbox"]:checked~label::before{
      opacity: 1
  }
  input[type="checkbox"]:disabled~label {
      cursor: default
  }
  input[type="checkbox"]:disabled~label::after {
      border: 2px solid #989797;
      background-color: lightgrey;
       cursor: default
  }
  input[type="checkbox"]:disabled~label::before{
      display: none
  }
}

/** Radio Button */
.radio-btn {
  input[type="radio"] {
    opacity: 0;
    z-index: 1
  }
  label {
      display: inline-block;
      line-height: 24px;
      vertical-align: middle;
      position: relative;
      padding-left: 20px;
      cursor: pointer;
      user-select: none;
      color: #424242
  }
  label::after {
      position: absolute;
      content: "";
      width: 18px;
      height: 18px;
      left: 0;
      top: 0;
      margin-left: -18px;
      background-color: white;
      border: 2px solid rgb(102,102,102);
      -webkit-border-radius: 3px;
      border-radius: 3px;
      background-clip: padding-box;
      cursor: pointer
  }
  label::before {
      position: absolute;
      content: "";
      width: 10px;
      height: 5px;
      top: 6px;
      left: 6px;
      margin-left: -18px;
      border: 2px solid rgb(102,102,102);
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      z-index: 1
  }
  label:active::before {
      opacity: 0.3
  }
  input[type="radio"]:checked+label {
      color: #000;
  }
  input[type="radio"]:checked+label::after {
      border: 2px solid #2196F3;
    background-color: #ffffff;
  }
  input[type="radio"]+label::after {
      border: 2px solid #787878;
      background-color: #ffffff;
  }
  input[type="radio"]:checked+label::before {
      opacity: 1
  }
  input[type="radio"]:disabled+label {
      color: #787878;
      cursor: default
  }

  input[type="radio"]:disabled+label::after {
      border: 2px solid #787878;
      background-color: #ffffff
  }

  input[type="radio"]:disabled+label::before {
      display: none
  }
  label::after {
      -webkit-border-radius: 50%;
      border-radius: 50%;
      background-clip: padding-box
  }
  label::before {
      width: 12px;
      height: 12px;
      border: none;
      top: 5px;
      left: 5px;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      background-clip: padding-box;
      background-color: #4285F4;
  }
}