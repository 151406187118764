@charset "UTF-8";

.site-flow {
  text-align: center;

  ul {
    li {
      vertical-align: top;
      display: inline-block;
      margin: 0 10px;
      font-size: 12px;
      position: relative;
      line-height: 1.2;

      &:after {
        @include PseudoElements();
        position: absolute;
        height: 8px;
        width: 42px;
        background: #eee;
        left: 62px;
        top: 42px;
        margin-top: -18px;
      }

      &:last-child:after {
        display: none;
      }

      & > span,
      & > a {
        width: 70px;
        display: block;
        color: #333;
      }

      & > a {
        @include HoverOpacity();
        text-decoration: none;
      }

      .ph {

        -webkit-transition: all .2s;
        -moz-transition: all .2s;
        -ms-transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s;

        position: relative;
        z-index: 1;
        display: block;
        text-align: center;
        margin: 0 auto 10px;
        height: 50px;
        width: 50px;
        border: 1px solid $theme_color_light;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
        position: relative;
        background-color: #fff;
        -webkit-background-size: 30px 30px;
        background-size: 30px 30px;
        background-repeat: no-repeat;
        background-position: center center;

        &.icn-site-basic {
          background-image: url(#{$IMG_PATH:}icon/icn_window02_b.png);
        }
        &.icn-site-contractor {
          background-image: url(#{$IMG_PATH:}icon/icn_company02_b.png);
        }
        &.icn-site-billing {
          background-image: url(#{$IMG_PATH:}icon/icn_receipt02_b.png);
        }
        &.icn-site-admin {
          background-image: url(#{$IMG_PATH:}icon/icn_admin02_b.png);
        }
        &.icn-site-area {
          background-image: url(#{$IMG_PATH:}icon/icn_mapselect02_b.png);
        }
        &.icn-site-pass {
          background-image: url(#{$IMG_PATH:}icon/icn_pass02_b.png);
        }
        &.icn-site-option {
          background-image: url(#{$IMG_PATH:}icon/icn_option02_b.png);
        }
        &.icn-site-basicconfig {
          background-image: url(#{$IMG_PATH:}icon/icn_basesetting_b.png);
        }
        &.icn-site-conf {
          background-image: url(#{$IMG_PATH:}icon/icn_conf02_b.png);
        }
        &.icn-site-comp {
          background-image: url(#{$IMG_PATH:}icon/icn_check_b.png);
        }

        &.icn-basic-info {
          background-image: url(#{$IMG_PATH:}icon/icn_window_b.png);
        }
        &.icn-add-info {
          background-image: url(#{$IMG_PATH:}icon/icn_addinfo_b.png);
        }
        &.icn-affiliation-info {
          background-image: url(#{$IMG_PATH:}icon/icn_company_b.png);
        }
        &.icn-contact-info {
          background-image: url(#{$IMG_PATH:}icon/icn_mail_b.png);
        }
        &.icn-user-option {
          background-image: url(#{$IMG_PATH:}icon/icn_account_b.png);
        }
        &.icn-admin-option {
          background-image: url(#{$IMG_PATH:}icon/icn_admin_b.png);
        }

        &.icn-search {
          background-image: url(#{$IMG_PATH:}icon/icn_search_b.png);
        }

        &.icn-user-select {
          background-image: url(#{$IMG_PATH:}icon/icn_user_select_b.png);
        }
        &.icn-asign {
          background-image: url(#{$IMG_PATH:}icon/icn_asign_b.png);
        }


        &.icn-course {
          background-image: url(#{$IMG_PATH:}icon/icn_courses_b.png);
        }
        &.icn-lesson-chapter {
          background-image: url(#{$IMG_PATH:}icon/icn_lesson_k.png);
        }
        &.icn-detail {
          background-image: url(#{$IMG_PATH:}icon/icn_docs_k.png);
        }


      }

      &.current {

        .ph {
          background-color: $theme_color_light;

          &.icn-site-basic {
            background-image: url(#{$IMG_PATH:}icon/icn_window02_w.png);
          }
          &.icn-site-contractor {
            background-image: url(#{$IMG_PATH:}icon/icn_company02_w.png);
          }
          &.icn-site-billing {
            background-image: url(#{$IMG_PATH:}icon/icn_receipt02_w.png);
          }
          &.icn-site-admin {
            background-image: url(#{$IMG_PATH:}icon/icn_admin02_w.png);
          }
          &.icn-site-area {
            background-image: url(#{$IMG_PATH:}icon/icn_mapselect02_w.png);
          }
          &.icn-site-pass {
            background-image: url(#{$IMG_PATH:}icon/icn_pass02_w.png);
          }
          &.icn-site-option {
            background-image: url(#{$IMG_PATH:}icon/icn_option02_w.png);
          }
          &.icn-site-basicconfig {
            background-image: url(#{$IMG_PATH:}icon/icn_basesetting_w.png);
          }
          &.icn-site-conf {
            background-image: url(#{$IMG_PATH:}icon/icn_conf02_w.png);
          }
          &.icn-site-comp {
            background-image: url(#{$IMG_PATH:}icon/icn_check_w.png);
          }

          &.icn-basic-info {
            background-image: url(#{$IMG_PATH:}icon/icn_window_w.png);
          }
          &.icn-add-info {
            background-image: url(#{$IMG_PATH:}icon/icn_addinfo_w.png);
          }
          &.icn-affiliation-info {
            background-image: url(#{$IMG_PATH:}icon/icn_company_w.png);
          }
          &.icn-contact-info {
            background-image: url(#{$IMG_PATH:}icon/icn_mail_w.png);
          }
          &.icn-user-option {
            background-image: url(#{$IMG_PATH:}icon/icn_account_w.png);
          }
          &.icn-admin-option {
            background-image: url(#{$IMG_PATH:}icon/icn_admin_w.png);
          }

          &.icn-search {
            background-image: url(#{$IMG_PATH:}icon/icn_search_w.png);
          }
          &.icn-user-select {
            background-image: url(#{$IMG_PATH:}icon/icn_user_select_w.png);
          }
          &.icn-asign {
            background-image: url(#{$IMG_PATH:}icon/icn_asign_w.png);
          }

          &.icn-course {
            background-image: url(#{$IMG_PATH:}icon/icn_courses_w.png);
          }
          &.icn-lesson-chapter {
            background-image: url(#{$IMG_PATH:}icon/icn_lesson_w.png);
          }
          &.icn-detail {
            background-image: url(#{$IMG_PATH:}icon/icn_docs_w.png);
          }


        }
      }

      &.error {
        color: red;

        a {
          color: red;
        }

        .ph {
          background-color: red;
          border-color: red;

          &.icn-site-basic {
            background-image: url(#{$IMG_PATH:}icon/icn_window02_w.png);
          }
          &.icn-site-contractor {
            background-image: url(#{$IMG_PATH:}icon/icn_company02_w.png);
          }
          &.icn-site-billing {
            background-image: url(#{$IMG_PATH:}icon/icn_receipt02_w.png);
          }
          &.icn-site-admin {
            background-image: url(#{$IMG_PATH:}icon/icn_admin02_w.png);
          }
          &.icn-site-area {
            background-image: url(#{$IMG_PATH:}icon/icn_mapselect02_w.png);
          }
          &.icn-site-pass {
            background-image: url(#{$IMG_PATH:}icon/icn_pass02_w.png);
          }
          &.icn-site-option {
            background-image: url(#{$IMG_PATH:}icon/icn_option02_w.png);
          }
          &.icn-site-basicconfig {
            background-image: url(#{$IMG_PATH:}icon/icn_basesetting_w.png);
          }
          &.icn-site-conf {
            background-image: url(#{$IMG_PATH:}icon/icn_conf02_w.png);
          }
          &.icn-site-comp {
            background-image: url(#{$IMG_PATH:}icon/icn_check_w.png);
          }

          &.icn-basic-info {
            background-image: url(#{$IMG_PATH:}icon/icn_window_w.png);
          }
          &.icn-add-info {
            background-image: url(#{$IMG_PATH:}icon/icn_addinfo_w.png);
          }
          &.icn-affiliation-info {
            background-image: url(#{$IMG_PATH:}icon/icn_company_w.png);
          }
          &.icn-contact-info {
            background-image: url(#{$IMG_PATH:}icon/icn_mail_w.png);
          }
          &.icn-user-option {
            background-image: url(#{$IMG_PATH:}icon/icn_account_w.png);
          }
          &.icn-admin-option {
            background-image: url(#{$IMG_PATH:}icon/icn_admin_w.png);
          }
        }
      }

    }
  }
}

.c-site-header {
  background: #fff;
  border-bottom: 1px solid #ccc;

  .c-site-header-inner {
    padding: 20px;

  }
}