@charset "UTF-8";
.report-score-list-header {
  background: #fff;
  .report-score-list-header-inner {
    position: relative;
    padding-top: 20px;
    .report-score-list-header-layout {
      height: 100%;
      min-height: 1px;
      .hide {
        display: none;
      }
      .m-form-search {
        padding-top: 10px;
        .form-btns {
          display: flex;
          padding-top: 10px;
          justify-content: center;
          >div {
            margin: 0px;
          }
        }
        .form-group {
          .inner-resize {
            padding: 0px;
            min-width: 890px;
            margin: 0 auto;
            .form-row {
              display: flex;
              justify-content: center;
              align-content: center;
              &.start-left {
                justify-content: flex-start;
                // padding-left: 16px;
              }
              .btn-inpt {
                width: 100%;
                text-align: right;
                   .sel.sel-size-auto {
                          height: 36px;
                          width: 170px !important;
                          width: 170px;
                          height: 36px;
                          background-color: white;
                          -webkit-border-radius: 0px;
                          -moz-border-radius: 0px;
                          border-radius: 0px;
                          color: #468CC8;
                          border: 1px solid;
                          .sel-txt {
                              margin-top: 0px !important;
                              font-weight: normal !important;
                              font-size: 13px !important;
                              padding-left: 10px;
                              padding-right: 17px;
                              background-size: 13px;
                              background-image: url(#{$IMG_PATH}icon/icn_selectbtn_bottom_b.png);
                              span{
                                  text-overflow: ellipsis;
                                  white-space: nowrap;
                                  overflow: hidden;
                              }
                          }
                      }
                input[type=button] {
                  width: 240px;
                  background-color: #fff;
                  border: 1px solid #c8c8c8;
                  height: 33px;
                  font-size: 16px;
                  color: #333;
                  font-weight: normal;
                  -webkit-border-radius: 5px;
                  -moz-border-radius: 5px;
                  border-radius: 5px;
                  text-align: center;
                }
              }
              .select-upload {
                      float: right;
                      margin-right: 5px;
                      .sel {
                          background-color: white;
                          -webkit-border-radius: 0px;
                          -moz-border-radius: 0px;
                          border-radius: 0px;
                          color: #468CC8;
                          border: 1px solid;
                          width: 200px;
                      }
                      .sel.sel-size-auto {
                          height: 36px;
                          width: 170px !important;
                          .sel-txt {
                              padding-left: 10px;
                              padding-right: 17px;
                              background-size: 18px;
                              background-image: url(#{$IMG_PATH}icon/icn_selectbtn_bottom_b.png);
                              span{
                                  text-overflow: ellipsis;
                                  white-space: nowrap;
                                  overflow: hidden;
                              }
                          }
                      }
                  } //select-upload
              .inpt {
                display: flex;
                align-items: center;
                width: 100%;
                &.f-end {
                  justify-content: center;
                }
                .title {
                  display: flex;
                  min-width: 85px;
                  // justify-content: flex-end;
                  padding-top: 10px;
                  align-items: center;
                  padding-right: 5px;
                  &.size-l {
                    min-width: 150px;
                  }
                  &.no-width {
                    min-width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding-left: 2px;
                  }
                }
                .c-name {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                  span {
                    white-space: pre !important;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    padding-top: 10px;
                  }
                }
                .div-err {
                  height: 20px !important;
                  &.size-l {
                    margin-left: 120px !important;
                  }
                  .error {
                    height: 100% !important;
                    &.course-name-err {
                      text-align: center;
                    }
                    &.rate-err {
                      padding-left: 90px;
                    }
                    &:first-child {
                      margin-bottom: 0px !important;
                    }
                  }
                }
              } //> div
            }
            .col1 {
              width: 31.3%;
              &.spc-right {
                margin-right: 3%;
              }
            }
            table {
              width: 100%;
              .opt-left {
                //border: solid 1px #c5c5c5;
                margin-bottom: 10px;
                min-width: 40%;
                align-items: center;
                padding: 5px;
                display: flex;
                justify-content: flex-end;
                font-size: 14px;
                .title{
                  //width: 100%;
                  //width: 20%;
                  min-width: 85px;
                  padding-left: 10px;
                }
                .status-rd,
                .status-chk {
                  width: 100%;
                }
              }
              .opt-right {
                min-width: 40%;
                margin-bottom: 10px;
                //border: solid 1px #c5c5c5;
                align-items: center;
                padding: 5px;
                display: flex;
                justify-content: flex-end;
                font-size: 14px;
                .title{
                  //width: 30%;
                  min-width: 105px;
                  padding-left: 10px;
                }
                .status-rd-ver {
                  width: 100%;
                }
              }
              .opt-space {
                width: 5%;
                min-width: 50px;
              }
              .status-rd {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                label {
                  padding-left: 24px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  div {
                    margin-right: 5px;
                  }
                }
                .rd-btn {
                  // min-width: 100px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  label {
                    padding-left: 24px;
                    &::after {
                      top: 5px;
                      left: 15px;
                      width: 14px !important;
                      height: 14px !important;
                    }
                    &::before {
                      top: 8px;
                      left: 17px;
                      width: 11px !important;
                      height: 5px !important;
                    }
                  }
                }
              }
              .status-chk {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                flex-direction: row;
                * {
                  height: 32px;
                }
                label {
                  padding-left: 22px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                .checkbox-btn {
                  //min-width: 105px;
                  min-width: 90px;
                  display: flex;
                  align-items: center;
                  // justify-content: center;
                  label {
                    padding-left: 20px;
                    &::after {
                      top: 5px;
                      left: 15px;
                      width: 14px !important;
                      height: 14px !important;
                    }
                    &::before {
                      top: 8px;
                      left: 17px;
                      width: 11px !important;
                      height: 5px !important;
                    }
                  }
                }
              }
              .status-rd-ver {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                flex-direction: row;
                label {
                  padding-left: 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  div {
                    margin-right: 5px;
                  }
                }
                .rd-btn {
                  // width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  label {
                    // padding-left: 15px;
                    &::after {
                      top: 5px;
                      left: 15px;
                      width: 14px !important;
                      height: 14px !important;
                    }
                    &::before {
                      top: 8px;
                      left: 17px;
                      width: 11px !important;
                      height: 5px !important;
                    }
                  }
                }
              }
              .note {
                font-size: 11px;
                margin-top: -10px;
              }
            }
          }
        } //.form-group
      } //.m-form-search
    } // .course-user-history-layout
  } // .index-header-inner
} // .index-header