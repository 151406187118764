@charset "UTF-8";

.page-log-search-sign-in {
  height: 100%;
 
  .m-search-c-inner {
    iframe {
      width: 100vw;
      height: 100vh;
    }
  }

  .page-inner {
    .log-search-sign-in-header {
      background: #fff;
      position: relative;
      padding-top: 20px;
    }

    background: $background_theme_color_contents;
    height: 100%;

    .page-contents {
      width: 100%;
      height: 100%;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      .m-form-search {
        padding-top: 10px;

        .form-btns {
          display: flex;
          justify-content: center;

          .div-form-btns {
            margin: 0px;
          }
          .div-downLoad-err {
            position: absolute;
            right: 12px;
            margin: 20px 0 0 0;
            .downLoad-err {
              font-size: 9px;
            }
          }
        }

        .error {
          margin: 1px 0 1px 0;
        }

        .form-group {

          margin-top: 0px;

          .layout {
            display: flex;
            justify-content: center;
            align-items: flex-start;
          }

          .inner-resize {
            padding: 0px;
            min-width: 890px;
            margin: 0 auto;
            max-width: 1500px;

            .col1 {
              width: 32% !important;
              
              &.attend-stt {
                padding-left: 25px;

                img {
                  max-width: 30px;
                  min-width: 30px;
                  width: 30px;
                  max-height: 30px;
                  min-height: 30px;
                  height: 30px;
                }
                
              }
                
              .status-rd {
                * {
                  height: 20px;
                }

                span {
                  height: 12px;
                }

                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;

                label {
                  padding-left: 24px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  div {
                    margin-right: 5px;
                  }
                }

                .rd-btn {
                  min-width: 100px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;

                  label {
                    padding-left: 10px;

                    &::after {
                      top: 5px;
                      left: 15px;
                      width: 14px !important;
                      height: 14px !important;
                    }

                    &::before {
                      top: 8px;
                      left: 17px;
                      width: 11px !important;
                      height: 5px !important;
                    }
                  }
                }
              }

              .status-chk {
                display: flex;
                justify-content: center;
                align-items: center;

                label {
                  padding-left: 15px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                .checkbox-btn {
                  display: flex;
                  //align-items: center;
                  //justify-content: center;
                  justify-content: left;
                  min-width: 90px;

                  label {
                    padding-left: 20px;

                    &::after {
                      top: 5px;
                      left: 15px;
                      width: 14px !important;
                      height: 14px !important;
                    }

                    &::before {
                      top: 8px;
                      left: 17px;
                      width: 11px !important;
                      height: 5px !important;
                    }
                  }
                }
              }

              .div-err {
                .error {
                  padding-left: 92px;
                  padding-bottom: 3px;
                }
              }
              &:last-child {
                .error {
                  padding-bottom: 3px;
                }
              }
            }

            .col2 {
              width: 20% !important;
              padding-left: 0px;

              .div-err {
                .error {
                  width: 210px;
                  padding-bottom: 3px;
                  padding-left: 70px;
                }
              }
              &:last-child {
                .error {
                  padding-bottom: 3px;
                }
              }
            }

            .col3 {
              width: 48% !important;
              padding-left: 0px;

                img {
                  max-width: 30px;
                  min-width: 30px;
                  width: 30px;
                  max-height: 30px;
                  min-height: 30px;
                  height: 30px;
                }
               
               .div-err {
                 display: flex;
                 min-height: 60px;
                 
                 .div-err-from {
                    padding-bottom: 3px;
                    padding-left: 105px;
                    min-width: 160px;
                  }
                  .div-err-to{
                    padding-bottom: 3px;
                    padding-left: 6%;
                    min-width: 160px;
                  }
               }
            }
          }

          .layout {
            margin-bottom: 0px;
            margin-top: 42px;

            div {
              margin-bottom: 0px;

              * {
                height: 32px;
              }

              .inpt {
                display: flex;
                align-items: center;
                width: 100%;
                
                &.flex-end{
                  justify-content: flex-end;
                }

                .td-input-cal {
                  display: flex;

                  input {
                    max-width: 170px;
                  }

                  a {
                    display: block;
                    padding-left: 5px;
                  }

                  span {
                    padding: 0 5px;
                    line-height: 35px;
                  }

                }
                
                .title {
                  display: flex;
                  min-width: 65px;
                  justify-content: flex-end;
                  align-items: center;
                  padding-right: 5px;
                  text-align: right;

                  &.size-l {
                    min-width: 100px;

                    &.st-left {
                      justify-content: flex-start;
                      padding-left: 20px;
                      min-width: 98px;
                    }
                  }

                  &.no-width {
                    min-width: 22px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-right: 0px;
                  }

                }
                .chosen {
                  min-width: 60px;
                  width: 100%;

                  .sel {
                    width: 100%;
                    border: 1px solid #c8c8c8;
                    -moz-border-radius: 0px;
                    border-radius: 0px;

                    p {
                      font-size: inherit;
                      font-weight: normal;
                      margin-top: 0;
                      background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
                      background-size: 12px;
                      line-height: 32px;
                      white-space: nowrap;
                      overflow: hidden;
                    }

                  }

                  select {
                    width: 100%;
                  }

                  &.catology {
                    width: 35%;
                    select {
                      display: inline-block;
                    }
                  }
                }

                .txt {
                  width: 100%;

                  &.s-right {
                    padding-right: 20px;
                  }

                }

                .btn-c-search {
                  width: 40px;
                  margin-left: 5px;
                }
              }

              //.inpt

              .div-err {

                height: auto !important;
                min-height: 20px;

                &.size-l {
                  margin-left: 120px !important;
                }

                .error {
                  height: 100% !important;
                  white-space: normal;
                  margin-bottom: 0px !important;
                }

              }

            }

            //> div

          }

          //.layout

        }

        //.form-group

      }

      //.m-form-search

      input,
      a {
        vertical-align: middle;
      }
    }

    .site-header {
      display: flex;
      padding-bottom: 5px;

      .except {
        height: 71px;
        display: none;
      }

      .download {
        position: absolute;
        right: 0px;
        margin-top: 5px;
        margin-right: 10px;
        min-height: 30px;
        padding-bottom: 5px;
      }

      .result-txt {
        margin-top: 5px;
        margin-left: 10px;
        min-height: 30px;
        display: flex;
        align-items: center;
      }

    }

    .site-result {
      @include search_table_f;
      padding: 0px !important;
      margin-right: 10px;
      margin-left: 10px;
      height: 61%;
      position: relative;

      @media screen and (max-height: 980px) {
        height: 65%;
      }

      @media screen and (max-height: 930px) {
        height: 64%;
      }

      @media screen and (max-height: 900px) {
        height: 62%;
      }

      @media screen and (max-height: 850px) {
        height: 60%;
      }

      @media screen and (max-height: 800px) {
        height: 59%;
      }

      @media screen and (max-height: 780px) {
        height: 57%;
      }

      @media screen and (max-height: 750px) {
        height: 55%;
      }

      @media screen and (max-height: 700px) {
        height: 52%;
      }

      @media screen and (max-height: 650px) {
        height: 48%;
      }

      @media screen and (max-height: 635px) {
        height: 45%;
      }

      @media screen and (max-height: 600px) {
        height: 39%;
      }

      .table-header {
        table-layout: auto;

        .col-info {
          min-width: 66px;
        }

        .col-login-id {
          min-width: 73px;
        }

        .col-user-name {
          min-width: 84px;
        }

        .col-user-agent {
          min-width: 134px;
        }

        .col-ip-address {
          min-width: 105px;
        }

        .col-access-date {
          min-width: 97px;
        }

        tr {
          width: 100%;
          position: fixed;

          th {
            padding: 5px 0;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
          }
        }
      }

      .txt-left {
        text-align: left !important;
        padding-left: 10px !important;
      }

      .table-result-nodata {
        height: 100%;

        tr {
          td {
            border: 0px solid #fafafa;
            color: #AFAFAF;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;

            span {
              position: relative;
            }
          }
        }
      }

      .table-result {
        table-layout: auto;

        tr {
          td:not(.row-ope) {
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
          }

          .red {
            color: #e7001f;
          }

          td {
            padding: 4px;

            &.col-info {
              width: 12%;
            }

            &.col-login-id {
              width: 13%;
            }

            &.col-user-name {
              width: 15%;
            }

            &.col-user-agent {
              width: 24%;
              max-width: 100px;
            }

            &.col-ip-address {
              width: 19%;
            }

            &.col-access-date {
              width: 17%;
            }
            .multi-space-ellipsis {
              white-space: nowrap !important;
              overflow: hidden;
              text-overflow: ellipsis !important;
              word-wrap: normal !important;
            }
          }
        }
      }

      table {

        tr:not(:first-child):hover,
        tr.selected,
        .hover {
          .btn-submit-delete {
            background: url(#{$IMG_PATH}icon/icn_trash_w.png) 20px center no-repeat;
            -webkit-background-size: 60%;
            background-size: 60%;
            background-position-x: 50%;
          }

          .btn-submit-setting {
            background: url(#{$IMG_PATH}icon/icn_edit_w.png) 15px center no-repeat;
            -webkit-background-size: 60%;
            background-size: 60%;
            background-position-x: 50%;
          }
        }

        tr.selected,
        .hover {
          .red {
            color: #d10064;
          }
        }

        tr:hover {
          .red {
            color: #d10064;
          }
        }
      }
    }

    .site-list-inner {
      height: 100%;
      background-color: #fff;
    }

    .site-list-outer {
      width: 100%;
      height: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    .scroll-area {
      &.ps-active-y {
        padding-right: 16.2px;
        margin-right: -16.2px;
      }

      .ps-scrollbar-y-rail {
        right: 16.2px;
      }

      height: 100%;
    }

    // .scroll-area
  }
}

.btn-download-modal {
  background-image: url(#{$IMG_PATH}icon/icn_download_o.png);
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px 20px;
  padding-left: 40px;
}
