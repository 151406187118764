@charset "UTF-8";
.page-courses-user {
    .page-inner {
        &.ps-active-y {
            .courses-user-layout {
                margin-bottom: 20px;
            }
        }
        .page-contents {
            width: 100%;
            height: 90%;
            padding: 0px 10px 10px;
            margin: auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            .courses-user-layout {
                .msg-assign {
                    padding: 8px 8px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }
                position: relative;
                z-index: 1;
                @include LayoutTable();
                width: 95%;
                height: 90%;
                min-height: 1px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                margin: auto;
                &>div {
                    @include LayoutCell(top);
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    height: 100%;
                    min-height: 1px;
                }
                .assign-member-search {
                    position: relative;
                    .assign-cell, .assign-member-cell {
                        height: calc( 100% - 46px);
                        min-height: 1px;
                    }
                    .assign-cell {
                        float: left;
                        left: 0;
                        width: 39%;
                    }
                    .assign-member-cell {
                        float: right;
                        right: 0;
                        width: 61%;
                    }
                }
                input[type=text] {
                    border: #A0A0A0 solid 1px;
                }
                .title {
                    color: grey;
                    font-size: 20px;
                    margin-bottom: 25px;
                    vertical-align: middle;
                    span, .condition-search {
                        display: inline-block;
                    }
                    .condition-search {
                        vertical-align: middle;
                        height: 100%;
                        font-size: 14px;
                        input[type="radio"] {
                            display: none;
                        }
                        label {
                            display: inline-block;
                            width: 90px;
                            height: 20px;
                            vertical-align: middle;
                            text-align: center;
                            padding-top: 2px;
                            line-height: 20px;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                        }
                        input:checked+label {
                            color: #fff;
                            border: none;
                            background-color: #82BEF0;
                        }
                        input+label {
                            color: #6E6E6E;
                            border: #A0A0A0 solid 1px;
                            -webkit-border-radius: 2px;
                            -moz-border-radius: 2px;
                            border-radius: 2px;
                        }
                    }
                    .member-search {
                        width: 100%;
                        padding-top: 4px;
                        overflow: hidden;
                    }
                    input[type=text] {
                        width: calc(100% - 120px);
                        border: 1px solid #A0A0A0;
                        box-sizing: border-box;
                        color: #6E6E6E;
                        font-size: 16px;
                        text-indent: 5px;
                        height: 40px;
                    }
                    input[type=submit] {
                        float: right;
                        background-color: #82BEF0 !important;
                        box-sizing: border-box;
                        background: url(#{$IMG_PATH}icon/icn_search_w.png) left center no-repeat;
                        background-position-x: 50%;
                        -webkit-background-size: 25px;
                        background-size: 25px;
                        width: 110px;
                        height: 40px;
                    }
                }
                .cell-inner {
                    height: 100%;
                    min-height: 1px;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    position: relative;
                    @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: .001dpcm) {
                        // chrome only
                        -webkit-box-sizing: content-box;
                        -moz-box-sizing: content-box;
                        box-sizing: content-box;
                    }
                    .ps-container.ps-active-y>.ps-scrollbar-y-rail {
                        background: none;
                        right: 0px!important;
                    }
                    .courses-user-list-outer {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 95%;
                        padding-bottom: 42px;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        .courses-user-list {
                            min-height: 265px;
                            height: 100%;
                            overflow-y: hidden;
                            padding-right: 15px;
                            .courses-user-list-inner {
                                min-height: 100%;
                                &>span {
                                    padding: 8px 10px;
                                    font-size: 16px;
                                    position: absolute;
                                }
                            }
                            li {
                                position: relative;
                                border-bottom: 1px solid #A0A0A0;
                                background: #fff;
                                height: 52px!important;
                                .inner>.inner-box {
                                    position: relative;
                                    display: block;
                                    font-size: 13px;
                                    color: $font_color_default;
                                    &:hover {
                                        text-decoration: none;
                                    }
                                    &>div {
                                        padding: 8px 30px 6px 50px;
                                    }
                                    .txt,
                                    .txt-assign {
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        line-height: 35px;
                                        min-height: 35px;
                                        font-size: 14px;
                                    }
                                    .date,
                                    .assign-type {
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                }
                                .inner>.inner-box:before {
                                    @include PseudoElements();
                                    position: absolute;
                                    display: block;
                                    width: 40px;
                                    height: 40px;
                                    left: 5px;
                                    top: 55%;
                                    margin-top: -22px;
                                    opacity: .6;
                                }
                                &.icn-users .inner>.inner-box:before {
                                    background: url(#{$IMG_PATH}icon/icn_user_k.png) left center no-repeat;
                                    -webkit-background-size: contain;
                                    background-size: contain;
                                }
                                &.icn-user-groups .inner>.inner-box:before {
                                    background: url(#{$IMG_PATH}icon/icn_usergroup_k.png) left center no-repeat;
                                    -webkit-background-size: contain;
                                    background-size: contain;
                                }
                                .btn-remove {
                                    @include HoverOpacity();
                                    position: absolute;
                                    display: block;
                                    right: 10px;
                                    top: 50%;
                                    margin-top: -10px;
                                    width: 20px;
                                    height: 20px;
                                    z-index: 1;
                                    -webkit-border-radius: 100%;
                                    -moz-border-radius: 100%;
                                    border-radius: 100%;
                                    -webkit-box-sizing: border-box;
                                    -moz-box-sizing: border-box;
                                    box-sizing: border-box;
                                    span {
                                        position: relative;
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                        -webkit-border-radius: 100%;
                                        -moz-border-radius: 100%;
                                        border-radius: 100%;
                                        &:after, &:before {
                                            @include PseudoElements();
                                            position: absolute;
                                            left: 50%;
                                            top: 50%;
                                            display: block;
                                            width: 2px;
                                            height: 12px;
                                            margin-left: -1px;
                                            margin-top: -6px;
                                            background: #6E6E6E;
                                        }
                                        &:before {
                                            -webkit-transform: rotate(-45deg);
                                            -moz-transform: rotate(-45deg);
                                            -ms-transform: rotate(-45deg);
                                            -o-transform: rotate(-45deg);
                                            transform: rotate(-45deg);
                                        }
                                        &:after {
                                            -webkit-transform: rotate(45deg);
                                            -moz-transform: rotate(45deg);
                                            -ms-transform: rotate(45deg);
                                            -o-transform: rotate(45deg);
                                            transform: rotate(45deg);
                                        }
                                    }
                                }
                                &:nth-child(even) {
                                    .inner>.inner-box {
                                        &>div {
                                            background: #FCFCFC;
                                        }
                                    }
                                }
                                &.update {
                                    border-color:  rgba(119, 119, 119, 0.3);
                                    .inner .inner-box {
                                        color: #fff;
                                        &>div {
                                            background: #DC780A;
                                        }
                                        &:before {
                                            opacity: 1;
                                        }
                                    }
                                    .btn-remove {
                                        span {
                                            &:after, &:before {
                                                background: #fff;
                                            }
                                        }
                                    }
                                    &.icn-users .inner>.inner-box:before {
                                        background: url(#{$IMG_PATH}icon/icn_user_w.png) left center no-repeat;
                                        -webkit-background-size: contain;
                                        background-size: contain;
                                    }
                                    &.icn-user-groups .inner>.inner-box:before {
                                        background: url(#{$IMG_PATH}icon/icn_usergroup_w.png) left center no-repeat;
                                        -webkit-background-size: contain;
                                        background-size: contain;
                                    }
                                }
                                &.selected {
                                    .inner:after {
                                        @include PseudoElements();
                                        position: absolute;
                                        right: -15px;
                                        top: 50%;
                                        margin-top: -26px;
                                        display: block;
                                        height: 51px;
                                        width: 15px;
                                        background: url(#{$IMG_PATH}common/balloon_list.png) left center no-repeat;
                                        -webkit-background-size: 100% 100%;
                                        background-size: 100% 100%;
                                    }
                                    .inner .inner-box {
                                        color: #fff;
                                        &>div {
                                            background: #82BEF0;
                                        }
                                    }
                                    &.icn-users .inner>.inner-box:before {
                                        background: url(#{$IMG_PATH}icon/icn_user_w.png) left center no-repeat;
                                        -webkit-background-size: contain;
                                        background-size: contain;
                                    }
                                    &.icn-user-groups .inner>.inner-box:before {
                                        background: url(#{$IMG_PATH}icon/icn_usergroup_w.png) left center no-repeat;
                                        -webkit-background-size: contain;
                                        background-size: contain;
                                    }
                                }
                                &.remove {
                                    border-color:  rgba(119, 119, 119, 0.3);
                                    .inner .inner-box {
                                        color: #fff;
                                        &>div {
                                            background: #A0A0A0;
                                        }
                                        &:before {
                                            opacity: 1;
                                        }
                                    }
                                    &.icn-users .inner>.inner-box:before {
                                        background: url(#{$IMG_PATH}icon/icn_user_w.png) left center no-repeat;
                                        -webkit-background-size: contain;
                                        background-size: contain;
                                    }
                                    &.icn-user-groups .inner>.inner-box:before {
                                        background: url(#{$IMG_PATH}icon/icn_usergroup_w.png) left center no-repeat;
                                        -webkit-background-size: contain;
                                        background-size: contain;
                                    }
                                }
                            } // li
                        } // .courses-user-list
                    } // .courses-user-list-outer
                } // .cell-inner
                .assign-cell {
                    .cell-inner {
                        .courses-user-list-outer {
                            .courses-user-list {
                                overflow-y: visible;
                                .courses-user-list-inner {
                                    border: #A0A0A0 solid 1px;
                                }
                                li {
                                    .inner {
                                        @include HoverOpacity();
                                    }
                                    .inner>.inner-box {
                                        &>div {
                                            padding: 8px 10px 9px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .assign-member-cell {
                    .courses-user-list {
                        border: #A0A0A0 solid 1px;
                    }
                    .courses-user-list-inner {
                        ul {
                            display: none;
                        }
                    }
                    .cell-inner .courses-user-list-outer .courses-user-list {
                        padding-right: 0px;
                        .courses-user-list-inner {
                            .inner:after {
                                content: none;
                            }
                        }
                        li {
                            .inner {
                                a {
                                    cursor: default;
                                    &.hide {
                                        display: none;
                                    }
                                    &>div {
                                        height: 38px;
                                    }
                                }
                                .btn-remove {
                                    cursor: pointer;
                                }
                            }
                            .inner>.inner-box {
                                .txt {
                                    line-height: 20px;
                                    min-height: 20px;
                                }
                                .txt-assign {
                                    line-height: 38px;
                                    min-height: 38px;
                                }
                                .date {
                                    font-size: 11px;
                                }
                            }
                        }
                    }
                }
                .assign-button-cell {
                    width: 90px;
                    .cell-inner {
                        a {
                            width: 60px;
                            height: 60px;
                            text-align: center;
                            line-height: 60px;
                            text-decoration: none;
                            position: absolute;
                            display: block;
                            top: 45%;
                            left: 15%;
                            border: #A0A0A0 solid 1px;
                            background: url(#{$IMG_PATH}icon/icn_back_k.png) center center no-repeat;
                            -webkit-background-size: 40px;
                            background-size: 40px;
                            -webkit-border-radius: 5px;
                            -moz-border-radius: 5px;
                            border-radius: 5px;
                            @include HoverOpacityTrans();
                        }
                    }
                }
                .none-assign-member-cell {
                    .cell-inner {
                        .courses-user-list-outer {
                            padding-bottom: 180px;
                            .courses-user-list {
                                min-height: 168px;
                                border: #A0A0A0 solid 1px;
                                padding-right: 0px;
                                margin-bottom: 12px;
                                .disabled {
                                    &>div.inner {
                                        opacity: .3;
                                    }
                                }
                                li {
                                    .inner>.inner-box {
                                        .txt {
                                            line-height: 20px;
                                            min-height: 20px;
                                        }
                                    }
                                    .assign-type {
                                        font-size: 11px;
                                    }
                                    .inner>a {
                                        &>div {
                                            height: 38px;
                                        }
                                    }
                                }
                            }
                            .assign-period {
                                padding: 11px 10px;
                                border: #A0A0A0 solid 1px;
                                font-size: 12px;
                                .title-period {
                                    padding-bottom: 10px;
                                    label {
                                        float: right;
                                    }
                                }
                            }
                            .period-input {
                                width: 100%;
                                display: table;
                                img {
                                    width: 25px;
                                    height: 25px;
                                }
                                &>div {
                                    display: table-cell;
                                    text-align: center;
                                }
                                &>div>span {
                                    padding: 0px 5px 0px 5px;
                                }
                                .start,
                                .end {
                                    position: relative;
                                    input[type=text] {
                                        width: calc(100% - 40px);
                                        padding: 3px 33px 4px 5px;
                                    }
                                    input[type=text]:disabled+a{
                                        pointer-events: none;
                                    }
                                    input[type=text]:disabled{
                                        background-color: lightgrey;
                                    }
                                    a {
                                        border: 1px solid #A0A0A0;
                                        padding: 0px 2px;
                                        position: absolute;
                                        bottom: 0px;
                                        right: 0px;
                                        @include HoverOpacityTrans();
                                    }
                                }
                            }
                        }
                    }
                }
            } // .courses-user-layout
            .refrash-date {
              position: relative;
              border: #A0A0A0 solid 1px;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              margin: auto;
              margin-left: 2.5%;
              padding: 10px;
              width: 44.5%;
            
            }
            .btn-confirm-assign {
                position: relative;
                z-index: 1;
                width: 95%;
                margin: auto;
                margin-top: 40px;
                a {
                    margin: auto;
                    text-align: center;
                    border: #468CC8 solid 1px;
                    -webkit-border-radius: 2px;
                    -moz-border-radius: 2px;
                    border-radius: 2px;
                    display: block;
                    width: 150px;
                    height: 35px;
                    line-height: 35px;
                    color: #468CC8;
                    @include HoverOpacityTrans();
                }
            }
        } // .page-contents
        @media screen and (min-width: 1280px) {
            .page-contents {
                height: 95%;
        }
        }
        @media screen and (min-width: 1366px) {
            .page-contents {
                height: 85%;
        }
        }
        @media screen and (min-width: 1920px) {
            .page-contents {
                height: 86%;
        }
        }
    } // .page-inner
} // .page-courses-user