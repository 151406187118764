@charset "UTF-8";

.container.educationPackUserManage {
	.header {
		.layout {
			padding: 20px;
			
			a img {
				display: inline-block;
				height: 20px;
				width: 20px;
			}
		}
	}
	
	
	.charCodeSelection {
		display: flex;
		flex-direction: row;
		padding-left: 30px;
		margin: 15px 0;
		
		.charCodeLabel {
			position: relative;
			width: 100px;
			
			span {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
			}
		}
		
		.chosen {
			width: 130px;
		}
	}
	
	.downloadTypeSelection {
		padding-left: 30px;
		
		.chosen {
			width: 200px;
		}
	}
	
	.CSVFileImport {
		display: block;
		flex-direction: row;
		padding-left: 30px;
		margin-top: 30px;

		.CSVFileImportLabel {
			border-top: 1px solid #c8c8c8;
			padding-top: 20px;
			margin-bottom: 10px;

			span {
				margin-bottom: 10px;
				font-size: 18px;
			}
		}

		.fileSelectionButton {
			margin-bottom: 20px;
		}
		
		.selectionStatus {
			position: relative;
			width: 100%;
			margin-left: 10px;
			
			span {
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
			}
		}

		.usageLabel {
			border-top: 1px solid #c8c8c8;
			padding-top: 20px;
			padding-bottom: 5px;

			.managerUsageLabel {
				font-size: 18px;
			}
			.LeanerUsageLabel {
				font-size: 18px;
				margin-top: 10px;
			}
			span {
				font-size: 14px;
				margin-top: 5px;
				padding-left: 20px;
			}
		}
	}
		
	.chosen {
		margin: 0;
		height: 40px;
		
		.init {
		display: block !important;
		}

		.sel {
			height: 100%;
			width: 100%;
			border: 1px solid #c8c8c8;
			-moz-border-radius: 0px;
			border-radius: 0px;

			p.sel-txt {
				font-size: inherit;
				font-weight: normal;
				margin-top: 0;
				background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
				background-size: 12px;
				line-height: 32px;
				white-space: nowrap;
				overflow: hidden;
				position: relative;
				
				span {
					position: absolute;
					top: 50%;
					transform: translateY(-40%);
				}
			}
		}
	}
	
	.educationPackUserImportModal {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 500;
		background: rgba(0, 0, 0, .5);
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		display: none;
		opacity: 0;

		.educationPackUserImportModal-inner {
			width: 100%;
			height: 100%;

			iframe {
				width: 100%;
				height: 100%;
			}
		}
	}
	.epManage-result-header {
		margin-top: 0px;
		padding-bottom: 5px;
	}

	.epManage-search {
		height: 100%;

		.page-inner {
			background: $background_theme_color_contents;
			height: 100%;

			.page-contents {
				width: 100%;
				height: 100%;
				margin: auto;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;

				.no-data {
					min-height: 40%;
					margin: 0 10px;
					background: white;
					display: flex;
					justify-content: center;
					align-items: center;
					padding-bottom: 20px;

					&.hide {
						display: none;
					}
				}

				.epManage-result-header {
					padding-bottom: 5px;

					.except {
						height: 71px;
						display: none;
					}

					.result-txt {
						margin-top: 10px;
						margin-left: 10px;
						min-height: 25px;
						display: inline-block;
						align-items: center;
					}
				}

				.epManage-result {
					@include search_table_f;
					padding: 0px;
					margin: 0px 10px 0px 10px;
					background: white;
					height: calc(100% - 500px);
					min-height: 200px;

					.table-result {
						td {
							padding: 10px !important;
						}
					}
					.col-1st {
						width: 30% !important;
					}
				}

				.ps-scrollbar-y-rail {
					right: 0;
				}
			}
		}
	}
}
