@charset "UTF-8";

.p-progress-bar {
  .bar-body {
    position: relative;
    background: #d4d4d4;
    height: 10px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

    .bar {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }

    .bar-01 {
      z-index: 2;
      background: $background_progress_01;
    }

    .bar-02 {
      z-index: 1;
      background: $background_progress_02;
    }

  }
}
