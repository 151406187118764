@charset "UTF-8";


.c-courses-input-header {

  .c-site-header-inner .site-flow ul li {
    width: 300px;

    &:after {
      width: 271px;
      left: 177px;
    }

    a {
      width: 130px;
      margin: auto;
    }

  }

  .course-search-header {
    background: #fff;
    padding: 0 20px;
    width: auto;

    .inner {
      min-height: 34px;
      padding: 20px 0;
      position: relative;
      overflow: hidden;

      .back {
          @include HoverOpacity();

          img {
              width: 20px;
              height: 20px;
              vertical-align: -webkit-baseline-middle;
          }
      }

      span {
          float: right;
          color: red;
      }
    }
  }

  .course-header-column-table {
    display: table;
    position : absolute;


    .course-header-column-left {
      display: table-cell;

      ul {
        margin-top: -15px;
        font-size: 12px;
        position: relative;
        line-height: 1.2;
        padding-left: 20px;

        li {
          &:hover {
            opacity: 0.8;
          }

          //@include HoverOpacity();
          cursor: pointer;
          margin-bottom: 15px;
          text-align: center;
          width: 70px;
          height: 70px;
          
          & > a {
            &:hover {
              opacity: 0.8;
            }
            cursor: pointer;
            text-decoration: none;
          }

          &:after {
            &:hover {
              opacity: 0.8;
            }
            cursor: pointer;
            width: 271px;
            left: 177px;
          }

          a {
            &:hover {
              opacity: 0.8;
            }
            cursor: pointer;
            width: 130px;
            margin: auto;
          }

          .ph {
            -webkit-transition: all .2s;
            -moz-transition: all .2s;
            -ms-transition: all .2s;
            -o-transition: all .2s;
            transition: all .2s;

            z-index: 1;
            display: inline-block;
            text-align: center;
            height: 50px;
            width: 50px;
            border: 1px solid $theme_color_light;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            position: relative;
            background-color: #fff;
            -webkit-background-size: 30px 30px;
            background-size: 30px 30px;
            background-repeat: no-repeat;
            background-position: center center;

            &.icn-course {
              background-image: url(#{$IMG_PATH:}icon/icn_courses_b.png);
            }
            &.icn-lesson-chapter {
              background-image: url(#{$IMG_PATH:}icon/gl_icn_catalogue_b.png);
            }
            &.icn-detail {
              background-image: url(#{$IMG_PATH:}icon/icn_docs_b.png);
            }


          }

          &.current {

            .ph {
              background-color: $theme_color_light;

              &.icn-course {
                background-image: url(#{$IMG_PATH:}icon/icn_courses_w.png);
              }
              &.icn-lesson-chapter {
                background-image: url(#{$IMG_PATH:}icon/gl_icn_catalogue_w.png);
              }
              &.icn-detail {
                background-image: url(#{$IMG_PATH:}icon/icn_docs_w.png);
              }


            }
          }

          &.error {
            color: red;

            a {
              color: red;
            }

            .ph {
              background-color: red;
              border-color: red;

              &.icn-course {
                background-image: url(#{$IMG_PATH:}icon/icn_courses_w.png);
              }
              &.icn-lesson-chapter {
                background-image: url(#{$IMG_PATH:}icon/gl_icn_catalogue_w.png);
              }
              &.icn-detail {
                background-image: url(#{$IMG_PATH:}icon/icn_docs_w.png);
              }
            }
          }
        }
        }
      }

      // &:after {
      //   @include PseudoElements();
      //   position: absolute;
      //   height: 8px;
      //   width: 42px;
      //   background: #eee;
      //   left: 62px;
      //   top: 42px;
      //   margin-top: -18px;
      // }

      // &:last-child:after {
      //   display: none;
      // }

      // & > span,
      // & > a {
      //   width: 70px;
      //   display: block;
      //   color: #333;
      // }

      

      

    .course-header-column-right {
      display: table-column;
      width: auto;
    }
  }

}