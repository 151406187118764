@charset "UTF-8";
.contents {
  
  .top-index {

    .top-inner {

      .top-contents {

        .user-management-top {
          display: table-cell;
          margin: auto;
          height: 100%;
          width: 100%;
          text-align: center; 
          vertical-align: middle;

          ul {
            @include EqualList(4, 20px);
            min-width: 960px;
            list-style: none;
            text-align: center;
            display: inline-block;
            margin-top: -50px;
            margin-left: -35px;

            li {
              width: 280px;
              text-align: center;
              margin-right: 40px;

              a {
                width: 240px;
                height: 240px;
                border: 1px solid gray;
                border-radius: 8px;
                display: block;
                @include HoverOpacity();

                h2.href-header {
                  padding-top: 15px;
                  font-size: 1.3em;
                  color: gray;
                }

                h2.href-footer {
                  font-size: 1em;
                  color: gray;
                  margin-top: -30px;
                }
              }

              .thumb {
                position: relative;
                width: 100%;
                height: 150px;
                background: #fff;
                margin-bottom: 10px;
                margin-top: -10px;
                z-index: -1;

                img {
                  @include AbsoluteCenter();
                }

              }
            }

          }
        }

      }
    }
  }
}