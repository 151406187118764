@charset "UTF-8";
.import {

 .m-modal {
    .modal-inner {
        position: relative;
        top : 10%;
        
        &.modal-inner-confirm-mail{
          width: 60%;
          min-height: 30%;
          padding: 0px;
          .opt-right {
            min-width: 40%;
            align-items: center;
            padding: 5px;
            font-size: 14px;
          }
          .debate-mail-sending {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .rd-btn {
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
            label {
              padding-left: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              div {
                margin-right: 5px;
              }
            }
          }
          .hints {
            .hint-btn {
              background: url(#{$IMG_PATH}icon/icn_hints_bk.png) no-repeat;
              padding-left: 55px;
              background-position: 50%;
              -webkit-background-size: 20px;
              background-size: 20px;
            }
            .hint-content {
              position: absolute;
              display: none;
              width: auto;
              min-width: 250px;
              max-width: 290px;
              background: #d3edfa;
              padding: 10px;
              font-size: 12px;
              margin-top: 3px;
              margin-left: 55px;
              z-index: 999;
            }
          }
          .modal-btns {
            height: 75px;
            display: -webkit-flex;
            -webkit-align-items: center;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .modal-btns .btn {
            margin: 12px;
          }
        }
        .modal-header-ccm {
          height: 45px;
          font-size: 18px;
          line-height: 35px;
          color: #6e6e6e;
          display: table;
          width: 100%;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          
          &>div {
            color: #82bef0;
            display: table-cell;
            vertical-align: middle;
            -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
            box-sizing: border-box;
          }
          .left {
            text-align: left;
            padding-left: 10px;
          }
          .right {
            text-align: right;
            padding-right: 15px;
          }
          .left, .right {
            width: 60px;
          }
        }
        .line-header {
          height: 1px;
          background-color: #4f81bd;
          box-shadow: 0px 1px #888888;
        }
        
        &.modal-inner-import-confirmCSVcontent {
        padding-left: 0px;
        padding-right: 0px;
        width: 750px;
        min-height:610px;
        @media screen and (max-width : 1366px) {
          @media screen and (max-height : 768px) {
              &.modal-inner-import-confirmCSVcontent {
                  min-height:510px;
              }
          }
        }
      .title {  
        span {
        color: #82bef0;
        font-size: 18px;
        }
      }
      .areError {
          min-height: 40px;
          margin-top: 30px;
          width: 100%;
          display:table;
      }
      .icon-error {
         float: left;
         margin-right: 5px;
            img {
                  width: 40px;
                  height: 40px;
            }
            span {
              margin-left: 71px;
              margin-top: 16px;
            }
      }
      .message-error{
        float: left;
        text-align: left;
        height: 100%;
        
        div {
              
              display: table-cell;
              vertical-align: middle;
              margin-top: 16px;
              font-size: 12px;
              color: #6e6e6e;
              padding-right: 10px;
              height: 40px;
              @media screen and (max-width : 1366px) {
                @media screen and (max-height : 768px) {
                font-size: 12px;

                }
              }
            }
      }

      .btn-download {
          float: right;
          margin-right: 67px;
          width: 200px;
           .sel {
              background-color: white;
              -webkit-border-radius: 0px;
              -moz-border-radius: 0px;
              border-radius: 0px;
              border: 1px solid;
              width: 200px;
          }
          .sel.sel-size-m {
              height: 28px;
              border:1px solid #a0a0a0;
              .sel-txt {
                  background-size: 18px;
                  line-height: 28px;
                  background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
                  color: #6e6e6e;
              }
          }         
         @media screen and (max-width : 1366px) {
          @media screen and (max-height : 768px) {
              font-size: 11px;
              margin-right: 70px;
          }
        }
      }
      .btn-submit-downloadCSV {

        position: inline;
        font-weight: initial;
        text-align: center;
        width: 213px;
        padding: 4px 7px;
        color: #6e6e6e;;
        border: 1px solid #a0a0a0;
        margin: 4px 2px;
        line-height: 22px;
        background: #fff;
        color: #6e6e6e;
        border-radius: 4px;
        img {
            width: 20px;
            height: 20px;
            padding-right: 7px;
        }

      }
      
      .dagdrop {
          width: 600px;
          height: 250px;
          left: 10%;
          position: relative;
          border: 4px dashed #c8c8c8;
          margin-bottom: 15px;
          margin-top: 15px;
          background: #f1f1f1;
        .dagdrop-text {
          color:#c8c8c8 ;
          position: relative;
          font-size: 18px;
          width: 250px;
          right: 26px;
          top: 113px;
        }

        .dragdrop-image{
          position: static;
          margin-top: 72px;
          float: left;
          margin-left: 135px;
        }
        @media screen and (max-width : 1366px) {
          @media screen and (max-height : 768px) {
            height: 200px;
            border: 4px dashed #c8c8c8;
            .dagdrop-text {
              color: #c8c8c8;
              position: relative;
              margin-top: 85px;
              font-size: 16px;
              width: 222px;
              right: 50px;
            }
              .dragdrop-image{
                position: static;
                margin-top: 30px;
                float: left;
                margin-left: 40px;
              }
          }
        }
      }

       .dagdrop::before {
        display: block;
        content: "";
        width: 90px;
        height: 90px;
        position: absolute;
        top: -6px;
        left: -6px;
        border-top: 7px solid #c8c8c8;
        border-left: 7px solid #c8c8c8;
        @media screen and (max-width : 1366px) {
        @media screen and (max-height : 768px) {
              width: 50px;
              height: 50px;
              position: absolute;
              top: -5px;
              left: -5px;
              border-top: 5px solid #c8c8c8;
              border-left: 5px solid #c8c8c8;
        }
        }
      }
        .dagdrop::after {
          display: block;
          content: "";
          width: 90px;
          height: 90px;
          position: absolute;
          top: -6px;
          right: -6px;
          border-top: 7px solid #c8c8c8;
          border-right: 7px solid #c8c8c8;
           @media screen and (max-width : 1366px) {
           @media screen and (max-height : 768px) {
              width: 50px;
              height: 50px;
              position: absolute;
              top: -5px;
              right: -5px;
              border-top: 5px solid #c8c8c8;
              border-right: 5px solid #c8c8c8
          }
          }
       }
       .border-dagdrop::before {
          display: block;
          content: "";
          width: 90px;
          height: 90px;
          position: absolute;
          bottom: -6px;
          left: -6px;
          border-bottom: 7px solid #c8c8c8;
          border-left: 7px solid #c8c8c8;
           @media screen and (max-width : 1366px) {
           @media screen and (max-height : 768px) {
              width: 50px;
              height: 50px;
              position: absolute;
              bottom: -5px;
              left: -5px;
              border-bottom: 5px solid #c8c8c8;
              border-left: 5px solid #c8c8c8;
          }
          }
      }
      .border-dagdrop::after {
          display: block;
          content: "";
          width: 90px;
          height: 90px;
          position: absolute;
          bottom: -6px;
          right: -6px;
          border-bottom: 7px solid #c8c8c8;
          border-right: 7px solid #c8c8c8;
          @media screen and (max-width : 1366px) {
           @media screen and (max-height : 768px) {
              width: 50px;
              height: 50px;
              position: absolute;
              bottom: -5px;
              right: -5px;
              border-bottom: 5px solid #c8c8c8;
              border-right: 5px solid #c8c8c8;
          }
          }
      }
      .chooseFile {
          top: 5px;
          position: relative;
          
      }
      .btn-choosefile {
          border: 1px solid #a0a0a0;
          font-weight: normal;
          border-radius: 2px;
          font-size: 12px;
          padding-right: 5px;
          background-color: white;
          color: #6e6e6e;
          width: 125px;
          line-height: 21px;
           @media screen and (max-width : 1366px) {
           @media screen and (max-height : 768px) {
              font-size: 11px;
          }
          }
        
      }
      #jsCSVUploadFile{
          position:  absolute;
          width: 127px;
          height: 25px;
          z-index: 99;
          filter: alpha(opacity=0);
          opacity: 0.0;
          font-size: 30px;
          cursor: pointer;
          overflow: hidden;
          left: 22%;
          @media screen and (max-width : 1366px) {
          @media screen and (max-height : 768px) {
            left: 21%;
          }
          }
          &:disabled {
            cursor: default;
          }
        }

        .radioImport {
          width: 70%;
          margin-left: 20%;
          padding-top: 17px;
          padding-bottom: 10px;
          @media screen and (max-width : 1366px) {
          @media screen and (max-height : 768px) {
              font-size: 11px;
          }
          }
          .div-table {
            display: table; 
            margin: 0 auto;      
            width: 100%;    
          }
          .div-table-row {
            text-align: left;
            display: table-row;
            width: auto;
            
          }
          .div-table-col {
            float: left; /* fix for  buggy browsers */
            display: inline-flex;    
            width: 50%;
            margin-bottom: 4px;
            label {
              padding-right: 15px ;  
            }
            .div-contains-radio{
              display: inline-flex;
            }
          }

        }

          .radioImport1 {
            margin-top: 30px;
            margin-bottom: 30px;
            width: 100%;
            @media screen and (max-width : 1366px) {
            @media screen and (max-height : 768px) {
                font-size: 11px;
            }
            }
          }

          .radioImport2 {
          position: absolute;
          margin-left: 55%;
          top: 500px;
          @media screen and (max-width : 1366px) {
          @media screen and (max-height : 768px) {
              font-size: 11px;
          }
          }
        }
        .fileName {
          width: 280px;
          color: #6e6e6e;
          
          &:disabled {
            background: #eee;
          }
        }
        .btn-next {
          font-weight: normal;
          font-size: 12px;
          padding-right: 5px;
          background-color: white;
          color: #0f83c6;
          border: 1px solid #0f83c6;
          border-radius: 2px;
          width: 124px;
          line-height: 24px;
          @media screen and (max-width : 1366px) {
          @media screen and (max-height : 768px) {
              font-size: 11px;
              font-weight: normal;
              padding-right: 5px;
              background-color: white;
              color: #468CC8;
             border: 1px solid #468CC8;
             border-radius: 2px;
              width: 124px;
              line-height: 24px;
          }
          }
        }

        .content-item-part-table {
          display: table;
          margin: 25px auto;
          table-layout: fixed;
          width: 500px;
          

          span {
            font-size: 12px;
            word-wrap: break-word;
          }

          input.input-html-name {
            width: 210px;
          }

          input.px-txt {
            width: 90px;
          }

          .content-item-part-row {
            display: table-row;

            .content-item-part-cell {
              display: table-cell;

              &.left-wing {
                text-align: right;
                width: 30%;
              }

              &.right-wing {
                text-align: left;
                width: 70%;
                padding-left: 23px;
              }
            }
          }
        }
    }

    &.modal-inner-CSVconfirm {
      padding-left: 0px;
      padding-right: 0px;
      width: 600px;
    }

    .send-mail-setting-content{
      padding: 10px 10%;
      .radio-row{
        width: 100%;
        text-align: left;
        line-height: 40px;
          .row-second{
            width: 77%;
            @media screen and (max-width : 1366px) {
               width: calc(87% - 2px);
              }
          }
          .row-last{
            width: 160px;
          }
      }
      .hints{    
        display: inline-block;
        line-height: normal;
        position: relative;
        &.text-content{
          margin-top: 5px;
        }
        .hint-btn{
          background: url(#{$IMG_PATH}icon/icn_hints_bk.png) no-repeat;
          padding-left: 25px;
          background-position: 50%;
          -webkit-background-size: 20px;
          background-size: 20px;
        }
        .hint-content{
          display: none;
          width: auto;
          min-width: 230px;
          max-width: 230px;
          background: #d3edfa;
          padding: 10px;
          font-size: 12px;
          margin-top: 3px;
          margin-left: 3px; 
          position: absolute; 
          top: 21px;
          z-index: 999;
        }
      }
    }
    .title-back{
      position: absolute;
      top: 5px;
      left: 35px;
      line-height: 30px;
    }
  }

}

}
