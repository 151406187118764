@charset "UTF-8";
.page-information-new {
    .page-inner {
        .page-contents {
            width: 100%;
            padding: 20px 30px;
            padding-top: 0px;
            margin: auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            max-width: 1200px;

            .m-search-c-inner {
                width: 100%;
                height: 100%;
                position: relative;

                iframe {
                width: 100%;
                height: 100%;
                }
            }
        }
        .m-modal-loading {
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 100;
          background: rgba(0,0,0,0.5);
          .modal-inner {
            border-radius: 0px;
            position: relative;
            top: 20%;
            width: 420px;
            padding: 25px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            margin: 0 auto !important;
            background: #fff;
            text-align: center;
            -webkit-box-shadow: 0 0 8px 1px rgba(0,0,0,0.5);
            -moz-box-shadow: 0 0 8px 1px rgba(0,0,0,0.5);
            box-shadow: 0 0 8px 1px rgba(0,0,0,0.5);
          
          }
        }
        .m-form {
            left: 0;
            width: 890px;

            .form-wrap {
                margin: 20px 0 !important;
                
                &:first-child {
                    margin-top: 0;
                    
                }
                .form-group {
                    padding-right: 90px;
                    margin-left: -90px;

                    .first-row {
                        position: relative;
                        top: 15px;
                    }

                    .c-name-label, .c-name-btn {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    .c-name-label {
                        max-width: 618px;
                        white-space: nowrap; 
                        text-overflow: ellipsis; 
                        overflow: hidden;
                    }

                    .notice-select-option {
                        margin-left: -10px;

                        label {
                            .inside-label-text {
                            margin-left: -2px;
                            }
                        }

                        label::before {
                            width: 11px;
                            height: 11px;
                            left:14px;
                            top: 6px;
                        }

                        label::after {
                            width: 15px;
                            height: 15px;
                            left: 10px;
                            top: 2px;
                        }
                    }

                    .span-required {
                        color: red;
                        padding-top: 1px;
                        position: relative;
                        top: -12px;
                    }
                    .input-text, select,.sel-size-w{
                        height: 39px;
                    }
                    
                    .placeholder{
                        color: pink;
                    }
                    .jsAddImage {
                        position: absolute;
                        width: 170px;
                        height: 26px;
                        z-index: 99;
                        margin-left: -170px;
                        filter: alpha(opacity=0);
                        opacity: 0.0;
                        cursor: pointer;
                        overflow: hidden;
                        font-size: 0px;
                        
                    }
                    .btn-img-select {
                        color: #858585;
                        border: 1px solid #a29e9d;
                        border-radius: 3px;
                        padding-left: 30px;
                        width: 170px;
                        height: 26px;
                        background: url(#{$IMG_PATH}icon/icn_fileattachment_k.png) 10px no-repeat;
                        -webkit-background-size: 20px;
                        background-size: 20px;
                        font-size: smaller !important;
                        
                    }
                    .input-size-period {
                        width: 269px;
                    }
                    .span-text-people {
                        position: relative;
                        top: 19px;
                    }
                    .div-text-people {
                        margin-top: -22px;
                        text-align: right;
                    }
                    .t-right{
                        text-align: right;
                    }

                    .ck-custom {
                        th {
                            padding-top: 20px;
                            vertical-align: top;
                        }
                    }

                    .private-ck {
                        margin-top: 8px;
                    }

                    .order-ck {
                        margin-top: 2px;
                    }

                    .th-private {
                        top: -10px;
                    }

                    .private-check {
                        &::before {
                            top: -16px;
                        }

                        &::after {
                            top: -20px;
                        }
                    }

                    .temp {
                        &::before {
                            top: -8px;
                        }

                        &::after {
                            top: -12px;
                        }
                    }

                    .explain-txt {
                        margin-top: -10px;
                    }

                    .order-tr {
                        .for-check {
                            top: 0px;
                        }
                    }
                    
                    .btn-delete {
                        background: url(#{$IMG_PATH}icon/icn_trash_k.png) 10px center no-repeat;
                        -webkit-background-size: 90%;
                        background-size: 90%;
                        background-position-x: 50%;
                        font-weight: normal;
                        width: 34px;
                        height: 34px;
                        border: 1px solid;
                        border-radius: 4px;
                        vertical-align: middle;
                        &.disabled {
                            cursor: default;
                            opacity: .3;
                        }
                    }

                    .custom-upload {
                        div.form-download-info {
                            label {
                                color: #0b3182;
                                cursor: default;
                            }
                        }
                        div.form-download-info-temp {
                            margin-top: 10px;
                            label {
                                color: #0b3182;
                                cursor: default;
                            }
                        }
                        div.form-btn-add {
                            margin-top: 10px;
                            position: absolute;
                            .btn-upload {
                                right: 0;
                                bottom: 0;
                                overflow: hidden;
                                padding: 10px;
                                label {
                                    @include HoverOpacity();
                                    background: url(#{$IMG_PATH}icon/icn_selectbtn_plus_k.png);
                                    -webkit-background-size: 100% 100%;
                                    background-size: 100% 100%;
                                    width: 38px;
                                    height: 38px;
                                    display: block;
                                }
                                input[type="file"] {
                                    position: absolute;
                                    left: -9999px;
                                    top: -9999px;
                                }
                            }
                        }
                    }

                    .custom-upload {
                        .notice-fake-upload-btn {
                            position: absolute;
                            .btn-file-select {
                                position: absolute;
                            }

                            .jsAddFile {
                                position: absolute;
                                width: 170px;
                                height: 26px;
                                z-index: 99;
                                opacity: 0.0;
                                cursor: pointer;
                                overflow: hidden;
                                top: 0px;
                                font-size: 0px;
                            }
                        }

                        .input-filename {
                            background-color: #fff;
                            height: 30px;
                            padding-top: 4px;
                            padding-bottom: 4px;
                            padding-left: 8px;
                            padding-right: 40px;
                            border-bottom: none;

                            &.last {
                                border-bottom-width: 1px;
                                border-bottom-style: inset;
                                @media screen and (-webkit-min-device-pixel-ratio:0) {
                                    border-bottom-style: outset;
                                }

                                @supports (-ms-ime-align:auto) {
                                    border-bottom-style: inset;
                                }
                                border-bottom-color: #fff;
                            }
                        }

                        .btn-file-delete {
                            opacity: .7;
                            background: url(#{$IMG_PATH}icon/icn_close_k.png) left center no-repeat;
                            background-size: 13px;
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            float: right;
                            margin-top: -25px;
                            margin-right: 5px;
                            &:hover {
                                opacity: 0.3;
                            }

                            &.hide {
                                display: none;
                            }
                        }

                        .display-btn-add {
                            .notice-btn-add {
                                margin-top: 10px;
                                right: 0px;
                                float: right;
                                margin-bottom: -60px;

                                .btn-upload {
                                    .notice-document {
                                        background: url(#{$IMG_PATH}icon/icn_selectbtn_plus_k.png);
                                        -webkit-background-size: 100% 100%;
                                        background-size: 100% 100%;
                                        width: 28px;
                                        height: 28px;
                                        display: block;
                                    }

                                    .fake-upload-file {
                                        position: relative;
                                        width: 28px;
                                        height: 28px;
                                        z-index: 99;
                                        filter: alpha(opacity=0);
                                        opacity: 0.0;
                                        cursor: pointer;
                                        overflow: hidden;
                                        margin-top: -50px;
                                    }
                                }
                            }
                        }
                        
                    }


                    

                    .for-check{
                      position: relative;
                      top: -6px;
                    }

                    .radio-btn {
                        input {
                            height: 32px;
                        }

                        label {
                            //padding-left: 15px;
                        }
                    }
                    
                }
                
                table {
                    th:first-child {
                        width: 20%;
                        &.vertical-top{
                          vertical-align: top;
                          .text-title{
                            margin-top: 5px;
                          }
                        }
                    }
                    td:nth-child(2) {
                        // text-align: right;
                    }
                }
            }
            &.conf-form {
                .form-wrap {
                    .form-group {
                        padding-right: 0;
                    }
                }
            }
            .form-btns{
                  .btn{
                      width: 200px !important;
                    }
                    
                }
        }
        .hints{    
            display: inline-block;    
            margin-top: 10px;
            position: absolute;
            &.text-content{
                margin-top: 5px;
            }
            .hint-btn{
                background: url(#{$IMG_PATH}icon/icn_hints_bk.png) no-repeat;
                padding-left: 25px;
                background-position: 50%;
                -webkit-background-size: 20px;
                background-size: 20px;
            }
            .hint-content{
                display: none;
                width: max-content;
                background: #d3edfa;
                padding: 10px;
                font-size: 12px;
                margin-top: 3px;
                margin-left: 3px;
                position: relative;
                z-index: 999;
            }
        }
        .btn-url {
            background: #FFFFFF url(#{$IMG_PATH}icon/icn_btn_urlhint_k.png) 10px center no-repeat !important;
            background-size: 26px 28px !important;
            background-position: center;
            width: 44px;
            height: 34px;
            //position: relative;
            //top: -7px;
            border: 1px solid #c8c8c8;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            //margin-left: 40%;
        }
    }
    .m-modal{
      .modal-inner.comp{
        width: 520px;
        .modal-inner-content{
          padding: 10px 20px;
          p{ 
            margin-bottom: 10px;    
            display: block;
            text-align: left;
            span{
              word-break: break-all;
              white-space: pre-wrap;
            }
          }
        }
      }
    }
}