@charset "UTF-8";
.contents {
  height: 100%;

  .p-pager {
      margin-top: 5px;
      margin-left: 10px;
      min-height: 30px;
      display: flex;
      align-items: center;

      ul.pagination {
        display: inline-block;
        padding: 0;
        margin: 0;

        li {
          display: inline;

          a {
            color: black;
            // float: left;
            padding: 3px 9px;
            text-decoration: none;
            border-radius: 5px;

            &.active {
              background-color: #468CC8;
              color: white;
              border-radius: 5px;
              }

            &:hover:not(.active) {
              background-color: #6E6E6E;
              color: white;
              }

            }

            strong {
              -webkit-user-select: none;  /* Chrome all / Safari all */
              -moz-user-select: none;     /* Firefox all */
              -ms-user-select: none;      /* IE 10+ */
              user-select: none;          /* Likely future */ 
              cursor: default;
            }

          }

      }

    }
}

  .contents-inner {
    height: 100%;
  }
  .page-courses-search {
    height: 100%;

    .page-inner {
      background: $background_theme_color_contents;
      height: 100%;

      .page-contents {
        width: 100%;
        height: 100%;
        margin: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        .no-data {
          min-height: 85%;
          margin: 0 10px;
          background: white;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 0px !important;

          &.hide {
            display: none;
          }
        }
        
        .course-header-multi {
          display: flex;
          flex-flow: column;
        }

        .course-header {
          display: flex;
          padding-bottom: 5px;
          
          .except {
            height: 71px;
            display: none;
          }
          
          .except-analysis {
            height: 106px;
            display: none;
          }
          
          .download {
            position: absolute;
            right: 0px;
            margin-top: 5px;
            margin-right: 10px;
            min-height: 30px;
            padding-bottom: 5px;
          }

          .result-txt {
            margin-top: 5px;
            margin-left: 10px;
            min-height: 30px;
            display: flex;
            align-items: center;
          }

          .average {
            margin-top: 0px;
            margin-left: 10px;
            min-height: 30px;
            display: flex;
            align-items: center;
          }
          
          .analysis {
            position: absolute;
            right: 0px;
            margin-top: 0px;
            margin-right: 10px;
            min-height: 30px;
            padding-bottom: 5px;
          }
        }

      }

      .course-result {  
        @include search_table_f;
        padding: 0px !important;

        thead>tr {
          z-index: 5;
        }

        tbody {
          .row.selected, td.hover {
            .bar-body {
              border: solid 1px;
            }
            &.check {
              background-image: url(#{$IMG_PATH:}icon/icn_check_w.png) !important;
              -webkit-background-size: 25% !important;
              background-size: 25px !important;
              background-repeat: no-repeat !important;
              background-position: 50% !important;
            }
            .check {
              background-image: url(#{$IMG_PATH:}icon/icn_check_w.png) !important;
              -webkit-background-size: 25% !important;
              background-size: 25px !important;
              background-repeat: no-repeat !important;
              background-position: 50% !important;
            }
          }
          tr {
            .in-time-progress-bar {
              &.w70 {
                width: 70%;
                float: right;
              }
              &.w60 {
                width: 60%;
                float: left;
              }
              .bar-body {
                &.user-course-history-bar {
                  position: relative;
                  -webkit-border-radius: 5px;
                  -moz-border-radius: 5px;
                  border-radius: 5px;
                  height: 25px; // border: 1px solid #7E7E7E;
                  background: #EBEBEB;
                }
                .bar {
                  position: absolute;
                  left: 0;
                  top: 0;
                  height: 100%;
                  -webkit-border-radius: 5px;
                  -moz-border-radius: 5px;
                  border-radius: 5px;
                  &.blue-bar {
                    background: #84CBF1;
                    &+.data {
                      color: #FFFFFF;
                    }
                  }
                  &.yellow-bar {
                    background: #FEF8A0;
                  }
                  &.red-bar {
                    background: #FF8A84;
                  }
                  &.gray-bar {
                    background: #EBEBEB;
                    width: 100%;
                  }
                }
                .bar-01 {
                  z-index: 2;
                }
                .data {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-weight: bold;
                  z-index: 3;
                  color: #606060;
                  width: 100%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: clip;
                  font-size: 10px;
                  .completeds {
                    font-size: 14px;
                  }
                }
              }
            } //in-time-progress-bar
          }
        }

        &.result-tbl {

            @media screen and (max-width : 1366px) {
            @media screen and (max-height : 768px) {
              
                height: 85%;
              
            }
            }
            padding: 0px;
            margin: 0px 10px 0px 10px !important;
            height: 90%;
            background: white;

            .ps-scrollbar-y-rail {
              z-index: 4;
              right: 0px;
            }

        .table-result, .table-header {
          table-layout: fixed;
          .col-course-name {
            width: 26%;
            // min-width: 227px;
          }
          .col-login-id {
            width: 18%;
            // min-width: 157px;
          }
          .col-user-name {
            width: 13%;
            // min-width: 113px;
          }
          .col-rate {
            width: 10%;
            // min-width: 87px;
          }
          .col-attend-stt {
            width: 12%;
            // min-width: 104px;
          }
          .col-cp {
            width: 10%;
            // min-width: 87px;
          }
          .col-access {
            width: 11%;
            // min-width: 95px;
          }

          &.tbl-summary {
            .col-course-id {
              width: 10%;
            }
            .col-course-name {
              width: 17%;
            }
            .col-course-group {
              width: 17%;
            }
            .col-chapter-count {
              width: 14%;
            }
            .col-assign-evaluated {
              width: 17%;
            }
            .col-rate-assign-count {
              width: 25%;
              .w25{
                font-weight: bold;
                width: 25%;
                float: left;
              }
            }
          }

          &.tbl-course-chapter {
            .col-lesson {
              width: 17%;
            }
            .col-chapter {
              width: 17%;
            }
            .col-evaluated {
              &.check {
                background-image: url(#{$IMG_PATH:}icon/icn_check_b.png);
                -webkit-background-size: 25%;
                background-size: 25px;
                background-repeat: no-repeat;
                background-position: 50%;
              }
            }
            .col-content {
              width: 13%;
            }
            .col-finish {
              width: 8%;
            }
            .col-in-progress {
              width: 8%;
            }
            .col-not-start {
              width: 8%;
            }
            // .col-assign-count {
            //   width: 8%;
            // }
            .col-rate {
              width: 22%;
              .w30 {
                width: 30%;
                float: left;
                font-weight: bold;
              }
            }
          }

          &.tbl-user-chapter {

           .row.selected, td.hover {
             &.check {
               background-image: url(#{$IMG_PATH}icon/icon_late_w.png) !important;
               -webkit-background-size: 25% !important;
               background-size: 25px !important;
               background-repeat: no-repeat !important;
               background-position: 50% !important;
             }
             .check {
               background-image: url(#{$IMG_PATH}icon/icon_late_w.png) !important;
               -webkit-background-size: 25% !important;
               background-size: 25px !important;
               background-repeat: no-repeat !important;
               background-position: 50% !important;
             }
            }

            .col-login-id {
              width: 10%;
            }
            .col-user-name {
              width: 17%;
            }
            .col-attend-stt {
              width: 13%;
            }
            .col-view-time {
              width: 9%;
            }
            .col-late {
              &.check {
                background-image: url(#{$IMG_PATH:}icon/icon_late_g.png);
                -webkit-background-size: 25%;
                background-size: 25px;
                background-repeat: no-repeat;
                background-position: 50%;
              }
              width: 9%;
            }
            .col-point {
              width: 7.5%;
              .be-late-score {
                color: red;
                text-decoration: line-through;
              }
            }
            .col-pass {
              width: 7.5%;
            }
            .col-score {
              width: 7.5%;
            }
            .col-correct-answer {
              width: 14%;
            }
            .col-times {
              width: 7.5%;
            }
            .col-access {
              width: 14%;
            }
          }
        }
        .table-result {
          &.tbl-summary {
            tr.selected, tr:hover {
              .col-chapter-count {
                &.img {
                      background-image: url(#{$IMG_PATH}icon/icn_docsnull_wf.png) !important;
                      background-size: 22px !important;
                      background-repeat: no-repeat !important;
                      background-position: right !important;
                      background-position-x: 90% !important;
                }
              }
              .col-rate-assign-count {
                &.img {
                      background-image: url(#{$IMG_PATH}icon/icn_flag_wf.png) !important;
                      background-size: 22px !important;
                      background-repeat: no-repeat !important;
                      background-position: right !important;
                      background-position-x: 94% !important;
                }
              }
            }
            .hover:hover{
              &.col-chapter-count {
                  &.img {
                        background-image: url(#{$IMG_PATH}icon/icn_docsnull_bf.png) !important;
                        background-size: 22px !important;
                        background-repeat: no-repeat !important;
                        background-position: right !important;
                        background-position-x: 90% !important;
                  }
                }
              &.col-rate-assign-count {
                  &.img {
                        background-image: url(#{$IMG_PATH}icon/icn_flag_bf.png) !important;
                        background-size: 22px !important;
                        background-repeat: no-repeat !important;
                        background-position: right !important;
                        background-position-x: 94% !important;
                  }
                }

            }
          }
        }

        .table-header {
          thead {
            tr {
              .header {
                cursor: pointer;
                div {
                  display: inline-flex;
                  .img {
                    background-image: url(#{$IMG_PATH}icon/icn_pullupdown_k.png) ;
                    background-repeat: no-repeat ;
                    background-position: center ;
                    background-size: 26px;
                    width: 27px;
                    padding-left: 7px;
                  }

                }
                
                }
                .header-sort-up {
                  cursor: pointer;
                  div {
                    display: inline-flex;
                    .img {
                      width: 26px;
                      background-position: center ;
                      background-repeat: no-repeat ;
                      background-image: url(#{$IMG_PATH}icon/icn_pullup_k.png) ;
                      background-size: 13px;
                      padding-left: 7px;
                      }
                  }
                }

                .header-sort-down {
                  cursor: pointer;
                  div {
                    display: inline-flex;
                    .img {
                      width: 26px;
                      background-position: center ;
                      background-repeat: no-repeat ;
                      background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png) ;
                      background-size: 13px;
                      padding-left: 7px;
                    }
                    
                  }
                }
              }

            }

          }
        }


      }

      .m-search-c {
        .m-search-c-inner{
          width: 100%;
          height: 100%;
          position: relative;

          iframe {
            width: 100%;
            height: 100%;

          }
        }
      }


    }
  }

.m-correct-percentage-modal{
 	 .m-correct-percentage-inner {
      iframe {
        width: 100vw;
        height: 100vh;
      }
	}
}
.m-test-score-histogram-modal {
	.m-test-score-histogram-inner {
	  iframe {
        width: 100vw;
        height: 100vh;
      }
	}
}