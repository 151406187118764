@charset "UTF-8";
.page-site-usage-check {
  height: 100%;
  .page-inner {
    background: $background_theme_color_contents;
    height: 100%;
    background: #fff;
    .page-contents {
      height: 100%;
      width: 100%;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      .m-form{
        text-align: -webkit-center;
      }
      .page-site-usage-check-header{
        
          padding: 0 20px;
        .inner {
           min-height: 34px;
           padding: 20px 0 5px 0;
            position: relative;
            overflow: hidden;
            .back {
            @include HoverOpacity();
            position: absolute;
            left: 0;
              img {
                  width: 20px;
                  height: 20px;
                  vertical-align: -webkit-baseline-middle;
            }
         }
        }
      }
    }

      .timeText{
        margin-top:20px;
      }

  }
  .m-form{
  	width: 100%;
  	background: #fff;
    padding-bottom: 10px;
    left: 0;
    .form-wrap{
       margin: 20px 0;
      .form-group {
        margin: 0 20px;
        &.bg-gray {
          background: #eee;
        }
        .check{
          width: 700px;
          color: #6E6E6E;
          margin: 0 auto;
          .bar-body{
            border-radius: 20px;
            overflow: hidden;
          }
          div:first-child{
            // border: 1px solid #c8c8c8;
          }
          div:nth-child(2){
            // border-left: 1px solid #c8c8c8;
            // border-right: 1px solid #c8c8c8;
            border-top: 1px solid #c8c8c8;
            span:nth-child(4){
              margin-left: 20px;
            }
            span:nth-child(2),span:nth-child(5){
              font-size: 18px;
              font-weight: bold;
            }
          }
          div.p-progress-bar2{
            text-align: left;
            p{
              display: inline-block;
              margin: 5px 0;
            }
            p:first-child, p:nth-child(3){
              padding-left: 0;
              padding-bottom: 0;
            }
            div{
              border: 0;
            }
            span{
              display: inline-block;
              font-size: 12px;
              &.blue{
                height: 10px;
                width: 10px;
                background-color: #84CBF1;
              }
              &.orange{
                height: 10px;
                width: 10px;
                background-color: #FFB864;
              }
              &.green{
                height: 10px;
                width: 10px;
                background-color: #A9EC64;
              }
            }
          }
          p:first-child{
            padding-top: 20px;
          }
          p{
            text-align: left;
            padding-bottom: 20px;
            padding-left: 20px;
          }
            //.CCM071
            .p-progress-bar2 {
              .bar-body {
                position: relative;
                background: #d4d4d4;
                height: 15px;
                -webkit-border-radius: 7px;
                -moz-border-radius: 7px;
                border-radius: 7px;

                .bar {
                  float: left;
                  top: 0;
                  height: 100%;
                }

                .bar-01 {
                  -webkit-border-radius: 7px 0px 0px 7px;
                  -moz-border-radius: 7px 0px 0px 7px;
                  border-radius: 7px 0px 0px 7px;
                  z-index: 2;
                  background: #84CBF1;
                }

                .bar-02 {
                  z-index: 1;
                  background: #FFB864;
                }
                .bar-03 {
                 -webkit-border-radius: 0px 7px 7px 0px;
                  -moz-border-radius: 0px 7px 7px 0px;
                  border-radius: 0px 7px 7px 0px;
                  z-index: 3;
                  background: #A9EC64;
                }

              }
              &.video-play-count-per-hour,&.video-play-count-per-day {
                display:flex;
                .progress-wrapper {
                  width: 100%;
                  padding-right: 20px;
                }
                .detail-btn-wrapper {
                  display: flex;
                  align-items: flex-end;
                .detail-btn {
                  font-weight: normal;
                  text-align: center;
                  border: #000 solid 1px;
                  -webkit-border-radius: 2px;
                  -moz-border-radius: 2px;
                  border-radius: 2px;
                  display: inline-block;
                  width: 90px;
                  height: 40px;
                  line-height: 40px;
                  color: #000;
                  -webkit-transition: opacity .3s;
                  -moz-transition: opacity .3s;
                  -ms-transition: opacity .3s;
                  -o-transition: opacity .3s;
                  transition: opacity .3s;
                  text-decoration: none;
                  cursor: pointer;
                  &:hover {
                    opacity: .7;
                  }
                 }
                }
              }
            }
        }
        
        .size-over{
          color: #e7001f;
        }
      }
    
    }
  }

  .btn-site{
    font-weight: initial;
    text-align: center;
    width: 130px;
  	padding: 6px 19px;
  	color: #000;
    border: 1px solid #c8c8c8;
  	-webkit-border-radius: 5px;
  	-moz-border-radius: 5px;
  	border-radius: 5px;
  	margin: -3px 0;
  	line-height: 22px;
  	background: #fff;
    color: #6E6E6E;
    img{
      width: 20px;
      height: 20px;
      padding-right: 7px;
    }
  }
  
}