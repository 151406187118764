@charset "UTF-8";

.page-external-tool-v1p3-edit {
  .page-inner {
    .external-tool-v1p3-edit-header {
      background: #fff;
      padding: 0 20px;
      .inner {
        min-height: 34px;
        padding: 20px 0;
        position: relative;
        overflow: hidden;
        .back {
          img {
            width: 20px;
            height: 20px;
            vertical-align: -webkit-baseline-middle;
          }
        }
      }
    }
    .m-form {
      table {
        tr {
         th {
           &:first-child {
             width:30%;
           }
         }
         input {
           &.tool-name, &.client-id {
             width: 80%;
           }
         }
          .fc-red {
            display : block;
          }
        }
      }
    }
  }
}