@charset "UTF-8";
.c-course-summary-header {
    background: #fff;
    .c-course-summary-header-inner {
        position: relative;
        padding-top: 20px;
        .course-summary-header-layout {
            height: 100%;
            min-height: 1px;
            .hide {
                display: none;
            }
            .m-form-search {
                    padding-top: 10px;
                .form-group {
                    .inner-resize {
                        padding: 0px;
                        min-width: 890px;
                        margin: 0 auto;
                        max-width: 1000px;
                        .col1 {
                            width: 50%;
                        }
                    } //.inner-resize
                    .layout {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 0px;
                        div {
                            margin-bottom: 0px;
                            * {
                                height: 32px;
                            }
                            .inpt {
                                display: flex;
                                align-items: center;
                                width: 100%;

                            .c-name {
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                display: flex;
                                align-items: center;

                                span {
                                white-space: pre !important;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                padding-top: 10px;
                                }
                            }

                                .title {
                                    display: flex;
                                    min-width: 85px;
                                    justify-content: flex-end;
                                    align-items: center;
                                    padding-right: 5px;
                                    width: 30%;
                                }
   
                                .txt {
                                    width: 70%;

                                    &.rate {
                                        display: flex;
                                        align-items: center;
                                        width: 50%;
                                        input {
                                            width: 45%;
                                        }
                                        .no-width {
                                            margin: 0px 10px 0px 5px;
                                            display: flex;
                                            align-items: center;
                                        }
                                    }
                                    &.creater {
                                        input {
                                            width: 60%;
                                        }
                                    }
                                    &.course-id {
                                        width: 50%;
                                    }
                                    &.catology {
                                        display: flex;
                                        align-items: center;
                                        input {
                                            width: 60%;
                                        }
                                      .chosen {
                                        // min-width: 60px;
                                        width: 40%;
                                        }
                                        .sel-txt {
                                            font-size: 12px;
                                            font-weight: none;
                                            width: 100%;
                                            margin-top: 0px;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;
                                            overflow: hidden;
                                            padding-right: 15px;
                                        }
                                        // width: 35%;
                                        // font-size: 10px;
                                        // p {
                                        //   line-height: normal;
                                        // }
                                        select {
                                            // word-wrap: break-word;      /* IE*/
                                            // white-space: -moz-pre-space; /* Firefox */
                                            // white-space: pre-wrap;      /* other browsers */
                                            display: inline-block;
                                        }
                                    
                                    }
                                }
                                .chosen {
                                    // min-width: 60px;
                                    width: 45%;
                                    .sel {
                                        width: 100%;
                                        border: 1px solid #c8c8c8;
                                        -moz-border-radius: 0px;
                                        border-radius: 0px;
                                        p {
                                            font-size: inherit;
                                            font-weight: normal;
                                            margin-top: 0;
                                            background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
                                            background-size: 12px;
                                            line-height: 32px;
                                            white-space: nowrap;
                                            // text-overflow: ellipsis;
                                            overflow: hidden;
                                        }
                                    }
                                    select {
                                        width: 100%;
                                    }
                                    
                                }
                                .btn-c-search {
                                    width: 40px;
                                    margin-left: 5px;
                                }
                            } //.inpt
                            .div-err {
                                padding-left: 31%;
                                height: 20px !important;
                                .error {
                                    height: 100% !important;
                                }
                            }
                        } //> div
                    } //.layout 
                } //.form-group
                .form-btns {
                    display: flex;
                    justify-content: center;
                    > div {
                        margin: 0px;
                    }
                } // form-btns
            } //.m-form-search
        } // .course-summary-layout
    } // .index-header-inner
} // .index-header