@charset "UTF-8";

.modal-cer-ref {

  iframe{
    width: 100vw;
    height: 100vh;
  }
  
  .txt12 {
    max-width: 12em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .txt25 {
    max-width: 25em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .txt32 {
    max-width: 32em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .m-modal {
    .modal-inner {
      &.c-search {
        top: 18%;
        min-height: 485px;
        padding-left: 0px;
        padding-right: 0px;
        width: 630px;
        text-align: left;
        background: #fff;
        z-index: 11000;

        .container {
          margin: auto;
          padding: 10px 30px;
          box-sizing: border-box;

          .row-user-box {
            display: block;
            padding: 0 0 15px 34px;

            .icon-box {

              img {
                height: 32px;
              }

            }

            .icon-box,
            .user-name,
            .user-id {
              display: inline-block;
              vertical-align: bottom;
              font-size: 18px;
            }

            .user-name,
            .user-id {
              max-width: 12em;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }//row-user-box

          .row-course-box {
            display: block;
            padding: 0 0 15px 34px;

            .icon-box {

              img {
                height: 32px;
              }

            }

            .icon-box,
            .course-name {
              display: inline-block;
              vertical-align: bottom;
              font-size: 18px;
            }
            
            p {
                padding: 0px 0 0px 34px;
                span::before {
                  content: ":";
                  padding: 0 5px;
                }
            }
          }//row-course-box
          
          .row-image-box {
            width: 100%;
            display: block;
            box-sizing: border-box;

            .col-left {
              display: inline-block;
              width: 48%;
              vertical-align: top;
              
              .col-text {
                width: 200px;
                margin: auto;
              }
            }
            
            .col-right {
              display: inline-block;
              width: 47%;
              vertical-align: top;
              
              .col-text {
                width: 240px;
                margin: 0 0 0 32px;
              }
              
            }
            
            .col-title {
              text-align: center;
            }
            
            .col-image {
              position: relative;
              margin: auto;
              width: 200px;
              height: 150px;
              margin-bottom: 5px;
              border: 1px solid #ccc;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: contain;

              img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                max-height: 150px;
              }
              
              .prev-arrow {
                position: absolute;
                top: 50%;
                left: -40px;
                transform: translateY(-50%);
                content: "";
                background: url(#{$IMG_PATH}icon/icn_collapse_k.png) center center / cover no-repeat;
                width: 26px;
                height: 34px;
                z-index: 10;
                cursor: pointer;
                
                &:hover {
                  opacity: .8;
                }
              }
              
              .next-arrow {
                position: absolute;
                top: 50%;
                right: -40px;
                transform: translateY(-50%);
                content: "";
                background: url(#{$IMG_PATH}icon/icn_expand_k.png) center center/cover no-repeat;
                width: 26px;
                height: 34px;
                z-index: 10;
                cursor: pointer;
                
                &:hover {
                  opacity: .8;
                }
              }
              
              
            }
              
              .conf-status {
                  text-align: center;
              }
              
              p {
                  text-align: left;
                  padding-bottom: 3px;
                
                  span::before {
                    content: ":";
                    padding: 0 15px 0 5px;
                  }
              }
            
            .btn-box-m {
              text-align: right;
                .box-border{
                    border: 1px solid #c8c8c8;
                    position: absolute;
                    margin-left: 174px;
                    border-radius: 5px; 
                    padding: 0px 1px;                  
                    .btn-regref-l{
                        margin: 0px 0 10px 0px;
                        padding-bottom: 6px;
                        height: 34px;
                        background-size: 38px;
                        width: 60px;
                    }
                    p{
                        font-size: 9px;
                        padding: 22px 0px 0px 0px;
                        color: #a8a8a8;
                        font-weight: 100; 
                        text-align: center;
                    }
                }
            }

          }
          
          
          
          
          
        }//container




      .page-inner {

        .course-result {
          @media screen and (max-height: 980px) {
            height: 365px !important;
          }

          @media screen and (max-height: 870px) {
            height: 320px !important;
          }

          @media screen and (max-height: 830px) {
            height: 300px !important;
          }

          @media screen and (max-height: 790px) {
            height: 280px !important;
          }

          @media screen and (max-height: 770px) {
            height: 250px !important;
          }

          @media screen and (max-height: 730px) {
            height: 220px !important;
          }

          @media screen and (max-height: 690px) {
            height: 190px !important;
          }
        }

        }//page-inner
      }
    }
  }
}
