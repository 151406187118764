@charset "UTF-8";
.page-reservation-list {
    height: 100%;
    .page-inner {
        background: $background_theme_color_contents;
        height: 100%;
        .page-contents {
            height: 100%;
            width: 100%;
            margin: auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
             .m-form-search {
                 padding: 0;
                 .error {
                     margin-top: 1px;
                 }
                .form-group {
                    .inner {
                        width: 85%;
                        .item-group{
                            width: 25%;
                            margin-bottom: 10px;
                            color: #6E6E6E;
                            .content{
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                display: inline-block;
                                width: 57%;
                                vertical-align: bottom;
                            }
                            @media screen and (max-width: 1240px) {
                                &.item-date-time{
                                    width : 30%;
                                }
                                &.item-condition{
                                    width : 20%;
                                }
                            }
                            .all-content{
                                display: none;
                                position: absolute;
                                z-index: 1;
                                padding: 10px;
                                width: 300px;
                                -webkit-box-sizing: border-box;
                                -moz-box-sizing: border-box;
                                box-sizing: border-box;
                                background: #333;
                                color: #fff;
                                -webkit-border-radius: 5px;
                                -moz-border-radius: 5px;
                                border-radius: 5px;
                                font-size: 12px;
                                margin-top: 5px;
                                white-space: normal;
                                 &:before {
                                    background: #333;
                                    z-index: 1;
                                    @include PseudoElements();
                                    width: 10px;
                                    height: 10px;
                                    position: absolute;
                                    top: -5px;
                                    -webkit-transform: rotate(-45deg);
                                    -moz-transform: rotate(-45deg);
                                    -ms-transform: rotate(-45deg);
                                    -o-transform: rotate(-45deg);
                                    transform: rotate(-45deg);

                                }
                            }
                             .content:hover > .all-content {
                                display: block;
                            }
                        }
                        .item-group:last-child{
                            width: 100%;
                        }
                    }
                }
            }
        }

      .course-header {
        display: flex;
        padding-bottom: 5px;
        min-height: 36px;
        .except {
          height: 71px;
          display: none;
        }

        .result-txt {
          margin-top: 5px;
          margin-left: 10px;
          min-height: 30px;
          display: flex;
          align-items: center;
        }

      }

        .mail-search-header {
            background: #fff;
            .inner {
                position: relative;
                overflow: hidden;
                .name{
                    padding-left: 30px;
                    line-height: 30px;
                    color: #6E6E6E;
                    display: inline-block;
                    cursor: default;
                    word-break: break-all;
                    width: 90%;
                }
                .btn {
                    float: right;
                    text-align: center;
                }
            }
        }

        .mail-result {
            @include search_table_f;
          margin: 0 10px;
          padding: 0;
          position: relative;
          height: calc(98% - 36px);
            @media screen and (max-height: 830px) {
                height : 97%;
            }
            .table-header{
                table-layout: auto;
                tr{
                    width: 100%;
                    position: fixed;
                    th{
                        padding: 5px;
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                    .header {
                        cursor: pointer;
                        div {
                            display: inline-flex;
                            .img {
                                background-image: url(#{$IMG_PATH}icon/icn_pullupdown_k.png) ;
                                background-repeat: no-repeat ;
                                background-position: center ;
                                background-size: 26px;
                                width: 27px;
                                padding-left: 7px;
                            }
                        }
                        
                    }
                    .header-sort-up {
                        cursor: pointer;
                        div {
                            display: inline-flex;
                            .img {
                            width: 26px;
                            background-position: center ;
                            background-repeat: no-repeat ;
                            background-image: url(#{$IMG_PATH}icon/icn_pullup_k.png) ;
                            background-size: 13px;
                            padding-left: 7px;
                            }
                        }
                    }

                    .header-sort-down {
                        cursor: pointer;
                        div {
                            display: inline-flex;
                            .img {
                            width: 26px;
                            background-position: center ;
                            background-repeat: no-repeat ;
                            background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png) ;
                            background-size: 13px;
                            padding-left: 7px;
                            }
                            
                        }
                    }
                }
                .col-send-id{
                    min-width: 90px;
                }
                .col-opposition-number{
                    min-width: 90px;
                }
                .col-user-name{
                   min-width: 150px;
                }
                .col-sent-date{
                   min-width: 145px;
                }
                .col-mail-title{
                   min-width: 215px;
                }
                .col-state{
                    min-width: 90px;
                }
                .col-btn{
                    min-width: 90px;
                }
            }
            .table-result-nodata{
                height: 100%;
                tr{
                    td{
                        border: 0px solid #fafafa  !important;
                        color: #AFAFAF;
                         box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        span{
                            position: relative;
                        }
                    }
                }
            }
            .table-result{
                table-layout: auto;
                .col-send-id{
                    min-width: 90px;
                    width: 10%;
                }
                .col-opposition-number{
                    min-width: 90px;
                    width: 10%;
                }
                .col-user-name{
                   min-width: 150px;
                   width: 20%;
                }
                .col-sent-date{
                   min-width: 145px;
                   width: 15%;
                }
                .col-mail-title{
                   min-width: 215px;
                   width: 25%;
                   text-align: left;
                   padding: 5px 10px;
                   color: #1A0DAB;
                }
                .col-state{
                    min-width: 90px;
                    width: 10%;
                }
                .col-btn{
                    min-width: 90px;
                    width: 10%;
                }
                tr{
                    td:not(.row-ope){
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                }
                tr:not(:first-child):hover, tr.selected,.hover{
                    .btn-submit-delete { 
                        background: url(#{$IMG_PATH}icon/icn_trash_w.png) 20px center no-repeat;
                        -webkit-background-size: 80%;
                        background-size: 80%;
                        background-position-x: 50% ;
                    }
                    .btn-submit-setting {
                        background: url(#{$IMG_PATH}icon/icn_edit_w.png) 20px center no-repeat;
                        -webkit-background-size: 80%;
                        background-size: 80%;
                        background-position-x: 50% ;
                    }
                }
            }

              
        }
        
        .mail-list-inner{
            height: 100%;
            background-color: #fff;
        }
        .mail-list-outer{
            width: 100%;
            height: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;}
          }
        .scroll-area {
            &.ps-active-y {
                padding-right: 16.2px;
                margin-right: -16.2px;
            }
            .ps-scrollbar-y-rail{
                right: 16.2px;
            }
            height: 100%;
        } // .scroll-area
        input.btn,
        a.btn {
            &.btn-submit-download {
                float: right;
                font-weight: initial;
                text-align: center;
                width: auto;
                padding: 6px 19px;
                color: #000;
                border: 1px solid #c8c8c8;
                margin: -3px 0;
                line-height: 22px;
                background: #fff;
                color: #6E6E6E;
                img{
                width: 20px;
                height: 20px;
                padding-right: 7px;
                }
            }
            &.btn-submit-upload {
                background: url(#{$IMG_PATH}icon/icn_upload_w.png) 20px center no-repeat;
                -webkit-background-size: 20px;
                background-size: 20px;
                color: #468cc8;
                border: 1px solid;
                background-color: white;
                font-weight: normal;
                height: 34px;
                line-height: 34px;
                text-align: center;
                -webkit-border-radius: 0px;
                -moz-border-radius: 0px;
                border-radius: 0px;
                font-size: 16px;
                padding: 0;
            }
            &.btn-submit-search-new {
                background: url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
                -webkit-background-size: 20px;
                background-size: 20px;
                background-position-x: 50% ;
                color: #468cc8;
                border: 1px solid #82BEF0;
                background-color: #82BEF0;
                font-weight: normal;
                width: 130px;
                height: 34px;
                line-height: 34px;
                text-align: center;
                -webkit-border-radius: 0px;
                -moz-border-radius: 0px;
                border-radius: 5px;
                font-size: 16px;
                padding: 0;
            }
            &.btn-submit-setting {
                background: url(#{$IMG_PATH}icon/icn_edit_k.png) 20px center no-repeat;
                -webkit-background-size: 80%;
                background-size: 80%;
                background-position-x: 50% ;
                font-weight: normal;
                width: 34px;
                height: 34px;
                line-height: 34px;
                text-align: center;
                font-size: 16px;
                padding: 0;
            }
            &.btn-submit-delete {
                background: url(#{$IMG_PATH}icon/icn_trash_k.png) 20px center no-repeat;
            -webkit-background-size: 80%;
                background-size:80%;
                background-position-x: 50% ;
                font-weight: normal;
                width: 34px;
                height: 34px;
                line-height: 34px;
                text-align: center;
                font-size: 16px;
                padding: 0;
            }
        
        }
    }

.confirm-search-modal{
    .modal-inner{
        min-width: 600px;
        .btn{
            width: 150px !important;
        }
    }
}

