@charset "UTF-8";

.report-scoring-detail {
  width: 100%;
  height: 100%;
  min-width: 972px;

  .upper-wrap {
    height: 51%;
    width: 100%;
    min-height: 395px;

    .form {
      height: 100%;

      .detail-form-wrap {
        height: calc(100% - 100px);
        background-color: #bae3f9;
        padding: 20px 20px 0px 20px;
        width: 100%;
        min-height: 297px;

        .left-panel {
          width: calc(50% - 30px);
          height: 100%;
          display: inline-block;
          padding-right: 10px;

          .left-panel-upper-wrap {
            width: 100%;
            height: 20%;
            display: flex;
            
            .student-information {
              display: flex;
              width: 100%;
              height: 0px;
              margin-top:20px;
              
              .login-id{
                width: 100%;
              }
              
              .name{
                width: 100%;
              }
              
            }
            
            .div-report-download {
              margin-left:auto;
            
              .btn-report-download {
                margin-top: 20px;
                width: 200px;
                height: 25px;
                position: relative;
                font-size: 12px;
                color: #000000;
                background: #FFFFFF;
                font-weight: normal;
                border-radius: 2px;
                border: 1px solid #c8c8c8;
                vertical-align: middle;
                padding-left: 25px;
              }
              
              .btn-report-download::before {
                content: '';
                display: inline-block;
                width: 18px;
                height: 18px;
                margin: -2px 5px 0 -25px;
                background: url(#{$IMG_PATH}icon/icn_download_k.png) no-repeat;
                background-size: contain;
                vertical-align: middle;
              }
            }
          }

          .feedback-comment-wrap {
            width: 100%;
            height: 80%;

            .feedback-comment-label-region {
              width: 100%;
              height: 22px;

              .feedback-comment-label {
                display: block;
                width: fit-content;
                margin-left: 10px;
              }
            }

            .feedback-comment-input-region {
              width: 100%;
              height: calc(100% - 44px);

              .feedback-comment-input {
                width: 100%;
                max-width: 100%;
                height: 100%;
                max-height: 100%;
                border: 1px solid #ccc;
              }
            }

            .feedback-error-region {
              width: 100%;
              height: 22px;

              p {
                font-size: 12px;
                color: red;
                font-weight: bold;
                width: 100%;
                word-break: break-all;

                &.hide-err {
                  color: #bae3f9;
                }
              }
            }
          }
        }

        .right-panel {
          width: calc(50% - 30px);
          height: calc(100% - 20px);
          display: inline-block;
          padding-left: 10px;
          vertical-align: top;

          .feedback-field-wrap {
            width: 100%;
            height: 100%;

            .feedback-field-score-region {
              margin-top: 22px;
              height: 27px;

              .score-field-wrap {
                width: 190px;
                display: inline-block;

                .score-field-label1 {
                  width: fit-content;
                  display: inline-block;
                }

                .score-field-input-region {
                  width: 60px;
                  display: inline-block;
                }

                .score-field-label2 {
                  margin-left: 10px;
                  width: 60px;
                  display: inline-block;
                }
              }

              .remand-field-wrap {
                width: 100px;
                display: inline-block;

                input[type="checkbox"]:disabled ~ label::before {
                  display: block !important;
                }
              }

              .confirmed-score-wrap {
                width: 145px;
                display: inline-block;
              }

              .checkbox-btn {
                display: inline-block;
              }

            }

            .feedback-field-file-region {
              margin-top: 20px;
              height: calc(100% - 240px);
              width: calc(100% - 50px);

              .feedback-field-file-label-wrap {
                height: 22px;
              }

              .feedback-field-file-list-wrap {
                border: 1px solid #c8c8c8;
                height: 100%;

                .report-file-field-list {
                  height: 100%;
                  background-color: #fff;

                  tr {
                    td:nth-child(1) {
                      width: 50%;
                    }

                    td:nth-child(2) {
                      width: 20%;
                    }

                    td:nth-child(3) {
                      width: 7%;

                      a {
                        margin: auto;
                      }
                    }
                  }

                  .ps-scrollbar-y-rail {
                    right: 0px !important;
                  }
                }
              }
            }

            .feedback-field-upload-region {
              margin-top: 40px;

              .dagdrop {
                width: calc(100% - 60px);
                height: 90px;
                position: relative;
                border: 3px dashed #c8c8c8;
                margin-bottom: 10px;
                margin-top: 15px;
                background: #f1f1f1;

                &::before {
                  display: block;
                  content: "";
                  width: 35px;
                  height: 35px;
                  position: absolute;
                  top: -6px;
                  left: -6px;
                  border-top: 7px solid #c8c8c8;
                  border-left: 7px solid #c8c8c8;
                }

                &::after {
                  display: block;
                  content: "";
                  width: 35px;
                  height: 35px;
                  position: absolute;
                  top: -6px;
                  right: -6px;
                  border-top: 7px solid #c8c8c8;
                  border-right: 7px solid #c8c8c8;
                }

                .border-dagdrop {

                  &::before {
                    display: block;
                    content: "";
                    width: 35px;
                    height: 35px;
                    position: absolute;
                    bottom: -6px;
                    left: -6px;
                    border-bottom: 7px solid #c8c8c8;
                    border-left: 7px solid #c8c8c8;
                  }

                  &::after {
                    display: block;
                    content: "";
                    width: 35px;
                    height: 35px;
                    position: absolute;
                    bottom: -6px;
                    right: -6px;
                    border-bottom: 7px solid #c8c8c8;
                    border-right: 7px solid #c8c8c8;
                  }
                }

                .dragdrop-memo {
                  text-align: center;
                  margin-top: 13px;

                  .dragdrop-image {
                    position: static;
                    width: 65px;
                    display: inline-block;
                  }

                  .dagdrop-text {
                    color: #c8c8c8;
                    position: relative;
                    width: 210px;
                    display: inline-block;
                    margin-top: 22px;
                  }
                }
              }

              .chooseFile {
                width: calc(100% - 60px);

                .chFileWrap {
                  width: 419px;
                  margin: auto;

                  .btn-choosefile {
                    border: 1px solid #a0a0a0;
                    font-weight: normal;
                    border-radius: 2px;
                    font-size: 12px;
                    padding-right: 5px;
                    background-color: white;
                    color: #6e6e6e;
                    width: 125px;
                    line-height: 21px;
                  }

                  .fileName {
                    width: 60%;
                    color: #6e6e6e;
                  }

                  #jsCSVUploadFile {
                    width: 127px;
                    height: 25px;
                    z-index: 99;
                    opacity: 0.0;
                    font-size: 30px;
                    cursor: pointer;
                    overflow: hidden;
                    margin-left: -93%;
                  }
                }
              }
            }
          }
        }
      }

      .detail-btn-wrap {
        height: 80px;

        .btn-submit-region {
          display: inline-block;
          width: 47%;
          height: 100%;

          .btn-submit {
            width: 175px !important;
            margin: 15px 15px 15px;
            float: left;
          }
        }

        .btn-back-region {
          display: inline-block;
          width: 47%;
          height: 100%;

          .btn-back {
            width: 175px !important;
            text-align: center;
            margin: 15px 15px 15px;
            float: right;
          }
        }
      }
    }

    & + .bottom-wrap {
      @media screen and (max-height: 775px) {
        height: 40%;
      }

      @media screen and (min-height: 776px) {
        height: 46%;
      }

      @media screen and (min-height: 810px) {
        height: 48%;
      }

    }
  }

  .bottom-wrap {
    width: 100%;

    @media screen and (max-height: 775px) {
      height: 80%;
      max-height: 135%;
    }

    @media screen and (min-height: 776px) {
      height: 85%;
      max-height: 135%;
    }

    @media screen and (min-height: 810px) {
      height: 92%;
      max-height: 130%;
    }

    .report-scoring-history {
      height: 100%;
      width: 100%;

      form {
        height: calc(100% - 20px);
        padding: 10px;
        width: calc(100% - 20px);

        .report-scoring-history-list {
          border: 1px solid #ccc;
          height: 100%;
          width: 100%;

          .report-scoring-history-item {
            height: 195px;
            border-bottom: 1px solid #ccc;

            .detail-form-wrap {
              height: 172px;
              padding: 10px;
              width: calc(100% - 20px);
              position: relative;

              .left-panel {
                width: calc(50% - 10px);
                height: 100%;
                display: inline-block;
                padding-right: 10px;

                .feedback-comment-wrap {
                  width: 100%;
                  height: 100%;

                  .feedback-comment-label-region {
                    width: 100%;
                    height: 22px;

                    .feedback-comment-label {
                      display: block;
                      width: fit-content;
                      margin-left: 10px;
                    }
                  }

                  .feedback-comment-input-region {
                    width: 100%;
                    height: 150px;

                    .feedback-comment-input {
                      width: 100%;
                      max-width: 100%;
                      height: 100%;
                      max-height: 100%;
                      border: 1px solid #ccc;
                    }
                  }

                  .feedback-error-region {
                    width: 100%;
                    height: 22px;

                    p {
                      font-size: 12px;
                      color: red;
                      font-weight: bold;
                      width: 100%;
                      word-break: break-all;

                      &.hide-err {
                        color: #bae3f9;
                      }
                    }
                  }
                }
              }

              .right-panel {
                width: calc(50% - 30px);
                height: 100%;
                display: inline-block;
                padding-left: 10px;
                vertical-align: top;

                .feedback-field-wrap {
                  width: 100%;
                  height: 100%;

                  .feedback-field-score-region {
                    margin-top: 22px;
                    height: 27px;
                    width: calc(100% - 29px);

                    .score-field-wrap {
                      width: 190px;
                      display: inline-block;

                      .score-field-label1 {
                        width: fit-content;
                        display: inline-block;
                      }

                      .score-field-input-region {
                        width: 60px;
                        display: inline-block;
                      }

                      .score-field-label2 {
                        margin-left: 10px;
                        width: 60px;
                        display: inline-block;
                      }
                    }

                    .remand-field-wrap {
                      width: 100px;
                      display: inline-block;

                      input[type="checkbox"]:disabled ~ label::before {
                        display: block !important;
                      }
                    }

                    .confirmed-score-wrap {
                      width: calc(100% - 325px);
                      display: inline-block;
                      height: 15px;

                      // @media screen and (max-width: 1170px) {
                      //   display: block;
                      //   margin-top: 5px;
                      // }

                      .confirmed-score-content-column {
                        position: absolute;
                        height: 70px;

                        @media screen and (max-width: 1170px) {
                          padding-left: 10px;
                        }

                        .confirmed-score-pair {

                          // @media screen and (max-width: 1170px) {
                          //   display: inline-block;
                          // }

                          .confirmed-score-label {
                            display: inline-block;
                            width: 90px;

                            @media screen and (max-width: 1170px) {
                              width: auto;
                            }
                          }

                          .confirmed-score-value {
                            display: inline-block;
                            //width: 90px;
                          }
                        }

                        .confirmed-score-date-pair {

                          // @media screen and (max-width: 1170px) {
                          //   display: inline-block;
                          //   margin-left: 10px;
                          // }

                          .confirmed-score-date-label {
                            display: inline-block;
                            width: 90px;

                            @media screen and (max-width: 1170px) {
                              width: auto;
                            }
                          }

                          .confirmed-score-date-value {
                            display: inline-block;
                            //width: 90px;
                          }
                        }

                        .confirmed-grader-pair {

                          // @media screen and (max-width: 1170px) {
                          //   display: inline-block;
                          //   margin-left: 10px;
                          // }

                          .confirmed-grader-label {
                            display: inline-block;
                            width: 90px;

                            @media screen and (max-width: 1170px) {
                              width: auto;
                            }
                          }

                          .confirmed-grader-value {
                            display: inline-block;
                            //width: 100px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            vertical-align: middle;
                            white-space: pre !important;
                          }
                        }

                        .ps-scrollbar-y-rail {
                          right: 0px !important;

                          @media screen and (max-width: 1170px) {
                            left: 0px !important;
                          }
                        }
                      }
                    }
                  }

                  .feedback-field-file-region {
                    margin-top: 27px;
                    height: 73px;
                    width: calc(100% - 29px);

                    .feedback-field-file-label-wrap {
                      height: 22px;
                    }

                    .feedback-field-file-list-wrap {
                      border: 1px solid #c8c8c8;
                      height: 100%;

                      .report-file-field-list {
                        height: 100%;
                        background-color: #fff;

                        tr {
                          td:nth-child(1) {
                            width: 70%;
                          }

                          td:nth-child(2) {
                            width: 20%;

                            a {
                              float: none;
                              margin: auto;
                            }
                          }

                          td:nth-child(3) {
                            display: none;
                            width: 7%;

                            a {
                              margin: auto;
                            }
                          }
                        }

                        .ps-scrollbar-y-rail {
                          right: 0px !important;
                        }
                      }
                    }
                  }

                }
              }

              .topright-btn {
                position: absolute;
                top: 10px;
                right: 10px;

                .btn-image {
                  background-image: url(#{$IMG_PATH}icon/icn_trash_k.png);
                  display: block;
                  border: none;
                  background-size: 20px 20px;
                  background-repeat: no-repeat;
                  width: 25px;
                  height: 20px;
                  margin: 0px auto;

                  &:hover {
                    opacity: .7;
                  }
                }
              }

            }

            &:nth-last-child(3) {
              border-bottom: none;
            }
          }

          .ps-scrollbar-y-rail {
            right: 0px !important;
          }
        }
      }
    }
  }
}
