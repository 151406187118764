@charset "UTF-8";
.page-confirm-target-user {
    height: 100%;
    .page-inner {
        background: $background_theme_color_contents;
        height: 100%;
        .page-contents {
            height: 100%;
            width: 100%;
            margin: auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
             .m-form-search {
                 padding: 0;
                 .error {
                     margin-top: 1px;
                 }
                .form-group {
                    .inner {
                        width: 85%;
                        .item-group{
                            width: 25%;
                            margin-bottom: 10px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            .content{
                                white-space: pre;
                            }
                            .all-content{
                                display: none;
                                position: absolute;
                                z-index: 1;
                                padding: 10px;
                                max-width: 400px;
                                -webkit-box-sizing: border-box;
                                -moz-box-sizing: border-box;
                                box-sizing: border-box;
                                background: #333;
                                color: #fff;
                                -webkit-border-radius: 5px;
                                -moz-border-radius: 5px;
                                border-radius: 5px;
                                font-size: 12px;
                                margin-top: 5px;
                                white-space: pre-wrap;
                                word-break: break-all;
                                &:before {
                                    background: #333;
                                    z-index: 1;
                                    @include PseudoElements();
                                    width: 10px;
                                    height: 10px;
                                    position: absolute;
                                    top: -5px;
                                    -webkit-transform: rotate(-45deg);
                                    -moz-transform: rotate(-45deg);
                                    -ms-transform: rotate(-45deg);
                                    -o-transform: rotate(-45deg);
                                    transform: rotate(-45deg);

                                }
                            }
                        }

                        .item-group:hover {
                            .all-content {
                                display: block;
                             }
                        }
                    }
                }
            }
        }

      .course-header {
        display: flex;
        padding-bottom: 5px;

        .except {
          height: 71px;
          display: none;
        }

        .result-txt {
          margin-top: 5px;
          margin-left: 10px;
          min-height: 30px;
          display: flex;
          align-items: center;
        }

      }

        .mail-search-header {
            background: #fff;
            padding: 0 20px;
            .inner {
                min-height: 34px;
                padding: 20px 0 0 0;
                position: relative;
                overflow: hidden;
                .back {
                    @include HoverOpacity();
                    margin-top: 4px;
                    position: absolute;
                    left: 0;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
                .name{
                    padding-left: 30px;
                    line-height: 30px;
                    display: inline-block;
                    cursor: default;
                    word-break: break-all;
                    width: 90%;
                }
                .btn {
                    float: right;
                    text-align: center;
                }
            }
        }

        .mail-result {
            @include search_table_f;
            margin: 0 10px;
            padding: 0;
            position: relative;
            height: 77%;
            @media screen and (max-height: 980px) {
                height : 76%;
            }
            @media screen and (max-height: 950px) {
                height : 75%;
            }
            @media screen and (max-height: 910px) {
                height : 74%;
            }
            @media screen and (max-height: 880px) {
                height : 73%;
            }
            @media screen and (max-height: 850px) {
                height : 72%;
            }
            @media screen and (max-height: 820px) {
                height : 71%;
            }
            @media screen and (max-height: 790px) {
                height : 70%;
            }
            @media screen and (max-height: 760px) {
                height : 69%;
            }
            @media screen and (max-height: 740px) {
                height : 67%;
            }
            @media screen and (max-height: 700px) {
                height : 66%;
            }
            @media screen and (max-height: 685px) {
                height : 65%;
            }
            @media screen and (max-height: 665px) {
                height : 64%;
            }
            @media screen and (max-height: 650px) {
                height : 63%;
            }
            @media screen and (max-height: 630px) {
                height : 62%;
            }
            @media screen and (max-height: 615px) {
                height : 61%;
            }
            @media screen and (max-height: 600px) {
                height : 60%;
            }
            .table-header{
                table-layout: auto;
                tr{
                    width: 100%;
                    position: fixed;
                    th{
                        padding: 5px;
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                }
                .col-user-id{
                    min-width: 90px;
                }
                .col-user-name{
                    min-width: 124px;
                }
                .col-user-group{
                   min-width: 184px;
                }
                .col-role{
                   min-width: 120px;
                }
                .col-mail{
                   min-width: 150px;
                } 
                .col-sent{
                    min-width: 102px;
                } 
                .col-status{
                    min-width: 100px;
                }
            }
            .table-result-nodata{
                height: 100%;
                tr{
                    td{
                        border: 0px solid #fafafa  !important;
                        color: #AFAFAF;
                         box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        span{
                            position: relative;
                        }
                    }
                }
            }
            .table-result{
                table-layout: auto;
                .col-user-id{
                    min-width: 90px;
                    width: 8%;
                }
                .col-user-name{
                    min-width: 124px;
                    width: 15%;
                }
                .col-user-group{
                   min-width: 184px;
                   width: 25%;
                   text-align: left;
                   padding: 5px 10px;
                }
                .col-role{
                   min-width: 120px;
                   width: 10%;
                }
                .col-mail{
                   min-width: 150px;
                   width: 20%;
                   text-align: left;
                   padding: 5px 10px;
                }
                .col-sent{
                   min-width: 102px;
                   width: 12%;
                } 
                .col-status{
                   min-width: 100px;
                   width: 10%;
                }
                tr{
                    td:not(.row-ope){
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                }
            }

              
        }
        
        .mail-list-inner{
            height: 100%;
            background-color: #fff;
        }
        .mail-list-outer{
            width: 100%;
            height: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;}
          }
        .scroll-area {
            &.ps-active-y {
                padding-right: 16.2px;
                margin-right: -16.2px;
            }
            .ps-scrollbar-y-rail{
                right: 16.2px;
            }
            height: 100%;
        } // .scroll-area

    }

.confirm-search-modal{
    .modal-inner{
        min-width: 600px;
        .btn{
            width: 150px !important;
        }
    }
}

