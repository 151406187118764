@charset "UTF-8";

.admin-import {

  .m-modal {
    .modal-inner {
      top: 10%;

      &.confirm-link-modal {
        color: #6e6e6e;
      }

      &.modal-inner-import {
        padding-left: 0px;
        padding-right: 0px;
        width: 1400px !important;
        max-width: 1250px;
        height: 650px;

        .title {
          span {
            color: #82bef0;
            font-size: 18px;
          }
        }

        .csvLocation {
          text-align: center;
          font-size: 20px;
          display: inline;
          float: left;
          margin-left: 0%;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          margin-top: 4.5%;
          color: #6e6e6e;
          max-width: 500px !important;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .areaTypeImport {
          display: inline;
          text-align: right;
          float: right;
          margin-right: 3%;
          margin-top: 5%;
        }

        .typename {
          text-align: right;
          display: inline;
          margin-right: 15px;
          color: #6e6e6e;
        }

        .typecode {
          text-align: right;
          display: inline;

        }

        .typecode {
          float: right;
          margin-right: 5px;
          margin-top: -5px;

          .sel {
            background-color: white;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 0px;
            border: 1px solid;
            width: 130px;
          }

          .sel.sel-size-auto {
            height: 28px;
            width: 130px !important;
            border: 1px solid #a0a0a0;

            .sel-txt {
              background-size: 18px;
              line-height: 28px;
              background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
              color: #6e6e6e;
            }
          }
        }

        .btnRefesh {
          background: url(#{$IMG_PATH}icon/icn_reload_k.png) 20px center no-repeat;
          background-size: 90%;
          border: 1px solid #a0a0a0;
          background-position-x: 50%;
          width: 28px;
          height: 28px;
          vertical-align: middle;
        }

        .course-result-f {
          background-color: #d2ebfa;
          margin-top: 10%;
          height: 60%;
          padding: 20px 20px 20px 10px !important;

          .scroll-area {

            &.ps-active-y {
//              margin-top: 41px;
            }

            &.ps-active-x {
              margin-top: 7%;
              padding-bottom: 5px;
            }

            .ps-scrollbar-y-rail {
              right: 0;
              margin-bottom: 6px;
              opacity: .4 !important;
            }

            .ps-scrollbar-x-rail {
              height: 7px !important;
              opacity: .4 !important;
            }

            .ps-scrollbar-x {
              display: none;
              height: 100%;
              bottom: 0;
            }

            height: 100%;
            width: 100%;
          }

          // .scroll-area
          .modal-import-list-inner {
            min-height: 100%;
            min-width: 100%;
          }

          .modal-import-list-outer {
            position: absolute;
            right: 45px;
            top: 29%;
            width: 84%;
            height: 40%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            border: none;
          }
          
          
          .modal-import-list-outer:hover .ps-scrollbar-x {
            display: none;
          }

          .modal-import-list-outer:hover .ps-scrollbar-x,
          .modal-import-list-outer:hover .ps-scrollbar-y {
            position: absolute;
            background-color: black;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
            -webkit-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
            transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
            -moz-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
            -o-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
            transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
            transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -webkit-border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
          }

          @include search_table_f;

          .init-requied {
            position: absolute;
            margin-top: -15px;
            margin-left: 1120px;
            color: red;
            font-size: 11px;
            z-index: 10;

          }

          table {
            table-layout: auto;
            color: #6e6e6e;

            tbody {
              width: 100%;
              height: 100%;
            }

            thead {
              width: 860px;
            }
          }

          tr {

            td,
            th {
              min-width: 340px !important;
            }

            &.header {
              position: fixed;
              width: 1050px;
              touch-action: none;
              overflow: hidden !important;

              @media screen and (min-height: 820px) {
                top: 290px !important;
              }

              @media screen and (min-height: 910px) {
                top: 296px !important;
              }

              .ps-scrollbar-x-rail {
                display: none;
              }

              .required {
                position: absolute;
                margin-top: -3px;
                font-size: 12px;
                color: red;
                z-index: 10;
              }

              label::after {
                width: 12px !important;
                height: 12px !important;
              }

              label::before {
                width: 7px !important;
                height: 3px !important;
                top: 4px !important;
                left: 3px !important;
              }

            }

          }

          tr:first-child th {
            line-height: 23px;
            background: #d2ebfa !important;
            border-left-color: #d2ebfa !important;
            border-right-color: #d2ebfa !important;
            border-bottom-color: #d2ebfa !important;
            padding: 5px;
            color: #424242;
          }

          tr:nth-child(2n) td {
            background: #fafafa;
            line-height: 35px;
            width: 300px;
          }

          td {
            background: #fff;
            line-height: 35px;
          }

          tr:first-child td {
            border-top: 0px solid #fafafa;
          }
        }

        .nameCSV {
          width: 105px !important;
          height: 155px;
          display: table-cell;
          vertical-align: top;
          color: #424242;
          padding-right: 5px;
          position: absolute;
          margin-top: 10px;
          margin-left: 20px;
        }

        .btn-next-confirm {
          margin-top: 24px;
          width: 160px;
          display: inline;
          background-color: white;
          border-radius: 3px;
          line-height: 26px;
          color: #468CC8;
          border: 1px solid #468CC8;
          border-radius: 2px;
          font-weight: normal;

        }

      }

    }

    .nameCSV {

      label::after {
        width: 12px !important;
        height: 12px !important;
      }

      label::before {

        width: 7px !important;
        height: 3px !important;
        top: 4px !important;
        left: 3px !important;
      }

    }
  }

  @media screen and (max-width : 1366px) {
    @media screen and (max-height: 768px) {

      .m-modal {
        .modal-inner {
          &.modal-inner-import {
            padding-left: 0px;
            padding-right: 0px;
            width: 1400px !important;
            max-width: 1100px;
            height: 550px;

            .title {
              span {
                color: #82bef0;
                font-size: 18px;
              }
            }

            .csvLocation {
              text-align: center;
              font-size: 20px;
              display: inline;
              float: left;
              margin-left: 0%;
              margin-top: 1.5%;
              max-width: 500px !important;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .areaTypeImport {
              display: inline;
              text-align: right;
              float: right;
              margin-right: 5%;
              margin-top: 2%;
            }

            .typename {
              text-align: right;
              display: inline;
            }

            .typecode {
              text-align: right;
              display: inline;

            }

            .typeCombo {
              -webkit-appearance: button;
              -webkit-border-radius: 2px;
              -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
              -webkit-user-select: none;
              border: 1px solid #AAA;
              color: #555;
              font-size: inherit;
              margin: 2px;
              overflow: hidden;
              padding: 2px 0px;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 130px;

              .sel-txt {
                background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
              }
            }

            .btnRefesh {
              background: url(#{$IMG_PATH}icon/icn_reload_k.png) 20px center no-repeat;
              border: 1px solid #a0a0a0;
              background-size: 90%;
              background-position-x: 50%;
              width: 28px;
              height: 28px;
              vertical-align: middle;
            }

        .course-result-f {
          background-color: #d2ebfa;
          margin-top: 10%;
          height: 60%;
          padding: 20px 20px 20px 10px!important;
           .scroll-area {
              
             &.ps-active-y {
//              margin-top: 41px;
             }
             &.ps-active-x {
              margin-top: 7%;
              padding-bottom: 5px;
             }
             .ps-scrollbar-y-rail{
                right: 0;
                margin-bottom: 6px;
                opacity: .4 !important;
             }

             .ps-scrollbar-x-rail{
              height: 7px !important;
              opacity: .4 !important;
             }
             .ps-scrollbar-x{
              height: 100%;
              bottom: 0;
             }
            height: 100%;
            width: 100%;
          } // .scroll-area
          .modal-import-list-inner{
            min-height: 100%;
            min-width: 100%;
          }
          .modal-import-list-outer{
            position: absolute;
            right: 45px;
            top: 29%;
            width: 84%;
            height: 40%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            border: none;
          }
          .modal-import-list-outer:hover .ps-scrollbar-x,
          .modal-import-list-outer:hover .ps-scrollbar-y{
            position: absolute;
            background-color: black;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
            -webkit-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
            transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
            -moz-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
            -o-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
            transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
            transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -webkit-border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
          }

          @include search_table_f;
          .init-requied {
            position: absolute;
            margin-top: -15px;
            margin-left: 1120px;
            color: red;
            font-size: 11px;
            
          }
          table {
            table-layout: auto;
            color: #6e6e6e;
            tbody{
              width: 100%;
              height: 100%;
            }
            thead{
                width: 860px;
            }
          }
          tr{
            td,th{
              min-width: 200px !important;
            }
            &.header{   
                position: fixed;  
                width: 1050px;
                touch-action: none;
                overflow: hidden !important;
               
                .ps-scrollbar-x-rail{
                  display: none;
                }
                .required {
                  position: absolute;
                  margin-top: -3px;
                  font-size: 12px;
                  color: red;
                }

            label::after {
              width: 12px!important;
                height: 12px!important;
            }
            label::before {
              width: 7px!important;
              height: 3px!important;
              top: 4px!important;
              left: 3px!important;
            }    

                }
          }

          tr:first-child th {
              width: 0.1%;
              line-height: 23px;
              background: #d2ebfa !important;
              border-left-color: #d2ebfa !important;
              border-right-color: #d2ebfa !important;
              border-bottom-color: #d2ebfa !important;
              padding: 5px;
              color: #424242;
            }
            tr:nth-child(2n) td {
              background: #fafafa;
              line-height: 35px;
              width: 300px;            }

            td {
              background: #fff;
              line-height: 35px;
            }
             tr:first-child td {
              border-top: 0px solid #fafafa;             
            }
        }
         .nameCSV {
            width: 105px !important;
            height: 155px;
            display: table-cell;
            vertical-align: top;
            color: #424242;
            padding-right: 5px;
            position: absolute;
            margin-top: -5px;
            margin-left: 20px;
          }

          .btn-next-confirm {
            margin-top: 24px;
            width: 160px;
            display: inline;
            background-color: white;
            border-radius: 3px;
            line-height: 26px;
            color: #468CC8;
             border: 1px solid #468CC8;
             border-radius: 2px;
            font-weight: normal;

          }

        }

  }

    .nameCSV{

      label::after {
          width: 12px!important;
          height: 12px!important;
      }
      label::before {

          width: 7px!important;
          height: 3px!important;
          top: 4px!important;
          left: 3px!important;
      }    
          
        }
  }

        @media screen and (max-width : 1366px) {
        @media screen and (max-height : 768px) {
          
          .m-modal {
    .modal-inner {
        &.modal-inner-import {
          padding-left: 0px;
          padding-right: 0px;
          width: 1400px !important;
          max-width: 1100px;
          height: 550px;
       .title {
          span {
          color: #82bef0;
          font-size: 18px;
          }
        }

        .csvLocation {
          text-align: left;
          font-size: 20px;
          display: inline;
          float: left;
          max-width: 500px!important;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .areaTypeImport {
          display: inline;
          text-align: right;
          float: right;
          margin-right: 5%;
          margin-top: 2%;
        }

        .typename {
          text-align:right;
          display: inline;  
        }
        .typecode {
            text-align:right;
            display: inline;  
          
        }
        .typeCombo {
          -webkit-appearance: button;
          -webkit-border-radius: 2px;
          -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
          -webkit-user-select: none;
           border: 1px solid #AAA;
          color: #555;
          font-size: inherit;
          margin: 2px;
          overflow: hidden;
          padding: 2px 0px;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 130px;
          .sel-txt{
          background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
          }
        }
        .btnRefesh {
          background: url(#{$IMG_PATH}icon/icn_reload_k.png) 20px center no-repeat;
          border: 1px solid #a0a0a0;
          background-size: 90%;
          background-position-x: 50%;          
          width:28px;
          height:28px;
          vertical-align:middle;
        }

        .course-result-f {
          background-color: #d2ebfa;
          margin-top: 7%;
          height: 60%;
           .scroll-area {
              
             &.ps-active-y {
//              margin-top: 41px;
             }
             &.ps-active-x {
              margin-top: 0%;
              padding-bottom: 5px;
             }
             .ps-scrollbar-y-rail{
                right: 0;
                margin-bottom: 6px;
                opacity: .4 !important;
             }

             .ps-scrollbar-x-rail{
              height: 7px !important;
              opacity: .4 !important;
             }
             .ps-scrollbar-x{
              height: 100%;
              bottom: 0;
             }
            height: 100%;
            width: 100%;
            
          } // .scroll-area
          .modal-import-list-inner{
            min-height: 100%;
            min-width: 100%;
          }
          .modal-import-list-outer{
            position: absolute;
            right: 60px;
            top: 37%;
            width: 83%;
            height: 40%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            border: none;
          }
          @include search_table_f;
          .init-requied {
            position: absolute;
            margin-top: -15px;
            margin-left: 960px;
            color: red;
            font-size: 11px;
            
          }
          table {
            table-layout: auto;
            font-size: 12px;
            tbody{
              width: 100%;
              height: 100%;
            }
            thead{
                width: 860px;
            }
          }
          tr{
            td,th{
              min-width: 293px !important;
            }
            &.header{   
                position: fixed;
                width: 912px;
                .ps-scrollbar-x-rail{
                  display: none;
                }
                .required {
                  position: absolute;
                  margin-top: -3px;
                  font-size: 12px;
                  color: red;
                }

              }
          }

          tr:first-child th {
              line-height: 23px;
              background: #d2ebfa !important;
              border-left-color: #d2ebfa !important;
              border-right-color: #d2ebfa !important;
              border-bottom-color: #d2ebfa !important;
              padding: 5px;
              color: #424242;
            }
            tr:nth-child(2n) td {
              background: #fafafa;
              line-height: 35px;
              width: 300px;            }

            td {
              background: #fff;
              line-height: 35px;
            }
             tr:first-child td {
              border-top: 0px solid #fafafa;             
            }
        }
         .nameCSV {
            max-width: 100px !important;
            height: 155px;
            display: table-cell;
            vertical-align: top;
            color: #424242;
            padding-right: 5px;
            position: absolute;
            margin-top: 10px;
            margin-left: 15px;
            font-size: 12px;

          }

          .btn-next-confirm {
            margin-top: 24px;
            width: 160px;
            display: inline;
            background-color: white;
            border-radius: 3px;
            line-height: 26px;
            color: #468CC8;
             border: 1px solid #468CC8;
             border-radius: 2px;
            font-weight: normal;

          }

        }

  }

  }
        }
          
        }

        @media screen and (max-width : 1280px) {
          
          .m-modal {
    .modal-inner {
        &.modal-inner-import {
          padding-left: 0px;
          padding-right: 0px;
          width: 1400px !important;
          max-width: 1100px;
          height: 550px;
       .title {
          span {
          color: #82bef0;
          font-size: 18px;
          }
        }

        .csvLocation {
          text-align: left;
          font-size: 20px;
          display: inline;
          float: left;
          margin-top: 2%;
          max-width: 550px!important;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .areaTypeImport {
          display: inline;
          text-align: right;
          float: right;
          margin-right: 4%;
          margin-top: 2%;
        }

        .typename {
          text-align:right;
          display: inline;  
        }
        .typecode {
            text-align:right;
            display: inline;  
          
        }
        .typeCombo {
          -webkit-appearance: button;
          -webkit-border-radius: 2px;
          -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
          -webkit-user-select: none;
           border: 1px solid #AAA;
          color: #555;
          font-size: inherit;
          margin: 2px;
          overflow: hidden;
          padding: 2px 0px;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 130px;
          .sel-txt{
            background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
          }
        }
        .btnRefesh {
          background: url(#{$IMG_PATH}icon/icn_reload_k.png) 20px center no-repeat;
          background-size: 90%;
          border: 1px solid #a0a0a0;
          background-position-x: 50%;          
          width:28px;
          height:28px;
          vertical-align:middle;
        }


        .course-result-f {
          background-color: #d2ebfa;
          margin-top: 7%;
          height: 60%;
           .scroll-area {
              
             &.ps-active-y {
//              margin-top: 41px;
             }
             &.ps-active-x {
              margin-top: 0%;
              padding-bottom: 5px;
             }
             .ps-scrollbar-y-rail{
                right: 0;
                margin-bottom: 6px;
             }

             .ps-scrollbar-x-rail{
              height: 7px !important;
              opacity: .4 !important;
             }
             .ps-scrollbar-x{
              height: 100%;
              bottom: 0;
             }
            height: 100%;
            width: 100%;
          } // .scroll-area
          .modal-import-list-inner{
            min-height: 100%;
            min-width: 100%;
          }
          .modal-import-list-outer{
            position: absolute;
            right: 45px;
            top: 39%;
            width: 86%;
            height: 40%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            border: none;
          }
          @include search_table_f;
          .init-requied {
            position: absolute;
            margin-top: -15px;
            margin-left: 995px;
            color: red;
            font-size: 11px;
            
          }
          table {
            table-layout: auto;
            font-size: 12px;
            tbody{
              width: 100%;
              height: 100%;
            }
            thead{
                width: 860px;
            }
          }
          tr{
            td,th{
              min-width: 185px !important;
            }
            &.header{   
                position: fixed;
                width: 945px;
                .ps-scrollbar-x-rail{
                  display: none;
                }
                .required {
                  position: absolute;
                  margin-top: -3px;
                  font-size: 12px;
                  color: red;
                }

              }
          }

          tr:first-child th {
              line-height: 23px;
              background: #d2ebfa !important;
              border-left-color: #d2ebfa !important;
              border-right-color: #d2ebfa !important;
              border-bottom-color: #d2ebfa !important;
              padding: 5px;
             color: #424242;
            }
            tr:nth-child(2n) td {
              background: #fafafa;
              line-height: 35px;
              width: 300px;            }

            td {
              background: #fff;
              line-height: 35px;
            }
             tr:first-child td {
              border-top: 0px solid #fafafa;             
            }
           
        }
         .nameCSV {
            width: 95px !important;
            height: 155px;
            display: table-cell;
            vertical-align: top;
            color: #424242;
            padding-right: 5px;
            padding-top: 2px;
            position: absolute;
            margin-top: 10px;
            font-size: 12px;
            margin-left: 5px;
          }

          .btn-next-confirm {
            margin-top: 24px;
            width: 160px;
            display: inline;
            background-color: white;
            border-radius: 3px;
            line-height: 26px;
            color: #468CC8;
             border: 1px solid #468CC8;
             border-radius: 2px;
            font-weight: normal;

          }

        }

  }

  }
          

}}}}