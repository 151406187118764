@charset "UTF-8";

.main-container {
  width: 100%;
  min-width: 840px;
  height: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1800px;	
  margin: 0% 0% 0% 0%;	

  .face-monitoring {
    position: absolute;
    right: 1px;
    top: 61px;
    width: 224px;

    &-inner {
      position: relative;
      height: 168px;
    }

    .face {
      background: #eee;
      width: 100%;
      height: 100%;
    }

    .face-alert {
      position: absolute;
      background: rgba(255, 255, 255, .7);
      color: #ff0000;
      padding: 5px;
      box-sizing: border-box;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 95%;
    }
  }

  .main-wrap {
    display: flex;
  }

  .main-test {
    height: 100%;
    width: 75%;
    position: absolute;
    min-height: 568px;
  }

  /* Pagination */
  .paginationNav {
    display: none
  }

  /* CSS Content Left*/
  .col1 {
    flex: 1 480px;						
    min-width: calc(840px - 480px);		
    height: 83%;
    min-height: 549px;

    .main {
      overflow-y: auto;
      overflow-x: hidden;
      padding: 10px 20px 0px 20px;
      position: relative;
      min-height: 470px;

      p {
        padding: 8px 0px;
        word-break: break-all;
        white-space: pre-wrap;
      }

      .decription {
        height: calc(100% - 70px);

        .header {
          text-align: center;
          margin-top: 25px;
          font-size: 25px;
          color: #8AC2F1;
          word-break: break-all;

          p {
            margin: 0px;
          }
        }

        .content {

          border: 1px solid #8B8B8B;
          width: 85%;
          height: 70%;
          margin: 15px auto 10px auto;
          padding: 35px 24px 25px 24px;
          color: #333;
          font-size: 16px;
          word-break: break-word;
          word-wrap: break-word;

          .content-outer {
            width: 100%;
            height: 235px;
            padding-right: 7px;
          }

          .err-mess {
            color: red;
          }
        }

      }

      .question-main {
        font-size: 18px;
        color: #333;

        .question-cell {
          margin-bottom: 5px;
        }

        .answer-content {
          margin: 10px 5px;

          .text {
            text-align: center;
          }

          .lbl-first {
            margin: 0px 10px -7px 5px;
            overflow: hidden;
            max-width: 1000%;
            display: inline-block;
            font-weight: bold;
          }

          .lbl-correct {
            color: #9CC947;
          }

          .lbl-incorrect {
            color: #EF8976;
          }

          .answer {
            margin: 2px 0px;
            max-width: 100%;
            margin-bottom: 5px;
            padding: 3px 3px;
            word-break: break-all;

            label {
              display: block;
              margin-left: 20px;

              p:first-of-type {
                padding-top: 0;
              }

              .img {
                padding-bottom: 10px;
              }
              
            }

            input[type=radio] {
              display: none;
            }

            input[type=checkbox] {
              display: none;
            }
            
            .valid-click-range {
              &.option-text {
                padding-left: 30px;
                p {
                  padding: 0;
                }
              }
              &.img {
               img {
                  cursor: pointer;
                }
              }
            }
          }

          .text {
            text-align: center;
          }

          .correct {
            background: #9CC947;

            input[type="text"] {
              width: 95%;
              background: #9CC947;
              border: 0px solid #9CC947;
            }
          }

          .incorrect {
            background: #EF8976;

            input[type="text"] {
              width: 95%;
              background: #EF8976;
              border: 0px solid #EF8976;
            }
          }

          .comment {
            padding: 10px 5px;
          }
        }

        .answer-result {
          label {
            pointer-events: none;
          }

          input {
            pointer-events: none;
          }

          textarea {
            pointer-events: none;
          }

          label::after {
            border-color: #787878;
          }

          label::after {
            background-color: #fff;
          }

          input[type="radio"] + label::before {
            background-color: #787878;
          }

          input[type="checkbox"] + label::before {
            border: 3px solid #787878;
            border-top: none;
            border-right: none;
          }
        }

        textarea {
          width: 98%;
          resize: vertical;
          min-height: 110px;
          font-size: 16px;
          border: 1px solid #797979;
          color: #333;
        }

        input[type=text] {
          height: 25px;
          width: 98%;
          color: #333;
          font-size: 16px;
        }

        .clear {
          width: 98%;
          height: 1px;
          background: #9A9A9A;
          margin: 0 auto;
        }

        .question-content {
          margin-bottom: 10px;
          width: 100%;

          .txt {
            padding: 10px 0px 0px 10px;

            .title {
              font-size: 21px;
              font-weight: bold;
            }

            .required {
              font-size: 12px;
              color: red;
              display: block;
              margin-top: -5px;
            }
          }
        }

        .img {
          display: flex;
          flex-direction: column;
          align-items: center;

          img:not(:first-of-type) {
            margin-top: 10px;
          }
        }

        .audio {
          text-align: center;

          audio {
            height: 50px;
            width: 300px;
          }
        }
      }
    }

    // End css main

    .btn-feedback-area {
      padding: 15px 0px;
      text-align: center;
    }

    .footer {
      text-align: center;
      height: 48px;
      margin: 5px 0px;
      display: flex;
      justify-content: center;

      a {
        margin: 2px 10px;
      }
      .all-comment > .all-comment-outter > .scroll-area {
        padding-bottom: 1px;
      }
    }
  }

  // End css col1

  .right-col {
//    width: 480px;	//CC20-4502 => Move to ".col1",".col2",".col3"
  }

  /* CSS Content Timer*/
  .col2 {
    width: 100%;
    max-width: 480px;	
    height: 230px;
    padding-right: 224px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;

    .done {
      text-align: center;
      font-size: 28px;
      font-weight: bold;
      color: #E3760D !important;
      width: 240px;

      span {
        font-size: 25px !important;
        color: #E3760D !important;
      }
    }

    .btn-modal-exit {
      position: absolute;
      top: 2px;
      right: 4px;
      z-index: 1;
    }

    .timer {
      margin: 0px 20px;
      width: 460px;
      text-align: left;
      height: 38px;

      .pre-done span {
        font-size: 30px;
        color: #333;
      }
    }

    .btn-area {
      margin: 15px 15px;
      width: 480px;

      & > a:first-child {
        margin-right: 10px;
      }
    }
    .question-number-area {
      margin: 5px 15px;
      width: 480px;
      .question-number {
        display: block;
        font-size: 30px;
        color: #333;
        padding-left: 5px;
      }
    }
  }

  // End Css col2

  .col3 {
    width: 100%;
    max-width: 480px;

    .col3-inner {
      padding-bottom: 1px;


      overflow: hidden;

      .info {
        text-align: left;
        margin: 3% 5% 0px 5%;
        width: 90%;
        height: 97%;

        font-size: 20px;
        color: #333;

        p {
          padding: 10px 0px;
        }

        .recruited-score {
          color: #FF0000;
        }

        .late-arrival {
          font-size: 14px;
          opacity: 0.7;
          margin-top: -18px;
        }
      }

      .questions-area {
        position: relative;
        overflow-x: hidden !important;
        height: 100%;

        ul {
          padding: 0px;
          margin: 0px;
          list-style: none;
        }

        .question-scroll {
          height: 100%;

          ul {
            li {
              height: 44px;
              border-bottom: 1px solid;
              cursor: pointer;

              a {
                position: relative;
                display: block;
                font-size: 13px;
                text-decoration: none;
              }

              &:hover {
                opacity: 0.6;
              }

              .txt {
                padding: 8px 0px 8px 15px;
                font-size: 18px;
                color: #333;
                float: left;
              }

              p {
                margin: 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }

              .left {
                width: 20%;
              }

              .right {
                width: 50%;
              }

              .speaker {
                float: right;

                .img-speaker {
                  background-repeat: no-repeat;
                  height: 25px;
                  background-size: 25px;
                  background-position: 0px 5px;
                  display: block;
                  padding-left: 40px;
                  font-size: 30px;
                  background-image: url('#{$IMG_PATH}icon/icn_speaker_k.png');
                }
              }
            }

            li[class="checked"] {
              background: #5FACD9;

              .txt {
                color: #fff;
              }

              .img-speaker {
                background-image: url('#{$IMG_PATH}icon/icn_speaker_w.png');
              }
            }

            li[class="pass"] {
              background: #E9923E;
            }

            li[class="fail"] {
              background: #D0D0D0;
            }
          }

          .start-3-area ul li {
            background: #D0D0D0;

            a {
              pointer-events: none;
            }
          }
        }
      }
    }
  }

  // End css col3
  /*
    @media screen and (max-height: 800px) {
        .col1 {
            min-height: 549px;
            max-height: 549px;
        }
        .col3 {
            min-height: 317px;
            max-height: 317px;
        }
    }

    @media screen and (min-height: 800px) and (max-height: 950px) {
        .col1 {
            min-height: 760px;
            max-height: 760px;
        }
        .col3 {
            min-height: 528px;
            max-height: 528px;
        }
    }

    @media screen and (min-height: 950px) {
    
        .col1 {
            min-height: 700px;
            max-height: 700px;
        }
        .col3 {
            min-height: 468px;
            max-height: 468px;
        }
    }
    */


  @media screen and (max-height: 690px) {
    .col1 {
      max-height: 540px;
      min-height: 540px;

      .content-outer {
        min-height: 260px;
      }

      .main {
        min-height: 430px;
      }
    }

    .col3 {
      max-height: 308px;
      min-height: 308px;
    }
  }

  @media screen and (min-height: 690px) and (max-height: 730px) {
    .col1 {
      max-height: 580px;
      min-height: 580px;

      .content-outer {
        min-height: 280px;
      }
    }

    .col3 {
      max-height: 348px;
      min-height: 348px;
    }
  }

  @media screen and (min-height: 730px) and (max-height: 780px) {
    .col1 {
      max-height: 620px;
      min-height: 620px;

      .content-outer {
        min-height: 320px;
      }
    }

    .col3 {
      max-height: 388px;
      min-height: 388px;
    }
  }

  @media screen and (min-height: 780px) and (max-height: 830px) {
    .col1 {
      max-height: 670px;
      min-height: 670px;

      .content-outer {
        min-height: 370px;
      }
    }

    .col3 {
      max-height: 438px;
      min-height: 438px;
    }
  }

  @media screen and (min-height: 830px) and (max-height: 880px) {
    .col1 {
      max-height: 720px;
      min-height: 720px;

      .content-outer {
        min-height: 420px;
      }
    }

    .col3 {
      max-height: 488px;
      min-height: 488px;
    }
  }

  @media screen and (min-height: 880px) and (max-height: 950px) {
    .col1 {
      max-height: 760px;
      min-height: 760px;

      .content-outer {
        min-height: 460px;
      }
    }

    .col3 {
      max-height: 528px;
      min-height: 528px;
    }
  }

  @media screen and (min-height: 950px) {
    .col1 {
      max-height: 760px;
      min-height: 760px;

      .content-outer {
        min-height: 460px;
      }
    }

    .col3 {
      max-height: 528px;
      min-height: 528px;
    }
  }



  .col1,
  .col2,
  .col3 {
    float: left;
    margin: 1px;
    background: #FFF;
  }

  .btn {
    position: relative;
    width: 200px;
    height: 40px;
    line-height: 40px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    font-size: 16px;
    border-radius: 2px !important;
    font-weight: normal;
    color: #6E6E6E;
  }

  .btn:hover {
    opacity: 0.6;
  }

  .btn-gray {
    border: 2px solid gray;
    background: none !important;
  }

  .btn-organe {
    border: 2px solid #E3760D;
    color: #E3760D !important;
  }

  .btn-timer {
    width: 20% !important;
    height: 70px !important;
    text-align: center !important;
    line-height: 70px !important;
    font-size: 1.2vw !important;
  }

  .btn-next-question {
    border: 1px solid;
    height: 35px;
    line-height: 37px;

    img {
      width: 20px;
      margin-top: 6px;
    }
  }

  .btn-modal {
    width: 155px !important;
  }

  .btn-feedback {
    height: 35px;
    line-height: 35px;
    border: 2px solid #E3760D;
    color: #E3760D !important;
    font-size: 16px;
  }

  .btn-inactive {
    cursor: default;
    border: none;
    background: #6E6E6E !important;
    color: #F6F6F6 !important;
    pointer-events: none;
    display: none;
  }

  .btn-inactive:hover {
    opacity: 1;
  }

  .btn-modal-exit {
    border-radius: 0px;
    top: 5px;
    width: 20px;
    height: 20px;
    background: url("#{$IMG_PATH}icon/icn_close_k.png") center no-repeat;
    background-size: 15px;
  }

  /* CSS POPUP */
  .modal {
    display: none;
    position: fixed;
    z-index: 99999;
    padding-top: 220px;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px 10px;
      border: 1px solid #888;
      width: 415px;

      .main {
        text-align: center;
        font-size: 20px;
        min-height: 113px;

        p {
          margin: 5px 5px;
          color: #6E6E6E;
        }

        span {
          color: #6E6E6E;
          font-size: 25px;
        }
        .icn-remaining-time {
          background-position: 0px center;
        }

        .notAnsweredArea {
          p {
            color: red;
            
            span {
              color: red;
              font-size: inherit;
            }
          }
        }
      }

      .footer {
        margin-top: 15px;
        text-align: center;

        & > a:first-child {
          margin-right: 10px;
        }
      }
    }
  }

  .modal-error {
    display: block;
    opacity: 1;
  }

  .break-test {
    .modal-content {
      width: 511px !important;
      height: 215px !important;
    }
  }

  .feedback-modal {
    padding-top: 70px;

    .modal-content {
      width: 610px;
      height: 455px;
      padding: 5px 10px 10px 10px;

      .main p {
        color: #E3760D;
      }

      .content {
        text-align: left;
        border: 1px solid #888;
        height: 340px;
        width: 95%;
        margin: 0 auto;
        padding: 10px;
        font-size: 16px;

        pre {
          white-space: pre-wrap;
        }
      }

      .modal-btns {
        float: right;
        height: 20px;
        width: 100%;
        text-align: right;
      }
    }
  }

  /* CSS Radio & Checkbox */

  .correct {
    input {
      pointer-events: none;
      cursor: default;
    }

    label {
      pointer-events: none;
      cursor: default;
    }

    label::after {
      border-color: #fff !important;
    }

    label::after {
      background-color: #9CC947 !important;
    }

    input[type="radio"] + label::before {
      background-color: #fff !important;
    }

    input[type="checkbox"] + label::before {
      border: 3px solid #fff !important;
      border-top: none !important;
      border-right: none !important;
    }
  }

  .incorrect {
    input {
      pointer-events: none;
    }

    label {
      pointer-events: none;
    }

    label::after {
      border-color: #fff !important;
    }

    label::after {
      background-color: #EF8976 !important;
    }

    input[type="radio"] + label::before {
      background-color: #fff !important;
    }

    input[type="checkbox"] + label::before {
      border: 3px solid #fff !important;
      border-top: none !important;
      border-right: none !important;
    }
  }

  .sp-only {
    display: none !important;
  }

  .result-bg {
    background: #9CC947 !important;
  }

  .fail-results {
    background: #D0D0D0;
  }

  .result-bg .txt {
    color: #fff !important;
  }

  .icn-remaining-time {
    background-image: url('#{$IMG_PATH}icon/icn_history_k.png');
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 0px center;
    padding-left: 40px;
  }
}

.modal-time-limit {
  .modal-time-limit-inner {
    position: relative;
    top: 20%;
    height: auto;
    width: 60%;
    max-width: 700px;
    padding: 25px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 auto !important;
    background: #fff;
    text-align: center;
    -webkit-box-shadow: 0 0 8px 1px rgba(0,0,0,0.5);
    -moz-box-shadow: 0 0 8px 1px rgba(0,0,0,0.5);
    box-shadow: 0 0 8px 1px rgba(0,0,0,0.5);
    .btn-modal {
      width: 200px;
      height: 32px;
      background: none;
      font-size: 16px;
      font-weight: normal;
      line-height: 32px;
    }
    .btn-modal-cancel {
      color: #a0a0a0;
      border: #a0a0a0 solid 1px;
    }
    .btn-modal-start {
      color: #DC780A;
      border: #DC780A solid 1px;
    }
    .msg-message {
      padding: 10px 0;
      font-size: 16px;
      
      &.message-under {
        padding-bottom: 0;
      }
    }
  }
    
  &.hide {
    display: none;
    opacity: 0;
    z-index: -1;
  }
}
// End css main-container
.m-testAnswerConfirm-modal{
	.m-testAnswerConfirm-inner{
		iframe {
			width: 100vw;
			height: 100vh;
		}
	}
}

.cke_contents_ltr strong {
  font-weight: bold;
}
.cke_contents_ltr em {
  font-style: italic;
}
