@charset "UTF-8";

.page-history-download {

  h1 {
    margin-top: 180px;

    img {
      display: none;
    }
  }

  .page-contents {

    .title-download {
      width: 270px;
      margin-top: 140px;
      text-align: left;
      font-size: 16px;
      border-bottom: 2px solid #0f83c6;
    }

    .page-inner {
      width: 800px;
      margin: auto;
    }

    .page-contents {
      text-align: center;
    }

    .file-name {
      display: block;
      margin-top: 80px;
      text-align: left;
      box-sizing: border-box;
      font-size: 16px;

      .item-title {
        display: inline-block;
      }

      .item-detail {
        display: inline-block;
        width: 370px;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }

      span {
        padding: 0 35px 0 25px;
      }

      .item-detail::before {
        content: ":";
      }

      .download {
        display: inline-block;
      }

      .btn-download {
        width: 190px;
        font-size: 14px;
      }

    }


  }
}
