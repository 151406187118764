@charset "UTF-8";

.page-courses-lesson-detail {

  .page-inner {

    .page-contents {
      width: 100%;
      padding: 20px 30px;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      max-width: 1200px;
    }

    .m-form {
      left: 0;
      width: 840px;

      .form-wrap {

        &:first-child {
          margin-top: 0;
          .form-sub-heading {
          }
        }

        .form-group {
          padding-right: 40px;
        }

        table {
          th:first-child {
            line-height: 1.2;
            width: 25%;
          }
        }
      }

      &.conf-form {
        .form-wrap {

          .form-group {
            padding-right: 0;
          }
        }
      }

    }

  }

}

