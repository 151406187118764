@charset "UTF-8";

.page-reg-ref {
  height: 100%;

  .m-search-c-inner {
    iframe {
      width: 100vw;
      height: 100vh;
    }
  }

  .page-inner {
    .face-ref-header {
      background: #fff;
      position: relative;
      padding-top: 20px;
    }

    background: $background_theme_color_contents;
    height: 100%;

    .page-contents {
      width: 100%;
      height: 100%;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      .m-form-search {
        padding-top: 10px;

        .form-btns {
          display: flex;
          justify-content: center;

          > div {
            margin: 0px;
          }
        }

        .back {
          @include HoverOpacity();
          position: absolute;
          padding: 0px 0 0 20px;
          float: right;
          text-align: center;

          img {
            padding-top: 12px;
            width: 20px;
            height: 20px;
            vertical-align: -webkit-baseline-middle;
          }
        }

        .error {
          margin: 1px 0 1px 0;
        }

        .form-group {

          //margin-top: 60px;
          margin-top: 0px;

          .layout {
            display: flex;
            justify-content: center;
            align-items: flex-start;
          }

          .inner-resize {
            padding: 0px;
            min-width: 890px;
            margin: 0 auto;
            max-width: 1500px;

            .col1 {
              width: 27% !important;

              .div-err {
                .error {
                  padding-left: 92px;
                  padding-bottom: 3px;
                }
              }
              &:last-child {
                .error {
                  padding-bottom: 3px;
                }
              }
            }

            .col2 {
              width: 30% !important;

              .div-err {
                .error {
                  width: 234px;
                  padding-bottom: 3px;
                }
              }
              &:last-child {
                .error {
                  padding-bottom: 3px;
                }
              }
            }

            .col3 {
              /*
              @media screen and (min-width: 1430px) {
                width: 40%;
              }

              @media screen and (min-width: 1280px) and (max-width: 1429px) {
                width: 35%;
              }

              @media screen and (min-width: 1100px) and (max-width: 1279px) {
                width: 40%;
              }
                */

              width: 46% !important;

              &.attend-stt {
                padding-left: 25px;

                &.m-nega {
                  margin-top: -10px;
                  margin-bottom: 10px;
                }

                img {
                  max-width: 30px;
                  min-width: 30px;
                  width: 30px;
                  max-height: 30px;
                  min-height: 30px;
                  height: 30px;
                }

              }

              .status-rd {
                * {
                  height: 20px;
                }

                span {
                  height: 12px;
                }

                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;

                label {
                  padding-left: 24px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  div {
                    margin-right: 5px;
                  }
                }

                .rd-btn {
                  min-width: 100px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;

                  label {
                    padding-left: 10px;

                    &::after {
                      top: 5px;
                      left: 15px;
                      width: 14px !important;
                      height: 14px !important;
                    }

                    &::before {
                      top: 8px;
                      left: 17px;
                      width: 11px !important;
                      height: 5px !important;
                    }
                  }
                }
              }

              .status-chk {
                display: flex;
                justify-content: center;
                align-items: center;

                label {
                  padding-left: 15px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                .checkbox-btn {
                  display: flex;
                  //align-items: center;
                  //justify-content: center;
                  justify-content: left;
                  min-width: 90px;

                  label {
                    padding-left: 20px;

                    &::after {
                      top: 5px;
                      left: 15px;
                      width: 14px !important;
                      height: 14px !important;
                    }

                    &::before {
                      top: 8px;
                      left: 17px;
                      width: 11px !important;
                      height: 5px !important;
                    }
                  }
                }

                // .pseudo-checkbox {
                //   @include pseudo-checkbox-f
                // }

              }

              .note {
                font-size: 12px;
                margin-top: -8px;
                margin-left: 21px;
              }
            }

          }

          //.inner-resize

          .inner {
            padding: 0px;
          }

          .layout {
            margin-bottom: 0px;

            div {
              margin-bottom: 0px;

              * {
                height: 32px;
              }

              .inpt {
                display: flex;
                align-items: center;
                width: 100%;

                .td-input-cal {
                  display: flex;

                  input {
                    max-width: 200px;
                  }

                  a {
                    display: block;
                    padding-left: 5px;
                  }

                  span {
                    padding: 0 5px;
                    line-height: 35px;
                  }

                }

                &.f-end {
                  justify-content: center;
                }

                .title {
                  display: flex;
                  min-width: 85px;
                  justify-content: flex-end;
                  align-items: center;
                  padding-right: 5px;
                  text-align: right;

                  &.size-l {
                    min-width: 150px;

                    &.st-left {
                      justify-content: flex-start;
                      padding-left: 20px;
                      min-width: 98px;
                    }
                  }

                  &.no-width {
                    min-width: 22px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-right: 0px;
                  }

                }

                .c-name {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  display: flex;
                  align-items: center;

                  span {
                    white-space: pre !important;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    padding-top: 10px;
                  }
                }

                .chosen {
                  min-width: 60px;
                  width: 100%;

                  .sel {
                    width: 100%;
                    border: 1px solid #c8c8c8;
                    -moz-border-radius: 0px;
                    border-radius: 0px;

                    p {
                      font-size: inherit;
                      font-weight: normal;
                      margin-top: 0;
                      background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
                      background-size: 12px;
                      line-height: 32px;
                      white-space: nowrap;
                      // text-overflow: ellipsis;
                      overflow: hidden;
                    }

                  }

                  select {
                    width: 100%;
                  }

                  &.catology {
                    width: 35%;
                    // font-size: 10px;

                    // p {
                    //   line-height: normal;
                    // }

                    select {
                      // word-wrap: break-word;      /* IE*/
                      // white-space: -moz-pre-space; /* Firefox */
                      // white-space: pre-wrap;      /* other browsers */
                      display: inline-block;

                    }
                  }
                }

                .txt {
                  width: 100%;

                  &.s-right {
                    padding-right: 20px;
                  }

                }

                .btn-c-search {
                  width: 40px;
                  margin-left: 5px;
                }
              }

              //.inpt

              .div-err {

                height: auto !important;
                min-height: 20px;

                &.size-l {
                  margin-left: 120px !important;
                }

                .error {
                  height: 100% !important;
                  white-space: normal;
                  padding-left: 155px;
                  margin-bottom: 0px !important;

                  &.course-name-err {
                    text-align: center;
                  }

                  &.rate-err {
                    padding-left: 90px !important;
                  }

                }

              }

            }

            //> div

          }

          //.layout

        }

        //.form-group

      }

      //.m-form-search

      input,
      a {
        vertical-align: middle;
      }
    }

    .site-header {
      display: flex;
      padding-bottom: 5px;

      .except {
        height: 71px;
        display: none;
      }

      .download {
        position: absolute;
        right: 0px;
        margin-top: 5px;
        margin-right: 10px;
        min-height: 30px;
        padding-bottom: 5px;
      }

      .result-txt {
        margin-top: 5px;
        margin-left: 10px;
        min-height: 30px;
        display: flex;
        align-items: center;
      }

    }

    .site-result {
      @include search_table_f;
      padding: 0px !important;
      margin-right: 10px;
      margin-left: 10px;
      height: 61%;
      position: relative;

      @media screen and (max-height: 980px) {
        height: 65%;
      }

      @media screen and (max-height: 930px) {
        height: 64%;
      }

      @media screen and (max-height: 900px) {
        height: 62%;
      }

      @media screen and (max-height: 850px) {
        height: 60%;
      }

      @media screen and (max-height: 800px) {
        height: 59%;
      }

      @media screen and (max-height: 780px) {
        height: 57%;
      }

      @media screen and (max-height: 750px) {
        height: 55%;
      }

      @media screen and (max-height: 700px) {
        height: 52%;
      }

      @media screen and (max-height: 650px) {
        height: 48%;
      }

      @media screen and (max-height: 635px) {
        height: 45%;
      }

      @media screen and (max-height: 600px) {
        height: 39%;
      }

      .table-header {
        table-layout: auto;

        .col-user-id {
          min-width: 84px;
          // width: 8%;
        }

        .col-user-name {
          min-width: 84px;
          // width: 8%;
        }

        .col-group-id {
          min-width: 84px;
          // width: 8%;
        }

        .col-group-name {
          min-width: 84px;
          // width: 8%;
        }

        .col-reg-date {
          min-width: 270px;
          // width: 27.9%;
        }

        .col-reg-status {
          min-width: 84px;
          // width: 8%;
        }

        .col-conf-status {
          min-width: 153px;
          // width: 16%;
        }

        .col-cu-responsible {
          min-width: 84px;
          // width: 8%;
        }

        .ucol-btn {
          min-width: 84px;
          // width: 8%;
        }

        tr {
          width: 100%;
          position: fixed;

          th {
            padding: 5px 0;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
          }
        }
      }

      .txt-left {
        text-align: left !important;
        padding-left: 10px !important;
      }

      .table-result-nodata {
        height: 100%;

        tr {
          td {
            border: 0px solid #fafafa;
            color: #AFAFAF;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;

            span {
              position: relative;
            }
          }
        }
      }

      .table-result {
        table-layout: auto;

        .col-site-id {
          min-width: 84px;
          width: 8%;
        }

        .col-site-name {
          min-width: 84px;
          width: 8%;
        }

        .col-contact-name {
          min-width: 84px;
          width: 8%;
        }

        .col-contract-plan {
          min-width: 84px;
          width: 8%;
        }

        .col-capacity {
          min-width: 270px;
          width: 27.9%;
        }

        .col-number-of-users {
          min-width: 84px;
          width: 8%;
        }

        .col-number-of-administrators {
          min-width: 153px;
          width: 16%;
        }

        .col-cu-responsible {
          min-width: 84px;
          width: 8%;
        }

        .ucol-btn {
          max-width: 64px;
          // width: 6%;
        }

        tr {
          td:not(.row-ope) {
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
          }

          .red {
            color: #e7001f;
          }

          td {
            padding: 4px;

            &.col-user-id {
              width: 15%;
            }

            &.col-user-name {
              width: 15%;
            }

            &.col-group-id {
              width: 15%;
            }

            &.col-reg-date {
              min-width: 137px;
            }

            &.col-group-name {
              width: 18%;
            }

            &.col-reg-status {
              width: 8%;
            }

            &.col-reg-date {
              width: 12%;
            }

            &.col-reg-status {
              width: 9%;
            }

            &.col-conf-status {
              width: 11%;
              // width: 6%;
            }

            &.ucol-btn {
              width: 3.4%;
              min-width: 50px;
            }
          }
        }
      }

      table {

        tr:not(:first-child):hover,
        tr.selected,
        .hover {
          .btn-submit-delete {
            background: url(#{$IMG_PATH}icon/icn_trash_w.png) 20px center no-repeat;
            -webkit-background-size: 60%;
            background-size: 60%;
            background-position-x: 50%;
          }

          .btn-submit-setting {
            background: url(#{$IMG_PATH}icon/icn_edit_w.png) 15px center no-repeat;
            -webkit-background-size: 60%;
            background-size: 60%;
            background-position-x: 50%;
          }
        }

        tr.selected,
        .hover {
          .red {
            color: #d10064;
          }
        }

        tr:hover {
          .red {
            color: #d10064;
          }
        }
      }
    }

    .site-list-inner {
      height: 100%;
      background-color: #fff;
    }

    .site-list-outer {
      width: 100%;
      height: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    .scroll-area {
      &.ps-active-y {
        padding-right: 16.2px;
        margin-right: -16.2px;
      }

      .ps-scrollbar-y-rail {
        right: 16.2px;
      }

      height: 100%;
    }

    // .scroll-area
  }

  input.btn,
  a.btn {
    &.btn-submit-download {
      float: right;
      font-weight: initial;
      text-align: center;
      width: auto;
      padding: 6px 19px;
      color: #000;
      border: 1px solid #c8c8c8;
      margin: -3px 0;
      line-height: 22px;
      background: #fff;
      color: #6E6E6E;

      img {
        width: 20px;
        height: 20px;
        padding-right: 7px;
      }
    }

    &.btn-new-site {
      width: 150px !important;
    }

    &.btn-submit-upload {
      background: url(#{$IMG_PATH}icon/icn_upload_w.png) 20px center no-repeat;
      -webkit-background-size: 20px;
      background-size: 20px;
      color: #468cc8;
      border: 1px solid;
      background-color: white;
      font-weight: normal;
      width: auto;
      height: 34px;
      line-height: 34px;
      text-align: center;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 0px;
      font-size: 16px;
      padding: 0;
    }

    &.btn-submit-search-new {
      background: url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
      -webkit-background-size: 20px;
      background-size: 20px;
      background-position-x: 50%;
      color: #468cc8;
      border: 1px solid #82BEF0;
      background-color: #82BEF0;
      font-weight: normal;
      width: 130px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 5px;
      font-size: 16px;
      padding: 0;
    }

    &.btn-submit-setting {
      background: url(#{$IMG_PATH}icon/icn_edit_k.png) 15px center no-repeat;
      -webkit-background-size: 60%;
      background-size: 60%;
      background-position-x: 50%;
      font-weight: normal;
      width: 35px;
      height: 23px;
      line-height: 35px;
      text-align: center;
      font-size: 16px;
      padding: 0;
    }

    &.btn-submit-delete {
      background: url(#{$IMG_PATH}icon/icn_trash_k.png) 20px center no-repeat;
      -webkit-background-size: 80%;
      background-size: 80%;
      background-position-x: 50%;
      font-weight: normal;
      width: 34px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      font-size: 16px;
      padding: 0;
    }

  }
}

.btn-download-modal {
  background-image: url(#{$IMG_PATH}icon/icn_download_o.png);
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px 20px;
  padding-left: 40px;
}
