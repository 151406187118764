@charset "UTF-8";

.modal-reg-ref-eng {

  .m-modal {
    .modal-inner {
      &.c-search {
        top: 2%;
        height: 93vh;
        //max-height: 760px;
        min-height: 645px;
        padding-left: 0px;
        padding-right: 0px;
        width: 520px;
        text-align: left;
        background: #fff;
        z-index: 11000;
        padding: 15px 0;

        .c-search-header {
          display: block;
          margin: 0px 20px 0px 50px;

          .row-user-box {
            display: flex;
            align-items: top;
            padding-bottom: 10px;

            .user-thumb {
              width: 106px;
              height: 80px;
              border: 1px solid #ccc;

              .user-ph {
                display: inline-block;
                width: 100%;
                max-width: 106px;
                height: 100%;
                max-height: 80px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: center no-repeat;
              }

              img {
                max-width: 100%;
                width: 106px;
                height: auto;
              }
            }
            
            .user-name {
              margin-left: 10px;
            }

            .user-thumb,
            .user-name,
            .user-id {
              display: inline-block;
              font-size: 16px;
            }
          }

          .row {
            display: block;
            padding-bottom: 10px;
            font-size: 12px;
            
            .status-rd-ver {
            
            .rd-btn:first-child {
              padding-left: 43px;
              }
            }

            .status-title,
            .status-rd-ver,
            .rd-btn {
              display: inline-block;
              font-size: 12px;
            }
            
            .rd-btn {
              padding-left: 10px;
            }
            
            .rd-btn:last-child {
              padding-left: 50px;
            }

            .status-chk {
              display: inline-block;
            }

            .checkbox-btn:first-child {
              padding-left: 15px;
            }

            .checkbox-btn {
              display: inline-block;
              padding-left: 11px;
            }
          }

        }

        .input-text {
          width: 100%;
          border: 1px solid #c8c8c8;
          padding: 3px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          max-width: 100%;
        }

        .c-btn {
          text-align: center;
          margin-right: 30px;

          .btn-c-search {
            width: 145px !important;
            height: 33px;

            button {
              height: 30px;
            }

          }
        }

      }

      .c-err {
        display: block;
        text-align: center;
        margin-left: 0;

        .error {
          font-size: 12px;
          color: red;
          font-weight: bold;

          &.hide {
            display: none;
          }
        }
      }

      .page-inner {


        .course-result {
            height: 580px !important;
          @media screen and (max-height: 980px) {
            //height: 365px !important;
            height: 580px !important;
          }
          @media screen and (max-height: 920px) {
            //height: 365px !important;
            height: 540px !important;
          }

          @media screen and (max-height: 870px) {
            //height: 320px !important;
            height: 510px !important;
          }

          @media screen and (max-height: 840px) {
            height: 480px !important;
          }
          @media screen and (max-height: 790px) {
            height: 460px !important;
          }
          @media screen and (max-height: 770px) {
            height: 440px !important;
          }
          @media screen and (max-height: 740px) {
            height: 410px !important;
          }
          @media screen and (max-height: 710px) {
            height: 380px !important;
          }
        }

          #loading {
            height: 380px !important;
            span {
              img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }

        &.c-search-modal {
          height: 321px;
          margin: 1px 10px 0px 10px !important;
            
          .no-data {}


          .course-header {
            background: #bae3f9;
          }

          .result-txt {
            margin-top: 5px;
            margin-left: 10px;
            min-height: 25px;
            display: inline-block;
            align-items: center;
          }

          .pulldown-pager {
            margin-top: -1px !important;
          }

          .s-pager {
            top: 0 !important;
              strong{
                  height: 25px;
              }
          }


          .table-result {
            margin-left: 30px;
            .col-course-id {
              width: 30%;
            }

            .row {

              position: relative;

              .col-user-thumb.using_chk::after {
                position: absolute; 
                content: "In use";
                top: 13px;
                left: 7px;
                background: #4285F4;
                padding: 0 10px;
                color: #fff;
              }

              .col-user-thumb.using_chk {

                img {
                  box-sizing: border-box;
                  border: 2px solid #4285F4;
                  padding: 3px;
                  width: 136px;
                }

              }

            }


            .col-course-name {
              width: 70%;
            }

            .col-user-thumb {
              position: relative;
              width: 150px;
              vertical-align: middle;

              .user-thumb-wrap {
                box-sizing: border-box;
                width: 136px;
                height: 104px;
                position: absolute;
                top: 50%;
                left: 50%;
                padding: 3px;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, 11%);
              }

              img {
                box-sizing: border-box;
                position: absolute;
                max-width: 125px;
                width: auto;
                max-height: 95px;
                height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                border: 1px solid #ccc;
              }
            }

          }
        }
      }

      .table-result {

        tr {
          height: 130px;
        }

        .col-user-info {

          display: table-cell;
          padding-left: 10px;
          font-size: 12px;
          vertical-align: middle;

          p {
            padding: 0 0 10px 6px;

            span::before {
              content: "："
            }
          }
        }

        .status-rd-ver {
          display: inline-block;
          margin-top: -6px;

          .rd-btn {
            display: inline-block;
            padding-left: 5px;
          }

          .rd-btn:nth-child(2) {
            padding-left: 20px;
          }

            .btn-box-m {
                display: inline-block;
                text-align: right;
                height: 30px;
                .box-border{
                    border: 1px solid #c8c8c8;
                    position: absolute;
                    border-radius: 5px; 
                    margin-left: 26px;                    
                    padding: 0px 1px;
                    .btn-cerref-l{
                        margin: 0px 0 8px 0px;
                        padding-bottom: 10px;
                        height: 29px;
                        background-size: 32px;
                    }
                    p{
                        font-size: 9px;
                        padding: 20px 0px 0px 0px;
                        color: #a8a8a8;
                        font-weight: 100; 
                    }
                }
            }
//          .btn-box-m {
//            display: inline-block;
//          }

        }

      }

      //table-result


    }
  }
  
  .ps-scrollbar-y-rail {
    right: 0px !important;
  }

}
