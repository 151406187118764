@charset "UTF-8";

.m-breakinfo-modal{

 	 .m-breakinfo-inner {
    iframe {
      width: 100vw;
      height: 100vh;
    }
	
  }
}
.modal-break-information {
	  .m-modal {
	    .modal-inner {
	      &.u-search {
	        padding-left: 0px;
	        padding-right: 0px;
	        width: 700px;
	        text-align: center;
	        background: #fff;
	        z-index: 11000;
	        padding-bottom: 5px;
	        padding-top: 5px;
	        top:5%;

	        .top-area {
	          position: relative;
	          height: 40px;
	          background-color: white;
	          text-align: center;
	          width: 400px;
	          margin: auto;

	          .btn-close-modal {
	            position: absolute;
	            top: 4px;
	            right: -45px;
	            background: url(#{$IMG_PATH}icon/icn_close_k.png) left center no-repeat;
	            background-size: 15px;
	            width: 20px;
	            height: 20px;
	            float: right;
	            cursor: pointer;

	            &:hover {
	              opacity: .7;
	            }
	          }

	          .top-title {
	            color: gray;
	            font-weight: bold;
	            line-height: 30px;
	            font-size: 15px;
	            text-align: center;
	          }
	        }

	        .u-search-header {
	          margin: auto;
	          width: 600px;
	          text-align: left;
	          
	          .breakinfo-error{
	          	margin: 20px auto;
	          	text-align: center;
	          }

	          .description-row {

	            .title-description {
	              margin-bottom: 10px;
	              font-weight: bold;
	              border-bottom: 2px solid gray;
	            }

	            .text-subdescription {
	              margin-bottom: 10px;
	              margin-left: 5px;

	              .title-user,
	              .title-type {
	                margin-right: 1em;
	              }

	              .list-detail:before {
	                content: ":";
	                padding-right: 5px;
	              }
	              .list-title {
	                display:inline-block;
	                width:150px;
	              }

	            }

	          }

	          .description-row:not(:first-child) {
	            margin: 20px 0;
	          }

	         

	
	          table td {
	            padding-right: 10px;
	          }

	        }

	        .error {
	          font-size: 12px;
	          color: red;
	          font-weight: bold;
	          text-align: left;
	        }

	        .page-inner {
	          margin: auto;
	          background: $background_theme_color_contents;
	          width: 590px;
	          height: 100%;

	          &.u-search-modal {
	            height: auto;
	            margin: auto;
	            padding: 5px;
	            margin: 10px auto;

	            .page-contents {
	              width: 100%;
	              height: 100%;

	              .course-header {
	                padding-bottom: 5px;

	              }
	            }

	            .no-data {
	              height: 263px;
	              display: flex;
	              justify-content: center;
	              align-items: center;
	            }

	            .course-result {
	              @include search_table_f;
	              margin: 1px 5px;
	              height: 32px;
	              padding: 0px;
	              background: white;
	              text-align: left;
	              	

		              .header-m-course td {
		                background: #fff !important;
		                color: #82BEF0;
		                vertical-align: middle;
                        text-align: center;
		              }

		              .ps-scrollbar-y-rail {
		                right: 0px;
		                z-index: 6;
		              }

		              thead > tr {
		                z-index: 5;
		              }
		              &.slide-info{
		              	height:auto;
		              	max-height:330px;
		              }

	              }

	              .table-result {
	                table-layout: fixed;
	                border-bottom-width: 0px;
	              }
            
	              .table-header {
	                table-layout: auto;

	                tr {
	                  width: 100%;
	                  
	                  th {
	                    padding: 5px 5px;
	                    box-sizing: border-box;
	                    -webkit-box-sizing: border-box;
	                    -moz-box-sizing: border-box;
	                     text-align: left !important;
	                     line-height: 22px;
	                  }
	                }

	                thead {
	                  .header-sort {
	                    cursor: pointer;

	                    div.col-sort {
	                      display: inline-flex;

	                    }
	                  }
	                }
	              }
	              td{
	              	 text-align: left !important;
	              	 line-height: 22px;
	            
	              }

	              .col-play-time{
	              	width:35%
	              }
	              .col-play-time-second{
	              	width:65%
	              }
	              .col-slide {
	                text-align: left;
	                width: 30%;
	              }
	              .col-contents-time {
	                text-align: left;
	                width: 30%;
	              }
	              .col-display {
	                text-align: left;
	                width: 15%;
	              }
	              .col-viewed-status{
	              	text-align: left;
	              	width: 25%;
	              }
	            }
	          }
	        }
	      }
	    }
	  }
	
 .breakInfo {
 	&:hover {
 		text-decoration: underline;
 	}
 	cursor: pointer;
 }
