@charset "UTF-8";

.page-user-search {

  .page-inner {
    background: $background_theme_color_contents;

    .page-contents {
      width: 100%;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    .course-search-header {
      background: #fff;
      padding: 0 20px;

      .inner {
        min-height: 34px;
        padding: 20px 0;
        position: relative;
        border-bottom: 2px solid #d1d1d1;
        overflow: hidden;

        .back {
          @include HoverOpacity();
          position: absolute;
          left: 0;
        }
        .btn {
          float: right;
          text-align: center;
        }
      }

    }

    .form-group {
      .layout {
        margin: 20px 0 20px -20px;

        & > div {
          width: 33.3333%;
        }
      }
    }

    .course-result {
      @include search_table;
    }

  }

}