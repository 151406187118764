@charset "UTF-8";

.page-index {

  .page-inner {

    .page-contents {
      width: 100%;
      padding: 40px 30px 0;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      max-width: 1200px;

      .index-nav {

        margin: auto;

        ul {
          @include EqualList(4, 20px);

          li {
            text-align: center;

            a {
              width: 100%;
              height: 270px;
              border: 1px solid $theme_color_default;
              display: block;
              @include HoverOpacity();
            }

            .thumb {
              position: relative;
              width: 100%;
              height: 200px;
              background: #fff;
              margin-bottom: 10px;

              img {
                @include AbsoluteCenter();
              }
            }
          }

        }
      }

    }
  }
}