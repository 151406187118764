@charset "UTF-8";
@mixin search_table_f {
    @include design_default_set;
    padding: 20px;
    &.bg {
        background: $background_theme_color_contents;
    }
    table + .sub-heading {
        margin-top: 20px;
    }
    table {
        width: 100%;
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: 2px;
        tr.selected {
            td {
                background: #82bef0 !important;
                z-index: 1;
                color: #fff;
            }
        }
        tr:nth-child(2n) {
            th, td {
                background: #fcfcfc;
            }
        }
        tr:first-child {
            th {
                background: #d1d1d1;
            }
        }
        th,
        td {
            padding: 10px;
            text-align: left;
            vertical-align: middle;
        }
        td {
            background: #fff;
        }
        .row-select {
            width: 50px;
        }
        .row-check {
            width: 50px;
        }
        .row-id {
            width: 136px;
        }
        .row-ope {
            width: 160px;
        }
        .btn-ope-play,
        .btn-ope-revert,
        .btn-ope-edit,
        .btn-ope-clone,
        .btn-ope-delete {
            @include HoverOpacity();
            display: inline-block;
            padding: 3px 8px;
            color: #fff;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            margin: -3px 2px;
            line-height: 22px;
        }
        .btn-ope-play,
        .btn-ope-edit {
            float: left;
            background: #333333;
        }
        .btn-ope-revert,
        .btn-ope-clone {
            float: right;
            background: #b9b9b9;
        }
        .btn-ope-delete {
            background: #e66464;
        }
        .hover{
          background-color: #82BEF0 !important;
          color: #fff;
          cursor:pointer;
        }
    }
    tr:first-child {
        th {
            background: #fff !important;
            color: #82BEF0;
            vertical-align: middle;
            text-align: center;
        }
    }
    table {
        td {
            padding: 5px;
            vertical-align: middle;
            text-align: center;
            word-break: break-all;
            word-wrap: break-word;
        }
        border-collapse: collapse !important;
        td, th {
            border: 1px solid #c8c8c8;
            border-top: 0px solid #fafafa;
        }
        tr{
             td:first-child, th:first-child {
                border-left: 0px solid #fafafa;
             }
             td:last-child,th:last-child {
                border-right: 0px solid #fafafa;
             }
        }
        .row-ope {
            width: 75px !important;
        }
    }
}