@charset "UTF-8";

.m-search-result {

  padding-top: 20px;

  .result-table {
    margin-top: 20px;
    width: 100%;
    table-layout: fixed;

    th, td {
      border: 1px solid #ccc
    }

    thead {
      background: $theme_color_default;

      th {
        color: #fff;
        font-weight: bold;
        text-align: center;
        font-size: 14px;
        padding: 8px;
      }
    }

    tbody {
      &:nth-child(odd){
        background: #eee;
      }
    }

    td {
      position: relative;
      padding: 5px 30px 5px 5px;
      font-size: 12px;

      .pseudo-checkbox {
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }

  }

  .under-table {
    margin-top: 20px;
    width: 100%;
    table-layout: fixed;

    div {
      margin-bottom: 5px;
    }
  }

}

