@charset "UTF-8";

.page-password-reset-user-creation {

  .page-inner {
    padding-top: 50px;
    width: 500px;
    margin: auto;

    .page-contents {
      text-align: center;

      .logo {
        margin-bottom: 20px;
      }
      .title-pass-reset{
        p{
          margin-bottom: 20px;
        }
      }
      .btn {
        font-size: 18px;
      }
      .form-new-pass{
        margin: 0;
      }
      .form-btns {
        margin-bottom: 30px;
        .change-pass{
          margin-bottom: 20px;
        }
      }

    }
  }
}