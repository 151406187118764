@charset "UTF-8";
.import {

 .m-modal {
    .modal-inner {
      &.uploading{
        width: 450px;
        height: 200px;
        #jsModalClose {
          display: none;
        }
        .csvUploadLoading{
            position: relative;
           margin-top: 17px;
          
        }
        .busyText{
          display: inline-block;
          line-height: 125px;
          font-size: 18px;
          margin-left: 10px;
        }
        }
      
      }

      }
       
        }
          

