@charset "UTF-8";

.page-group-search {

  .site-flow {
    padding-top: 20px;
  }

  .page-inner {
    background: $background_theme_color_contents;

    .page-contents {
      width: 100%;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    .sub-heading {
      font-weight: bold;
      font-size: 16px;
      color: $font_color_theme;
      margin-bottom: 5px;
      margin-left: 2px;
    }

    .course-search-header {
      background: #fff;
      padding: 0 20px;

      .inner {
        padding: 20px 0;
        position: relative;
        border-bottom: 2px solid #d1d1d1;
        overflow: hidden;
        min-height: 60px;
        margin-top: -70px;

        .back {
          @include HoverOpacity();
          position: absolute;
          left: 0;
        }
        .btn {
          float: right;
          text-align: center;
        }
        
      }
      
    }

    .selected-area {
      padding: 20px 20px 0;
      background: #fff;

      .selected-area-inner {
        padding: 20px;
        background: $background_theme_color_light;
        .course-result {
          padding: 0;
        }
      }

    }

    .form-group {
      .form-row {
        margin: 20px 0;
      }
    }

    .course-result {
      @include search_table;

      & + .ui-btns {
        margin-top: 0px;
        margin-bottom: 20px;
      }
    }

    .conf-result {
      @include search_table;
      background: #e6e6e6;
      
      .sub-heading {
        color: #000;
      }

    }

  }

  .m-form-search {

    .start, .end {
      position: relative;

      .btn-cal {
        position: absolute;
        top: 26px;
      }
    }

    .start {
      padding-right: 55px;

      span {
        position: absolute;
        right: -5px;
        top: 31px;
      }

      .btn-cal {
        right: 20px;
      }

    }

    .end {
      padding-left: 15px!important;
      padding-right: 40px;

      .btn-cal {
        right: 5px;
      }
    }

  }

}