@charset "UTF-8";

.c-courses-summary-header {
  background: #fff;

  .c-courses-summary-header-inner {
    position: relative;
    padding-top: 20px;

    .courses-summary-header-layout {
      height: 100%;
      min-height: 1px;

      .m-form-search {
        width: 100%;

        .form-group.back-group-btn {
          margin-top: -20px;
          margin-bottom: 0px;

          input.btn-back-icon {
            margin-left: -15px;
            background: url(#{$IMG_PATH}icon/icn_back_k.png) 10px center no-repeat !important;
            background-size: 22px !important;
            float: left;
          }

          .copyNotify {
            display: none;
            margin-left: 58%;
            width: 400px;
            height: 32px;
            text-align: center;
            background-color: #82BEF0;
            color: white;
            vertical-align: middle;
            border-radius: 3px;
            span {
              position: relative;
              top: 6px;
            }
            .close {
              position: relative;
              top: -2px;
              right: -15px;
              line-height: 10px;
              padding: 0;
              cursor: pointer;
              background: transparent;
              border: 0;
              color: white;
              -webkit-appearance: none;
            }
          }
        }

        .form-group {
          height: 45px;
          &.stform-group {
            height: 40px;
          }
          .layout {
            display: flex;
            justify-content: center;
            align-items: flex-start;
          }
          .inner-resize {
            padding: 0px;
            min-width: 890px;
            margin: 0 auto;
            max-width: 1300px;
            * {
              height: 32px;
            }

            .inpt {
              display: flex;
              align-items: center;
              width: 100%;
              .title {
                display: flex;
                min-width: 115px;
                padding-right: 5px;
                justify-content: flex-start;
                align-items: center;
              }
            }

            .f-pane {
              min-width: 20%;
              max-width: 30%;
              display: flex;
              flex-direction: row;
              .btn-mail-address {
                background: url(#{$IMG_PATH}icon/icn_mailsend_bk.png) 10px center no-repeat !important;
                background-size: 26px 28px !important;
                background-position: center;
                width: 44px;
                height: 34px;
                position: relative;
                top: -7px;
                border: 1px solid #c8c8c8;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;
              }
              .title {
                white-space: pre !important;
                display: flex;
                min-width: 115px;
                padding-right: 5px;
                justify-content: flex-end;
              }
              .lbl {
                //white-space: pre !important;
                display: flex;
                min-width: 98px;
                padding-right: 5px;
                justify-content: flex-start;
                text-overflow: ellipsis;
                overflow: hidden;
                &.mail-address {
                  text-overflow: unset;
                  overflow: unset;
                  a {
                    cursor: pointer;
                  }
                }
              }
            }
            .s-pane {
              min-width: 590px;
              width: 50%;
              height: 50px;
              display: flex;
              align-items: center;
  
              .col3 {
                .status-chk {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                  .checkbox-btn {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    &.hide {
                      display: none;
                    }
  
                    label {
                      padding-left: 17px;
                      &::after {
                        top: 6px;
                        left: 15px;
                        width: 14px !important;
                        height: 14px !important;
                      }
                      &::before {
                        top: 9px;
                        left: 17px;
                        width: 11px !important;
                        height: 5px !important;
                      }
                    }
                  }
  
                  
                }
  
                .note {
                  font-size: 11px;
                  margin-top: -10px;
                }
              }
            }
            .c-pane {
              min-width: 322px;
              width: 30%;
              height: 50px;
              display: flex;
              align-items: center;
              .col1 {
                .status-chk {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                  .checkbox-btn {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
  
                    label {
                      padding-left: 17px;
                      &::after {
                        top: 5px;
                        left: 15px;
                        width: 14px !important;
                        height: 14px !important;
                      }
                      &::before {
                        top: 8px;
                        left: 17px;
                        width: 11px !important;
                        height: 5px !important;
                      }
                    }
                  }
  
                  
                }
  
                .note {
                  font-size: 11px;
                  margin-top: -10px;
                }
              }
            }
          }
        }
        .form-btns {
          display: flex;
          justify-content: center;

          > div {
            margin: 0px;
          }

          margin-left: -50px;
          margin-top: 25px;
        }
      }
    }
  }
}