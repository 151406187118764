@charset "UTF-8";
.page-user-group-new {
    .page-inner {
       
        .page-contents {
            width: 100%;
            margin: auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
             .m-form-search {
                 border-bottom: 1px solid #6e6e6e;
                 p {
                        font-size: 12px;
                        font-weight: normal !important ;
                        margin-top: 1px;
                        color: red;
                  }   
                .form-group {
                    .inner {
                        width: 80%;
                        table{
                            width: 100%;
                            tr {
                                    height: 69px;
                                    input:not([type="checkbox"]){
                                      height: 39px;
                                    }
                                    label[for="portal_flag"]{
                                      line-height: 18px;
                                      display: flex;
                                    }
                                    .pseudo-checkbox {
                                      @include pseudo-checkbox-f
                                    }
                                }
                               
                               
                            }
                           
                            }
                            .td-1{
                                text-align: right;
                                padding-right: 5px;
                                 width: 40%;
                                  span{
                                    
                                    color: red;
                                    padding-top: 1px;
                                    position: relative;
                                    top: -12px;
                                }
                            }
                            .td-5{
                                text-align: center;
                            }
                            .input-text{
                                width: 50%;
                            }
                             .tr-1{
                                height: 20px !important;
                                
                             }
                             .td-button{
                                 text-align: right !important;
                                 
                             }
                    }
                }
                .form-btns{
                    padding-top: 20px;
                    display: flex;
                    justify-content: center;
                }
            }
        }
        .course-search-header {
            background: #fff;
            padding: 0 20px;
            .inner {
                min-height: 34px;
                padding: 20px 0;
                position: relative;
                // border-bottom: 2px solid #d1d1d1;
                overflow: hidden;
                .back {
                    @include HoverOpacity();
                    img {
                        width: 20px;
                        height: 20px;
                        vertical-align: -webkit-baseline-middle;
                    }
                    
                }
                span {
                    float: right;
                    
                    color: red;
                }
                
            }
        }
      
        
    }
    input,a{
        &.btn-new-user-group {
            background: url(#{$IMG_PATH}icon/icn_download_w.png) 20px center no-repeat;
            -webkit-background-size: 20px;
            background-size: 20px;
            color: #dc780a;
            border: 1px solid;
            font-weight: normal;
            width: 150px;
            height: 39px;
            line-height: 39px;
            text-align: center;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 2px;
            border-color: #dc780a;
            background-color: #fff;
            font-size: 16px;
            padding: 0 ;
            
        }
        &.btn-back-new {
            background: url(#{$IMG_PATH}icon/icn_download_w.png) 20px center no-repeat;
            -webkit-background-size: 20px;
            background-size: 20px;
            color: #dc780a;
            border: 1px solid;
            font-weight: normal;
            width: 150px;
            height: 39px;
            line-height: 39px;
            text-align: center;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 2px;
            color: #a0a0a0;
            background-color: #fff;
            font-size: 16px;
            padding: 0 ;
            
        }
        &.btn-new-parent {
           
            -webkit-background-size: 20px;
            background-size: 20px;
            background-position-x:50% ;
            color: #a0a0a0;
            border: 1px solid;
            background-color: #fff;
            font-weight: normal;
            width: 100px;
            height: 39px;
            line-height: 34px;
            text-align: center;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 2px;
            font-size: 16px;
            padding: 0;
            float: right;
            margin-right: 50%;
        }
         
       
    }

/* CSS POPUP */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 99999; /* Sit on top */
    padding-top: 280px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 40px 20px;
    border: 1px solid #888;
    width: 20%;
    min-width: 450px;
    min-height: 150px;
}

.modal-content .main {
    text-align: center;
    font-size: 20px;
}

.modal-content .main p {
    margin: 5px 5px;
}

.modal-content .main img {
    max-width: 20px;
    height: 20px;
}

.modal-content .main span {
    font-size: 25px;
}

.modal-content .footer {
    margin-top: 15px;
    text-align: center;
}

.modal-content .footer > a:first-child {
    margin-right: 10px;
}

/* CCM036 */
#select-tree-modal .custom-inner_modal {
  width: 80% !important;
  height: 80%;
  min-height: 600px !important;
  margin: 0 0 0 0 !important;
  padding: 50px;
  color: black;
  font: 12px Verdana, sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
}

#select-tree-modal .custom-inner_modal *,
#select-tree-modal .custom-inner_modal *:before,
#select-tree-modal .custom-inner_modal *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


#select-tree-modal .tree-wrapper {
  position: relative;
  width: 100%;
  height: 85%;
  margin-top: 20px;
}

#select-tree-modal .site-root{
  height : 90px;
}

#select-tree-modal .site-root .site-name{
  height: 20px;
}

#select-tree-modal .site-root .site-line{
  height: 58px;
  border-left: 1px solid #A0A0A0; 
  left : 110px;
  margin-top: 12px;
  position: absolute;
}

#select-tree-modal .site-root span{
  display: block;
  min-width: 170px;
  padding: 5px 10px;
  line-height: 20px;
  text-align: left;
  border: 1px solid #82BEF0;
  border-radius: 5px;
  position: absolute;
  left: 20px;
  color: #82BEF0;
}

#select-tree-modal .branch {
  position: relative;
  margin-left: 190px;
}

#select-tree-modal .branch.branch-child {
  margin-left: 250px !important;
}

#select-tree-modal .branch:before {
  content: "";
  width: 40px;
  border-top: 1px solid #A0A0A0;
  position: absolute;
  left: -80px;
}

#select-tree-modal .entry {
  position: relative;
  min-height: 60px;
}


#select-tree-modal .entry:before {
  content: "";
  height: 100%;
  border-left: 1px solid #A0A0A0;
  position: absolute;
  left: -40px;
}

#select-tree-modal .entry:after {
  content: "";
  width: 40px;
  position: absolute;
  left: -40px;
}

#select-tree-modal .entry:first-child:before {
  width: 10px;
}

#select-tree-modal .entry:first-child:after {
  height: 10px;
}

#select-tree-modal .entry:last-child:before {
  width: 10px;
  height: 0px !important;
}

#select-tree-modal .entry:last-child:after {
  height: 10px;
  border-top: none;
}

#select-tree-modal .label:before {
  content: "";
  width: 40px;
  border-top: 1px solid #A0A0A0;
  position: absolute;
  margin-top: 14px;
  left: -41px;
}

#select-tree-modal .togglechild {
  float: right;
  width: 10px;
  height: 10px;
  cursor: pointer;
}

#select-tree-modal .border-input-round{
  padding: 5px 10px;
  text-align: center;
  border: 1px solid;
  border-radius: 2px;
  background-color: white;
}

#select-tree-modal .border-input-color-black{
  border-color: grey;
  line-height: 22px;
  width: 125px;
  margin-top: 10px;
}

#select-tree-modal .border-input-color-black:hover{
  opacity: 0.7;
}

#select-tree-modal .border-input-color-orange{
  border-color: $font_color_theme_light;
  color: $font_color_theme_light;
  width: 175px;
  line-height: 29px;
  margin-top: 10px;
}

#select-tree-modal .border-input-color-orange:hover{
  opacity: 0.7;
}

#select-tree-modal .button-wrapper{
    text-align: left !important;
}

#select-tree-modal .setting-button{
  text-align: center;
  margin-bottom: 10px;
}

/* None Click*/
#select-tree-modal .label {
  display: block;
  min-width: 170px;
  padding-top: 0px;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-left: 5px;
  line-height: 30px;
  text-align: left;
  border: 1px solid #82BEF0;
  border-radius: 5px;
  position: absolute;
  left: 0;
  margin-top: -15px;
}

#select-tree-modal .label-name {
  float : left;
  overflow: hidden;
  text-overflow: ellipsis; 
  white-space: nowrap; 
  width: 135px;
  cursor: pointer;
  color: #82BEF0;
}

#select-tree-modal .togglechild .expand{
  display:inline-block;
  background-image: url(#{$IMG_PATH}icon/icn_triangle_b_k.png);
  width: 10px;
  height: 10px;
  background-size: cover;
}

#select-tree-modal .togglechild .collapse{
  display:inline-block;
  background-image: url(#{$IMG_PATH}icon/icn_triangle_r_k.png);
  background-size: cover;
  width: 10px;
  height: 10px;
}

/* Disabled */
#select-tree-modal .no-parent-disabled {
  color: #DA7826 !important;
  background-color: white !important;
  border-color:  #DA7826 !important;
}

#select-tree-modal .label-disabled {
  background-color: white !important;
  border-color:  #DA7826 !important;
}

#select-tree-modal .label-name-disabled{
  color: #DA7826 !important;
}

#select-tree-modal .togglechild .expand-disabled{
  background-image: url(#{$IMG_PATH}icon/icn_triangle_b_o.png) !important;
}

#select-tree-modal .togglechild .collapse-disabled{
  background-image: url(#{$IMG_PATH}icon/icn_triangle_r_o.png) !important;
}

/* Clicked */
#select-tree-modal .no-parent-clicked {
  color: white !important;
  background-color: #82BEF0 !important;
  border-color: #82BEF0 !important;
}

#select-tree-modal .label-clicked {
  background-color: #82BEF0 !important;
  border-color: #82BEF0 !important;
}

#select-tree-modal .label-name-clicked{
  color: white !important;
}

#select-tree-modal .togglechild .expand-clicked{
  background-image: url(#{$IMG_PATH}icon/icn_triangle_b_w.png) !important;
}

#select-tree-modal .togglechild .collapse-clicked{
  background-image: url(#{$IMG_PATH}icon/icn_triangle_r_w.png) !important;
}

/* Will be chosen */
#select-tree-modal .no-parent-chosen {
  color: white !important;
  background-color: #DA7826 !important;
  border-color: #DA7826 !important;
}

#select-tree-modal .label-chosen {
  background-color: #DA7826 !important;
  border-color: #DA7826 !important;
}

#select-tree-modal .label-name-chosen{
  color: white !important;
}

#select-tree-modal .togglechild .expand-chosen{
  background-image: url(#{$IMG_PATH}icon/icn_triangle_b_w.png) !important;
}

#select-tree-modal .togglechild .collapse-chosen{
  background-image: url(#{$IMG_PATH}icon/icn_triangle_r_w.png) !important;
}

/* Grayout */
#select-tree-modal .no-parent-grayout {
  color: white !important;
  background-color: grey !important;
  border-color: grey !important;
}

#select-tree-modal .label-grayout {
  background-color: grey !important;
  border-color: grey !important;
}

#select-tree-modal .label-name-grayout{
  color: white !important;
}

#select-tree-modal .togglechild .expand-grayout{
  background-image: url(#{$IMG_PATH}icon/icn_triangle_b_w.png) !important;
}

#select-tree-modal .togglechild .collapse-grayout{
  background-image: url(#{$IMG_PATH}icon/icn_triangle_r_w.png) !important;
}


#select-tree-modal .modal-import-list-outer{
    top: 191px;
    width: 100%;
    height: 85%;
    border: 1px solid lightgrey;
}

#select-tree-modal .scroll-area {
    .ps-scrollbar-x-rail{
        bottom: 0px;
        margin-right: 7px;
        height: 7px !important;
        opacity: .4 !important;
    }
    .ps-scrollbar-y-rail{
        right: 0px;
        margin-bottom: 7px;
        opacity: .4 !important;
    }
    .ps-scrollbar-x{
        height: 7px !important;
        bottom: 0px !important;
    }
    .ps-scrollbar-y{
        right: 0px !important;
        width: 7px !important;
    }
    height: 100%;
    width: 100%;
} 

#select-tree-modal .modal-import-list-outer:hover .ps-scrollbar-x,
#select-tree-modal .modal-import-list-outer:hover .ps-scrollbar-y{
  position: absolute;
  background-color: black;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
  -moz-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
  -o-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -webkit-border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
}

