@charset "UTF-8";

/*
#overview
pagerスタイル

*/

/*
#styleguide
pager

```

```
*/
.disabled-pager {
  position: relative;
  text-align: right;

  .btn {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  a, strong {
    margin-left: 5px;
    display: inline-block;
    padding: 0 10px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    color: #fff;
  }

  a {
    @include HoverOpacity();
    background: #bbb;
  }

  strong {
    background: $theme_color_default;
    color: #fff;
  }


}

.s-pager {
  display: inline;
  position: relative;

  top: -1px;

  .btn {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  strong {
    position: relative;
    top: 1px;

    @media screen and (-webkit-min-device-pixel-ratio:0) {
      position: inherit;
      top: 0.5px;
    }
  }
  

  a, strong {
    margin-left: -3px;
    display: inline-block;
    padding: 0 8px;

    height: 26px;
    line-height: 26px;

    @media screen and (-webkit-min-device-pixel-ratio:0) {
      height: 26px;
      line-height: 27px;
    }

    text-align: center;
    color: #333;
    border-radius: 5px;
    font-size: 15px;
  }

  a {
    &.prev, &.next {
      @include HoverOpacity();
    }

    &.number:hover, &.first:hover, &.last:hover {
        background-color: #777;
        text-decoration: none !important;
        color: #fff;
    }
  }

  strong {
    background: #4285F4;
    color: #fff;
  }

  .prev {
    background-image: url(#{$IMG_PATH}icon/icn_arrowleft_k.png);
    background-position: center;
    background-position-y: 6px;
    background-repeat: no-repeat;
    background-size: 150% 50%;

    &:hover {
      background-image: url(#{$IMG_PATH}icon/icn_triangle_l_k.png);
      background-position: center;
      background-position-y: 6px;
      background-repeat: no-repeat;
      background-size: 90% 50%;
    }
  }

  .next {
    background-image: url(#{$IMG_PATH}icon/icn_arrowright_k.png);
    background-position: center;
    background-position-y: 6px;
    background-repeat: no-repeat;
    background-size: 150% 50%;

    &:hover {
      background-image: url(#{$IMG_PATH}icon/icn_triangle_r_k.png);
      background-position: center;
      background-position-y: 6px;
      background-repeat: no-repeat;
      background-size: 90% 50%;
    }
  }

  .dot {
    margin-left:-4px;
  }

}

.pulldown-pager {
  display: inline-block;
  margin-left: 5px;
  margin-right: 10px;
  margin-top: -5px;
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    margin-top: -3px;
  }
  color: #888;
  vertical-align: middle;

  .sel {
    font-size: 12px;
  }

  .sel-size-mini-custom {
    border-radius: 5px;
    height: 20px;
    width: 112px;

    .sel-txt {
      padding-top: 1px;
      padding-left: 8px;
    }
  }
}