@charset "UTF-8";

@mixin design_default_set {

  .section {
    margin-bottom: 40px;

    & > * {
      margin: 20px 0;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .layout {
    @include ClearFix;

    & > div {
      float: left; // default float
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      &.left {
        float: left;
      }

      &.right {
        float: right;
      }

    }

  } // .layout

  .layout-table {
    width: 100%;
    display: table;
    table-layout: fixed;

    & > div {
      display: table-cell;
    }
  }

  // box
  .box {
    border: 1px solid $border_color_default;
    padding: 20px;

    .box {
      padding: 15px;
    }

    & > * {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.radius {
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }
    &.bg {
      background: #efefef;
    }

    &.shadow {
      -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, .3);
      -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, .3);
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, .3);
    }
    &.in-shadow {
      -webkit-box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, .3);
      -moz-box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, .3);
      box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, .3);
    }
  }

  pre {
    word-break: break-all;
    white-space: pre-line;
  }

  // list
  .lst-check {
    padding-left: 18px;
    & > li {
      position: relative;
      margin-bottom: 10px;
    }
    & > li:after {
      display: block;
      content: '';
      position: absolute;
      top: .5em;
      left: -1em;
      width: 8px;
      height: 3px;
      border-left: 2px solid $theme_color_default;
      border-bottom: 2px solid $theme_color_default;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }

  .lst-decimal {
    padding-left: 18px;
    list-style: none;
    counter-reset: ol_li;
    & > li {
      position: relative;
      margin-bottom: 10px;
    }
    & > li:after {
      position: absolute;
      left: -.5em;
      margin: 0 0.5em 0 -0.5em;
      counter-increment: ol_li;
      content: counter(ol_li);
      color: $theme_color_default;
      font-weight: bold;
    }
  }

  .lst-disc {
    padding-left: 18px;
    & > li {
      list-style-type: disc;
      margin-bottom: 10px;
    }
    & > li:after {
      display: none;
    }
  }

  // img default
  img {
    max-width: 100%;
  }

  // 装飾
  .bb {
    border-bottom: 2px solid $theme_color_default;
    &.double {
      position: relative;
      padding-bottom: .5em;
      border-bottom: 4px solid #ccc;
      &:after {
        position: absolute;
        bottom: -4px;
        left: 0;
        z-index: 2;
        content: '';
        width: 20%;
        height: 4px;
        background-color: $theme_color_default;
      }
    }
  }
  .bt {
    border-top: 2px solid $theme_color_default;
    &.double {
      position: relative;
      padding-top: .5em;
      border-top: 4px solid #ccc;
      &:before {
        position: absolute;
        top: -4px;
        left: 0;
        z-index: 2;
        content: '';
        width: 20%;
        height: 4px;
        background-color: $theme_color_default;
      }
    }
  }
  .bl {
    padding: .25em 0 .25em .75em;
    border-left: 6px solid $theme_color_default;
  }
  .bbl {
    position: relative;
    padding: .25em 0 .5em .75em;
    border-left: 6px solid $theme_color_default;
    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';

      width: 100%;
      height: 0;
      border-bottom: 1px solid $theme_color_default;
    }
  }

  .heading-bg {
    padding: .5em .75em;
    background-color: #f6f6f6;
  }

  .marker {
    position: relative;
    &:after{
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      content: " ";
      width: 100%;
      height: 10px;
      background-color: #b6f0fc;
    }
  }

  sup {
    font-size: 80%;
    font-weight: normal;
    vertical-align: super;
  }

  // gird system
  .col-1 { width: unquote((100/12*1)+"%"); }
  .col-1_5 { width: unquote((100/12*1.5)+"%"); }
  .col-2 { width: unquote((100/12*2)+"%"); }
  .col-2_5 { width: unquote((100/12*2.5)+"%"); }
  .col-3 { width: unquote((100/12*3)+"%"); }
  .col-4 { width: unquote((100/12*4)+"%"); }
  .col-5 { width: unquote((100/12*5)+"%"); }
  .col-6 { width: unquote((100/12*6)+"%"); }
  .col-7 { width: unquote((100/12*7)+"%"); }
  .col-7_5 { width: unquote((100/12*7.5)+"%"); }
  .col-8 { width: unquote((100/12*8)+"%"); }
  .col-9 { width: unquote((100/12*9)+"%"); }
  .col-10 { width: unquote((100/12*10)+"%"); }
  .col-11 { width: unquote((100/12*11)+"%"); }
  .col-12 { width: unquote((100/12*12)+"%"); }

  // font style
  .fz-xs { font-size: 11px;}
  .fz-s { font-size: 12px; }
  .fz-m { font-size: $font_size_m; }
  .fz-l { font-size: $font_size_l; }
  .fw-b { font-weight: bold; }
  .fs-i{ font-style: italic }
  .ta-c { text-align: center; }
  .ta-r { text-align: right; }
  .ta-l { text-align: left; }
  .va-t { vertical-align: top; }
  .va-m { vertical-align: middle; }
  .va-b { vertical-align: bottom; }

  .footnote { @extend .fz-xs; }

  // color
  .fc-lightpink { color: #FFB6C1; } /* ライト・ピンク */
  .fc-pink { color: #FFC0CB; } /* ピンク */
  .fc-crimson { color: #DC143C; } /* クリムゾン */
  .fc-palevioletred { color: #DB7093; } /* ペール・バイオレット・レッド */
  .fc-lavenderblush { color: #FFF0F5; } /* ラベンダー･ブラシ */
  .fc-hotpink { color: #FF69B4; } /* ホット・ピンク */
  .fc-deeppink { color: #FF1493; } /* ディープ・ピンク */
  .fc-mediumvioletred { color: #C71585; } /* ミディアム・バイオレット・レッド */
  .fc-orchid { color: #DA70D6; } /* オーキッド */
  .fc-magenta { color: #FF00FF; } /* マゼンタ */
  .fc-fuchsia { color: #FF00FF; } /* フクシャ */
  .fc-darkmagenta { color: #8B008B; } /* ダーク・マゼンタ */
  .fc-purple { color: #800080; } /* パープル */
  .fc-violet { color: #EE82EE; } /* バイオレット */
  .fc-plum { color: #DDA0DD; } /* プラム */
  .fc-thistle { color: #D8BFD8; } /* シスル */
  .fc-mediumorchid { color: #BA55D3; } /* ミディアム・オーキッド */
  .fc-darkviolet { color: #9400D3; } /* ダーク・バイオレット */
  .fc-darkorchid { color: #9932CC; } /* ダーク・オーキッド */
  .fc-indigo { color: #4B0082; } /* インディゴ */
  .fc-blueviolet { color: #8A2BE2; } /* ブルー・バイオレット */
  .fc-mediumpurple { color: #9370DB; } /* ミディアム・パープル */
  .fc-mediumslateblue { color: #7B68EE; } /* ミディアム・スレート・ブルー */
  .fc-slateblue { color: #6A5ACD; } /* スレート・ブルー */
  .fc-darkslateblue { color: #483D8B; } /* ダーク・スレート・ブルー */
  .fc-blue { color: #0000FF; } /* ブルー */
  .fc-mediumblue { color: #0000CD; } /* ミディアム・ブルー */
  .fc-darkblue { color: #00008B; } /* ダーク・ブルー */
  .fc-navy { color: #000080; } /* ネイビー */
  .fc-midnightblue { color: #191970; } /* ミッドナイト・ブルー */
  .fc-lavender { color: #E6E6FA; } /* ラベンダー */
  .fc-ghostwhite { color: #F8F8FF; } /* ゴースト・ホワイト */
  .fc-royalblue { color: #4169E1; } /* ロイヤル・ブルー */
  .fc-cornflowerblue { color: #6495ED; } /* コーン・フラワー・ブルー */
  .fc-lightsteelblue { color: #B0C4DE; } /* ライト・スティール・ブルー */
  .fc-lightslategray { color: #778899; } /* ライト・スレートグレイ */
  .fc-slategray { color: #708090; } /* スレート・グレイ */
  .fc-dodgerblue { color: #1E90FF; } /* ドジャー・ブルー */
  .fc-aliceblue { color: #F0F8FF; } /* アリスブルー */
  .fc-steelblue { color: #4682B4; } /* スティール・ブルー */
  .fc-lightskyblue { color: #87CEFA; } /* ライト・スカイブルー */
  .fc-skyblue { color: #87CEEB; } /* スカイブルー */
  .fc-deepskyblue { color: #00BFFF; } /* ディープ・スカイブルー */
  .fc-lightblue { color: #ADD8E6; } /* ライト・ブルー */
  .fc-powderblue { color: #B0E0E6; } /* パウダー・ブルー */
  .fc-cadetblue { color: #5F9EA0; } /* カデット・ブルー */
  .fc-cyan { color: #00FFFF; } /* シアン */
  .fc-aqua { color: #00FFFF; } /* アクア */
  .fc-darkturquoise { color: #00CED1; } /* ダーク・ターコイズ */
  .fc-darkcyan { color: #008B8B; } /* ダーク・シアン */
  .fc-teal { color: #008080; } /* ティール */
  .fc-darkslategray { color: #2F4F4F; } /* ダーク･スレート･グレイ */
  .fc-paleturquoise { color: #AFEEEE; } /* ペール・ターコイズ */
  .fc-lightcyan { color: #E0FFFF; } /* ライト・シアン */
  .fc-azure { color: #F0FFFF; } /* アズール */
  .fc-mediumturquoise { color: #48D1CC; } /* ミディアム・ターコイズ */
  .fc-lightseagreen { color: #20B2AA; } /* ライト・シーグリーン */
  .fc-turquoise { color: #40E0D0; } /* ターコイズ */
  .fc-aquamarine { color: #7FFFD4; } /* アクアマリン */
  .fc-mediumaquamarine { color: #66CDAA; } /* ミディアム・アクアマリン */
  .fc-mediumspringgreen { color: #00FA9A; } /* ミディアム・スプリンググリーン */
  .fc-springgreen { color: #00FF7F; } /* スプリンググリーン */
  .fc-mintcream { color: #F5FFFA; } /* ミントクリーム */
  .fc-mediumseagreen { color: #3CB371; } /* ミディアム・シーグリーン */
  .fc-seagreen { color: #2E8B57; } /* シーグリーン */
  .fc-lime { color: #00FF00; } /* ライム */
  .fc-green { color: #008000; } /* グリーン */
  .fc-forestgreen { color: #228B22; } /* フォレスト・グリーン */
  .fc-palegreen { color: #98FB98; } /* ペール・グリーン */
  .fc-lightgreen { color: #90EE90; } /* ライト・グリーン */
  .fc-darkseagreen { color: #8FBC8F; } /* ダーク・シーグリーン */
  .fc-honeydew { color: #F0FFF0; } /* ハニーデュー */
  .fc-darkgreen { color: #006400; } /* ダーク・グリーン */
  .fc-limegreen { color: #32CD32; } /* ライムグリーン */
  .fc-chartreuse { color: #7FFF00; } /* シャルトリューズ */
  .fc-lawngreen { color: #7CFC00; } /* ローン・グリーン */
  .fc-greenyellow { color: #ADFF2F; } /* グリーン・イエロー */
  .fc-darkolivegreen { color: #556B2F; } /* ダーク・オリーブグリーン */
  .fc-yellowgreen { color: #9ACD32; } /* イエロー・グリーン */
  .fc-olivedrab { color: #6B8E23; } /* オリーブドラブ */
  .fc-lightgoldenrodyellow { color: #FAFAD2; } /* ライト･ゴールデンロッド･イエロー */
  .fc-yellow { color: #FFFF00; } /* イエロー */
  .fc-olive { color: #808000; } /* オリーブ */
  .fc-lightyellow { color: #FFFFE0; } /* ライト・イエロー */
  .fc-beige { color: #F5F5DC; } /* ベージュ */
  .fc-ivory { color: #FFFFF0; } /* アイボリー */
  .fc-darkkhaki { color: #BDB76B; } /* ダーク・カーキ */
  .fc-palegoldenrod { color: #EEE8AA; } /* ペール・ゴールデンロッド */
  .fc-khaki { color: #F0E68C; } /* カーキ */
  .fc-lemonchiffon { color: #FFFACD; } /* レモンシフォン */
  .fc-gold { color: #FFD700; } /* ゴールド */
  .fc-cornsilk { color: #FFF8DC; } /* コーンシルク */
  .fc-darkgoldenrod { color: #B8860B; } /* ダーク・ゴールデンロッド */
  .fc-goldenrod { color: #DAA520; } /* ゴールデンロッド */
  .fc-wheat { color: #F5DEB3; } /* ウィート */
  .fc-oldlace { color: #FDF5E6; } /* オールド・レース */
  .fc-floralwhite { color: #FFFAF0; } /* フローラル・ホワイト */
  .fc-orange { color: #FFA500; } /* オレンジ */
  .fc-moccasin { color: #FFE4B5; } /* モカシン */
  .fc-papayawhip { color: #FFEFD5; } /* パパイヤ・ホイップ */
  .fc-navajowhite { color: #FFDEAD; } /* ナバホ・ホワイト */
  .fc-blanchedalmond { color: #FFEBCD; } /* ブランチド･アーモンド */
  .fc-tan { color: #D2B48C; } /* タン */
  .fc-antiquewhite { color: #FAEBD7; } /* アンティーク・ホワイト */
  .fc-burlywood { color: #DEB887; } /* バーリー・ウッド */
  .fc-darkorange { color: #FF8C00; } /* ダーク・オレンジ */
  .fc-bisque { color: #FFE4C4; } /* ビスク */
  .fc-peru { color: #CD853F; } /* ペルー */
  .fc-linen { color: #FAF0E6; } /* リネン */
  .fc-peachpuff { color: #FFDAB9; } /* ピーチ・パフ */
  .fc-sandybrown { color: #F4A460; } /* サンディ・ブラウン */
  .fc-saddlebrown { color: #8B4513; } /* サドル・ブラウン */
  .fc-chocolate { color: #D2691E; } /* チョコレート */
  .fc-seashell { color: #FFF5EE; } /* シーシェル */
  .fc-sienna { color: #A0522D; } /* シエナ */
  .fc-lightsalmon { color: #FFA07A; } /* ライト・サロモン */
  .fc-orangered { color: #FF4500; } /* オレンジ・レッド */
  .fc-coral { color: #FF7F50; } /* コーラル */
  .fc-darksalmon { color: #E9967A; } /* ダーク・サロモン */
  .fc-tomato { color: #FF6347; } /* トマト */
  .fc-salmon { color: #FA8072; } /* サロモン */
  .fc-mistyrose { color: #FFE4E1; } /* ミスティ・ローズ */
  .fc-red { color: #FF0000; } /* レッド */
  .fc-darkred { color: #8B0000; } /* ダーク・レッド */
  .fc-maroon { color: #800000; } /* マルーン */
  .fc-firebrick { color: #B22222; } /* ファイア・ブリック */
  .fc-brown { color: #A52A2A; } /* ブラウン */
  .fc-indianred { color: #CD5C5C; } /* インディアン・レッド */
  .fc-lightcoral { color: #F08080; } /* ライト・コーラル */
  .fc-rosybrown { color: #BC8F8F; } /* ロージー・ブラウン */
  .fc-snow { color: #FFFAFA; } /* スノウ */
  .fc-white { color: #FFFFFF; } /* ホワイト */
  .fc-whitesmoke { color: #F5F5F5; } /* ホワイト・スモーク */
  .fc-gainsboro { color: #DCDCDC; } /* ゲインズボロ */
  .fc-lightgrey { color: #D3D3D3; } /* ライト・グレイ */
  .fc-silver { color: #C0C0C0; } /* シルバー */
  .fc-darkgray { color: #A9A9A9; } /* ダーク・グレイ */
  .fc-gray { color: #808080; } /* グレイ */
  .fc-dimgray { color: #696969; } /* ディム・グレイ */
  .fc-black { color: #000000; } /* ブラック */
  .fc-theme { color: $theme_color_default; } /* テーマカラー */
  .fc-accent { color: $accent_color_dark; } /* アクセントカラー */
  .fc-cap { color: #666; } /* キャプションカラー */

  .w-10 { width: 10px;}
  .w-20 { width: 20px;}
  .w-30 { width: 30px;}
  .w-40 { width: 40px;}
  .w-50 { width: 50px;}
  .w-60 { width: 60px;}
  .w-70 { width: 70px;}
  .w-80 { width: 80px;}
  .w-90 { width: 90px;}
  .w-100 { width: 100px;}
  .w-110 { width: 110px;}
  .w-120 { width: 120px;}
  .w-130 { width: 130px;}
  .w-140 { width: 140px;}
  .w-150 { width: 150px;}
  .w-160 { width: 160px;}
  .w-170 { width: 170px;}
  .w-180 { width: 180px;}
  .w-190 { width: 190px;}
  .w-200 { width: 200px;}

  .pl-5 { padding-left: 5px; }
  .pl-10 { padding-left: 10px; }

}


@mixin pseudo-checkbox-f {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    &.on {
        .checked {
        border-color: #4285F4;
        background: #4285F4;
        border-radius: 3px;
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: bottom;

        &:before {
            content: '✓';
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 3px;
            margin: 10px 10px 0px 1px;
            color: #fff;
            line-height: 0px;
            text-align: center;
            font-weight: bold;
        }

      }

    }

}