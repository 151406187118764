@charset "UTF-8";

//ログイン画面のcontentsのみinlineのheightを上書きする
.contents.page-login-wrap{
  height: auto !important;
}

.page-login {

  .page-inner {
    padding-top: 50px;
    width: 400px;
    margin: auto;

    .page-contents {
      text-align: center;

      .logo {
        margin-bottom: 60px;
      }

      .btn {
        font-size: 18px;
      }

      .form-btns {
        margin-top: 70px;
        margin-bottom: 30px;
      }

      .forgot-pass {
        font-size: 13px;
      }

    }
  }
}