@charset "UTF-8";

.report-file-field-list {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 5px 0 5px;

  table {
    width: 100%;
    table-layout: fixed;

    tr{
      line-height: 20px;

      .btn-delete{
        width: 30px;
      }
      
      td {   
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 5px 0;
        vertical-align: middle;
        font-size: 12px;
        border-bottom: 1px solid #ccc;
            
        .btn-dowload{
          text-decoration: none;
          border-radius: 5px;
          float: right;
          display: block;
          border: 1px solid #ee7800;
          background-size: 20px 20px;
          background-repeat: no-repeat;
          width: 50%;
          min-width: 100px;
          height: 24px;
          color: #ee7800;;
          line-height: 26px;
          text-align: center;

          &:hover {
            opacity: .7;
          }

          &:visited {
            text-decoration: none;
          }
        }

        .btn-image{
          background-image: url(#{$IMG_PATH}icon/icn_trash_k.png);
          display: block;
          border: none;
          background-size: 20px 20px;
          background-repeat: no-repeat;
          width: 25px;
          height: 20px;
          margin: 0px auto;

          &:hover {
            opacity: .7;
          }
        }
      }
    }

    tr:last-child {
      border-bottom: none;
    }
  }
}