@charset "UTF-8";

.m-form {
  width: 900px;
  margin: auto;
  left: -20px;
  position: relative;

  @include design_default_set;

  .lead {
    margin: 20px 0 40px;
  }
  
  .error-area {
    padding: 15px;
    background-color: #f2dede;
    border: 1px solid #eed3d7;
    color: #b94a48;
  }

  .msg-area {
    padding: 15px;
    text-align: center;
    font-size: 15px;
  }

  .ph {
    border: 1px solid #c8c8c8;
    padding: 2px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    width: 100px;
    height: 100px;

    .user-ph {
      display: block;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center center;
    }

  }

  .input-text {
    border: 1px solid #c8c8c8;
    padding: 10px 8px 9px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    
    &:disabled {
      background: $background_color_disabled;
      border: $border_input_disabled;
      border-width: 1px;
    }

    &.readonly {
      background: $background_color_readonly;
      border: $border_input_readonly;
    }

    &.is-error {
      background: $background_error;
    }
    
    &.input-size-ms {
      width: 150px;
      height: 26px;
    }

    &.input-size-m {
      width: 50%;
    }

    &.input-size-s {
      width: 25%;
    }

    &.input-size-period {
      width: 35%;
    }

    &::-webkit-input-placeholder {
      color: #999;
      text-indent: 0;
    }
    &:-ms-input-placeholder {
      color: #999;
      text-indent: 0;
    }
    &::-moz-placeholder {
      color: #999;
      text-indent: 0;
    }

  }

  .btn-cal {
    @include HoverOpacity();
    margin-left: 5px;
    img {
      vertical-align: middle;
    }
  }

  textarea {
    border: 1px solid #c8c8c8;
    padding: 7px 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;

    &.is-error {
      background: $background_error;
    }
  }

  .radio-group, .checkbox-group {
    display: inline-block;
    vertical-align: middle;
    margin: 2px 30px 2px 0;

    .pseudo-checkbox, .pseudo-radio {
      margin-right: 10px;
    }
  }

  .select-group {
    vertical-align: middle;
    margin-right: 20px;
    .sel {
      margin-right: 10px;
    }
  }

  .form-wrap {
    margin: 40px 0;
  }

  .form-group {
    margin: 20px 0;
  }

  .form-row {

    & + .form-row {
      margin-top: 10px;
    }

    .layout {
      margin-left: -10px;
      & > div {
        padding-left: 10px;
      }
    }

  }

  .form-clone-wrap {
    position: relative;

    .form-row {
      position: relative;
    }

    .form-clone-control {
      position: absolute;
      bottom: 11px;
      right: -55px;
      width: 50px;
    }

    .btn-remove, .btn-clone {
      @include HoverOpacity();
      display: inline-block;
      width: 16px;
      height: 16px;

      &.disabled {
        cursor: default;
        opacity: .3;
      }

      &.is-hidden {
        display: none;
      }

      span {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        border: 1px solid #4EA3D4;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
      }

    }

    .btn-remove {
      span {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        border: 1px solid #666;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
      }

    }

    .btn-remove {
      margin-right: 5px;

      span {

        &:after {
          @include PseudoElements();
          position: absolute;
          display: block;
          width: 2px;
          height: 12px;
          background: #666;
          left: 50%;
          top: 50%;
          margin-left: -1px;
          margin-top: -6px;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }
    }

    .btn-clone {

      span {

        &:after, &:before {
          @include PseudoElements();
          position: absolute;
          display: block;
          width: 2px;
          height: 12px;
          background: #4EA3D4;
          left: 50%;
          top: 50%;
          margin-left: -1px;
          margin-top: -6px;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
        }

        &:before {
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }

    }
  }

  .error {
    margin-top: 10px;
    font-size: 12px;
    color: red;
    font-weight: bold;
  }

  table {
    width: 100%;
    table-layout: fixed;

    tr {

      td,th {
        padding: 10px;
      }

      th:first-child {
        //width: 200px;
        line-height: 1.2;
        text-align: right;
        padding-right: 20px;
        vertical-align: middle;
      }

      &.error-row {
        td {
          padding-top: 0;

          .error {
            margin-top: 0;
          }
        }
      }
    }

    table {
      tr {
        th:first-child {
          padding-right: 5px;
          color: $font_color_default;
          &.w-20p {
            width: 20%!important;
          }
        }
        th {
          text-align: right;
        }

        th, td {
          padding: 5px;
        }
      }
    }

  }

  .form-btns {
    text-align: center;
    margin: 20px 0;

    .btn {
      margin: 0 7px;
    }

    & > div {
      margin: 20px 0;
    }

  }

  &.login-form {
    width: 100%;
    left: 0;
    
    .input-text {
      border: 1px solid #c8c8c8;
      text-align: left;

      &:-moz-placeholder {
      }
      &:-ms-input-placeholder {
      }
      &::-webkit-input-placeholder {
      }
    }
    
  }

  &.forgot-form {
    width: 100%;
    left: 0;

    .input-text {
      border: 1px solid #d1d1d1;
      background: #f2f2f2;
    }
    
    table {

      tr {

        th, td {
          border: 1px solid #ccc;
        }
        th:first-child {
          width: 180px;
          text-align: left;
          color: $font_color_default;
          padding-left: 20px;
          background: $background_theme_color_light;
        }
      }
    }

    .mini {
      font-size: 10px;
      padding-left: 5px;
    }

  }

  .comp-text {
    margin-top: 40px;
    font-size: 16px;
  }

  &.conf-form {

    table {

      tr {

        td,th {
          vertical-align: middle;
          border: 1px solid #ccc;
        }

        th:first-child {
          background: $background_theme_color_light;
        }

      }

      &.with-public-row tr {

        td {
          border-right: none;

          &:last-child {
            border-left: none;
            border-right: 1px solid #ccc;
            color: $theme_color_default;
          }
        }

      }

    }

  }

  &.comp-form {

    table {

      tr {

        td,th {
          border: 1px solid #ccc;
        }

        th:first-child {
          background: $background_theme_color_light;
        }

      }

    }

  }

}

.form-sub-heading {
  margin-top: 20px;
  font-size: 20px;
  border-bottom: 1px solid #ccc;

  img {
    width: 30px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
  }
}

.form-under-heading {
  font-weight: bold;
  position: relative;
  font-size: 18px;
  padding-left: 15px;
  border-bottom: 2px solid #0b3182;
  color: $theme_color_default;
  padding-bottom: 2px;
  margin-bottom: 25px;

  &:before {
    @include PseudoElements();
    position: absolute;
    left: 0;
    top: 2px;
    width: 4px;
    height: 20px;
    background: #0b3182;
  }
}

.m-form-search {

  background: #fff;
  padding-top: 20px;
  padding-bottom: 10px;

  &.conf {
    padding-top: 0;
    padding-bottom: 0;
  }

  .form-group {
    margin: 0 20px;

    &.bg-gray {
      background: #eee;
    }

    .inner {
      padding: 20px 0;
      width: 730px;
      margin: 0 auto;
    }
  }

  .form-group.-col-2 {
    width: 730px;
    margin: auto;

    .layout {
      @include EqualList(2, 20px);
    }
  }

  .form-group.-col-3 {

    .layout {
      @include EqualList(3, 20px);
    }

  }

  .form-group.-col-4 {

    .layout {
      @include EqualList(4, 20px);
    }

  }

  .form-row {
    .layout {
      @include EqualList(2, 20px);
    }

   }

  select.default {
    width: 100%;
    height: 39px;
    border: 1px solid #c8c8c8;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
  }

  .error {
    &:first-child {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  .input-text {
    width: 100%;
    border: 1px solid #c8c8c8;
    padding: 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 100%;
  }

  .error {
    margin-top: 10px;
    font-size: 12px;
    color: red;
    font-weight: bold;
  }

  .search-ttl,
  .search-ttl-sub {
    margin-bottom: 5px;
  }

  .search-ttl {
    font-weight: bold;
  }

  .form-btns {
    text-align: center;
    .btn {
      margin: 0 7px;
    }

    & > div {
      margin: 10px 0;
    }

  }

}