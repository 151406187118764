@charset "UTF-8";

.contents {
  height: 100%;

  .p-pager {
    margin-top: 5px;
    margin-left: 10px;
    min-height: 30px;
    display: flex;
    align-items: center;

    ul.pagination {
      display: inline-block;
      padding: 0;
      margin: 0;

      li {
        display: inline;

        a {
          color: black; // float: left;
          padding: 3px 9px;
          text-decoration: none;
          border-radius: 5px;

          &.active {
            background-color: #468CC8;
            color: white;
            border-radius: 5px;
          }

          &:hover:not(.active) {
            background-color: #6E6E6E;
            color: white;
          }
        }

        strong {
          -webkit-user-select: none;
          /* Chrome all / Safari all */
          -moz-user-select: none;
          /* Firefox all */
          -ms-user-select: none;
          /* IE 10+ */
          user-select: none;
          /* Likely future */
          cursor: default;
        }
      }
    }
  }
}

.contents-inner {
  height: 100%;
}

.page-report-score-list {
  height: 100%;

  .page-inner {
    background: $background_theme_color_contents;
    height: 100%;

    .page-contents {
      width: 100%;
      height: 100%;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      .p-update {
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        min-height: 56px;
        max-height: 56px;
        margin-top: 5px;
      }

      .no-data {
        min-height: 85%;
        margin: 0 10px;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 0px !important;

        &.hide {
          display: none;
        }
      }

      .report-score-header {
        display: flex;
        padding-bottom: 5px;

        .except {
          height: 71px;
          display: none;
        }

        .download-csv {
          position: absolute;
          // right: 0px;
          right: 225px;
          margin-top: 5px;
          margin-right: 10px;
          min-height: 30px;
          padding-bottom: 5px;
        }

        .download-report {
          position: absolute;
          // right: 185px;
          right: 0px;
          margin-top: 5px;
          margin-right: 10px;
          min-height: 30px;
          padding-bottom: 5px;

          input[type=button] {
            width: 215px;
          }
        }

        .result-txt {
          margin-top: 5px;
          margin-left: 10px;
          min-height: 30px;
          display: flex;
          align-items: center;
        }
      }
    }

    .report-score-result {
      @include search_table_f;
      padding: 0px !important;

      &.result-tbl {
        @media screen and (max-width: 1366px) {
          @media screen and (max-height: 768px) {
            height: 85%;
          }
        }

        padding: 0px;
        margin: 0px 10px 0px 10px !important;
        height: 85%;
        background: white;

        .ps-scrollbar-y-rail {
          right: 0px;
          z-index: 6;
        }

        table {
          &.report-score-table-result {
            margin-bottom: 15px;
          }

          min-width: 1700px;

          thead > tr {
            width: 100%;
            position: fixed;
            z-index: 5;

            .ps-scrollbar-x-rail,
            .ps-scrollbar-y-rail {
              display: none;
            }
          }

          th {
            border: 0.5px solid #c8c8c8 !important;
            border-top: 0px solid #fafafa !important;
            padding: 5px;
          }

          th:first-child {
            border-left: 0px solid #fafafa !important;
          }
        }

        .report-score-table-result,
        .report-score-table-header {
          table-layout: fixed;

          .choosen > .sel {
            width: 100%;
            height: 32px;

            .sel-txt {
              display: flex;
              align-items: center;
            }
          }

          input[type=checkbox] {
            display: none;
          }

          td,
          th {
            padding: 5px;
            box-sizing: border-box;
          }

          th:hover {
            cursor: pointer;
          }

          label {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 14px !important;
            height: 14px !important;

            &::after {
              left: calc(50% + 19px);
              width: 14px !important;
              height: 14px !important;
            }

            &::before {
              left: calc(50% + 21px);
              width: 11px !important;
              height: 5px !important;
            }
          }

          input[type=text] {
            width: 95%;
            text-align: center;
          }

          .col-check {
            width: 3%;
//            min-width: 39px !important;
//            max-width: 39px !important;
            height: 34px;
          }

          .col-login-id {
            width: 8%;
//            min-width: 121px !important;
//            max-width: 121px !important;
          }

          .col-name {
            width: 8%;
//            min-width: 121px !important;
//            max-width: 121px !important;
          }

          .col-submit-select {
            width: 6%;
//            min-width: 88px !important;
//            max-width: 88px !important;
          }

          .col-submit-file-name {
            width: 9%;
//            min-width: 137px !important;
//            max-width: 137px !important;
          }

          .col-submit-date {
            width: 9%;
//            min-width: 137px !important;
//            max-width: 137px !important;
          }

          .col-last-submitted {
            width: 9%;
          }

          .col-late {
            width: 5%;
//            min-width: 72px !important;
//            max-width: 72px !important;

            &.check {
              background-image: url(#{$IMG_PATH}icon/icon_late_g.png);
              -webkit-background-size: 25%;
              background-size: 25px;
              background-repeat: no-repeat;
              background-position: 50%;
            }
          }

          .col-return {
            width: 3.5%;
//            min-width: 47px !important;
//            max-width: 47px !important;

            .checkbox-btn-disabled {
              label {
                cursor: default;
              }

              input {
                cursor: default;
              }

              label::before {
                cursor: default;
              }

              label::after {
                background-color: #eee !important;
                cursor: default;
              }
            }

            input[type="checkbox"]:disabled ~ label::before {
              display: block !important;
            }
          }

          .col-score {
            width: 7%;
//            min-width: 71px !important;
//            max-width: 71px !important;
          }

          .col-confirm-score {
            width: 7%;
//            min-width: 103px !important;
//            max-width: 103px !important;
          }

          .col-feedback-comment {
            width: 10%;
//            padding: 0 4px;
//            min-width: 155px !important;
//            max-width: 155px !important;
          }

          .multi-space-ellipsis {
            white-space: nowrap !important;
            overflow: hidden;
            text-overflow: ellipsis !important;
            word-wrap: normal !important;
          }

          .col-return-file {
            width: 9%;
//            min-width: 136px !important;
//            max-width: 136px !important;
          }

          .col-grader {
            width: 7%;
//            min-width: 103px !important;
//            max-width: 103px !important;
          }

          .col-score-date {
            width: 9%;
//            min-width: 136px !important;
//            max-width: 136px !important;
          }

          .col-edit {
            width: 5%;
//            min-width: 78px !important;
//            max-width: 78px !important;

            .btn-submit-setting {
              background: url(#{$IMG_PATH}icon/icn_edit_k.png) 20px center no-repeat;
              -webkit-background-size: 80%;
              background-size: 80%;
              background-position-x: 50%;
              font-weight: normal;
              width: 34px;
              height: 34px;
              line-height: 34px;
              text-align: center;
              font-size: 16px;
              padding: 0;
            }
          }
        }

        .report-score-table-header {
          thead {
            tr {
              .header {
                cursor: pointer;
                box-sizing: border-box;

                div {
                  display: inline-flex;
                   .img {
                     background-image: url(#{$IMG_PATH}icon/icn_pullupdown_k.png);
                     background-repeat: no-repeat;
                     background-position: center;
                     background-size: 26px;
                     width: 27px;
                     padding-left: 7px;
                   }
                }
              }

              .header-sort-up {
                cursor: pointer;

                div {
                  display: inline-flex;

                  .img {
                    width: 26px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url(#{$IMG_PATH}icon/icn_pullup_k.png);
                    background-size: 13px;
                    padding-left: 7px;
                  }
                }
              }

              .header-sort-down {
                cursor: pointer;

                div {
                  display: inline-flex;

                  .img {
                    width: 26px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
                    background-size: 13px;
                    padding-left: 7px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .m-search-c {
      .m-search-c-inner {
        width: 100%;
        height: 100%;
        position: relative;

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.import {
  .m-modal {
    .modal-inner {
      &.modal-import-csv {
        position: relative;
        top: 20%;
        padding-left: 0px;
        padding-right: 0px;
        width: 750px;
        min-height: 510px;

        @media screen and (max-width: 1366px) {
          @media screen and (max-height: 768px) {
            &.modal-import-csv {
              min-height: 410px;
            }
          }
        }

        .title {
          span {
            color: #82bef0;
            font-size: 18px;
          }
        }

        .dagdrop {
          width: 600px;
          height: 250px;
          left: 10%;
          position: relative;
          border: 4px dashed #c8c8c8;
          margin-bottom: 15px;
          margin-top: 15px;
          background: #f1f1f1;

          .dagdrop-text {
            color: #c8c8c8;
            position: relative;
            font-size: 18px;
            width: 250px;
            right: 26px;
            top: 113px;
          }

          .dragdrop-image {
            position: static;
            margin-top: 72px;
            float: left;
            margin-left: 135px;
          }

          @media screen and (max-width: 1366px) {
            @media screen and (max-height: 768px) {
              height: 200px;
              border: 4px dashed #c8c8c8;

              .dagdrop-text {
                color: #c8c8c8;
                position: relative;
                margin-top: 85px;
                font-size: 16px;
                width: 222px;
                right: 50px;
              }

              .dragdrop-image {
                position: static;
                margin-top: 30px;
                float: left;
                margin-left: 40px;
              }
            }
          }
        }

        .dagdrop::before {
          display: block;
          content: "";
          width: 90px;
          height: 90px;
          position: absolute;
          top: -6px;
          left: -6px;
          border-top: 7px solid #c8c8c8;
          border-left: 7px solid #c8c8c8;

          @media screen and (max-width: 1366px) {
            @media screen and (max-height: 768px) {
              width: 50px;
              height: 50px;
              position: absolute;
              top: -5px;
              left: -5px;
              border-top: 5px solid #c8c8c8;
              border-left: 5px solid #c8c8c8;
            }
          }
        }

        .dagdrop::after {
          display: block;
          content: "";
          width: 90px;
          height: 90px;
          position: absolute;
          top: -6px;
          right: -6px;
          border-top: 7px solid #c8c8c8;
          border-right: 7px solid #c8c8c8;

          @media screen and (max-width: 1366px) {
            @media screen and (max-height: 768px) {
              width: 50px;
              height: 50px;
              position: absolute;
              top: -5px;
              right: -5px;
              border-top: 5px solid #c8c8c8;
              border-right: 5px solid #c8c8c8
            }
          }
        }

        .border-dagdrop::before {
          display: block;
          content: "";
          width: 90px;
          height: 90px;
          position: absolute;
          bottom: -6px;
          left: -6px;
          border-bottom: 7px solid #c8c8c8;
          border-left: 7px solid #c8c8c8;

          @media screen and (max-width: 1366px) {
            @media screen and (max-height: 768px) {
              width: 50px;
              height: 50px;
              position: absolute;
              bottom: -5px;
              left: -5px;
              border-bottom: 5px solid #c8c8c8;
              border-left: 5px solid #c8c8c8;
            }
          }
        }

        .border-dagdrop::after {
          display: block;
          content: "";
          width: 90px;
          height: 90px;
          position: absolute;
          bottom: -6px;
          right: -6px;
          border-bottom: 7px solid #c8c8c8;
          border-right: 7px solid #c8c8c8;

          @media screen and (max-width: 1366px) {
            @media screen and (max-height: 768px) {
              width: 50px;
              height: 50px;
              position: absolute;
              bottom: -5px;
              right: -5px;
              border-bottom: 5px solid #c8c8c8;
              border-right: 5px solid #c8c8c8;
            }
          }
        }

        .chooseFile {
          top: 5px;
          position: relative;
        }

        .btn-choosefile {
          border: 1px solid #a0a0a0;
          font-weight: normal;
          border-radius: 2px;
          font-size: 12px;
          padding-right: 5px;
          background-color: white;
          color: #6e6e6e;
          width: 125px;
          line-height: 21px;

          @media screen and (max-width: 1366px) {
            @media screen and (max-height: 768px) {
              font-size: 11px;
            }
          }
        }

        #jsCSVUploadFile {
          position: absolute;
          width: 127px;
          height: 25px;
          z-index: 99;
          filter: alpha(opacity=0);
          opacity: 0.0;
          font-size: 30px;
          cursor: pointer;
          overflow: hidden;
          left: 22%;

          @media screen and (max-width: 1366px) {
            @media screen and (max-height: 768px) {
              left: 21%;
            }
          }
        }

        .fileName {
          width: 280px;
          color: #6e6e6e;
        }

        .btn-next {
          margin-top: 50px;
          font-weight: normal;
          font-size: 12px;
          padding-right: 5px;
          background-color: white;
          color: #0f83c6;
          border: 1px solid #0f83c6;
          border-radius: 2px;
          width: 124px;
          line-height: 24px;

          @media screen and (max-width: 1366px) {
            @media screen and (max-height: 768px) {
              font-size: 11px;
              font-weight: normal;
              padding-right: 5px;
              background-color: white;
              color: #468CC8;
              border: 1px solid #468CC8;
              border-radius: 2px;
              width: 124px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}
