@charset "UTF-8";
.grade-evaluate-import-container-inner {

	.separator {
		margin: 5px 25px 10px 25px;
		border-bottom: 2px solid $border_color_default;
	}

	.main-area {
	    margin: 5px 5px 5px 5px;
	    padding: 20px;
		width: 800px;

		.select-upload {
			text-align: right;

			.sel {
				background-color: white;
				-webkit-border-radius: 0px;
				-moz-border-radius: 0px;
				border-radius: 0px;
				color: #468CC8;
				border: 1px solid;
				width: 200px;

				sel-txt {
					adding-left: 10px;
					padding-right: 17px;
				}
			}
		}

		.dagdrop {
//			width: 100%;
			height: 150px;
			position: relative;
			border: 4px dashed #c8c8c8;
			margin-bottom: 15px;
			margin-top: 15px;
			background: #f1f1f1;

			.dagdrop-text {
				color:#c8c8c8 ;
				position: relative;
				font-size: 18px;
				width: 250px;
	//			right: 26px;
				top: 65px;
			}

			.dragdrop-image{
				position: static;
				margin-top: 5px;
				float: left;
				margin-left: 50px;
			}

			@media screen and (max-width : 1366px) {
				@media screen and (max-height : 768px) {
					height: 150px;
					border: 4px dashed #c8c8c8;
					.dagdrop-text {
						color: #c8c8c8;
						position: relative;
						margin-top: 85px;
						font-size: 16px;
						width: 222px;
	//					right: 50px;
					}
					.dragdrop-image{
						position: static;
						margin-top: 5px;
						float: left;
						margin-left: 80px;
					}
				}
			}
		}

		.dagdrop::before {
			display: block;
			content: "";
			width: 30px;
			height: 30px;
			position: absolute;
			top: -6px;
			left: -6px;
			border-top: 7px solid #c8c8c8;
			border-left: 7px solid #c8c8c8;
			@media screen and (max-width : 1366px) {
				@media screen and (max-height : 768px) {
					width: 30px;
					height: 30px;
					position: absolute;
					top: -5px;
					left: -5px;
					border-top: 5px solid #c8c8c8;
					border-left: 5px solid #c8c8c8;
				}
			}
		}

		.dagdrop::after {
			display: block;
			content: "";
			width: 30px;
			height: 30px;
			position: absolute;
			top: -6px;
			right: -6px;
			border-top: 7px solid #c8c8c8;
			border-right: 7px solid #c8c8c8;
			@media screen and (max-width : 1366px) {
				@media screen and (max-height : 768px) {
					width: 30px;
					height: 30px;
					position: absolute;
					top: -5px;
					right: -5px;
					border-top: 5px solid #c8c8c8;
					border-right: 5px solid #c8c8c8
				}
			}
		}

		.border-dagdrop::before {
			display: block;
			content: "";
			width: 30px;
			height: 30px;
			position: absolute;
			bottom: -6px;
			left: -6px;
			border-bottom: 7px solid #c8c8c8;
			border-left: 7px solid #c8c8c8;
			@media screen and (max-width : 1366px) {
				@media screen and (max-height : 768px) {
					width: 30px;
					height: 30px;
					position: absolute;
					bottom: -5px;
					left: -5px;
					border-bottom: 5px solid #c8c8c8;
					border-left: 5px solid #c8c8c8;
				}
			}
		}

		.border-dagdrop::after {
			display: block;
			content: "";
			width: 30px;
			height: 30px;
			position: absolute;
			bottom: -6px;
			right: -6px;
			border-bottom: 7px solid #c8c8c8;
			border-right: 7px solid #c8c8c8;
			@media screen and (max-width : 1366px) {
				@media screen and (max-height : 768px) {
					width: 30px;
					height: 30px;
					position: absolute;
					bottom: -5px;
					right: -5px;
					border-bottom: 5px solid #c8c8c8;
					border-right: 5px solid #c8c8c8;
				}
			}
		}

		.chooseFile {
			top: 5px;
			left: 10%;
			position: relative;

			.btn-choosefile {
				border: 1px solid #a0a0a0;
				font-weight: normal;
				border-radius: 2px;
				font-size: 12px;
//				padding-right: 5px;
				padding: 3px;
				background-color: white;
				color: #6e6e6e;
//				width: 125px;
//				line-height: 21px;
//				text-align: center;
			}

			.fileName {
				width: 280px;
				color: #6e6e6e;
				background: white;
				border: 1px solid #a0a0a0;
			}

			#jsCSVUploadFile {
				visibility: hidden;
				width: 0px;
			}
		}

		.default-margin {
			top: 20px;
			left: 10%;
			position: relative;
			padding: 2px;
		 	width: 100%;

			 .text-header {
			 	display: inline-block;
			 	width: 140px;
			 }

			 .text-header::after {
			 	content: ":";
			 }
		}

		.default-center {
			top: 20px;
			text-align: center;
			position: relative;
		}

		.note-text-area {
			border: 1px solid #a0a0a0;
			width: 570px;
			height: 150px;
		}

		.btn {
			position: relative;
			background: #fff;
			border: 1px solid #DC780A;
			width: 200px;
			height: 34px;
			line-height: 34px;
			font-size: 16px;
			color: #DC780A;
			font-weight: normal;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			border-radius: 2px;
			margin: 5px;
		}

		.import-failed-modal-inner {
			width: 600px;
		}
		
		.is-update-confirm-modal-inner {
		    width: 650px;
          .cancel-btn-confirm {
            border-color: #a0a0a0;
            color: #a0a0a0;
          }
		}

        .import-complete-modal-inner {
		    width: 800px;
        }

		.error-message {
			color: red;
			text-align: left;
		}
	}
}
