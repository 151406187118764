@charset "UTF-8";
.c-course-user-history-header {
  background: #fff;
  .pl-30 {
    padding-left: 30px!important;
  }
  .pl-100 {
    padding-left: 100px!important;
  }
  .c-course-user-history-header-inner {
    position: relative;
    padding-top: 20px;
      .form-btns-top{
          .btn-back-icon{
              z-index: 1;
              float: left;
              width: 0px;
          }
          .inner-resize {
            padding: 0px;
            min-width: 890px;
            margin: 0 auto;
            max-width: 1300px;
            .col1 {
              &.lst {
                width: 43%;
                max-width: 43%;
                min-width: 43%;
                .rate {
                    max-width: 198px;
                    .input-text{
                        max-width: 80%;
                    }
                    .title.no-width{
                        min-width: 15px;
                    }
                }
              }
              width: 28%;
              max-width: 28%;
              min-width: 28%;
              .status-chk {
                display: flex;
                justify-content: center;
                align-items: center;
                label {
                  padding-left: 15px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                .checkbox-btn {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  label {
                    padding-left: 20px;
                    &::after {
                      top: 5px;
                      left: 15px;
                      width: 14px !important;
                      height: 14px !important;
                    }
                    &::before {
                      top: 8px;
                      left: 17px;
                      width: 11px !important;
                      height: 5px !important;
                    }
                  }
                }
              }
              .note {
                font-size: 11px;
                margin-top: -10px;
              }
            }
          } //.inner-resize
      }

    .tab-select-search {
      display: flex;
      position: relative;
      margin-bottom: 20px!important;
      input[type='radio'] {
        display: none;
      }
      .btn:hover {
        &:not(.rd-selected) {
          opacity: 0.7;
        }
      }
      .btn-select-tab {
        border: 1px solid #c8c8c8;
        background-color: white;
        width: 100px;
        height: 32px;
        color: #333;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .rd-selected {
        background: #82BEF0;
        color: #fff;
      }

        .tab-select-search-left{
            width : 10%;
        }
    }
    .course-user-history-header-layout {
      height: 100%;
      min-height: 1px;
      .hide {
        display: none;
      }
      .m-form-search {
        padding-top: 10px;
        .course-tab {
          display: block;
        }
        .lesson-tab {
          display: none;
        }
        .chapter-tab {
          display: none;
        }
        .form-btns {
          display: flex;
          justify-content: center;
          >div {
            margin: 0px;
          }
        }
        .form-group {
          .layout {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            &.start {
              justify-content: start;
            }
            &_trisect {
              > * {
                width: 33.3%!important;
                min-width: 33.3%!important;
                max-width: 33.3%!important;
              }
            }
            &_bisect {
              justify-content: space-around;
              > * {
                width: 34%!important;
                min-width: 500px!important;
                max-width: 34%!important;
              }
            }
            }
          }
          .inner-resize {
            padding: 0px;
            min-width: 890px;
            margin: 0 auto;
            max-width: 1300px;
            .layout_trisect > .col1 {
              margin-top: 20px;
            }
            .col1.pl-100+.col1.lst {
              padding-left: 140px !important;
            }
            .col1 {
              &.lst {
                width: 43%;
                max-width: 43%;
                min-width: 43%;
                .rate {
                    max-width: 198px;
                    .input-text{
                        max-width: 80%;
                    }
                    .title.no-width{
                        min-width: 15px;
                    }
                }
              }
              width: 28%;
              max-width: 28%;
              min-width: 28%;
              .status-chk {
                display: flex;
                justify-content: center;
                align-items: center;
                label {
                  padding-left: 15px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                .checkbox-btn {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  label {
                    padding-left: 20px;
                    &::after {
                      top: 5px;
                      left: 15px;
                      width: 14px !important;
                      height: 14px !important;
                    }
                    &::before {
                      top: 8px;
                      left: 17px;
                      width: 11px !important;
                      height: 5px !important;
                    }
                  }
                }
              }
              .note {
                font-size: 11px;
                margin-top: -10px;
              }
              // .btn-lesson-chapter {
              //   border: 1px solid #c8c8c8;
              //   background: url(#{$IMG_PATH}icon/icn_lesson_k.png) 50% center no-repeat;
              //   background-color: white;
              //   background-size: 22px;
              //   position: relative;
              //   width: 40px;
              //   height: 33px;
              //   line-height: 44px;
              //   font-size: 16px;
              //   color: #333;
              //   font-weight: normal;
              //   -webkit-border-radius: 5px;
              //   -moz-border-radius: 5px;
              //   border-radius: 5px;
              //   text-align: center;
              //   margin-left: 5px;
              // }
            }
          } //.inner-resize
          .inner {
            padding: 0px;
          }
          .layout {
            margin-bottom: 0px;
            div {
              margin-bottom: 0px;
              *:not(.ratePulldown) {
                height: 32px;
              }
              .inpt {
                display: flex;
                align-items: center;
                width: 100%;
                &.f-end {
                  justify-content: start;
                }
                .title {
                  &.start {
                    justify-content: flex-start;
                  }
                  display: flex;
                  min-width: 60px;
                  justify-content:flex-end;
                  align-items:center;
                  padding-right: 5px;
                  &.size-l {
                    min-width: 115px;
                  }
                  &.no-width {
                    min-width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px;
                  }
                }
                .c-name {
                  white-space: nowrap !important;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                  //min-width: 185px;
                  span {
                    white-space: pre !important;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    padding-top: 10px;
                    &.filter-select {
                      font-weight: bold;
                    }
                  }
                }
                .chosen {
                  min-width: 60px;
                  width: 230px;
                  .sel {
                    width: 100%;
                    border: 1px solid #c8c8c8;
                    -moz-border-radius: 0px;
                    border-radius: 0px;
                    p {
                      font-size: inherit;
                      font-weight: normal;
                      margin-top: 0;
                      background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
                      background-size: 12px;
                      line-height: 32px;
                      white-space: nowrap; // text-overflow: ellipsis;
                      overflow: hidden;
                    }
                  }
                  select {
                    width: 100%;
                  }
                  &.catology {
                    width: 35%; // font-size: 10px;
                    // p {
                    //   line-height: normal;
                    // }
                    select {
                      // word-wrap: break-word;      /* IE*/
                      // white-space: -moz-pre-space; /* Firefox */
                      // white-space: pre-wrap;      /* other browsers */
                      display: inline-block;
                    }
                  }
                }
                .txt {
                  width: 100%;
                  &.maker {
                    width: 50%;
                  }
                  &.w80{
                    width: 80%;
                  }
                }
                .rate {
                  max-width: 146px;
                    .input-text{
                        //max-width: 75%;
                        max-width: 100%;
                    }
                .rate_small {
                  max-width: 80px!important;
                    .input-text{
                        max-width: 100%;
                    }
                }
                .btn-c-search {
                  width: 40px;
                  margin-left: 5px;
                }
              } //.inpt
              .user-group-name-err-div {
                height: auto;
                min-height: 20px;
                .user-group-name-err {
                  height: 100%;
                }
              }
              .div-err {
                height: 20px !important;
                &.size-l {
                  margin-left: 120px !important;
                }
                .error {
                  height: 100% !important;
                  &.course-name-err {
                    text-align: center;
                  }
                  &.rate-err {
                    padding-left: 120px;
                  }
                  &:first-child {
                    margin-bottom: 0px !important;
                  }
                }
              }
            } //> div
          } //.layout
        } //.form-group
      } //.m-form-search
    } // .course-user-history-layout
  } // .index-header-inner
} // .index-header