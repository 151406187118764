@charset "UTF-8";

.c-course-chapter-history-header {
  background: #fff;

  .c-course-chapter-history-header-inner {
    position: relative;
    padding-top: 20px;

    .course-chapter-history-header-layout {
      height: 100%;
      min-height: 1px;

      .hide {
        display: none;
      }
      .m-form-search {
            padding-top: 10px;
        .form-btns {
          display: flex;
          justify-content: center;

          > div {
            margin: 0px;
            padding-top: 5px;
          }
        }

        .form-group {
          
          .layout {
            display: flex;
            justify-content: center;
            align-items: flex-start;
          }
          .inner-resize {
            padding: 0px;
            min-width: 890px;
            margin: 0 auto;
            max-width: 1300px;

            .col1-right {
              min-width: 305px;
            }

            .col1 {
              width: 35%;
            }

            .col3-left {
              min-width: 610px;
            }

            .col3 {
              width: 47%;
              .status-chk {
                display: flex;
                justify-content: center;
                align-items: center;
                label {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                .checkbox-btn {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  &.hide {
                    display: none;
                  }

                  label {
                    padding-left: 17px;
                    &::after {
                      top: 6px;
                      left: 15px;
                      width: 14px !important;
                      height: 14px !important;
                    }
                    &::before {
                      top: 9px;
                      left: 17px;
                      width: 11px !important;
                      height: 5px !important;
                    }
                  }
                }

                
              }

              .note {
                font-size: 11px;
                margin-top: -10px;
              }
            }

          } //.inner-resize

          .inner {
            padding: 0px;
          }

          .layout {
            margin-bottom: 0px;

            div {
              margin-bottom: 0px;
              * {
                height: 32px;
              }

              .inpt {
                display: flex;
                align-items: center;
                width: 100%;

                &.f-end {
                  justify-content: center;
                }
                .title {
                  display: flex;
                  min-width: 115px;
                  justify-content:flex-end;
                  align-items:center;
                  padding-right: 5px;

                  &.size-l {
                    min-width: 119px;
                  }

                  &.no-width {
                    min-width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding-left: 2px;
                  }

                }
                .c-name {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: flex;
                    align-items: center;

                    span {
                      white-space: pre !important;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      padding-top: 10px;
                    }
                  }

                .chosen {
                  min-width: 60px;
                  width: 100%;

                  .sel {
                    width: 100%;         
                    border: 1px solid #c8c8c8;
                    -moz-border-radius: 0px;
                    border-radius: 0px;
                    .sel-txt {
                      width: 100%;
                      margin-top: 0px;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      padding-right: 15px;
                    }
                    p {
                      font-size: inherit;
                      font-weight: normal;
                      margin-top: 0;
                      background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
                      background-size: 12px;
                      line-height: 32px;
                      white-space: nowrap;
                      // text-overflow: ellipsis;
                      overflow: hidden;
                    }

                  }

                  select {
                    width: 100%;
                  }

                }

                .txt {
                  width: 70%;

                  &.maker {
                    width: 50%;
                  }

                  &.content-type {
                    width: 60%;
                  }
                }

                .rate {
                    max-width: 115px;
                }

                .btn-c-search {
                  width: 40px;
                  margin-left: 5px;
                }
              } //.inpt

              .div-err {
                
                height: auto !important;
                min-height: 20px;

                &.size-l {
                  margin-left: 120px !important;
                }
                .error {
                  height: 100% !important;
                  white-space: nowrap;
                  &.course-name-err {
                    text-align: center;
                  }
                  &.creater-err {
                    padding-left: 90px;
                  }
                  &.lesson-err {
                    padding-left: 120px;
                  }
                  &.chapter-err {
                    padding-left: 120px;
                  }
                  &.rate-err{
                    padding-left: 120px;
                  }
                  &:first-child {
                    margin-bottom: 0px !important;
                    white-space: normal;
                  }

                }

              }
         
            } //> div

          } //.layout 

        } //.form-group

      } //.m-form-search

    } // .course-chapter-history-layout

  } // .index-header-inner

} // .index-header
