@charset "UTF-8";

.modal-wat-ref {

  .m-search-c-inner {
    iframe {
      width: 100vw;
      height: 100vh;
    }
  }

  .txt9 {
    max-width: 9em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .txt26 {
    max-width: 25em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .m-modal {
    .modal-inner {
      &.c-search {
        width: 680px;
        top: 2.5%;
        height: 95vh;
        min-height: 530px;
        padding: 15px 0;
        text-align: left;
        background: #fff;
        z-index: 11000;

        .c-search-header {
          position: relative;
          display: block;
          margin: 0px;

          .prev-arrow {
            position: absolute;
            top: 30%;
            left: 30px;
            transform: translateY(-50%);
            content: "";
            background: url(#{$IMG_PATH}icon/icn_collapse_k.png) center center/cover no-repeat;
            width: 16px;
            height: 24px;
            z-index: 10;
            cursor: pointer;
          }

          .next-arrow {
            position: absolute;
            top: 30%;
            right: 30px;
            transform: translateY(-50%);
            content: "";
            background: url(#{$IMG_PATH}icon/icn_expand_k.png) center center/cover no-repeat;
            width: 16px;
            height: 24px;
            z-index: 10;
            cursor: pointer;
          }

          .prev-arrow:hover,
          .next-arrow:hover {
            opacity: .8;
          }

          .col-top-left {
            display: inline-block;
            width: 57%;
            vertical-align: top;
            padding-left: 85px;

            .row-user-box {
              display: block;
              padding-bottom: 5px;

              .icon-box {

                img {
                  height: 22px;
                }

              }

              .icon-box,
              .user-name,
              .user-id {
                display: inline-block;
                vertical-align: bottom;
                font-size: 14px;
              }

              .user-name,
              .user-id {
                max-width: 9em;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }

            //row-user-box

            .row-course-box {
              display: block;

              .icon-box {

                img {
                  height: 22px;
                }

              }

              .icon-box,
              .course-name {
                display: inline-block;
                vertical-align: bottom;
                font-size: 14px;
              }

              p {
                padding: 0 0 0 26px;
                font-size: 12px;

                span::before {
                  content: ":";
                  padding: 0 5px;
                }
              }

              p.cer-date {
                padding: 5px 0 0 0;
              }
            }

            //row-course-box

            .row-memo-box {

              height: 40px;

              textarea {
                width: 343px;
                height: 27px;
                border: 1px solid #a9a9a9;
                resize: none;
                color: gray;
              }

              textarea::-webkit-input-placeholder {
                opacity: .5
              }

              textarea:-moz-placeholder {
                opacity: .3
              }

              textarea::-moz-placeholder {
                opacity: .3
              }

              textarea:-ms-input-placeholder {
                opacity: .5
              }
            }

          }

          .col-top-right {

            display: inline-block;
            width: 25%;
            vertical-align: top;

            .top-user-thumb {
              position: relative;
              width: 120px;
              height: 90px;
              margin-bottom: 3px;
              border: 1px solid #ccc;

              img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-height: 100%;

              }
            }

            .no_data_txt {
              padding-top: 5px;
              font-size: 10px;

              p {
                text-align: center;
              }
            }

            .rd-btn {
              margin-bottom: 3px;
              font-size: 12px;
            }

            .pseudo-radio {
              width: 16px;
              height: 16px;
            }

            .pseudo-radio .checked {
              width: 10px;
              height: 10px;
              left: 2px;
              top: 2px;
            }

          }


          .select-area {
            padding: 12px 0;
            border-top: 1px solid #a9a9a9;
            text-align: center;

            .form-box {
              display: inline-block;
              padding: 0 50px 18px;
              font-size: 12px;

              .sel {
                line-height: 32px;
              }
            }

            .num-box {
              display: inline-block;
              position: relative;
              padding: 0 60px 18px 30px;
            }

            .num-box {
              span {
                padding: 0 5px;
                font-size: 12px;
              }

              input {
                text-align: left;
                width: 50px;
                height: 26px;
              }

              #errorField {
                position: absolute;
                width: 276px;
                overflow: hidden;

                #rateErr {
                  font-size: 12px;
                  font-weight: bold;
                  color: #E20000;
                  text-align: left;
                }
              }
            }

          }


          .input-text {
            width: 100%;
            border: 1px solid #c8c8c8;
            padding: 3px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            max-width: 100%;
          }

          .c-btn {
            text-align: center;

            .btn-c-search {
              width: 145px;
              height: 33px;

              button {
                height: 30px;
              }

            }
          }

        }
      }

      .c-err {
        display: block;
        text-align: center;
        margin-left: 0;

        .error {
          font-size: 12px;
          color: red;
          font-weight: bold;

          &.hide {
            display: none;
          }
        }
      }

      .page-inner {

        .course-result {

          @media screen and (max-height: 1080px) {
            height: 586px !important;
          }

          @media screen and (max-height: 980px) {
            height: 556px !important;
          }

          @media screen and (max-height: 910px) {
            height: 526px !important;
          }

          @media screen and (max-height: 880px) {
            height: 506px !important;
          }

          @media screen and (max-height: 860px) {
            height: 476px !important;
          }

          @media screen and (max-height: 830px) {
            height: 456px !important;
          }

          @media screen and (max-height: 800px) {
            height: 436px !important;
          }

          @media screen and (max-height: 780px) {
            height: 426px !important;
          }

          @media screen and (max-height: 770px) {
            height: 416px !important;
          }

          @media screen and (max-height: 750px) {
            height: 386px !important;
          }

          @media screen and (max-height: 720px) {
            height: 346px !important;
          }

          @media screen and (max-height: 670px) {
            height: 280px !important;
          }

          @media screen and (max-height: 620px) {
            height: 270px !important;
          }
        }

        .course-result:after {
          display: block;
          clear: both;
          content: "";
        }

          #loading {
            height: 350px !important;
            span {
              img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        &.c-search-modal {
          height: 321px;
          margin: 0px;

          .course-header {
            position: relative;
            background: #bae3f9;
            box-sizing: border-box;
            margin: 0 10px;
            height: 30px;

            .log-btn {
              position: absolute;
              top: 3px;
              right: 26px;
              background: url(#{$IMG_PATH}icon/icn_error_r.png) 15px center no-repeat;
              -webkit-background-size: 60%;
              background-size: 80%;
              background-position-x: 50%;
              font-weight: normal;
              width: 32px;
              height: 23px;
              line-height: 32px;
              text-align: center;
              font-size: 16px;
              padding: 0;

              input.btn {
                width: 30px;
                opacity: 0;
              }

              input.btn:hover {
                opacity: 0;
              }

            }

            .log-btn:hover {
              opacity: .7;
            }
          }

          .result-txt {
            margin-top: 5px;
            margin-left: 50px;
            min-height: 30px;
            display: inline-block;
            align-items: center;
          }

          .pulldown-pager {
            margin-top: -1px !important;
          }

          .s-pager {
            top: 0 !important;
          }

          .table-result {

            width: 100%;
            margin: 0 0 0 32px;

            tr {
              position: relative;
              width: 30.3333%;
              display: block;
              vertical-align: middle;
              float: left;
            }

            th {
              position: relative;
              padding: 15px 20px 0px;
            }

            .col-user-thumb {
              position: absolute;
              width: 160px;
              height: 122px;
              vertical-align: middle;

              img {
                position: absolute;
                max-width: 156px;
                max-height: 122px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }

            .col-user-box.data-error {
              .col-user-thumb {
                position: absolute;
                top: 12px;
                left: 16px;
                border: 3px solid #e20000;
              }

              .col-text-box {
                color: #e20000;
              }
            }

            .col-text-box {
              font-size: 10px;
              position: relative;

              width: 100%;
              margin-top: 130px;
              text-align: left;

              .comp-perf {
                position: absolute;
                display: inline-block;
                top: 0px;
                left: 60%;
                white-space: nowrap;
              }

              span.add-date {
                margin-left: -3px;
              }

              span {
                padding-right: 0px;

              }
            }
          }
        }
      }

    }
  }

}
