@charset "UTF-8";
.m-lst-course {
  max-width: 1200px;
  margin: auto;
  @include ResponsiveForLargePC() {
    max-width: none;
  }
  ul {

    @include ClearInlineSpace;

    li {
      vertical-align: top;
      display: inline-block;
      width: 273px;
      margin: 0 3px 20px;
      .inner {
        position: relative;
        background: #fff;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
        border: 1px solid #b2b2b2;
        cursor: pointer;
        -webkit-transition: all .2s;
        -moz-transition: all .2s;
        -ms-transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s;
        &:hover {
          -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5);
          -moz-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5);
        }
        .layout-table {
          &>div {
            -webkit-border-radius: 8px 8px 0px 0px;
            -moz-border-radius: 8px 8px 0px 0px;
            border-radius: 8px 8px 0px 0px;
            vertical-align: top;
            &.ph {
              vertical-align: middle;
            }
          }
        }
        .ph {
          width: 100%;
          text-align: center;
          background: #eee;
          height: 210px;
          img {
            -webkit-border-radius: 5px 5px 0px 0px;
            -moz-border-radius: 5px 5px 0px 0px;
            border-radius: 5px 5px 0px 0px;
            max-height:100%;
            //width:100%;
          }
        }
        .text {
          padding: 10px 10px 5px 10px;
          .course-name {
            @include sts_notice() {
              left: 10px;
              top: 58%;
            }
            padding-left: 15px;
            font-size: 18px;
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          } // .course-name
          .mid {
            padding: 20px 0 3px 0px;
            position: relative;
            overflow: hidden;
            .next-chapter {
              position: absolute;
              left: 0;
              bottom: -1px;
            }
          } // .mid
          .chapter-name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            line-height: 30px;
            height: 30px;
            padding: 0px 30px 0px 5px;
            color: #e3760d;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border: #e3760d solid 1px;
            border-radius: 5px;
            background: url(#{$IMG_PATH}icon/icn_next_chapter_o.png) center no-repeat;
            -webkit-background-size: 15px;
            background-size: 15px;
            background-position: 98% center;
            text-align: center;
            &:hover {
              @include HoverOpacity();
            }
            &.finish {
              background: #b4b4b4;
              color: #fff;
              border: #555 solid 1px;
            }

            &.chapter-disabled {
              background: #fff;
              color: #888;
              border: #555 solid 1px;
              cursor: default;
              pointer-events: none;
            }
          } // .chapter-name
          .icn{
            background-image: url(#{$IMG_PATH}icon/icn_premise_base_k.png);
            background-size: 20px;
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            display: inline-block;
            vertical-align: middle;
            padding-left: 3px;
            padding-bottom: 2px;
          }
        } // .text
        .day-progress {
          display: table;
          width: 100%;
          padding: 0px 5px;
          height: 80px;
          .days {
            display: table-cell;
            width: 29%;
            vertical-align: middle;
            position: relative;
            .days-number {
              text-align: left;
              img {
                width: 17px;
                height: 17px;
                vertical-align: baseline;
                opacity: .3;
              }
              font-size: 21px !important;
              line-height: 1;
              color: $font_color_theme;
            }
            &.past {
              .days-number {
                font-size: 21px;
                color: #4ea3d4;
                &.past-day{
                  color: #ff5b46;
                }
              }
            }
            .days-text {
              color: $font_color_theme_light;
              text-align: left;
              font-size: 12px;
              display: block;
            }
          } // .days
          .progress {
            display: table-cell;
            padding-left: 5px;
            &>div:first-child {
              width: 100%;
              padding-right: 10px;
            }
            &>div:last-child {
              width: 100%;
              padding-right: 10px;
            }
            .p-progress-bar {
              position: relative;
              margin-top: -10px;
              margin-bottom: -10px;
              .bar-body {
                position: relative;
                width: 63%;
                display: inline-block;
              }
              .numbers {
                display: inline-block;
                &>span:first-child {
                  color: #4ea3d4;
                  font-size: 16px;
                }
                &>span:last-child {
                  font-size: 12px;
                }
              }
              // @include ResponsiveMaxWidth (1150) {
              //   .numbers {
              //     top: -7px;
              //     &>span:first-child {
              //       color: #4ea3d4;
              //       //font-size: 16px;
              //     }
              //   }
              // }
            }
            .progress {
              background: url(#{$IMG_PATH}icon/icn_process_k.png) center center no-repeat;
            }
            .score {
              background: url(#{$IMG_PATH}icon/icn_score_k.png) center center no-repeat;
            }
            .progress,
            .score {
              opacity: .4;
              display: inline-block;
              width: 13px;
              height: 13px;
              padding-left: 0px;
              margin-bottom: 3px;
              -webkit-background-size: 13px;
              background-size: 13px;
              vertical-align: middle;
              &+span {
                font-size: 12px;
              }
            }
          }
        } //day-progress
        @media screen and (max-width: 1366px) {
          @media screen and (max-height: 768px) {
            .ph {
              height: 150px;
            }
            .text {
              padding-top: 5px;
              .mid {
                padding-top: 15px;
                .next-chapter {
                  font-size: 13px;
                }
              }
              .course-name {
                @include sts_notice() {
                  left: 10px;
                  top: 180px;
                }
                font-size: 15px;
              }
              .chapter-name {
                font-size: 13px;
              }
            }
            .day-progress {
              .days {
                width: 35%;
                .days-number{
                  font-size: 21px;
                }
                .days-text {
                  font-size: 10px;
                }
              }
              .progress {

                &>div>div:first-child {
                  margin-bottom: -1px;
                }
                .p-progress-bar {
                  .bar-body {
                    width: 54%;
                  }
                  .numbers {
                    &>span:first-child {
                      font-size: 16px;
                    }
                    &>span:last-child {
                      font-size: 10px;
                    }
                  }
                }
              }
            }
          }
        }
        //  @media screen and (max-width: 1340px) {
        //   .day-progress {
        //     .days{
        //       width: 29%;
        //     }
        //      @media screen and (max-width: 1200px) {
        //        .days{
        //           width: 32%;
        //        }
        //      }
        //      @media screen and (max-height: 768px) {
        //           .days{
        //           width: 34%;
        //        }
        //   }
        //     .progress {
        //       .p-progress-bar {
        //         .bar-body {
        //           width: 59%;
        //         }
        //           @media screen and (max-height: 768px) {
        //           .bar-body {
        //             width: 49%;
        //           }

        //         }
        //       }
        //     }
        //   }
        // }
        // @media screen and (max-width: 1280px) {
        //   .day-progress {
        //     .progress {
        //       .p-progress-bar {
        //         .bar-body {
        //           width: 54%;
        //         }
        //         @media screen and (max-height: 768px) {
        //           .bar-body {
        //             width: 47%;
        //           }

        //         }
        //       }
        //     }
        //   }
        // }
        // @media screen and (max-width: 1200px) {
        //   .day-progress {
        //     .progress {
        //       .p-progress-bar {
        //         .bar-body {
        //           width: 50%;
        //         }
        //            @media screen and (max-height: 768px) {
        //           .bar-body {
        //             width: 49%;
        //           }

        //         }
        //       }
        //     }
        //   }
        // }
        //  @media screen and (max-width: 1160px) {
        //   .day-progress {
        //     .progress {
        //       .p-progress-bar {
        //         .bar-body {
        //           width: 47%;
        //         }
        //       }
        //     }
        //   }
        // }

        // @media screen and (max-width: 1125px) {
        //   .day-progress {
        //     .progress {
        //       .p-progress-bar {
        //         .bar-body {
        //           width: 45%;
        //         }
        //         @media screen and (max-height: 768px) {
        //           .bar-body {
        //             width: 52%;
        //           }

        //         }
        //       }
        //     }

        //   }
        // }
        //  @media screen and (max-width: 1100px) {
        //   .day-progress {
        //     .progress {
        //       .p-progress-bar {
        //         .bar-body {
        //           width: 60%;
        //         }
        //       }
        //     }
        //   }
        // }
        // @media screen and (max-width: 1090px) {
        //   .day-progress {
        //     .days{
        //       width: 26%;
        //     }
        //     .progress {
        //       .p-progress-bar {
        //         .bar-body {
        //           width: 62%;
        //         }
        //       }
        //     }
        //   }
        // }
        // @media screen and (max-width: 1025px) {
        //   .day-progress {
        //     .progress {
        //       .p-progress-bar {
        //         .bar-body {
        //           width: 58%;
        // }}}}}

      } // .inner
    } // li
    @media screen and (max-width: 1366px) {
      @media screen and (max-height: 768px) {
        li{
          width: 226px;
        }
      }}
  } // ul
} // .m-lst-course