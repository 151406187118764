@charset "UTF-8";

.page-multi-chapter-search{
    .c-search{
      padding-top:0;
      padding-bottom: 0;
      top: 12%;
        .top-area{
          position: relative;
          height:30px;
          text-align: center;
          .btn-close-modal {
              position: absolute;
              top:4px;
              right: 4px;
              background: url(#{$IMG_PATH}icon/icn_close_w.png) left center no-repeat;
              background-size: 15px;
              width: 20px;
              height: 20px;
              float: right;
              cursor:pointer;
          }
            .top-title-search{
                line-height: 30px;
                font-size: 15px;
                background-color: #8eb4e3;
                color: #fff;
            }
        }

        .top-link{
            text-align: left;
            border-bottom: 1px solid #afacab;
            margin: auto 25px;
            display: flex;
            .lesson-chapter{
              display: flex;
              font-weight: bold;
            }
            .lesson-name{
                cursor: pointer;
            }
        }

        .c-search-header{
          margin: 10px 20px !important;
          display: block !important;
          .row-c{
            text-align: center;
            width: 100%;
            .title{
              text-align: left !important;
              display: inline-block !important;
              vertical-align: top;
              margin-top: 3px;
              span{
                background: none !important;
                color: #000 !important;
                font-weight: normal !important;
                padding-left: 0;
              }

              &.tt-group {
                padding-left: 75px;
              }
              
            }
            .c-name{
              display: inline-block;
              width: 30%;
              max-width: 252px;
              vertical-align: top;
              height: auto !important;
              .error{
                padding-top: 2px;
                font-size: 12px;
                color: red;
                font-weight: bold;
                width: 100%;
                max-width: 270px;
              }
            }
            .c-btn{
              .btn-c-search{
                width: 120px !important;
                height: 29px !important;
                margin-top: 10px !important;
              }
            }
          }
        }
        .table-header{
          tr{
            z-index: 1000;
          }
        }
        .table-result{
           tr.selected {
            .col-grade-evaluation{
              background-image: url(#{$IMG_PATH}icon/icn_check_w.png) !important;
              background-size: 25px !important;
              background-repeat: no-repeat !important;
              background-position: 50% !important;
            }
          }
            .checkbox-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: -8px;
              label {
                // padding-left: 20px;
                &::after {
                  top: 2px;
                  left: 15px;
                  width: 14px !important;
                  height: 14px !important;
                }
                &::before {
                  top: 5px;
                  left: 17px;
                  width: 11px !important;
                  height: 5px !important;
                }
              }
            }
            .col-course-id{
              width: 5% !important;
            }
            .col-lesson-name{
              width: 25% !important;
            }
            .col-chapter-name{
              width: 25% !important;
            }
            .col-content-type-name{
              width: 25% !important;
            }
            .col-grade-evaluation{
              background-image: url(#{$IMG_PATH}icon/icn_check_k.png) !important;
              width: 20% !important;
              background-size: 25px !important;
              background-repeat: no-repeat !important;
              background-position: 50% !important;
            }
            .col-grade-evaluation.hover{
              background-image: url(#{$IMG_PATH}icon/icn_check_w.png) !important;
              background-size: 25px !important;
              background-repeat: no-repeat !important;
              background-position: 50% !important;
            }
        }
        .ps-scrollbar-y-rail{
          z-index: 1000;
        }
      .footer-area{
          background: #fff;
          width: 100%;
          height: 30px;
          padding: 10px 0;
          text-align: center;
          a{
            margin-right: 10px;
          }
          .btn-modal-back-c{
              padding: 0 15px;
              width: 10%;
              background-color: #FFFFFF;
              text-align: center;
              -moz-border-radius: 0px;
              border-radius: 2px;
              border: 1px solid #A0A0A0;
              color: #6E6E6E;
              font-weight: normal;
              line-height: 30px;
              font-size: 16px;
          }
          .btn-modal-submit-c{
              padding: 0 15px;
              width: 10%;
              background-color: #FFFFFF;
              text-align: center;
              -moz-border-radius: 0px;
              border-radius: 2px;
              border: 1px solid #DC780A;
              color: #DC780A;
              font-weight: normal;
              line-height: 30px;
              font-size: 16px;
          }
      }
    }

}