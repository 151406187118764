@charset "UTF-8";

.modal-extraction-status {
  .m-modal {
    .modal-inner {
      &.u-search {
        padding-left: 0px;
        padding-right: 0px;
        width: 900px;
        text-align: center;
        background: #fff;
        z-index: 11000;
        padding-bottom: 5px;
        padding-top: 5px;

        .top-area {
          position: relative;
          height: 40px;
          background-color: white;
          text-align: center;
          width: 800px;
          margin: auto;

          .btn-close-modal {
            position: absolute;
            top: 4px;
            right: -45px;
            background: url(#{$IMG_PATH}icon/icn_close_k.png) left center no-repeat;
            background-size: 15px;
            width: 20px;
            height: 20px;
            float: right;
            cursor: pointer;

            &:hover {
              opacity: .7;
            }
          }

          .top-title {
            color: gray;
            font-weight: bold;
            line-height: 30px;
            font-size: 15px;
            text-align: center;
          }
        }

        .u-search-header {
          margin: auto;
          width: 800px;
          text-align: left;

          .description-row {

            .title-description {
              margin-bottom: 10px;
              font-weight: bold;
              border-bottom: 2px solid gray;
            }

            .text-subdescription {
              margin-bottom: 10px;
              margin-left: 5px;

              .title-user,
              .title-type {
                margin-right: 1em;
              }

              .title-course {
                margin-right: 3em;
              }

              .list-detail:before {
                content: ":";
                padding-right: 5px;
              }

              .list-detail.end-day:before {
                content: "";
              }
            }

          }

          .description-row:not(:first-child) {
            margin: 20px 0;
          }

          .input-group-name,
          .input-user-name {
            margin-left: 10px;
            height: 33px;
            width: 250px;

            .input-text {
              width: 100%;
              border: 1px solid #c8c8c8;
              padding: 3px;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              max-width: 100%;
            }
          }

          .u-btn {
            margin-left: 10px;

            .btn-u-search {
              width: 55px;
              height: 30px;

              button {
                height: 30px;
              }

            }
          }

          table td {
            padding-right: 10px;
          }

        }

        .error {
          font-size: 12px;
          color: red;
          font-weight: bold;
          text-align: left;
        }

        .page-inner {
          margin: auto;
          background: $background_theme_color_contents;
          width: 800px;
          height: 100%;

          &.u-search-modal {
            height: 175px;
            margin: auto;
            padding: 5px;
            margin: 10px auto;

            .page-contents {
              width: 100%;
              height: 100%;

              .course-header {
                padding-bottom: 5px;

                .except {
                  height: 71px;
                  display: none;
                }

                .download {
                  position: absolute;
                  right: 0px;
                  margin-top: 5px;
                  margin-right: 10px;
                  min-height: 30px;
                  padding-bottom: 5px;
                }

                .result-txt {
                  margin-top: 5px;
                  margin-left: 10px;
                  min-height: 30px;
                  display: flex;
                  align-items: center;
                }
              }
            }

            .no-data {
              height: 263px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .course-result {
              @include search_table_f;
              margin: 0px 5px;
              height: 164px;
              padding: 0px;
              background: white;
              text-align: left;

              .ps-scrollbar-y-rail {
                right: 0px;
                z-index: 6;
              }

              thead > tr {
                z-index: 5;
              }

              .checkbox-btn {
                display: flex;
                justify-content: center;
                height: 20px;

                label {
                  padding-left: 0px;
                }
              }

              .table-result {
                table-layout: fixed;
                
                
                .col-checkbox {
                  width: 10%;
                  // min-width: 157px;
                }

                .col-user-name {
                  width: 35%;
                  // min-width: 113px;
                }

                .col-user-group-name {
                  width: 35%;
                  // min-width: 227px;
                }

                .col-menber {
                  width: 20%;
                  // min-width: 227px;
                }
              }

              //table-result

              .table-header {
                table-layout: auto;

                tr {
                  width: 100%;
                  position: fixed;

                  th {
                    padding: 5px 0;
                    box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                  }
                }

                thead {
                  .header-sort {
                    cursor: pointer;

                    div.col-sort {
                      display: inline-flex;

                      .img {
                        background-image: url(#{$IMG_PATH}icon/icn_pullupdown_k.png);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 26px;
                        width: 27px;
                        padding-left: 7px;

                        &.header-sort-up {
                          cursor: pointer;
                          display: inline-flex;
                          width: 26px;
                          background-position: center;
                          background-repeat: no-repeat;
                          background-image: url(#{$IMG_PATH}icon/icn_pullup_k.png);
                          background-size: 13px;
                          padding-left: 7px;
                        }

                        &.header-sort-down {
                          cursor: pointer;
                          display: inline-flex;
                          width: 26px;
                          background-position: center;
                          background-repeat: no-repeat;
                          background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
                          background-size: 13px;
                          padding-left: 7px;
                        }
                      }
                    }
                  }
                }
              }
              
              .col-sort {
                text-align: left;
                margin-left: 5px;
              }

              .col-course-name {
                text-align: left;
                width: 25%;
              }
              .col-course-group-name {
                text-align: left;
              }
              //table-header
            }

            //course-result
          }
        }

        .footer-area {
          background: #fff;
          width: 100%;
          height: 30px;
          padding: 10px 0;
          text-align: center;

          a {
            margin-right: 10px;
          }

          .btn-modal-submit-c {
            padding: 0 15px;
            width: 10%;
            background-color: #FFFFFF;
            text-align: center;
            -moz-border-radius: 0px;
            border-radius: 2px;
            border: 1px solid #DC780A;
            color: #DC780A;
            font-weight: normal;
            line-height: 30px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
