@charset "UTF-8";
.page-external-tool-v1p3-search{
  height: 100%;
  .external-tool-v1p3-search-header {
    background: #fff;
    padding: 0 20px;
    .inner {
      min-height: 34px;
      padding: 20px 0;
      position: relative;
      overflow: hidden;
      .back {
        img {
          width: 20px;
          height: 20px;
          vertical-align: -webkit-baseline-middle;
        }
      }
      a {
        &.btn-new-external-tool {
          position: relative;
          background: #fff;
          border: 1px solid #DC780A;
          width: 150px;
          height: 34px;
          line-height: 34px;
          font-size: 16px;
          color: #DC780A;
          font-weight: normal;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
          float: right;
          text-align: center;
        
        }
      
      }
    }
  }
  .m-form-search {
    padding: 0;
    .form-group {
      margin: 0 20px;
      .inner {
         width: 80%;
         padding: 20px 0;
         margin: 0 auto;
         .form-row {
           min-height: 1px;
           display: block;
           margin-left: -20px;
           margin-bottom: -20px;
           .layout {
             &>* {
               width: 100%;
               margin-bottom: 0;
             }
           }
         }
         tr {
           height: 50px;
           input {
             height: 32px;
           }
           
           &:last-child {
             height: 60px;
           }
         }
         .td-1 {
          width: 10%;
          text-align: right;
          padding-right: 5px;
         }
         .td-2, td-4 {
           width: 30%;
         }
         .td-3 {
           text-align: right;
           width: 20%;
           padding-right: 5px;
         }
         .td-5 {
           text-align: center;
         }
       }
    }
    .error {
      margin: 1px 0 1px 0;
      font-size: 12px;
      color: red;
      font-weight: bold;

    }
  }
  .page-inner {
    background: #bae3f9;
    height: 100%;
    .page-contents {
      width: 100%;
      height: 100%;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      .external-tool-v1p3-header {
        padding-bottom: 5px;
        .except {
          height: 71px;
          display: none;
        }
        .result-txt {
          margin-top: 10px;
          margin-left: 10px;
          min-height: 25px;
          display: inline-block;
          align-items: center;
        }
      }
      .external-tool-v1p3-result {
        @include search_table_f;
        padding: 0;
        margin: 0 10px;
        position: relative;
        height: calc(100% - 256px);
        .table-header{
           table-layout: auto;
           tr {
            width: 100%;
            position: fixed;
            th{
              padding: 5px;
              box-sizing:border-box;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
            }
          }
        }
        .table-result{
          table-layout: auto;
          tr{
            td:not(.row-ope){
              box-sizing:border-box;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
            }
            
            td {
              width:19%;
              white-space: nowrap;
              overflow: hidden;
              max-width: 0;
              text-overflow: ellipsis;
              &:first-child, &:last-child{
                width:12%;
              }
            }
            
          }
        }
        .table-result-nodata {
          height: 100%;
          tr {
            td {
              border: 0px solid #fafafa;
              color: #AFAFAF;
              box-sizing: border-box;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
            }
          
          }
        }
        .external-tool-v1p3-list-outer {
          width: 100%;
          height: 100%;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
        } 
        .external-tool-v1p3-list-inner{
          height: 99.9%;
          background-color: #fff;
        }
        .scroll-area {
          &.ps-active-y {
            padding-right: 16.2px;
            margin-right: -16.2px;
          }
          .ps-scrollbar-y-rail{
            right: 16.2px;
          }
          height: 100%;
        } // .scroll-area
      }
    }
  }
  
  button {
    &.btn-submit-search-new {
      background: url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
      -webkit-background-size: 20px;
      background-size: 20px;
      background-position-x: 50%;
      color: #468cc8;
      border: 1px solid #82BEF0;
      background-color: #82BEF0;
      font-weight: normal;
      width: 130px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 5px;
      font-size: 16px;
      padding: 0;
    }
  }
  a{
    &.btn-submit-setting {
      background: url(#{$IMG_PATH}icon/icn_edit_k.png) 20px center no-repeat;
      -webkit-background-size: 80%;
      background-size: 80%;
      background-position-x: 50%;
      font-weight: normal;
      width: 34px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      font-size: 16px;
      padding: 0;
    }
    &.btn-submit-delete {
      background: url(#{$IMG_PATH}icon/icn_trash_k.png) 20px center no-repeat;
      -webkit-background-size: 80%;
      background-size: 80%;
      background-position-x: 50%;
      font-weight: normal;
      width: 34px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      font-size: 16px;
      padding: 0;
    }
  }
}

.lti-external-tool-search-tooltip-v1p3 {
    position: absolute;
    padding: 10px;
    z-index: 101;
    font-size: 12px;
    word-break: break-all;
    line-height: 1.6em;
    color: #fff;
    border-radius: 5px;
    background: #000;
    min-height: 100px;
    min-width: 12%;
    max-width: 20%!important;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    white-space: pre-wrap;
}