@charset "UTF-8";

.m-modal {
  &.modal-mail-body {
    background: none;
    padding: 0px;
    .modal-inner {
      &.modal-inner-mail-body {
        padding: 25px;
        width: 35%;
        min-width: 600px;
        height: 80%;
        top: 10%;
        .title {
          p {
            color: #82bef0;
            font-size: 24px;
            line-height: 30px;
          }
        }
        .details {
          display: flex;
          flex-direction: column;
          height: 95%;
          .header {
            display: flex;
            .mail-title {
              text-align: left;
              color: #82bef0;
              border: solid #82bef0 1.5px;
              font-size: 14px;
              margin-top: 20px;
              padding: 8px 15px;
              width: 100%;
            }
            .hint-area {
              position: relative;
              .hint-btn{
                background: url(#{$IMG_PATH}icon/icn_hints_bk.png) no-repeat;
                background-position: 50%;
                -webkit-background-size: 30px;
                background-size: 30px;
                width: 35px;
                margin: 20px 0 0 10px;
                display: block;
                height: calc(100% - 20px);
              }
              .hint-content{
                width: 20vw;
                background-color: #d3edfa;
                position: absolute;
                top: 20px;
                left: 70px;
                text-align: left;
                padding: 15px;
                &.hide {
                  display: none;
                }
                .mail-tooltip {
                  margin-top: 20px;
                  position: relative;
                  ul {
                    word-break: break-all;
                    width: calc(100% - 10px);
                    max-height: calc(60vh - 95px);
                    padding-right: 20px;
                  }
                }
              }
            }
          }
          .main {
            flex: 1 1 auto;
            margin-top: 20px;
            position: relative;
            .mail-body {
              width: calc(100% - 20px);
              height: 100%;
              background-color: #fff;
              word-break: break-all;
              white-space: pre-wrap;
              text-align: left;
              padding-right: 30px;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          .footer {
            margin-top: 20px;
            .btn-area {
              border-top: 0.5px solid #777777;
              padding-top: 20px;
              .btn-gray {
                color: #a0a0a0;
                background: #fff !important;
                border: 1px solid #a0a0a0;
                height: 38px;
                width: 180px;
              }
            }
          }
        }
      }
    }
  }
}
