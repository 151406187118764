@charset "UTF-8";

.page-wat-ref {
  height: 100%;

  .m-search-c-inner {
    iframe {
      width: 100vw;
      height: 100vh;
    }
  }

  .page-inner {
    background: $background_theme_color_contents;
    height: 100%;

    .page-contents {
      width: 100%;
      height: 100%;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      .face-ref-header {
        background: #fff;
        position: relative;
        padding-top: 20px;
      }

      .m-form-search {

        padding-top: 0;

        .form-btns {
          display: flex;
          justify-content: center;

          > div {
            margin: 0px;
          }
        }

        .back {
          @include HoverOpacity();
          position: absolute;
          padding: 0px 0 0 20px;
          float: right;
          text-align: center;

          img {
            padding-top: 12px;
            width: 20px;
            height: 20px;
            vertical-align: -webkit-baseline-middle;
          }
        }

        .error {
          margin: 1px 0 1px 0;
        }

        .form-group {

          .layout {
            min-width: 1180px;
            margin: auto;
            display: flex;
            justify-content: center;
          }

          .layout:first-child {
            //padding-bottom: 10px;
          }

          .inpt {
            display: flex;
            align-items: center;
          }

          .inner-resize {
            padding: 0px;
            min-width: 890px;
            margin: 0 auto;
            max-width: 1500px;

            .label-dialog-firing {
              display: flex;
              align-items: center;

              .c-name-label {}

              .c-name {
                display: flex;
                align-items: center;

                span {
                  white-space: pre !important;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  //padding-top: 10px;
                  max-width: 173px;
                }
              }

            }

            .col1 {
              width: 30%;
            }

            .col2 {
              width: 28%;
              padding-left: 0px;
            }

            .col3,
            .col4 {
              width: 27%;
              padding-left: 0px !important;

              .title {
                display: flex;
                min-width: 85px;
                justify-content: flex-end;
                align-items: center;
                padding-right: 5px;

                &.size-l {
                  min-width: 85px;

                  &.st-left {
                    justify-content: flex-start;
                    padding-left: 20px;
                    min-width: 98px;
                  }
                }

                &.no-width {
                  min-width: 22px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding-right: 0px;
                }

              }

              .c-name {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: flex;
                align-items: center;

                span {
                  white-space: pre !important;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  padding-top: 10px;
                }
              }

              .select-upload {
                width: 100%;
                min-width: 95px;

                .sel {
                  width: 100%;
                  height: 32px;
                  overflow: hidden;

                  select {
                    height: 32px;
                  }
                }

                .sel-txt {
                  margin-top: 0;
                  font-weight: normal;
                  line-height: 30px;
                }

              }
            }

            .col4 {
              width: 27% !important;
              padding-left: 22px !important;
            }

          }

          .layout:nth-child(2) {

            .col1,
            .col2 {
              width: 50%;

              .td-input-cal {

                input {
                  width: 150px;
                }

              }

              .inpt {
                .title {
                  padding-right: 5px;
                }
              }

              img {
                max-width: 30px;
                min-width: 30px;
                width: 30px;
                max-height: 30px;
                min-height: 30px;
                height: 30px;
              }

              .td-input-cal {
                display: flex;
                height: 32px;

                .m-form-search .input-text {
                  width: 40px;
                }

                a {
                  display: block;
                  padding-left: 5px;
                }

                span {
                  padding: 0 5px;
                  line-height: 35px;
                }

              }

            }
          }

          //.layout:nth-child(2)

          .layout:last-child {

            //padding: 20px 0;

            .col1 {
              display: flex;
              width: 100%;
            }

            .inpt {
              &:last-child {
                > .inpt {
                  padding-left: 3rem;
                }
              }
            }

            .title.size-l {
              padding-top: 10px;
            }

            label {
              padding-right: 5px;
            }

            .status-chk {
              display: flex;
              padding-top: 10px;
              padding-left: 10px;
            }

            .status-rd-ver {
              display: flex;
              padding: 10px 0 0 0;
            }

            .rd-btn {
              padding-left: 10px;

              &:last-child {
                padding-left: 5px;
              }
            }

            .input-raido {
              height: 20px
            }

            .inner-resize {
              padding: 0px;
              min-width: 890px;
              margin: 0 auto;
              max-width: 1500px;

              .label-dialog-firing {
                display: flex;
                align-items: center;

                .c-name {
                  display: flex;
                  align-items: center;
                }

                .label-user-name,
                .label-course-name {
                  margin-top: 9px;
                }

              }

            }

          }

          //last-child
        }

        //.form-group
      }

      //.m-form-search

      input,
      a {
        vertical-align: middle;
      }
    }

    .course-search-header {
      background: #fff;
      padding: 0 40px 20px;
      height: 26px;

      .page-switch {
        width: 203px;
        height: 28px;
        margin-bottom: 25px;
        overflow: hidden;
        cursor: pointer;
      }

      .page-switch.activeBtn img {
        transform: translateY(-28px);
      }

      .inner {
        min-height: 34px;
        padding: 20px 0 0 0;
        position: relative;
        overflow: hidden;

        .back {
          @include HoverOpacity();
          position: absolute;
          left: 0;

          img {
            width: 20px;
            height: 20px;
            vertical-align: -webkit-baseline-middle;
          }
        }

        .btn {
          float: right;
          text-align: center;
        }

        .select-upload {
          float: right;
          margin-right: 5px;

          .sel {
            background-color: white;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 0px;
            color: #468cc8;
            border: 1px solid;
            width: 200px;
          }

          .sel.sel-size-auto {
            height: 36px;
            width: 170px !important;

            .sel-txt {
              padding-left: 10px;
              padding-right: 17px;
              background-size: 18px;
              background-image: url(#{$IMG_PATH}icon/icn_selectbtn_bottom_b.png);

              span {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
          }
        }
      }
    }

    .site-header {
      display: flex;
      padding-bottom: 5px;

      .except {
        height: 71px;
        display: none;
      }

      .download {
        position: absolute;
        right: 0px;
        margin-top: 5px;
        margin-right: 10px;
        min-height: 30px;
        padding-bottom: 5px;
      }

      .result-txt {
        margin-top: 5px;
        margin-left: 10px;
        min-height: 30px;
        display: flex;
        align-items: center;
      }

    }

    .site-result {
      @include search_table_f;
      padding: 0px !important;
      margin-right: 10px;
      margin-left: 10px;
      //height: 61%;
      height: 60%;
      position: relative;

      @media screen and (max-height: 980px) {
        height: 65%;
      }

      @media screen and (max-height: 930px) {
        height: 64%;
      }

      @media screen and (max-height: 900px) {
        height: 63%;
      }

      @media screen and (max-height: 850px) {
        height: 61%;
      }

      @media screen and (max-height: 820px) {
        height: 59%;
      }

      @media screen and (max-height: 800px) {
        height: 58%;
      }

      @media screen and (min-width: 751px) and (max-height: 780px) {
        height: 58%;
      }

      @media screen and (max-height: 750px) {
        height: 51%;
      }

      @media screen and (max-height: 700px) {
        height: 50%;
      }

      @media screen and (max-height: 650px) {
        height: 48%;
      }

      @media screen and (max-height: 635px) {
        height: 46%;
      }

      @media screen and (max-height: 600px) {
        height: 44%;
      }

      .table-header {
        table-layout: auto;

        .col-coruse-name {
          min-width: 84px;
          // width: 12%;
        }

        .col-lesson-name {
          min-width: 84px;
          // width: 10%;
        }

        .col-chapta-name {
          min-width: 90px;
          // width: 12%;
        }

        .col-user-id {
          min-width: 100px;
          // width: 14%;
        }

        .col-user-name {
          min-width: 84px;
          // width: 12%;
        }

        .col-acquire-num {
          min-width: 54px;
          // width: 8%;
        }

        .col-startwat-date {
          min-width: 100px;
          // width: 14%;
        }

        .col-endwat-date {
          min-width: 100px;
          // width: 6%;
        }

        .col-conf-status {
          min-width: 100px;
          // width: 6%;
        }

        .col-attention-mark {
          min-width: 60px;
          // width: 6%;
        }

        .ucol-btn {
          min-width: 50px;
          // width: 6%;
        }

        tr {
          width: 100%;
          position: fixed;

          th {
            padding: 5px 0;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
          }
        }
      }

      .table-result-nodata {
        height: 100%;

        tr {
          td {
            border: 0px solid #fafafa;
            color: #AFAFAF;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;

            span {
              position: relative;
            }
          }
        }
      }

      .table-result {
        table-layout: auto;

        .col-coruse-name {
          width: 12%;
        }

        .col-lesson-name {
          width: 10%;
        }

        .col-chapter-name {
          width: 10%;
        }

        .col-user-id {
          width: 12%;
        }

        .col-user-name {
          width: 12%;
        }

        .col-acquire-num {
          width: 8%;
        }

        .col-startwat-date {
          width: 13%;
        }

        .col-endwat-date {
          width: 13%;
        }

        .col-conf-status {
          width: 11%;
          white-space: nowrap;

          // width: 6%;
          .status-ailment {
            padding-left: 11px;
            padding-right: 10px;
            background: url(#{$IMG_PATH}icon/icn_concon_k.png) center / 19px no-repeat;
          }

          .multi-space-ellipsis {
            white-space: pre !important;
            overflow: hidden;
            text-overflow: ellipsis !important;
            word-wrap: normal !important;
          }
        }

        .col-attention-mark {
          min-width: 60px;
          // width: 6%;
        }

        span.icon-submit-attention img {
          width: 25px;
        }

        .ucol-btn {
          width: 4.2%;
          min-width: 50px;
        }

        tr {
          td:not(.row-ope) {
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
          }

          .red {
            color: #e7001f;
          }

          td {
            padding: 4px;
          }
        }
      }

      table {

        tr:not(:first-child):hover,
        tr.selected,
        .hover {
          .btn-submit-delete {
            background: url(#{$IMG_PATH}icon/icn_trash_w.png) 20px center no-repeat;
            -webkit-background-size: 60%;
            background-size: 60%;
            background-position-x: 50%;
          }

          .btn-submit-setting {
            background: url(#{$IMG_PATH}icon/icn_edit_w.png) 15px center no-repeat;
            -webkit-background-size: 60%;
            background-size: 60%;
            background-position-x: 50%;
          }
        }

        tr.selected,
        .hover {
          .red {
            color: #d10064;
          }
        }

        tr:hover {
          .red {
            color: #d10064;
          }
        }
      }
    }

    .site-list-inner {
      height: 100%;
      background-color: #fff;
      position: relative;

      .no-data {
        &.hide {
          display: none;
        }

        max-height: 100%;
        height: 100px !important;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(-50%, -50%);
      }

    }

    .site-list-outer {
      width: 100%;
      height: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    .scroll-area {
      &.ps-active-y {
        padding-right: 16.2px;
        margin-right: -16.2px;
      }

      .ps-scrollbar-y-rail {
        right: 16.2px;
      }

      height: 100%;
    }

    // .scroll-area
  }

  input.btn,
  a.btn {
    &.btn-submit-download {
      float: right;
      font-weight: initial;
      text-align: center;
      width: auto;
      padding: 6px 19px;
      color: #000;
      border: 1px solid #c8c8c8;
      margin: -3px 0;
      line-height: 22px;
      background: #fff;
      color: #6E6E6E;

      img {
        width: 20px;
        height: 20px;
        padding-right: 7px;
      }
    }

    &.btn-new-site {
      width: 150px !important;
    }

    &.btn-submit-upload {
      background: url(#{$IMG_PATH}icon/icn_upload_w.png) 20px center no-repeat;
      -webkit-background-size: 20px;
      background-size: 20px;
      color: #468cc8;
      border: 1px solid;
      background-color: white;
      font-weight: normal;
      width: auto;
      height: 34px;
      line-height: 34px;
      text-align: center;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 0px;
      font-size: 16px;
      padding: 0;
    }

    &.btn-submit-search-new {
      background: url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
      -webkit-background-size: 20px;
      background-size: 20px;
      background-position-x: 50%;
      color: #468cc8;
      border: 1px solid #82BEF0;
      background-color: #82BEF0;
      font-weight: normal;
      width: 130px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 5px;
      font-size: 16px;
      padding: 0;
    }

    &.btn-submit-setting {
      background: url(#{$IMG_PATH}icon/icn_edit_k.png) 15px center no-repeat;
      -webkit-background-size: 60%;
      background-size: 60%;
      background-position-x: 50%;
      font-weight: normal;
      width: 35px;
      height: 23px;
      line-height: 35px;
      text-align: center;
      font-size: 16px;
      padding: 0;
    }

    &.btn-submit-delete {
      background: url(#{$IMG_PATH}icon/icn_trash_k.png) 20px center no-repeat;
      -webkit-background-size: 80%;
      background-size: 80%;
      background-position-x: 50%;
      font-weight: normal;
      width: 34px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      font-size: 16px;
      padding: 0;
    }

  }
}

.btn-download-modal {
  background-image: url(#{$IMG_PATH}icon/icn_download_o.png);
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px 20px;
  padding-left: 40px;
}
