@charset "UTF-8";

.m-chapter-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, .5);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: none;
  opacity: 0;

  .m-chapter-modal-inner {
    width: 100%;
    height: 100%;
    position: relative;

    iframe {
      width: 100%;
      height: 100%;
    }

    .btn-close {
      position: absolute;
      display: block;
      width: 50px;
      height: 50px;
      right: 10px;
      top: 10px;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      line-height: 50px;
      text-align: center;
      background: url('#{$IMG_PATH}icon/icn_close_k.png') center no-repeat;
      background-color: #fff;
      background-size: 17px;
    }

  }

}