@charset "UTF-8";

.global-menu {
  background: #2E2624;

  .global-menu-inner {
    position: relative;
    height: 100%;

    .global-menu-box {
      position: relative;
      height: 100%;
      overflow: hidden;
      -webkit-perspective: 500;
      -moz-perspective: 500;
      -ms-perspective: 500;
      perspective: 500;
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      -ms-transform-style: preserve-3d;
      -o-transform-style: preserve-3d;
      transform-style: preserve-3d;

      .sp-hidden {
        left: 25px;
        margin-top: 20px;
        display: block;
        text-align: center;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        .switch-group {
          margin: 0 auto;
          width: 50px;
          height: 65px;
          position: relative;

          .icn {
            display: block;
            text-align: center;
            -webkit-transition: opacity .3s;
            -moz-transition: opacity .3s;
            -ms-transition: opacity .3s;
            -o-transition: opacity .3s;
            transition: opacity .3s;
            opacity: 1;
            height: 40px;
            width: 40px;
            margin: 0 auto;
          }

          .mini-icon {
            position: absolute;
            display: block;
            text-align: center;
            -webkit-transition: opacity .3s;
            -moz-transition: opacity .3s;
            -ms-transition: opacity .3s;
            -o-transition: opacity .3s;
            transition: opacity .3s;
            opacity: 1;
            height: 21px;
            width: 21px;
            bottom: 0;

            &:hover {
              opacity: .7;
            }
          }

          .mini-left {
            background: url(#{$IMG_PATH}icon/gl_icn_prev.png) center center no-repeat;
            -webkit-background-size: contain;
            background-size: contain;
            left: 0;
          }

          .mini-right {
            background: url(#{$IMG_PATH}icon/gl_icn_next.png) center center no-repeat;
            -webkit-background-size: contain;
            background-size: contain;
            right: 0;
          }

          .student_bottom {
            background: url(#{$IMG_PATH}icon/gl_icn_bg_training.png) center center no-repeat;
            -webkit-background-size: contain;
            background-size: contain;

            &:hover {
              opacity: .7;
            }
          }

          .instructor_bottom {
            background: url(#{$IMG_PATH}icon/gl_icn_bg_ta.png) center center no-repeat;
            -webkit-background-size: contain;
            background-size: contain;

            &:hover {
              opacity: .7;
            }
          }

          .courses_bottom {
            background: url(#{$IMG_PATH}icon/gl_icn_bg_course.png) center center no-repeat;
            -webkit-background-size: contain;
            background-size: contain;

            &:hover {
              opacity: .7;
            }
          }

          .users_bottom {
            background: url(#{$IMG_PATH}icon/gl_icn_bg_setting.png) center center no-repeat;
            -webkit-background-size: contain;
            background-size: contain;

            &:hover {
              opacity: .7;
            }
          }

          .educationPackManager_bottom {
            background: url(#{$IMG_PATH}icon/gl_icn_bg_ta.png) center center no-repeat;
            -webkit-background-size: contain;
            background-size: contain;

            &:hover {
              opacity: .7;
            }
          }

        }
      }

      .global-menu-list {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: none;

        .icn {
          width: 45px;
          height: 45px;
          margin: 0 auto;
          img {
            width: 100%;
          }
        }

        .home {
          background: url(#{$IMG_PATH}icon/gl_icn_home_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }

        .portal {
          background: url(#{$IMG_PATH}icon/icon_portal_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }

        .forum {
          background: url(#{$IMG_PATH}icon/gl_icn_forum_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }

        .catalogue {
          background: url(#{$IMG_PATH}icon/gl_icn_catalogue_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }

        .courseListRef {
          background: url(#{$IMG_PATH}icon/icn_courselist_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }
        
        .attend-progress{
          background: url(#{$IMG_PATH}icon/icn_attend_progress_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }

        .profile {
          background: url(#{$IMG_PATH}icon/icn_portfolio_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }

        .title-list {
          background: url(#{$IMG_PATH}icon/gl_icn_titleslist_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }

        .question-bank {
          background: url(#{$IMG_PATH}icon/icn_question_bank_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }

        .approval {
          background: url(#{$IMG_PATH}icon/gl_icn_approval_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;

          &.on::after {
            content: " ";
            display: block;
            position: absolute;
            width: 9px;
            height: 9px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            background: #ee7800;
            right: 20px;
            top: 15px;
          }
        }


        .face-auth {
          background: url(#{$IMG_PATH}icon/icn_face_certification_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }
        .learning-progress {
          background: url(#{$IMG_PATH}icon/gl_icn_portfolio02_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }

        .top {
          background: url(#{$IMG_PATH}icon/icn_sitetop_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }

        .contents{
          background: url(#{$IMG_PATH}icon/icn_contents_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }

        .contentcheck {
          background: url(#{$IMG_PATH}icon/icn_contentcheck_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }
        
        .user-edit {
          background: url(#{$IMG_PATH}icon/gl_icn_useredit_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }

        .course-edit {
          background: url(#{$IMG_PATH}icon/gl_icn_courseedit_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }

        .site-management {
          background: url(#{$IMG_PATH}icon/gl_icn_system_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }

        .system {
          background: url(#{$IMG_PATH}icon/icon_system_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }

        .mail {
          background: url(#{$IMG_PATH}icon/icn_mailsend_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }
        
        .education {
          background: url(#{$IMG_PATH}icon/icn_education_pack_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }
        
        .education-manage {
          background: url(#{$IMG_PATH}icon/icn_education_manage_w.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }

        .sale {
          -webkit-background-size: contain;
          background-size: contain;
        }

        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        -o-animation-fill-mode: forwards;
        animation-fill-mode: forwards;

        &.current {
          display: block;
          -webkit-animation: fadeIn .2s ease-out;
          -moz-animation: fadeIn .2s ease-out;
          -o-animation: fadeIn .2s ease-out;
          animation: fadeIn .2s ease-out;
        }

        &.before {
          -webkit-animation: fadeOut .2s ease-out;
          -moz-animation: fadeOut .2s ease-out;
          -o-animation: fadeOut .2s ease-out;
          animation: fadeOut .2s ease-out;
          display: none;
        }

        &.after {
          -webkit-animation: fadeOut .2s ease-out;
          -moz-animation: fadeOut .2s ease-out;
          -o-animation: fadeOut .2s ease-out;
          animation: fadeOut .2s ease-out;
          display: none;
        }

        @-webkit-keyframes fadeIn {
          0% { display: none; opacity: 0; }
          1% { display: block; opacity: 0; }
          100% { display: block; opacity: 1; }
        }
        @-moz-keyframes fadeIn {
          0% { display: none; opacity: 0; }
          1% { display: block; opacity: 0; }
          100% { display: block; opacity: 1; }
        }
        @-o-keyframes fadeIn {
          0% { display: none; opacity: 0; }
          1% { display: block; opacity: 0; }
          100% { display: block; opacity: 1; }
        }
        @keyframes fadeIn {
          0% { display: none; opacity: 0; }
          1% { display: block; opacity: 0; }
          100% { display: block; opacity: 1; }
        }

        @-webkit-keyframes fadeOut {
          0% { display: block; opacity: 1; }
          99% { display: block; opacity: 0; }
          100% { display: none; opacity: 0; }
        }
        @-moz-keyframes fadeOut {
          0% { display: block; opacity: 1; }
          99% { display: block; opacity: 0; }
          100% { display: none; opacity: 0; }
        }
        @-o-keyframes fadeOut {
          0% { display: block; opacity: 1; }
          99% { display: block; opacity: 0; }
          100% { display: none; opacity: 0; }
        }
        @keyframes fadeOut {
          0% { display: block; opacity: 1; }
          99% { display: block; opacity: 0; }
          100% { display: none; opacity: 0; }
        }

        li.base-red {
            &:hover {
              a {
                text-decoration: none;
              }
              background-color: #4C3C41;
            }
        }

        li.base {
            &:hover {
              a {
                text-decoration: none;
              }
              background-color: #435057;
            }
        }

        li {
          width: 100px;
          height: 80px;

          a {
            position: relative;
            display: block;
            text-align: center;
            height: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            padding-top: 15px;

            .icn {
              display: block;
              text-align: center;
              -webkit-transition: opacity .3s;
              -moz-transition: opacity .3s;
              -ms-transition: opacity .3s;
              -o-transition: opacity .3s;
              transition: opacity .3s;
              opacity: 1;
            }

            .name {
              display: block;
              text-align: center;
              -webkit-transition: opacity .3s;
              -moz-transition: opacity .3s;
              -ms-transition: opacity .3s;
              -o-transition: opacity .3s;
              transition: opacity .3s;
              color: #fff;
              opacity: 1;
              font-size: 11px;

              &.current {
                color: #54C3F1;
              }

              &.current-red {
                color: #EC6D7B;
              }
            }

            &.current-red {
              color: #EC6D7B;

              .name ,.icn {
                opacity: 1;
              }

              .home {
                background: url(#{$IMG_PATH}icon/gl_icn_home_r.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .forum {
                background: url(#{$IMG_PATH}icon/gl_icn_forum_r.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .catalogue {
                background: url(#{$IMG_PATH}icon/gl_icn_catalogue_r.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .courseListRef {
                background: url(#{$IMG_PATH}icon/icn_courselist_r.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .face-auth {
                background: url(#{$IMG_PATH}icon/icn_face_certification_r.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .learning-progress {
                background: url(#{$IMG_PATH}icon/gl_icn_portfolio02_r.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .title-list {
                background: url(#{$IMG_PATH}icon/gl_icn_titleslist_r.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .question-bank {
                background: url(#{$IMG_PATH}icon/icn_question_bank_w.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .approval {
                background: url(#{$IMG_PATH}icon/gl_icn_approval_r.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;

                &.on::after {
                  content: " ";
                  display: block;
                  position: absolute;
                  width: 9px;
                  height: 9px;
                  -webkit-border-radius: 5px;
                  -moz-border-radius: 5px;
                  border-radius: 5px;
                  background: #ee7800;
                  right: 20px;
                  top: 15px;
                }
              }

              .user-edit {
                background: url(#{$IMG_PATH}icon/gl_icn_useredit_r.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .course-edit {
                background: url(#{$IMG_PATH}icon/gl_icn_courseedit_r.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .site-management {
                background: url(#{$IMG_PATH}icon/gl_icn_system_r.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .system {
                background: url(#{$IMG_PATH}icon/icon_system_r.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .mail {
                background: url(#{$IMG_PATH}icon/icn_mailsend_r.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }
              
              .education-manage {
                background: url(#{$IMG_PATH}icon/icn_education_manage_r.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .sale {
                -webkit-background-size: contain;
                background-size: contain;
              }
            }

            &.current {
              color: #54C3F1;

              .name ,.icn {
                opacity: 1;
              }

              .home {
                background: url(#{$IMG_PATH}icon/gl_icn_home_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .portal {
                background: url(#{$IMG_PATH}icon/icon_portal_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .forum {
                background: url(#{$IMG_PATH}icon/gl_icn_forum_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .catalogue {
                background: url(#{$IMG_PATH}icon/gl_icn_catalogue_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .courseListRef {
                background: url(#{$IMG_PATH}icon/icn_courselist_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }
              
              .attend-progress {
                background: url(#{$IMG_PATH}icon/icn_attend_progress_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .profile {
                background: url(#{$IMG_PATH}icon/gl_icn_portfolio_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .title-list {
                background: url(#{$IMG_PATH}icon/gl_icn_titleslist_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .question-bank {
                background: url(#{$IMG_PATH}icon/icn_question_bank_w.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .approval {
                background: url(#{$IMG_PATH}icon/gl_icn_approval_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;

                &.on::after {
                  content: " ";
                  display: block;
                  position: absolute;
                  width: 9px;
                  height: 9px;
                  -webkit-border-radius: 5px;
                  -moz-border-radius: 5px;
                  border-radius: 5px;
                  background: #ee7800;
                  right: 20px;
                  top: 15px;
                }
              }

              .face-auth {
                background: url(#{$IMG_PATH}icon/icn_face_certification_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .learning-progress {
                background: url(#{$IMG_PATH}icon/gl_icn_portfolio02_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .user-edit {
                background: url(#{$IMG_PATH}icon/gl_icn_useredit_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .course-edit {
                background: url(#{$IMG_PATH}icon/gl_icn_courseedit_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .site-management {
                background: url(#{$IMG_PATH}icon/gl_icn_system_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .system {
                background: url(#{$IMG_PATH}icon/icon_system_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .mail {
                background: url(#{$IMG_PATH}icon/icn_mailsend_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }
              
              .education {
                background: url(#{$IMG_PATH}icon/icn_education_pack_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .sale {
                -webkit-background-size: contain;
                background-size: contain;
              }
            }

          } // a

        } // li

      } // .global-menu-list

    } // .global-menu-box


    .global-menu-ctr {
      position: absolute;
      bottom: 0;
      width: 100%;
      overflow: hidden;

      a {
        @include HoverOpacityTrans();
        display: block;
        width: 50%;
        float: left;
        text-align: center;
        height: 23px;
        line-height: 23px;
        color: #fff;
        background: #000;
        font-size: 10px;

        &.disabled {
          cursor: default;
          &:hover {
            opacity: 1;
          }
          background: #2f2f2f;
          color: #525252;
        }
      }
    } // .global-menu-ctr

    .global-menu-toggle {
      position: absolute;
      height: 100%;
      width: 10px;
      right: -10px;
      top: 0;
      background-color: #000;
      background-image: url(#{$IMG_PATH}common/arrow_global_menu_toggle.png);
      background-position: center center;
      background-repeat: no-repeat;

      &.close {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }

      &.toggle-red {
        background-color: #A4192D;
      }

      &.toggle-black {
        background-color: #000;
      }
    }
  }
} // .global-menu