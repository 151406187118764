@charset "UTF-8";

.modal-wat-status {

  .m-modal {
    .modal-inner {
      
      padding: 0;
      
      &.c-search {
        width: 600px;
        top: 35%;
        height: 20vh;
        padding-left: 0px;
        padding-right: 0px;
        text-align: left;
        background: #fff;
        z-index: 11000;

        .c-search-header {
          position: relative;
          display: block;
          margin: 0px;
          
        .input-text {
          width: 100%;
          border: 1px solid #c8c8c8;
          padding: 3px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          max-width: 100%;
        }

        }
      }

      .page-inner {
        
        .ps-scrollbar-y {
          margin-left: 10px;
        }
        
        .page-contents {
          background: #bae3f9;
          height: 260px;
        }

        .course-result {
          height: 200px !important;
          background: #fff;
        }
        
        &.c-search-modal {
          height: 321px;
          margin: 0px;

          .site-header {
            background: #bae3f9;
          }

          .result-txt {
            display: block;
            font-size: 18px;
            font-weight: bold;
            min-height: 30px;
            text-align: center;
            padding-top: 7px;
            background: #bae3f9;
          }
          
          .site-result {

            width: 95%;
            border: 1px solid #ccc;
            margin: 0px auto;
            height: 180px;
            
            .col-text-box:not(:first-child) {
              margin: 20px 0;
            }

            tr {
              width: 100%;
              display: block;
            }

            th {
              padding: 10px 10px;
              background: #fff;
              .col-text-box {
                text-align: left;
              }
            }

          }
        }
      }

    }
  }

}
