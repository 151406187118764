@charset "UTF-8";

.m-modal {
  &.modal-test-score-histogram {
    background: none;
    padding: 0px;
    .modal-inner {
      &.modal-inner-test-score-histogram {
        width: 80%;
        min-width: 800px;
        height: 80%;
        min-height: 450px;
        top: 10%;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction : column;
        flex-direction : column;
        .chapter-name {
          margin-bottom: 20px;
        }
        .search-condition {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 20px;
          .input-text:disabled{
              background: #eee;
              border: 2px solid #aaa;
              border-width: 1px;
          }
          .contidion-distribution-map {
            margin-right: 20px;
          }
          .condition-class {
            margin-right: 20px;
            label {
              margin-left:10px;
            }
            input {
              margin-left:15px;
              display: inline-block;
              width: 50px;
              border: 1px solid #c8c8c8;
              padding: 5px;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
            }
          }
          .condition-score {
            margin-right: 20px;
            label {
              margin-left:10px;
            }
          }
          .btn-submit-search-new {
            background: url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
            -webkit-background-size: 20px;
            background-size: 20px;
            background-position-x: 50% ;
            color: #468cc8;
            border: 1px solid #82BEF0;
            background-color: #82BEF0;
            font-weight: normal;
            width: 75px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 5px;
            font-size: 16px;
            padding: 0;
          }
        }
        .validation-error-msg {
          text-align : left;
          font-size: 12px;
          color:red;
          font-weight: bold;
          margin-bottom: 20px;
        }
        .chart-area {
          flex: 1;
          position: relative;
          .parent-chart {
            width: 100%;
            height: 100%;
            display: none;
            position: absolute;
          }
          .nodata {
            font-size: 14px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform : translate(-50%,-50%);
            transform : translate(-50%,-50%);
          }
          .loading {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform : translate(-50%,-50%);
            transform : translate(-50%,-50%);
          }
        }
        .pseudo-radio {
          &.radio-disabled {
            border: 1px solid #787878;
            background-color: #c9c9c9;
            input[type="radio"]:disabled+span {
                background: #787878;
                cursor: default
            }
          }
        }
      }
    }
  }
}
