@charset "UTF-8";
.page-course-group-search {
    height: 100%;
    .page-inner {
        background: $background_theme_color_contents;
        height: 100%;
        .page-contents {
            height: 100%;
            width: 100%;
            margin: auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
             .m-form-search {
                 padding: 0;
                 .error {
                     margin-top: 1px;
                 }
                .form-group {
                    .inner {
                        width: 80%;

                        @media all and (max-width: 1080px) {
                            width: 90%;
                        }

                        tr {
                            height: 80px;
                            input{
                              height: 39px;
                            }
                        }
                         tr:last-child{
                            height: 60px;
                        }
                        .td-1 {
                            width: 18%;
                            text-align: right;
                            padding-right: 10px;
                        }
                        .td-2 {
                            width: 30%;
                            
                        }
                        .td-3 {
                            text-align: right;
                            width: 18%;
                            padding-right: 10px;
                        }
                        .td-4 {
                            width: 30%;
                        }
                        .td-5 {
                            text-align: center;
                        }
                        .form-row{
                            .layout>*{
                                width: 100% !important; 
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }

      .course-header {
        display: flex;
        padding-bottom: 5px;

        .except {
          height: 71px;
          display: none;
        }
        .download {
          position: absolute;
          right: 0px;
          margin-top: 5px;
          margin-right: 10px;
          min-height: 30px;
          padding-bottom: 5px;
        }

        .result-txt {
          margin-top: 5px;
          margin-left: 10px;
          min-height: 30px;
          display: flex;
          align-items: center;
        }

      }

        .course-search-header {
            background: #fff;
            padding: 0 20px;
            .inner {
                min-height: 34px;
                padding: 20px 0;
                position: relative;
                // border-bottom: 2px solid #d1d1d1;
                overflow: hidden;
                .back {
                    @include HoverOpacity();
                    position: absolute;
                    left: 0;
                    img {
                        width: 20px;
                        height: 20px;
                        vertical-align: -webkit-baseline-middle;
                    }
                }
                .btn {
                    float: right;
                    text-align: center;
                }
                .select-upload {
                    float: right;
                    margin-right: 5px;
                    .sel {
                        background-color: white;
                        -webkit-border-radius: 0px;
                        -moz-border-radius: 0px;
                        border-radius: 0px;
                        color: #468cc8;
                        border: 1px solid;
                        width: 200px;
                    }
                    .sel.sel-size-auto {
                        height: 36px;
                        width: 170px !important;

                        .sel-txt {
                            padding-left: 10px;
                            padding-right: 17px;
                            background-size: 18px;
                            background-image: url(#{$IMG_PATH}icon/icn_selectbtn_bottom_b.png);
                            span{
                               text-overflow: ellipsis;
                               white-space: nowrap;
                               overflow: hidden;
                            }
                        }
                    }
                }
            }
        }

        .course-result {
            @include search_table_f;
          margin: 0 10px;
          padding: 0;
          position: relative;
          height: 61%;
            @media screen and (max-height: 980px) {
                height : 59%;
            }
            @media screen and (max-height: 930px) {
                height : 57%;
            }
            @media screen and (max-height: 900px) {
                height : 54%;
            }
            @media screen and (max-height: 850px) {
                height : 52%;
            }
            @media screen and (max-height: 810px) {
                height : 51%;
            }
            @media screen and (max-height: 800px) {
                height : 50%;
            }
            @media screen and (max-height: 780px) {
                height : 48%;
            }
            @media screen and (max-height: 750px) {
                height : 44%;
            }
            @media screen and (max-height: 700px) {
                height : 39%;
            }
            @media screen and (max-height: 650px) {
                height : 37%;
            }
            @media screen and (max-height: 635px) {
                height : 34%;
            }
            @media screen and (max-height: 600px) {
                height : 32%;
            }
            .table-header{
                table-layout: auto;
                tr{
                    width: 100%;
                    position: fixed;
                    th{
                        padding: 5px;
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                }
                 .col-group-name{
                    min-width: 350px;
                }
                .col-group-id{
                    min-width: 130px;
                }
                .col-portal-flag{
                    min-width: 195px;
                }
                .col-group-sum{
                    min-width: 95px;
                }
                .col-btn{
                    min-width: 95px;
                }
            }
            .table-result-nodata{
                height: 100%;
                tr{
                    td{
                        border: 0px solid #fafafa  !important;
                        color: #AFAFAF;
                         box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        span{
                            position: relative;
                        }
                    }
                }
            }
            .table-result{
                table-layout: auto;
                .col-group-name{
                    min-width: 350px;
                    width: 55%;
                }
                .col-group-id{
                    min-width: 130px;
                    width: 10%;
                }
                .col-portal-flag{
                    min-width: 195px;
                    width: 15%;
                }
                .col-group-sum{
                    min-width: 95px;
                    width: 10%;
                }
                .col-btn{
                    min-width: 95px;
                    width: 10%;
                }
                tr{
                    td:not(.row-ope){
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                }
                .portal-flag{
                    background: url(#{$IMG_PATH}icon/icn_check_k.png) center center no-repeat;
                    -webkit-background-size: 70%;
                    background-size: 70%;
                    display: inline-block;
                    width: 34px;
                    height: 34px;
                    background-position-x: 50%;
                }
            }
            table{ 
                tr:not(:first-child):hover, tr.selected,.hover{
                    .btn-submit-delete { 
                        background: url(#{$IMG_PATH}icon/icn_trash_w.png) 20px center no-repeat;
                        -webkit-background-size: 80%;
                        background-size: 80%;
                        background-position-x: 50% ;
                    }
                    .btn-submit-setting {
                        background: url(#{$IMG_PATH}icon/icn_edit_w.png) 20px center no-repeat;
                        -webkit-background-size: 80%;
                        background-size: 80%;
                        background-position-x: 50% ;
                    }
                    .portal-flag{
                    background: url(#{$IMG_PATH}icon/icn_check_w.png) center center no-repeat;
                    -webkit-background-size: 70%;
                    background-size: 70%;
                    background-position-x: 50%;
                }
                }
            }
              
        }
        
        .course-list-inner{
            height: 100%;
            background-color: #fff;
        }
        .course-list-outer{
            width: 100%;
            height: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;}
          }
        .scroll-area {
            &.ps-active-y {
                padding-right: 16.2px;
                margin-right: -16.2px;
            }
            .ps-scrollbar-y-rail{
                right: 16.2px;
            }
            height: 100%;
        } // .scroll-area
         .pseudo-radio {
            margin-right: 10px;
        }

        input,a{
        vertical-align: middle;
        &.btn-submit-download {
            float: right;
            font-weight: initial;
            text-align: center;
            width: auto;
            padding: 6px 19px;
            color: #000;
            border: 1px solid #c8c8c8;
            margin: -3px 0;
            line-height: 22px;
            background: #fff;
            color: #6E6E6E;
            img{
              width: 20px;
              height: 20px;
              padding-right: 7px;
            }
        }
        &.btn-new-course {
          width: 150px !important;
        }
        &.btn-submit-upload {
            background: url(#{$IMG_PATH}icon/icn_upload_w.png) 20px center no-repeat;
            -webkit-background-size: 20px;
            background-size: 20px;
            color: #468cc8;
            border: 1px solid;
            background-color: white;
            font-weight: normal;
            width: auto;
            height: 34px;
            line-height: 34px;
            text-align: center;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 0px;
            font-size: 16px;
            padding: 0;
        }
        &.btn-submit-search-new {
           background: url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
            -webkit-background-size: 20px;
            background-size: 20px;
            background-position-x: 50% ;
            color: #468cc8;
            border: 1px solid #82BEF0;
            background-color: #82BEF0;
            font-weight: normal;
            width: 130px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 5px;
            font-size: 16px;
            padding: 0;
        }
         &.btn-submit-setting {
            background: url(#{$IMG_PATH}icon/icn_edit_k.png) 20px center no-repeat;
            -webkit-background-size: 80%;
            background-size: 80%;
            background-position-x: 50% ;
            font-weight: normal;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            font-size: 16px;
            padding: 0;
        }
        &.btn-submit-delete {
            background: url(#{$IMG_PATH}icon/icn_trash_k.png) 20px center no-repeat;
           -webkit-background-size: 80%;
            background-size: 80%;
            background-position-x: 50% ;
            font-weight: normal;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            font-size: 16px;
            padding: 0;
        }
       
    }
}

input,a {
   vertical-align: middle;
   &.btn-new-course {
          width: 150px !important;
   }
}

.confirm-search-modal{
    .modal-inner{
        min-width: 600px;
        .btn{
            width: 150px !important;
        }
    }
}

