@charset "UTF-8";
.page-courses-search{
    .m-modal { 
        .modal-inner {
            &.u-search {
                min-width: 580px;
                padding-left: 0px;
                padding-right: 0px;
                width: 70%;
                text-align: center;
                background: #fff;
                z-index: 11000;
                .top-area{
                    margin-top: -22px;
                    position: relative;
                    height:30px;
                    text-align: center;
                    .btn-close-modal {
                        position: absolute;
                        top:4px;
                        right: 4px;
                        background: url(#{$IMG_PATH}icon/icn_close_k.png) left center no-repeat;
                        background-size: 15px;
                        width: 20px;
                        height: 20px;
                        float: right;
                        cursor:pointer;
                        &:hover {
                            opacity: 0.7;
                        }
                    }
                    // .top-title{
                    //     line-height: 30px;
                    //     font-size: 15px;
                    //     background-color: #8eb4e3;
                    //     color: #fff;
                    // }
                    }
                .u-search-header {
                    // align-items: center;
                    justify-content: flex-start;
                    // margin: 0px 20px 0px 20px;
                    margin: 4px 20px 0px 20px;

                    table {
                        tr {
                            .td-title {
                                min-width: 100px;
                                display: inline-flex;
                                align-items: center;

                                span {
                                    color: #82bef0;
                                    display: inline-block;
                                    padding-left: 25px;
                                    background: url(#{$IMG_PATH}icon/icn_user_b.png) no-repeat;
                                    background-position: 0% 35%;
                                    -webkit-background-size: 20px;
                                    background-size: 20px;
                                    cursor: default;
                                    font-weight: bold;
                                }                             
                            }
                            .td-u-name {
                                padding-left: 10px;

                                min-width: 50px;
                                width: 30%;
                                max-width: 200px;

                                height: 33px;
                                .input-text {
                                    width: 100%;
                                    border: 1px solid #c8c8c8;
                                    padding: 3px;
                                    -webkit-box-sizing: border-box;
                                    -moz-box-sizing: border-box;
                                    box-sizing: border-box;
                                    max-width: 100%;
                                }
                            }
                            .td-5-percents {
                                width: 5%;
                            }
                            .td-10-percents {
                                width: 10%;
                            }
                            .td-title-group {
                                min-width: 158px;
                                display: inline-flex;
                                align-items: center;
                                span {
                                    color: #82bef0;
                                    display: inline-block;
                                    padding-left: 25px;
                                    background: url(#{$IMG_PATH}icon/icn_usergroup_b.png) no-repeat;
                                    background-position: 0% 35%;
                                    -webkit-background-size: 20px;
                                    background-size: 20px;
                                    cursor: default;
                                    font-weight: bold;
                                }
                            }
                            .td-ug-name {
                                padding-left: 10px;

                                min-width: 106px;
                                width: 30%;
                                max-width: 260px;


                                height: 33px;
                                .input-text {
                                    width: 100%;
                                    border: 1px solid #c8c8c8;
                                    padding: 3px;
                                    -webkit-box-sizing: border-box;
                                    -moz-box-sizing: border-box;
                                    box-sizing: border-box;
                                    // max-width: 100%;
                                }
                            }
                            .td-u-btn {
                                margin-left: 10px;
                                .btn-u-search {
                                    width: 55px;
                                    height: 30px;
                                    button {
                                        height: 30px;
                                    }

                                }
                            }
                            .error {
                                text-align: left;
                                font-size: 12px;
                                color: red;
                                font-weight: bold;
                                padding-top: 2px;

                                &.hide {
                                display: none;
                                }
                            }   
                        }
                    }
                }

                .page-inner {
                    &.u-search-modal {
                        height: 321px;
                        margin: 5px 20px 0px 20px;
                        .no-data {
                            height: 263px;
                        }
                        .course-result {
                            height: 263px;
                            &.result-tbl {
                                padding: 0px;
                                margin: 0px 10px 0px 10px !important;
                                background: white;

                                .ps-scrollbar-y-rail {
                                right: 0px;
                                }
                            }
                        }

                        .table-result {
                            table-layout: fixed;
                            .col-login-id {
                                width: 20%;
                                // min-width: 157px;
                            }
                            .col-user-name {
                                width: 40%;
                                // min-width: 113px;
                            }
                            .col-course-name {
                                width: 40%;
                                // min-width: 227px;
                            }
                        }
                        .table-header {
                            table-layout: auto;
                            .col-name{
                                min-width: 257px;
                            }
                            .col-id{
                                min-width: 135px;
                            }
                            .col-btn{
                                min-width: 83.2px;
                            }
                            tr{
                                width: 100%;
                                position: fixed;

                                th{
                                    padding: 5px 0;
                                    box-sizing:border-box;
                                    -webkit-box-sizing: border-box;
                                    -moz-box-sizing: border-box;
                                }
                            }
                            thead {
                                tr {
                                    cursor: pointer;
                                    div {
                                        display: inline-flex;
                                        .img {
                                        background-image: url(#{$IMG_PATH}icon/icn_pullupdown_k.png) ;
                                        background-repeat: no-repeat ;
                                        background-position: center ;
                                        background-size: 26px;
                                        width: 27px;
                                        padding-left: 7px;

                                        &.header-sort-up {
                                            cursor: pointer;
                                            display: inline-flex;
                                            width: 26px;
                                            background-position: center ;
                                            background-repeat: no-repeat ;
                                            background-image: url(#{$IMG_PATH}icon/icn_pullup_k.png) ;
                                            background-size: 13px;
                                            padding-left: 7px;
                                        }

                                        &.header-sort-down {
                                        cursor: pointer;
                                        display: inline-flex;
                                        width: 26px;
                                        background-position: center ;
                                        background-repeat: no-repeat ;
                                        background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png) ;
                                        background-size: 13px;
                                        padding-left: 7px;
                                        }

                                    }
                                        

                                        
                                    }

                                    }
                                }
                      }
                    }

                }

            }

        }
    }

    
}