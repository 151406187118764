@charset "UTF-8";
  .page-collective-approval {
          height: 100%;
    .page-inner {
        background: $background_theme_color_contents;
        height: 100%;
        .page-contents {
            height: 100%;
            width: 100%;
            margin: auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
           .form-wrap-inner{
              padding: 0 10%;
              .wrap-row{
                width: 100%;
                margin-bottom: 10px;
                div{
                  display: inline-block;
                  vertical-align: top;
                  &.approve-status{
                    width: 30% ;
                  }
                  &.user-name-cell{
                    width: 25%;
                  }
                  &.course-name-cell{
                    width: calc(25% - 5px);
                  }
                  &.content-type-cell{
                    width: calc(30% - 5px);
                  }
                  &.content-processing-cell{
                    width: calc(20% - 5px);
                  }
                    &.approve-status, &.user-name-cell,&.course-name-cell{
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: pre;
                    }
                }             
              }
            }
             .m-form-search {
                 padding: 0;
                 .error {
                     margin-top: 1px;
                 }
                .form-group {
                    .inner {
                        width: 85%;
                        .item-group{
                            width: 25%;
                            margin-bottom: 10px;
                            .content{
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                display: inline-block;
                                width: 57%;
                                vertical-align: bottom;
                            }
                            @media screen and (max-width: 1240px) {
                                &.item-date-time{
                                    width : 30%;
                                }
                                &.item-condition{
                                    width : 20%;
                                }
                            }
                            .all-content{
                                display: none;
                                position: absolute;
                                z-index: 2;
                                padding: 10px;
                                width: 300px;
                                -webkit-box-sizing: border-box;
                                -moz-box-sizing: border-box;
                                box-sizing: border-box;
                                background: #333;
                                color: #fff;
                                -webkit-border-radius: 5px;
                                -moz-border-radius: 5px;
                                border-radius: 5px;
                                font-size: 12px;
                                margin-top: 5px;
                                white-space: normal;
                                word-break: break-all;
                                 &:before {
                                    background: #333;
                                    z-index: 1;
                                    @include PseudoElements();
                                    width: 10px;
                                    height: 10px;
                                    position: absolute;
                                    top: -5px;
                                    -webkit-transform: rotate(-45deg);
                                    -moz-transform: rotate(-45deg);
                                    -ms-transform: rotate(-45deg);
                                    -o-transform: rotate(-45deg);
                                    transform: rotate(-45deg);

                                }
                            }
                             .content:hover > .all-content {
                                display: block;
                            }
                        }
                        .item-group:last-child{
                            width: 100%;
                        }
                    }
                }
            }
        }

      .course-header {
        display: flex;
        padding-bottom: 5px;

        .except {
          height: 71px;
          display: none;
        }

        .result-txt {
          margin-top: 5px;
          margin-left: 10px;
          min-height: 30px;
          display: flex;
          align-items: center;
        }

      }

        .search-header {
            background: #fff;
            padding: 0 20px;
            .inner {
                min-height: 34px;
                padding: 20px 0 0 0;
                position: relative;
                overflow: hidden;
                .back {
                    @include HoverOpacity();
                    margin-top: 4px;
                    position: absolute;
                    left: 0;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
                .name{
                    padding-left: 30px;
                    line-height: 30px;
                    display: inline-block;
                    cursor: default;
                    word-break: break-all;
                    width: 90%;
                }
                .btn {
                    float: right;
                    text-align: center;
                }
            }
        }

        .approve-result {
            @include search_table_f;
          margin: 0 10px;
          padding: 0;
          position: relative;
          height: 76%;
            @media screen and (max-height: 940px) {
                height : calc(74% - 10px);
            }
            @media screen and (max-height: 900px) {
                height : calc(73% - 10px);
            }
            @media screen and (max-height: 880px) {
                height : calc(72% - 10px);
            }
            @media screen and (max-height: 830px) {
                height : calc(70% - 10px);
            }
            @media screen and (max-height: 810px) {
                height : calc(69% - 10px);
            }
            @media screen and (max-height: 790px) {
                height : calc(68% - 10px);
            }
            @media screen and (max-height: 760px) {
                height : calc(67% - 10px);
            }
            @media screen and (max-height: 730px) {
                height : calc(66% - 10px);
            }
            @media screen and (max-height: 700px) {
                height : calc(65% - 10px);
            }
            @media screen and (max-height: 670px) {
                height : calc(64% - 10px);
            }
            @media screen and (max-height: 640px) {
                height : calc(63% - 10px);
            }
            @media screen and (max-height: 610px) {
                height : calc(62% - 10px);
            }
            @media screen and (max-height: 600px) {
                height : calc(61% - 10px);
            }
            .table-header{
                table-layout: auto;
                tr{
                    width: 100%;
                    position: fixed;
                    z-index: 10;
                    th{
                        padding: 5px;
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                }
            }
            .table-result-nodata{
                height: 100%;
                tr{
                    td{
                        border: 0px solid #fafafa  !important;
                        color: #AFAFAF;
                         box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        span{
                            position: relative;
                        }
                    }
                }
            }
            .table-result{
                // table-layout: auto;
                    z-index: -1;
                .col-check-box{
                  min-width: 47px;
                  width: 5%;
                }
                .col-user-name{
                  width: 10%;
                }
                .col-approve-time{
                  width: 12%;
                }
                .col-content-type{
                  width: 10%;
                }
                .col-content-name{
                  width: 14%;
                  white-space: pre-wrap;
                  word-break: break-all;
                }
                .col-course-name{
                  width: 14%;
                  white-space: pre-wrap;
                  word-break: break-all;
                }
                .col-lesson-name{
                  width: 14%;
                  white-space: pre-wrap;
                  word-break: break-all;
                }
                .col-chapter-name{
                  width: 14%;
                  white-space: pre-wrap;
                  word-break: break-all;
                }
                .col-preview {
                    width: 85px;
                }
                tr{
                    td:not(.row-ope){
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                }
                .content-ccp {
                        background: url(#{$IMG_PATH}icon/icn_conccp_k.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        vertical-align: middle;
                        display: inline-block;
                        width: 34px;
                        height: 34px;
                        background-position-x: 50%;
                    }
                    .content-anket {
                        background: url(#{$IMG_PATH}icon/icn_conenq_k.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        vertical-align: middle;
                        display: inline-block;
                        width: 34px;
                        height: 34px;
                        background-position-x: 50%;
                    }
                    .content-test {
                        background: url(#{$IMG_PATH}icon/icn_test_k.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        vertical-align: middle;
                        display: inline-block;
                        width: 34px;
                        height: 34px;
                        background-position-x: 50%;
                    }
                    .content-document {
                        background: url(#{$IMG_PATH}icon/icn_file_document_k.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        vertical-align: middle;
                        display: inline-block;
                        width: 34px;
                        height: 34px;
                        background-position-x: 50%;
                    }
                    .content-video {
                        background: url(#{$IMG_PATH}icon/icn_video_k.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        vertical-align: middle;
                        display: inline-block;
                        width: 34px;
                        height: 34px;
                        background-position-x: 50%;
                    }
                    .content-html {
                        background: url(#{$IMG_PATH}icon/icn_file_html_k.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        vertical-align: middle;
                        display: inline-block;
                        width: 34px;
                        height: 34px;
                        background-position-x: 50%;
                    }
                    .content-slide {
                        background: url(../../img/icon/icn_slideonly_k.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        vertical-align: middle;
                        display: inline-block;
                        width: 34px;
                        height: 34px;
                        background-position-x: 50%;
                    }
                    .content-preview {
                        background: url(#{$IMG_PATH}icon/icn_preview_o.png) 20px center no-repeat;
                        -webkit-background-size: 80%;
                        background-size: 80%;
                        background-position-x: 50% ;
                        font-weight: normal;
                        width: 34px;
                        height: 34px;
                        line-height: 34px;
                        text-align: center;
                        font-size: 16px;
                        padding: 0;
                        z-index: -1;
                    }
                    .icn-chapter-check {
                        background: url(#{$IMG_PATH}icon/icn_check_k.png) center center no-repeat;
                        -webkit-background-size: 70%;
                        background-size: 70%;
                        display: inline-block;
                        width: 34px;
                        height: 34px;
                        background-position-x: 50%;
                        vertical-align: middle;
                    }
            }
            table{ 
                tr:not(:first-child):hover, tr.selected,.hover {
                    .btn-submit-delete { 
                        background: url(#{$IMG_PATH}icon/icn_edit_w.png) 20px center no-repeat;
                        -webkit-background-size: 80%;
                        background-size: 80%;
                        background-position-x: 50% ;
                    }
                    .btn-submit-setting {
                        background: url(#{$IMG_PATH}icon/icn_trash_w.png) 20px center no-repeat;
                        -webkit-background-size: 80%;
                        background-size: 80%;
                        background-position-x: 50% ;
                    }
                    .content-ccp {
                        background: url(#{$IMG_PATH}icon/icn_conccp_w.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        background-position-x: 50%;
                      }
                    .content-anket {
                        background: url(#{$IMG_PATH}icon/icn_conenq_w.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        background-position-x: 50%;
                    }
                    .content-test {
                        background: url(#{$IMG_PATH}icon/icn_test_w.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        background-position-x: 50%;
                      }
                    .content-document {
                        background: url(#{$IMG_PATH}icon/icn_file_document_w.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        background-position-x: 50%;
                    }
                    .content-video {
                        background: url(#{$IMG_PATH}icon/icn_video_w.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        background-position-x: 50%;
                    }
                    .content-html {
                        background: url(#{$IMG_PATH}icon/icn_file_html_w.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        background-position-x: 50%;
                    }
                    .content-slide {
                        background: url(#{$IMG_PATH}icon/icn_slideonly_w.png) center center no-repeat;
                        -webkit-background-size: 100%;
                        background-size: 100%;
                        background-position-x: 50%;
                    }
                    .icn-chapter-check {
                        background: url(#{$IMG_PATH}icon/icn_check_w.png) center center no-repeat;
                        -webkit-background-size: 70%;
                        background-size: 70%;
                        background-position-x: 50%;
                    }
                }
            }
            .table-result,.table-header{
                .checkbox-btn {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  label {
                    padding-left: 20px;
                    &::after {
                      top: 2px;
                      left: 15px;
                      width: 14px !important;
                      height: 14px !important;
                    }
                    &::before {
                      top: 5px;
                      left: 17px;
                      width: 11px !important;
                      height: 5px !important;
                    }
                  }
                }
                .btn-play-thumb {
                    z-index:2;
                    display: block;
                    position: relative;
                    @include HoverOpacity();
                    
                    &:before,
                    &:after {
                    @include PseudoElements();
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    }

                    &:before{
                    background: rgba(0, 0, 0, .4);
                    }

                    &:after {
                    background: url(#{$IMG_PATH}icon/icn_preview_w.png) center center no-repeat;
                    -webkit-background-size: 26px auto;
                    background-size: 26px auto;
                    }
                    
                }
            }
            .header {
                cursor: pointer;
                div {
                    display: inline-flex;
                    .img {
                        background-image: url(#{$IMG_PATH}icon/icn_pullupdown_k.png) ;
                        background-repeat: no-repeat ;
                        background-position: center ;
                        background-size: 26px;
                        width: 27px;
                        padding-left: 7px;
                    }
                }
                
            }
            .header-sort-up {
                cursor: pointer;
                div {
                    display: inline-flex;
                    .img {
                    width: 26px;
                    background-position: center ;
                    background-repeat: no-repeat ;
                    background-image: url(#{$IMG_PATH}icon/icn_pullup_k.png) ;
                    background-size: 13px;
                    padding-left: 7px;
                    }
                }
            }

            .header-sort-down {
                cursor: pointer;
                div {
                    display: inline-flex;
                    .img {
                    width: 26px;
                    background-position: center ;
                    background-repeat: no-repeat ;
                    background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png) ;
                    background-size: 13px;
                    padding-left: 7px;
                    }
                    
                }
            }
              
        }
        
        .approve-list-inner{
            height: 100%;
            background-color: #fff;
        }
        .approve-list-outer{
            width: 100%;
            height: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;}
          }
        .scroll-area {
            &.ps-active-y {
                padding-right: 16.2px;
                margin-right: -16.2px;
            }
            .ps-scrollbar-y-rail{
                right: 16.2px;
            }
            .ps-scrollbar-y{
                z-index: 99999;
            }
            height: 100%;
        } // .scroll-area
        .form-btns{
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .disabled{
            opacity: .4 !important;
            cursor: default;       
        }

        .btn-modal-approve{
          padding: 0 15px;
          width: 150px;
          background-color: #FFFFFF;
          text-align: center;
          -moz-border-radius: 0px;
          border-radius: 2px;
          border: 1px solid #DC780A;
          color: #DC780A;
          font-weight: normal;
          line-height: 30px;
          height: 30px;
          font-size: 16px;
          box-sizing: border-box;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          vertical-align: middle;
      }
      .btn-modal-cancel{
          padding: 0 15px;
          width: 150px;
          background-color: #FFFFFF;
          text-align: center;
          -moz-border-radius: 0px;
          border-radius: 2px;
          border: 1px solid #555;
          color: #555;
          font-weight: normal;
          line-height: 30px;
          height: 30px;
          font-size: 16px;
          box-sizing: border-box;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          vertical-align: middle;
      }
  }
