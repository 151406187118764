
@charset "UTF-8";
.import {

 .m-modal {
    .modal-inner {
        top:10%;
        &.modal-inner-CSVimport {
        padding-left: 0px;
        padding-right: 0px;
        max-width: 800px;
        min-width: 800px;
        width: auto;
        margin-top: 5%;
      .title {
        span {
        color: #82bef0;
        font-size: 18px;
        }
      }
      .totalAction {
        margin-top: 15px;
          table {
              th{   
                   font-size: 20px;
                }
              span{    
                    font-size: 35px !important;
                    font-weight: bold;
                    color: #848484;
                }
                width: 664px;
                margin-left: 70px;
                color: #6e6e6e;
                .insertRecord {
                    text-align: left;
                }    
                .updateRecord {
                    text-align: center;
                } 
                .deleteRecord {
                    text-align: right;
                }   
                span {
                    font-size: 20px;
                    .number {
                    font-size: 35px; 
                    font-weight :bold;
                    color: #848484;
                    }
                } 
          }
             
      }

    .are-error {
        margin-top: 30px;
        height: 430px;
        background: #d2ebfa;
        color: #6e6e6e;
        .warning-text {
            padding-top: 20px;
            text-align: left;
            margin-left: 65px;
        }
        .table-warning {
            width: 100%;
            text-align: left;
            td {
                padding-left: 10px;
            }
            tr:first-child td {
                padding-top: 10px;
            }
        }
        .table-error {
            width: 100%;
            text-align: left;
            color: red;
             td {
                padding-left: 10px;
            }
            tr:first-child td {
                padding-top: 10px;
            }
        }
        .error-text {
            padding-top: 20px;
            text-align: left;
            padding-left: 65px;
        }
        .scroll-area {
            height: 145px;
            width: 83%;
            margin: 0 auto;
            border: 1px solid #a0a0a0; 
            background: white;   
        }
        .ps-scrollbar-y-rail {
            right: 1px;
        }

    }

    .modal-btns {
        .btn-importcsv{
            padding: 6px 19px;
            width: 170px;
            color: #DC780A;
            font-weight: normal;
            border: 1px solid #DC780A;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 2px;
            margin: auto 15px;
            line-height: 18px;
            background: #fff;
            margin-top: 30px;
        }

        .btn-backChooseFile{
            padding: 6px 19px;
            width: 170px;
            color: #a0a0a0;
            font-weight: normal;
            border: 1px solid #a0a0a0;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 2px;
            margin: auto 15px;
            line-height: 18px;
            background: #fff;
            margin-top: 30px;
        }
    }
    }
    }
  }

        @media screen and (max-width : 1366px) {
        @media screen and (max-height : 768px) { 

             .m-modal {
         .modal-inner {
        &.modal-inner-CSVimport {
            padding-left: 0px;
            padding-right: 0px;
            max-width: 590px;
            min-width: 590px;
            width: auto;

      .title {
        span {
        color: #82bef0;
        font-size: 18px;
        }
      }
      .totalAction {
        margin-top: 5px;
          table {
              th{   
                   font-size: 18px;
                }
              span{    
                    font-size: 25px !important;
                    font-weight: bold;
                    color: #848484;
                }
                width: 489px;
                margin-left: 50px;
                color: #6e6e6e;
                .insertRecord {
                    text-align: left;
                }    
                .updateRecord {
                    text-align: center;
                } 
                .deleteRecord {
                    text-align: right;
                }   
                span {
                    font-size: 20px;
                    .number {
                    font-size: 25px; 
                    font-weight :bold;
                    color: #848484;
                    }
                } 
          }
             
      }

    .are-error {
        margin-top: 7px;
        height: 310px;
        background: #d2ebfa;
        .warning-text {
            padding-top: 10px;
            text-align: left;
            margin-left: 48px;
        }
        .table-warning {
            width: 100%;
            text-align: left;
            font-size: 12px;
            td {
                padding-left: 10px;
            }
            tr:first-child td {
                padding-top: 10px;
            }
        }
        .table-error {
            width: 100%;
            text-align: left;
            color: red;
            font-size: 12px;
             td {
                padding-left: 10px;
            }
            tr:first-child td {
                padding-top: 10px;
            }
        }
        .error-text {
            padding-top: 10px;
            text-align: left;
            padding-left: 50px;
        }
        .scroll-area {
            height: 106px;
            width: 83%;
            margin: 0 auto;
            border: 1px solid #a0a0a0;  
            background: white;  
        }
        .ps-scrollbar-y-rail {
            right: 1px;
        }

    }

    .modal-btns {
        .btn-importcsv{
            padding: 6px 19px;
            width: 170px;
            color: #DC780A;
            font-weight: normal;
            border: 1px solid #DC780A;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 2px;
            margin: auto 15px;
            line-height: 18px;
            background: #fff;
            margin-top: 30px;
        }
        .btn-backChooseFile{
            padding: 6px 19px;
            width: 170px;
            color: #a0a0a0;
            font-weight: normal;
            border: 1px solid #a0a0a0;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 2px;
            margin: auto 15px;
            line-height: 18px;
            background: #fff;
            margin-top: 30px;
        }        
    }
    }
    }
  }
        }
        
        }


 }
