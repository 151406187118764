@charset "UTF-8";

.page-mail-input {

  .page-inner {

    .page-contents {
      width: 100%;
      padding: 20px 30px;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      max-width: 1250px;
    }
    .mail-search-header {
        background: #fff;
        padding: 0 20px;
        .inner {
            min-height: 34px;
            padding: 20px 0 0 0;
            position: relative;
            overflow: hidden;
            .back {
                @include HoverOpacity();
                position: absolute;
                left: 0;
                img {
                    width: 20px;
                    height: 20px;
                    vertical-align: -webkit-baseline-middle;
                }
            }
            .btn {
                float: right;
                text-align: center;
            }
        }
    }
    .m-form { 
      left: 0;
      width: 90%;
     .radio-group{
          margin: 0 30px 0 0;
      }
      .form-wrap {
        margin: 0;
        &:first-child {
          margin-top: 0;
          
        }
        .form-sub-heading {
          font-size: inherit;
        }
        .required-note{
          float: right;
        }
        .form-group {
          padding-right: 40px;
        }

         table {
            th:first-child {
              width: 30%;
              &.vertical-top{
                vertical-align: top;
                .text-title{
                  margin-top: 5px;
                }
              }
              .fz-s{
                padding-top: 1px;
                position: relative;
                top: -10px;
                font-size: 10px;
              }
            }
            td{
              &.radio-row{
                padding-top: 2px;
              }
              .first-row{
                margin-right:0;
                width: calc(65% - 5px);
              }
              .input-size-m{
                width: 65%;
              }
              .textarea-size-l{
                width: 65%;
              }
            }
            th,td{
              padding: 5px 15px;;
            }
         }
         .form-wrap-inner{
           padding: 0 20px;
           &.head-wrap{
             padding: 0 30px;
           }
           .wrap-row{
             width: 100%;
             margin-bottom: 5px;
             div{
               display: inline-block;
               vertical-align: top;
               &.course-name-cell{
                 width: 60% ;
               }
               &.percent-cell{
                 width: 35%;
               }
               &.user-name-cell{
                 width: 30%;
               }
               &.user-group-cell{
                 width: calc(30% - 5px);
               }
               &.status-cell{
                 width: 35%;
               }
                &.lesson-name-cell{
                width: 30%;
                }
                &.chapter-name-cell{
                          width: calc(30% - 5px);
                }
                &.last-day-cell{
                          width: calc(40% - 5px);
                }
                &.course-name-cell, &.user-name-cell,&.user-group-cell{
                 text-overflow: ellipsis;
                 overflow: hidden;
                 white-space: pre;
                }
                &.lesson-name-cell, &.chapter-name-cell,&.last-day-cell{
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: pre;
                  }
             }             
           }
         }
      }
    }
  }
  .btn-back,.btn-submit{
    height: 32px !important;
    line-height: 32px !important;
    width: auto !important;
    padding: 0 10px !important;
    min-width: 140px;
  }
  .m-modal{
    .modal-inner.comp{
      width: 520px;
      .modal-inner-content{
        padding: 10px 20px;
        p{ 
          margin-bottom: 10px;    
          display: block;
          text-align: left;
          span{
            word-break: break-all;
            white-space: pre-wrap;
          }
        }
      }
      
    }
  }
  .hints{    
    display: inline-block;    
    margin-top: 10px;
    position: absolute;
    &.text-content{
       margin-top: 5px;
    }
    .hint-btn{
      background: url(#{$IMG_PATH}icon/icn_hints_bk.png) no-repeat;
      padding-left: 25px;
      background-position: 50%;
      -webkit-background-size: 20px;
      background-size: 20px;
    }
    .hint-content{
      display: none;
      width: auto;
      max-width: 300px;
      background: #d3edfa;
      padding: 10px;
      font-size: 12px;
      margin-top: 3px;
      margin-left: 3px;
      position: relative;
      z-index: 999;
    }
  }
}