@charset "UTF-8";

.page-site {

  .page-inner {

    .page-contents {
      width: 100%;
      padding: 20px 30px;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      max-width: 1200px;
    }

    .checkbox-btn-check-disabled{
      label{
        cursor: default;
      }

      input{
        cursor: default;
      }

        label::before{
          border-color: #787878 !important;
          cursor: default;
        }
        label::after{
          background-color: #eee !important;
          border-color: #787878 !important;
          cursor: default;
        }
    }

    .checkbox-btn-uncheck-disabled{
        label{
        cursor: default;
      }

      input{
        cursor: default;
      }

        label::before{
          cursor: default;
        }
        label::after{
          background-color: #eee !important;
          cursor: default;
        }
    }

    .m-form {
      left: 0;
      width: 840px;

      .form-wrap {

        &:first-child {
          margin-top: 0;
          
        }
          .form-sub-heading {
            span{
              float: right !important;
              font-size: 13px;
              line-height: 50px;
              color:rgba(0, 0, 0, .7);
            }
          }
        .form-group {
          padding-right: 40px;
        }

        table {
          &.option-site{
            .pseudo-checkbox {
              @include pseudo-checkbox-f;
              width: 20px;
              height: 20px;
              &.disabled{
                border: 2px solid #aaa;
              }
              &.on {
                .checked {
                width: 20px;
                height: 20px;
                  &:before {
                    margin-left: 3px;
                    line-height: 2px;
                  }
                }
              }
            }
            th:first-child{
              padding: 0;
            }
            .download-area{
              &.disabled{
                background-color: #eee;
              }
              label{
                margin: 0 20px;
              }
              text-align: center;
              background: #dff2fc;
              line-height: 50px;
              float: left;
            }
            
            #lti-provider{
              &.disabled{
                background-color: #eee;
              }
              label{
                margin: 0 20px;
              }
              text-align: center;
              background: #dff2fc;
              line-height: 50px;
              float: left;
            }

            .content-select{
              &.disabled{
                background-color: #eee;
              }
              &.content-select-off{
                width: 100%;
              }
              label{
                margin: 0 20px;
              }
              text-align: center;
              background: #dff2fc;
              line-height: 50px;
              float: left;
            }

            // .download-area-disabled{
            //   input{
            //     background: #eee !important;
            //     pointer-events: none;
            //   }
            //   label::after{
            //     background-color: #eee !important;
            //     pointer-events: none;
            //   }
            //   label::before{
            //     background-color: #eee !important;
            //     pointer-events: none;
            //   }
            // }
            .ip-list-btn-area{
               text-align: center;
            }
            .lti-config{
               vertical-align:middle;
               line-height:42px;
               .lti-config-label{
                  margin-left: 20px;
               }
               .tool-info{
                  width: 100%;
                  float: left;
                  margin: 0 50px;
                  .info-label{
                     width: auto;
                     margin-right:10px; 
                     float: left;
                  }
                  .info-btn{
                     float: left;
                     a{
                        margin-left: 100px;
                     }
                  }
                  .public-key-input{
                     width: 38%;
                     float: left;
                     position: relative;
                     display: inline-block;
                     .hide {
                       display: none;
                     }
                     .tooltip{
                       position: absolute;
                       padding: 10px;
                       top: -50px;
                       left: 200px;
                       z-index: 99;
                       font-size: 12px;
                       word-break: break-all;
                       white-space: pre-wrap; 
                       line-height: 1.6em;
                       color: #fff;
                       border-radius: 5px;
                       background: #000;
                       min-height: 100px;
                       min-width: 300px;
                       max-width: 300px !important;
                       overflow-y: scroll;
                       -ms-overflow-style: none;
                       scrollbar-width: none;
                    }
                    .tooltip::-webkit-scrollbar {
                      display: none;
                      
                    }
                    .tooltip:before {
                      content: "";
                      position: absolute;
                      top: 50px;
                      right: 99%; 
                      border-top: 20px solid transparent;
                      border-bottom: 20px solid transparent; 
                      border-right: 20px solid #000;
                      margin-left: -30px;   
                      z-index: 99;
                    }
                  }
               }
            }
          }

          .custome-td{
              vertical-align:middle; 
              line-height:42px;
              
              .div-api-input {
                width: 100%;
                float: left;
                margin: 0 20px;
                .api-label {
                  width: auto;
                  margin-right:10px; 
                  float: left;
                }
                .api-input {
                  width: 41%;
                  float: left;
                }
                .api-btn {
                  float: left;
                  a {
                    margin-left: 20px;
                  }
                }
              }
              #ccp-label {
                width: auto;
                margin-left: 20px;
                margin-right: 10px;
                float: left;
                text-align: center;
              }
              #ccp-text {
                width: 80px;
                float: left;
              }
          }

          .lti-td{
            padding:10px !important;

            div{
              float:right;
            }

            .lti-input{
              width:26%;
            }

            .lti-label{
              margin-right:10px;
              width:15%;
            }
          }
          .api-td {
            float: left;
          }
          
          .max-video-play-number-td {
            .input-max-video-play-number {
              width:80px;
            }
          }
          .max-video-size-mb {
            .input-max-video-size-mb {
              width:80px;
            }
          }

          div.checkbox-btn{
            float : left;
            &.checkbox-btn-off{
              width: 30%;
              text-align: left;
            }
          }

          th:first-child {
            //width: 25%;
            width: 31%;
          }
          .face-td{
          padding:10px !important;

          div{
            float:right;
          }

          .face-input{
            //width:30%;
            width: 80px;
            margin-right: 190px;
          }
          .face-input-authentication{
            width: 80px;
          }

          .face-label{
            margin-right:10px;
            width: 11%;
            margin-left: 8px;
          }
          .face-label-max{
            margin-right: 10px;
            width: 16%;
            line-height: 20px;
            text-align: center;
            margin-top: 5px;
          }
        }
        .threshold-td{
          padding:10px !important;
          div{
            float:right;
          }
           .threshold-label{
            margin-right: 10px;
            width: 11%;
            text-align: center;
            line-height: 18px;
            margin-left: 8px;
            margin-top: 3px;
          }
          .threshold-input{
            width:80px;
          &.input-margin{
            margin-right: 6px;
          }
          }
          .threshold-label-max{
            width: 16%;
            text-align: center;
            line-height: 21px;
            margin-right: 10px;
          }
        }
        .td-red-message{
          font-size: 10px;
          color: red;
          font-weight: bold;
          p{
            margin-left: 10px;
          }
        }
        .lti-version{
          &.disabled{
            background-color: #eee;
          }
          .checkbox-btn{
            margin-top: 15px;
          }
          label{
            margin: 0 20px;
          }
          text-align: center;
          background: #dff2fc;
          height: 50px;
        }
        .v1_3-info{
          float: left;
          position: relative;
          
          .tooltip{
             position: absolute;
             padding: 10px;
             top: -50px;
             left: 200px;
             z-index: 99;
             font-size: 12px;
             word-break: break-all;
             white-space: pre-wrap; 
             line-height: 1.6em;
             color: #fff;
             border-radius: 5px;
             background: #000;
             min-height: 100px;
             min-width: 300px;
             max-width: 300px !important;
             overflow-y: scroll;
             -ms-overflow-style: none;
             scrollbar-width: none;
          }
          .tooltip::-webkit-scrollbar {
            display: none;
            
          }
          .tooltip:before {
            content: "";
            position: absolute;
            top: 50px;
            right: 99%; 
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent; 
            border-right: 20px solid #000;
            margin-left: -30px;   
            z-index: 99;
          }
        }
        .v1_3-info-name{
          margin-top: 10px;
          margin-right: 10px;
        }
        .btn-copy{
          width:40px;
        }
        
        .education-pack-info{
          float: left;
          position: relative;
          
          .tooltip{
             position: absolute;
             padding: 10px;
             top: -50px;
             left: 200px;
             z-index: 99;
             font-size: 12px;
             word-break: break-all;
             white-space: pre-wrap; 
             line-height: 1.6em;
             color: #fff;
             border-radius: 5px;
             background: #000;
             min-height: 100px;
             min-width: 300px;
             max-width: 300px !important;
             overflow-y: scroll;
             -ms-overflow-style: none;
             scrollbar-width: none;
          }
          .tooltip::-webkit-scrollbar {
            display: none;
            
          }
          .tooltip:before {
            content: "";
            position: absolute;
            top: 50px;
            right: 99%; 
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent; 
            border-right: 20px solid #000;
            margin-left: -30px;   
            z-index: 99;
          }
        }
        .education-pack-info-name{
          margin-top: 10px;
          margin-right: 10px;
          width: 35%;
        }
        .education-pack-info-value{
          width: 60%;
        }
      }

      &.conf-form {
        .form-wrap {

          .form-group {
            padding-right: 0;
          }
        }
      }
      &.conf-form{
        table{
          tr{
            th:first-child{
              padding-right: 15px;
            }
          }
        }
      }
    }
  }

  .btn-setting{
    //font-weight: initial;
    font-weight: normal;
    margin: auto;
    text-align: center;
    border: #468CC8 solid 1px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    display: inline-block;
    width: 90px;
    height: 40px;
    line-height: 40px;
    color: #468CC8;
    -webkit-transition: opacity .3s;
    -moz-transition: opacity .3s;
    -ms-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s;
    margin-left: 50px;
    &.btn-lst-ip{
      width: 170px;
    }
    &.btn-lti-config{
      width: 170px;
    }
    &.disabled{
      pointer-events: none;
      border: 2px solid #aaa;
      background-color: #eee;
      color: #aaa;
      cursor: default;
    }
  }
}
}
.warning-modal {
	position: fixed;
    top: 65%;
    left: 55%;
    z-index: 11002;
    width: 440px;
    margin: -250px 0 0 -280px;
    text-align: center;
    background: #fff;
    
    .btn-submit {
    	width: 40%;
    	margin-top: 5px;
    }
}