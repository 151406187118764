
@charset "UTF-8";
.modal-report-csv-content-confirmation {
    .m-modal {
        .modal-inner {
            padding: 0px;
            top:10%;
            .modal-header-ccm {
                height: 45px;
                font-size: 18px;
                line-height: 35px;
                color: #6e6e6e;
                display: table;
                width: 100%;
                -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
                -moz-box-sizing: border-box;    /* Firefox, other Gecko */
                box-sizing: border-box;         /* Opera/IE 8+ */
                &> {
                    div{
                        color: #82bef0;
                        display: table-cell;
                        vertical-align: middle;
                        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
                        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
                        box-sizing: border-box;         /* Opera/IE 8+ */
                    }
                }
                .left,.right {
                    width: 60px;
                }
                .left {
                    text-align: left;
                    padding-left: 10px;
                }
                .right {
                    text-align: right;
                    padding-right: 15px;
                }

            }
            .line-header {
                height: 1px;
                background-color: #4f81bd;
                box-shadow: 0px 1px #888888;
            }
            &.modal-inner-CSVimport {
                width: 670px;
                .areError {
                    min-height: 40px;
                    margin-top: 15px;
                    width: 100%;
                    display:table;
                    padding-left: 10%;
                    padding-right: 9%;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    &> div{
                        display: table-cell;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                    }
                    .icon-error {
                        width: 41px;
                        padding-right: 5px;
                        img {
                            width: 40px;
                            height: 40px;
                        }
                        
                    }
                    .message-error{
                        text-align: left;
                        height: 100%;
                        vertical-align: middle;
                        padding-left: 5px;
                        padding-right: 5px;
                        max-width: 300px;
                        .error {
                            color: red;
                            word-wrap: break-word; // for IE11
                            overflow-wrap: break-word;
                        }
                    }
                    .btn-download {
                        width: 200px;
                        .sel.sel-size-auto {
                            width: 200px !important;
                            height: 30px;
                            .sel-txt {
                                line-height: 29px;
                            }
                        }
                    }
                }
                .dagdrop {
                    width: 80%;
                    height: 250px;
                    left: 10%;
                    position: relative;
                    border: 4px dashed #c8c8c8;
                    margin-bottom: 15px;
                    margin-top: 15px;
                    background: #f1f1f1;
                    .image-text {
                        display: -webkit-flex;
                        -webkit-align-items: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                    }
                    .dagdrop-text {
                        color:#c8c8c8 ;
                        font-size: 18px;
                        width: 250px;
                    }

                    @media screen and (max-width : 1366px) {
                        @media screen and (max-height : 768px) {
                            height: 200px;
                            border: 4px dashed #c8c8c8;
                            .dagdrop-text {
                            color: #c8c8c8;
                            font-size: 16px;
                            width: 222px;
                            }
                        }
                    }
                }
                .dagdrop::before {
                    display: block;
                    content: "";
                    width: 90px;
                    height: 90px;
                    position: absolute;
                    top: -6px;
                    left: -6px;
                    border-top: 7px solid #c8c8c8;
                    border-left: 7px solid #c8c8c8;
                    @media screen and (max-width : 1366px) {
                        @media screen and (max-height : 768px) {
                        width: 50px;
                        height: 50px;
                        position: absolute;
                        top: -5px;
                        left: -5px;
                        border-top: 5px solid #c8c8c8;
                        border-left: 5px solid #c8c8c8;
                        }
                    }
                }
                .dagdrop::after {
                    display: block;
                    content: "";
                    width: 90px;
                    height: 90px;
                    position: absolute;
                    top: -6px;
                    right: -6px;
                    border-top: 7px solid #c8c8c8;
                    border-right: 7px solid #c8c8c8;
                    @media screen and (max-width : 1366px) {
                        @media screen and (max-height : 768px) {
                        width: 50px;
                        height: 50px;
                        position: absolute;
                        top: -5px;
                        right: -5px;
                        border-top: 5px solid #c8c8c8;
                        border-right: 5px solid #c8c8c8
                        }
                    }
                }
                .border-dagdrop::before {
                    display: block;
                    content: "";
                    width: 90px;
                    height: 90px;
                    position: absolute;
                    bottom: -6px;
                    left: -6px;
                    border-bottom: 7px solid #c8c8c8;
                    border-left: 7px solid #c8c8c8;
                    @media screen and (max-width : 1366px) {
                        @media screen and (max-height : 768px) {
                            width: 50px;
                            height: 50px;
                            position: absolute;
                            bottom: -5px;
                            left: -5px;
                            border-bottom: 5px solid #c8c8c8;
                            border-left: 5px solid #c8c8c8;
                        }
                    }
                }
                .border-dagdrop::after {
                    display: block;
                    content: "";
                    width: 90px;
                    height: 90px;
                    position: absolute;
                    bottom: -6px;
                    right: -6px;
                    border-bottom: 7px solid #c8c8c8;
                    border-right: 7px solid #c8c8c8;
                    @media screen and (max-width : 1366px) {
                        @media screen and (max-height : 768px) {
                            width: 50px;
                            height: 50px;
                            position: absolute;
                            bottom: -5px;
                            right: -5px;
                            border-bottom: 5px solid #c8c8c8;
                            border-right: 5px solid #c8c8c8;
                        }
                    }
                }
                .chooseFile {
                    min-height: 50px;
                    width: 100%;
                    display:table;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    &> div{
                        display: table-cell;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        width: 50%;
                    }
                    div:first-child {
                        text-align: right;
                        position: relative;
                        width: 35%;
                        .btn-next-gray-modal{
                            z-index: 69;
                            cursor: pointer;
                        }
                        #jsCSVUploadFile{
                            position:  absolute;
                            width: 127px;
                            height: 25px;
                            z-index: 99;
                            filter: alpha(opacity=0);
                            opacity: 0.0;
                            font-size: 30px;
                            cursor: pointer;
                            overflow: hidden;
                            right: 0;
                            &:disabled {
                              cursor: default;
                            }
                        }
                    }
                    div:last-child {
                        text-align: left;
                        padding-left: 10px;
                        padding-right: 10px;
                        .fileName {
                            height: 23px;
                            width: 275px;
                            &:disabled {
                              background: #eee;
                            }
                        }
                    }
                    
                }
                .btns-bottom{
                    height: 50px;
                }
            }
            &.modal-inner-comfirm {
                width: 80%;
                height: 80%;
                color: #6e6e6e;
                .mid-error-btns {
                    height: 40px;
                    padding-top: 20px;
                    display: table;
                    width: 100%;
                    -webkit-box-sizing: border-box; 
                    -moz-box-sizing: border-box;
                    box-sizing: border-box; 
                    .csvLocation {
                        text-align:left;
                        max-width: 650px!important;
                        display: table-cell;
                        -webkit-box-sizing: border-box; 
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        padding-left: 22px;
                    }
                    .areaTypeImport {
                        display: table-cell;
                        float: right;
                        -webkit-box-sizing: border-box; 
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        padding-right: 45px;
                        &>div{
                            display: table;
                            &>div{
                                display: table-cell;
                            }
                        }
                        .typename {
                            vertical-align: middle;
                            padding-right: 10px;
                        }
                        .typecode {
                            vertical-align: middle; 
                            margin-right: 5px;
                            .sel {
                                background-color: white;
                                -webkit-border-radius: 0px;
                                -moz-border-radius: 0px;
                                border-radius: 0px;
                                border: 1px solid;
                                width: 130px;
                            }
                            .sel.sel-size-auto {
                                height: 28px;
                                width: 130px !important;
                                border:1px solid #a0a0a0;
                                .sel-txt {
                                    background-size: 18px;
                                    line-height: 28px;
                                    background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
                                }
                            }
                        }
                    }
                }
                
                .course-result-f {
                    background-color: #d2ebfa;
                    height: calc(100% - 176px);
                    padding: 20px 20px 20px 10px!important;
                    position: relative;
                    -webkit-box-sizing: border-box; 
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    .scroll-area {
                        margin-top: 48px;
                        &.ps-active-x {
                            padding-bottom: 5px;
                        }
                        .ps-scrollbar-y-rail{
                            right: 0;
                            margin-bottom: 6px;
                            opacity: .4 !important;
                        }

                        .ps-scrollbar-x-rail{
                            height: 7px !important;
                            opacity: .4 !important;
                        }
                        .ps-scrollbar-x{
                            height: 100%;
                            bottom: 0;
                        }
                        height: 100%;
                        width: 100%;
                    } // .scroll-area
                    .modal-import-list-inner{
                        min-height: 100%;
                        min-width: 100%;
                        
                    }
                    .modal-import-list-outer{
                        position: absolute;
                        left: 95px;
                        width: calc(100% - 140px);
                        height: calc(100% - 98px);;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        border: none;
                    }
                    .modal-import-list-outer:hover .ps-scrollbar-x,
                    .modal-import-list-outer:hover .ps-scrollbar-y{
                        position: absolute;
                        background-color: black;
                        -webkit-border-radius: 6px;
                        -moz-border-radius: 6px;
                        border-radius: 6px;
                        -webkit-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
                        transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
                        -moz-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
                        -o-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
                        transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
                        transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -webkit-border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
                    }

                    @include search_table_f;
                    .init-requied {
                        position: absolute;
                        margin-top: -15px;
                        margin-left: 1120px;
                        color: red;
                        font-size: 11px;
                        
                    }
                    table {
                        table-layout: auto;
                        tbody{
                        width: 100%;
                        height: 100%;
                        }
                        thead{
                            width: 860px;
                        }
                    }
                    tr{
                        td,th{
                        min-width: 200px !important;
                        }
                        &.header{   
                            position: fixed;  
                            width: 1050px;
                            touch-action: none;
                            overflow: hidden !important;
                            height: 57px;
                            .ps-scrollbar-x-rail{
                            display: none;
                            }
                            .required {
                            position: absolute;
                            margin-top: -3px;
                            font-size: 12px;
                            color: red;
                            }

                            label::after {
                                width: 12px!important;
                                height: 12px!important;
                            }
                            label::before {
                                width: 7px!important;
                                height: 3px!important;
                                top: 4px!important;
                                left: 3px!important;
                            }    

                        }
                    }

                    tr:first-child th {
                        line-height: 23px;
                        background: #d2ebfa !important;
                        border-left-color: #d2ebfa!important;
                        border-right-color: #d2ebfa!important;
                        border-bottom-color: #d2ebfa !important;
                        padding: 5px;
                        color: #424242;
                    }
                    tr:nth-child(2n) td {
                        background: #fafafa;
                        line-height: 35px;
                        width: 300px;            
                    }

                    td {
                        background: #fff;
                        line-height: 35px;
                    }
                    tr:first-child td {
                        border-top: 0px solid #fafafa;             
                    }
                    .nameCSV {
                        position: absolute;
                        text-align: right;
                        width: 80px;
                        top: 17px;
                        label::after {
                            width: 12px!important;
                            height: 12px!important;
                        }
                        label::before {
                            width: 7px!important;
                            height: 3px!important;
                            top: 4px!important;
                            left: 3px!important;
                        }
                    }
                }
                .btns-bottom {
                    height: 75px;
                    display: -webkit-flex;
                    -webkit-align-items: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            &.modal-inner-comfirmerror {
                width: 80%;
                height: 80%;
                .totalAction {
                    margin-top: 15px;
                    table {
                        text-align: center;
                        margin: 0 auto;
                        color: #6e6e6e;
                        font-size: 14px;
                        th {
                            padding-left: 15px;
                            padding-right: 15px;
                        }
                        .number {    
                            font-size: 35px;
                            font-weight: bold;
                            color: #848484;
                        }
                    }
                }
                .are-error {
                    height: calc(100% - 192px);
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    padding-bottom: 16px;
                    padding-top: 4px;
                    background: #d2ebfa;
                    color: #6e6e6e;
                    .warning-text, .error-text {
                        text-align: left;
                        margin-left: 30px;
                    }
                    .table-warning,.table-error {
                        width: 100%;
                        text-align: left;
                        td {
                            padding-left: 10px;
                        }
                        tr:first-child td {
                                padding-top: 10px;
                        }
                    }
                    .table-error {
                        color: red;
                    }
                    
                    .scroll-area {
                        height: calc(50% - 22px);
                        width: 96%;
                        margin: 0 auto;
                        border: 1px solid #a0a0a0; 
                        background: white;   
                    }
                    .ps-scrollbar-y-rail {
                        right: 1px;
                    }

                }
                .modal-btns{
                    height: 75px;
                    display: -webkit-flex;
                    -webkit-align-items: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .btn {
                        margin: 12px;
                    }
                }
            }
            &.modal-inner-confirm-mail {
                width: 60%;
                min-height: 30%;
                .modal-btns{
                    height: 75px;
                    display: -webkit-flex;
                    -webkit-align-items: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .btn {
                        margin: 12px;
                    }
                }
                .opt-right {
                  min-width: 40%;
                  align-items: center;
                  padding: 5px;
                  font-size: 14px;
                }
                .report-mail-sending {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-wrap: wrap;
                  label {
                    padding-left: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    div {
                      margin-right: 5px;
                    }
                  }
                  .rd-btn {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    label {
                      padding-left: 24px;
                      &::after {
                        top: 5px;
                        left: 15px;
                        width: 14px !important;
                        height: 14px !important;
                      }
                      &::before {
                        top: 8px;
                        left: 17px;
                        width: 11px !important;
                        height: 5px !important;
                      }
                    }
                  }
                  .btn-img {
                    width: 32px;
                    height: 32px;
                    img{
                      padding-left: 15px;
                      width: 30px;
                      height: 30px;
                    }
                  }
                }
                .hints .hint-btn {
                  background: url(../../img/icon/icn_hints_bk.png) no-repeat;
                  padding-left: 55px;
                   background-position: 50%;
                   -webkit-background-size: 20px;
                   background-size: 20px;
                }
                .hints .hint-content {
                  position: absolute;
                  display: none;
                  width: auto;
                  min-width: 250px;
                  max-width: 290px;
                  background: #d3edfa;
                  padding: 10px;
                  font-size: 12px;
                  margin-top: 3px;
                  margin-left: 55px;
                  z-index: 999;
                }
            }
            &.confirm-link-modal {
                padding: 20px;
            }
        }
    }
}