@charset "UTF-8";
.page-log-search-screen-operation {
        height: 100%;
  .m-search-c-inner {
    iframe {
      width: 100vw;
      height: 100vh;
    }
  }
    .page-inner {
        background: $background_theme_color_contents;
            height: 100%;
        .page-contents {
            width: 100%;
            height: 100%;
            margin: auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
             .m-form-search {
                    padding: 0;
                    .error {
                        margin: 1px 0 1px 0;
                    }
                .form-group {

                    .inner {
                        width: 80%;
                        tr {
                            height: 50px;
                            input{
                              height: 32px;
                            }
                        } 
                        .form-row{
                            .layout>*{
                                width: 100% !important; 
                                margin-bottom: 0;
                            }
                            .form-btns{
                                display: flex;
                                justify-content: center;
                            }
                            .inputs{
                                display: flex;
                                justify-content: center;
                                margin-top: 50px;
                                margin-bottom: 50px;
                                .col1{
                                    width: 40%;
                                    min-width: 340px;
                                    .input-user-name{
                                        display: flex;
                                        .user-name{
                                            display: flex;
                                            align-items: center;
                                            #userNameMain{
                                            	white-space: nowrap !important;
                                                overflow: hidden;
                                                text-overflow: ellipsis !important;
                                                word-wrap: normal !important;
                                                max-width: 200px;
                                            }
                                        }
                                    }
                                    .user-name-err{
                                    	text-align: left;
                                    }
                                }
                                .col2{
                                    width: 40%;
                                    min-width: 560px;
                                    .operationDate{
                                        width: 100%;
                                        display: flex;
                                        height: 30px;
                                        align-items: center;
                                        .search-sub-ttl{
                                            margin-right: 5px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                        }
                                        .date-input{
                                            width: 200px;
                                            display: flex;
                                            .btn-cal{
                                                margin-left: 5px;
                                            }
                                            input{
                                                max-width: 77%;
                                            }
                                        }
                                        .date-input>*{
                                    	    height: 32px;
                                        }
                                    }
                                    .until{
                                    	margin-right: 9px;
                                    	height: auto !important;
                                    }
                                    .operationDate>*{
                                    	height: 32px;
                                    }
                                    .operation-date-err{
                                        text-align: left;
                                        width: 200px;
                                    }
                                }
                                
                            }
                        }
                        
                    }
                    .div-err {
                        text-align: right;
                    }
                }
            }
                        
        }
      .result-header {
        padding-bottom: 5px;

        .except {
          height: 71px;
          display: none;
        }

        .download {
          position: absolute;
          right: 0px;
          margin-top: 5px;
          margin-right: 10px;
          min-height: 30px;
          padding-bottom: 5px;
        }

        .result-txt {
          margin-top: 10px;
          margin-left: 10px;
          min-height: 25px;
          display: inline-block;
          align-items: center;
        }


      }


        .log-result {
            @include search_table_f;
          padding: 0;
          margin: 0 10px;
            position: relative;
          height: 61%;

            @media screen and (max-height: 980px) {
                height : 59.5%;
            }
            @media screen and (max-height: 950px) {
                height : 58.4%;
            }
            @media screen and (max-height: 925px) {
                height : 57%;
            }
            @media screen and (max-height: 900px) {
                height : 55%;
            }
            @media screen and (max-height: 850px) {
                height : 53%;
            }
            @media screen and (max-height: 825px) {
                height : 51%;
            }
            @media screen and (max-height: 800px) {
                height : 50%;
            }
            @media screen and (max-height: 775px) {
                height : 48%;
            }
            @media screen and (max-height: 750px) {
                height : 47%;
            }
            @media screen and (max-height: 725px) {
                height : 43%;
            }
            @media screen and (max-height: 700px) {
                height : 41.5%;
            }
            @media screen and (max-height: 685px) {
                height : 40.5%;
            }
            @media screen and (max-height: 675px) {
                height : 39.3%;
            }
            @media screen and (max-height: 650px) {
                height : 39%;
            }
            @media screen and (max-height: 630px) {
                height : 33%;
            }
            @media screen and (max-height: 600px) {
                height : 30%;
            }


        .table-header{
            table-layout: auto;
                tr{
                    width: 100%;
                    position: fixed;
                    th{
                        padding: 5px;
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                    th:nth-child(1),th:nth-child(2),th:nth-child(3){
                    min-width: 140px;
                    }
                    th:nth-child(4){
                        min-width: 175px;
                    }
                    th:nth-child(5){
                        min-width: 141px;
                        
                    }
                    th:nth-child(6){
                        min-width: 116px;
                    }
                }
            }
          .table-result{
                table-layout: auto;
                margin-top: 33px;
                tr{
                    td:not(.row-ope){
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                }
            }

            table {

                td:nth-child(1),td:nth-child(2){
                    width: 9%;
                    min-width: 140px;
                }
                tr td:nth-child(3){
                    width: 13%;
                    min-width: 175px;
                }
                tr td:nth-child(4){
                    width: 13%;
                    min-width: 175px;
                }
                tr td:nth-child(5){
                    width: 13%;
                    min-width: 175px;
                    max-width: 175px;
                    white-space: nowrap !important;
                    overflow: hidden;
                    text-overflow: ellipsis !important;
                    word-wrap: normal !important;
                }
                tr td:nth-child(6){
                    width: 17%;
                    min-width: 116px;
                    max-width: 116px;
                    white-space: nowrap !important;
                    overflow: hidden;
                    text-overflow: ellipsis !important;
                    word-wrap: normal !important;
                }
                tr td:nth-child(7){
                    width: 13%;
                    min-width: 116px;
                }
                tr td:nth-child(8){
                    width: 13%;
                    min-width: 116px;
                }
              }

            .table-result-nodata{
                height: 90%;
                
                background: white;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 0px !important;
                tr{
                    td{
                        
                        color: #AFAFAF;
                    }
                }
            }
              
        }

      .result-list-inner{
        height: 99.9%;
        background-color: #fff;
      }
      .log-list-outer{
        width: 100%;
        height: 90%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }
      .scroll-area {
        &.ps-active-y {
          padding-right: 16.2px;
          margin-right: -16.2px;
        }
        .ps-scrollbar-y-rail{
          right: 16.2px;
        }
        height: 100%;
      } // .scroll-area
    }
   
    
    // loading 
    .loader {
    position: absolute;
    left: 50%;
    top: 60%;
    z-index: -999999;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    
    }

    @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }
    /* Add animation to "page content" */
    .animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
    }

    @-webkit-keyframes animatebottom {
    from { bottom:-100px; opacity:0 } 
    to { bottom:0px; opacity:1 }
    }

    @keyframes animatebottom { 
    from{ bottom:-100px; opacity:0 } 
    to{ bottom:0; opacity:1 }
    }
}


.btns-info{
        .modal-inner.modal-inner-content{
            // margin-top: -(screen.height - this)px !important; 
            top: 50%;
            left: 50%;
            position:fixed;
            transform: translate(-50%, -50%);
        }

}


