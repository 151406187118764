@charset "UTF-8";

.course-attend-multiple-chapter-result {
  .header-1st, .col-1st {
    min-width: 13% !important;
    width: 13%;
  }

  .header-2nd, .col-2nd {
    min-width: 13% !important;
    width: 13%;
  }

  .header-3rd, .col-3rd {
    min-width: 7% !important;
    width: 7%;
    &.check {
      background-image: url(#{$IMG_PATH:}icon/icn_check_b.png) !important;
      -webkit-background-size: 25% !important;
      background-size: 25px !important;
      background-repeat: no-repeat !important;
      background-position: 50% !important;
    }
  }

  .header-4th, .col-4th {
    min-width: 12% !important;
    width: 12%;
  }

  .header-5th, .col-5th {
    min-width: 12% !important;
    width: 12%;
  }
  
  .header-6th, .col-6th {
    min-width: 7% !important;
    width: 7%;
  }

  .header-7th, .col-7th {
    min-width: 6% !important;
    width: 6%;
    .be-late-score {
      color: red;
      text-decoration: line-through;
    }
  }
  
  .header-8th, .col-8th {
    min-width: 6% !important;
    width: 6%;
  }

  .header-9th, .col-9th {
    min-width: 6% !important;
    width: 6%;
  }

  .header-10th, .col-10th {
    min-width: 6% !important;
    width: 6%;
  }

  .header-11th, .col-11th {
    min-width: 12% !important;
    width: 12%;
  }

  .header-13th, .col-13th {
    min-width: 7% !important;
    width: 7%;
    &.check {
      background-image: url(#{$IMG_PATH:}icon/icn_check_b.png) !important;
      -webkit-background-size: 25% !important;
      background-size: 25px !important;
      background-repeat: no-repeat !important;
      background-position: 50% !important;
    } 
  }
  
  .header {
    cursor: auto !important;
  }

  .td-9th {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .btn-download {
    width: 100% !important;
    //width: 55px !important;
    background-position-x: calc(10%);
    border-radius: 4px;
}
}

// .course-result {
//   .sumc-table, .table-attend-multiple-chapter-header {
//     table-layout: fixed;
//     width: 100% !important;
    
//     .col-1st {
//       width: 17% !important;
//     }

//     .col-2nd {
//       width: 9% !important;
//     }

//     .col-3rd {
//       width: 17% !important;
//     }

//     .col-4th {
//       width: 12% !important;
//     }

//     .col-5th {
//       width: 11% !important;
//     }

//     .col-6th {
//       width: 8% !important;
//     }

//     .col-7th {
//       width: 7% !important;
//     }

//     .col-8th {
//       width: 90px !important;
//       height: 22px !important;
//     }

//     .col-9th {
//       width: 7% !important;
//     }

//     .col-10th {
//       width: 100px !important;
//     }

//     .col-11th {
//       width: 100px !important;
//     }

//     .td-8th {
//       padding-left: 15px !important;
//       padding-right: 15px !important;
//     }

//     @media screen and (max-width: 1700px) {
//       .td-8th {
//         padding-left: 10px !important;
//         padding-right: 10px !important;
//       }
//     }

//     @media screen and (max-width: 1366px) {
//         .col-3rd {
//           width: 15% !important;
//         }

//         .col-6th {
//           width: 10% !important;
//         }

//         .col-7th {
//           width: 70px !important;
//         }

//         .col-10th {
//           width: 100px !important;
//         }

//         .col-8th {
//           width: 70px !important;
//           min-width: 70px !important;
//           height: 22px !important;
//         }

//         .td-8th {
//           padding-left: 3px !important;
//           padding-right: 3px !important;
//         }
//     }

//     @media screen and (max-width: 1024px) {
//         .col-1st {
//           width: 15% !important;
//         }

//         .col-3rd {
//           width: 15% !important;
//         }

//         .col-5th {
//           width: 10% !important;
//         }

//         .col-6th {
//           width: 10% !important;
//         }

//         .col-7th {
//           width: 70px !important;
//         }

//         .col-10th {
//           width: 100px !important;
//         }

//         .col-8th {
//           width: 70px !important;
//           min-width: 70px !important;
//           height: 22px !important;
//         }

//         .td-8th {
//           padding-left: 5px !important;
//           padding-right: 5px !important;
//         }
//     }

    
    
//     .btn-download {
//         width: 100% !important;
//         //width: 55px !important;
//         background-position-x: calc(10%);
//         border-radius: 4px;
//     }
//   }
// }

.page-courses-search {
  .page-inner {
    .page-contents {
      .no-data {
        padding-bottom: 20px;
      }

      .course-attend-multiple-chapter-result {
        min-height: 85% !important;
        background-color: white !important;
        padding: 0px !important;
        margin: 0px 10px 0px 10px !important;

        .ps-scrollbar-y-rail {
          right: 0px !important;
        }
      }
    }
  }
}