@charset "UTF-8";


@mixin sts_notice(){
  &.notice {
    &:before {
      @include PseudoElements();
      width: 10px;
      height: 10px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      background: $accent_color_default;
      position: absolute;
      @content;
    }
  } // .notice
}