@charset "UTF-8";

/*
#overview
btnスタイル

*/

/*
#styleguide
btn

```
<a href="#" class="btn btn-size-m btn-red">更新</a>
<a href="#" class="btn btn-size-m btn-orange">進む</a>
<a href="#" class="btn btn-size-m btn-gray">戻る</a>
<a href="#" class="btn btn-size-m btn-gray">検索</a>
<a href="#" class="btn btn-size-m btn-g-border">詳細</a>
```
*/

button.btn,
input.btn,
a.btn {
  border: none;
  @include HoverOpacity();
  display: inline-block;
  color: $theme_color_default;
  font-weight: bold;

  &.with-img {
    position: relative;

    img {
      vertical-align: middle;
      margin-right: 15px;
      margin-left: -40px;
      position: relative;
      top: -2px;
      width: 25px;

      &.left {
        margin-left: 0;
        margin-right: 0;
        position: absolute;
        left: 10px;
        top: 50%;
        margin-top: -13px;
      }
    }

  }

  &.btn-search {
    background: $accent_color_dark url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
    -webkit-background-size: 20px;
    background-size: 20px;
    color: #fff;
    border: none;
    font-weight: normal;
    width: 200px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    font-size: 16px;
    padding: 0;
  }

  &.btn-chapter-start {
    position: relative;
    background: $accent_color_dark;
    width: 100%;
    height: 34px;
    line-height: 34px;
    font-size: 16px;
    color: #fff;
    font-weight: normal;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

    &:after {
      @include PseudoElements();
      position: absolute;
      width: 16px;
      height: 16px;
      background: url(#{$IMG_PATH}common/arrow_btn_chapter_start.png) left top no-repeat;
      right: 10px;
      top: 50%;
      margin-top: -8px;
    }
  }

  &.btn-chapter-detail {
    background: $theme_color_light;
    width: 100%;
    height: 34px;
    line-height: 34px;
    color: #fff;
    font-weight: normal;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    font-size: 13px;
  }

  &.btn-start {
    @extend .btn-chapter-start;
    width: 180px;
    text-align: center;
  }

  &.btn-upl {
    background: $accent_color_dark;
    position: relative;
    width: 180px;
    height: 34px;
    line-height: 34px;
    font-size: 16px;
    color: #fff;
    font-weight: normal;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;

    &:after {
      @include PseudoElements();
      position: absolute;
      width: 16px;
      height: 16px;
      background: url(#{$IMG_PATH}icon/icn_upload_w.png) left top no-repeat;
      -webkit-background-size: contain;
      background-size: contain;
      right: 10px;
      top: 50%;
      margin-top: -8px;
    }
  }

  &.btn-dll {
    background: $theme_color_light;
    position: relative;
    width: 180px;
    height: 34px;
    line-height: 34px;
    font-size: 16px;
    color: #fff;
    font-weight: normal;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;

    &:after {
      @include PseudoElements();
      position: absolute;
      width: 16px;
      height: 16px;
      background: url(#{$IMG_PATH}icon/icn_download_w.png) left top no-repeat;
      -webkit-background-size: contain;
      background-size: contain;
      right: 10px;
      top: 50%;
      margin-top: -8px;
    }
  }

  &.btn-gray {
    background: #999999;
    position: relative;
    width: 240px;
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    color: $font_color_default;
    font-weight: normal;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;

    &.btn-size-l {
      width: 260px;
    }

  }

  &.btn-clear {
    border: 1px solid #c8c8c8;
    background: url(#{$IMG_PATH}icon/icn_formclear_k.png) 20px center no-repeat;
    background-size: 22px;
    position: relative;
    width: 150px;
    height: 33px;
    font-size: 16px;
    color: $font_color_default;
    font-weight: normal;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    font-size: 12px;
    padding-left: 27px;

    &.btn-size-l {
      width: 260px;
    }
  }

  &.btn-memo {
    border: 1px solid #c8c8c8;
    background: #fff;
    position: relative;
    width: 50px;
    height: 30px;
    font-size: 16px;
    color: $font_color_default;
    font-weight: normal;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    font-size: 12px;
    margin: 8px 0 8px 164px;
  }

  &.btn-back-icon {
    background: url(#{$IMG_PATH}icon/icn_back_k.png) 15px center no-repeat;
    background-size: 22px;
    position: relative;
    width: 160px;
    height: 30px;
    font-size: 16px;
    color: $font_color_default;
    font-weight: normal;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: left;
    font-size: 13px;
    padding-left: 35px;

    &.btn-size-l {
      width: 260px;
    }

    &.btn-size-s {
      width: 30px;
      padding: 0;
      margin-left: 15px;
      background-position: center;
    }

  }

  &.btn-switch {
    border: 1px solid #c8c8c8;
    background: url(#{$IMG_PATH}icon/icn_reload_k.png) 13px center no-repeat;
    background-size: 22px;
    position: relative;
    width: 175px;
    height: 33px;
    font-size: 16px;
    color: $font_color_default;
    font-weight: normal;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    font-size: 12px;
    padding-left: 35px;

    &.btn-size-l {
      width: 260px;
    }

  }

  &.btn-search-icon {
    background: url(#{$IMG_PATH}icon/icn_search_w.png) 50% center no-repeat;
    background-color: #82BEF0;
    background-size: 22px;
    position: relative;
    width: 145px;
    height: 33px;
    line-height: 44px;
    font-size: 16px;
    color: $font_color_default;
    font-weight: normal;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;

    &.btn-size-l {
      width: 260px;
    }

  }

  &.btn-search-sub {
    border: 1px solid #c8c8c8;
    background: url(#{$IMG_PATH}icon/icn_courses_k.png) 50% center no-repeat;
    background-color: white;
    background-size: 22px;
    position: relative;
    width: 40px;
    height: 33px;
    line-height: 44px;
    font-size: 16px;
    color: $font_color_default;
    font-weight: normal;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    margin-left: 5px;

    &.btn-size-l {
      width: 260px;
    }

  }

  &.btn-lti-tool-search-sub {
    border: 1px solid #c8c8c8;
    background: url(#{$IMG_PATH}icon/icn_courses_k.png) 50% center no-repeat;
    background-color: white;
    background-size: 22px;
    position: relative;
    width: 40px;
    height: 33px;
    line-height: 44px;
    font-size: 16px;
    color: $font_color_default;
    font-weight: normal;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    margin-left: 5px;

    &.btn-size-l {
      width: 260px;
    }

  }

  &.btn-search-user-sub {
    border: 1px solid #c8c8c8;
    background: url(#{$IMG_PATH}icon/icn_user_k.png) 50% center no-repeat;
    background-color: white;
    background-size: 22px;
    position: relative;
    width: 40px;
    height: 33px;
    line-height: 44px;
    font-size: 16px;
    color: $font_color_default;
    font-weight: normal;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    margin-left: 5px;

    &.btn-size-l {
      width: 260px;
    }

  }

  &.btn-regref {
    border: 1px solid #c8c8c8;
    background: url(#{$IMG_PATH}icon/icn_regref_m.png) 50% center no-repeat;
    background-color: white;
    background-size: 45px;
    position: relative;
    width: 59px;
    height: 51px;
    line-height: 44px;
    color: $font_color_default;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    margin: 0 0 0 25px;
  }

  &.btn-to-cerref {
    border: 1px solid #c8c8c8;
    background: url(#{$IMG_PATH}icon/icn_cerref.png) 8% center no-repeat;
    background-color: white;
    background-size: 32px;
    position: relative;
    width: 130px;
    height: 42px;
    line-height: 44px;
    color: #333;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    margin: 14px 14px 0 14px;
    font-weight: normal;
    font-size: 10px;
    padding-left: 15px;
  }

  &.btn-to-no-cerref {
    border: 1px solid #c8c8c8;
    background: url(#{$IMG_PATH}icon/icn_cerref.png) 4% center no-repeat;
    background-color: white;
    background-size: 23px;
    position: relative;
    width: 84px;
    height: 26px;
    line-height: 28px;
    color: #333;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    margin: 2px 0 0 10px;
    font-weight: normal;
    font-size: 10px;
    padding-left: 15px;
  }

  &.btn-cerref-l {
    background: url(#{$IMG_PATH}icon/icn_cerref.png) 50% center no-repeat;
    background-color: white;
    background-size: 45px;
    position: relative;
    width: 55px;
    height: 47px;
    line-height: 44px;
    color: #333;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    margin: 0 38px;
  }

  &.btn-regref-l {
    //border: 1px solid #c8c8c8;
    //background: url(../img/icon/icn_regref_m.png) 50% center no-repeat;
    background: url(#{$IMG_PATH}icon/icn_regref.png) 50% center no-repeat;
    background-color: white;
    background-size: 45px;
    position: relative;
    width: 55px;
    height: 47px;
    line-height: 44px;
    color: #333;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    margin: 0 38px;
  }

  &.btn-download {

    background-color: white;
    background: url(#{$IMG_PATH}icon/icn_download_k.png) 13px center no-repeat;
    -webkit-background-size: 19px;
    background-size: 21px;
    border: 1px solid #c8c8c8;
    font-weight: normal;
    width: 180px;
    line-height: 26px;
    text-align: center;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    font-size: 12px;
    padding-left: 35px;
    background-color: white;
    color: #333;
    background-position-y: 2px;

    &.btn-size-l {
      width: 260px;
    }

    &.disable {
      background-color: #c8c8c8;
      cursor: default;
    }

  }

  &.btn-uptake {
    text-align: center;
    vertical-align: center;
    position: relative;
    background: #fff;
    border: 1px solid #DC780A;
    width: 200px;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    color: #DC780A;
    font-weight: normal;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    padding: 0;
  }

  &.btn-download-modal {

    background-color: white;
    // background: url(#{$IMG_PATH}icon/icn_download_k.png) 10px center no-repeat;
    // -webkit-background-size: 20px;
    // background-size: 20px;
    border: 1px solid $accent_color_default;
    font-weight: normal;
    width: 130px;
    height: 30px;
    text-align: center;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: 12px;
    // padding-left: 5px;  
    background-color: white;
    color: $accent_color_default;

    &.btn-size-l {
      width: 260px;
    }

    &.disable {
      background-color: #c8c8c8;
      cursor: default;
    }

  }

  &.btn-history {
    position: relative;
    background: #fff;
    border: 1px solid #82BEF0;
    width: 180px;
    height: 24px;
    line-height: 26px;
    font-size: 14px !important;
    color: #82BEF0;
    text-align: center;
    font-weight: normal;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;

    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    padding: 0;

    &.active {
      color: #fff;
      background: #82BEF0;
    }
  }

  &.btn-submit {
    position: relative;
    background: #fff;
    border: 1px solid $color_orange;
    width: 240px;
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    color: $color_orange;
    font-weight: normal;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;

    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    padding: 0;

    &.btn-blue {
      border: 1px solid $theme_color_light;
      color: $theme_color_light;
    }

    &.btn-gray {
      border: 1px solid #a0a0a0;
      color: #a0a0a0;
    }

    &.btn-size-m {
      width: 180px;
    }
  }

  &.btn-new-course {
    position: relative;
    background: #fff;
    border: 1px solid $color_orange;
    width: 200px;
    height: 34px;
    line-height: 34px;
    font-size: 16px;
    color: $color_orange;
    font-weight: normal;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }

  &.btn-submit-search {
    position: relative;
    background: transparent;
    border: 1px solid $theme_color_light;
    width: 240px;
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    color: $theme_color_light;
    font-weight: normal;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    padding: 0;
  }

  &.btn-back {
    position: relative;
    border: 1px solid #a0a0a0;
    width: 240px;
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    color: #a0a0a0;
    background-color: #fff;
    font-weight: normal;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }

  &.btn-close-modal-x {
    background: url(#{$IMG_PATH}icon/icn_close_k.png) left center no-repeat;
    background-size: 15px;
    width: 15px;
    height: 15px;
    cursor: pointer;

    &:hover {
      opacity: .7;
    }
  }

  &.btn-next-gray-modal,
  &.btn-next-choose-file-modal {
    border: 1px solid #a0a0a0;
    font-weight: normal;
    border-radius: 2px;
    font-size: 12px;
    padding-right: 5px;
    background-color: white;
    color: #6e6e6e;
    width: 125px;
    line-height: 29px;
    height: 30px
  }

  &.btn-next-blue-modal,
  &.btn-next-confirm-modal {
    color: #0f83c6;
    border: 1px solid #0f83c6;
    font-weight: normal;
    border-radius: 2px;
    font-size: 12px;
    padding-right: 5px;
    background-color: white;
    width: 125px;
    line-height: 29px;
    height: 30px
  }

  &.btn-next-orange-modal {
    color: #DC780A;
    border: 1px solid #DC780A;
    font-weight: normal;
    border-radius: 2px;
    font-size: 12px;
    padding-right: 5px;
    background-color: white;
    width: 125px;
    line-height: 29px;
    height: 30px
  }

  &.btn-back-modal-c {
    @include HoverOpacity();
    background: url(#{$IMG_PATH}icon/icn_back_k.png) left center no-repeat;
    background-size: 20px;
    width: 20px;
    height: 20px;
  }

  &.btn-refesh-charcode-modal {
    background: url(#{$IMG_PATH}icon/icn_reload_k.png) 20px center no-repeat;
    background-size: 90%;
    border: 1px solid #a0a0a0;
    background-position-x: 50%;
    width: 28px;
    height: 28px;
    vertical-align: middle;
  }

  &:disabled,
  &.btn-disabled {
    cursor: default;
    pointer-events: none;
    opacity: .3;

    &:after,
    &:before {
      opacity: .2;
    }

    &:hover {
      opacity: 1;
    }
  }

  &.btn-send-mail {
    background: url(#{$IMG_PATH}icon/icn_mailsend_bk.png) 8px center no-repeat;
    -webkit-background-size: 28px;
    background-size: 28px;
    background-position-y: 50% !important;
    border: 1px solid #c8c8c8;
    font-weight: normal;
    width: auto;
    min-width: 132px;
    text-align: center;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    font-size: 12px;
    padding-left: 35px;
    background-color: white;
    color: #333;
    background-position-y: 2px;
    line-height: 26px;
  }

  &.btn-force-exe {
    background-color: white;
    background: url(#{$IMG_PATH}icon/icon_force_exe.png) 6px center no-repeat;
    -webkit-background-size: 16px;
    background-size: 16px;
    border: 1px solid #c8c8c8;
    font-weight: normal;
    width: 72px;
    line-height: 18px;
    text-align: center;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    font-size: 9px;
    padding-left: 26px;
    background-color: white;
    color: #333;
    background-position-y: 2px;
    border-radius: 5px;
  }

  &.btn-download-histroy {
    background-color: white;
    background: url(#{$IMG_PATH}icon/icn_download_k.png) 13px center no-repeat;
    -webkit-background-size: 19px;
    background-size: 21px;
    border: 1px solid #c8c8c8;
    font-weight: normal;
    width: 180px;
    line-height: 26px;
    text-align: center;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    font-size: 12px;
    padding-left: 35px;
    background-color: white;
    color: #333;
    background-position-y: 2px;

    &.btn-size-l {
      width: 260px;
    }

    &.disable {
      background-color: #c8c8c8;
      cursor: default;
    }

  }

  &.btn-correct-percentage {
    border: 1px solid #c8c8c8;
    font-weight: normal;
    width: auto;
    min-width: 156px;
    text-align: center;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    font-size: 12px;
    padding-left: 0px;
    background-color: white;
    color: #333;
    line-height: 26px;
  }
  
  &.btn-freq-distribution {
    border: 1px solid #c8c8c8;
    font-weight: normal;
    width: auto;
    min-width: 156px;
    text-align: center;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    font-size: 12px;
    padding-left: 0px;
    background-color: white;
    color: #333;
    line-height: 26px;
  }
}

.ui-btns {
  margin: 20px 0;
  text-align: center;
  overflow: hidden;

  &:first-child {
    margin-top: 0;
  }

  &.left {
    text-align: left;

    .btn {
      margin-left: 0;
    }

  }

  .btn {
    margin: 0 7px;
  }

  & > div {
    width: 48%;
    float: right;

    &:first-child {
      float: left;
    }

    .btn {
      margin: 0;
    }
  }


}
