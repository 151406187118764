@charset "UTF-8";

.notice-search {
  height: 100%;

  .page-inner {
    background: $background_theme_color_contents;
    height: 100%;

    .page-contents {
      width: 100%;
      height: 100%;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      .no-data {
        min-height: 85%;
        margin: 0 10px;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;

        &.hide {
          display: none;
        }
      }

      .notice-result-header {
        padding-bottom: 5px;
          
        .except {
          height: 71px;
          display: none;
        }

        .result-txt {
          margin-top: 10px;
          margin-left: 10px;
          min-height: 25px;
          display: inline-block;
          align-items: center;
        }
      }

      .m-search-c-inner {
        width: 100%;
        height: 100%;
        position: relative;

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }

    .notice-search-header {
      background: #fff;
      padding: 0 20px;

      .inner {
        min-height: 34px;
        padding: 20px 0;
        position: relative;
        overflow: hidden;

        .right-header {
          float: right;

          .error-force {
            vertical-align: top;
            margin-right: 40px;
            color: red;
          }
        }

        .back {
          @include HoverOpacity();
          position: absolute;
          left: 0;

          img {
            width: 20px;
            height: 20px;
            vertical-align: -webkit-baseline-middle;
          }
        }

        .btn {
          text-align: center;
        }
      }
    }

    .m-form-search {
      padding: 0;

      .error {
        margin: 1px 0 1px 0;
      }

      .form-group {
        margin-top: -10px;
        width: calc(100% - 40px);

        .inner {
          width: 100%;

          
          table {
            width: 100% !important;
            margin-bottom: 0px;

            tbody {
              width: 100%;
            }
          }

          tr {
            width: 100%;

            input {
              height: 32px;
            }

            .btn-submit-search-new {
              width: 110px;
            }
          }

          .c-name-label, .c-name-btn {
            display: inline-block;
            vertical-align: top;
          }

          .c-name-label {
            margin-top: 5px;
            max-width: 400px;

            .c-name {
              white-space: nowrap; 
              text-overflow: ellipsis; 
              overflow: hidden;

              .label-course {
                display: inline;
              }
            }
          }

          .c-name-btn {
            width: 32px;

            .btn-search-sub {
              height: 32px;
            }
          }

          .notice-select {
            //min-width: 455px;

            .notice-select-label, .notice-select-option, .notice-select-chosen {
              display: table-cell;
            }

            .notice-select-label {
              margin-left: 10px;
              text-align: left;
              width: 130px;
              padding-right: 13px;

              .label-long {
                display: block;
                margin-top: 4px;
              }

              span.multichoose {
                display: block;
                font-size: 11px;
                margin-top:-5px;
              }
            }

            .notice-select-option {
              margin-left: -10px;

              label {
                margin-left: -35px;

                .inside-label-text {
                  margin-left: -2px;
                }
              }

              label::before {
                width: 11px;
                height: 11px;
                left:14px;
                top: 6px;
              }

              label::after {
                width: 15px;
                height: 15px;
                left: 10px;
                top: 2px;
              }
            }
          }

          .notice-select-chosen {
            width: 500px;
            
            label::before {
              width: 11px;
              height: 5px;
              top: 3px;
            }

            label::after {
              width: 14px;
              height: 14px;
            }
          }

          .notice-keyword {
            display: inline-block;
            width: 200px;
          }

          .checkbox-btn {
            display: inline-block;
          }

          .span-required {
            position: absolute;
            margin-left: -20px;
            margin-top: -13px;
          }

          .error {
            text-align: left;
            position: absolute;
          }

          .td-1 {
            width: calc(45% - 200px);
            margin-bottom: 0px;

          }

          .td-2 {
            width: 400px;
            padding-left: 0px;
            margin-bottom: 0px;

            .ns_v5 {
              width: 310px;
              margin: 5px auto;
            }
          }

          .td-3 {
            width: calc(55% - 200px);
            padding-left: 0px;
            margin-bottom: 0px;

            .ns_v5 {
              width: 310px;
              float: left;
              margin-top: 6px;

              .notice-select-label {
                width: 80px;
              }
            }
          }

          .td-4 {
            width: calc(45% - 175px);

            .ns_v52 {
              float: right;
              min-width: 275px;
            }
          }

          .td-5 {
            width: calc(55% + 175px);

            .notice-select-label {
              width: 105px;
              text-align: right;
            }
          }

          .form-row {
            width: 100%;

            .layout {
              min-width: 980px;
              width: 100%;
              margin-left: 0px;
            }
          }

          .course-chosen-dialog-firing {
            float: right;
            min-width: 250px;
            margin-left: 30px;

            .c-name {
              max-width: 400px;

              @media screen and (max-width: 1920px) {
                max-width: 400px;
              }

              @media screen and (max-width: 1600px) {
                max-width: 370px;
              }

              @media screen and (max-width: 1366px) {
                max-width: 218px;
              }

              @media screen and (max-width: 1200px) {
                max-width: 135px;
              }
            }
          }
        }
      }

      .form-btns {
          display: flex;
          justify-content: center;
          min-width: 900px;
          margin-left: -50px;
      }
    }



    .notice-result {
      @include search_table_f;
      padding: 0px;
      margin: 0px 10px 0px 10px !important;
      background: white;
      height: calc(100% - 300px);
      min-height: 297px;

      table {
        table-layout: fixed;
        width: 100%;
      }

      .ps-scrollbar-y-rail {
        right: 0px;
      }

      .table-header {
        tr {
            width: 100%;
            position: fixed;

            th{
                padding: 5px 0;
                box-sizing:border-box;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
            }
        }
      }

      .table-result {
        td {
          .img-move-up {
            @include HoverOpacity();
            width: 15px;
            height: 15px;
            display: inline-block;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(#{$IMG_PATH}icon/icn_pullup_k.png);
            background-size: 13px;
          }

          .img-move-down {
            @include HoverOpacity();
            width: 15px;
            height: 15px;
            display: inline-block;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
            background-size: 13px;
          }
          .btn-submit-linkcopy {
              background: url(#{$IMG_PATH}icon/icn_lesson_k.png) 20px center no-repeat;
              -webkit-background-size: 80%;
              background-size: 80%;
              background-position-x: 50% ;
              font-weight: normal;
              width: 34px;
              height: 34px;
              line-height: 34px;
              text-align: center;
              font-size: 16px;
              padding: 0;
          }
          .btn-submit-setting {
            background: url(#{$IMG_PATH}icon/icn_edit_k.png) 20px center no-repeat ;
            -webkit-background-size: 80%;
            background-size: 80%;
            background-position-x: 50% ;
            font-weight: normal;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            font-size: 16px;
            padding: 0;
          }
          .btn-submit-delete {
              background: url(#{$IMG_PATH}icon/icn_trash_k.png) 20px center no-repeat;
             -webkit-background-size: 80%;
              background-size: 80%;
              background-position-x: 50% ;
              font-weight: normal;
              width: 34px;
              height: 34px;
              line-height: 34px;
              text-align: center;
              font-size: 16px;
              padding: 0;
          }

          .copyNotify {
              display: none;
              padding-left:10px;
              padding-right:10px;
              height: 32px;
              text-align: center;
              background-color: #82BEF0;
              color: white;
              vertical-align: middle;
              border-radius: 3px;
              position: absolute;
              right: 20px;
              span {
                  position: relative;
                  top: 6px;
              }
              .tooltip-close {
                  position: relative;
                  top: -2px;
                  right: -7px;
                  line-height: 10px;
                  padding: 0;
                  cursor: pointer;
                  background: transparent;
                  border: 0;
                  color: white;
                  -webkit-appearance: none;
              }
          }

        }

        tr.selected {
          .btn-submit-delete {
            background: url(#{$IMG_PATH}icon/icn_trash_w.png) 20px center no-repeat;
            -webkit-background-size: 80%;
            background-size: 80%;
            background-position-x: 50% ;
          }

          .btn-submit-setting {
            background: url(#{$IMG_PATH}icon/icn_edit_w.png) 20px center no-repeat;
            -webkit-background-size: 80%;
            background-size: 80%;
            background-position-x: 50% ;
          }
          .btn-submit-linkcopy {
            background: url(#{$IMG_PATH}icon/icn_lesson_w.png) 20px center no-repeat;
            -webkit-background-size: 80%;
            background-size: 80%;
            background-position-x: 50% ;
          }

          .copyNotify {
              display: none;
              padding-left:10px;
              padding-right:10px;
              height: 32px;
              text-align: center;
              background-color: #82BEF0;
              color: white;
              vertical-align: middle;
              border-radius: 3px;
              position: absolute;
              right: 20px;
              span {
                  position: relative;
                  top: 6px;
              }
              .tooltip-close {
                  position: relative;
                  top: -2px;
                  right: -7px;
                  line-height: 10px;
                  padding: 0;
                  cursor: pointer;
                  background: transparent;
                  border: 0;
                  color: white;
                  -webkit-appearance: none;
              }
          }

          .col-9th.forcibly-show {
            background-image: url(#{$IMG_PATH}icon/icn_check_w.png) !important;
            -webkit-background-size: 25% !important;
            background-size: 25px !important;
            background-repeat: no-repeat !important;
            background-position: 50% !important;
          }

          .img-move-up {
            width: 15px;
            height: 15px;
            display: inline-block;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(#{$IMG_PATH}icon/icn_pullup_w.png);
            background-size: 13px;
          }

          .img-move-down {
            width: 15px;
            height: 15px;
            display: inline-block;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(#{$IMG_PATH}icon/icn_pulldown_w.png);
            background-size: 13px;
          }


        }

        td.hover {
          .btn-submit-delete { 
            background: url(#{$IMG_PATH}icon/icn_trash_w.png) 20px center no-repeat;
            -webkit-background-size: 80%;
            background-size: 80%;
            background-position-x: 50% ;
          }

          .btn-submit-setting {
              background: url(#{$IMG_PATH}icon/icn_edit_w.png) 20px center no-repeat;
              -webkit-background-size: 80%;
              background-size: 80%;
              background-position-x: 50% ;
          }

          .btn-submit-linkcopy {
              background: url(#{$IMG_PATH}icon/icn_lesson_w.png) 20px center no-repeat;
              -webkit-background-size: 80%;
              background-size: 80%;
              background-position-x: 50% ;
          }

          .copyNotify {
              display: none;
              padding-left:10px;
              padding-right:10px;
              height: 32px;
              text-align: center;
              background-color: #82BEF0;
              color: white;
              vertical-align: middle;
              border-radius: 3px;
              position: absolute;
              right: 20px;
              span {
                  position: relative;
                  top: 6px;
              }
              .tooltip-close {
                  position: relative;
                  top: -2px;
                  right: -7px;
                  line-height: 10px;
                  padding: 0;
                  cursor: pointer;
                  background: transparent;
                  border: 0;
                  color: white;
                  -webkit-appearance: none;
              }
          }

          .img-move-up {
            width: 15px;
            height: 15px;
            display: inline-block;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(#{$IMG_PATH}icon/icn_pullup_w.png);
            background-size: 13px;
          }

          .img-move-down {
            width: 15px;
            height: 15px;
            display: inline-block;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(#{$IMG_PATH}icon/icn_pulldown_w.png);
            background-size: 13px;
          }
        }

        td.title {
          text-align: left !important;
          padding-left: 25px;
        }
      }

      .table-header {
        .header-1st {
          min-width: 60px;
        }

        .header-2nd {
          min-width: 95px;
        }

        .header-3rd {
          min-width: 102px;
        }

        .header-4th {
          min-width: 155px;
        }

        .header-5th {
          min-width: 95px;
        }

        .header-6th {
          min-width: 140px;
        }

        .header-7th {
          min-width: 140px;
        }

        .header-8th {
          min-width: 94px;
        }

        .header-9th {
          min-width: 82px;
        }

        .header-10th {
          min-width: 82px;
        }

        .header-11th {
          min-width: 90px;
        }

        @media all and (max-width: 1366px) {
          .header-1st {
            min-width: 60px;
          }

          .header-2nd {
            min-width: 70px;
          }

          .header-3rd {
            min-width: 80px;
          }

          .header-4th {
            min-width: 80px;
          }

          .header-5th {
            min-width: 81px;
          }

          .header-4th {
            min-width: 66px;
          }

          .header-6th {
            min-width: 85px;
          }

          .header-7th {
            min-width: 85px;
          }

          .header-8th {
            min-width: 60px;
          }

          .header-9th {
            min-width: 50px;
          }

          .header-10th {
            min-width: 50px;
          }

          .header-11th {
            min-width: 85px;
          }
        }

        @media all and (max-width: 1024px) {
          .header-1st {
            min-width: 60px;
          }

          .header-2nd {
            min-width: 90px;
          }

          .header-3rd {
            min-width: 111px;
          }

          .header-4th {
            min-width: 108px;
          }

          .header-5th {
            min-width: 81px;
          }

          .header-6th {
            min-width: 101px;
          }

          .header-7th {
            min-width: 101px;
          }

          .header-8th {
            min-width: 61px;
          }

          .header-9th {
            min-width: 61px;
          }

          .header-10th {
            min-width: 96px;
          }
        }
      }

      .table-result {
        .col-9th.forcibly-show {
          background-image: url(#{$IMG_PATH}icon/icn_check_b.png);
          -webkit-background-size: 25%;
          background-size: 25px;
          background-repeat: no-repeat;
          background-position: 50%;
        }

        .col-9th.forcibly-show.hover {
          background-image: url(#{$IMG_PATH}icon/icn_check_w.png);
        }
      }

      .table-result {

        .col-1st {
          width: 5%;
        }

        .col-2nd {
          width: 8%;
        }

        .col-3rd {
          width: 10%;
        }

        .col-4th {
          width: 17%;
        }

        .col-5th {
          width: 8%;
        }

        .col-6th {
          width: 12%;
        }

        .col-7th {
          width: 12%;
        }

        .col-8th {
          width: 8%;
        }

        .col-9th {
          width: 7%;
        }

        .col-10th {
          width: 7%;
        }

        .col-11th {
          width: 9%;
        }

        @media all and (max-width: 1366px) {
          .col-1st {
            width: 60px;
          }

          .col-2nd {
            width: 70px;
          }

          .col-3rd {
            width: 70px;
          }

          .col-5th {
              width: 70px;
          }

          .col-6th, .col-7th {
            width: 95px;
          }

          .col-8th, .col-9th, .col-10th {
            width: 50px;
          }

          .col-11th {
            width: 85px;
          }
        }

        @media all and (max-width: 1024px) {
          .col-1st {
            width: 50px;
          }

          .col-2nd {
            width: 69px;
          }

          .col-3rd {
            width: 80px;
          }

          .col-5th {
            width: 70px;
          }

          .col-6th, .col-7th {
            width: 80px;
          }

          .col-8th, .col-9th, .col-10th {
            width: 50px;
          }

          .col-11th {
            width: 85px;
          }
        }
      }


    }

    .no-notice-data {
      padding-bottom: 20px;
      min-height: 30% !important;
    }

  }
}