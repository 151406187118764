@charset "UTF-8";

/*
#overview
フォント設定

フォントに関する変数を設定しています。
*/

/*
#styleguide
font-family

$font_family_default: フォント指定
```
none
```
*/
//$font_family_default: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$font_family_default: "メイリオ", "Meiryo", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Osaka", Verdana, "ＭＳ Ｐゴシック", "MS P Gothic", Arial, Helvetica, sans-serif;
$font_family_number: Arial, Roboto, "Droid Sans", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;

/*
#styleguide
font-size

$font_size_default: 14px;
$font_size_l: 16px;
$font_size_m: 13px;
$font_size_s: 11px;
$font_size_btn_l: 24px;
$font_size_btn_m: 20px;
$font_size_btn_s: 16px;

```
none
```
*/
$font_size_default: 14px;
$font_size_xxxl: 32px;
$font_size_xxl: 24px;
$font_size_xl: 22px;
$font_size_l: 18px;
$font_size_m: 16px;
$font_size_s: 13px;
$font_size_xs: 10px;
$font_size_btn_l: 24px;
$font_size_btn_m: 20px;
$font_size_btn_s: 16px;

$font_size_site_name: $font_size_xxl;

/*
#styleguide
line-height

$line_height_default: 1.6;
```
none
```
*/
$line_height_default: 1.6;