@charset "UTF-8";

.page-courses-input {

  .page-inner {

    .page-contents {
      width: 100%;
      padding: 20px 30px;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      max-width: 1200px;
    }
    .m-modal-loading {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      background: rgba(0,0,0,0.5);
      .modal-inner {
        border-radius: 0px;
        position: relative;
        top: 20%;
        width: 420px;
        padding: 25px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        margin: 0 auto !important;
        background: #fff;
        text-align: center;
        -webkit-box-shadow: 0 0 8px 1px rgba(0,0,0,0.5);
        -moz-box-shadow: 0 0 8px 1px rgba(0,0,0,0.5);
        box-shadow: 0 0 8px 1px rgba(0,0,0,0.5);
      
      }
    }
    .m-form {
      left: 0;
      width: 840px;

      .form-wrap {
        margin-bottom: 65px;

        &:first-child {
          margin-top: 0;
          .form-sub-heading {
          }
        }

        .form-group {
          padding-right: 40px;
        }

        table {
          th:first-child {
            width: 25%;
          }
        }
      }

      &.conf-form {
        .form-wrap {

          .form-group {
            padding-right: 0;
          }
        }
      }

    }

    .input-filename{
      width:20% !important;
    }

    .input-comment{
      width:72% !important;
    }
    .set-height-for-url{
        height: 45px;
      }

    .btn-delete{
      background: url(#{$IMG_PATH}icon/icn_trash_k.png) 10px center no-repeat;
      -webkit-background-size: 90%;
      background-size: 90%;
      background-position-x: 50%;
      font-weight: normal;
      width: 34px;
      height: 34px;
      border: 1px solid;
      border-radius: 4px;
      vertical-align: middle;
	  
	  &.disabled {
        cursor: default;
        border-color: #c8c8c8;
        opacity: .3;
      }

      &:not(.disabled) {
        border-color: #c8c8c8;
      }
    }

    .form-download{
      div.form-download-info{
        label{
          color: #0b3182;
          cursor:default;
        }
      }

      div.form-download-info-temp{
        margin-top:10px;
        label{
          color: #0b3182;
          cursor:default;
        }
      }
      div.form-btn-add {
          margin-top:10px;
          position: absolute;
      }

      div.form-btn-add {
        &:not(:first-child) {
          .btn-upload{
            label {
              background: none;
            }
          }
        }
        .btn-upload{
          right: 0;
          bottom: 0;
          overflow: hidden;
          padding: 10px;
          label {
              @include HoverOpacity();
            background: url(#{$IMG_PATH}icon/icn_selectbtn_plus_b.png);
            -webkit-background-size: 100% 100%;
            background-size: 100% 100%;
              width: 38px;
              height: 38px;
              display: block;
            }

            input[type="file"]{
              position: absolute;
              left: -9999px;
              top: -9999px;
            }
        }
      }
    }

    .form-download-url,.form-download-urlzero{
      div.form-download-info-url{
        label{
          color: #0b3182;
          cursor:default;
        }
      }

      div.display-form-download-url{
        label{
          color: #0b3182;
          cursor:default;
        }
        table {
          table-layout: auto;
          td {
            vertical-align: middle;
            padding-left: 0px;
          }
          td:nth-child(1) {
            min-width: 50px;
            text-align: right;
            div {
                float: left;
            }
          }
          td:nth-child(3) {
            width: 36px;
            text-align: right;
            padding-right: 1px;
          }
        }
      }
      div.form-btn-add-url{
        margin-top: -10px;
      }

      div.form-btn-add-url,div.form-btn-add-urlzero {
        .btn-upload-url,.btn-upload-urlzero{
          right: 0;
          bottom: 0;
          overflow: hidden;
          padding-left: 10px;
          label {
              @include HoverOpacity();
            background: url(#{$IMG_PATH}icon/icn_selectbtn_plus_b.png);
            -webkit-background-size: 100% 100%;
            background-size: 100% 100%;
              width: 38px;
              height: 38px;
              display: block;
            }

        }
      }
    }
    .tr-approve-data {
      .title-approve {
        position: relative;
        span{
          position: absolute;
          top: 0;
          left: 38%;
        }
      }
      .approve-border{
          border: 1px solid #c8c8c8;
          float: left;
          height: 100%;
          width: 100%;
          padding: 5px;
          -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
          -moz-box-sizing: border-box;    /* Firefox, other Gecko */
          box-sizing: border-box;         /* Opera/IE 8+ */
          display: flex;
          display: -webkit-flex;
      }
    }
    .tr-approve {
      height: 38px;
      .row-approve {
        position: relative;
        vertical-align: middle;
        
        .btn-setting{
          float: right;
          width: 190px;
          position: absolute;
          right: 15px;
          bottom: 0px;
          .btn-subsetting,.btn-subclear{
            width: 90px;
            border: 1px solid $theme_color_light;
            color: $theme_color_light;
            line-height: 35px;
            height: 36px;
          }
        } 
      }
      
    }
    
  }
  .m-search-c {
    z-index: 305;// >global-header 304
    .m-search-c-inner{
      width: 100%;
      height: 100%;
      position: relative;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}