@charset "UTF-8";

.modal-correct-percentage {
	  .m-modal {
	    .modal-inner {
	      &.u-search {
	        padding-left: 0px;
	        padding-right: 0px;
	        width: 620px;
	        text-align: center;
	        background: #fff;
	        z-index: 11000;
	        padding-bottom: 5px;
	        padding-top: 5px;
	        top:5%;

	        .top-area {
	          position: relative;
	          height: 40px;
	          background-color: white;
	          text-align: center;
	          width: 400px;
	          margin: auto;

	          .top-title {
	            color: gray;
	            line-height: 45px;
	            font-size: 15px;
	            text-align: center;
	          }
	        }

	        .page-inner {
	          margin: auto;
	          background: black;
	          width: 590px;
	          height: 100%;

	          &.u-search-modal {
	            height: auto;
	            margin: auto;
	            padding: 1px;
	            margin: 10px auto;

	            .page-contents {
	              width: 100%;
	              height: 100%;

	              .tooltip-pro-arrow {
	                position: fixed;
	              }
	              .tooltip-pro-comment {
	                position: fixed;
	              }
	              .content-tooltip-pro-text {
	                text-align: left;
	              }
	            }

	            .course-result {
	              @include search_table_f;
	              margin: 0px 0px;
	              height: 32px;
	              padding: 0px;
	              background: white;
	              text-align: left;
	              	
		            .ps-scrollbar-y-rail {
		              right: 0px;
		              z-index: 6;
		            }

		            thead > tr {
		              z-index: 5;
		            }
		            &.answer-info{
		              height:495px;
		              max-height:495px;
		            }
	            }

	            .result-table {
	              table-layout: fixed;
	              border-bottom-width: 0px;
	                
	              tr:nth-child(even) td.correct-percentage-result {
				    background-color: #f2f2f2;
				  }
	            }
            
	            .table-header {
	              table-layout: auto;

	              tr {
	                width: 100%;
	                
	                th {
	                  padding: 5px 5px;
	                  box-sizing: border-box;
	                  -webkit-box-sizing: border-box;
	                  -moz-box-sizing: border-box;
	                  text-align: center !important;
	                  line-height: 22px;
	                    
	                  &.correctPercentage-header {
	                   background-color: #b4c6e7 !important;
	                   color:$font_color_default;
	                   border: 0px none;
	                  }
	                }
	              }
	            }
	            td{
	              text-align: left !important;
	              line-height: 22px;
	            }

	            .col-question_sequence {
	              text-align: right !important;
	              width: 8%;
	            }
	            .col-sentence {
	              text-align: left;
	              width: 64%;
	            }
	            .col-sentence-qbank {
	              text-align: left;
	              width: 44%;
	            }
	            .col-correct-answer {
	              text-align: left;
	              width: 28%;
	            }
	            .col-correct-answer-qbank {
	              text-align: left;
	              width: 48%;
	            }
	            .col-percentage{
	              text-align: right !important;
	              width: 14%;
	              padding-right: 8px !important;
	            }	              
	            .col-correct-answer-count{
	              text-align: right !important;
	              width: 14%
	            }
	            .col-qbank-group-display{
	              text-align: right !important;
	              width: 20%
	            }
	          }
	        }
	      }
	    }
	  }
}
