@charset "UTF-8";
.m-modal {
    .modal-inner {
        &.c-search {
            padding-left: 0px;
            padding-right: 0px;
            width: 70%;
            text-align: center;
            background: #fff;
            z-index: 11000;
            .c-search-header {
                display: flex;
                // align-items: center;
                justify-content: flex-start;
                margin: 0px 20px 0px 20px;

                .title {
                    display: flex;
                    align-items: center;
                    span {
                        color: #82bef0;
                        display: inline-block;
                        padding-left: 25px;
                        background: url(#{$IMG_PATH}icon/icn_courses_b.png) no-repeat;
                        background-position: 0% 35%;
                        -webkit-background-size: 20px;
                        background-size: 20px;
                        cursor: default;
                        font-weight: bold;
                    }
                }

                .c-name {
                    margin-left: 10px;
                    height: 33px;
                    .input-text {
                        width: 100%;
                        border: 1px solid #c8c8c8;
                        padding: 3px;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        max-width: 100%;
                    }
                }

                .c-btn {
                    margin-left: 10px;
                    .btn-c-search {
                        width: 55px;
                        height: 30px;
                        button {
                            height: 30px;
                        }

                    }
                }

            }
            .c-err {
                margin-left: 125px;
                display: flex;
                align-items: center;
                height: 15px;
                .error {
                    font-size: 12px;
                    color: red;
                    font-weight: bold;
                    &.hide {
                    display: none;
                    }
                }
            }

            .page-inner {
                &.c-search-modal {
                    height: 321px;
                    margin: 5px 20px 0px 20px;
                    .no-data {
                        height: 263px;
                    }
                    .course-result {
                        height: 263px;
                    }

                    .table-result {
                        .col-course-id {
                            width: 30%;
                        }
                        .col-course-name {
                            width: 70%;
                        }

                    }
                }
            }
        }
    }
}