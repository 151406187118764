@charset "UTF-8";
.contents {
  height: 100%;

  .p-pager {
      margin-top: 5px;
      margin-left: 10px;
      min-height: 30px;
      display: flex;
      align-items: center;

      ul.pagination {
        display: inline-block;
        padding: 0;
        margin: 0;

        li {
          display: inline;

          a {
            color: black;
            // float: left;
            padding: 3px 9px;
            text-decoration: none;
            border-radius: 5px;

            &.active {
              background-color: #468CC8;
              color: white;
              border-radius: 5px;
              }

            &:hover:not(.active) {
              background-color: #6E6E6E;
              color: white;
              }

            }

            strong {
              -webkit-user-select: none;  /* Chrome all / Safari all */
              -moz-user-select: none;     /* Firefox all */
              -ms-user-select: none;      /* IE 10+ */
              user-select: none;          /* Likely future */ 
              cursor: default;
            }

          }

      }

    }
}

  .contents-inner {
    height: 100%;
  }
  .page-courses-user-history-search {
    height: 100%;

    .m-search-c-inner {
      width: 100%;
      height: 100%;
      position: relative;

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    .ps-container>.ps-scrollbar-x-rail {
      opacity: 0.4;
    }

    .ps-container>.ps-scrollbar-x-rail>.ps-scrollbar-x {
      background-color: #777777;
    }

    .ps-container:hover>.ps-scrollbar-x-rail {
      opacity: 1;
    }

    .page-inner {
      background: $background_theme_color_contents;
      height: 100%;

      .page-contents {
        width: 100%;
        height: 100%;
        margin: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        .no-data {
          min-height: 85%;
          margin: 0 10px;
          background: white;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 0px !important;

          &.hide {
            display: none;
          }
        }

        .course-header {
          display: flex;
          padding-bottom: 5px;
          
          .except {
            height: 71px;
            display: none;
          }
          .download {
            position: absolute;
            right: 0px;
            margin-top: 5px;
            margin-right: 10px;
            min-height: 30px;
            padding-bottom: 5px;
          }

          .result-txt {
            margin-top: 5px;
            margin-left: 10px;
            min-height: 30px;
            display: flex;
            align-items: center;
          }

        }

      }
      .no-data {
        .modal-inner {
          width: 440px;
        }
      }
    }
    .course-result {
      @include search_table_f;
      padding: 0px !important;
      .tbl-co-user-history {
        margin-bottom: 15px;
      }
      table {
        table-layout: auto !important;
        min-width: 800px;
        thead {
          position: fixed;
          z-index: 5;
          .ps-scrollbar-x-rail{
            display: none;
          }
          .ps-scrollbar-y-rail{
            display: none;
          }
          tr {
            // display: flex;
            color: #82BEF0;
            th {
              border-right: 0px;
            }
          }
        }
        
      }
      &.result-tbl {
        @media screen and (max-width : 1366px) {
          @media screen and (max-height : 768px) {
            height: 85%;
          }
        }
        padding: 0px;
        margin: 0px 10px 0px 10px !important;
        height: 90%;
        background: white;

        &.ps-active-x{
          .table-header{
            margin-bottom: 15px!important;
          }
        }

        .ps-scrollbar-y-rail {
          right: 0px;
          z-index: 4;
        }
        .ps-scrollbar-x-rail {
          z-index: 4;
        }
    
      .table-result{
        .col-login-id {
          width: 24%;
          &.hover {
            div {
              display: inline-flex;
              vertical-align: middle;
              .grade-evaluate-icon {
                width: 23px;
                opacity: .5;
                background-position: center;
                background-repeat: no-repeat;
                background: url(#{$IMG_PATH}icon/icn_score_w.png) center no-repeat;
                -webkit-background-size: 90%;
                background-size: 23px;
                margin-right: 5px;
              }
            }
          }
          div {
            display: inline-flex;
            vertical-align: middle;
            .grade-evaluate-icon {
              width: 23px;
              opacity: .5;
              background-position: center;
              background-repeat: no-repeat;
              background: url(#{$IMG_PATH}icon/icn_score_k.png) center no-repeat;
              -webkit-background-size: 90%;
              background-size: 23px;
              margin-right: 5px;
            }
          }
          
        }
        .col-user-name {
          width: 24%;
        }
        .col-attend-stt {
          width: 9%;
        }
        .col-access {
          width: 11%;
        }
        .col-rate-all-chapter {
          .percent{
            font-weight: bold;
            //font-size: 16px;
            width: 20%;
            float: left;
          }
          width: 30%;
        }
        .col-lesson {
          width: 20%;
          border-bottom: 0px;
        }
        .header.col-chapter-n {
          border-left: 0px;
          background: #fff;
          &.left-border {
            border-left: 1px solid #c8c8c8;
          }
          div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        td.col-chapter-n {
          border-left: white;
          border-right: white;
          padding-left: 1px;
          padding-right: 1px;
          padding-top: 0px;
          padding-bottom: 0px;
        }
    
        tbody {
          .row.selected, td.hover {
            .bar-body {
                border: solid 1px;
                margin: -1px;
            }
            .in-time-progress-bar-l{
                  .completeds{
                      color: #909aaf;
                  }
                  .totals{
                      color: #909aaf;
                  }
                  .lesson-late{
                      color: #909aaf;
                  }
              }
          }
          tr{
            .in-time-progress-bar-l{
              .bar-body{
                &.user-course-history-bar{
                  position: relative;
                  height: 33px;
                  background: #EBEBEB;
                }
                .bar{
                  position: absolute;
                  left: 0;
                  top: 0;
                  height: 100%;

                  &.blue-bar{
                    background: #84CBF1;
                    & +.data {
                      color: #FFFFFF;
                    }
                  }
                  &.yellow-bar{
                    background: #FEF8A0;
                  }
                  &.red-bar{
                    background: #FF8A84;
                    & +.data {
                      color: #FFFFFF;
                    }
                  }
                  &.gray-bar{
                  background: #EBEBEB;
                  width: 100%;
                  }
                }
                .bar-01 {
                  z-index: 2;
                }
                .data {
                  display: flex;
                  flex-direction: column;
                  // justify-content: center;
                  // align-items: center;
                  font-weight: bold;
                  z-index: 3;
                  color: #606060;
                  width: 100%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: clip;
                  font-size: 10px;
                  .completeds {
                    display: flex;
                    font-size: 16px;
                    padding-left: 5px;
                    height: 20.13px;
                    &.circle {
                      border: solid 2px;
                      border-radius: 50%;
                      width: 24px;
                    }
                  }
                  .totals {
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 5px;
                    height: 12.88px;
                  }
                  .lesson-late {
                    position: absolute;
                    top: -2px;
                    right: 10%;
                    font-size: 16px;
                  }
                }
             }
            }//in-time-progress-bar-l
            .in-time-progress-bar{
              &.pb-size {
                width: 78%;
                float: right;
                margin-right: 2%;
              }
              .bar-body{
                &.user-course-history-bar{
                  position: relative;
                  -webkit-border-radius: 5px;
                  -moz-border-radius: 5px;
                  border-radius: 5px;

                  height: 25px;
                  // border: 1px solid #7E7E7E;
                  background: #EBEBEB;
                }
                .bar{
                  position: absolute;
                  left: 0;
                  top: 0;
                  height: 100%;
                  -webkit-border-radius: 5px;
                  -moz-border-radius: 5px;
                  border-radius: 5px;

                  &.blue-bar{
                    background: #84CBF1;
                    & +.data {
                      color: #FFFFFF;
                    }
                  }
                  &.yellow-bar{
                    background: #FEF8A0;
                  }
                  &.red-bar{
                    background: #FF8A84;
                  }
                  &.gray-bar{
                  background: #EBEBEB;
                  width: 100%;
                  }
                }
                .bar-01 {
                  z-index: 2;
                }
                .data {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-weight: bold;
                  z-index: 3;
                  color: #606060;
                  width: 100%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: clip;
                  font-size: 10px;
                  .completeds {
                    font-size: 16px;
                  }
                }
              }
            }//in-time-progress-bar
          }
        }
       
      }
    
      .table-result {
          thead{
            touch-action: none;
            overflow: hidden !important;
          }
          tr {
            .header {
              cursor: pointer;
              div {
                display: inline-flex;
                .img {
                  background-image: url(#{$IMG_PATH}icon/icn_pullupdown_k.png) ;
                  background-repeat: no-repeat ;
                  background-position: center ;
                  background-size: 26px;
                  width: 27px;
                  padding-left: 7px;
                }
    
              }
              
              }
              .header-sort-up {
                cursor: pointer;
                div {
                  display: inline-flex;
                  .img {
                    width: 26px;
                    background-position: center ;
                    background-repeat: no-repeat ;
                    background-image: url(#{$IMG_PATH}icon/icn_pullup_k.png) ;
                    background-size: 13px;
                    padding-left: 7px;
                    }
                }
              }
    
              .header-sort-down {
                cursor: pointer;
                div {
                  display: inline-flex;
                  .img {
                    width: 26px;
                    background-position: center ;
                    background-repeat: no-repeat ;
                    background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png) ;
                    background-size: 13px;
                    padding-left: 7px;
                  }
                  
                }
              }
            }
    
          }
    
        }
      }
  }
