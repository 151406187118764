@charset "UTF-8";
  .page-multi-courses-search {
    .c-search{
      padding-top:0;
      padding-bottom: 0;
      top: 12%;
        .top-area{
          position: relative;
          height:30px;
          background-color: white;
          text-align: center;
          .btn-close-modal {
              position: absolute;
              top:4px;
              right: 4px;
              background: url(#{$IMG_PATH}icon/icn_close_k.png) left center no-repeat;
              background-size: 15px;
              width: 20px;
              height: 20px;
              float: right;
              cursor:pointer;
          }
          .top-title{
              line-height: 30px;
              color: #82bef0;
              font-size: 15px;
          }
        }
        .c-search-header{
          margin: 10px 20px !important;
          display: block !important;
          .row-c{
            text-align: left;
            width: 100%;
            // height: 60px;
            .title{
              // max-width: 150px;
              // width: 17%;
              text-align: left;
              display: inline-block;
              &.tt-group {
                padding-left: 15px;
              }
              .group{
                background: url(#{$IMG_PATH}icon/icn_coursegroup_b.png) no-repeat;
                background-position: 0% 35%;
                -webkit-background-size: 20px;
                background-size: 20px;
              }
            }
            .c-name{
              display: inline-block;
              width: 30%;
              max-width: 250px;
              .error{
                padding-top: 2px;
                position: absolute;
                font-size: 12px;
                color: red;
                font-weight: bold;
                width: 30%;
                max-width: 270px;
              }
            }
            .c-btn{
              display: inline-block;
              .btn-c-search{
                width: 60px;
                height: 29px;
              }
            }
          }
        }
        .table-header{
          tr{
            z-index: 1000;
          }
        }
        .table-result{
            .checkbox-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              label {
                padding-left: 20px;
                &::after {
                  top: 2px;
                  left: 15px;
                  width: 14px !important;
                  height: 14px !important;
                }
                &::before {
                  top: 5px;
                  left: 17px;
                  width: 11px !important;
                  height: 5px !important;
                }
              }
            }
            .col-course-id{
              width: 15% !important;
            }
            .col-course-name{
              width: 40% !important;
            }
            .col-course-group-name{
              width: 45% !important;
            }
        }
        .header-m-course{
          .select-all-header:hover{
            // background: #82bef0 !important;
            z-index: 1;
            color: #fff;
            cursor: pointer;
          }
          .checkbox-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            label {
              padding-left: 20px;
              &::after {
                top: 2px;
                left: 15px;
                width: 14px !important;
                height: 14px !important;
              }
              &::before {
                top: 5px;
                left: 17px;
                width: 11px !important;
                height: 5px !important;
              }
            }
          }
      }
        .ps-scrollbar-y-rail{
          z-index: 1000;
        }
      .footer-area{
          background: #fff;
          width: 100%;
          height: 30px;
          padding: 10px 0;
          text-align: center;
          a{
            margin-right: 10px;
          }
          .btn-modal-back-c{
              padding: 0 15px;
              width: 10%;
              background-color: #FFFFFF;
              text-align: center;
              -moz-border-radius: 0px;
              border-radius: 2px;
              border: 1px solid #A0A0A0;
              color: #6E6E6E;
              font-weight: normal;
              line-height: 30px;
              font-size: 16px;
          }
          .btn-modal-submit-c{
              padding: 0 15px;
              width: 10%;
              background-color: #FFFFFF;
              text-align: center;
              -moz-border-radius: 0px;
              border-radius: 2px;
              border: 1px solid #DC780A;
              color: #DC780A;
              font-weight: normal;
              line-height: 30px;
              font-size: 16px;
          }
      }
    }

  }
