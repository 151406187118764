@charset "UTF-8";

/* ---------------------------------------------------------------

  Base setting

--------------------------------------------------------------- */

/*  base style
------------------------------------------ */
html {
  width: 100%;
  height: 100%;
}

body {
  opacity: 0;
  font-family: $font_family_default;
  font-size: $font_size_default;
  line-height: $line_height_default;
  background: $background_color_default;
  color: $font_color_default;
  width: 100%;
  height: 100%;
  overflow: auto;

  &.modal-open {
    overflow: hidden;
    position: fixed;
    width: 100%;
  }
}

:root * {
  font-family: $font_family_default;
}

//@media all and (-ms-high-contrast:none){
//  :root * {
//    font-family: "メイリオ", "Meiryo", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Osaka", Verdana, "ＭＳ Ｐゴシック", "MS P Gothic", Arial, Helvetica, sans-serif!important;
//  } /* IE10~ */
//}


/*  Anchor default
------------------------------------------ */

a {
  @include HoverUnder;
  color: $link_color_default;
}

/*  input , textarea Font
------------------------------------------ */
input,textarea {
  font-family: $font_family_default;
  font-family: "ＭＳ Ｐゴシック", "MS PGothic", sans-serif \9;
}


.container {
  @include design_default_set;
}

.pc-hidden {
  display: none;
}

table {
  th, td {
    word-wrap: break-word;
  }
}

.unable {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}