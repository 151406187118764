@charset "UTF-8";

.c-no-login-header {
  padding: 30px;

  .c-no-login-header-inner {
    overflow: hidden;
  }

  .logo {
    float: left;
    img {
      width: 180px;
    }
  }

  .lang-nav {
    float: right;

    ul {
      overflow: hidden;
      li {
        float: left;
        margin-left: 20px;
      }
    }
  }

}