@charset "UTF-8";

.admin-import {

  .m-modal {
    .modal-inner {
      position: relative;
      top: 10%;

      &.modal-inner-import-confirmCSVcontent {
        padding-left: 0px;
        padding-right: 0px;
        width: 750px;
        min-height: 610px;

        @media screen and (max-width: 1366px) {
          @media screen and (max-height: 768px) {
            &.modal-inner-import-confirmCSVcontent {
              min-height: 510px;
            }
          }
        }

        .title {
          span {
            color: #82bef0;
            font-size: 18px;
          }
        }

        .areError {
          min-height: 40px;
          margin-top: 30px;
          width: 100%;
          display: table;
        }

        .icon-error {
          float: left;
          margin-right: 5px;

          img {
            width: 40px;
            height: 40px;
          }

          span {
            margin-left: 71px;
            margin-top: 16px;
          }
        }

        .message-error {
          width: 364px;
          height: 100%;
          float: left;
          text-align: left;
          overflow: hidden;

          div {

            display: table-cell;
            max-width: 364px;
            height: 40px;
            margin-top: 16px;
            padding-right: 10px;
            vertical-align: middle;
            font-size: 12px;
            color: #6e6e6e;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            letter-spacing: -0.01em;

            @media screen and (max-width: 1366px) {
              @media screen and (max-height: 768px) {
                font-size: 12px;

              }
            }

            & > p:last-child {
              max-width: 360px;
              overflow: hidden;
              -ms-text-overflow: ellipsis;
              text-overflow: ellipsis;
            }
          }
        }

        .btn-download {
          float: right;
          margin: 5px 67px 0 0;
          width: 200px;

          .sel {
            background-color: white;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 0px;
            border: 1px solid;
            width: 200px;
          }

          .sel.sel-size-m {
            height: 28px;
            border: 1px solid #a0a0a0;

            .sel-txt {
              background-size: 18px;
              line-height: 28px;
              background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
              color: #6e6e6e;
            }
          }

        }

        .btn-submit-downloadCSV {

          position: inline;
          font-weight: initial;
          text-align: center;
          width: 213px;
          padding: 4px 7px;
          color: #6e6e6e;
          ;
          border: 1px solid #a0a0a0;
          margin: 4px 2px;
          line-height: 22px;
          background: #fff;
          color: #6e6e6e;
          border-radius: 4px;

          img {
            width: 20px;
            height: 20px;
            padding-right: 7px;
          }

        }

        .dagdrop {
          width: 600px;
          height: 250px;
          left: 10%;
          position: relative;
          border: 4px dashed #c8c8c8;
          margin-bottom: 15px;
          margin-top: 15px;
          background: #f1f1f1;

          .dagdrop-text {
            color: #c8c8c8;
            position: relative;
            font-size: 18px;
            width: 250px;
            right: 66px;
            top: 113px;
          }

          .dragdrop-image {
            position: static;
            margin-top: 65px;
            float: left;
            margin-left: 85px;
            height: 130px;
          }

        }

        .dagdrop::before {
          display: block;
          content: "";
          width: 90px;
          height: 90px;
          position: absolute;
          top: -6px;
          left: -6px;
          border-top: 7px solid #c8c8c8;
          border-left: 7px solid #c8c8c8;

          @media screen and (max-width: 1366px) {
            @media screen and (max-height: 768px) {
              width: 50px;
              height: 50px;
              position: absolute;
              top: -5px;
              left: -5px;
              border-top: 5px solid #c8c8c8;
              border-left: 5px solid #c8c8c8;
            }
          }
        }

        .dagdrop::after {
          display: block;
          content: "";
          width: 90px;
          height: 90px;
          position: absolute;
          top: -6px;
          right: -6px;
          border-top: 7px solid #c8c8c8;
          border-right: 7px solid #c8c8c8;

          @media screen and (max-width: 1366px) {
            @media screen and (max-height: 768px) {
              width: 50px;
              height: 50px;
              position: absolute;
              top: -5px;
              right: -5px;
              border-top: 5px solid #c8c8c8;
              border-right: 5px solid #c8c8c8
            }
          }
        }

        .border-dagdrop::before {
          display: block;
          content: "";
          width: 90px;
          height: 90px;
          position: absolute;
          bottom: -6px;
          left: -6px;
          border-bottom: 7px solid #c8c8c8;
          border-left: 7px solid #c8c8c8;

          @media screen and (max-width: 1366px) {
            @media screen and (max-height: 768px) {
              width: 50px;
              height: 50px;
              position: absolute;
              bottom: -5px;
              left: -5px;
              border-bottom: 5px solid #c8c8c8;
              border-left: 5px solid #c8c8c8;
            }
          }
        }

        .border-dagdrop::after {
          display: block;
          content: "";
          width: 90px;
          height: 90px;
          position: absolute;
          bottom: -6px;
          right: -6px;
          border-bottom: 7px solid #c8c8c8;
          border-right: 7px solid #c8c8c8;

          @media screen and (max-width: 1366px) {
            @media screen and (max-height: 768px) {
              width: 50px;
              height: 50px;
              position: absolute;
              bottom: -5px;
              right: -5px;
              border-bottom: 5px solid #c8c8c8;
              border-right: 5px solid #c8c8c8;
            }
          }
        }

        .chooseFile {
          top: 5px;
          position: relative;

        }

        .btn-choosefile {
          border: 1px solid #a0a0a0;
          font-weight: normal;
          border-radius: 2px;
          font-size: 12px;
          padding-right: 5px;
          background-color: white;
          color: #6e6e6e;
          width: 125px;
          line-height: 21px;

          @media screen and (max-width: 1366px) {
            @media screen and (max-height: 768px) {
              font-size: 11px;
            }
          }

        }

        #jsCSVUploadFile {
          position: absolute;
          width: 127px;
          height: 25px;
          z-index: 99;
          filter: alpha(opacity=0);
          opacity: 0.0;
          font-size: 30px;
          cursor: pointer;
          overflow: hidden;
          left: 22%;

          @media screen and (max-width: 1366px) {
            @media screen and (max-height: 768px) {
              left: 21%;
            }
          }
        }

        .radioImport {
          width: 70%;
          margin-left: 20%;
          padding-top: 17px;
          padding-bottom: 10px;

          @media screen and (max-width: 1366px) {
            @media screen and (max-height: 768px) {
              font-size: 11px;
            }
          }

          .div-table {
            display: table;
            margin: 0 auto;
            width: 100%;
          }

          .div-table-row {
            text-align: left;
            display: table-row;
            width: auto;

          }

          .div-table-col {
            float: left;
            /* fix for  buggy browsers */
            display: inline-flex;
            width: 50%;
            margin-bottom: 4px;

            label {
              padding-right: 15px;
            }

            .div-contains-radio {
              display: inline-flex;
            }
          }

        }

        .radioImport1 {
          margin-top: 30px;
          margin-bottom: 30px;
          width: 100%;

          @media screen and (max-width: 1366px) {
            @media screen and (max-height: 768px) {
              font-size: 11px;
            }
          }
        }

        .radioImport2 {
          position: absolute;
          margin-left: 55%;
          top: 500px;

          @media screen and (max-width: 1366px) {
            @media screen and (max-height: 768px) {
              font-size: 11px;
            }
          }
        }

        .fileName {
          width: 280px;
          color: #6e6e6e;

        }

        .btn-next {
          font-weight: normal;
          font-size: 12px;
          padding-right: 5px;
          background-color: white;
          color: #0f83c6;
          border: 1px solid #0f83c6;
          border-radius: 2px;
          width: 124px;
          line-height: 24px;

          @media screen and (max-width: 1366px) {
            @media screen and (max-height: 768px) {
              font-size: 11px;
              font-weight: normal;
              padding-right: 5px;
              background-color: white;
              color: #468CC8;
              border: 1px solid #468CC8;
              border-radius: 2px;
              width: 124px;
              line-height: 24px;
            }
          }
        }

        .content-item-part-table {
          display: table;
          margin: 25px auto;
          table-layout: fixed;
          width: 500px;


          span {
            font-size: 12px;
            word-wrap: break-word;
          }

          input.input-html-name {
            width: 210px;
          }

          input.px-txt {
            width: 90px;
          }

          .content-item-part-row {
            display: table-row;

            .content-item-part-cell {
              display: table-cell;

              &.left-wing {
                text-align: right;
                width: 30%;
              }

              &.right-wing {
                text-align: left;
                width: 70%;
                padding-left: 23px;
              }
            }
          }
        }
      }

      &.modal-inner-CSVconfirm {
        padding-left: 0px;
        padding-right: 0px;
        width: 600px;
      }

      .send-mail-setting-content {
        padding: 10px 10%;

        .radio-row {
          width: 100%;
          text-align: left;
          line-height: 40px;

          .row-second {
            width: 77%;

            @media screen and (max-width: 1366px) {
              width: calc(87% - 2px);
            }
          }

          .row-last {
            width: 160px;
          }
        }

        .hints {
          display: inline-block;
          line-height: normal;
          position: relative;

          &.text-content {
            margin-top: 5px;
          }

          .hint-btn {
            background: url(#{$IMG_PATH}icon/icn_hints_bk.png) no-repeat;
            padding-left: 25px;
            background-position: 50%;
            -webkit-background-size: 20px;
            background-size: 20px;
          }

          .hint-content {
            display: none;
            width: auto;
            min-width: 230px;
            max-width: 230px;
            background: #d3edfa;
            padding: 10px;
            font-size: 12px;
            margin-top: 3px;
            margin-left: 3px;
            position: absolute;
            top: 21px;
            z-index: 999;
          }
        }
      }

      .title-back {
        position: absolute;
        top: 5px;
        left: 35px;
        line-height: 30px;
      }
    }

  }

}
