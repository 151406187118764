@charset "UTF-8";

.m-contents-list {
  width: 100%;
  max-width: 892px;
  margin: auto;


  .form-sub-heading {
    margin-bottom: 30px;
  }

  .heading-area {
    margin-bottom: 20px;

    .sub-heading {
      margin-bottom: 0;
    }
  }

  .m-contents-list-block {
    margin-bottom: 10px;
  }

  ul {
    @include ClearInlineSpace;
    text-align: left;
    margin-left: -25px;
    margin-right: -25px;

    @include ResponsiveMaxWidth(1060){
      text-align: center;
    }


    .item {

      .itemThumbnail a:after {
        @include PseudoElements();
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        background-color: #000;
        opacity: 0;
        -webkit-transition: all .2s;
        -moz-transition: all .2s;
        -ms-transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s;
        background-position:  center center;
        -webkit-background-size: 50px auto;
        background-size: 50px auto;
      }

      &.is-checked {
        .itemThumbnail a:after {
          background-image:url(#{$IMG_PATH}icon/icn_check_w.png);
          background-repeat: no-repeat;
          background-size: 80px auto;
          opacity: .6;
        }
      }

      .itemNumber {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: 48px;
        font-weight: bold;
        background: #000;
        color: #fff;
        opacity: .6;
        text-align: center;
        span {
          position: absolute;
          left: 50%;
          top: 50%;
          margin-top: -55px;
          margin-left: -55px;
          width: 110px;
          height: 110px;
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          border-radius: 100%;
          border: 3px solid #fff;
          line-height: 110px;
        }
      }

    }

  }

  #userContentStatus {
    margin-top: 82px;
  }

  ul li {
    text-align: left;
    vertical-align: top;
    display: inline-block;
    width: 264px;
    margin: 0 25px 20px;
    position: relative;
  }

  ul li .itemUpper {
    width: 264px;
    border: 1px solid #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    position: relative;
    height: 100%;
  }

  .contentName {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 3px;
    margin-top: 2px;
    z-index: 100;
  }

  .contentLabel-none {
    height: 20px;
    width: 258px;
    padding-top: 2px;
    padding-left: 2px;
    white-space: nowrap;
    overflow: hidden;
  }

  .contentLabel {
    height: 20px;
    width: 120px;
    padding-top: 2px;
    padding-left: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    font-size: 12px;

    /*     -moz-box-shadow: 0px 0px 3px #000000; */
    /*     -webkit-box-shadow: 0px 0px 3px #000000; */
    /*     box-shadow: 0px 0px 3px #000000; */
    background-color: #696969;
    color: #FFFFFF;
    /*     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=  '#696969', */
    /*                                                        endColorstr=  '#696969'); */
    /*     -ms-filter: */
    /*     "progid:DXImageTransform.Microsoft.gradient(startColorstr = '#696969', endColorstr = '#696969')"; */
    /*     background-image: -moz-linear-gradient(top, #696969, #696969); */
    /*     background-image: -ms-linear-gradient(top, #696969, #696969); */
    /*     background-image: -o-linear-gradient(top, #696969, #696969); */
    /*     background-image: -webkit-gradient(linear, center top, center bottom, from(#696969), */
    /*                                        to(#696969)); */
    /*     background-image: -webkit-linear-gradient(top, #696969, #696969); */
    /*     background-image: linear-gradient(top, #696969, #696969); */
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    opacity: 0.8;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
    vertical-align: middle;
  }

  .contentInput {
    height: 19px;
    width: 254px;
    margin-top: -1px;
    padding-left: 2px;
    font-size: 12px;
  }

  ul li .itemUpper .itemThumbnail, ul li .itemUpper .btnArea {
    padding: 1px;
    background-color: rgba(0, 0, 0, 0.9);
  }

  ul li .itemUpper .itemThumbnail {
    position: relative;
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    -o-border-radius: 2px 2px 0 0;
    -ms-border-radius: 2px 2px 0 0;
    border-radius: 2px 2px 0 0;
  }

  ul li .itemUpper .itemThumbnail img {
    border: 1px solid #686868;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -o-border-radius: 2px;
    -ms-border-radius: 2px;
    border-radius: 2px;
  }

  /*-- btnArea --*/
  ul li .itemUpper .btnArea {
    padding: 3px 10px 7px;
    -webkit-border-radius: 0 0 2px 2px;
    -moz-border-radius: 0 0 2px 2px;
    -o-border-radius: 0 0 2px 2px;
    -ms-border-radius: 0 0 2px 2px;
    border-radius: 0 0 2px 2px;
    overflow: hidden;
  }

  ul li .itemUpper .btnArea .subBtnArea {
    float: right;
    span {
      vertical-align: top;
    }
  }

  ul li .itemUpper .btnArea a,
  ul li .itemUpper .btnArea button {
    display: inline-block;
    vertical-align: top;
    border: 0 none;
    background: none;
    padding: 0;
    text-decoration: none;
    color: $font_color_default;
  }

  ul li .itemUpper .btnArea a span,
  ul li .itemUpper .btnArea button span {
    display: block;
    text-indent: -9999em;
  }

  ul li .itemUpper .btnArea button.checkBtn span {
    width: 29px;
    height: 22px;
    background: url(#{$IMG_PATH}ccp/top/btn_check_home.png) no-repeat scroll 0 0;
  }


  ul li .itemUpper .btnArea button.uploadBtn span {
    width: 27px;
    height: 21px;
    background: url(#{$IMG_PATH}ccp/top/btn_upload_home.png) no-repeat scroll 0 0;
  }

  ul li .itemUpper .btnArea button.changePdfBtn span {
    width: 14px;
    height: 16px;
    background: url(#{$IMG_PATH}ccp/top/btn_pdf_home.png) no-repeat scroll 0 0;
  }

  ul li .itemUpper .btnArea button.downloadContBtn span {
    width: 21px;
    height: 18px;
    background: url(#{$IMG_PATH}ccp/top/btn_video_home.png) no-repeat scroll 0 0;
  }

  ul li .itemUpper .btnArea button.downloadContBtn.disabled span {
    background-position: 0 -18px;
  }

  ul li .itemUpper .btnArea button.deleteBtn span {
    width: 15px;
    height: 15px;
    background: url(#{$IMG_PATH}ccp/top/btn_delete_home.png) no-repeat scroll 0 0;
  }

  ul li .itemUpper .btnArea button.deleteBtn.disabled span {
    background-position: 0 -15px;
  }

  ul li .itemUpper .btnArea button.playBtn span,
  ul li .itemUpper .btnArea .detailBtn span {
    width: 22px;
    height: 22px;
    background: #fff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    text-indent: 0;
    font-size: 10px;
    line-height: 22px;
  }

  ul li .itemUpper .btnArea button.checkBtn.disabled, ul li .itemUpper .btnArea button.uploadBtn.disabled, ul li .itemUpper .btnArea button.changePdfBtn.disabled, ul li .itemUpper .btnArea button.downloadContBtn.disabled, ul li .itemUpper .btnArea button.deleteBtn.disabled {
    cursor: default;
  }

  ul li .itemUpper .btnArea button.checkBtn.recorded span {
    background-position: 0 -22px;
  }

  ul li .itemUpper .btnArea button.checkBtn.visited span {
    background-position: 0 -44px;
  }

  ul li .itemUpper .btnArea button.uploadBtn.visited span {
    background-position: 0 -21px;
  }

  ul li .itemUpper .btnArea button.uploadBtn.uploaded span {
    background-position: 0 -42px;
  }

  /*-- itemInfo --*/
  ul li .itemInfo {
    overflow: hidden;
    padding: 0 3px;
  }

  ul li .itemInfo p {
    float: left;
    font-family: "Courier New", monospace;
    font-size: 90%;
    font-weight: bold;
    line-height: 1.6;
    color: #333;
  }

  ul li .itemInfo p span {
    font-size: 108%;
  }

  ul li .itemInfo p.date {
    margin-right: 1px;
  }

  ul li .itemInfo p.length {
    padding-left: 11px;
    background: url(#{$IMG_PATH}ccp/common/icons/ico_time_10x10.png) no-repeat scroll 0 4px;
  }

  ul li .itemInfo p.sheets {
    padding-left: 11px;
    margin-right: 2px;
    background: url(#{$IMG_PATH}ccp/common/icons/ico_sheets_10x10.png) no-repeat scroll 0 4px;
  }

  ul li .itemInfo p.size {
    padding-left: 13px;
    margin-right: 2px;
    background: url(#{$IMG_PATH}ccp/common/icons/ico_size_12x10.png) no-repeat scroll 0 5px;
  }

  /*-- hover時 --*/
  ul li.hover .itemUpper {
    -webkit-animation-name: glow;
    -webkit-animation-timing-function: ease-in;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    -webkit-animation-duration: 500ms;
    -moz-animation-name: glow;
    -moz-animation-timing-function: ease-in;
    -moz-animation-iteration-count: infinite;
    -moz-animation-direction: alternate;
    -moz-animation-duration: 500ms;
    -o-box-shadow: 0 0 10px 0 rgba(81, 142, 255, 0.7);
    -ms-box-shadow: 0 0 10px 0 rgba(81, 142, 255, 0.7);
    box-shadow: 0 0 10px 0 rgba(81, 142, 255, 0.7);
  }

  /*-- 完了マーク --*/
  ul li span.mark {
    position: absolute;
    width: 260px;
    height: 195px;
    top: 2px;
    left: 2px;
    text-indent: -9999em;
  }

  ul li.pdfUploaded span.mark {
    background: transparent url(#{$IMG_PATH}ccp/top/bg_mark_pdf_uploaded.png) no-repeat scroll 0 0;
  }

  ul li.recorded span.mark {
    background: transparent url(#{$IMG_PATH}ccp/top/bg_mark_recorded.png) no-repeat scroll 0 0;
  }

  ul li.visited span.mark {
    background: transparent url(#{$IMG_PATH}ccp/top/bg_mark_visited.png) no-repeat scroll 0 0;
  }

  /*-- uploaded --*/
  ul li .uploadedStatus {
    /* display: none; */
    position: absolute;
    width: 264px;
    height: 199px;
    padding-top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    top: 1px;
    left: 1px;
    z-index: 999;
  }

  ul li .uploadedStatus a {
    display: block;
    width: 100%;
    height: 149px;
    margin-top: 50px;
  }

  /*-- encoding --*/
  ul li.encoding span.mark {
    display: none;
  }

  ul li .encodeStatus {
    /* display: none; */
    position: absolute;
    width: 264px;
    height: 232px;
    padding-top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    top: 1px;
    left: 1px;
    z-index: 999;
  }

  ul li .encodeStatus .statusInner {
    padding-top: 50px;
  }

  ul li .encodeStatus p {
    color: #c9c9c9;
    margin-top: 5px;
    font-size: 93%;
    font-weight: bold;
  }

  /* コンテンツ生成エラー */
  ul li.encodingError .encodeStatus {
    height: 199px;
  }

  ul li.encodingError .encodeStatus a {
    display: block;
    width: 100%;
    height: 149px;
  }

  ul li.encodingError .encodeStatus a:hover {
    text-decoration: none;
  }

  ul li.encodingError .itemUpper .btnArea {
    background-color: #090909;
  }

  ul li.encodingError .itemUpper .btnArea button.checkBtn, ul li.encodingError .itemUpper .btnArea button.uploadBtn {
    filter: alpha(opacity=45);
    opacity: 0.4;
    cursor: default;
  }

  /*-- new item --*/
  #newItemBox {
    background: url(#{$IMG_PATH}ccp/top/bg_item_new.png) no-repeat scroll 0 0;
    width: 264px;
    border: 1px solid #fff;
    margin: 0 20px 20px 25px !important;
    height: 81px;
    padding-top: 147px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  #newItemBox.selected {
    background-position: 0 -228px;
  }

  #newItemBox .newItemBtns {
    position: absolute;
    top: 53px;
    left: 194px;
    z-index: 9999;
    height: 117px;
    width: 261px;
    background: url(#{$IMG_PATH}ccp/top/bg_new_item_btns.png) no-repeat;
  }

  #newItemBox button {
    border: 0;
    width: 58px;
    height: 81px;
    background: none;
    position: absolute;
    top: 20px;
  }

  #newItemBox button.jsNewContent {
    left: 33px;
  }

  #newItemBox button.jsNewVideo {
    left: 107px;
  }

  #newItemBox button.jsContentFileUpload {
    left: 182px;
  }

  #newItemBox button:hover {

  }

  #newItemBox button span {
    display: block;
    text-indent: -9999em;
  }

  .jsSearch {
    width: 180px;
  }

  button.searchBtn {
    border: 0 none;
    width: 153px;
    height: 23px;
    background: transparent url(#{$IMG_PATH}ccp/common/btn_search.png) no-repeat scroll 0 0;
  }

  button.searchBtn:hover {
    background-position: 0 -23px;
  }

}