@charset "UTF-8";

.page-courses-chapter-detail {

  .page-inner {

    .page-contents {
      width: 100%;
      padding: 20px 30px;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      max-width: 1200px;
    }

    .m-form {
      left: 0;
      width: 840px;

      .form-wrap {

        &:first-child {
          margin-top: 0;
        }

        .form-group {
          padding-right: 40px;
        }

        table {
          th:first-child {
            line-height: 1.2;
            width: 25%;
          }
        }
      }

      &.conf-form {
        .form-wrap {

          .form-group {
            padding-right: 0;
          }
        }
      }

    }

  }

}


.page-courses-chapter-detail-test {

  .page-inner {
      .m-search-c {
        .m-search-c-inner{
          width: 100%;
          height: 100%;
          position: relative;

          iframe {
            width: 100%;
            height: 100%;

          }
        }
      }

    .page-contents {
      width: 100%;
      padding: 0px 30px;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      max-width: 1200px;
    }

    .m-form {
      left: 0;
      width: 100%;

    .vertical-top{
        vertical-align: top;
    }

	.btn-url {
	    background: url(#{$IMG_PATH}icon/icn_lesson_k.png) 10px center no-repeat !important;
	    background-size: 26px 28px !important;
	    background-position: center;
	    width: 44px;
	    height: 34px;
	    //position: relative;
	    //top: -7px;
	    border: 1px solid #c8c8c8;
	    -webkit-border-radius: 5px;
	    -moz-border-radius: 5px;
	    border-radius: 5px;
        //margin-left: 40%;
	}

    .radios{
        
          .radio-group-custom:first-child{
              padding-bottom: 12px;
              margin: -3px 30px 0 0;
          }
          .row-second {
            width: 87%;
            padding-bottom: 12px;
            margin: -3px 30px 0 31px;
          }
          .row-last {
            width: 100%;
          }
      }

      .form-wrap {
		.copyNotifArea{
             padding-left:10px;
             padding-right:10px;
	         height: 32px;
		}

       .copyNotify {
           display: none;
           padding-left:10px;
           padding-right:10px;
           height: 32px;
           text-align: center;
           background-color: #82BEF0;
           color: white;
           vertical-align: middle;
           border-radius: 3px;
           position: absolute;
           margin-left: 80%;
           span {
               position: relative;
               top: 6px;
           }
         .close {
           position: relative;
           top: -2px;
           right: -7px;
           line-height: 10px;
           padding: 0;
           cursor: pointer;
           background: transparent;
           border: 0;
           color: white;
           -webkit-appearance: none;
         }
       }

        &:first-child {
          margin-top: 0;
          .form-sub-heading {
          }
        }

        .form-group {
          padding-right: 40px;
          margin: 0px 0;

          .setting-chapter{
            float: left;
            width: 75%;
            @media screen and (max-width: 1366px) {
              width: 66%;
            }
          }

          
          .btn-c-search-chapter{
            background: none;
            width: 90px;
            height: auto;
            line-height: 35px;
            color: #468CC8;
            border: #468CC8 solid 1px;
            
          }

          .btn-c-clear-chapter{
            background: none;
            width: 90px;
            height: auto;
            line-height: 35px;
            color: #468CC8;
            border: #468CC8 solid 1px;
          }

          .btn-c-lti-tool-search-chapter{
            background: none;
            width: 200px;
            height: auto;
            line-height: 35px;
            color: #468CC8;
            border: #468CC8 solid 1px;
            
          }

          .lti-iframe-size{
            padding-left: 110px;
          }
          
          .btn-register-deployment-id{
            background: none;
            width: 90px;
            height: auto;
            line-height: 35px;
            border: #468CC8 solid 1px;
            border-radius: 5px;
            font-size: 16px;
            color: #468CC8;
            font-weight: normal;
          }

          .hints{    
            display: inline-block;
            position: relative;
            &.text-content{
              margin-top: 5px;
            }
            .hint-btn{
              background: url(#{$IMG_PATH}icon/icn_hints_bk.png) no-repeat;
              padding-left: 25px;
              background-position: 50%;
              -webkit-background-size: 20px;
              background-size: 20px;
            }
            .hint-content{
              display: none;
              width: 400px;
              background: #d3edfa;
              padding: 10px;
              font-size: 12px;
              margin-top: -75px;
              margin-left: 29px;
              position: absolute;
              z-index: 0;
              @media screen and (max-width: 1025px) {
                width: 260px;
              }

                @media screen and (min-width: 1026px) {
                  @media screen and (max-width: 1280px) {
                    width: 300px;
                  }
              }
            }
          }

          .specified-time-start {
            margin-right: 23px;
          }

          .input-filename{
            width:20% !important;
          }

          .input-comment{
            width:72% !important;
          }

          .img_face {
            margin-right:10px;
            margin-top:-4px;
          }

          .btn-delete{
            background: url(#{$IMG_PATH}icon/icn_trash_k.png) 10px center no-repeat;
            -webkit-background-size: 90%;
            background-size: 90%;
            background-position-x: 50%;
            font-weight: normal;
            width: 34px;
            height: 34px;
            border: 1px solid;
            border-radius: 4px;
            vertical-align: middle;
          
          &.disabled {
              cursor: default;
              background-color: #ddd;
              opacity: .3;
            }
          }

          .set-height-for-document {
              height: 45px;
          }

          .form-download-chapter{
            div.form-download-info-chapter{
              label{
                color: #0b3182;
                cursor:default;
              }
            }

            div.form-download-info-temp-chapter{
              margin-top:10px;
              label{
                color: #0b3182;
                cursor:default;
              }
            }
            
            div.form-btn-add-chapter {
                margin-top:10px;
                position: absolute;
            }

            div.form-btn-add-chapter {
              .btn-upload-chapter{
                right: 0;
                bottom: 0;
                overflow: hidden;
                padding: 10px;
                label {
                  @include HoverOpacity();
                  background: url(#{$IMG_PATH}icon/icn_selectbtn_plus_b.png);
                  -webkit-background-size: 100% 100%;
                  background-size: 100% 100%;
                    width: 38px;
                    height: 38px;
                    display: block;
                    &.btn-add-disabled{
                      opacity: 0.5;
                      pointer-events: none;
                      background: url(#{$IMG_PATH}icon/icn_btn_plus_k.png);
                      -webkit-background-size: 100% 100%;
                      background-size: 100% 100%;
                    }
                  }

                  input[type="file"]{
                    position: absolute;
                    left: -9999px;
                    top: -9999px;
                  }
              }
            }
          }

          .late-period-button {
            margin-left: 20px;
          }
          .page-thread-search {
              height: 100%;
              .page-thread-inner {
                  background: $background_theme_color_contents;
                  height: 100%;
                  .page-thread-contents {
                      width: 100%;
                      height: 450px;
                      margin: auto;
                      -webkit-box-sizing: border-box;
                      -moz-box-sizing: border-box;
                      box-sizing: border-box;
                  }
                  .thread-result {
                      @include search_table_f;
                      padding: 10px 10px 0px 10px!important;
                      height: 85%;
                      position: relative;
                      .table-header{
                          table-layout: auto;
                          .col-name{
                              min-width: 257px;
                          }
                          .col-id{
                              min-width: 135px;
                          }
                          .col-btn{
                              min-width: 83.2px;
                          }
                          tr{
                              width: 100%;
                              position: fixed;
                              th{
                                  padding: 5px 0;
                                  box-sizing:border-box;
                                  -webkit-box-sizing: border-box;
                                  -moz-box-sizing: border-box;
                              }
                          }
                      }
                      .table-result-nodata{
                          height: 100%;
                          tr{
                              td{
                                  border: 0px solid #fafafa;
                                  color: #AFAFAF;
                                  box-sizing:border-box;
                                  -webkit-box-sizing: border-box;
                                  -moz-box-sizing: border-box;
                                  span{
                                      position: relative;
                                  }
                              }
                          }
                      }
                      .table-result{
                          table-layout: auto;
                          .col-name{
                              min-width: 250px;
                              width: 35%;
                          }
                          .col-id{
                              min-width: 135px;
                              width: 10%;
                          }
                          .col-btn{
                              min-width: 84px;
                              width: 10%;
                          }
                          .btn-up, .btn-down {
                              @include HoverOpacity();
                              position: absolute;
                              display: block;
                              width: 15px;
                              height: 15px;
                              z-index: 1;
                              -webkit-box-sizing: border-box;
                              -moz-box-sizing: border-box;
                              box-sizing: border-box;
                            }

                            .btn-up {
                              background-position: center;
                              background-repeat: no-repeat;
                              background-image: url(#{$IMG_PATH}icon/icn_pullup_k.png);
                              background-size: 15px;
                              left: 110px;
                              margin-top: -18px;
                            }
                            .btn-down {
                              background-position: center;
                              background-repeat: no-repeat;
                              background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
                              background-size: 15px;
                              left: 95px;
                              margin-top: -18px;
                            }
                          tr{
                              td:not(.row-ope){
                                  box-sizing:border-box;
                                  -webkit-box-sizing: border-box;
                                  -moz-box-sizing: border-box;
                              }
                              .icon-disable {
                                background: #eee;
                              }
                          }
                      }
                      table{ 
                          tr:not(:first-child):hover, tr.selected,.hover{
                              .btn-submit-delete { 
                                  background: url(#{$IMG_PATH}icon/icn_trash_w.png) 20px center no-repeat;
                                  -webkit-background-size: 80%;
                                  background-size: 80%;
                                  background-position-x: 50% ;
                              }
                              .btn-submit-setting {
                                  background: url(#{$IMG_PATH}icon/icn_edit_w.png) 20px center no-repeat;
                                  -webkit-background-size: 80%;
                                  background-size: 80%;
                                  background-position-x: 50% ;
                              }
                              .btn-down {
                                background-image: url(#{$IMG_PATH}icon/icn_pulldown_w.png);
                              }
                              .btn-up {
                                background-image: url(#{$IMG_PATH}icon/icn_pullup_w.png);
                              }
                          }
                      }
                  }

                  .thread-list-inner{
                      height: 100%;
                      background-color: #fff;
                  }
                  .thread-list-outer{
                      width: 100%;
                      height: 100%;
                      -webkit-box-sizing: border-box;
                      -moz-box-sizing: border-box;
                      box-sizing: border-box;
                    }
                  .scroll-area {
                      &.ps-active-y {
                          padding-right: 16.2px;
                          margin-right: -16.2px;
                      }
                      .ps-scrollbar-y-rail{
                          right: 16.2px;
                      }
                      height: 100%;
                  } // .scroll-area
                  .btn-new{
                    background-color: #fff;
                    margin: 0px 10px 10px 10px !important;
                    height: 10%;
                    .btn-new-thread {
                      cursor: pointer;
                      @include HoverOpacity();
                      background: url(#{$IMG_PATH}icon/icn_selectbtn_plus_b.png);
                      -webkit-background-size: 100% 100%;
                      background-size: 100% 100%;
                        width: 38px;
                        height: 38px;
                        display: block;
                        float: right;
                        margin-right: 5px;
                        margin-top: 4px;
                      &.btn-new-disabled {
                        opacity: 0.5;
                        pointer-events: none;
                        background: url(#{$IMG_PATH}icon/icn_btn_plus_k.png);
                        -webkit-background-size: 100% 100%;
                      background-size: 100% 100%;
                      }
                    }
                  }
              }
              input.btn,
              a.btn {
                  &.btn-submit-download {
                      float: right;
                      font-weight: initial;
                      text-align: center;
                      width: auto;
                      padding: 6px 19px;
                      color: #000;
                      border: 1px solid #c8c8c8;
                      margin: -3px 0;
                      line-height: 22px;
                      background: #fff;
                      color: #6E6E6E;
                      img{
                        width: 20px;
                        height: 20px;
                        padding-right: 7px;
                      }
                  }
                  &.btn-new-course {
                    width: 150px !important;
                  }
                  &.btn-submit-upload {
                      background: url(#{$IMG_PATH}icon/icn_upload_w.png) 20px center no-repeat;
                      -webkit-background-size: 20px;
                      background-size: 20px;
                      color: #468cc8;
                      border: 1px solid;
                      background-color: white;
                      font-weight: normal;
                      width: auto;
                      height: 34px;
                      line-height: 34px;
                      text-align: center;
                      -webkit-border-radius: 0px;
                      -moz-border-radius: 0px;
                      border-radius: 0px;
                      font-size: 16px;
                      padding: 0;
                  }
                  &.btn-submit-search-new {
                      background: url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
                      -webkit-background-size: 20px;
                      background-size: 20px;
                      background-position-x: 50% ;
                      color: #468cc8;
                      border: 1px solid #82BEF0;
                      background-color: #82BEF0;
                      font-weight: normal;
                      width: 130px;
                      height: 34px;
                      line-height: 34px;
                      text-align: center;
                      -webkit-border-radius: 0px;
                      -moz-border-radius: 0px;
                      border-radius: 5px;
                      font-size: 16px;
                      padding: 0;
                  }
                  &.btn-submit-setting {
                      background: url(#{$IMG_PATH}icon/icn_edit_k.png) 20px center no-repeat;
                      -webkit-background-size: 80%;
                      background-size: 80%;
                      background-position-x: 50% ;
                      font-weight: normal;
                      width: 34px;
                      height: 34px;
                      line-height: 34px;
                      text-align: center;
                      font-size: 16px;
                      padding: 0;
                  }
                  &.btn-submit-delete {
                      background: url(#{$IMG_PATH}icon/icn_trash_k.png) 20px center no-repeat;
                    -webkit-background-size: 80%;
                      background-size:80%;
                      background-position-x: 50% ;
                      font-weight: normal;
                      width: 34px;
                      height: 34px;
                      line-height: 34px;
                      text-align: center;
                      font-size: 16px;
                      padding: 0;
                  }
                
              }
          }
            
            .address-button{
                float: right;
                margin-bottom: -2%;
            }
            .chapter-name{
                float: left;
                padding-top: 0%;
                width: 90%;
            }
        }

        table {
          th:first-child {
            line-height: 1.2;
            width: 25%;
          }

          tr th:first-child{
            padding-right: 0px;
          }

          .inner-table {
            position: relative;
            left: -20%;
          }
        }
            .checkbox-btn-check-disabled{
              pointer-events: none;
      label{
        cursor: default;
      }

      input{
        cursor: default;
        pointer-events: none;
      }

        label::before{
          border-color: #787878 !important;
          cursor: default;
        }
        label::after{
          background-color: #eee !important;
          border-color: #787878 !important;
          cursor: default;
        }
    }

    .checkbox-btn-uncheck-disabled{
      pointer-events: none;
        label{
        cursor: default;
      }

      input{
        cursor: default;
      }

        label::before{
          cursor: default;
        }
        label::after{
          background-color: #eee !important;
          cursor: default;
        }
    }
  
    .pseudo-radio {
      &.radio-checked-disabled {
        border: 1px solid #787878;
        background-color: #c9c9c9;
        input[type="radio"]:disabled+span {
            background: #787878;
            cursor: default
        }
      }
    }

      }

      &.conf-form {
        .form-wrap {

          .form-group {
            padding-right: 0;
          }
        }
      }

    }

    .div-line{
      .half-left{
        width: 100%;
        text-align: left;
        border-bottom: 1px solid #c8c8c8;
        margin-left: 5%;
        padding-left: 10px;
      }


    }

    .input-small{
      width: 80px;
      margin-right: 15px;
    }

    .clear-float{
      clear: both;
    }

    .preview-area{
      padding: 10px;
      background: rgb(238, 238, 238);
      width: 432px;
      .preview-title {
        margin-left: 10px;
      }
      .preview{
        width: initial;
      }
    }
  }

}

    .m-newthread-modal {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      background: rgba(0, 0, 0, .5);
      display: none;

      a {
        @include HoverOpacityTrans();
        cursor: pointer;
      }

      .m-newthread-modal-inner {
        text-align: center;
        background: #fff;
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 11000;
        width: 700px;
        margin: -250px 0 0 -280px;


        .modal-header {
          padding-top: 25px;
          border: 0 none;
          position: relative;
          padding: 5px 15px;
          text-align: center;
          height: 5%;

          h3 {
              font-size: 26px;
              font-weight: bold;
          }

        } //.modal-header

        .modal-body {
            padding: 15px 15px 0px 30px;
//            max-height: 450px;	// CC20-4520

            a {
              @include HoverOpacityTrans();
              cursor: pointer;
            }

            &.m-form {
              width: 100%;
              margin: auto;
              left: 0px;
              position: relative;
              font-size: 20px;
            }

            .clear-txt {
              float: left;
              clear: both;
              display: flex;
              width: 85%;

              &.error {
                margin-left: 17%;
                margin-top: 3px;
              }

              &.hide {
                display: none;
              }

              &.txt {
                display: flex;
                align-items: center;
                justify-content: center;
              }

              &.chk {
                margin-top: 20px;
              }

              span {
                width: 80px;
                text-align: left;
                min-width: 20%;
              }

              &.area-h {
                height: 200px;
                margin-top: 20px;
              }

              .input-file {
                position: relative;
                height: 44px;
                z-index: 99;
                filter: alpha(opacity=0);
                opacity: 0.0;
                cursor: pointer;
                overflow: hidden;
              }

              &.chs-img {
                height: 44px;
                margin-top: 20px;
              }
            } //.clear-txt

            .post-inner {
              display: flex;
              width: 100%;

              .post-img {
                float: left;
              }

              .post-img {
                &>img {
                  width: 100px;
                  height: 100px;
                  object-fit: contain;
                }

              }

              .post-text {
                width: 65.55%;
                z-index: 4;
                background: white;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 10px;
                border: 2px solid #0b3182;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                padding: 10px;
                margin-left: 20px;
                align-items: center;
                display: flex;

              .post-text-inner {
                text-align: left;
                line-height: 100%;

                & > a {
                  text-decoration: underline;
                  font-size: 11px;
                  color: #333;
                }

                & > span {
                  font-size: 11px;
                }

              }

              .post-tri {
                position: absolute;
              /*     top: 125px; */
              /*     left: 98px; */
                display: block;
                width: 20px;
                height: 20px; 

                &:after {
                    content: " ";
                    display: block;
                    width: 12px;
                    height: 12px;
                    margin: 18px -18px;
                    background: white;
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                    border-bottom: 2px solid #0b3182;
                    border-left: 2px solid #0b3182;
                }

              }

            } //.post-text

          } //.post-inner

        } //.modal-body

        .modal-footer {
            padding-top: 10px;
            padding-bottom: 0;
            height: 5%;
        } //.modal-footer

      }  //.m-qa-modal-inner

      .btn-modal-close {
        position: absolute;
        display: block;
        width: 70px;
        height: 70px;
        right: -70px;
        top: -20px;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
        background: #fff;
        line-height: 50px;
        text-align: center;

        p {
          height: 20px;
        }

      }

    }  //.m-thread-modal

    .m-newthread-modal .m-newthread-modal-inner {
        .hide {
            display: none !important;
        }
        .modal-header {
            position: relative;
            padding-top: 30px;
            padding: 0px;
            .btn-modal-exit {
                position: absolute;
                border-radius: 0px;
                top: 5px;
                right: 5px;
                width: 20px;
                height: 20px;
                background: url(#{$IMG_PATH}icon/icn_close_k.png) center no-repeat;
                background-size: 15px;
            }
            div {
                font-size: 16px;
                color: #82BEF0;
                margin: 0px auto;
                line-height: 36px;
                vertical-align: middle;
            }
        }
        .footer {
            clear: both;
            margin-bottom: 20px;
            .btn {
                width: 200px;
                height: 40px;
                line-height: 40px;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                font-size: 16px;
                border-radius: 2px;
                font-weight: normal;
                color: #DC780A;
                border: #DC780A solid 1px;
            }
        }
    }

    .m-newthread-modal .m-newthread-modal-inner table {
      .jsAddImage {
        position: absolute;
        width: 170px;
        height: 26px;
        z-index: 99;
        margin-left: -170px;
        filter: alpha(opacity=0);
        opacity: 0.0;
        cursor: pointer;
        overflow: hidden;
        font-size: 0px;
        }
      .btn-img-select {
          color: #858585;
          border: 1px solid #a29e9d;
          border-radius: 3px;
          padding-left: 30px;
          width: 170px;
          height: 26px;
          background: url(#{$IMG_PATH}icon/icn_fileattachment_k.png) 10px no-repeat;
          -webkit-background-size: 20px;
          background-size: 20px;
          font-size: smaller !important;
          }
         .input-filename{
            width:20% !important;
          }

          .input-comment{
            width:72% !important;
          }

          .btn-delete{
            background: url(#{$IMG_PATH}icon/icn_trash_k.png) 10px center no-repeat;
            -webkit-background-size: 90%;
            background-size: 80%;
            background-position-x: 50%;
            font-weight: normal;
            width: 25px;
            height: 25px;
            border: 1px solid;
            border-radius: 4px;
            vertical-align: middle;
          
          &.disabled {
              cursor: default;
              background-color: #ddd;
              opacity: .3;
            }
          }

          .set-height-for-document {
              height: 45px;
          }

          .form-download-thread{
            div.form-download-info-thread{
              label{
                color: #0b3182;
                cursor:default;
              }
            }

            div.form-download-info-temp-thread{
              margin-top:5px;
              label{
                color: #0b3182;
                cursor:default;
              }
            }
            
            div.form-btn-add-thread {
                margin-top:10px;
                position: absolute;
            }

            div.form-btn-add-thread {
              .btn-upload-thread{
                right: 0;
                bottom: 0;
                overflow: hidden;
                padding: 10px;
                label {
                    @include HoverOpacity();
                  background: url(#{$IMG_PATH}icon/icn_selectbtn_plus_b.png);
                  -webkit-background-size: 100% 100%;
                  background-size: 100% 100%;
                    width: 38px;
                    height: 38px;
                    display: block;
                  }

                  input[type="file"]{
                    position: absolute;
                    left: -9999px;
                    top: -9999px;
                  }
              }
            }
          }
      }

      #newThread.m-newthread-modal {
        .m-newthread-modal-inner {
//            top: 40% !important;	// CC20-4520
            margin: 2% 8% 2% 8%;	// CC20-4520
            width: 90%;				// CC20-4520
            height:95%;				// CC20-4520
            min-width: 700px;		// CC20-4520
            max-width: 1200px;		// CC20-4520
            min-height: 620px;		// CC20-4520
            max-height: 840px;		// CC20-4520
            top: auto;				// CC20-4520
            left: auto;				// CC20-4520
            .modal-header {
                div {
                    color: #fff;
                    background-color: #82BEF0;
                }
                .btn-modal-exit {
                    background: url(#{$IMG_PATH}icon/icn_close_w.png) center no-repeat;
                    background-size: 15px;
                }
            }
            .require-info {
                text-align: right;
                width: 85%;
                margin: 0px auto;
            }
            .modal-body {
                padding: 0px;
                height: 80%;			// CC20-4520

                #addOrUpdateThread {	// CC20-4520
                    height: 100%;
                }

                table {
                    width: 100%;
                    height: 100%;		// CC20-4520
                    margin: 0px auto;
                    border-spacing: 15px;
                    border-collapse: separate;

					.modal-post-content {	// CC20-4520
						max-height: 400px;
						min-height: 150px;
						height: 100%;

						#cke_responseText {				// CC20-4520

							height: 80%;
							max-height: 400px;
							max-width: 900px;

							.cke_inner {				// CC20-4520

								height: 100%;

								.cke_contents {			// CC20-4520

									height: 100%;

									.cke_wysiwyg_div {	// CC20-4520
										height: 100%;
										max-height: 400px;
									}
								}
							}
						}
					}

                    td.td-left {
                        width: 15%;
                        vertical-align: top;
                        padding-top: 5px;
                    }
                    td.td-right {
                        width: 85%;
                        text-align: left;
                        padding-right: 10%;
                    }
                    td {
                        textarea {
                            border: 1px solid #a9a9a9;
                            width: 100%;
                            max-width: 486px;
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                            box-sizing: border-box;
                            resize: none;
                        }
                        .cke_top{
                          display: none;
                        }
                        .cke_bottom{
                          display: none;
                        }
                        .cke_contents {
                          overflow-y: auto;
                        }
                        input{
//                        max-width: 486px;			// CC20-4602
                        }
                        .input-filename {			// CC20-4602
                          max-width: 486px;
                        }
                        .input-comment {			// CC20-4602
                          width: 67% !important;
                        }
                        .input-text {
                            width: 100%;
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                            box-sizing: border-box;
                        }
                    }
                }
            }
        }
    }

.m-register-deployment-id-modal {
	display: none;
	width: 100%;
	div.modal-inner{
		width: 600px !important;
		.btn-modal-exit-deployment {
			position: absolute;
			border-radius: 0px;
			top: 5px;
			right: 5px;
			width: 20px;
			height: 20px;
			background: url(#{$IMG_PATH}icon/icn_close_k.png) center no-repeat;
			background-size: 15px;
		}
		div.layout-table{
			margin: auto;
			height: 100% !important;
		}
	}

	.info{
	
		.search-result-header {
			display: flex;
			padding-bottom: 5px;
			
			.pulldown-pager {
				.sel-size-mini-custom {
					p {
						&.sel-txt {
							font-size: 12px;
							font-weight: normal;
							margin-top: 0;
						}
					}
				}
			}
		}

		.scroll-area{
			height: 400px !important;
			background: white;
		}

		.message-no-data{
			color: #AFAFAF;
		}
	}

	#result{
		padding:20px 20px 0 20px;
	}

	.result-inner{
		padding-bottom: 15px !important;
	}	
	
	.btn-submit{
		text-align: center;
	}

	.content-result {
		padding: 10px !important;
		position: relative;
		background:#D1EAF9 !important;
		height: 400px;
		@include search_table_f;
		table.deployment-table-result{
			width: 100% !important;
			table-layout: fixed !important;
			thead{
				position: sticky;
				top: 0;
				tr{
					th.col-deployment-id{
						width: 80% !important;
						padding: 5px;
					}
					th.col-btn{
						width: 20% !important;
						padding: 5px;
					}
				}
			}
			tbody{
				overflow-x: hidden;
				height: 80% !important;
				td.col-deployment-id{
					width: 80% !important;
					input{
						display: inline-block;
						text-align: left;
						width: 100%;
						box-sizing: border-box;
					}
					p{
						display: inline-block;
						padding: 10px 0px 0px 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						margin: 0px;
						text-align: left;
						width: 100%;
						box-sizing: border-box;
						font-weight: normal !important;
					}
				}
				td.col-btn{
					width: 20% !important;
					a{
						display: inline-block;
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
						color: #82BEF0
					}
				}
			}
			tr{
				height: 55px !important;
				.hover, &.selected{
					.btn-submit-delete-deployment { 
						background: url(#{$IMG_PATH}icon/icn_trash_w.png) 20px center no-repeat;
						-webkit-background-size: 80%;
						background-size: 80%;
						background-position-x: 50% ;
					}
					.btn-submit-setting-deployment { 
						background: url(#{$IMG_PATH}icon/icn_edit_w.png) 20px center no-repeat;
						-webkit-background-size: 80%;
						background-size: 80%;
						background-position-x: 50% ;
					}
				}
			}
			tr{
				.hover{
					&.disabled{
						color: rgba(255,255,255,0.3);
					}
				}
				&.selected{
					.disabled{
						color: rgba(255,255,255,0.3)
					}
				}
			}
		}
		.add-area{
			float: right;
			padding: 5px 0;
			vertical-align: middle;
			text-align: center;
			width: 10%;
			.btn-add-row{
				border: 1px solid #a29e9d;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				border-radius: 50%;
				height: 30px;
				width: 30px;
				color:#a29e9d;
				text-align: center;
				vertical-align: middle;
				line-height: 30px;
			} 
		}
		
		.btn-submit-delete-deployment {
			background: url(#{$IMG_PATH}icon/icn_trash_k.png) 20px center no-repeat;
			-webkit-background-size: 80%;
			background-size:80%;
			background-position-x: 50% ;
			font-weight: normal;
			width: 34px;
			height: 34px;
			line-height: 34px;
			text-align: center;
			font-size: 16px;
			padding: 0;
		}
		.btn-submit-setting-deployment {
			background: url(#{$IMG_PATH}icon/icn_edit_k.png) 20px center no-repeat;
			-webkit-background-size: 80%;
			background-size: 80%;
			background-position-x: 50%;
			font-weight: normal;
			width: 34px;
			height: 34px;
			line-height: 34px;
			text-align: center;
			font-size: 16px;
			padding: 0;
		}
	}

	@media screen and (max-height : 768px) {
		&.page-material-selection {
			width:100%;
			height:300px;
		}
		.content-result{
			height: 310px;
		}
		.form-btns>div{
			margin: 3px 0;
		}
		.btn-close-modal{
		    padding: 0px 4px;
			font-size: 13px;
		}
		.info{
			.scroll-area {
				height: 225px !important;
			}
			.table-result-nodata {
				height: 258px !important;
			}
		}
		.page-inner{
			margin-top: 70px;
		}
	}

	@media screen and (max-height : 768px) and (-ms-high-contrast: none), (-ms-high-contrast: active){
		.info .scroll-area{
			height: 312px !important;
		}
	}
	.footer {
		display: block !important;
		position: relative;
		width: 60% !important;
		height: 50px;
		padding: 0px;
		margin: auto;
		top: 20%;
		text-align: center;
		.btn {
			background: #fff;
			width: 200px;
			height: 40px;
			line-height: 40px;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			font-size: 16px;
			border-radius: 5px !important;
			font-weight: normal;
			color: #DC780A;
			border: #DC780A solid 1px;
		}
	}
}

.deployment-modal {
	display: none;
	.modal-inner{
		top: 30% !important;
	}
	p.msg-comp {
		overflow-wrap: break-word;
	}
}