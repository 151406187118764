@charset "UTF-8";

.import-debate{
  #modal-import2{
    .modal-inner-import{
      top : 5% !important;
    }
  }
   #modal-import3{
     .modal-inner-CSVimport{
       top : 5% !important;
     }
   }
}