@charset "UTF-8";

.modal-inner {
  &.modal-inner-video_play_count {
    padding: 25px;
    width: 600px;
    height: 580px;
    .title {
      margin-bottom: 10px;
    }
    .result-label {
      margin-bottom: 10px;
    }
    .caution {
      color: #FF0000;
      margin-bottom: 10px;
    }
    .search-header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .label {
        color: #82BEF0;
        margin-right: 20px;
      }
      .sel {
        .sel-txt {
          span {
            vertical-align: sub;
          }
        }
        margin-right: 10px;
        &.sel-target-hour {
          width: 200px;
        }
      }
      button#search {
        -webkit-background-size: 22px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        background: url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
        background-color: #82BEF0;
        background-size: 22px;
        background-position-x: 50%;
        border: 1px solid #82BEF0;
        border-radius: 5px;
        color: #468cc8;
        font-size: 12px;
        font-weight: normal;
        height: 28px;
        line-height: 34px;
        padding: 0;
        text-align: center;
        width: 60px;
      }
      @mixin placeholder($color) {
        &::placeholder {
          color: $color;
        }
        // IE
        &:-ms-input-placeholder {
          color: $color;
        }

        // Edge
        &::-ms-input-placeholder {
          color: $color;
        }
      }
    }
    .search-result-box {
      background: #bae3f9;
      height: 400px;
      .search-result-header {
        display: flex;
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        text-align: center;
        z-index: 11000;
        .except {
          height: 71px;
          display: none;
        }
        .result-txt {
          margin-top: 5px;
          margin-left: 10px;
          min-height: 30px;
          display: flex;
          align-items: center;
        }
      }
      .search-result-body {
        height: 365px;
        .result-list-outer {
          width:100%;
          height:100%;
          padding: 0 10px 10px 10px;
          box-sizing: border-box;
          .result-list-inner {
            height: 100%;
            background-color: #fff;
            .table-header {
              width: 100%;
              table-layout: auto;
              tr {
                th {
                  height: 30px;
                  border-width: 0 1px 1px 1px;
                  border-color: #c8c8c8;
                  border-style: solid;
                  &:first-child {
                    border-left: 0;
                  }
                  &:last-child {
                    border-right: 0;
                  }
                }
                &:first-child {
                  th {
                    color: #82BEF0;
                    vertical-align: middle;
                    text-align: center;
                    width: 33.33%;
                  }
                }
              }
            }
            .scroll-area {
              overflow:hidden;
              height: calc(100% - 30px);
              .table-result {
                background-color: #ffffff;
                width: 100%;
                border-collapse: collapse;
                table-layout: fixed;    
                tr {
                  td {
                    height: 30px;
                    width: 50%;
                    border-width: 0 1px 1px 1px;
                    border-color: #c8c8c8;
                    border-style: solid;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding: 0 15px;
                    overflow: hidden;
                    vertical-align: middle;
                    &:first-child {
                      border-left: 0;
                    }
                    &:last-child {
                      border-right: 0;
                    }
                  }
                }
              }
              .ps-scrollbar-y-rail {
                right: 0px;
              }
            }
            .table-result-nodata {
              height: 355px;
              width: 100%;
              #nodata, #loading {
                vertical-align: middle;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }  
}