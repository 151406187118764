@charset "UTF-8";
.page-exceptional-user-search{
  .m-modal { 
    .modal-inner.page-exceptional-user-search-modal-inner {
      background: #fff;
      min-width: 600px;
      padding-top: 15px;
      text-align: center;
      top: 20px;
      width: 80%;
      .header-line {
        .header-txt {
          color: #82bef0;
          display: inline-block;
          font-size: 22px;
          font-weight: bold;
          text-align: center;
        }
        .select-upload {
          position: absolute;
          right: 45px;
          top: 10px;
          .sel.sel-size-auto {
            height: 36px;
            width: 170px;
            background-color: white;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 0px;
            color: #468CC8;
            border: 1px solid;
            .sel-txt {
              margin-top: 0px !important;
              padding-left: 10px;
              padding-right: 17px;
              background-size: 13px;
              background-image: url(#{$IMG_PATH}icon/icn_selectbtn_bottom_b.png);
              span{
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
              }
            }
          }
        }
      }
      .m-form-search {
        width: 100%;
        padding-top: 10px;
        .form-wrap {
          .inner {
            .form-row {
              margin: 0 auto 5px;
              text-align:left;
              width: 100%;
              min-height: 70px;
              .search-input-box {
                display: inline-block;
                margin: 0 0 0 5%;
                text-align: left;
                vertical-align: top;
                width: 40%;
                input[type="text"] {
                  padding:5px;
                }
                .search-sub-ttl {
                  display: inline-block;
                  text-align: left;
                  width: 35%;
                } 
                .input-text {
                  display: inline-block;
                  text-align: left;
                  width: 63%;
                }
                .error {
                  margin-top: 0px;
                }
              }
            }
            .form-row-checkbox{
              margin: 0 auto 5px;
              text-align:left;
              width: 100%;
              .search-checkbox {
                margin: 0 0 0 5%;
                text-align: left;
                width: 90%;
                .checkbox-btn{
                  display: inline-block;
                  top: 0px;
                  left: 0px;
                }
                .search-sub-ttl {
                  display: inline-block;
                  text-align: left;
                  width: 15.5%;
                } 
              }
            }
            .search-btn-area {
              .btn{
                margin: 0 7px;
                &.btn-clear{
                  -webkit-border-radius: 5px;
                  -moz-border-radius: 5px;
                  border: 1px solid #c8c8c8;
                  background: url(#{$IMG_PATH}icon/icn_formclear_k.png) 20px center no-repeat;
                  background-size: 22px;
                  border-radius: 5px;
                  color: #333;
                  font-size: 12px;
                  font-weight: normal;
                  height: 33px;
                  padding-left: 27px;
                  position: relative;
                  text-align: center;
                  width: 150px;
                }
                &.btn.btn-submit-search-new {
                  -webkit-background-size: 22px;
                  -webkit-border-radius: 5px;
                  -moz-border-radius: 5px;
                  background: url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
                  background-color: #82BEF0;
                  background-size: 22px;
                  background-position-x: 50%;
                  border: 1px solid #82BEF0;
                  border-radius: 5px;
                  color: #468cc8;
                  font-size: 12px;
                  font-weight: normal;
                  height: 34px;
                  line-height: 34px;
                  padding: 0;
                  text-align: center;
                  width: 150px;
                }
              }
            }
          }
        }
      }
         
      #external-user-save-form {
        .search-result-box{
          background: #bae3f9;
          padding-bottom: 5px;
	      .search-result-header {
	        display: flex;
	        min-width: 580px;
		    padding-left: 0px;
		    padding-right: 0px;
		    text-align: center;
		    z-index: 11000;
		    .except {
		      height: 71px;
		      display: none;
		    }
		    .result-txt{
		      margin-top: 5px;
		      margin-left: 10px;
		      min-height: 30px;
		      display: flex;
		      align-items: center;
		    }
		    .pulldown-pager{
		      display: inline-block;
		      margin-left: 5px;
		      margin-right: 10px;
		      margin-top: -5px;
		      color: #888;
		      vertical-align: middle;
		    }
		    .s-pager {
		      display: inline;
		      position: relative;
		      top: -1px;
		      .prev {
		        background-image: url(#{$IMG_PATH}icon/icn_arrowleft_k.png);
		        background-position: center;
		        background-position-y: 6px;
		        background-repeat: no-repeat;
		        background-size: 150% 50%;
		      }
		      a {
		        margin-left: -3px;
		        display: inline-block;
		        padding: 0 8px;
		        height: 26px;
		        line-height: 26px;
		        text-align: center;
		        color: #333;
		        border-radius: 5px;
		        font-size: 15px;
		      }
		      .next {
		        background-image: url(#{$IMG_PATH}icon/icn_arrowright_k.png);
		        background-position: center;
		        background-position-y: 6px;
		        background-repeat: no-repeat;
		        background-size: 150% 50%;
		      }
		    }
		    .download {
		      margin: 5px 10px 0 auto;
		    }
		  }	         
		}
		.user-result {
		  background-coloer: #fff;
		  padding: 20px;
		  padding: 5px 0 !important;
		  margin-right: 10px;
		  margin-left: 10px;
		  height: 100%;
		  position: relative;
		  .user-list-outer {
		    width: 100%;
		    height: 100%;
		    -webkit-box-sizing: border-box;
		    -moz-box-sizing: border-box;
		    box-sizing: border-box;
		    .user-list-inner {
		      height: 100%;
		      background-color: #fff;
		      .table-header {
		        width: 100%;
		        table-layout: auto;
		        tr {
		          th{
		            border: 1px solid #c8c8c8;
                    border-top: 0px solid #fafafa;
		            padding: 5px 0;
		          }
		          width: 90%;
		          th:first-child {
		            border-left: 0px solid #fafafa;
		          }
		          th:last-child {
		            border-right: 0px solid #fafafa;
		          }
		          .col-user {
		            width: 30%;
		          }
		          .col-group {
		            width: 40%;
		          }
		          .col-external-auth {
		            .checkbox-btn {
		              display: inline-block;
		              label:before {
		                top: -6px;
		              }
		              label:after {
		                top: -10px;
		              }
		            }
		          }
		        }
		        tr:first-child th {
		          background: #fff !important;
		          color: #82BEF0;
		          vertical-align: middle;
		          text-align: center;
		        }
		      }
		      .scroll-area {
		        height: 200px !important;
		        overflow: hidden !important;
		        .table-result {
		          background-color: #ffffff;
		          width: 100%;
		          table-layout: auto;
		          border-collapse: collapse !important;
		          table-layout: fixed;
		          tr {
		            width: 90%;
		            td{
		              border: 1px solid #c8c8c8;
                      border-top: 0px solid #fafafa;
                      padding: 5px 0;
		            }
		            td:first-child {
		              border-left: 0px solid #fafafa;
		            }
		            td:last-child {
		              border-right: 0px solid #fafafa;
		            }
		            .col-user {
		              width: 30%;
		              vertical-align: middle;
		            }
		            .col-group {
		              width: 40%;
		              vertical-align: middle;
		            }
		            .col-external-auth {
		              width: 30%;
		              vertical-align: middle;
		              .checkbox-btn{
                        display: inline-block;
                        top: 0px;
                        left: 0px;
                        label:before{
                          top: -7px;
                        }
                        label:after{
                          top: -11px;
                        }
                      }
		            }
		            
		          }
		          tr:first-child th {
		            background: #fff !important;
		            color: #82BEF0;
		            vertical-align: middle;
		            text-align: center;
		          }
		        }
		      }
		      .table-result-nodata {
		        height: 233px;
		        width: 100%;
		        #nodata{
		          vertical-align: middle;
		          text-align: center;
		        }
		        #loading {
		          vertical-align: middle;
		          text-align: center;
		        }
		      }
		    }
		  }
		  .ps-scrollbar-y-rail {
            right: 0!important;
          }
		}
        .btn-save {
          margin-top: 10px;
          position: relative;
          background: #fff;
          border: 1px solid #DC780A;
          width: 240px;
          height: 44px;
          line-height: 44px;
          font-size: 16px;
          color: #DC780A;
          font-weight: normal;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
          -webkit-box-sizing: content-box;
          -moz-box-sizing: content-box;
          box-sizing: content-box;
          padding: 0;  
        }
      }
    }
  }   
}