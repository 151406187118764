@charset "UTF-8";
body{
	background: transparent;
}
.page-lti-setting-uri{
    
    .top-area{
        position: relative;
        height:30px;
        background-color: #82bef0;
        text-align: center;
        .area-btn-close {
            position: absolute;
            top:4px;
            right: 4px;
            background: url(#{$IMG_PATH}icon/icn_close_w.png) left center no-repeat;
            background-size: 15px;
            width: 20px;
            height: 20px;
            float: right;
            cursor:pointer;
        }
        .top-title{
            line-height: 30px;
            color: #fff;
            font-size: large;
        }
        &.header-no-color{
             background-color: #fff;
             .btn-close-modal,.area-btn-close {
                 background: url(#{$IMG_PATH}icon/icn_close_k.png) left center no-repeat;
                 background-size: 15px;
             }
             .top-title{
                line-height: 30px;
                color: #82bef0;
                font-size: large;
            }
        }
    }
    .top-area2{
        padding: 0 20px;
        position: relative;
        height:40px;
        background-color: #fff;
        p{
            font-size: 23px;
            line-height: 40px;
            border-bottom: 2px solid #a29e9d;
            color: #333;
        }
    }
	.page-inner{
		margin-top:78px;
	}

	.back img{
		width:20px;
	}

	.m-form-search{
		padding-top: 60px;
		padding-bottom: 0px;
	}

	.inner{
		border-bottom: none !important;
	}

	.text-align-center{
		text-align: center;
	}

	.m-form-search{
        padding: 0;
            .error {
                margin: 1px 0 1px 0;
            }
        .form-group {
            .inner {
                padding-bottom:0;
                width: 80%;
                tr {
                    height: 80px;
                    input{
                        height: 30px;
                    }
                }
                tr:last-child{
                    height: 50px;
                    input{
                        height: 39px;
                    }
                }

                .td-mid{
                    width:50%;
                    vertical-align: top;
                }
                .label-left{
                    float: left;
                    margin-right: 10px;
                    .search-sub-ttl{
                        margin-top: 5px;
                    }
                }
                .input-left{
                    float: left;
                    width:70%;
                }

                .label-right{
                    float: right;
                    margin-right: 10px;
                    .search-sub-ttl{
                        margin-top: 5px;
                    }
                }
                .input-right{
                    float: right;
                    width:70%;
                }

                .td-5 {
                    text-align: center;
                }
                .form-row{
                    .layout{
                        margin-left: 0px;
                    }

                    .layout>*{
                        width: 100% !important; 
                        margin-bottom: 0;
                    }
                }
            }
        }

		.ps-scrollbar-y-rail{
			right: 0px;
		}

	}

	.info{

		.result-label{
			padding-bottom: 3px;
			input{
				float: right;
			}
		}
		.scroll-area{
			height: 347px !important;
			background: white;
		}

		.message-no-data{
			color: #AFAFAF;
		}

		

	}

	#result{
		padding:20px 20px 0 20px;
	}

	.result-inner{
		padding-bottom: 15px !important;
	}	
	
	.btn-submit{
		text-align: center;
	}

	.content-result {
        position: relative;
        background:#D1EAF9 !important;
        height: 405px;
        @include search_table_f;
        .table-header{
            .col-lesson-name{
                min-width: 150px;
            }
            .col-chapter-name{
                min-width: 150px;
            }
            .col-content-type{
                min-width: 100px;
            }
            .col-url{
                min-width: 150px;
            }
            tr{
                position: fixed;
                th{
                    padding: 5px;
                    box-sizing:border-box;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                }
            }
            &.table-header-ltiv1-3{
              .col-lesson-name{
                  min-width: 100px;
              }
              .col-chapter-name{
                  min-width: 100px;
              }
              .col-content-type{
                  min-width: 100px;
              }
              .col-url{
                  min-width: 150px;
              }
              .col-tool-endpoint{
                  min-width: 150px;
              }
              .col-tool-oidc{
                  min-width: 150px;
              }
            }
        }
        .table-result{
            .col-lesson-name{
                width: 25%;
                word-break: normal;
            }
            .col-chapter-name{
                width: 25%;
                word-break: normal;
            }
            .col-content-type{
                width: 15%;
            }
            .col-url{
                width: 30%;
            }
            th, td{
            padding: 4px;
            }
            .link-td-last{
                float: left;
                width: 60%;
                text-align: left;
                line-height: 20px;
                a{
                    display: block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    //color: #82BEF0
                    text-decoration: none;
                    color:#000;
                    margin-top: 6px;
                }
            }
            tr{
                line-height: 35px;
                .hover, &.selected{
                    .link-lti{
                        color: #fff;
                    }
                }
                td{
                    &.disabled{
                        color: rgba(0,0,0,0.3);
                    }
                }
            }
            tr{
                .hover{
                     &.disabled{
                        color: rgba(255,255,255,0.3);
                    }
                }
                &.selected{
                     .disabled{
                        color: rgba(255,255,255,0.3)
                    }
                }
            }
            .btn-td-last{
                float: right;
                min-width: 25%;
                padding-right: 7px;
                .btn-select {
                    float: right;
                    padding: 0 10px;
                    background-size: 20px;
                    background-color: #FFFFFF;
                    text-align: center;
                    -moz-border-radius: 0px;
                    border-radius: 2px;
                    border: 1px solid #A0A0A0;
                    color: #6E6E6E;
                    font-weight: normal;
                    line-height: 26px;
                }
            }
            &.table-result-ltiv1-3{
              .col-lesson-name{
                  width: 10.5%;
              }
              .col-chapter-name{
                  width: 13%;
              }
              .col-content-type{
                  width: 10.5%;
              }
              .col-url{
                  width: 22%;
              } 
              .col-tool-endpoint{
                  width: 22%;
              } 
              .col-tool-oidc{
                  width: 22%;
              } 
            }
        }
    }
    .lti-footer{
        position: absolute;
        width: calc( 100% - 40px );
        bottom: 0;
        padding-bottom: 10px;
        .btn-copy-clipboard{
            padding: 0 15px;
            float: right;
            max-width: 20%;
            background-size: 20px;
            background-color: #FFFFFF;
            text-align: center;
            -moz-border-radius: 0px;
            border-radius: 2px;
            border: 1px solid #A0A0A0;
            color: #6E6E6E;
            font-weight: normal;
            line-height: 30px;
        }
    }

	.btn-submit-search-new{
		background: url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
		-webkit-background-size: 20px;
		background-size: 20px;
		background-position-x: 50%;
		color: #81BDEF;
		border: 1px solid;
		background-color: #81BDEF;
		font-weight: normal;
		width: 152px;
		height: 36px;
		line-height: 36px;
		text-align: center;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 5px;
		font-size: 16px;
		padding: 0
	}



	



	// @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	// 	.info .scroll-area{
	// 		height: 351px !important;
	// 	}	
	// }


	//@media screen and (max-width : 1366px) {
        @media screen and (max-height : 768px) {
            &.page-material-selection {
             width:100%;
             height:300px;
             }
			.content-result{
				padding-top: 2px;
				height: 365px;
			}
			.form-btns>div{
				margin: 3px 0;
			}
			.area-btn-close{
			    padding: 0px 4px;
    			font-size: 13px;
			}
			.info .scroll-area{
				height: 307px !important;
			}
			.page-inner{
				margin-top: 2px;
			}
        }
    //}

	// @media screen and (max-width : 1366px) and (-ms-high-contrast: none), (-ms-high-contrast: active){
    //     @media screen and (max-height : 768px) and (-ms-high-contrast: none), (-ms-high-contrast: active){
	// 		.info .scroll-area{
	// 			height: 345px !important;
	// 		}
    //     }
    // }
    .footer-area{
        background: #fff;
        width: 100%;
        height: 30px;
        padding: 10px 0;
        text-align: center;
        .btn-modal-back{
            padding: 0 15px;
            width: 10%;
            background-size: 20px;
            background-color: #FFFFFF;
            text-align: center;
            -moz-border-radius: 0px;
            border-radius: 2px;
            border: 1px solid #A0A0A0;
            color: #6E6E6E;
            font-weight: normal;
            line-height: 30px;
        }
    }

}
