@charset "UTF-8";

/****************************************************************
 ----------------------------------------------------------------

 ポータル作成アプリケーション

 ----------------------------------------------------------------
 ****************************************************************/

.page-page {
  .ps-scrollbar-y-rail {
    right: 0!important;
  }
  .page-contents {
    height: 100%;
  }

  .error-text {
    color: red;

    &.has-margin {
      margin: 5px 0;
    }
  }
}

// modal
.portal-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  z-index: 100;

  &-inner {
    position: absolute;
    width: 420px;
    background: #fff;
    left: 50%;
    margin-left: -210px;
    top: 20%;
  }

  &-header {
    padding: 25px 15px 5px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  &-content {
    text-align: center;
    padding: 15px 20px 0 20px;
  }

  &-footer {
    padding: 30px;
    .ui-btns {
      overflow: visible;
      margin: 0;
    }
  }

  &.portal-modal-error {
    .portal-modal-header {
      color: red;
    }
  }

  .btn-modal-cancel {
    margin-top: 15px;
    height: 32px;
    line-height: 32px;
    font-weight: normal;
    font-size: 16px;
    width: 40%;
    background: none;
    border: #6E6E6E solid 1px;
    color: #6E6E6E;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    padding: 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }

  .btn-modal-ok {
    width: 40%;
    height: 32px;
    line-height: 32px;
    font-weight: normal;
    font-size: 16px;
    background: #fff;
    border: 1px solid #ee7800;
    color: #ee7800;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    padding: 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }

  .btn-modal-close {
    width: 40%;
    margin-top: 15px;
    height: 32px;
    line-height: 32px;
    font-weight: normal;
    font-size: 16px;
    background: none;
    border: #6E6E6E solid 1px;
    color: #6E6E6E;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    padding: 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }

}

#portal-maker-app {
  position: relative;
  min-height: 100%;

  /*
    base
  --------------------------------------------------------------- */

  // layout
  .portal-layout {
    padding-left: 181px;

    .side {
      left: 1px;
      top: 0;
      position: absolute;
      height: 100%;
      width: 180px;
      background: #77b6ee;
      z-index: 11;
    }

    .main {
      padding: 10px 25px 10px 10px;
    }

    .layout {
      overflow: hidden;
      &:after {
        display: none;
      }
      .col-6:only-child {
        width: 100%;
      }
    }
  }

  // portal-nav
  .portal-nav {
    li {
      border-bottom: 1px solid #fff;

      .is-use-portal {
        position: relative;
        padding-left: 32px;
        text-align: left;
        line-height: 1.2;
        .pseudo-checkbox {
          position: absolute;
          left: 5px;
          top: 50%;
          margin-top: -10px;
        }
      }
      
      &:nth-child(2n){
        background: #58a0ef;
      }

      a, label {
        @include HoverOpacity();
        display: block;
        text-align: center;
        padding: 10px 0;
        color: #fff;
      }

      .disabled {
        background: #ddd;
        color: #ccc;
      }
      .error {
        background: #ff6565;
      }

      .cancel {
        position: relative;
        cursor: default;
        &:after {
          @include PseudoElements();
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, .3);
        }

        &:hover {
          opacity: 1;
        }
      }

    }
    .checkbox-group.btn-disabled {
      cursor: default;
      pointer-events: none;
      opacity: .3;
    }
  }

  .no-edit-permission {
    background: #fff;
    z-index: 1;
    padding: 10px 20px;
    text-align: center;
    border: 2px solid #333;
    margin: 10px;

    p {
      font-size: 16px;
    }

    span {
      font-size: 13px;
    }
  }

  // no-use-display
  .no-use-display {
    z-index: 10;
    position: absolute;
    text-align: center;
    left: 0;
    top: 0;
    padding-top: 200px;
    width: 100%;
    height: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: rgba(255, 255, 255, .8);
    p {
      padding-left: 180px;
    }
    display: none;
  }

  // portal-loading
  .portal-loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(255, 255, 255, .8);

    .loader,
    .loader:after {
      border-radius: 50%;
      width: 10em;
      height: 10em;
    }
    .loader {
      font-size: 10px;
      display: block;
      position: absolute;
      margin: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      text-indent: -9999em;
      border-top: 1.1em solid rgba(0, 0, 0, 0.2);
      border-right: 1.1em solid rgba(0, 0, 0, 0.2);
      border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
      border-left: 1.1em solid #000;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: load8 1.1s infinite linear;
      animation: load8 1.1s infinite linear;
    }
    @-webkit-keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }

  /*
    common
  --------------------------------------------------------------- */

  // portal-content-block
  .portal-content-block {
    position: relative;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  // trigger-close
  .trigger-close {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #666;
    display: inline-block;
    background-image: url(#{$IMG_PATH}icon/icn_close_k.png);
    background-position: right center;
    background-repeat: no-repeat;
    -webkit-background-size: 12px 12px;
    background-size: 12px 12px;
    padding-right: 18px;
  }

  // m-form over write
  .m-form {
    left: 0;
    width: auto;
  }

  // sel-txt over write
  .sel-txt {
    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap!important;
      padding-right: 25px;
    }
  }

  @mixin frame_common {
    @include PseudoElements();
    position: absolute;
    width: 79px;
    height: 79px;
  }

  @mixin frame_common_m {
    @include PseudoElements();
    position: absolute;
    width: 55px;
    height: 55px;
    -webkit-background-size: 55px auto;
    background-size: 55px auto;
  }

  @mixin frame_common_s {
    @include PseudoElements();
    position: absolute;
    width: 40px;
    height: 40px;
    -webkit-background-size: 40px auto;
    background-size: 40px auto;
  }

  // upload-area
  .upload-area {
    position: relative;
    border: 2px dashed #d9d9d9;
    background: #f6f6f6;
    color: #ccc;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;

    &.dragenter {
      -webkit-box-shadow: 0 0 4px 3px rgba(132, 132, 132, 0.3);
      -moz-box-shadow: 0 0 4px 3px rgba(132, 132, 132, 0.3);
      box-shadow: 0 0 4px 3px rgba(132, 132, 132, 0.3);
    }

    p {
      &:before {
        position: absolute;
        @include PseudoElements();
        width: 110px;
        height: 113px;
        top: 50%;
        margin-top: -55px;
        display: inline-block;
        background: url(#{$IMG_PATH}icon/drop_icn_jpg.png) left top no-repeat;
        -webkit-background-size: 100% auto;
        background-size: 100% auto;
      }
    }

    &:before {
      @include frame_common;
      left: -5px;
      top: -5px;
      background-image: url(#{$IMG_PATH}icon/drop_frame_left_top.png);
    }

    &:after {
      @include frame_common;
      right: -5px;
      top: -5px;
      background-image: url(#{$IMG_PATH}icon/drop_frame_right_top.png);
    }

    .upload-area-inner {

      &:before {
        @include frame_common;
        left: -5px;
        bottom: -5px;
        background-image: url(#{$IMG_PATH}icon/drop_frame_left_bottom.png);
      }
      &:after {
        @include frame_common;
        right: -5px;
        bottom: -5px;
        background-image: url(#{$IMG_PATH}icon/drop_frame_right_bottom.png);
      }
    }

    &.-size-s {
      &:before {
        @include frame_common_s;
        left: -3px;
        top: -3px;
        background-image: url(#{$IMG_PATH}icon/drop_frame_left_top.png);
      }

      &:after {
        @include frame_common_s;
        right: -3px;
        top: -3px;
        background-image: url(#{$IMG_PATH}icon/drop_frame_right_top.png);
      }

      .upload-area-inner {

        &:before {
          @include frame_common_s;
          left: -3px;
          bottom: -3px;
          background-image: url(#{$IMG_PATH}icon/drop_frame_left_bottom.png);
        }
        &:after {
          @include frame_common_s;
          right: -3px;
          bottom: -3px;
          background-image: url(#{$IMG_PATH}icon/drop_frame_right_bottom.png);
        }
      }

    }

    &.-size-m {
      &:before {
        @include frame_common_m;
        left: -3px;
        top: -3px;
        background-image: url(#{$IMG_PATH}icon/drop_frame_left_top.png);
      }

      &:after {
        @include frame_common_m;
        right: -3px;
        top: -3px;
        background-image: url(#{$IMG_PATH}icon/drop_frame_right_top.png);
      }

      .upload-area-inner {

        &:before {
          @include frame_common_m;
          left: -3px;
          bottom: -3px;
          background-image: url(#{$IMG_PATH}icon/drop_frame_left_bottom.png);
        }
        &:after {
          @include frame_common_m;
          right: -3px;
          bottom: -3px;
          background-image: url(#{$IMG_PATH}icon/drop_frame_right_bottom.png);
        }
      }

    }

    .preview-img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      z-index: 1;
    }

  }

  /*
    blocks
  --------------------------------------------------------------- */

  // portal-sitebnr
  .portal-sitebnr {
    .upload-area {
      width: 320px;
      height: 200px;
      p {
        padding-top: 78px;
        padding-left: 140px;
        &:before {
          left: 15px;
        }
      }
    }
    .layout-table {
      & > div {
        vertical-align: middle;
      }
      .upload-outer {
        width: 320px;
      }
      .meta {
        padding-left: 40px;
      }
    }
  }

  // portal-main-visual
  .portal-main-visual {
    .upload-area {
      width: 550px;
      height: 270px;
      p {
        padding-top: 116px;
        padding-left: 250px;
        &:before {
          left: 125px;
        }
      }
    }
    .layout-table {
      & > div {
        vertical-align: middle;
      }
      .upload-outer {
        width: 550px;
      }
      .meta {
        padding-left: 20px;
        .util-mb5 {
          margin-bottom: 5px;
        }

        ul li {
          margin-bottom: 10px;
        }

      }
    }
  }

  // portal-menu-setting
  .portal-menu-setting {

    .upload-area {
      width: 100%;
      height: 120px;
      text-align: center;
      margin-bottom: 10px;
      margin-left: -2px;

      p {
        padding-top: 35px;
        display: inline-block;
        text-align: left;
        &:before {
          display: none;
        }
      }

      .preview-img {
        width: 50px;
        height: 50px;
        top: 50%;
        left: 50%;
        margin-left: -25px;
        margin-top: -25px;
        background-size: cover;
      }

    }

    .m-form p {
      margin-bottom: 5px;
    }

    .m-form .radio-group {
      margin: 0;
      display: block;
    }

    .m-form .radio-group .pseudo-radio {
      margin-right: 5px;
    }

    .menu-list {
      margin-top: 10px;
      overflow: hidden;
      margin-left: -10px;

      &:first-of-type {
        margin-top: 30px;
      }

      li {
        width: 20%;
        float: left;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-left: 10px;
      }
    }

    .ui-droppable-hover .menu-item:after {
      opacity: 1;
      z-index: 1;
    }

    .menu-item {
      position: relative;
      background: #fff;

      .error-text {
        font-size: 10px;
      }

      &:after {
        content: "入れ替える";
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        padding-top: 80px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: rgba(118, 184, 230, 0.5);
        opacity: 0;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
      }

      .m-form {
        font-size: 12px;

        .input-text {
          padding-top: 3px;
          padding-bottom: 3px;
        }
      }

      &-header {
        position: relative;
        background: #eee;
        padding: 0 5px 5px;

        .draggable-elem {
          cursor: move;
          background: #e3e3e3;
          height: 12px;
          margin-left: -5px;
          margin-right: -5px;
          margin-bottom: 5px;
          text-align: center;
          position: relative;

          .bar {
            position: absolute;
            display: block;
            width: 24px;
            height: 1px;
            background: #ccc;
            left: 50%;
            margin-left: -12px;

            &-top {
              top: 4px;
            }
            &-btm {
              top: 7px;
            }
          }
        }

        p {
          line-height: 18px;
        }

        .trigger-clear {
          @include HoverOpacity();
          position: absolute;
          right: 5px;
          top: 15px;
          display: block;
          width: 18px;
          height: 18px;
          background: url(#{$IMG_PATH}icon/icn_formclear_k.png) left top no-repeat;
          -webkit-background-size: 100% auto;
          background-size: 100% auto;
        }
      }

      &-content {
        padding: 10px;
        border: 1px solid #eee;
        border-width: 0 1px 1px 1px;
      }

    }

  }

  // portal-schedule
  .portal-schedule {
    padding: 0 10px 0 0;
    border: none;
    .trigger-close {
      top: 5px;
    }
    &.col-12 {
      padding: 0;
    }
    .schedule-area {
      .heading {
        a {
          display: block;
          color: #3eb3e1;
          font-size: 18px;
          cursor: default;
          margin-bottom: 5px;
          text-decoration: none!important;
          span {
            padding-left: 22px;
            display: inline-block;
            background: url(#{$IMG_PATH}icon/icn_schedule_b.png) left center no-repeat;
            -webkit-background-size: 1em;
            background-size: 1em;
            background-position-y: 3px;
          }
        }
      }
      .time-schedule {
        display: none;
        background-color: #fff;
        margin-right: 104px;
        padding: 5px;
        .time {
          font-weight: bold;
          padding: 10px 0px 0px 10px;
          span {
            color: #717171;
          }
        }
        .detail {
          display: table;
          padding: 5px 10px 0px 10px;
          width: 95%;
          // div:first-child {
          //   vertical-align: middle;
          //   width: 70px;
          //   display: table-cell;
          // }
          div {
            vertical-align: middle;
            display: table-cell;
            font-weight: bold;
            text-align: center;
            line-height: 50px;
            span {
              color: #666465;
              font-size: 12px;
            }
            img+span {
              color: #4ea3d4;
              font-size: 30px;
            }
            .detail-1{
              display: inline-block;

            }
            .detail-2{
              display: inline-block;

            }
            .detail-3{
              display: inline-block;

            }
            .detail-4{
              display: inline-block;

            }
            .detail-1, .detail-2, .detail-3, .detail-4{
              img, span{
                display: inline;
              }
              img{
                width: 50px;
                opacity: 0.3;
                margin-right: 10px;
              }
            }
            @include ResponsiveMaxWidth (1150) {
              .detail-1, .detail-2, .detail-3, .detail-4{
                img{
                  width: 35px;
                  vertical-align: -7px;
                }
              }
              img+span {
                color: #4ea3d4;
                font-size: 20px;
              }
            }
          }
        }
        @include ResponsiveMinWidth(1300) {
          .detail {
            &>div {

              span:first-child {
                font-size: 35px;
              }
            }
          }
        }
        &.current {
          display: inherit;
          min-height: 154px;
          border: $border_color_default solid 1px;
        }
      }
      .schedule-navbar {

        float: right;
        width: 101px;
        li {
          background-color: #fff;
          margin-bottom: 4px;
          line-height: 22px;
          a {
            display: block;
            padding: 3px 13px;
            color: #b4b5b5;
            text-align: center;
            &.notice{
              color: #e58a7f;
            }
          }
          .tab-schedule-link {
            border: $border_color_default solid 1px;
          }
          .current {
            border-left: none;
            font-weight: bold;
            color: #3d3a39;
          }
        }
        .margin-left {
          margin-left: -4px;
        }
        a {
          text-decoration: none!important;
        }
      } // .schedule-navbar
    } // .schedule-area

    .touch-area.only-today {
      .time-schedule {
        margin-right: 0;
      }
    }
  }

  // portal-information (from lp information)
  .portal-information {
    padding: 0;
    border: none;

    .col-6 {
      position: relative;
    }

    .trigger-close {
      color: #fff;
      background-image: url(#{$IMG_PATH}icon/icn_close_w.png);
      z-index: 1;
    }

    .img-delete-btn {
      width: 30px;
      height: 30px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      display: block;
      background: rgba(0, 0, 0, .6) url(#{$IMG_PATH}icon/icn_close_w.png) center center no-repeat;
      -webkit-background-size: 12px;
      background-size: 12px;
      position: absolute;
      right: 10px;
      top: 5px;
      z-index: 2;
      -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);

      &:hover {
        opacity: .7;
      }
    }

    .information-img {
      padding-right: 10px;

      .upload-area {
        width: 100%;
        height: 166px;
        text-align: center;

        p {
          margin-top: 20px;
          position: relative;
          padding-top: 35px;
          padding-bottom: 35px;
          padding-left: 120px;
          display: inline-block;
          text-align: left;
          &:before {
            left: 0;
          }
        }

        .preview-img {
          width: 100%;
          height: 166px;
          -webkit-background-size: cover;
          background-size: cover;
        }
      }

    }

    .information-area {
      background: #81c0e9;
      padding: 10px 0 10px 10px;
      margin-bottom: 5px;

      &.no-bg {
        padding: 0 0 0 5px;
        background: none;
      }
      .information-area-inner {
        background: #81c0e9;
        padding: 10px 0 10px 10px;
      }

      .heading {
        position: relative;
        padding-right: 120px;
        margin-bottom: 5px;
        .information-tab {
          position: absolute;
          font-size: 0;
          letter-spacing: -.4em;
          display: inline-block;
          vertical-align: middle;
          top: 50%;
          margin-top: -17px;
          margin-left: 10px;
          white-space: unset !important;
        }

        a {
          color: #333;
          font-size: 12px;
          letter-spacing: normal;
          display: inline-block;
          width: 70px;
          text-align: center;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          margin-right: 3px;
          padding: 5px 0;
          background: #fff;

          &:hover {
            opacity: .7;
            text-decoration: none;
          }

          &.on {
            background: #3eb3e1;
            border: 1px solid #3eb3e1;
            color: #fff;
          }
          &:last-child {
            margin-right: 0;
          }
        }
        .heading-text {
          display: inline-block;
          padding-left: 22px;
          background: url(#{$IMG_PATH}icon/icn_infomation_w.png) left center no-repeat;
          -webkit-background-size: 18px;
          background-size: 18px;
          background-position: 0% 30%;
          color: #fff;
          font-size: 18px;
          cursor: default;
          margin-bottom: 5px;
        }
      }

      .information-for-content {
        display: none;
        &.on {
          display: block;
        }
      }

      .number-info {
        font-size: 30px;
        font-weight: bold;
        color: #4aa7d8;
      }
      .new-info {
        float: right;
        margin-right: 9px;
        color: #949394;
        .infomation {
          background: #fff;
          text-align: center;
          margin-bottom: 4px;
          font-size: 16px;
          position: relative;
          padding: 9px 0px 9px 0px;
        }
        .new {
          padding-right: 10px;
          display: inline-block;
          width: 50px;
          height: 22px;
          background: url(#{$IMG_PATH}icon/icn_new_k.png) center center no-repeat;
          -webkit-background-size: 50px;
          background-size: 50px;
          opacity: .3;
        }
      }
      .star,
      .bell {
        padding-right: 10px;
        display: inline-block;
        width: 20px;
        height: 20px;
        cursor: pointer;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
        opacity: .2;
        @include HoverOpacityTrans(.3s, .1);
        &.on {
          background-size: contain;
          opacity: 1;
          @include HoverOpacityTrans(.3s, .7);
        }
      }
      .bell {
        background: url(#{$IMG_PATH}icon/icn_bell_k.png) center center no-repeat;
        -webkit-background-size: contain;
        background-size: contain;
        &.on {
          background: url(#{$IMG_PATH}icon/icn_bell_o.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }
      }
      .star {
        background: url(#{$IMG_PATH}icon/icn_star_k.png) center center no-repeat;
        -webkit-background-size: contain;
        background-size: contain;
        @include HoverOpacityTrans(.3s, .1);
        &.on {
          background: url(#{$IMG_PATH}icon/icn_star_o.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
          @include HoverOpacityTrans(.3s, .7);
        }
      }
      .remind {
        width: 49%;
        float: right;
        background: #fff;
        text-align: center;
        padding-top: 4px;
        padding-bottom: 4px;
      }
      .important {
        width: 49%;
        background: #fff;
        text-align: center;
        padding-top: 4px;
        padding-bottom: 4px;
      }
      .remind,
      .important {
        .number-info {
          font-size: 26px;
        }
      }
      .detail-infomation {
        height: 115px;
        .detail-infomation-inner {
          padding-right: 13px;
          a {
            padding: 3px 10px 2px 0px;
            position: relative;
            text-align: right;
            line-height: 20px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            font-size: 14px;
            @include HoverOpacityTrans();
            background: #fff;
            color: #81c0e9;
            display: block;
            .all-info-icon {
              padding-right: 20px;
              width: 25px;
              height: 25px;
              background: url(#{$IMG_PATH}icon/icn_infomation_all_b.png) center center no-repeat;
              -webkit-background-size: 15px;
              background-size: 15px;
              background-position-y: 2px;
            }
          }
          ul {
            li {
              background: #fff;
              margin-bottom: 3px;
              font-size: 12px;
              position: relative;
              padding: 3px 5px 4px 30px;
              @include sts_notice() {
                left: 10px;
                top: 50%;
                margin-top: -5px;
              }
              p {
                @include LayoutTable();
                width: 80%;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                span {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: 13px;
                  @include LayoutCell(middle);
                }
                .date {
                  width: 80px;
                  padding-right: 8px;
                  font-size: 11px;
                }
              }
              .star,
              .bell {
                width: 18px;
                height: 18px;
                top: 15px;
                position: absolute;
              }
              .star {
                right: 30px;
              }
              .bell {
                right: 5px;
              }
            } // li
          } // ul
        } // .detail-infomation-inner
      } // .detail-infomation-area

      .scroll-area.ps-active-y {
        .scroll-area-inner {
          padding-right: 25px;
        }
      }

      .scroll-area {
        height: 115px;

        .scroll-area-inner {
          padding-right: 10px;

          ul {
            li {
              background: #fff;
              margin-bottom: 3px;
              font-size: 12px;
              position: relative;
              padding: 5px 5px 5px 30px;

              @include sts_notice(){
                left: 10px;
                top: 50%;
                margin-top: -5px;
              }

              p {
                @include LayoutTable();
                padding-right: 80px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;

                span {
                  @include LayoutCell(middle);
                }
                .date {
                  width: 80px;
                  padding-right: 8px;
                }
              }

              .star, .bell {
                position: absolute;
                top: 4px;
                display: block;
                width: 20px;
                height: 20px;
                cursor: pointer;
                -webkit-transition: all .3s;
                -moz-transition: all .3s;
                -ms-transition: all .3s;
                -o-transition: all .3s;
                transition: all .3s;
                opacity: .2;
                @include HoverOpacityTrans(.3s, .1);
                &.on {
                  background-size: contain;
                  opacity: 1;
                  @include HoverOpacityTrans(.3s, .7);
                }
              }

              .star {
                right: 50px;
                background: url(#{$IMG_PATH}icon/icn_star_k.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
                @include HoverOpacityTrans(.3s, .1);
                &.on {
                  background: url(#{$IMG_PATH}icon/icn_star_o.png) center center no-repeat;
                  -webkit-background-size: contain;
                  background-size: contain;
                  @include HoverOpacityTrans(.3s, .7);
                }
              }

              .bell {
                right: 15px;
                background: url(#{$IMG_PATH}icon/icn_bell_k.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
                &.on {
                  background: url(#{$IMG_PATH}icon/icn_bell_o.png) center center no-repeat;
                  -webkit-background-size: contain;
                  background-size: contain;
                }
              }

            } // li
          } // ul
        } // .scroll-area-inner

      } // .scroll-area
    } // .information-area
  }

  .portal-memo {
    padding: 0 5px 0 0;
    margin-bottom: 0;
    border: none;

    .portal-memo-inner {
      background: #81c0e9;
      padding: 10px;
    }
    .trigger-close {
      top: 10px;
      right: 15px;
      color: #fff;
      background-image: url(#{$IMG_PATH}icon/icn_close_w.png);
    }

    &.col-12 {
      padding-right: 10px;
    }

    .memo-area {
      background: #fff;
      height: 166px!important;
      overflow-y: auto;
      &-inner {
        padding: 10px;
      }
      .ps-scrollbar-y-rail {
        right: 0!important;
      }
    }
    .schedule-area {
      .heading {
        margin-top: 0;
        padding-bottom: 0;
        margin-bottom: 5px;

        .heading-text {
          display: inline-block;
          padding-left: 22px;
          background: url(#{$IMG_PATH}icon/icn_infomation_w.png) left center no-repeat;
          -webkit-background-size: 18px;
          background-size: 18px;
          background-position: 0% 30%;
          color: #fff;
          font-size: 18px;
          cursor: default;
          margin-bottom: 5px;
        }
        a {
          display: block;
          color: #3eb3e1;
          font-size: 18px;
          cursor: default;
          margin-bottom: 5px;
          text-decoration: none!important;
          span {
            padding-left: 22px;
            display: inline-block;
            background: url(#{$IMG_PATH}icon/icn_schedule_b.png) left center no-repeat;
            -webkit-background-size: 1em;
            background-size: 1em;
            background-position-y: 3px;
          }
        }
      }
    } // .schedule-area
  }

  .portal-course-group {

    .course-group-edit {
      margin-top: 30px;
    }

    .btn-add-course-group {
      position: relative;
      padding: 0 40px 0 10px;
      height: 38px;
      line-height: 38px;
      font-size: 14px;
      color: #37bef0;
      border: 1px solid #37bef0;
      font-weight: normal;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      text-align: center;
      &:after {
        content: " ";
        display: block;
        position: absolute;
        width: 25px;
        height: 25px;
        background: url(#{$IMG_PATH}icon/icn_imageupload_b.png) left top no-repeat;
        -webkit-background-size: contain;
        background-size: contain;
        right: 10px;
        top: 50%;
        margin-top: -13px;
      }
    }

    .ui-droppable-hover .course-group-item:after {
      opacity: 1;
      z-index: 1;
    }

    .trigger-close {
      z-index: 1;
    }

    .course-group-item {
      background: #fff;
      position: relative;
      padding: 5px 10px 5px 20px;
      border: 1px solid #ccc;
      margin-bottom: 5px;

      &.has-error {
        .des {
          color: red;
        }
      }

      &:after {
        content: "入れ替える";
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: rgba(118, 184, 230, 0.5);
        opacity: 0;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .draggable-elem {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 11px;
        background: #e3e3e3;
        cursor: move;

        .bar {
          position: absolute;
          display: block;
          width: 1px;
          height: 24px;
          background: #ccc;
          top: 50%;
          margin-top: -12px;

          &-left {
            left: 3px;
          }

          &-right {
            left: 6px;
          }
        }
      }

    }

    .trigger-delete {
      @include HoverOpacity();
      display: block;
      position: absolute;
      width: 25px;
      height: 25px;
      background: url(#{$IMG_PATH}icon/icn_trash_k.png) left top no-repeat;
      -webkit-background-size: 100% auto;
      background-size: 100% auto;
      right: 5px;
      top: 50%;
      margin-top: -13px;
    }

  }

  .portal-my-courses {
    background: $background_theme_color_contents;
    border: none;
    padding: 0;

    .m-page-heading-area {
      padding: 10px;

      .page-heading {
        float: left;
        margin-right: 15px;
        font-size: 18px;
        color: #3eb3e1;
        font-weight: normal;

        a {
          color: #3eb3e1;
        }
        span {
          display: inline-block;
          padding-left: 20px;
          background: url(#{$IMG_PATH}icon/icn_my_courses_b.png) no-repeat;
          background-position-y: 4px;
          -webkit-background-size: 15px;
          background-size: 15px;
          cursor: default;
        }
      }
      .thumbnail {
        padding-right: 10px;
        display: inline-block;
        width: 25px;
        height: 25px;
        background: url(#{$IMG_PATH}icon/icn_download_b.png) center center no-repeat;
        -webkit-background-size: contain;
        background-size: contain;
        vertical-align: middle;
      }
      .list {
        padding-right: 10px;
        display: inline-block;
        width: 25px;
        height: 25px;
        background: url(#{$IMG_PATH}icon/icn_download_b.png) center center no-repeat;
        -webkit-background-size: contain;
        background-size: contain;
        vertical-align: middle;
      }
      .display-list {
        float: right;
        input[type="radio"] {
          display: none;
        }

        label {
          display: inline-block;
          vertical-align: middle;
        }
        input[id="display-large"]:checked+label {
          background: url(#{$IMG_PATH}icon/icn_course_menu_b.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
          opacity: 1;
        }
        input[id="display-small"]:checked+label {
          background: url(#{$IMG_PATH}icon/icn_detail_b.png) center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
          opacity: 1;
        }
        input[id="display-large"]+label{
          background: url(#{$IMG_PATH}icon/icn_course_menu_k.png) center center no-repeat;
          width: 25px;
          height: 25px;
          -webkit-background-size: contain;
          background-size: contain;
          opacity: .5;
          padding-right: 15px;
        }
        input[id="display-small"]+label{
          background: url(#{$IMG_PATH}icon/icn_detail_k.png) center center no-repeat;
          width: 25px;
          height: 25px;
          -webkit-background-size: contain;
          background-size: contain;
          opacity: .5;
        }


      }

    } // .sort

    .page-inner {
      .page-contents {
        width: 100%;
        padding: 10px 10px 0;
        margin: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      } // .test

      @media screen and (max-width : 1366px) {

        @media screen and (max-height : 768px) {
          .page-contents {
            padding: 7px 10px 0;
          }
        }}
    } // .page-inner
  }

}

.ui-draggable-dragging {
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 10;
}


.portal-schedule-and-information--memo {
  position: relative;
  margin-bottom: 10px;
  .portal-information .information-area {
    margin-bottom: 0!important;
  }
}

#portal-maker-app .portal-information.col-12 .information-area .heading .information-tab {
  left: 50%;
  margin-left: 0;
}
