@charset "UTF-8";
body{
	background: transparent;
}
.page-user-permission-IP-1{
    
    .top-area{
        position: relative;
        height:30px;
        background-color: #82bef0;
        text-align: center;
        .btn-close-modal {
            position: absolute;
            top:4px;
            right: 4px;
            background: url(#{$IMG_PATH}icon/icn_close_w.png) left center no-repeat;
            background-size: 15px;
            width: 20px;
            height: 20px;
            float: right;
            cursor:pointer;
        }
        .top-title{
            line-height: 30px;
            color: #fff;
            font-size: large;
        }
        
        &.header-no-color{
             background-color: #fff;
             .btn-close-modal {
                 background: url(#{$IMG_PATH}icon/icn_close_k.png) left center no-repeat;
                 background-size: 15px;
             }
             .top-title{
                line-height: 30px;
                color: #82bef0;
                font-size: large;
            }
        }
    }
    .top-area2{
        padding: 0 20px;
        position: relative;
        height:40px;
        background-color: #fff;
        p{
            font-size: 23px;
            line-height: 40px;
            border-bottom: 2px solid #a29e9d;
            color: #333;
        }
    }
	.page-inner{
		margin-top:78px;
	}

	.back img{
		width:20px;
	}

	.m-form-search{
		padding-top: 60px;
		padding-bottom: 0px;
	}

	.inner{
		border-bottom: none !important;
	}

	.text-align-center{
		text-align: center;
	}

	.m-form-search{
        padding: 0;
        .error {
            margin: 1px 0 1px 0;
        }
        .form-group {
            .inner {
                padding-bottom:0;
                width: 85%;
                tr {
                    height: 70px;
                    input{
                        height: 30px;
                    }
                }
                tr:last-child{
                    height: 45px;
                    input{
                        height: 35px;
                    }
                }
                .td-1 {
                    width: 18%;
                    text-align: right;
                    padding-right: 10px;
                }
                .td-2 {
                    width: 30%;
                    
                }
                .td-3 {
                    text-align: right;
                    width: 18%;
                    padding-right: 10px;
                }
                .td-4 {
                    width: 30%;
                }
                .td-5 {
                    text-align: center;
                }
                .form-row{
                    .layout>*{
                        width: 100% !important; 
                        margin-bottom: 0;
                    }
                }
            }
        }

		.ps-scrollbar-y-rail{
			right: 0px;
		}

	}

	.info{

		.result-label{
			padding-bottom: 3px;
			input{
				float: right;
			}
		}
		.scroll-area{
			height: 327px !important;
			background: white;
		}

		.message-no-data{
			color: #AFAFAF;
		}

		

	}

	#result{
		padding:20px 20px 0 20px;
	}

	.result-inner{
		padding-bottom: 15px !important;
	}	
	
	.btn-submit{
		text-align: center;
	}

	.content-result {
        position: relative;
        background:#D1EAF9 !important;
        height: 355px;
        padding: 10px !important;
        @include search_table_f;
        .table-header{
            .col-user-name-id{
                min-width: 222px;
            }
            .col-group-name-id{
                min-width: 259px;
            }
            .col-authorized-ip{
                min-width: 185px;
            }
            .col-btn{
                min-width: 74px;
            }
            tr{
                position: fixed;
                th{
                    padding: 5px;
                    box-sizing:border-box;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                }
            }
        }
        .table-result{
            .col-user-name-id{
                width: 30%;
            }
            .col-group-name-id{
                width: 35%;
            }
            .col-authorized-ip{
                width: 25%;
            }
            .col-btn{
                width: 10%;
            }
            th, td{
            padding: 4px;
            }
            .link-td-last{
                float: left;
                min-width: 70%;
                text-align: left;
                a{
                    display: block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    color: #82BEF0
                }
            }
            tr{
                .hover, &.selected{
                    .link-lti{
                        color: #fff;
                    }
                    .btn-submit-setting {
                        background: url(#{$IMG_PATH}icon/icn_edit_w.png) 20px center no-repeat;
                        -webkit-background-size: 80%;
                        background-size: 80%;
                        background-position-x: 50% ;
                    }
                }
                td{
                    &.disabled{
                        color: rgba(0,0,0,0.3);
                    }
                }
            }
            tr{
                .hover{
                     &.disabled{
                        color: rgba(255,255,255,0.3);
                    }
                }
                &.selected{
                     .disabled{
                        color: rgba(255,255,255,0.3)
                    }
                }
            }
            .btn-td-last{
                float: right;
                min-width: 25%;
                padding-right: 7px;
                .btn-select {
                    float: right;
                    padding: 0 10px;
                    background-size: 20px;
                    background-color: #FFFFFF;
                    text-align: center;
                    -moz-border-radius: 0px;
                    border-radius: 2px;
                    border: 1px solid #A0A0A0;
                    color: #6E6E6E;
                    font-weight: normal;
                    line-height: 30px;
                }
            }    
        }
        .btn-submit-setting {
            background: url(#{$IMG_PATH}icon/icn_edit_k.png) 20px center no-repeat;
            -webkit-background-size: 80%;
            background-size: 80%;
            background-position-x: 50% ;
            font-weight: normal;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            font-size: 16px;
            padding: 0;
        }
    }
    .lti-footer{
        position: absolute;
        width: calc( 100% - 40px );
        bottom: 0;
        padding-bottom: 10px;
        .btn-copy-clipboard{
            padding: 0 15px;
            float: right;
            max-width: 20%;
            background-size: 20px;
            background-color: #FFFFFF;
            text-align: center;
            -moz-border-radius: 0px;
            border-radius: 2px;
            border: 1px solid #A0A0A0;
            color: #6E6E6E;
            font-weight: normal;
            line-height: 30px;
        }
    }

	.btn-submit-search-new{
		background: url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
		-webkit-background-size: 20px;
		background-size: 20px;
		background-position-x: 50%;
		color: #81BDEF;
		border: 1px solid;
		background-color: #81BDEF;
		font-weight: normal;
		width: 120px;
		height: 36px;
		line-height: 36px;
		text-align: center;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 5px;
		font-size: 16px;
		padding: 0
	}


        @media screen and (max-height : 768px) {
            &.page-material-selection {
             width:100%;
             height:300px;
             }
			.content-result{
				padding-top: 2px;
				height: 267px;
			}
			.form-btns>div{
				margin: 3px 0;
			}
			.btn-close-modal{
			    padding: 0px 4px;
    			font-size: 13px;
			}
			.info .scroll-area{
				height: 243px !important;
			}
			.page-inner{
				margin-top: 20px !important;
			}
            .error {
                font-size: 11px !important;
            }
            .form-group {
                .inner {
                    tr {
                        height: 61px !important;
                        input{
                            height: 25px !important;
                        }
                    }
                    tr:last-child{
                        height: 35px !important;
                        input{
                            height: 30px !important;
                        }
                    }
                }
            }
        
    }
    .footer-area{
        background: #fff;
        width: 100%;
        height: 30px;
        padding: 10px 0;
        text-align: center;
        .btn-modal-back{
            padding: 0 15px;
            width: 10%;
            background-color: #FFFFFF;
            text-align: center;
            -moz-border-radius: 0px;
            border-radius: 2px;
            border: 1px solid #A0A0A0;
            color: #6E6E6E;
            font-weight: normal;
            line-height: 30px;
              font-size: 16px;
        }
    }

}
