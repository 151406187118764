@charset "UTF-8";
.grade-evaluate-container-inner {
  margin: 5px 5px 5px 5px;
  .separator {
    margin: 5px 25px 10px 25px;
    border-bottom: 2px solid $border_color_default;
  }
  .container-header {
    margin-top: 15px;
    .grade-evaluate-header {
      width: 100%;
      height: 10%;
      min-width: 972px;
      line-height: 0px;
      .back {
        @include HoverOpacity();
        left: 0;
        display: inline-block;
        padding-right: 10px;
        img {
          width: 20px;
          height: 20px;
          vertical-align: -webkit-baseline-middle;
        }
      }
      .grade-evaluate-header-course-name {
        color: #82BEF0;
      }
    }
  } // container-header end
  .grade-evaluate-setting {
    width: 100%;
    height: 90%;
    min-width: 972px;
    margin: 5px 20px 0px 20px;
    .upper-wrap {
      height: 10%;
      width: 100%;
      min-height: 30px;
      .grade-evaluate-setting-public {
        color: #FFCC00;
      }
    }
    .category-setting-wrap {
      width: calc(50% - 30px);
      height: 90%;
      display: inline-block;
      padding-right: 10px;
      .category-setting-body {
        border: solid thin #6E6E6E;
        min-height: 400px;
        position: relative;
        .category-setting-table {
          margin: 10px 0px 10px 20px;
          width: 90%;
          table-layout: fixed;
          .table-bottom-border {
            border-bottom: 1px solid #6E6E6E;
          }
        }
        .col-grade-evaluate-category-id {
          width: 0%;
        }
        .col-category-select {
          width: 5%;
        }
        .col-category-name {
          width: 67%;
          .input-category-name {
            border: 1px solid #c8c8c8;
            margin: 1px 4px 4px;
            box-sizing: border-box;
            width: 95%;
          }
          .input-readonly {
            border: none;
            background: none;
          }
        }
        .col-evaluate-rate {
          width: 17%;
          .input-evaluate-rate {
            border: 1px solid #c8c8c8;
            margin: 1px 4px 4px;
            box-sizing: border-box;
            max-width: 50%;
          }
          .input-readonly {
            border: none;
            background: none;
          }
        }
        .col-display-order {
          width: 0%;
        }
        .col-btn-down {
          width: 3%;
        }
        .col-btn-up {
          width: 3%;
        }
        .col-btn-delete {
          width: 5%;
          vertical-align: middle;
        }
        .selected {
          background: #82bef0;
          color: #FFFFFF;
        }
        .btn-up, .btn-down {
          @include HoverOpacity();
          display: block;
          width: 15px;
          height: 15px;
          z-index: 1;
          display: inline-block;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
        }
        .btn-up {
          background-position: center;
          background-repeat: no-repeat;
          background-image: url(#{$IMG_PATH}icon/icn_pullup_k.png);
          background-size: 15px;
        }
        .btn-down {
          background-position: center;
          background-repeat: no-repeat;
          background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
          background-size: 15px;
        }
        .btn-remove {
          @include HoverOpacity();
          position: relative;
          display: block;
          margin-top: 5px;
          margin-left: 5px;
          width: 23px;
          height: 23px;
          z-index: 1;
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          border-radius: 100%;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          border: 2px solid #6E6E6E;
          span {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            &:after, &:before {
              @include PseudoElements();
              position: absolute;
              left: 44%;
              top: 20%;
              display: block;
              width: 2px;
              height: 12px;
              background: #6E6E6E;
            }
            &:before {
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(-45deg);
            }
            &:after {
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
          &.hide {
            display: none;
          }
        }
        .evaluate-rate-total-area {
          width: 90%;
          margin: 10px 0px 0px 25px;
          border-top: 1px solid #6E6E6E;
          position: absolute;
          bottom: 15%;
          .evaluate-rate-total-wrap {
            display: flex;
            position: absolute;
            left: 65%;
            .evaluate-rate-total {
              margin: 0px 10px 0px 100px;
            }
          }
        }
        .btn-add-category-setting {
          right: 0;
          bottom: 0;
          overflow: hidden;
          padding-right: 100px;
          label {
            @include HoverOpacity();
            background: url(#{$IMG_PATH}icon/icn_newplus_bk.png);
            -webkit-background-size: 100% 100%;
            background-size: 100% 100%;
            width: 38px;
            height: 38px;
            display: block;
          }
          .btn-add-category {
            float: right;
          }
        }
        .btn-edit-category-setting {
          position: absolute;
          bottom: 2%;
          left: 35%;
          .btn-edit-category {
            background: none;
            width: 90px;
            height: 30px;
            line-height: 30px;
            color: #6E6E6E;
            border: #6E6E6E solid 1px;
          }
          .btn-setting-category-member {
            background: none;
            width: 90px;
            height: 30px;
            line-height: 30px;
            color: #468CC8;
            border: #468CC8 solid 1px;
          }
        }
      }
    }
    .target-chapter-setting-wrap {
      width: calc(50% - 30px);
      height: 90%;
      display: inline-block;
      padding-left: 10px;
      vertical-align: top;
      .target-chapter-setting-body {
        border: solid thin #6E6E6E;
        min-height: 400px;
        position: relative;

        .target-chapter-setting-separator {
          border-bottom: 1px solid #6E6E6E;
        }
        .target-chapter-setting-table-area {
          @include search_table_f;
          padding: 0px !important; //width: 90%;
          margin: 10px 10px 10px 10px;
          max-height: 350px;
          &.result-tbl {
            @media screen and (max-width: 1366px) {
              @media screen and (max-height: 768px) {
                height: 85%;
              }
            }
            .ps-scrollbar-y-rail {
              right: 0px;
              z-index: 6;
            }
            table {
              &.target-chapter-setting-table {
                margin-top: 3px;
                margin-bottom: 15px;
              }
              thead>tr {
                width: 100%;
                position: fixed;
                z-index: 5;
                .ps-scrollbar-x-rail,
                .ps-scrollbar-y-rail {
                  display: none;
                }
              }
            }
            .header {
              color: #000000;
              
            }
            .target-chapter-setting-table,
            .target-chapter-setting-table-header {
              td, th {
                padding: 5px;
                box-sizing: border-box;
                text-align: left;
                border: none;
              }
              th:hover {
                cursor: pointer;
              }
              label {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 14px !important;
                height: 14px !important;
                &::after {
                  left: calc(50% + 19px);
                  width: 14px !important;
                  height: 14px !important;
                }
                &::before {
                  left: calc(50% + 21px);
                  width: 11px !important;
                  height: 5px !important;
                }
              }
              .col-base {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                -webkit-text-overflow: ellipsis;
                -o-text-overflow: ellipsis;
              }
              .col-lesson-id {
                width: 0%;
              }
              .col-lesson-name {
                @extend .col-base;
                width: 37%;
              }
              .col-chapter-id {
                width: 0%;
              }
              .col-content-icon {
                width: 6%;
                &.icn-content-test {
                  &.hover {
                    background: url(#{$IMG_PATH}icon/icn_test_w.png) right no-repeat;
                    -webkit-background-size: 90%;
                    background-size: 23px;
                  }
                  background: url(#{$IMG_PATH}icon/icn_test_k.png) right no-repeat;
                  -webkit-background-size: 90%;
                  background-size: 23px;
                }
                &.icn-content-debate {
                  &.hover {
                    background: url(#{$IMG_PATH}icon/icn_debate_w.png) right no-repeat;
                    -webkit-background-size: 90%;
                    background-size: 23px;
                  }
                  background: url(#{$IMG_PATH}icon/icn_debate_k.png) right no-repeat;
                  -webkit-background-size: 90%;
                  background-size: 25px;
                }
                &.icn-content-report {
                  &.hover {
                    background: url(#{$IMG_PATH}icon/icn_report_w.png) right no-repeat;
                    -webkit-background-size: 90%;
                    background-size: 23px;
                  }
                  background: url(#{$IMG_PATH}icon/icn_report_k.png) right no-repeat;
                  -webkit-background-size: 90%;
                  background-size: 23px;
                }
              }
              .col-chapter-name {
                @extend .col-base;
                width: 57%;
              }
              
            }
          }
        }
        .target-chapter-setting-table-result-nodata {
          position: relative;
          text-align: center;
          width: 100%;
          color: #6E6E6E;
          margin-top: 170px;
        }
        .no-data {
          min-height: 85%;
          margin: 0 10px;
          background: white;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 0px !important;
          &.hide {
            display: none;
          }
        }
        .btn-edit-category-member-setting {
          position: absolute;
          bottom: 2%;
          left: 35%;
          .btn-setting-member {
            background: none;
            width: 90px;
            height: 30px;
            line-height: 30px;
            color: #468CC8;
            border: #468CC8 solid 1px;
          }
          .btn-clear-member {
            background: none;
            width: 90px;
            height: 30px;
            line-height: 30px;
            color: #6E6E6E;
            border: #6E6E6E solid 1px;
          }
        }
      }
    }
    .btn-submit-region {
      display: inline-block;
      width: 100%;
      height: 100%;
      text-align: center;
      .btn-submit {
        width: 150px !important;
        height: 30px;
        line-height: 0;
        margin: 15px 15px 15px;
      }
      .btn-save-with-private {
        color: #6E6E6E;
        border: 1px solid #6E6E6E;
      }
    }
  }
  .m-modal {
    .modal-inner {
      top: 10%;
      .btn-temp-save {
        margin-top: 5px;
        width: 200px;
        height: 40px;
      }
      &.category-member-setting-search {
        padding-left: 0px;
        padding-right: 0px;
        width: 80%;
        text-align: center;
        background: #fff;
        z-index: 11000;
        padding-bottom: 5px;
        padding-top: 5px;
        font-size: 13px;
        .top-area {
          position: relative;
          height: 40px;
          background-color: white;
          text-align: center;
          .btn-close-modal {
            position: absolute;
            top: 4px;
            right: 4px;
            background: url(#{$IMG_PATH}icon/icn_close_k.png) left center no-repeat;
            background-size: 15px;
            width: 20px;
            height: 20px;
            float: right;
            cursor: pointer;
            &:hover {
              opacity: .7;
            }
          }
          .top-title {
            line-height: 30px;
            color: #82bef0;
            font-size: 15px;
          }
        }
        .selected-course-area {
          display: flex;
          margin: 0px 20px 0px 20px;
          .label-selected-category-name {
            font-weight: bold;
          }
        }
        .category-member-search-header {
          justify-content: flex-start;
          margin: 0px 20px 0px 20px;
          .label-lesson-name {
            display: inline-flex;
            align-items: center;
            span {
              display: inline-block;
              padding-left: 25px;
            }
          }
          .label-chapter-name {
            display: inline-flex;
            align-items: center;
            span {
              display: inline-block;
              padding-left: 25px;
            }
          }
          .label-content-type {
            display: inline-flex;
            align-items: center;
            span {
              display: inline-block;
              padding-left: 25px;
            }
          }
          .input-lesson-name {
            margin-left: 10px;
            height: 33px;
            width: 200px;
            .input-text {
              width: 100%;
              border: 1px solid #c8c8c8;
              padding: 3px;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              max-width: 100%;
            }
          }
          .input-chapter-name {
            margin-left: 10px;
            height: 33px;
            width: 200px;
            .input-text {
              width: 100%;
              border: 1px solid #c8c8c8;
              padding: 3px;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              max-width: 100%;
            }
          }
          .input-content-type {
            text-align: left;
            display: inline-flex;
            .checkbox-btn {
              padding-right: 10px;
            }
          }
          table td {
            padding-right: 10px;
          }
        }
        .error {
          font-size: 12px;
          color: red;
          font-weight: bold;
          text-align: left;
        }
        .page-inner {
          background: $background_theme_color_contents;
          height: 100%;
          &.category-member-setting-search-modal {
            .page-contents {
              width: 100%;
              height: 100%;
              margin: auto;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              .category-member-setting-search-header {
                display: flex;
                padding-bottom: 5px;
                .except {
                  height: 71px;
                  display: none;
                }
                .result-txt {
                  margin-top: 5px;
                  margin-left: 10px;
                  min-height: 30px;
                  display: flex;
                  align-items: center;
                }
              }
            }
            height: 321px;
            margin: 5px 20px 0px 20px;
            .no-data {
              height: 263px;
            }
            .category-member-setting-result {
              @include search_table_f;
              padding: 0px !important;
              &.result-tbl {
                @media screen and (max-width: 1366px) {
                  @media screen and (max-height: 768px) {
                    height: 85%;
                  }
                }
                padding: 0px;
                margin: 0px 10px 0px 10px !important;
                height: 85%;
                background: white;
                .ps-scrollbar-y-rail {
                  right: 0px;
                  z-index: 6;
                }
                table {
                  &.select-category-member-setting-table {
                    margin-bottom: 15px;
                    margin-top: 30px;
                  }
                  min-width: 1000px;
                  thead>tr {
                    width: 100%;
                    position: fixed;
                    z-index: 5;
                    .ps-scrollbar-x-rail, .ps-scrollbar-y-rail {
                      display: none;
                    }
                  }
                  th {
                    border: 0.5px solid #c8c8c8 !important;
                    border-top: 0px solid #fafafa !important;
                    padding: 5px;
                  }
                  th:first-child {
                    border-left: 0px solid #fafafa !important;
                  }
                }
                .select-category-member-setting-table, .select-category-member-setting-table-header {
                  //table-layout: auto;
                  input[type=checkbox] {
                    display: none;
                  }
                  td, th {
                    padding: 5px;
                    box-sizing: border-box;
                  }
                  th:hover {
                    cursor: pointer;
                  }
                  label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 14px !important;
                    height: 14px !important;
                    &::after {
                      left: calc(50% + 19px);
                      width: 14px !important;
                      height: 14px !important;
                    }
                    &::before {
                      left: calc(50% + 21px);
                      width: 11px !important;
                      height: 5px !important;
                    }
                  }
                  input[type=text] {
                    width: 95%;
                    text-align: center;
                  }
                  .col-check {
                    width: 5%;
                    height: 34px;
                    .checkbox-btn-disabled {
                      label {
                        cursor: default;
                      }
                      input {
                        cursor: default;
                      }
                      label::before {
                        cursor: default;
                      }
                      label::after {
                        background-color: #eee !important;
                        cursor: default;
                      }
                    }
                    input[type="checkbox"]:disabled~label::before {
                      display: block !important;
                    }
                  }
                  .col-base {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -webkit-text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                  }
                  .col-lesson-name {
                    @extend .col-base;
                    width: 25%;
                  }
                  .col-chapter-name {
                    @extend .col-base;
                    width: 25%;
                  }
                  .col-content-type {
                    @extend .col-base;
                    width: 20%;
                  }
                  .col-category-name {
                    @extend .col-base;
                    width: 25%;
                  }
                }
              }
            }
          }
        }
        .footer-area {
          background: #fff;
          width: 100%;
          height: 30px;
          padding: 10px 0;
          text-align: center;
          a {
            margin-right: 10px;
          }
          .btn-modal-submit-c {
            padding: 0 15px;
            width: 10%;
            background-color: #FFFFFF;
            text-align: center;
            -moz-border-radius: 0px;
            border-radius: 2px;
            border: 1px solid #DC780A;
            color: #DC780A;
            font-weight: normal;
            line-height: 30px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
