@charset "UTF-8";

@mixin icon_content_detail {
    position: absolute;
    display: block;
    width: 80px;
    height: 80px;
    z-index: 1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size:60px;
    margin-top: -40px;
    border: 2px solid #a0a0a0;
    opacity: .5;
}