@charset "UTF-8";

.page-all-history-extraction-error {

  .m-search-c-inner {
    iframe {
      width: 100vw;
      height: 100vh;
    }
  }

  .global-header {
    position: static !important;
    width: 800px;
    margin-top: 80px;
    box-shadow: none;
    border-bottom: 2px solid gray;

    span.name {
      font-size: 18px;
      opacity: 1 !important;
      color: gray !important;
    }

    span img {
      opacity: 1 !important;
    }

  }

  .page-inner {
    padding-top: 10px;
    width: 800px;
    margin: auto;

    .page-contents {
      text-align: center;

      .m-form {
        width: 800px;
        left: 0;
      }

      .inner {
        text-align: left;

        .back img {
          width: 25px;
          height: 25px;
          vertical-align: -webkit-baseline-middle;
        }

      }

      .content-box {
        display: none;
      }

      .content-box.active {
        display: block !important;
      }

      .form-row.btn-switch a {
        margin-left: 13px;
      }

      .form-row:last-child {
        margin-top: 31px;
      }

      .form-row {
        width: 525px;
        margin: auto;
        text-align: left;
        margin-top: 50px;

        & > span {
          float: left;
        }

        & > input {
          height: 32px;
        }

        a {
          display: inline-block;
        }

        .attention {
          font-size: 12px;
          color: red;
        }

      }

      .form-row.form-status {
        margin-top: 31px !important;

        a {
          pointer-events: none;
        }
      }

      .form-btns.btn-status {
        margin-top: 79px !important;
      }

      .course-length {
        display: inline-block;
        margin: -5px 5px 0 27px;
        vertical-align: middle;
        max-width: 28em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .c-name-btn {
        display: inline-block;
        margin: -5px 0 0 -11px;
        vertical-align: middle;
      }

      .course-search {
        display: inline-block;
        vertical-align: middle;
      }

      .logo {
        margin-bottom: 60px;
      }

      .btn {
        font-size: 18px;
      }

      .form-btns {
        margin-top: 60px;
        margin-bottom: 30px;

        .btn-gray {
          border: 1px solid #a0a0a0;
          color: #a0a0a0;
        }
      }

      .forgot-pass {
        font-size: 13px;
      }

    }
  }

  .confirm-link-modal {
    opacity: 0;
    display: none;
  }
}
