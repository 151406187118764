@charset "UTF-8";

.page-user-edit {

  .page-inner {

    .page-contents {
      width: 100%;
      padding: 20px 30px;
      margin: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      max-width: 1200px;
    }

    .m-form {
      left: 0;
      width: 840px;
      
        .warning-row{
        
          td{
            padding: 0;
            padding-left: 10px;
          }
          
          .warning{
            margin-top: 0px;
            font-size: 12px;
            color: red;
            font-weight: bold;
          }
        }

        .hazz_width{
            
            width: 62.5%;
            
            
        }
        
        .hazz_pos{
            
            position: relative;
            
            
        }
        
        .hazz_pos_td{
            position: relative;

        }



      .radio-row{
          .radio-group-custom:not(:first-child){
              line-height: 42px;
              margin: 0 30px 0 0;
          }
          .radio-group-custom:first-child{
              padding-bottom: 12px;
              margin: -3px 30px 0 0;
          }
          .row-second {
            width: 87%;
          }
          .row-last {
            width: 165px;
          }
      }


      .form-wrap {

        &:first-child {
          margin-top: 0;
          .form-sub-heading {
          }
        }

        .form-group {
          padding-right: 40px;
        }

        table {

          th:first-child {
            width: 25%;
          }
          .vertical-top{
            vertical-align: top;
          }
        }
      }

      &.conf-form {
        .form-wrap {

          .form-group {
            padding-right: 0;
          }
        }
      }

      label {
        &.disabled {
          cursor: default;
        }
        .pseudo-radio {
          &.disabled {
            cursor: default;
            border-color: #aaa;
            
            .checked {
              background: #aaa;
            }
          }
        }
      }
    }

  }
 .admin-img-select{
    width:320px;
  }

@media all and (-ms-high-contrast: none){
 /* IE のみ適用される */
  .admin-img-select{
    width:320px;
    text-align: right;
      input{
          width: 320px;
      }
      .upload-thumb-del{
          text-align: left;
      }
  }
}

@supports (-ms-ime-align: auto) {
  /* Edge のみ適用される */
  .admin-img-select{
      width:320px;
      text-align: right;
      input{
          width: 320px;
      }
      .upload-thumb-del
      {
          text-align: left;
      }
  }
}


  .hazz_hints{
    top:26px;
  }
  .hazz_pos_day{
       top:50px;
  }
  @-moz-document url-prefix() {
    .hazz_hints{
      right:10px;
    }
  }

@media all and (-ms-high-contrast: none){
 /* IE のみ適用される */
    .hazz_hints{
        top:19px;
        margin-left: 13px;
    }
}

@supports (-ms-ime-align: auto) {
  /* Edge のみ適用される */
    .hazz_hints{
        top:19px;
        margin-left: 13px;
    }

  .hazz_pos_day{
       margin-left: 6px;
  }

}



  .hints{
    display: inline-block;
    margin-top: 11px;
    position: absolute;

    &.text-content{
      margin-top: 5px;
    }


    .hint-btn{
      background: url(#{$IMG_PATH}icon/icn_hints_bk.png) no-repeat;
      padding-left: 25px;
      background-position: 50%;
      -webkit-background-size: 20px;
      background-size: 20px;
      &.hint-btn-admin{
       /*padding-bottom: 60px;*/
      }
    }
    .hint-content{
/*      position: relative;*/
        position: absolute;
      display: none;
      width: auto;
      min-width: 250px;
      max-width: 290px;
      background: #d3edfa;
      padding: 10px;
      font-size: 12px;
      margin-top: 3px;
      margin-left: 3px;
      z-index: 999;
        &.hint-content-admin{
         /* margin-top: 35px;*/
        }
    }


@media all and (-ms-high-contrast: none){
 /* IE のみ適用される */
    .hint-content{
        margin-top: 25px;
    }
}

@supports (-ms-ime-align: auto) {
  /* Edge のみ適用される */
    .hint-content{
        margin-top: 25px;
    }
}






  }
}