@charset "UTF-8";
.header-chapter-detail{
  position: relative;
  z-index: 2;
}
.c-courses-detail-header {
  background: #fff;
  position: relative;

  .course-header-top {
    overflow: hidden;
    margin-bottom: 10px;
    min-height: 30px;
    height: auto;

    .course-name {
      //position: absolute;
      //top: 10px;
      //left: 10px;
      font-size: 20px;
      color: #1dadde;
    }

    .course-time {
      float: right;
      padding: 6px 0 6px 30px;
      background: url(#{$IMG_PATH}icon/icn_calendar_b.png) left center no-repeat;
      -webkit-background-size: 20px auto;
      background-size: 20px auto;
    }
  }

  .c-courses-detail-header-inner {
    position: relative;
    padding: 10px 5px 5px 10px;

    .courses-detail-header-layout {
      height: 100%;
      min-height: 1px;

      & > div {
        vertical-align: top;
        height: 100%;
        min-height: 1px;
      }

      .left {
        width: 65%;
        padding-right: 20px;
      }
    } // .courses-detail-layout

    .courses-detail-area {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      table-layout:fixed;

      .ph-area {
        vertical-align: top;
        width: 290px;
        img {
          width: 100%;
        }

        .upload {
          position: relative;
          background: #d8d8d8;
          height: 217.5px;
          width: 290px;

          .ph {
            display: block;
            width: 100%;
            height: 100%;

            .thumb {
              display: block;
              width: 100%;
              height: 100%;
              background-repeat: no-repeat;
              -webkit-background-size: cover;
              background-size: cover;
              background-position: center center;
              color: #fff;
              text-align: center;
              font-size: 22px;
              line-height: 217.5px;
            }

          }

          .btn-upload {
            position: absolute;
            right: 0;
            bottom: 0;
            overflow: hidden;
            padding: 10px;

            input[type="file"] {
              position: absolute;
              left: -9999px;
              top: -9999px;
            }

            label {
              @include HoverOpacity();
              background: #fff url(#{$IMG_PATH}icon/icn_selectbtn_plus_b.png);
              -webkit-background-size: 100% 100%;
              background-size: 100% 100%;
              width: 38px;
              height: 38px;
              display: block;

              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              border-radius: 50%;

            }
          }

        }

      } // .ph-area

      .course-info-area {
        vertical-align: top;
        padding-left: 20px;

        table {
          width: 100%;
          table-layout: fixed;

          th, td {
            padding: 8px;
            border: 1px solid $border_color_default;
          }

          th {
            text-align: right;
            width: 120px;
            background: $background_theme_color_light;
          }

          td {
            vertical-align: middle;

            .scroll-cell{
              height: 122px;
              padding-right: 25px;
              overflow: auto;
            }
          }
        }

      } // .course-info-area
    } // .courses-detail-area

    .courses-nav {
      margin-top: 20px;
      ul {
        @include EqualList(4, 10px);

        li {

          a {
            @include sts_notice(){
              right: -5px;
              top: -5px;
            };
            @include HoverOpacity();
            position: relative;
            display: block;
            text-align: center;
            color: $font_color_default;
            border: 1px solid #999;
            line-height: 28px;
            font-size: 13px;
          }

        }
      }
    } // .courses-nav

    .information-area {
      position: relative;
      background: $background_theme_color_light;
      padding: 10px 0 10px 10px;
      height: 100%;
      min-height: 1px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) { // chrome only
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
      }

      .heading {
        font-weight: bold;

        a {
          display: block;
          color: #143395;
          font-size: 18px;
          text-align: center;
          cursor: default;
          margin-bottom: 10px;
        }

        span {
          display: inline-block;
          padding-left: 25px;
          background: url(#{$IMG_PATH}icon/icn_infomation_b.png) left center no-repeat;
          -webkit-background-size: 18px;
          background-size: 18px;
        }
      }

      .scroll-area-outer {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        padding-left: 10px;
        padding-top: 47px;
        padding-bottom: 10px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      .scroll-area.ps-active-y {
        .scroll-area-inner {
          padding-right: 25px;
        }
      }

      .scroll-area {
        height: 100%;

        .scroll-area-inner {
          padding-right: 10px;

          ul {
            li {
              background: #fff;
              margin-bottom: 3px;
              font-size: 12px;
              position: relative;
              padding: 5px 5px 5px 30px;

              @include sts_notice(){
                left: 10px;
                top: 50%;
                margin-top: -5px;
              }

              p {

                span {
                  display: block;
                }

              }

            } // li
          } // ul
        } // .scroll-area-inner

      } // .scroll-area
    } // .information-area

    .description-balloon {
      position: absolute;
      left: 0!important;
      margin-top: -60px;
      width: 800px;
      z-index: 4;
      background: rgba(0, 0, 0, .9);
      color: #fff;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 20px;
      font-size: 12px;
      display: none;

      .description-balloon-inner {
        position: relative;

        & > div {
          display: none;
          max-height: 180px;
          padding-right: 20px;
          margin-right: -10px;
          overflow-y: auto;
        }

        .tri {
          position: absolute;
          bottom: -40px;
          left: 20px;
          display: block;
          width: 20px;
          height: 20px;
          overflow: hidden;

          &:after {
            @include PseudoElements();
            width: 12px;
            height: 12px;
            margin: -6px auto 0;
            background: rgba(0, 0, 0, .9);
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        .close {
          @include HoverOpacity();
          display: block;
          width: 14px;
          height: 14px;
          position: absolute;
          right: -14px;
          top: -14px;

          div {
            position: relative;
            width: 100%;
            height: 100%;

            span {
              position: absolute;
              top: 50%;
              margin-top: -1px;
              display: block;
              width: 100%;
              height: 2px;
              -webkit-border-radius: 2px;
              -moz-border-radius: 2px;
              border-radius: 2px;
              background: #fff;

              &.bar-01 {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
              }
              &.bar-02 {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
              }
            }
          }
        }
      }

    }

  } // .index-header-inner

} // .index-header
