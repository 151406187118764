@charset "UTF-8";

@mixin search_table {
  @include design_default_set;
  padding: 20px;
  
  &.bg {
    background: $background_theme_color_contents;
  }

  table + .sub-heading {
    margin-top: 20px;
  }

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 2px;

    tr:nth-child(2n){
      th, td {
        background: #fafafa;
      }
    }

    tr:first-child {
      th {
        background: #d1d1d1;
      }
    }

    th, td {
      padding: 10px;
      text-align: left;
      vertical-align: middle;
    }

    th {
      font-weight: bold;
    }

    td {
      background: #fff;
    }

    .row-select {
      width: 50px;
    }

    .row-check {
      width: 50px;
    }

    .row-id {
      width: 136px;
    }

    .row-ope {
      width: 160px;
    }

    .btn-ope-play,
    .btn-ope-revert,
    .btn-ope-edit,
    .btn-ope-clone,
    .btn-ope-delete {
      @include HoverOpacity();
      display: inline-block;
      padding: 3px 8px;
      color: #fff;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      margin: -3px 2px;
      line-height: 22px;
    }

    .btn-ope-play,
    .btn-ope-edit {
      float: left;
      border: 1px solid #333;
      color: #333;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
    }
    .btn-ope-revert,
    .btn-ope-clone {
      float: right;
      border: 1px solid #b9b9b9;
      color: #b9b9b9;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
    }
    .btn-ope-delete {
      border: 1px solid #e66464;
      color: #e66464;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
    }

  }
}