@charset "UTF-8";

// .icon-area {
//   letter-spacing: -.4em;
//   .icon {
//     letter-spacing: normal;
//   }
//   span img {
//     width: 68px;
//   }
// }

// .icon {
//   display: inline-block;
//   width: 85px;
//   height: 29px;
//   border-width: 2px;
//   border-style: solid;
//   text-align: center;
//   //font-size: 13px;
//   -webkit-box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   box-sizing: border-box;
//   font-weight: bold;
//   line-height: 25px;
//   vertical-align: top;
//   margin-right: 5px;
//   margin-bottom: 5px;
//   font-size: 11px;

//   &.icon-size-l {
//     width: 90px;
//     height: 30px;
//     line-height: 26px;
//     font-size: 16px;
//   }

// } // .icon


.icon-input-usergroup {
    padding-left: 25px;
    background: url(#{$IMG_PATH}icon/icn_usergroup_b.png) no-repeat;
    background-position: 0% 35%;
    -webkit-background-size: 20px;
    background-size: 20px;
    cursor: default;
}
.icon-input-user {
    padding-left: 25px;
    background: url(#{$IMG_PATH}icon/icn_user_b.png) no-repeat;
    background-position: 0% 35%;
    -webkit-background-size: 20px;
    background-size: 20px;
    cursor: default;
}

.icn-conccp-span {
    background: url(#{$IMG_PATH}icon/icn_conccp_k.png) center center no-repeat;
    -webkit-background-size: 100%;
    background-size: 100%;
    vertical-align: middle;
    display: inline-block;
    width: 32px;
    height: 32px;
    background-position-x: 50%;
    
}

.icn-contest-span {
    background: url(#{$IMG_PATH}icon/icn_test_k.png) center center no-repeat;
    -webkit-background-size: 100%;
    background-size: 100%;
    vertical-align: middle;
    display: inline-block;
    width: 34px;
    height: 34px;
    background-position-x: 50%;
    width: 32px;
    height: 32px;
}

.icn-conanket-span {
    background: url(#{$IMG_PATH}icon/icn_conenq_k.png) center center no-repeat;
    -webkit-background-size: 100%;
    background-size: 100%;
    vertical-align: middle;
    display: inline-block;
    width: 32px;
    height: 32px;
    background-position-x: 50%;
    
}

.icn-convideo-span {
    background: url(#{$IMG_PATH}icon/icn_video_k.png) center center no-repeat;
    -webkit-background-size: 100%;
    background-size: 100%;
    vertical-align: middle;
    display: inline-block;
    width: 32px;
    height: 32px;
    background-position-x: 50%;
    
}

.icn-condocument-span {
    background: url(#{$IMG_PATH}icon/icn_file_document_k.png) center center no-repeat;
    -webkit-background-size: 100%;
    background-size: 100%;
    vertical-align: middle;
    display: inline-block;
    width: 32px;
    height: 32px;
    background-position-x: 50%;
    
}

.icn-conhtml-span {
    background: url(#{$IMG_PATH}icon/icn_file_html_k.png) center center no-repeat;
    -webkit-background-size: 100%;
    background-size: 100%;
    vertical-align: middle;
    display: inline-block;
    width: 32px;
    height: 32px;
    background-position-x: 50%;
}

.icn-condebate-span {
    background: url(#{$IMG_PATH}icon/icn_conccp_k.png) center center no-repeat;
    -webkit-background-size: 100%;
    background-size: 100%;
    vertical-align: middle;
    display: inline-block;
    width: 32px;
    height: 32px;
    background-position-x: 50%;
}

.icn-conreport-span {
    background: url(#{$IMG_PATH}icon/icn_report_k.png) center center no-repeat;
    -webkit-background-size: 100%;
    background-size: 100%;
    vertical-align: middle;
    display: inline-block;
    width: 32px;
    height: 32px;
    background-position-x: 50%;
}

.icn-conurl-span {
    background: url(#{$IMG_PATH}icon/icn_conccp_k.png) center center no-repeat;
    -webkit-background-size: 100%;
    background-size: 100%;
    vertical-align: middle;
    display: inline-block;
    width: 32px;
    height: 32px;
    background-position-x: 50%;

}