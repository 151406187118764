@charset "UTF-8";

#exportCsv {
  display: none;
}

.m-modal {
  .modal-inner {
    &.export.export-search {
      width: 620px;
    }

    &.export {
      padding-left: 0px;
      padding-right: 0px;
      width: 440px;
      text-align: center;
      background: #fff;
      z-index: 11000;

      .inpt {
        .only-charcode {
          display: block;
        }

        margin-top: 20px;
        margin-bottom: 25px;

        .lable-txt {
          display: flex;
          margin-left: 22%;
        }

        .chosen {
          margin-left: 22%;
          height: 32px;
          width: 60%;
          margin-bottom: 20px;

          .sel.js-changeSelect.disabled {
            display: none;
          }

          .init {
            display: block !important;
          }

          .sel {
            height: 100%;
            width: 100%;
            border: 1px solid #c8c8c8;
            -moz-border-radius: 0px;
            border-radius: 0px;

            p {
              font-size: inherit;
              font-weight: normal;
              margin-top: 0;
              background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
              background-size: 12px;
              line-height: 32px;
              white-space: nowrap;
              // text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }

      .title {
        margin-top: 10px;

        span {
          color: #82bef0;
          font-size: 18px;
        }
      }
    }
  }
}
