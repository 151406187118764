@charset "UTF-8";
.debate-grading-wrap{
    min-width: 1200px;
    .contents {
    height: 100%;

    .contents-inner {
        height: 100%;
        .multi-space-ellipsis {
        white-space: pre !important;
        overflow: hidden;
        text-overflow: ellipsis !important;
        word-wrap: normal !important;
        }
        
        .hidden {
            display: none;
        }

        .page-debate-grading-search {
        height: 100%;

        .page-inner {
            background: $background_theme_color_contents;
            height: 100%;

            .page-contents {
                width: 100%;
                height: 100%;
                margin: auto;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;

                .m-form-search {
                    padding: 0;
                    .error {
                        margin: 1px 0 1px 0;
                    }
                    .form-group {
                        .inner {
                            width: 80%;
                            padding-bottom: 20px;
                            padding-top: 5px;
                            table {

                                .checkbox-btn label::after {
                                    width: 14px !important;
                                    height: 14px !important;
                                }

                                table-layout: fixed;
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                tr {
                                    width: 100%;
                                    height: 30px;
                                    td {
                                        padding-left: 20px;
                                        max-width: 400px;
                                        min-width: 300px;
                                        @media screen and (max-width: 1000px) {
                                            max-width: 273px;
                                        }
                                        //for IE
                                        @media screen and (max-width: 1000px) and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                                            max-width: 300px;
                                        }
                                        @media screen and (min-width: 1000px) and (max-width: 1280px){
                                            max-width: 300px;
                                        }
                                    }
                                }
                                tr:last-child {
                                    display: table-row;
                                
                                    vertical-align: middle;
                                    height: 30px;
                                    td {
                                        padding-left: 0px;
                                        text-align: center;
                                    }
                                }
                                
                                .form-row {
                                    .layout>*{
                                        width: 100% !important; 
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }//.form-group
                    .inpt {
                        display: flex;
                        align-items: center;
                        width: 100%;

                        &.f-end {
                        justify-content: center;
                        }

                        &.flex-end {
                        justify-content: flex-end;
                        }

                        .title {
                        display: flex;
                        min-width: 85px;
                        justify-content:flex-end;
                        align-items:center;
                        padding-right: 5px;

                        &.size-l {
                            min-width: 119px;
                        }

                        &.no-width {
                            min-width: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            padding-left: 2px;
                        }

                        }
                        .status-chk {
                            display: flex;
                            .checkbox-btn {
                                padding-left: 20px;
                            }
                        }
                        .c-name {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            display: flex;
                            align-items: center;

                            span {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            padding-top: 10px;
                            }
                        }

                        .chosen {
                        min-width: 60px;
                        width: 100%;

                        .sel {
                            width: 100%;         
                            border: 1px solid #c8c8c8;
                            -moz-border-radius: 0px;
                            border-radius: 0px;

                            p {
                            font-size: inherit;
                            font-weight: normal;
                            margin-top: 0;
                            background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
                            background-size: 12px;
                            line-height: 32px;
                            white-space: nowrap;
                            // text-overflow: ellipsis;
                            overflow: hidden;
                            }

                        }

                        select {
                            width: 100%;
                        }

                        &.catology {
                            width: 35%;
                            // font-size: 10px;

                            // p {
                            //   line-height: normal;
                            // }

                            select {
                            // word-wrap: break-word;      /* IE*/
                            // white-space: -moz-pre-space; /* Firefox */
                            // white-space: pre-wrap;      /* other browsers */
                            display:inline-block;
                            
                            }
                        }
                        }

                        .txt {
                        width: 100%;

                        &.maker {
                            width: 50%;
                        }

                        }

                        .rate {
                            max-width: 115px;
                        }

                        .btn-c-search {
                        width: 40px;
                        margin-left: 5px;
                        }
                    } //.inpt
                }//.m-form-search

                .no-data {
                    min-height: 85%;
                    margin: 0 10px;
                    background: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-bottom: 0px !important;

                    &.hide {
                    display: none;
                    }
                }

                .debate-header {
                    display: flex;
                    padding-bottom: 5px;
                    
                    .except {
                    height: 71px;
                    display: none;
                    }
                    .download {
                    position: absolute;
                    right: 0px;
                    margin-top: 5px;
                    margin-right: 10px;
                    min-height: 30px;
                    padding-bottom: 5px;
                    }

                    .result-txt {
                    margin-top: 5px;
                    margin-left: 10px;
                    min-height: 30px;
                    display: flex;
                    align-items: center;
                    }
                }

                .footer-area {
                    background: #bae3f9;
                    width: 100%;
                    height: 35px;
                    text-align: center;

                    position: relative;
                    bottom: 0px;
                    left: 0px;
                    padding-bottom: 100px;
                    margin-top: 5px;

                    .align-center{
                        margin: auto;
                    }

                    a {
                        margin-right: 10px;
                    }
                    .btn-modal-submit-c{
                        /*
                        padding: 0 15px;
                        width: 10%;
                        background-color: #FFFFFF;
                        text-align: center;
                        -moz-border-radius: 0px;
                        border-radius: 2px;
                        border: 1px solid #DC780A;
                        color: #DC780A;
                        font-weight: normal;
                        line-height: 30px;
                        font-size: 16px;
                        */
					    position: relative;
					    background: #fff;
					    border: 1px solid #DC780A;
					    width: 240px;
					    height: 44px;
					    line-height: 44px;
					    font-size: 16px;
					    color: #DC780A;
					    font-weight: normal;
					    -webkit-border-radius: 2px;
					    -moz-border-radius: 2px;
					    border-radius: 2px;
					    -webkit-box-sizing: content-box;
					    -moz-box-sizing: content-box;
					    box-sizing: content-box;
					    padding: 0;
                    }
                }

            }//.page-contents

            .debate-grading-search-header {
                background: #fff;
                padding: 0 20px;
                .inner {
                    min-height: 34px;
                    //padding: 20px 0;
                    padding-bottom: 10px;
                    padding-top: 20px;
                    position: relative;
                    // border-bottom: 2px solid #d1d1d1;
                    overflow: hidden;
                    .back {
                        @include HoverOpacity();
                        position: absolute;
                        left: 0;
                        img {
                            width: 20px;
                            height: 20px;
                            vertical-align: -webkit-baseline-middle;
                        }
                    }
                    .btn {
                        float: right;
                        text-align: center;
                    }
                    .select-upload {
                        float: right;
                        margin-right: 5px;
                        .sel {
                            background-color: white;
                            -webkit-border-radius: 0px;
                            -moz-border-radius: 0px;
                            border-radius: 0px;
                            color: #468CC8;
                            border: 1px solid;
                            width: 200px;
                        }
                        .sel.sel-size-auto {
                            height: 36px;
                            width: 170px !important;
                            .sel-txt {
                                padding-left: 10px;
                                padding-right: 17px;
                                background-size: 18px;
                                background-image: url(#{$IMG_PATH}icon/icn_selectbtn_bottom_b.png);
                                span{
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;
                                }
                            }
                        }
                    }
                }
            }//.debate-search-header

            .debate-result {
                @include search_table_f;
                // padding: 20px 0 !important;
                padding: 0px !important;
                margin-right: 10px;
                margin-left: 10px;
                height: 61%;
                position: relative;
                //100% - (global-header) - (debate-grading-search-header) - (m-form-search) - (debate-header) - button
                //height : calc(100% - 53px - 76px - 194px - 40px - 20px);
                height: calc(100% - 33px - 56px - 194px - 40px - 20px);

                .table-header{
                    table-layout: auto;
                    .col-name{
                        min-width: 257px;
                    }
                    .col-id{
                        min-width: 135px;
                    }
                    .col-btn{
                        min-width: 83.2px;
                    }
                    tr{
                        width: 100%;
                        position: fixed;
                        th{
                            padding: 5px;
                            box-sizing:border-box;
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                        }
                    }
                }
                .table-result-nodata{
                    height: 100%;
                    tr{
                        td{
                            border: 0px solid #fafafa;
                            color: #AFAFAF;
                            box-sizing:border-box;
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                            span{
                                position: relative;
                            }
                        }
                    }
                }
                .table-result,.table-header {
                    .col-checkbox {
                        width: 2%;
                        min-width: 38px;
                    }
                    
                    .col-login-id {
                        width: 11%;
                        min-width: 84px;
                    }
                    .col-username {
                        width: 11%;
                        min-width: 67px;
                    }
                    .col-topic {
                        width: 12%;
                        min-width: 100px;
                        //max-width: 226px;
                        @media screen and (max-width: 1345px) {
                            //max-width: 100px;
                        }
                    }
                    .col-content {
                        width: 12%;
                        min-width: 118px;
                        //max-width: 226px;
                        @media screen and (max-width: 1345px) {
                            //max-width: 118px;
                        }

                    }
                    .col-posted-date {
                        width: 8%;
                        min-width: 80px;
                    }
                    .col-academic-status {
                        width: 5%;
                        min-width: 81px;
                    }
                    .col-grading-status {
                        width: 5%;
                        min-width: 81px;
                    }
                    .col-score {
                        width: 5%;
                        min-width: 55px;
                        //max-width: 70px;
                    }
                    .col-scoring-date {
                        width: 8%;
                        min-width: 80px;
                    }
                    .col-late {
                        width: 5%;
                        min-width: 45px;
                    }
                    .col-confirm-score {
                        width: 5%;
                        min-width: 67px;
                    }
                    .col-grader {
                        width: 11%;
                        min-width: 67px;
                    }
                    
                    .col-comment {
                        width: 11%;
                        min-width: 81px;
                    }
                }
                .table-result {
                    table-layout: fixed;
                    .col-topic {
                        .topic-name {
                            width: 98%;
                        }
                    }
                    .col-content {
                        text-align: left;
                        div.content-inside {

                            width: 98%;
                            display: inline-flex;
                            div.content-name {
                                width: 100%;
                            }
                            a {
                                margin-left: 4%;
                                .img {
                                    background-image: url(#{$IMG_PATH}icon/confirm_debate_content_g.png);
                                    background-repeat: no-repeat;
                                    background-size: 27px;
                                    width: 30px;
                                    height: 30px;
                                    margin-right: -3px;
                                    display: block;
                                }
                                img {
                                    content: url(#{$IMG_PATH}icon/confirm_debate_content_g.png);
                                }
                            }
                        }

                        &.hover {
                            div.content-inside {
                                a {
                                    .img {
                                        background-image: url(#{$IMG_PATH}icon/confirm_debate_content_w.png);
                                        background-repeat: no-repeat;
                                        background-size: 27px;
                                        width: 30px;
                                        height: 30px;
                                        margin-right: -3px;
                                        display: block;
                                    }
                                    img {
                                        content: url(#{$IMG_PATH}icon/confirm_debate_content_w.png);
                                    }
                                }
                            }
                        }

                    }
                    .col-posted-date {
                        word-break: normal;
                    }
                    .col-score {
                        input {
                            height: 19px;
                            text-align: right;
                            width: 90%;
                        }
                    }
                    .col-scoring-date {
                        word-break: normal;
                    }
                    .col-late {
                        &.check {
                            span {
                                height: 25px;
                                width: 25px;
                                display: inline-block;
                                background-image: url(#{$IMG_PATH}icon/icon_late_g.png);
                                -webkit-background-size: 25%;
                                background-size: 25px;
                                background-repeat: no-repeat;
                                background-position: 50%;
                            }
                            &.hover {
                                span {
                                    background-image: url(#{$IMG_PATH}icon/icon_late_w.png);
                                }
                            }
                        }
                    }
                    .col-comment {
                        input {
                            height: 19px;
                            width: 90%;
                        }
                    }

                    tr{
                        td:not(.row-ope){
                            box-sizing:border-box;
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                        }
                    }
                }
                table {
                    .checkbox-btn label::after {
                        left: 3px;
                        width: 14px !important;
                        height: 14px !important;
                    }
                    .checkbox-btn label::before {
                        left: 5px;
                        width: 11px !important;
                        height: 5px !important;
                    }
                    tr:not(:first-child):hover, tr.selected,.hover{
                        //for selected event
                        .col-late {
                            &.check {
                                span {
                                    background-image: url(#{$IMG_PATH}icon/icon_late_w.png);
                                }
                            }
                        }
                        .col-content {
                            div.content-inside {
                                a {
                                    .img {
                                        background-image: url(#{$IMG_PATH}icon/confirm_debate_content_w.png);
                                        background-repeat: no-repeat;
                                        background-size: 27px;
                                        width: 30px;
                                        height: 30px;
                                        display: block;
                                        margin-right: -3px;
                                    }

                                    img {
                                        content: url(#{$IMG_PATH}icon/confirm_debate_content_w.png);
                                    }
                                }
                            }
                        }

                        .btn-submit-delete { 
                            background: url(#{$IMG_PATH}icon/icn_trash_w.png) 20px center no-repeat;
                            -webkit-background-size: 80%;
                            background-size: 80%;
                            background-position-x: 50% ;
                        }
                        .btn-submit-setting {
                            background: url(#{$IMG_PATH}icon/icn_edit_w.png) 20px center no-repeat;
                            -webkit-background-size: 80%;
                            background-size: 80%;
                            background-position-x: 50% ;
                        }
                    }
                }//table

                .checkbox-btn {
                    display: flex;
                    justify-content: center;
                    height: 20px;
                    label {
                        padding-left: 0px;
                    }
                }

                thead {
                    tr {
                        z-index: 5;
                        .header {
                            cursor: pointer;
                            box-sizing: border-box;

                            div {
                                display: inline-flex;
                                .img {
                                    background-image: url(#{$IMG_PATH}icon/icn_pullupdown_k.png);
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: 26px;
                                    width: 27px;
                                    padding-left: 7px;
                                }
                            }
                        }

                        .header-sort-up {
                            cursor: pointer;

                            div {
                                display: inline-flex;

                                .img {
                                    width: 26px;
                                    background-position: center;
                                    background-repeat: no-repeat;
                                    background-image: url(#{$IMG_PATH}icon/icn_pullup_k.png);
                                    background-size: 13px;
                                    padding-left: 7px;
                                }
                            }
                        }

                        .header-sort-down {
                            cursor: pointer;

                            div {
                                display: inline-flex;
                                .img {
                                    width: 26px;
                                    background-position: center;
                                    background-repeat: no-repeat;
                                    background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png);
                                    background-size: 13px;
                                    padding-left: 7px;
                                }
                            }
                        }
                    }
                }
            }//.debate-result

            .debate-list-inner{
                height: 100%;
                background-color: #fff;
            }
            .debate-list-outer{
                width: 100%;
                height: 100%;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                .all-comment {

                    box-shadow: grey 7px 7px 12px;
                    white-space: pre-wrap;

                    position: absolute !important;
                    display: none;
                    z-index: 99;
                    padding: 10px;

                    max-width: 300px;
                    max-height: 135px;
                    min-height: 60px;
                    min-width: 200px;

                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    background: #fff;
                    color: #333;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    border-radius: 5px;
                    border: 1px solid #333;
                    font-size: 12px;

                    &:before, &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 100%;
                        width: 0;
                        height: 0;
                        border-style: solid;
                    }
                    /* this border color controlls the outside, thin border */
                    &:before {
                        left: 50px;
                        top: calc(100% + 1px);
                        border-color: black transparent transparent transparent;
                        border-width: 22px 41px 0px 21px;
                    }

                    /* this border color controlls the color of the triangle (what looks like the fill of the triangle) */
                    &:after {
                        left: 50px;
                        border-color: white transparent transparent transparent;
                        border-width: 22px 41px 0px 21px;
                    }

                    .triangle-shadow {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-style: solid;

                        left: 57px;
                        top: calc(100% + 4px);
                        border-color: grey transparent transparent transparent;
                        border-width: 23px 41px 0px 20px;

                        -webkit-filter: blur(2px);
                        -moz-filter: blur(2px);
                        -o-filter: blur(2px);
                        -ms-filter: blur(2px);
                        filter: blur(2px);
                    }
                    .content-all-comment {
                        display: block;
                        max-height: 110px;
                        .ps-scrollbar-y-rail {
                            right: 1px !important;
                        }
                    }
                }
            }
            .scroll-area {
                &.ps-active-y {
                    padding-right: 16.2px;
                    margin-right: -16.2px;
                }
                .ps-scrollbar-y-rail{
                    right: 16.2px;
                }
                height: 100%;
            } // .scroll-area

            .no-data {
                .modal-inner {
                    width: 440px;
                }
            }

        } //.page-inner
        input.btn, a.btn {
            &.btn-submit-download {
                float: right;
                font-weight: initial;
                text-align: center;
                width: auto;
                padding: 6px 19px;
                color: #000;
                border: 1px solid #c8c8c8;
                margin: -3px 0;
                line-height: 22px;
                background: #fff;
                color: #6E6E6E;
                img{
                    width: 20px;
                    height: 20px;
                    padding-right: 7px;
                }
            }
            &.btn-submit-upload {
                background: url(#{$IMG_PATH}icon/icn_upload_w.png) 20px center no-repeat;
                -webkit-background-size: 20px;
                background-size: 20px;
                color: #468cc8;
                border: 1px solid;
                background-color: white;
                font-weight: normal;
                width: auto;
                height: 34px;
                line-height: 34px;
                text-align: center;
                -webkit-border-radius: 0px;
                -moz-border-radius: 0px;
                border-radius: 0px;
                font-size: 16px;
                padding: 0;
            }
            &.btn-submit-search-new {
                background: url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
                -webkit-background-size: 20px;
                background-size: 20px;
                background-position-x: 50% ;
                color: #468cc8;
                border: 1px solid #82BEF0;
                background-color: #82BEF0;
                font-weight: normal;
                width: 130px;
                height: 34px;
                line-height: 34px;
                text-align: center;
                -webkit-border-radius: 0px;
                -moz-border-radius: 0px;
                border-radius: 5px;
                font-size: 16px;
                padding: 0;
            }
            &.btn-submit-setting {
                background: url(#{$IMG_PATH}icon/icn_edit_k.png) 20px center no-repeat;
                -webkit-background-size: 80%;
                background-size: 80%;
                background-position-x: 50% ;
                font-weight: normal;
                width: 34px;
                height: 34px;
                line-height: 34px;
                text-align: center;
                font-size: 16px;
                padding: 0;
            }
            &.btn-submit-delete {
                background: url(#{$IMG_PATH}icon/icn_trash_k.png) 20px center no-repeat;
                -webkit-background-size: 80%;
                background-size:80%;
                background-position-x: 50% ;
                font-weight: normal;
                width: 34px;
                height: 34px;
                line-height: 34px;
                text-align: center;
                font-size: 16px;
                padding: 0;
            }
            
        } //input.btn, a.btn

        }//.page-debate-grading-search

    }//.contents-inner

    }//.contents
} //debate-grading-wrap