@charset "UTF-8";
.contents {
  height: 100%;

  .p-pager {
      margin-top: 5px;
      margin-left: 10px;
      min-height: 30px;
      display: flex;
      align-items: center;

      ul.pagination {
        display: inline-block;
        padding: 0;
        margin: 0;

        li {
          display: inline;

          a {
            color: black;
            // float: left;
            padding: 3px 9px;
            text-decoration: none;
            border-radius: 5px;

            &.active {
              background-color: #468CC8;
              color: white;
              border-radius: 5px;
              }

            &:hover:not(.active) {
              background-color: #6E6E6E;
              color: white;
              }

            }

            strong {
              -webkit-user-select: none;  /* Chrome all / Safari all */
              -moz-user-select: none;     /* Firefox all */
              -ms-user-select: none;      /* IE 10+ */
              user-select: none;          /* Likely future */ 
              cursor: default;
            }

          }

      }

    }
}

  .contents-inner {
    height: 100%;
  }

  .page-user-courses-history-search {
    height: 100%;
    .hidden {
      display: none;
    }
    .page-inner {
        background: $background_theme_color_contents;
        height: 100%;

      &.posr{
        position: relative;
      }

      .no-data {
        justify-content: center;
        align-items: center;

        &.hide {
          display: none !important;
        }

        &.for-scroll-x{
          position: absolute;
          width: calc(100% - 20px) !important;
          height: calc(100% - 69px) !important;
          top: 40px;
          left: 0px;
          z-index: 100;
          display: flex;
        }
      }

      .course-result {
          .table-result{
                table-layout: auto;
                .col-name{
                    min-width: 250px;
                    width: 35%;
                }
                .col-id{
                    min-width: 135px;
                    width: 10%;
                }
                .col-btn{
                    min-width: 84px;
                    width: 10%;
                }
                tr{
                    td:not(.row-ope){
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                }
            }
        }


        .page-contents {

          width: 100%;
          height: 100%;
          // overflow: auto;

          margin: auto;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;

          .no-data {
            min-height: 85%;
            margin: 0 10px;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 0px !important;

            &.hide {
              display: none;
            }

            &.for-scroll-x{
              position: absolute;
              width: calc(100% - 20px) !important;
              height: calc(100% - 69px) !important;
              top: 40px;
              left: 0px;
              z-index: 100;
              display: flex;
            }
          }

          .course-header {
            display: flex;
            padding-bottom: 5px;
            
            .except {
              height: 71px;
              display: none;
            }
            .download {
              position: absolute;
              right: 0px;
              margin-top: 5px;
              margin-right: 10px;
              min-height: 30px;
              padding-bottom: 5px;
            }

            .result-txt {
              margin-top: 5px;
              margin-left: 10px;
              min-height: 30px;
              display: flex;
              align-items: center;
            }

          }

        }

        .user-course-history-outer{
            width: 100%;
            height: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            border: none;
            .ps-scrollbar-x-rail{
                height: 7px !important;
                opacity: .4 !important;
                z-index: 90;
              .ps-scrollbar-x{
                height: 100%;
                z-index: 90;
              }
            }

            .ps-scrollbar-y-rail{
              width: 7px !important;
              opacity: .4 !important;
              z-index: 90;
            }
        }

        .user-course-history-outer:hover .ps-scrollbar-x,
          .user-course-history-outer:hover .ps-scrollbar-y{
            position: absolute;
            background-color: black;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
            -webkit-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
            transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
            -moz-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
            -o-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
            transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
            transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -webkit-border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
        }

        .user-course-history-inner{
            min-height: 100%;
            min-width: 100%;

            // tr.header {
            //   // position: fixed;
            //   // z-index: 99;
            // }

        }

        .course-result { 
            @include search_table_f;
            padding: 0px !important;

            &.result-tbl {
              @media screen and (max-width : 1366px) {
                @media screen and (max-height : 768px) {
                  
                    height: 85%;
                  
                }
              }
              padding: 0px;
              margin: 0px 10px 0px 10px !important;
              height: 90%;
              background: white;

              .ps-scrollbar-y-rail {
                right: 0px;

              }

              .table-result {

                table-layout: auto !important;
                width: 100%;

                .numerator {
                  font-weight: bold;
                  font-size: 1.3em;
                }

                .denominator {
                  font-size: 0.85em;
                }

                .last {
                  border-right: 0px solid #fafafa;
                }

                th {
                  &.lessonCol {
                    padding-top: 0;
                    padding-bottom: 0;

                  }
                }
                td {

                  &.col-courses-name {
                    // width: 24%;

                    min-width: 300px;
                    &.hover {
                      div {
                        display: inline-flex;
                        vertical-align: middle;
                        .grade-evaluate-icon {
                          width: 23px;
                          opacity: .5;
                          background-position: center;
                          background-repeat: no-repeat;
                          background: url(#{$IMG_PATH}icon/icn_score_w.png) center no-repeat;
                          -webkit-background-size: 90%;
                          background-size: 23px;
                          margin-right: 5px;
                        }
                      }
                    }
                    div {
                      display: inline-flex;
                      vertical-align: middle;
                      .grade-evaluate-icon {
                        width: 23px;
                        opacity: .5;
                        background-position: center;
                        background-repeat: no-repeat;
                        background: url(#{$IMG_PATH}icon/icn_score_k.png) center no-repeat;
                        -webkit-background-size: 90%;
                        background-size: 23px;
                        margin-right: 5px;
                      }
                    }
                  }
                  // &.col-completion-rate {
                  //   // width: 10%;

                  //   min-width: 100px;
                  // }
                  &.col-academic-status {
                    // width: 22%;

                    min-width: 153px;
                    max-width: 153px;
                  }
                  &.col-last-access {
                    // width: 15%;

                    min-width: 168px;
                    max-width: 168px;
                  }
                  &.col-all-chapter {
                    
                    min-width: 300px;
                    max-width: 300px;
                    .completion-rate {
                      display: inline-block;
                      vertical-align: middle;

                      font-weight: bold;
                      // font-size: 1.3em;
                      min-width: 58px;
                      text-align: right;
                      width: 15%;
                      color: #606060;
                    }

                    .in-time-progress-bar {
                      display: inline-block;
                      vertical-align: middle;
                      max-width: 400px;
                      width: 65%;

                    }
                  }
                  &.lessonCol {

                    height: 1px;
                    // width: 190px;
                    min-width: 53px;
                    max-width: 53px;

                    border-right: #fff;
                    border-left: #fff;
                    padding: 0px 0px 0px 0px;

                    .lesson-col-inside {

                      // height: 100%;
                      height: 37px;
                      border: 1px solid white;
                      .lesson-col-content {
                        // position: relative;
                        // top: calc(50% - 14px);
                        // white-space: nowrap;
                        // word-spacing: -6px;
                        // padding: 0px 9px 0px 3px;

                        position: relative;
                        white-space: nowrap;
                        word-spacing: -6px;
                        padding: 0px 1px 0px 1px;
                        flex-direction: column;

                        .numerator {
                          //font-size: 1.15em;
                          //display: flex;
                          //height: 20px;
                          //padding-left: 5px;
                            display: flex;
                            font-size: 16px;
                            padding-left: 4px;
                            height: 20.13px;
                          }

                        .denominator {
                          font-size: 0.9em;
                          display: flex;
                          justify-content: flex-end;
                          height: 15px;
                          padding-right: 4px;
                        }

                      }//.lesson-col-content

                      .lesson-col-no-content {
                        position: relative;
                        top: calc(50% - 11px);
                        white-space: nowrap;

                        word-spacing: -6px;
                      }

                      &.bg-blue {
                        color: #fff;
                        background-color: #84CBF1;
                      }
                      &.bg-yellow {
                        color: #606060;
                        background-color: #FEF8A0;
                      }
                      &.bg-red {
                        color: #fff;
                        background-color: #FF8A84;
                      }
                      &.bg-gray {
                        color: #606060;
                        background-color: #EBEBEB;
                      }
                      &.bg-white {
                        color: #606060;
                        background-color: #FFFFFF;
                      }

                    } //.lesson-col-inside

                    .lesson-late {
                      position: absolute;
                      top: -2px;
                      right: 2%;
                      font-size: 16px;
                      
                      font-weight: bold;
                      padding-right: 4px;

                      }

                  }// &.lessonCol
                } //td

                thead {
                  position: fixed;
                  z-index: 99;
                  touch-action: none;
                  overflow: hidden !important;
                  &.header{
                    .ps-scrollbar-x-rail{
                      display: none;
                    }
                    .ps-scrollbar-y-rail{
                      display: none;
                    }
                  }

                  tr:first-child {
                    th:last-child {
                      border-bottom: #fff;
                    }
                  }

                  tr:nth-child(2) th {
                    background: #fff !important;
                    color: #82BEF0;
                    vertical-align: middle;
                    text-align: center;

                    border-right: #fff;
                    border-left: #fff;
                  }

                  tr {
                    cursor: pointer;

                    th {
                      box-sizing:border-box;
                      -webkit-box-sizing: border-box;
                      -moz-box-sizing: border-box;

                      &.col-all-chapter {
                        padding: 0;

                        .col-all-chapter-inner{
                          position: relative;
                          height: 100%;
                          width: 100%;
                        }
                        div.div-title{
                          position: relative;
                          top: calc(50% - 14px);
                        }
                        div.div-expand {
                          // background-color: #bae3f9;
                          height: 100%;
                          position: absolute;
                          top: 0;
                          right: 0;

                          .expand-button {
                            @include HoverOpacity();
                            cursor: pointer;
                            display: block;
                            float: right;
                            width: 26px;
                            height: 100%;
                            background-position: center;
                            background-repeat: no-repeat;
                            padding-left: 7px;
                            
                            &.expand-left {
                              background-image: url(#{$IMG_PATH}icon/icn_arrowleft_k.png);
                              background-size: 88% 33%;
                              &:hover {
                                background-image: url(#{$IMG_PATH}icon/icn_triangle_l_k.png);
                                background-size: 55% 33%;
                              }
                            }

                            &.expand-right {
                              background-image: url(#{$IMG_PATH}icon/icn_arrowright_k.png);
                              background-size: 88% 33%;
                              &:hover {
                                background-image: url(#{$IMG_PATH}icon/icn_triangle_r_k.png);
                                background-size: 55% 33%;
                              }
                            }
                          } //.expand-button
                        } //div.div-expand

                      } //&.col-all-chapter
                    } //th

                    div {
                      display: inline-block;
                      .img {
                        background-image: url(#{$IMG_PATH}icon/icn_pullupdown_k.png) ;
                        background-repeat: no-repeat ;
                        background-position: center ;
                        background-size: 26px;
                        width: 27px;
                        height: 27px;
                        padding-left: 7px;
                        display: inline-block;
                        vertical-align: text-bottom;

                        &.header-sort-up {
                          cursor: pointer;
                          background-position: center ;
                          background-repeat: no-repeat ;
                          background-image: url(#{$IMG_PATH}icon/icn_pullup_k.png) ;
                          background-size: 13px;
                          padding-left: 7px;
                        }

                        &.header-sort-down {
                          cursor: pointer;
                          background-position: center;
                          background-repeat: no-repeat;
                          background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png) ;
                          background-size: 13px;
                          padding-left: 7px;
                        }
                      }

                      .txt {
                        vertical-align: text-bottom;
                      }
                    } //div

                  } //tr
                } //thead

                tbody {

                  width: 100%;
                  height: 100%;

                  tr {
                    .in-time-progress-bar {
                      .bar-body {
                        &.user-course-history-bar {
                          position: relative;
                          -webkit-border-radius: 5px;
                          -moz-border-radius: 5px;
                          border-radius: 5px;

                          height: 25px;
                          // border: 1px solid #7E7E7E;
                          background: #EBEBEB;
                          border: 1px solid white;
                        }
                        .bar {
                          position: absolute;
                          left: 0;
                          top: 0;
                          height: 100%;
                          -webkit-border-radius: 5px;
                          -moz-border-radius: 5px;
                          border-radius: 5px;

                          &.blue-bar {
                            background: #84CBF1;
                            & +.data {
                              color: #FFFFFF;
                            }
                          }
                          &.yellow-bar {
                            background: #FEF8A0;
                          }
                          &.red-bar {
                            background: #FF8A84;
                          }
                          &.gray-bar {
                          background: #EBEBEB;
                          width: 100%;
                          }
                        }
                        .bar-01 {
                          z-index: 2;
                        }
                        .data {
                            z-index: 50;
                            color: #606060;
                            width: 100%;

                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: clip;

                            word-spacing: -5px;
                        }
                      }
                    }//.in-time-progress-bar
                    .hover, &.selected {
                        .lesson-col-inside {
                            &.bg-white {
                                background: #82bef0 !important;
                            }
                        }

                      .completion-rate {
                        background: #82bef0 !important;
                        z-index: 1;
                        color: #fff;
                      }

                      .lesson-col-content, .lesson-col-no-content {
                          //color: #333333;
						  color: #909aaf;
                          .no-lesson{
                              color: #fff;
                          }
                     }

                      .in-time-progress-bar {
                      .bar-body {
                          .bar {
                            &.blue-bar {
                                & +.data {
                                  //color: #333333;
								  //  color: #9ba7c2;
                                }
                              }
                              &.yellow-bar {
                                background: #FEF8A0;
                              }
                              &.red-bar {
                                background: #FF8A84;
                              }
                              &.gray-bar {
                              background: #EBEBEB;
                              width: 100%;
                              }
                          }
                        }
                        }

                      
                      
                      }//.hover, &selected

                  }//tr



                }//tbody
              }//.table-result

              table {
                  thead {

                    position: fixed;
                    z-index: 99;

                    &.header{
                      .ps-scrollbar-x-rail{
                        display: none;
                      }
                      .ps-scrollbar-y-rail{
                        display: none;
                      }
                    }

                    tr:first-child {
                      th:last-child {
                        border-bottom: #fff;
                      }
                    }

                    tr:nth-child(2) th {
                      background: #fff !important;
                      color: #82BEF0;
                      vertical-align: middle;
                      text-align: center;

                      border-right: #fff;
                      border-left: #fff;
                    }

                    tr {
                      cursor: pointer;

                      th {
                        box-sizing:border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;

                        &.col-all-chapter {
                          padding: 0;

                          .col-all-chapter-inner{
                            position: relative;
                            height: 100%;
                            width: 100%;
                          }
                          div.div-title{
                            position: relative;
                            top: calc(50% - 14px);
                          }
                          div.div-expand {

                            // background-color: #bae3f9;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            right: 0;

                            .expand-button {
                              @include HoverOpacity();
                              cursor: pointer;
                              display: block;
                              float: right;
                              width: 26px;
                              height: 100%;
                              background-position: center;
                              background-repeat: no-repeat;
                              padding-left: 7px;
                              
                              &.expand-left {
                                background-image: url(#{$IMG_PATH}icon/icn_arrowleft_k.png);
                                background-size: 88% 33%;
                                &:hover {
                                  background-image: url(#{$IMG_PATH}icon/icn_triangle_l_k.png);
                                  background-size: 55% 33%;
                                }
                              }

                              &.expand-right {
                                background-image: url(#{$IMG_PATH}icon/icn_arrowright_k.png);
                                background-size: 88% 33%;
                                &:hover {
                                  background-image: url(#{$IMG_PATH}icon/icn_triangle_r_k.png);
                                  background-size: 55% 33%;
                                }
                              }
                            } //.expand-button
                          } //div.div-expand

                        } //&.col-all-chapter
                      } //th

                      div {
                        display: inline-block;
                        .img {
                          background-image: url(#{$IMG_PATH}icon/icn_pullupdown_k.png) ;
                          background-repeat: no-repeat ;
                          background-position: center ;
                          background-size: 26px;
                          width: 27px;
                          height: 27px;
                          padding-left: 7px;
                          display: inline-block;
                          vertical-align: text-bottom;

                          &.header-sort-up {
                            cursor: pointer;
                            background-position: center ;
                            background-repeat: no-repeat ;
                            background-image: url(#{$IMG_PATH}icon/icn_pullup_k.png) ;
                            background-size: 13px;
                            padding-left: 7px;
                          }

                          &.header-sort-down {
                            cursor: pointer;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png) ;
                            background-size: 13px;
                            padding-left: 7px;
                          }
                        }

                        .txt {
                          vertical-align: text-bottom;
                        }
                      }

                    } //tr
                  } //thead
                } //table

            }
        }
        .m-search-c {
          .m-search-c-inner{
            width: 100%;
            height: 100%;
            position: relative;

            iframe {
              width: 100%;
              height: 100%;

            }
          }
        }
	    .modal-inner {
            width: 440px;

            .no-data {
              min-height: unset  !important;
              background-color: transparent  !important;
            }
	    }
      }
  }

  .page-user-courses-history-search {
    .page-inner {
      .page-contents {
        .no-data {
          padding-bottom: 20px;
        }

        .course-attend-multiple-chapter-result {
          min-height: 85% !important;
          background-color: white !important;
          padding: 0px !important;
          margin: 0px 10px 0px 10px !important;

          .ps-scrollbar-y-rail {
            right: 0px !important;
          }
        }
      }
    }
  }

