@charset "UTF-8";
.page-user-vs-user-group {
    .page-inner {
        &.ps-active-y {
            .user-courses-layout {
                margin-bottom: 20px;
            }
        }
        .page-contents {
            width: 100%;
            height: 100%;
            padding: 0px 10px 10px;
            margin: auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            .user-courses-layout {
                position: relative;
                z-index: 1;
                @include LayoutTable();
                width: 95%;
                height: 90%;
                min-height: 1px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                margin: auto;
                &>div {
                    @include LayoutCell(top);
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    height: 100%;
                    min-height: 1px;
                }
                .title {
                    color: #3cb2e1;
                    font-size: 20px;
                   
                    vertical-align: middle;
                    span,
                    .condition-search {
                        display: inline-block;
                    }
                    .condition-search {
                        vertical-align: top;
                        height: 100%;
                        font-size: 14px;
                        input[type="radio"] {
                            display: none;
                        }
                        label {
                            display: inline-block;
                            width: 90px;
                            height: 25px;
                            vertical-align: middle;
                            text-align: center;
                            padding-top: 3px;
                            line-height: 24px;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                        }
                        input:checked+label {
                            color: #fff;
                            border: none;
                            background-color: #82BEF0;
                        }
                        input+label {
                            color: #6E6E6E;
                            border: #A0A0A0 solid 1px;
                            -webkit-border-radius: 5px;
                            -moz-border-radius: 5px;
                            border-radius: 5px;
                        }
                    }
                    .member-search {
                        width: 100%;
                        padding-top: 4px;
                        overflow: hidden;
                    }
                    input[type=text] {
                        width: calc(100% - 120px);
                        border: 1px solid #A0A0A0;
                        box-sizing: border-box;
                        color: #6E6E6E;
                        font-size: 16px;
                        text-indent: 5px;
                        height: 40px;
                    }
                    input[type=submit] {
                        float: right;
                        background-color: #82BEF0 !important;
                        box-sizing: border-box;
                        background: url(#{$IMG_PATH}icon/icn_docs_w.png) left center no-repeat;
                        background-position-x: 50%;
                        -webkit-background-size: 25px;
                        background-size: 25px;
                        width: 110px;
                        height: 40px;
                    }
                }
                .cell-inner {
                    height: 100%;
                    min-height: 1px;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    position: relative;
                    @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: .001dpcm) {
                        // chrome only
                        -webkit-box-sizing: content-box;
                        -moz-box-sizing: content-box;
                        box-sizing: content-box;
                    }
                    .ps-container.ps-active-y>.ps-scrollbar-y-rail {
                        background: none;
                        right: 0px!important;
                    }
                    .ps-container>.ps-scrollbar-y-rail {
                        opacity: 0;
                    }
                    .ps-container:hover>.ps-scrollbar-y-rail,
                    .ps-container.ps-in-scrolling>.ps-scrollbar-y-rail {
                        opacity: .3;
                    }
                    .user-courses-list-outer {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        padding-bottom: 116px;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        
                        .assign-perioda{
                            .input-text{
                                    width: calc(100% - 246px);
                            }
                            margin-top: 15px;
                        }
                        .user-courses-list {
                            height: 100%;
                            overflow-y: hidden;
                            padding-right: 15px;
                            .user-courses-list-inner {
                                min-height: 100%;
                            }
                            li {
                                position: relative;
                                border-bottom: 1px solid #6E6E6E;
                                background: #fff;
                                height: 51px!important;
                                .inner>.inner-box {
                                    position: relative;
                                    display: block;
                                    font-size: 13px;
                                    color: $font_color_default;
                                    &:hover {
                                        text-decoration: none;
                                    }
                                    &>div {
                                        padding: 8px 30px 8px 15px;
                                    }
                                    .txt {
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        line-height: 35px;
                                        min-height: 35px;
                                        font-size: 14px;
                                    }
                                    .date {
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                }
                                .inner>.inner-box:before {
                                    @include PseudoElements();
                                    position: absolute;
                                    display: block;
                                    width: 40px;
                                    height: 40px;
                                    left: 5px;
                                    top: 50%;
                                    margin-top: -22px;
                                    opacity: .6;
                                }
                                &.icn-docs .inner>.inner-box:before {
                                    background: url(#{$IMG_PATH}icon/icn_docs_k.png) left center no-repeat;
                                    -webkit-background-size: contain;
                                    background-size: contain;
                                }
                                &.icn-books .inner>.inner-box:before {
                                    background: url(#{$IMG_PATH}icon/icn_books_k.png) left center no-repeat;
                                    -webkit-background-size: contain;
                                    background-size: contain;
                                }
                                &.icn-test .inner>.inner-box:before {
                                    background: url(#{$IMG_PATH}icon/icn_test_k.png) left center no-repeat;
                                    -webkit-background-size: contain;
                                    background-size: contain;
                                }
                                &.icn-debate .inner>.inner-box:before {
                                    background: url(#{$IMG_PATH}icon/icn_debate_k.png) left center no-repeat;
                                    -webkit-background-size: contain;
                                    background-size: contain;
                                }
                                .btn-remove {
                                    @include HoverOpacity();
                                    position: absolute;
                                    display: block;
                                    right: 10px;
                                    top: 50%;
                                    margin-top: -10px;
                                    width: 20px;
                                    height: 20px;
                                    z-index: 1;
                                    -webkit-border-radius: 100%;
                                    -moz-border-radius: 100%;
                                    border-radius: 100%;
                                    -webkit-box-sizing: border-box;
                                    -moz-box-sizing: border-box;
                                    box-sizing: border-box;
                                    span {
                                        position: relative;
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                        -webkit-border-radius: 100%;
                                        -moz-border-radius: 100%;
                                        border-radius: 100%;
                                        &:after,
                                        &:before {
                                            @include PseudoElements();
                                            position: absolute;
                                            left: 50%;
                                            top: 50%;
                                            display: block;
                                            width: 2px;
                                            height: 12px;
                                            margin-left: -1px;
                                            margin-top: -6px;
                                            background: #6E6E6E;
                                        }
                                        &:before {
                                            -webkit-transform: rotate(-45deg);
                                            -moz-transform: rotate(-45deg);
                                            -ms-transform: rotate(-45deg);
                                            -o-transform: rotate(-45deg);
                                            transform: rotate(-45deg);
                                        }
                                        &:after {
                                            -webkit-transform: rotate(45deg);
                                            -moz-transform: rotate(45deg);
                                            -ms-transform: rotate(45deg);
                                            -o-transform: rotate(45deg);
                                            transform: rotate(45deg);
                                        }
                                    }
                                }
                                &:nth-child(even) {
                                    .inner>.inner-box {
                                        &>div {
                                            background: #FCFCFC;
                                        }
                                    }
                                }
                               &.update {
                                    .inner .inner-box {
                                        color: #fff;
                                        &>div {
                                            background: #DC780A;
                                        }
                                    }
                                    .btn-remove {
                                        span {
                                            &:after,
                                            &:before {
                                                background: #fff;
                                            }
                                        }
                                    }
   
                                }
                                &.selected {
                                    .inner:after {
                                        @include PseudoElements();
                                        position: absolute;
                                        right: -15px;
                                        top: 50%;
                                        margin-top: -26px;
                                        display: block;
                                        height: 51px;
                                        width: 15px;
                                        background: url(#{$IMG_PATH}common/balloon_list.png) left center no-repeat;
                                        -webkit-background-size: 100% 100%;
                                        background-size: 100% 100%;
                                    }
                                    .inner .inner-box {
                                        color: #fff;
                                        &>div {
                                            background: #82BEF0;
                                        }
                                      
                                    }
                                    
                                }
                                &.remove {
                                .inner .inner-box {
                                    color: #fff;
                                    &>div {
                                        background: #A0A0A0;
                                    }
                                }
                            }
                            } // li
                        } // .user-courses-list
                    } // .user-courses-list-outer
                } // .cell-inner
                
                .assign-member-cell {
                    .user-courses-list {
                        border: #6E6E6E solid 1px;
                    }
                    .user-courses-list-inner {
                        ul {
                           
                        }
                    }
                }
                .assign-member-cell {
                    width: 47.45%;
                    .cell-inner .user-courses-list-outer .user-courses-list {
                        padding-right: 0px;
                        .user-courses-list-inner {
                            .inner:after {
                                content: none;
                            }
                        }
                        li {
                            .inner {
                                a {
                                    cursor: default;
                                }
                                .btn-remove {
                                    cursor: pointer;
                                }
                            }
                            .inner>.inner-box {
                               
                                .date {
                                    font-size: 11px;
                                }
                            }
                        }
                    }
                }
                .assign-button-cell {
                    width: 90px;
                    .cell-inner {
                        a {
                            width: 60px;
                            height: 60px;
                            text-align: center;
                            line-height: 60px;
                            text-decoration: none;
                            position: absolute;
                            display: block;
                            top: 30%;
                            left: 15%;
                            border: #6E6E6E solid 1px;
                            background: url(#{$IMG_PATH}icon/icn_back_arrow_g.png) center center no-repeat;
                            -webkit-background-size: 40px;
                            background-size: 40px;
                            -webkit-border-radius: 5px;
                            -moz-border-radius: 5px;
                            border-radius: 5px;
                            @include HoverOpacityTrans();
                        }
                    }
                }
                 @media screen and (max-width: 1100px) {
                     .assign-member-cell {
                             width: 230px;
                     }
                     .assign-button-cell {
                         width: 52px;
                         .cell-inner {
                         a{
                                 left: 10%;
                             width: 40px;
                             height: 40px;
                             line-height: 40px;
                                 -webkit-background-size: 30px;
    background-size: 30px;
                         }}
                     }
                 }

                .member-add-cell {
                    .title {
                        margin-bottom: 12px;
                    }
                    .cell-inner {
                        .user-courses-list-outer {
                            padding-bottom: 170px;
                            .user-courses-list {
                                border: #6E6E6E solid 1px;
                                padding-right: 0px;
                                margin-bottom: 12px;
                                li {
                                    .inner>.inner-box {
                                        &>div {
                                            padding: 8px 10px;
                                        }
                                    }
                                }
                            }
                            .assign-period {
                                padding: 8px 10px;
                                border: #6E6E6E solid 1px;
                                .title-period {
                                    padding-bottom: 5px;
                                    span:last-child {
                                        float: right;
                                        .pseudo-checkbox {
                                            border: #6E6E6E solid 1px;
                                            -webkit-border-radius: 0px;
                                            -moz-border-radius: 0px;
                                            border-radius: 0px;
                                        }
                                        &>span:last-child {
                                            padding-left: 5px;
                                        }
                                    }
                                }
                            }
                            .period-input {
                                width: 100%;
                                display: table;
                                &>div {
                                    display: table-cell;
                                    padding-right: 5px;
                                }
                                div:last-child {
                                    padding-right: 0;
                                    a {
                                        min-width: 60px;
                                        height: 30px;
                                        line-height: 30px;
                                        color: #6E6E6E;
                                        border: #6E6E6E solid 1px;
                                        text-align: center;
                                        text-decoration: none;
                                        -webkit-border-radius: 5px;
                                        -moz-border-radius: 5px;
                                        border-radius: 5px;
                                        @include HoverOpacityTrans();
                                        display: block;
                                    }
                                }
                                .start,
                                .end {
                                    position: relative;
                                    input[type=text] {
                                        width: calc(100% - 40px);
                                        padding: 5px 30px 5px 5px;
                                    }
                                    a {
                                        position: absolute;
                                        bottom: 5px;
                                        right: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            } // .user-courses-layout
            .btn-confirm-link {
                position: relative;
                z-index: 1;
                width: 100%;
                margin-top: -55px;
                a {
                    margin: 0px auto;
                    text-align: center;
                    border: #6E6E6E solid 1px;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    border-radius: 5px;
                    display: block;
                    width: 150px;
                    height: 35px;
                    line-height: 35px;
                    color: #6E6E6E;
                    @include HoverOpacityTrans();
                }
            }
        } // .page-contents
    } // .page-inner
} // .page-user-courses