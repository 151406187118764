@charset "UTF-8";

.page-all-history-extraction {

  .m-search-c-inner {
    iframe {
      width: 100vw;
      height: 100vh;
    }
  }

  .global-header {
    position: static !important;
    width: 800px;
    margin-top: 80px;
    box-shadow: none;
    border-bottom: 2px solid gray;

    span.name {
      font-size: 18px;
      opacity: 1 !important;
      color: gray !important;
    }

    span img {
      opacity: 1 !important;
    }

  }

  .course-length {
    display: inline-block;
    margin: -5px 5px 0 27px;
    vertical-align: middle;
    max-width: 28em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .c-name-btn {
    display: inline-block;
    margin: -5px 0 0 -11px;
    vertical-align: middle;
  }

  .course-search {
    display: inline-block;
    vertical-align: middle;
  }

  .page-inner {
    padding-top: 10px;
    width: 800px;
    margin: auto;

    .page-contents {
      text-align: center;

      .inner {
        text-align: left;

        .back img {
          width: 25px;
          height: 25px;
          vertical-align: -webkit-baseline-middle;
        }

      }

      .form-row:first-child a {
        margin-left: 20px;
      }

      .form-row {
        width: 525px;
        margin: auto;
        text-align: left;
        margin-top: 50px;

        & > span {
          float: left;
        }

        & > input {
          height: 32px;
        }

        a {
          display: inline-block;
        }

        span.attention {
          display: block;
          font-size: 12px;
          color: red;
        }

        a.btn-cal {
          pointer-events: none;
        }

      }

      .form-btns {
        margin-top: 60px;
        margin-bottom: 30px;

        .btn-gray {
          border: 1px solid #a0a0a0;
          color: #a0a0a0;
        }
      }

    }

  }

}
