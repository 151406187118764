@charset "UTF-8";

.global-header {
  background: #fff;
  -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);

  .global-header-inner {
    position: relative;

    .global-header-logo {
      position: absolute;
      left: 15px;
      top: 8px;
    } // .global-header-logo

    .global-header-ttl {
      text-align: center;
      padding-top: 8px;
      font-size: 22px;
      font-weight: normal;

      span {
        vertical-align: middle;
        img {
          vertical-align: middle;
          opacity: .6;
          width: 23px;
        }
      }

      .name {
        position: relative;
        top: 2px;
           opacity: .3;
      }

    } // .global-header-ttl

    .global-header-user {
      position: absolute;
      right: 20px;
      top: 8px;

      .global-header-user-inner {
        position: relative;
        width: 37px;
        height: 37px;

        .user-icon {
          @include HoverOpacityTrans(.3s, .7);
          display: block;
          z-index: 1;
          position: relative;

          .circle {
            display: block;
            overflow: hidden;
            width: 37px;
            height: 37px;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;

            .user-ph {
              display: block;
              width: 100%;
              height: 100%;
              background-position: center center;
              background-repeat: no-repeat;
              -webkit-background-size: cover;
              background-size: cover;
            }
          }

          &.notice:after {
            @include PseudoElements();
            position: absolute;
            width: 9px;
            height: 9px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            background: $accent_color_default;
            right: 0;
            top: 0;
          }

        } // .user-icon

        .user-info {
          opacity: 0;
          display: none;
          position: absolute;
          right: 0;
          top: -5px;
          margin-right: 50px;

          -webkit-transition: all .2s;
          -moz-transition: all .2s;
          -ms-transition: all .2s;
          -o-transition: all .2s;
          transition: all .2s;

          .user-info-inner {
            position: relative;
            background: #595959;
            padding: 10px;
            color: #fff;

            .tri {
              position: absolute;
              width: 14px;
              height: 14px;
              background: #595959;
              right: -5px;
              top: 15px;
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
            }

            .layout {
              display: table;

              & > div {
                display: table-cell;
                vertical-align: middle;
              }

              .ph {
                width: 80px;

                .circle {
                  display: inline-block;
                  overflow: hidden;
                  width: 60px;
                  height: 60px;
                  -webkit-border-radius: 100%;
                  -moz-border-radius: 100%;
                  border-radius: 100%;

                  a {
                    @include HoverOpacity();
                  }

                  .user-ph {
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    -webkit-background-size: cover;
                    background-size: cover;
                  }
                }
              }
              .text {
                padding-left: 10px;
                min-width: 100px;

                .btn-logout {
                  display: block;
                  width: 110px;
                  height: 20px;
                  margin: 5px auto 0;
                  -webkit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  text-align: center;
                  color: #fff;
                  border: 1px solid #fff;
                  font-size: 12px;
                  background-size: 15px 15px;
                  background-repeat: no-repeat;
                  background-position: 2px 2px;

                  &:hover {
                    background-color: #777;
                    text-decoration: none;
                  }
                }
              }
            }
          }

        }

      } // .global-header-user-inner

    } // .global-header-user

  } // .global-header-inner

} // .global-header