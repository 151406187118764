@charset "UTF-8";

.page-title {
  @include page_title();
}

.sub-heading {
  @include sub_heading();
}

.sub-heading-under {
  @include sub_heading_under();
}

